import React, { memo, useState, useRef, useEffect } from 'react'
import { useDrop } from 'react-dnd';
import { API } from "aws-amplify";

import MagnetBoardSectionHSMS from './MagnetboardSectionHSMS';

import { useOutsideClick, } from '../../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, } from '@fortawesome/free-solid-svg-icons';

export default memo(function MagnetboardDropPeriodHSMS ({ currentScheduleVersionID, currentView, currentViewType, currentDay, schoolInfo, scheduleInfo, scheduleType, scheduleStructureData, sections, setSections, subsections, labels, teachers, setTeachers, courses, classrooms, students, setStudents, classroomID, teacherID, coursePeriodID, periodID, periodName, setConflictsLoading, conflicts, setConflicts, setMatchPercentLoading, setMatchPercent, labsOnlyFiltered, nonLabsOnlyFiltered, singletonsFiltered, doubletonsFiltered, noClassroomFiltered, hasClassroomFiltered, toggleEditSection, handleSelectQuickViewDataID, selectedQuickViewSectionID, magnetboardOptions, updateSectionPlacement, updateManualPlacement, dataSections }) {
	/////////////////////
	///// USE STATE /////
	/////////////////////
	const dataID = useRef((currentView === 'classroom') ? classroomID : teacherID);
	const [sortedDataSections, setSortedDataSections] = useState([]);
	const [addNewSectionIsOpen, setAddNewSectionIsOpen] = useState(false);

	/////////////////////////////
	///// USE DROP FUNCTION /////
	/////////////////////////////
    const [{ isOver }, dropRef] = useDrop({
        accept: ['section', 'manual-section', 'lunch'],
		drop: (item) => {
			if(item.type === 'section')
			{
				updateSectionPlacement({...item, droppedDataID:dataID.current, droppedCoursePeriodID: coursePeriodID});
			}
			else if(item.type === 'manual-section')
			{
				updateManualPlacement({ updateType:'add', sectionID:item.sectionID, droppedCoursePeriodID: coursePeriodID});
			}
			else if(item.type === 'lunch')
			{
				const new_schedule_period_id = scheduleStructureData.course_periods[item.draggedCoursePeriodID].days[currentDay];
				updateLunchSchedule(currentView, new_schedule_period_id, dataID.current);
			}
		},
    });

	// ///////////////////
	// ///// USE REF /////
	// ///////////////////
	const add_new_section_ref = useRef();

	useOutsideClick(add_new_section_ref, () => {
		setAddNewSectionIsOpen(false);
	});

	// /////////////////////
	// ///// FUNCTIONS /////
	// /////////////////////
	const updateLunchSchedule = async (user_type, schedule_period_id, user_id) => {
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, user_type:user_type, user_id:user_id, schedule_period_id:schedule_period_id};

		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/update-lunch-schedule';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			await API.post(apiName, url, myInit);
			
			if(user_type === 'teacher')
			{
				const teacher_index = teachers.findIndex(teacher => teacher.teacher_id === user_id);
				if(teacher_index !== -1) teachers[teacher_index].lunch_schedule_period_id = schedule_period_id;

				setTeachers([...teachers]);
			}
			else if(user_type === 'student')
			{
				const student_index = students.findIndex(student => student.student_id === user_id);
				if(student_index !== -1) students[student_index].lunch_schedule_period_id = schedule_period_id;

				setStudents([...students]);
			}
		} catch(e)
		{
			console.log(e.response);
		}
	}

	// /////////////////////
	// ///// USE EFFECT /////
	// /////////////////////
	useEffect(() => {
		let logged_section_ids = []
		let data_sections = dataSections.reduce((results, section) => {
			const section_id = section.section_id;
			const subsection_index = subsections.findIndex(subsection => subsection.subsection_id === section_id);
			const main_section_id = (subsection_index !== -1) ? subsections[subsection_index].section_id : section_id;

			if(logged_section_ids.includes(main_section_id)) return results;
			logged_section_ids.push(main_section_id);

			const section_course_periods = ('course_periods' in section) ? section.course_periods : [];
			if(section_course_periods.includes(coursePeriodID)) results.push(section);

			return results;
		}, []);
		const course_period_sections_sorted_by_quarter = (data_sections.length > 0) ? data_sections.sort((section_A, section_B) => {
			const section_A_min_quarter = section_A.quarter_days.reduce((result, quarter_day) => {
				if(quarter_day.quarter < result) result = quarter_day.quarter;
				return result;
			}, '999');
	
			const section_B_min_quarter = section_B.quarter_days.reduce((result, quarter_day) => {
				if(quarter_day.quarter < result) result = quarter_day.quarter;
				return result;
			}, '999');
	
			if(section_A_min_quarter < section_B_min_quarter) return -1;
			return 1;
		}) : [];
		setSortedDataSections(course_period_sections_sorted_by_quarter);
	}, [dataSections, subsections, currentView, teacherID, classroomID, coursePeriodID])


    return (
        <div className={`mb-inner-box mb-schedule-inner-box ${isOver && 'mb-schedule-inner-box-hovered'}`} ref={dropRef}>
			<div className='print-period-name'>{periodName}</div>
			{/* {isTeacherLunchPeriod &&
				<div className='mb-section mb-lunch-section' ref={lunchDragRef}>
					<div className='mb-section-top-bar pastel-yellow'>
						<div>Lunch</div>
						<div className='mb-section-more-info opaque-link' onClick={() => updateLunchSchedule('teacher', null, teacherID)}><FontAwesomeIcon icon={faTrashAlt}/></div>
					</div>
					<div className='mb-section-content'>
						<div className='mb-section-name ellipsis'>Lunch</div>
					</div>
				</div>
			} */}
			{sortedDataSections.map((section_info, index) => {
				return (
					<MagnetBoardSectionHSMS key={index} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} scheduleType={scheduleType} currentView={currentView} currentViewType={currentViewType} currentDay={currentDay} currentScheduleVersionID={currentScheduleVersionID} teachers={teachers} courses={courses} classrooms={classrooms} sections={sections} setSections={setSections} subsections={subsections} labels={labels} conflicts={conflicts} setConflicts={setConflicts} setConflictsLoading={setConflictsLoading} setMatchPercent={setMatchPercent} setMatchPercentLoading={setMatchPercentLoading} labsOnlyFiltered={labsOnlyFiltered} nonLabsOnlyFiltered={nonLabsOnlyFiltered} singletonsFiltered={singletonsFiltered} doubletonsFiltered={doubletonsFiltered} noClassroomFiltered={noClassroomFiltered} hasClassroomFiltered={hasClassroomFiltered} toggleEditSection={toggleEditSection} dataID={dataID.current} coursePeriodID={coursePeriodID} sectionID={section_info.section_id} sectionType='section' handleSelectQuickViewDataID={handleSelectQuickViewDataID} selectedQuickViewSectionID={selectedQuickViewSectionID}/>
				)
			})}
			{magnetboardOptions.allow_new_sections &&
				<div className='mb-add-new-section' ref={add_new_section_ref}>
					<FontAwesomeIcon className='gray-to-blue-link' icon={faPlusCircle} onClick={magnetboardOptions.allow_placeholders ? () => setAddNewSectionIsOpen(!addNewSectionIsOpen) : () => toggleEditSection({section_id:null, data_id:dataID.current, section_type:'1', course_period_id:coursePeriodID})}/>
					{(dataID && addNewSectionIsOpen) &&
						<div className='dropdown-options-container' style={{top:'27px', zIndex:'999'}}>
							<div className='dropdown-option left-border-dark-blue-thick' onClick={() => toggleEditSection({section_id:null, data_id:dataID.current, section_type:'1', course_period_id:coursePeriodID})}>New Section</div>
							{(currentView === 'teacher') &&
								<>
								{currentViewType === 'day' &&
									<div className='dropdown-option left-border-pastel-yellow-thick' onClick={() => updateLunchSchedule('teacher', periodID, dataID.current)}>Lunch</div>
								}
								<div className='dropdown-option left-border-pastel-light-blue-thick' onClick={() => toggleEditSection({section_id:null, data_id:dataID.current, section_type:'2', course_period_id:coursePeriodID})}>Prep</div>
								<div className='dropdown-option left-border-pastel-brown-thick' onClick={() => toggleEditSection({section_id:null, data_id:dataID.current, section_type:'3', course_period_id:coursePeriodID})}>Duty</div>
								</>
							}
						</div>
					}
				</div>
			}
		</div>
    )
})