import React from 'react';
import MagnetboardElementary from '../MagnetboardElementary';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import '../../../css/elementary/auto_scheduling_setup.css';

export default function SpecialsReview({ isLoading, schoolSubscribed, subscreenLocked, subscreenRestricted, schoolInfoLoading, schoolInfo, scheduleInfoLoading, scheduleInfo, periodsLoading, periods, coursesLoading, courses, setCourses, teachersLoading, teachers, setTeachers, studentsLoading, students, setStudents, classroomsLoading, classrooms, setClassrooms, blocksLoading, blocks, setBlocks, handleMoveToNextScreen }) {
	const specials_teachers_ids = teachers.reduce((results, teacher) => {
		const teacher_id = teacher.teacher_id;
		const teacher_can_teach = teacher.teacher_can_teach;
		
		const teacher_teaches_specials = teacher_can_teach.reduce((result, tct_course_info) => {
			const tct_course_id = tct_course_info.course_id;
			const course_info = courses.find(course => course.course_id === tct_course_id);
			if(course_info && course_info.is_special === '1') result = true;
			return result;
		}, false)

		if(teacher_teaches_specials) results.push(teacher_id);
		return results;
	}, []);

	return (
		<>
		{(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):(isLoading) ?
		(
			<div className='center-text'>
				<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'25px'}}/>
			</div>
		):
		(
			<>
				<MagnetboardElementary schoolSubscribed={schoolSubscribed} schoolInfo={schoolInfo} schoolInfoLoading={schoolInfoLoading} scheduleInfo={scheduleInfo} scheduleInfoLoading={scheduleInfoLoading} periodsLoading={periodsLoading} periods={periods} teachersLoading={teachersLoading} teachers={teachers} setTeachers={setTeachers} coursesLoading={coursesLoading} courses={courses} setCourses={setCourses} classroomsLoading={classroomsLoading} classrooms={classrooms} setClassrooms={setClassrooms} studentsLoading={studentsLoading} students={students} setStudents={setStudents} blocksLoading={blocksLoading} blocks={blocks} setBlocks={setBlocks} magnetboardOptions={{screen_title: "Specials Teachers Schedules", show_conflicts:false, allowed_teacher_ids: specials_teachers_ids}}/>
				<div className='fixed-bottom-save-container'>
					<div className="btn btn-extra-large green-btn align-right move-to-next-screen-btn move-to-next-screen-btn" onClick={handleMoveToNextScreen}>Go to Next Step: Pullouts Setup</div>
				</div>
			</>
		)
		}
		</>
	)
}