import { getJSONFromStorage } from './Helpers';
import { API } from "aws-amplify";

export const submitDepartmentScreen = async (passed_data) => {
    const school_id = passed_data.school_id;
    const schedule_version_id = passed_data.schedule_version_id;
    const department_id = passed_data.department_id;
    const completed_subscreen = passed_data.completed_subscreen;

	const data = {school_id: school_id, completed_subscreen: completed_subscreen, department_id: department_id, schedule_version_id:schedule_version_id};
	
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/submit-department-screen';
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
    const response = await API.post(apiName, url, myInit);
    const result = response.data;
	
	return result;
}

export const updateUser = async (data) => {
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/admin/update-user';
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
    const response = await API.post(apiName, url, myInit);
    const response_data = response.data;
	
	return response_data;
}

export const saveSchoolInfo = async (data, _callback) => {
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/admin/save-school-info';
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
    const response = await API.post(apiName, url, myInit);
	
	const school_info = {...data, ...response.data};
	localStorage.setItem('schoolInfo', JSON.stringify(school_info));
	
	_callback();
}

export const saveSchoolSettings = async (data, _callback) => {
    //data : {school_id,  max_classes_in_a_row, max_teacher_classes_allowed, separate_ICR_codes}
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/admin/save-school-settings';
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
    await API.post(apiName, url, myInit);
	
    const schedule_info = getJSONFromStorage('scheduleInfo', true);
	const new_schedule_info = {...schedule_info, ...data};

	localStorage.setItem('scheduleInfo', JSON.stringify(new_schedule_info));
	
	_callback();
}

export const saveScheduleType = async (data) => {
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/admin/save-schedule-type';
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
    await API.post(apiName, url, myInit);
	
	const schedule_type = data.schedule_type;
	const scheduleInfo = getJSONFromStorage('scheduleInfo', true);
	
	// Set the schedule type in local storage
	scheduleInfo.schedule_type = schedule_type;
	localStorage.setItem("scheduleInfo", JSON.stringify(scheduleInfo));
	
	return;
} 

export const saveLunchStructure = async (data) => {
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/admin/save-lunch-structure';
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
    await API.post(apiName, url, myInit);
	
	return;
}

export const saveScheduleStructure = async (data) => {
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/admin/save-schedule-structure';
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
    const response = await API.post(apiName, url, myInit);
    const response_data = response.data;
	
	return response_data;
}

export const saveSIS = async (data) => {
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/admin/save-sis';
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
    await API.post(apiName, url, myInit);
	
	const sis = data.sis;
	const scheduleInfo = getJSONFromStorage('scheduleInfo', true);
	
	// Set the sis in local storage
	scheduleInfo.sis = sis;
	localStorage.setItem("scheduleInfo", JSON.stringify(scheduleInfo));
	
	return;
}

export const changeDataDepartment = async (data) => {
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/admin/change-data-department';
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
    await API.post(apiName, url, myInit);
	
	return;
}

export const saveDataToDatabase = async (data) => {
    const database = ('database' in data && data.data_type === 'course') ? data.database : 'edario';
	const apiName = (database === 'edario') ? process.env.REACT_APP_ENDPOINT_NAME : process.env.REACT_APP_ELEM_ENDPOINT_NAME;
	const url = (database === 'edario') ? '/admin/save-data-to-database' : '/elementary/save-course';
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
	let response_data;
    
    try {
		const response = await API.post(apiName, url, myInit);
		response_data = response.data;
	} catch(e)
	{
		console.log(e.response);
	}
	
	return response_data;
}

export const createInclusionLabels = async (data, _callback = () => {}) => {
    const apiName = process.env.REACT_APP_ENDPOINT_NAME;
    const url = '/admin/create-inclusion-labels';
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
   
    try {
        await API.post(apiName, url, myInit);
        
        _callback();
    } catch(e)
    {
        console.log(e.response);
    }
}

export const saveElementarySchoolSettings = async (data, _callback) => {
    //data : {school_id,  start_time, end_time}
	const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
	const url = '/elementary/save-schedule-info';
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
    await API.post(apiName, url, myInit);
	
    const schedule_info = getJSONFromStorage('scheduleInfo', true);
	const new_schedule_info = {...schedule_info, ...data};

	localStorage.setItem('scheduleInfo', JSON.stringify(new_schedule_info));
	
	_callback();
}