import React, { useEffect, useState } from 'react';
import Fuse from 'fuse.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default function SectionReviewEditingSecondaryTeacher({ teachers, courses, sectionID, courseID, secondaryTeacherID, onSelect = () => {}, onRemove = () => {}}) {
	const [teacherName, setTeacherName] = useState('');
	const [suggestedSearchResults, setSuggestedSearchResults] = useState([]);
	const [searchResults, setSearchResults] = useState([]);

	const handleSelect = (e, secondary_teacher_id) => {
		onSelect({e:e, section_id:sectionID, secondary_teacher_id:secondary_teacher_id});

		setSuggestedSearchResults([]);
		setSearchResults([]);
	}

	const handleRemove = () => {
		onRemove({section_id:sectionID, secondary_teacher_id:secondaryTeacherID});

		setSuggestedSearchResults([]);
		setSearchResults([]);
	}
	
	const fuzzySearch = (e) => {
		const search_value = e.target.value;
		
		/////////////////////////////////
		///// GET SUGGESTED RESULTS /////
		/////////////////////////////////
		let suggested_results = [];
		
		// Search only through teacher can teach teachers
		if(courseID)
		{
			const teachers_can_teach = courses.find(course => course.course_id === courseID).teachers_can_teach;
			const courses_teachers = [];
			
			teachers_can_teach.forEach(tct_info => {
				const tct_teacher_id = tct_info.teacher_id;
				const tct_teacher = teachers.find(teacher => teacher.teacher_id === tct_teacher_id);
				
				courses_teachers.push(tct_teacher);
			});
			
			const fuse = new Fuse(courses_teachers, {
				keys: ['first_name', 'name'],
				threshold: .1
			});
			
			const results = fuse.search(search_value);
			suggested_results = results.map(result => result.item);
		}
		
		//////////////////////////////
		///// GET SEARCH RESULTS /////
		//////////////////////////////
		const fuse = new Fuse(teachers, {
			keys: ['first_name', 'name'],
			threshold: .1
		});
		
		const results = fuse.search(search_value);
		const teacher_results = results.map(result => result.item).slice(0,4);
		
		///////////////////////
		///// SET RESULTS /////
		///////////////////////
		setSuggestedSearchResults(suggested_results);
		setSearchResults(teacher_results);
	}

	useEffect(() => {
		const teacher_info = teachers.find(teacher => teacher.teacher_id === secondaryTeacherID);
		const teacher_name = (teacher_info) ? `${teacher_info.name}, ${teacher_info.first_name}` : '';
		setTeacherName(teacher_name);
	},[secondaryTeacherID, teachers]);
	
	return (
		<div className='secion-review-secondary-teacher-input-container'>
			<div className='section-review-edit-secondary-teacher-container'>
				<div>
					<input key={teacherName} className='section-review-edit-section-subname' defaultValue={teacherName} onChange={fuzzySearch} />
				</div>
				<FontAwesomeIcon className='gray-to-red-link' icon={faTimes} onClick={handleRemove}/>
			</div>
			<div className='section-review-search-add-search-results'>
				{suggestedSearchResults.length !== 0 &&
					<>
					<div className='search-add-did-you-mean'>Teachers Who Can Teach:</div>
					{suggestedSearchResults.map((teacher, i) => {
						return (
							<div className='search-add-search-result' key={`suggested-${teacher.teacher_id}`}>
								<div>{teacher.name}, {teacher.first_name}</div>
								<div>
									<div className='search-add-add-btn' onClick={(e) => handleSelect(e, teacher.teacher_id)}>Add</div>
								</div>
							</div>
						)
					})}
					</>
				}
				{searchResults.length !== 0 &&
					<>
					<div className='search-add-did-you-mean'>Other Teachers:</div>
					{searchResults.map((teacher, i) => {
						return (
							<div className='search-add-search-result' key={`search-${teacher.teacher_id}`}>
								<div>{teacher.name}, {teacher.first_name}</div>
								<div>
									<div className='search-add-add-btn' onClick={(e) => handleSelect(e, teacher.teacher_id)}>Add</div>
								</div>
							</div>
						)
					})}
					</>
				}
			</div>
		</div>
	);
}