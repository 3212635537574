import React, { useState , useEffect } from 'react';
import { API } from "aws-amplify";
import swal from '@sweetalert/with-react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Button from '../../Button';

import { getJSONFromStorage, isEmpty } from '../../../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

export default function CleanDuplicates({ subscreenRestricted, subscreenLocked, userInfo, rolePages, schoolInfo, handleMoveToNextScreen }) {
	const [subscreenIsComplete, setSubscreenIsComplete] = useState(false);

	const [duplicatesLoaded, setDuplicatesLoaded] = useState(false);
	const [duplicates, setDuplicates] = useState({});
	const [duplicateScreen, setDuplicateScreen] = useState('courses');
	const [dontShowWarning, setDontShowWarning] = useState(false);
	
	const [showInstructions, setShowInstructions] = useState(false);
	
	const removeDuplicateGroup = (data_type, group_index) => {
		duplicates[data_type].splice(group_index, 1);
		setDuplicates({...duplicates});
		
		localStorage.setItem('duplicates', JSON.stringify(duplicates));
	}
	
	const removeDuplicate = async (data_type, group_index, data_row_index, data_id, data_info) => {
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:data_type, data_id:data_id};
		let remove_duplicate_bool = true;
		
		if(!dontShowWarning)
		{
			let data_name;
	    
		    if(data_type === 'courses')
		    {
			    data_name = `${data_info.name} (${data_info.course_code})`;
		    }
		    else if(data_type === 'teachers')
		    {
			    data_name = `${data_info.name}, ${data_info.first_name}`;
		    }
		    else if(data_type === 'classrooms')
		    {
			    data_name = `Room ${data_info.classroom_name}`;
		    }
		    else if(data_type === 'students')
		    {
			    data_name = `${data_info.last_name}, ${data_info.first_name}`;
		    }
		    
		    const options =  {
				title: "Are you sure?",
				icon: "warning",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Cancel",
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "Yes",
						value: true,
						visible: true,
						className: 'red-btn'
					},
				},
				content: (
					<div>
						<div>
							<p>Do you really want to delete {data_name}?</p>
							<p className='red-text'>This will <strong>permanently</strong> remove it from all departments.</p>
						</div>
						<div className='sweet-alert-dont-show-message' onClick={toggleDontShow}>
							<label className="checkmark_container">Please don&rsquo;t show me this message again
								<input type="checkbox" />
								<span className="checkmark"></span>
							</label>
						</div>
					</div>
				)
			}
		
			remove_duplicate_bool = await swal(options);
		}
	   
		if(remove_duplicate_bool)
		{
			const apiName = process.env.REACT_APP_ENDPOINT_NAME;
			const url = '/admin/clean-data/remove-duplicate';
		    const myInit = { // OPTIONAL
		        response: true,
		        body: JSON.stringify(data),
		    };
		    
			try {
				await API.post(apiName, url, myInit);
				
				duplicates[data_type][group_index]['group'][data_row_index]['is_deleted'] = '1';
				setDuplicates({...duplicates});
				
				localStorage.setItem('duplicates', JSON.stringify(duplicates));
			} catch(e)
			{
				console.log(e.response);
			}
		}
	}
	
	const toggleShowInstructions = () => {
		setShowInstructions(!showInstructions);
	}
	
	const toggleDontShow = () => {
		setDontShowWarning(!dontShowWarning);
	}
	
	const changeDuplicateScreen = (duplicate_screen) => {
		setDuplicateScreen(duplicate_screen);
	}

	useEffect(() => {
		if(rolePages)
		{
			const role_page_subscreens = rolePages['clean_data'].subscreens;
			const subscreen_index = role_page_subscreens.findIndex(temp_subscreen => temp_subscreen.subscreen === 'clean_duplicates');

			const is_complete = (subscreen_index !== -1) ? role_page_subscreens[subscreen_index].complete : false;
			setSubscreenIsComplete(is_complete);
		}
	}, [rolePages]);
	
	useEffect(() => {
		const getDuplicates = async () => {
			const data = {school_id:schoolInfo.school_id, data_version_id:schoolInfo.current_data_version_id};
			const apiName = process.env.REACT_APP_ENDPOINT_NAME;
			const url = '/admin/clean-data/get-duplicates';
			const myInit = { // OPTIONAL
				response: true,
				queryStringParameters: data,
			};
		
			try {
				const response = await API.get(apiName, url, myInit);
				const new_duplicates = response.data;
				
				localStorage.setItem('duplicates', JSON.stringify(new_duplicates));
				
				setDuplicates(new_duplicates);
				setDuplicatesLoaded(true);
			} catch(e)
			{
				console.log(e.response);
			}
		}

		const duplicates = getJSONFromStorage('duplicates', true);
		
		if(duplicates && !isEmpty(duplicates))
		{
			setDuplicates({...duplicates});
			setDuplicatesLoaded(true);
		}
		else
		{
			getDuplicates();
		}
	}, [schoolInfo.school_id, schoolInfo.current_data_version_id]);
	
	//console.log(duplicates);
	//console.log(appSubscreens);
	const current_user_is_admin = (userInfo.role_id === '1'|| userInfo.role_id === '2' || userInfo.role_id === '5');
	
	return (
		<>
		{(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):(current_user_is_admin) ?
		(
			<>
			<h1 className='progress-main-header'>Clean Dupicates</h1>
			<p className='progress-subheader'>Cleaning data upfront saves valuable scheduling time later.</p>
			<div id='clean-data-duplicate-main-container'>
				<div id='clean-data-duplicate-text' className='full-width'>
					<div>We&rsquo;ve found some data that could potentially be duplicate.</div>
					<div className='blue-link align-right' onClick={toggleShowInstructions}>{showInstructions ? 'Hide' : 'Show'} Instructions</div>
				</div>
				{showInstructions &&
					<div className='clean-data-instructions-container'>
						<h2 style={{margin:'0px'}}>Instructions</h2>
						<p>In order to move on to any future screens you must clear all duplicates, by either:</p>
						<div className='clean-data-instructions-options'>
							<div className='clean-data-instructions-option'>
								<h3>Ignoring Duplicates</h3>
								<p>If all the data looks okay, then clear the notification by pressing <img className='clean-data-btn-img' src={require('../../../images/this_is_okay_btn.png')} alt='this is okay button'/></p>
							</div>
							<div className='clean-data-instructions-option'>- OR -</div>
							<div className='clean-data-instructions-option'>
								<h3>Deleting Duplicates</h3>
								<p>If you find data you don&rsquo;t want, click <FontAwesomeIcon className='dark-blue-text' icon={faTrashAlt}/> to delete the duplicate data.</p>
								<p>Once you have deleted what you want, clear the notication by pressing <img className='clean-data-btn-img' src={require('../../../images/clear_btn.png')} alt='clear button'/></p>
							</div>
						</div>
					</div>
				}
				{!duplicatesLoaded &&
					<div style={{width:'100%', textAlign:'center'}}>
						<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'40px'}}/>
					</div>
				}
				{duplicatesLoaded &&
					<>
						<div className='clean-data-content-duplicates-header-container'>
							<div className='clean-data-duplicate-button-container'>
								<div className={`clean-data-duplicate-button ${duplicateScreen === 'courses' ? 'clean-data-duplicate-button-selected' : ''}`} onClick={() => changeDuplicateScreen('courses')}>
									<div className='clean-data-duplicate-btn-label'>Courses<div className='clean-data-duplicate-counter'>{Object.keys(duplicates.courses).length}</div></div>
								</div>
								<div className={`clean-data-duplicate-button ${duplicateScreen === 'teachers' ? 'clean-data-duplicate-button-selected' : ''}`} onClick={() => changeDuplicateScreen('teachers')}>
									<div className='clean-data-duplicate-btn-label'>Teachers<div className='clean-data-duplicate-counter'>{Object.keys(duplicates.teachers).length}</div></div>
								</div>
								<div className={`clean-data-duplicate-button ${duplicateScreen === 'classrooms' ? 'clean-data-duplicate-button-selected' : ''}`} onClick={() => changeDuplicateScreen('classrooms')}>
									<div className='clean-data-duplicate-btn-label'>Classrooms<div className='clean-data-duplicate-counter'>{Object.keys(duplicates.classrooms).length}</div></div>
								</div>
								<div className={`clean-data-duplicate-button ${duplicateScreen === 'students' ? 'clean-data-duplicate-button-selected' : ''}`} onClick={() => changeDuplicateScreen('students')}>
									<div className='clean-data-duplicate-btn-label'>Students<div className='clean-data-duplicate-counter'>{Object.keys(duplicates.students).length}</div></div>
								</div>
							</div>
						</div>
						{duplicates[duplicateScreen].length === 0 &&
							<div className='clean-data-no-data-container'>
								<FontAwesomeIcon className='clean-data-no-data-icon green-text' icon={faCheckCircle}/>
								<div>No Possible Duplicates Found</div>
							</div>
						}
						{duplicateScreen === 'courses' &&
							<TransitionGroup>
								{duplicates[duplicateScreen].map((duplicate_group, group_index) => {
									const num_nondeleted_data = duplicate_group.group.filter(data => data.is_deleted !== '1').length;
									
									return (
										<CSSTransition in={false} appear={false} exit={true} key={duplicate_group.group_id} timeout={500} classNames='duplicategroup'>
											<div className='duplicate-group-container'>
												<div className='duplicate-group-mark-as-okay no-select'>
													<div className='duplicate-group-mark-as-okay-btn' onClick={(e) => removeDuplicateGroup('courses', group_index)}>
														<div>{(num_nondeleted_data > 1) ? 'This is okay' : 'Clear'}</div>
														<div>
															<FontAwesomeIcon icon={faCheckCircle}/>
														</div>
													</div>
												</div>
												<div className='duplicate-group-row-header' style={{gridTemplateColumns:'3fr 1fr 1fr 1fr 2fr 80px'}}>
													<div>Course Name (Code)</div>
													<div>Elective</div>
													<div>Credits</div>
													<div>Semester</div>
													<div>Department</div>
													<div></div>
												</div>
												{duplicate_group.group.map((data_info, row_index) => (
													<div className={`duplicate-group-row ${data_info.is_deleted === '1' ? 'duplicate-group-row-deleted' : ''}`} key={row_index} style={{gridTemplateColumns:'3fr 1fr 1fr 1fr 2fr 80px'}}>
														<div className='red-text'>{data_info.name} ({data_info.course_code})</div>
														<div>{data_info.elective === '1' ? 'Yes' : 'No'}</div>
														<div>{data_info.credits}</div>
														<div>{data_info.semester}</div>
														<div>{data_info.department ? data_info.department : 'N/A'}</div>
														<div style={{justifySelf:'right', padding:'4px 10px 0px 0px'}}>
															{data_info.is_deleted === '1' &&
																<span className='red-text'>DELETED</span>
															}
															{data_info.is_deleted !== '1' &&
																<div className='tooltip' data-tooltip-text='Delete'>
																	<FontAwesomeIcon className='dark-blue-to-red-link' icon={faTrashAlt} onClick={(e) => removeDuplicate('courses', group_index, row_index, data_info.course_id, data_info)}/>
																</div>
															}
														</div>
													</div>
												))}
											</div>
										</CSSTransition>
									)
								})}
							</TransitionGroup>
						}
						{duplicateScreen === 'teachers' &&
							<TransitionGroup>
								{duplicates[duplicateScreen].map((duplicate_group, group_index) => {
									const num_nondeleted_data = duplicate_group.group.filter(data => data.is_deleted !== '1').length;
									
									return (
										<CSSTransition in={false} appear={false} exit={true} key={duplicate_group.group_id} timeout={500} classNames='duplicategroup'>
											<div className='duplicate-group-container'>
												<div className='duplicate-group-mark-as-okay'>
													<div className='duplicate-group-mark-as-okay-btn' onClick={(e) => removeDuplicateGroup('teachers', group_index)}>
														<div>{(num_nondeleted_data > 1) ? 'This is okay' : 'Clear'}</div>
														<div>
															<FontAwesomeIcon icon={faCheckCircle}/>
														</div>
													</div>
												</div>
												<div className='duplicate-group-row-header' style={{gridTemplateColumns:'2fr 2fr 1fr 80px'}}>
													<div>Last Name</div>
													<div>First Name</div>
													<div>Department</div>
													<div></div>
												</div>
												{duplicate_group.group.map((data_info, row_index) => (
													<div className={`duplicate-group-row ${data_info.is_deleted === '1' ? 'duplicate-group-row-deleted' : ''}`} key={row_index} style={{gridTemplateColumns:'2fr 2fr 1fr 80px'}}>
														<div className='red-text'>{data_info.name}</div>
														<div>{data_info.first_name}</div>
														<div>{data_info.department ? data_info.department : 'N/A'}</div>
														<div style={{justifySelf:'right', padding:'4px 10px 0px 0px'}}>
															{data_info.is_deleted === '1' &&
																<span className='red-text'>DELETED</span>
															}
															{data_info.is_deleted !== '1' &&
																<div className='tooltip' data-tooltip-text='Delete'>
																	<FontAwesomeIcon className='dark-blue-to-red-link' icon={faTrashAlt} onClick={(e) => removeDuplicate('teachers', group_index, row_index, data_info.teacher_id, data_info)}/>
																</div>
															}
														</div>
													</div>
												))}
											</div>
										</CSSTransition>
									)
								})}
							</TransitionGroup>
						}
						{duplicateScreen === 'classrooms' &&
							<TransitionGroup>
								{duplicates[duplicateScreen].map((duplicate_group, group_index) => {
									const num_nondeleted_data = duplicate_group.group.filter(data => data.is_deleted !== '1').length;
									
									return (
										<CSSTransition in={false} appear={false} exit={true} key={duplicate_group.group_id} timeout={500} classNames='duplicategroup'>
											<div className='duplicate-group-container'>
												<div className='duplicate-group-mark-as-okay'>
													<div className='duplicate-group-mark-as-okay-btn' onClick={(e) => removeDuplicateGroup('classrooms', group_index)}>
														<div>{(num_nondeleted_data > 1) ? 'This is okay' : 'Clear'}</div>
														<div>
															<FontAwesomeIcon icon={faCheckCircle}/>
														</div>
													</div>
												</div>
												<div className='duplicate-group-row-header' style={{gridTemplateColumns:'3fr 2fr 2fr 1fr 80px'}}>
													<div>Classroom</div>
													<div>Department</div>
													<div>Classroom Type</div>
													<div>Max Capacity</div>
													<div></div>
												</div>
												{duplicate_group.group.map((data_info, row_index) => (
													<div className={`duplicate-group-row ${data_info.is_deleted === '1' ? 'duplicate-group-row-deleted' : ''}`} key={row_index} style={{gridTemplateColumns:'3fr 2fr 2fr 1fr 80px'}}>
														<div className='red-text'>{data_info.classroom_name}</div>
														<div>{data_info.department ? data_info.department : 'N/A'}</div>
														<div>{data_info.classroom_type}</div>
														<div>{data_info.max_capacity}</div>
														<div style={{justifySelf:'right', padding:'4px 10px 0px 0px'}}>
															{data_info.is_deleted === '1' &&
																<span className='red-text'>DELETED</span>
															}
															{data_info.is_deleted !== '1' &&
																<div className='tooltip' data-tooltip-text='Delete'>
																	<FontAwesomeIcon className='dark-blue-to-red-link' icon={faTrashAlt} onClick={(e) => removeDuplicate('classrooms', group_index, row_index, data_info.classroom_id, data_info)}/>
																</div>
															}
														</div>
													</div>
												))}
											</div>
										</CSSTransition>
									)
								})}
							</TransitionGroup>
						}
						{duplicateScreen === 'students' &&
							<TransitionGroup>
								{duplicates[duplicateScreen].map((duplicate_group, group_index) => {
									const num_nondeleted_data = duplicate_group.group.filter(data => data.is_deleted !== '1').length;
									
									return (
										<CSSTransition in={false} appear={false} exit={true} key={duplicate_group.group_id} timeout={500} classNames='duplicategroup'>
											<div className='duplicate-group-container'>
												<div className='duplicate-group-mark-as-okay'>
													<div className='duplicate-group-mark-as-okay-btn' onClick={(e) => removeDuplicateGroup('students', group_index)}>
														<div>{(num_nondeleted_data > 1) ? 'This is okay' : 'Clear'}</div>
														<div>
															<FontAwesomeIcon icon={faCheckCircle}/>
														</div>
													</div>
												</div>
												<div className='duplicate-group-row-header' style={{gridTemplateColumns:'1fr 3fr 1fr 1fr 1fr 80px'}}>
													<div>Student Code</div>
													<div>Name</div>
													<div>Grade</div>
													<div>Gender</div>
													<div>Is Special Ed?</div>
													<div></div>
												</div>
												{duplicate_group.group.map((data_info, row_index) => (
													<div className={`duplicate-group-row ${data_info.is_deleted === '1' ? 'duplicate-group-row-deleted' : ''}`} key={row_index} style={{gridTemplateColumns:'1fr 3fr 1fr 1fr 1fr 80px'}}>
														<div className='red-text'>{data_info.student_code}</div>
														<div className='red-text'>{data_info.last_name}, {data_info.first_name}</div>
														<div>{data_info.grade}</div>
														<div>{data_info.gender}</div>
														<div>{data_info.is_special_ed === '1' ? 'Yes' : 'No'}</div>
														<div style={{justifySelf:'right', padding:'4px 10px 0px 0px'}}>
															{data_info.is_deleted === '1' &&
																<span className='red-text'>DELETED</span>
															}
															{data_info.is_deleted !== '1' &&
																<div className='tooltip' data-tooltip-text='Delete'>
																	<FontAwesomeIcon className='dark-blue-to-red-link' icon={faTrashAlt} onClick={(e) => removeDuplicate('students', group_index, row_index, data_info.id, data_info)}/>
																</div>
															}
														</div>
													</div>
												))}
											</div>
										</CSSTransition>
									)
								})}
							</TransitionGroup>
						}
						
					</>	
				}
			</div>
			</>
		): (userInfo && !current_user_is_admin && subscreenIsComplete) ?
		(
			<div className='locked-screen-notification-container'>
				<FontAwesomeIcon className='green-text' icon={faUnlock} style={{fontSize:'50px'}}/>
				<h1>Step is Complete!</h1>
				<p>The scheduling admin has finished cleaning duplicate data, and you can now move on to the next step!</p>
			</div>
		): (userInfo && !current_user_is_admin && !subscreenIsComplete) ?
		(
			<div className='locked-screen-notification-container'>
				<FontAwesomeIcon className='blue-text' icon={faLock} style={{fontSize:'50px'}}/>
				<h1>Temporarily Locked</h1>
				<p>This step is locked until the admin of this account has finished reviewing any duplicate data. You should be notified when this step is unlocked!</p>
			</div>
		): null
		}
		{(!subscreenLocked && !subscreenRestricted && current_user_is_admin && duplicatesLoaded && duplicates.courses.length === 0 && duplicates.teachers.length === 0 && duplicates.classrooms.length === 0 && duplicates.students.length === 0) &&
			<div className='fixed-bottom-save-container'>
				<Button classes={['btn', 'btn-extra-large', 'green-btn', 'align-right', 'move-to-next-screen-btn']} selected={false} text='Save & Continue' onClickFunction={handleMoveToNextScreen} />
			</div>
		}
		</>
	);
}