import React, { useState, useEffect } from 'react';
import { API } from "aws-amplify";
import Fuse from 'fuse.js';

import BulkEditCourseRequest from '../../BulkEditCourseRequest';

import { capitalizeFirstLetters, sortArrayOfObjects, recalculateMatchPercent } from '../../../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSearch, faSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare as farSquare } from '@fortawesome/free-regular-svg-icons';

export default function StudentRequests({ schoolInfo, scheduleInfo, sections, setSections, students, setStudents, courses, courseID }) {
	const [gradeLevels, setGradeLevels]= useState([]);
	const [gradesSelected, setGradesSelected] = useState([]);
	const [studentsByGradeSelected, setStudentsByGradeSelected] = useState([]);
	const [showOnlyStudentsWithoutRequest, setShowOnlyStudentsWithoutRequest] = useState(false);
	const [filterExistingStudentIDs, setFilterExistingStudentIDs] = useState([]);
	const [filterIDs, setFilterIDs] = useState([]);

	const [selectedStudents, setSelectedStudents] = useState([]);

	/////////////////////
	///// FUNCTIONS /////
	/////////////////////
	const fuzzySearch = (e, search_type) => {
		const search_value = e.target.value;

		const filtered_student_ids = [];
		
		// Search courses by name or code
		const students_fuse = new Fuse(students, {
			keys: ['last_name', 'first_name'],
			threshold: .1
		});

		const student_results = students_fuse.search(search_value);
		const subset_student_results = student_results.map(result => result.item).slice(0,4);

		subset_student_results.forEach(student => {
			const student_id = student.student_id;

			filtered_student_ids.push(student_id);
		});

		if(search_type === 'existing') setFilterExistingStudentIDs([...filtered_student_ids]);
		if(search_type === 'all') setFilterIDs([...filtered_student_ids]);
	}

	const toggleSelectStudent = (student_id) => {
		const student_selected_index = selectedStudents.findIndex(student => student === student_id);

		if(student_selected_index !== -1)
		{
			selectedStudents.splice(student_selected_index, 1);
		}
		else
		{
			selectedStudents.push(student_id);
		}

		setSelectedStudents([...selectedStudents]);
	}

	const toggleGradeSelected = (grade) => {
		const grade_currently_selected_index = gradesSelected.findIndex(selected_grade => selected_grade === grade);
		let new_students_by_grade_selected = [];

		if(grade_currently_selected_index !== -1)
		{
			new_students_by_grade_selected = studentsByGradeSelected.reduce((results, student_id) => {
				const student_info = students.find(student => student.student_id === student_id);
				const student_grade = student_info.grade;

				if(student_grade !== grade) results.push(student_id);
				return results;
			}, []);

			gradesSelected.splice(grade_currently_selected_index, 1);
		}
		else {
			new_students_by_grade_selected = students.reduce((results, student_info) => {
				const student_id = student_info.student_id;
				const student_grade = student_info.grade;

				if(!studentsByGradeSelected.includes(student_id) && student_grade === grade) results.push(student_id);
				return results;
			}, studentsByGradeSelected);

			gradesSelected.push(grade);
		}

		setStudentsByGradeSelected([...new_students_by_grade_selected]);
		setGradesSelected([...gradesSelected]);
	}

	const addRequest = async (student_ids) => {
		for(const student_id of student_ids)
		{
			const student_index = students.findIndex(student => student.student_id === student_id);

			if(student_index === -1) continue;

			const student_info = students[student_index];
			const student_requests = student_info.student_requests;

			const request_index = student_requests.findIndex(request => request.course_id === courseID);

			if(request_index !== -1)
			{
				student_info.student_requests[request_index].matched = '0';
				student_info.student_requests[request_index].data_origin = '2';
				student_info.student_requests[request_index].is_deleted = '0';
			}
			else
			{
				student_info.student_requests.push({course_id:courseID, matched:'0', priority:'1', data_origin:'2', is_deleted:'0'});
			}
		}

		setStudents([...students]);
		setSelectedStudents([]);

		const passed_data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, update_type:'add', course_id:courseID, student_ids:student_ids};
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/admin/update-requests'
		const myInit = { // OPTIONAL
			response: true,
			body: JSON.stringify(passed_data),
		};
		
		try {
			await API.post(apiName, url, myInit);

			recalculateMatchPercent({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id});
		} catch(e)
		{
			console.log(e);
		}
	}

	//// Set Grade Levels ////
	useEffect(() => {
		if(gradeLevels.length > 0) return;

		const grades = students.reduce((result, student) => {
			const student_grade = student.grade;
			if(result.findIndex(option => option === student_grade) === -1) result.push(student_grade);
			return result;
		}, []);
		
		grades.sort(function (a, b) {  return a - b;  }); // sort array numerically
		setGradeLevels(grades);
	}, [students, gradeLevels]);

	useEffect(() => {
		const filtered_student_ids = [];
		students.forEach(student => {
			const student_id = student.student_id;

			filtered_student_ids.push(student_id);
		});

		setFilterIDs([...filtered_student_ids]);
	}, [students]);

	const course_info = courses.find(course => course.course_id === courseID);
	const students_requesting_course = students.filter(student => (student.student_requests.findIndex(request => request.course_id === courseID && request.is_deleted === '0') !== -1));
	const sorted_students_requesting_course = (students_requesting_course.length > 0) ? sortArrayOfObjects(students_requesting_course, 'last_name', 'text', 'asc') : [];
	const request_type_count = students_requesting_course.reduce((results, student) => {
		const student_id = student.student_id;
		const request_info = student.student_requests.find(request => request.course_id === courseID);
		const is_alternate = (request_info.priority !== '1');
		const is_inclusion = (request_info.is_inclusion === '1');
		if(is_alternate) results.alternate_requests.push(student_id);
		if(is_inclusion) results.inclusion_requests.push(student_id);
		return results;
	}, {alternate_requests:[], inclusion_requests:[]});

	return (
		<>
			<h4>Student Requests</h4>
			<h1 className='report-name'>{capitalizeFirstLetters(course_info.name)} ({course_info.course_code})</h1>
			<div className='student-requests-course-container'>
				<div className='student-requests-add-request-container'>
					<div className='student-requests-subheader-container'>
						<h2 style={{margin:'0px'}}>Add Requests</h2>
					</div>
					<h3>By student:</h3>
					<div className='search-add-adding-input-container'>
						<input id='main-students-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'all')} placeholder='Search for student...'/>
						<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch} autoFocus={true}/>
					</div>
					<div id='student-requests-filter-students-with-request' onClick={() => setShowOnlyStudentsWithoutRequest(!showOnlyStudentsWithoutRequest)}>
						<FontAwesomeIcon icon={showOnlyStudentsWithoutRequest ? faCheckSquare : faSquare} className={showOnlyStudentsWithoutRequest ? 'fas-checkbox-checked' : 'fas-checkbox-unchecked'}/>
						<div>Only show students without this course request</div>
					</div>
					<div className='search-add-search-results' style={{maxHeight:'400px'}}>
						{students.map((student, i) => {
							const student_id = student.student_id;
							if(!filterIDs.includes(student_id)) return null;
							
							const student_already_requesting = (student.student_requests.findIndex(request => request.course_id === courseID && request.is_deleted === '0') !== -1);
							if(showOnlyStudentsWithoutRequest && student_already_requesting) return null;

							return (
								<div className='search-add-search-result student-requests-report-search-result' key={i}>
									<div>
										<div>{student.last_name}, {student.first_name}</div>
										<div className='small-text dark-gray-text'>{student.grade}th grade</div>
									</div>
									{student_already_requesting ?
										(
											<div className='small-text' style={{width:'60px'}}>Already requesting</div>
										):
										(
											<div className='btn btn-small blue-btn' onClick={() => addRequest([student_id])}>Add Request</div>
										)
										
									}
								</div>
							)
						})}
					</div>
					<h3>By grade:</h3>
					<div>
						{gradeLevels.map(grade => {
							const grade_selected = gradesSelected.includes(grade);

							return (
								<div className='student-requests-grade-row' key={grade} onClick={() => toggleGradeSelected(grade)}>
									<FontAwesomeIcon icon={grade_selected ? faCheckSquare : faSquare} className={grade_selected ? 'fas-checkbox-checked' : 'fas-checkbox-unchecked'}/>
									<div>{grade}</div>
								</div>
							)
						})}
						{studentsByGradeSelected.length > 0 &&
							<>
							<div className='student-requests-add-by-grade-student-list'>
								{studentsByGradeSelected.map((student_id, j) => {
										const student_info = students.find(student => student.student_id === student_id);

										if(filterExistingStudentIDs.length > 0 && !filterExistingStudentIDs.includes(student_id)) return null;

										const student_first_name = (student_info) ? student_info.first_name : null;
										const student_last_name = (student_info) ? student_info.last_name : null;
										const student_grade = (student_info) ? student_info.grade : null;

										return (
											<div className='student-requests-request-line' key={j}>
												<div>
													<div>{student_last_name}, {student_first_name}</div>
													<div className='small-text dark-gray-text'>{student_grade ? `${student_grade}th grade` : ''}</div>
												</div>
											</div>
										)
									})}
							</div>
							<div className='btn blue-btn' onClick={() => addRequest(studentsByGradeSelected)}>Add {studentsByGradeSelected.length} Student Requests</div>
							</>
						}
					</div>
				</div>
				<div className='student-requests-current-requests-container'>
					<div className='student-requests-subheader-container'>
						<h2 style={{margin:'0px'}}>Current Requests</h2>
						<div>
							<div className='medium-text'>{sorted_students_requesting_course.length} requests</div>
							{request_type_count.alternate_requests.length > 0 &&
								<div className='medium-text purple-text'>{request_type_count.alternate_requests.length} alternates</div>
							}
							{request_type_count.inclusion_requests.length > 0 &&
								<div className='medium-text orange-text'>{request_type_count.inclusion_requests.length} inclusion</div>
							}
						</div>
					</div>
					<div className='reports-search-bar-container'>
						<input className='reports-search-bar' placeholder={`Search for a student...`} defaultValue='' onChange={(e) => fuzzySearch(e, 'existing')}/>
						<FontAwesomeIcon className='reports-search-bar-icon' icon={faSearch}/>
					</div>
					<div style={{marginTop:'20px'}}>
						{sorted_students_requesting_course.length === 0 ?
							(
								<div className='conflict-matrix-no-data'>No current requests</div>
							):
							(
								<>
									<BulkEditCourseRequest courseID={courseID} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} courses={courses} students={students} setStudents={setStudents} sections={sections} setSections={setSections} selectedStudents={selectedStudents} setSelectedStudents={setSelectedStudents}/>
									<div className='modal-example-list'>
										{sorted_students_requesting_course.map((student, j) => {
											const student_id = student.student_id;

											if(filterExistingStudentIDs.length > 0 && !filterExistingStudentIDs.includes(student_id)) return null;

											const student_first_name = student.first_name;
											const student_last_name = student.last_name;
											// const student_requests = student.student_requests;
											// const request_info = student_requests.find(request => request.course_id === courseID);
											// const priority = request_info.priority;
											const student_request_is_alternate = request_type_count.alternate_requests.includes(student_id);
											const student_request_is_inclusion = request_type_count.inclusion_requests.includes(student_id);

											const student_selected = selectedStudents.includes(student_id);

											return (
												<div className='student-requests-request-line' key={j}>
													<FontAwesomeIcon className={student_selected ? 'blue-text' : 'dark-gray-text'} icon={student_selected ? faCheckSquare : farSquare} onClick={() => toggleSelectStudent(student_id)}/>
													<div>
														<div>
															{student_last_name}, {student_first_name} 
															{student_request_is_alternate &&
																<span className='small-text purple-text'>- Alternate </span>
															}
															{student_request_is_inclusion &&
																<span className='small-text orange-text'>- Inclusion </span>
															}
														</div>
														<div className='small-text dark-gray-text'>{student.grade}th grade</div>
													</div>
												</div>
											)
										})}
									</div>
								</>
							)
						}
					</div>
				</div>
			</div>
		</>
	);
}