import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import '../../css/documentation.css';

import GetStartedDocs from './documentation/GetStartedDocs';
import ScheduleStructureDocs from './documentation/ScheduleStructureDocs';
import UploadDataDocs from './documentation/UploadDataDocs';
import AssignResourcesDocs from './documentation/AssignResourcesDocs';
import CleanDataDocs from './documentation/CleanDataDocs';
import ReviewSectionsDocs from './documentation/ReviewSectionsDocs';
import PlaceSectionsDocs from './documentation/PlaceSectionsDocs';
import PlaceStudentsDocs from './documentation/PlaceStudentsDocs';

export default function Documentation({ match, isAuth, authComplete, schoolInfoLoading, userInfo, schoolType, setLoadScreen }) {
	let history = useHistory();
	
	const [isLoading, setIsLoading] = useState(true);
	const [screen, setScreen] = useState((!match.params.screen || match.params.screen === 'null') ? 'get_started' : match.params.screen);
	const [topic, setTopic] = useState((!match.params.topic || match.params.topic === 'null') ? null : match.params.topic);
	const [sis, setSis] = useState(match.params.sis);
	
	// Permissions 
	// 1 -> super_admin
	// 2 -> admin
	// 3 -> supervisors
	// 4 -> counselors
	const screens = [
		{screen:'get_started', name:'Getting Started', permissions:['1','2','3','4','5']}, 
		{screen:'schedule_structure', name:'Schedule Structure', permissions:['1','2','5']}, 
		{screen:'upload_data', name:'Uploading Data', permissions:['1','2','5']},  
		{screen:'assign_supervisors', name:'Department Supervisors', permissions:['1','2','5']}, 
		{screen:'clean_data', name:'Data Cleaning', permissions:['1','2','3','5']}, 
		{screen:'review_sections', name:'Create Sections', permissions:['1','2','3','5']}, 
		{screen:'place_sections', name:'Place Sections', permissions:['1','2','3','5']}, 
		{screen:'place_students', name:'Schedule Students', permissions:['1','2','3','5']}
	];
	
	const changeScreen = (new_screen) => {
		setTopic(null);
		setSis(null);
		setScreen(new_screen);
	}
	
	useEffect(() => {
		// Check if user is logged in
		// If not, send them to log in screen with a redirect to this screen
		if(authComplete && !isAuth)
		{
			history.push({
				pathname: '/app/login',
				state: {redirect: `/app/${schoolType}/documentation/account`}
			});
		}
		else if(authComplete && !schoolInfoLoading)
		{
			setLoadScreen('documentation');
			setIsLoading(false);
		}
	}, [isAuth, authComplete, schoolInfoLoading, history, schoolType, setLoadScreen]);
	
	return (
		<div id='documentation-main-container'>
			<div id='documentation-sidebar'>
				<div className='secondary-sidebar-header'>Docs</div>
				{screens.map((screen_option, i) => {
					const has_permission = (userInfo) ? screen_option.permissions.includes(userInfo.role_id) : false;
					
					return (
						<div key={i}>
						{has_permission && <Link className={`documentation-sidebar-btn ${screen_option.screen === screen ? 'documentation-sidebar-btn-selected' : ''}`} to={`/app/${schoolType}/documentation/${screen_option.screen}`} onClick={() => changeScreen(screen_option.screen)}>{screen_option.name}</Link>}
						</div>
					)
				})}
			</div>
			<div id='documentation-main-content'>
					<div id='documentation-main-content-inner'>
					{isLoading ?
						(
							<div className='center-text'>
								<img src={require('../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
							</div>
						):
						(
							<>
							{(() => {
								switch(screen) {
									case 'get_started':
										return <GetStartedDocs/>;
									case 'schedule_structure':
										return <ScheduleStructureDocs topic={topic} setTopic={setTopic} schoolType={schoolType}/>;
									case 'upload_data':
										return <UploadDataDocs topic={topic} setTopic={setTopic} sis={sis} setSis={setSis} schoolType={schoolType}/>;
									case 'assign_supervisors':
										return <AssignResourcesDocs topic={topic} setTopic={setTopic} userInfo={userInfo} schoolType={schoolType}/>;
									case 'clean_data':
										return <CleanDataDocs topic={topic} setTopic={setTopic} userInfo={userInfo} schoolType={schoolType}/>;
									case 'review_sections':
										return <ReviewSectionsDocs topic={topic} setTopic={setTopic} userInfo={userInfo} schoolType={schoolType}/>;
									case 'place_sections':
										return <PlaceSectionsDocs topic={topic} setTopic={setTopic} userInfo={userInfo} schoolType={schoolType}/>;
									case 'place_students':
										return <PlaceStudentsDocs topic={topic} setTopic={setTopic} userInfo={userInfo} schoolType={schoolType}/>;
									default:
										return null;
								}
							})()}
							</>
						)
					}
				</div>
			</div>
		</div>
	);
}