import React, { useState , useEffect } from 'react';
import { Link } from "react-router-dom";

import { getData, getSectionConnections } from '../../../js/Helpers';
import { FileUpload } from '../../../js/upload';

import UploadModal from '../../UploadModal';
import DatabaseDataSections from '../school_data/database_data_sections';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faQuestionCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

export default function UploadSections({ subscreenRestricted, subscreenLocked, subscreen, currentSIS, schoolInfo, scheduleInfo, scheduleStructureData, lunchInfo, courses, setCourses, teachers, setTeachers, classrooms, students, setStudents, sectionsLoading, setSectionsLoading, sections, setSections, setSubsections, departments, labels, setLabels, handleMoveToNextScreen}) {
	const [sectionDataExists, setSectionDataExists] = useState(false);

	const [alreadyUploadedChecked, setAlreadyUploadedChecked] = useState(false);
	const [alreadyUploaded, setAlreadyUploaded] = useState(false);
	
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [showSectionData, setShowSectionData] = useState(false);

	const setDataExists = (value) => {
		setSectionDataExists(value);
	}

	const handlePullNewData = () => {
		getData('sections', '/get-sections', {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_version_id:schoolInfo.current_data_version_id, database:'edario'}, true).then(returned_data => {
			setLabels(returned_data.labels);
			setSubsections(returned_data.subsections);
			setSections(returned_data.sections);
			
			setSectionsLoading(false);
		});


		setDataExists(true);
		setAlreadyUploadedChecked(true);
		setAlreadyUploaded(true);
	}
	
	////////////////////////////////
	///// USE EFFECT FUNCTIONS /////
	////////////////////////////////
	useEffect(() => {
		if(schoolInfo.current_data_version_id && schoolInfo.school_id)
		{
			async function checkUploadAlreadyProcessed() {
				const file_upload = new FileUpload(schoolInfo.current_data_version_id, schoolInfo.current_schedule_version_id, scheduleInfo.school_id);
			
				// Check whether courses have been uploaded
				const running_processes = await file_upload.getSchoolProcesses(schoolInfo.current_schedule_year_id);

				// Check if there are any running processes with the same data version id
				const dv_process_index = running_processes.findIndex(process => process.process_type === 'previousdata' && process.data_version_id == schoolInfo.current_data_version_id && process.is_done);
				const sv_process_index = running_processes.findIndex(process => process.process_type === 'previousdata' && process.schedule_version_id == schoolInfo.current_schedule_version_id && process.is_done);

				// If process has already finished running then set data existing
				if(sv_process_index !== -1 || dv_process_index !== -1) setAlreadyUploaded(true);

				setAlreadyUploadedChecked(true);
			}

			checkUploadAlreadyProcessed();
		}
	}, [scheduleInfo, schoolInfo, subscreen]);

	useEffect(() => {
		if(sections.length > 0) setSectionDataExists(true);
	}, [sections]);
	
	return (
		<>
		{(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):
		(
			<>
			{showUploadModal &&
				<UploadModal schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} uploadTitle='Sections' uploadAPIName='importsections' closeModal={() => setShowUploadModal(false)} handlePullNewData={handlePullNewData}/>
			}
			<h1 className='progress-main-header'>Upload Previous Sections</h1>
			<p className='progress-subheader'>We will analyze your previous sections to learn about the constraints on your schedule.</p>
			<div className="upload_screen">
				<div className='upload-screen-explanation-container'>
					<h2 className="upload-screen-heading-text"><span className="ms_login_system_name capitalize">{currentSIS}</span> Previous Sections Upload</h2>
					<p className='upload-screen-description'>Now we&rsquo;ll use data from previous year&rsquo;s sections in <span className="ms_login_system_name capitalize">{currentSIS}</span>:</p>
					<div className='upload-screen-data-container'>
						<img className='upload-info-image' src={require(`../../../images/classroom1.png`)} alt='classrooms'/>
						<div className='upload-info-name'>Sections</div>
						{sectionDataExists ?
							(
								<>
								<div>
									<FontAwesomeIcon icon={faCheckCircle} className='green-text upload-complete'/>
									<div className='upload-complete-text'>Complete</div>
								</div>
								<div className='btn btn-small blue-btn' onClick={()=>{setShowSectionData(!showSectionData)}}>See Data</div>
								</>
							):
							(
								<>
								<div>
									<FontAwesomeIcon icon={faCheckCircle} className='gray-text upload-complete'/>
									<div className='upload-complete-text gray-text'>No Data</div>
								</div>
								<div className='white-text'>.</div>
								</>
							)
						}
					</div>
					<div>
						{!alreadyUploadedChecked ?
							(
								<div className='center-text'>
									<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
								</div>
							): (alreadyUploaded)  ? 
							(
								<div className='upload-screen-already-uploaded-container'>
										<div>
											<FontAwesomeIcon className='upload-screen-already-uploaded-check green-text' icon={faCheckCircle}/>
										</div>
										<div>
											<h3 className='upload-screen-already-uploaded-header'>You already uploaded sections!</h3>
											<div>You can move on to the next step, or <span className='blue-link' onClick={() => setShowUploadModal(true)}>Reupload Sections</span>!</div>
										</div>
									</div>
							):
							(
								<div className='center-text'>
									<span className='btn turquoise-btn btn-round btn-large upload-btn' onClick={() => setShowUploadModal(true)}>Upload Sections</span>
								</div>
							)
						}
					</div>
					<p className='upload-how-to-line'><FontAwesomeIcon className='info-icon blue-text' icon={faQuestionCircle}/> <Link to={`/app/hsms/documentation/upload_data/upload_previous_sections/${currentSIS}`} target="_blank" style={{color:'#3498db'}}>How to download sections from <span className="ms_login_system_name capitalize">{currentSIS}</span></Link></p>
				</div>
				{showSectionData &&
					<div className='upload-parent-container'>
						<div className='upload-screen-close-data gray-to-dark-blue-link' onClick={() => {setShowSectionData(false)}}>
							<FontAwesomeIcon icon={faTimesCircle}/>
						</div>
						<DatabaseDataSections schoolInfo={schoolInfo} databaseData={sections} setDatabaseData={setSections} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} courses={courses} setCourses={setCourses} teachers={teachers} setTeachers={setTeachers} classrooms={classrooms} students={students} setStudents={setStudents} departments={departments} sections={sections} setSections={setSections} labels={labels} setLabels={setLabels} lunchInfo={lunchInfo} dataIsLoading={sectionsLoading}/>
					</div>
				}
			</div>
			<div className='fixed-bottom-save-container'>
				<div className="btn btn-extra-large green-btn align-right move-to-next-screen-btn move-to-next-screen-btn" onClick={handleMoveToNextScreen}>Go to Next Step: Upload Courses</div>
			</div>
			</>
		)}
		</>
	);
}