import React, { useState , useEffect } from 'react';
import Fuse from 'fuse.js';
import swal from '@sweetalert/with-react';

import Dropdown from '../../Dropdown';

import { sortArrayOfObjects, loadSpecificData } from '../../../js/Helpers';
import { saveDataToDatabase } from '../../../js/SaveFunctions';

import '../../../css/hsms/edit_modals.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes, faPlusCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-regular-svg-icons';

export default function EditStudentData({ dataID, toggleEditData, schoolInfo, teachers, students, courses, setDatabaseData, pulloutGroups, setPulloutGroups }) {
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState({});

	const [teacherOptions, setTeacherOptions] = useState([]);
	
	const [addStudentPulloutRequirementOpen, setAddStudentPulloutRequirementOpen] = useState(false);
	const [studentPulloutRequirementResults, setStudentPulloutRequirementResults] = useState([]);

	const [changesMade, setChangesMade] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	////////////////////////////////
	///// SET DROPDOWN OPTIONS /////
	////////////////////////////////
	const default_grades = [{display:'K', value:'0'}, {display:'1', value:'1'}, {display:'2', value:'2'}, {display:'3', value:'3'}, {display:'4', value:'4'}];
	const grades = (students.length > 0) ? students.reduce((result, student) => {
		const student_grade = student.grade;
		if(!student_grade) return result;

		if(result.findIndex(option => option.value === student_grade) === -1) result.push({display:student_grade, value:student_grade});
		return result;
	}, default_grades) : default_grades;
	const sorted_grades = sortArrayOfObjects(grades, 'value', 'number', 'asc');

	const genders = [{value:'M', display:'Male'}, {value:'F', display:'Female'}, {value:'O', display: 'Other'}];

	const toggleAddNewConnection = (connection_type) => {
		if(connection_type === 'student_pullout_requirement')
		{
			setAddStudentPulloutRequirementOpen(!addStudentPulloutRequirementOpen);
			setStudentPulloutRequirementResults([]);
		}
	}

	const fuzzySearch = (e, dataType) => {
		const search_value = e.target.value;
		
		if(dataType === 'courses')
		{
			const fuse = new Fuse(courses, {
				keys: ['name', 'course_code'],
				threshold: .1
			});
			
			const results = fuse.search(search_value);
			const student_results = results.map(result => result.item);
			
			setStudentPulloutRequirementResults(student_results);
		}
	}
	
	const updateData = (change_type, data_id, data_key) => {
		if(change_type === 'add')
		{
			data[data_key].push(data_id);
		}
		else if(change_type === 'change')
		{
			data[data_key] = data_id;
		}
		else if(change_type === 'remove')
		{
			data[data_key].splice(data_id, 1);
		}
		
		setData({...data});
		setChangesMade(true);
	}
	
	const handleExitWithoutSaving = async () => {
		let exit_without_saving = true;
		
		if(changesMade)
		{
			const options =  {
				title: "Exit without saving?",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Cancel",
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "Yes",
						value: true,
						visible: true,
						className: 'blue-btn'
					},
				},
				content: (
					<div>
						<p>You have changes that have not been saved. Do you really want to exit without saving?</p>
					</div>
				)
			}
		
			exit_without_saving = await swal(options);
		}
		
		if(exit_without_saving) toggleEditData();
	}
	
	const updateStudent = () => {
		const student_code = data.student_code;
		const first_name = data.first_name;
		const last_name = data.last_name;
		const gender = data.gender;
		const grade = data.grade;
		
		if(!student_code || !first_name || !last_name || !gender || !grade)
		{
			const options =  {
				title: "Missing Data",
				icon:"warning",
				buttons: {
					cancel: {
						text: "Okay",
						value: false,
						visible: true,
						className: 'blue-btn'
					}
				},
				content: (
					<div className='text-align-left'>
						<p>You are missing data! Make sure you have the following data before saving:</p>
						<ul>
							<li>Student Code</li>
							<li>First Name</li>
							<li>Last Name</li>
							<li>Gender</li>
							<li>Grade</li>
						</ul>
					</div>
				)
			}
		
			swal(options);
		}
		else
		{
			setIsSaving(true);
			
			saveDataToDatabase({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'student', data_to_save:data, database:'elementary'}).then(response_data => {
				// change data student id to new database id
				data.student_id = response_data.new_data_id;
				
				if(dataID)
				{
					const index_of_specific_data = students.findIndex(student => student.student_id === dataID);
					students[index_of_specific_data] = data;
					students[index_of_specific_data].is_deleted = '0';
				}
				else
				{
					students.push(data);
				}
				
				setDatabaseData([...students]);
				setIsSaving(false);
				toggleEditData();
			});
		}
	}
	
	/////////////////////////////
	///// DO ON CHANGE ////////
	/////////////////////////////
	useEffect(() => {
		const teacher_options = teachers.reduce((results, teacher) => {
			const teacher_id = teacher.teacher_id;
			const teacher_first_name = teacher.first_name;
			const teacher_last_name = teacher.name;
			const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name; 

			const new_option = {value:teacher_id, display:teacher_full_name};
			results.push(new_option);
			return results;
		}, [{value:null, display:'No Homeroom Teacher'}]);

		setTeacherOptions(teacher_options);
	}, [teachers]);
	
	/////////////////////////
	///// DO ON LOAD ////////
	/////////////////////////
	useEffect(() => {
		if(dataID)
		{
			loadSpecificData({data_type:'student', data_id:dataID, data_version_id:schoolInfo.current_data_version_id, schedule_version_id:schoolInfo.current_schedule_version_id, school_id:schoolInfo.school_id, database:'elementary'}).then(specific_data => {
				const index_of_specific_data = students.findIndex(student => student.student_id === dataID);
				students[index_of_specific_data] = specific_data;

				setDatabaseData(students);
				setData(specific_data);
				setIsLoading(false);
			});
		}
		else
		{
			setData({new: true, first_name: null, gender: null, grade: null, homeroom_teacher_id:null, is_special_ed: "0", last_name: null, state_id: null, student_code: null, student_id: null, pullout_groups:[], student_locked_blocks:[]});
			setIsLoading(false);
		}
	}, [dataID, schoolInfo.current_data_version_id, schoolInfo.current_schedule_version_id, schoolInfo.school_id, students]);
	
	//console.log({dataID, toggleEditData, students, courses, teachers, sections});
	console.log(data);
	
	return (
		<div className='modal-screen'>
			<div className='modal-screen-content'>
				{!isSaving &&
					<>
					<span className="data-modal-close" onClick={handleExitWithoutSaving}>&times;</span>
					<div className="modal-save-btn" onClick={updateStudent}><FontAwesomeIcon icon={faSave}/>Save Student</div>
					</>
				}
				<div className='full-width'>
					<div className='edit-data-content'>
						<FontAwesomeIcon className='edit-data-main-head-icon' icon={faEdit}/>
						<h1 className='edit-data-main-header'>{dataID ? 'Edit' : 'Add New'} Student</h1>
						{isSaving &&
							<div className='dashboard-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
								<div>Saving...</div>
							</div>
						}
						{isLoading &&
							<div className='dashboard-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
							</div>
						}
						{(!isLoading && !isSaving) &&
							<form className='edit-data-form'>
								<div className='edit-data-grid-33' style={{gridTemplateColumns:'200px 1fr 1fr'}}>
									<div>
										<p className='modal-label'>Student ID/Code:</p>
										<input type="text" className='edit-data-input' defaultValue={data.student_code} onChange={(e) => updateData('change', e.target.value, 'student_code')}/>
									</div>
									<div>
										<p className='modal-label'>Last Name:</p>
										<input type="text" className='edit-data-input' defaultValue={data.last_name} onChange={(e) => updateData('change', e.target.value, 'last_name')}/>
									</div>
									<div>
										<p className='modal-label'>First Name:</p>
										<input type="text" className='edit-data-input' defaultValue={data.first_name} onChange={(e) => updateData('change', e.target.value, 'first_name')}/>
									</div>
								</div>
								<div className='edit-data-grid-50'>
									<div>
										<div className='data-modal-heading'>Grade</div>
										<div id="student-grade-container">
											{sorted_grades.map((grade_info, index) => {
												return (
													<div className={`option-btn ${grade_info.value === data.grade ? 'option-btn-selected' : ''}`} key={index} onClick={() => updateData('change', grade_info.value, 'grade')}>{grade_info.display}</div>
												)	
											})}
										</div>
									</div>
									<div>
										<div className='data-modal-heading'>Gender</div>
										<div id="student-gender-type-container">
											{genders.map((gender_info, index) => {
												return (
													<div className={`option-btn ${gender_info.value === data.gender ? 'option-btn-selected' : ''}`} key={index} onClick={() => updateData('change', gender_info.value, 'gender')}>{gender_info.display}</div>
												)	
											})}
										</div>
									</div>
								</div>
								<div>
									<div className='data-modal-heading'>Homeroom Teacher</div>
									<div className='edit-data-dropdown-container'>
										<Dropdown data={teacherOptions} displayDefault='No Homeroom Teacher' currentValue={data.homeroom_teacher_id} handleChange={(new_value) => updateData('change', new_value, 'homeroom_teacher_id')} />
									</div>
								</div>
								{/* <div>
									<div className='data-modal-heading'>Pullout Course(s) Needed</div>
									<div>
										{data.pullout_groups.length === 0 &&
											<div className='edit-data-no-data-container'>No courses set yet!</div>
										}
										{data.pullout_groups.map((course_id, index) => {
											const course_info = courses.find(course => course.course_id === course_id);

											if(!course_info) return null;
											
											return (
												<div className='table-row-inv edit-data-course-row' key={index}>
													<div>{course_info.name} {course_info.course_code && `(${course_info.course_code})`}</div>
													<div></div>
													<div className="remove-data" onClick={() => updateData('remove', index, 'pullout_groups')}><FontAwesomeIcon icon={faTimes}/></div>
												</div>
											)	
										})}
									</div>
									<div className='edit-data-add-new-btn blue-link' onClick={() => toggleAddNewConnection('student_pullout_requirement')}>
										<FontAwesomeIcon icon={faPlusCircle}/>
										<div>Add Course</div>
									</div>
									{addStudentPulloutRequirementOpen &&
										<div className='search-add-row-container'>
											<div className='search-add-search-container'>
												<div className='search-add-adding-input-container'>
													<input id='search-tct-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'courses')} placeholder='Search for student'/>
													<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
												</div>
												<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('student_pullout_requirement')}/>
											</div>
											{studentPulloutRequirementResults.length !== 0 &&
												<div className='search-add-search-results'>
													<div className='search-add-did-you-mean'>Did you mean:</div>
													{studentPulloutRequirementResults.map((course, i) => {
														return (
															<div className='search-add-search-result' key={i}>
																<div>{course.name} {course.course_code && `(${course.course_code})`}</div>
																<div>
																	<div className='search-add-add-btn' onClick={() => updateData('add', course.course_id, 'pullout_groups')}>Add</div>
																</div>
															</div>
														)
													})}
												</div>
											}
										</div>
									}
								</div> */}
							</form>
						}
					</div>
				</div>
			</div>
		</div>
	);
}