import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog, faUsers } from '@fortawesome/free-solid-svg-icons';

import '../../css/settings.css';

import Account from './settings/Account';
import Users from './settings/Users';

export default function Settings({ location, isAuth, authComplete, currentSchoolID, userInfo, setUserInfo, users, setUsers, schoolInfo, schoolInfoLoading, setSchoolInfo, schoolType, setLoadScreen}) {
	let history = useHistory();
	
	const [isLoading, setIsLoading] = useState(true);
	
	const [page, setPage] = useState('account');
	const redirectURL = location.redirect;
	const previousLoadScreen = location.load_screen;
	
	const pages = [
		{page:'account', name:'Account', icon:faUserCog, permissions:['1','2', '3', '4', '5']}, 
		{page:'users', name:'Users', icon:faUsers, permissions:['1','2']}
	];
	
	//////////////////////////////
	///// DO ON STATE CHANGE /////
	//////////////////////////////
	useEffect(() => {
		// Check if user is logged in
		// If not, send them to log in screen with a redirect to this screen
		if(authComplete && !isAuth)
		{
			history.push({
				pathname: '/app/login',
				state: {redirect: `/app/${schoolType}/settings/account`}
			});
		}
		else if(authComplete && !schoolInfoLoading)
		{
			setLoadScreen('settings');
			setIsLoading(false);
		}
	}, [isAuth, authComplete, schoolInfoLoading, history, schoolType, setLoadScreen]);
	
	// Don't allow non-admins to access creating user information
	if(page === 'users' && userInfo.role_id && (userInfo.role_id === '3' || userInfo.role_id === '4' || userInfo.role_id === '5'))
	{
		setPage('account');
		location.pathname= `/app/${schoolType}/settings/account`;
	}
	
	return (
		<div id='settings-main-container'>
			<div id='settings-sidebar'>
				<div className='secondary-sidebar-header'>Settings</div>
				{pages.map((page_info, i) => {
					const user_has_permission = page_info.permissions.includes(userInfo.role_id);
					
					// Don't show users page if there is no current school ID
					if(page_info.page === 'users' && !currentSchoolID) return null;
					
					return (
						<React.Fragment key={i}>
						{user_has_permission && 
							<Link className={`settings-sidebar-btn ${page_info.page === page ? 'settings-sidebar-btn-selected' : ''}`} to={{pathname:`/app/${schoolType}/settings/${page_info.page}`, redirect:redirectURL}} onClick={() => setPage(page_info.page)}><FontAwesomeIcon className='sidebar-btn-icon' icon={page_info.icon}/>{page_info.name}</Link>
						}
						</React.Fragment>
					)
				})}
			</div>
			<div id='settings-main-content'>
				<div id='settings-main-content-inner'>
				{isLoading ?
					(
						<div className='center-text'>
							<img src={require('../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
						</div>
					):
					(
						<>
						{(() => {
							switch(page) {
								case 'account':
									return <Account redirectURL={redirectURL} previousLoadScreen={previousLoadScreen} currentSchoolID={currentSchoolID} userInfo={userInfo} setUserInfo={setUserInfo} schoolInfo={schoolInfo} schoolInfoLoading={schoolInfoLoading} setSchoolInfo={setSchoolInfo} setLoadScreen={setLoadScreen}/>;
								case 'users':
									return <Users redirectURL={redirectURL} previousLoadScreen={previousLoadScreen} currentSchoolID={currentSchoolID} users={users} setUsers={setUsers} schoolInfo={schoolInfo} setLoadScreen={setLoadScreen}/>;
								default:
									return null;
							}
						})()}
						</>
					)
				}
				</div>
			</div>
		</div>
	);
}