import React, { useState , useRef, useEffect } from 'react';
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import Fuse from 'fuse.js';

import DepartmentProgressScreen from '../department_progress_screen';
import EditCourseData from '../../modals/edit_course';
import Dropdown from '../../../Dropdown';
import Button from '../../../Button';

import { capitalizeFirstLetters, useOutsideClick, getData } from '../../../../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUserCircle, faChalkboardTeacher, faHourglassHalf, faCheckCircle, faCheck, faSearch, faTimes, faExclamationCircle, faTrashAlt, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default function CleanCourseTeachers({isLoading, subscreenRestricted, subscreenLocked, subscreen, subscreenResponsibleRoleID, supervisors, openSupervisorsList, closeSupervisorsList, addSupervisorToDepartment, removeSupervisorFromDepartment, users, userIsSupervisor, userIsAdmin, departments, setDepartments, departmentID, sortedDepartments, changeDepartment, userInfo, schoolInfo, schoolType, scheduleInfo, courses, setCourses, teachers, classrooms, students, setStudents, sections, labels, scheduleStructureData, addNewCourseOpen, setAddNewCourseOpen, subscreenSelect, submitDepartment, reviewing, toggleReviewDepartment, changeDepartmentResource, setRolePages, showNextButton, handleMoveToNextScreen, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo}) {
	const ref = useRef();
	
	const [addCourseOpen, setAddCourseOpen] = useState(false);
	const [courseResults, setCourseResults] = useState([...courses]);
	
	const [changesSavedMessage, setChangesSavedMessage] = useState(false);
	
	/////////////////////////////
	///// UTILITY FUNCTIONS /////
	/////////////////////////////
	useOutsideClick(ref, () => {
		closeSupervisorsList();
	});

	const addSearch = (e) => {
		const search_value = e.target.value;
		const fuse = new Fuse(courses, {
			keys: ['name', 'course_code'],
			threshold: .6
		})
		
		const results = fuse.search(search_value);
		const course_results = results.map(result => result.item).splice(0,5);
		
		setCourseResults(course_results);
	}
	
	////////////////////////////
	///// UPDATE FUNCTIONS /////
	////////////////////////////
	const updateCourseTeachers = async (course_id, update_type, teacher_id) => {
		const parentDiv = document.querySelectorAll(`.clean-data-connection-container[data-courseid='${course_id}'][data-teacherid='${teacher_id}']`)[0];
		
		const tct_type = (update_type === 'update') ? parentDiv.querySelector('.clean-data-connection-details').querySelector('.dropdown-display').getAttribute('data-value') : '1';
		
		const data = {school_id: schoolInfo.school_id, department_id:departmentID, schedule_version_id:schoolInfo.current_schedule_version_id, course_id:course_id, update_type:update_type, teacher_id:teacher_id, tct_type:tct_type};
		
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/admin/clean-data/update-course-teachers';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			await API.post(apiName, url, myInit);
			
			///////////////////////
			///// UPDATE JSON /////
			///////////////////////
			const department_index = departments.findIndex(department => department.department_id === departmentID);

			if(userIsSupervisor) 
			{
				if(department_index !== -1)
				{
					departments[department_index].subscreens[subscreen].submitted = false;
					departments[department_index].subscreens[subscreen].confirmed = false;
				}

				// Reset overall progress to update the Progress screen
				getData('schoolProgress', '/get-school-progress', {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true).then(user_progress_data => {
					const role_pages = user_progress_data.role_pages;
					
					setRolePages(role_pages);
				});
			}

			if(userIsAdmin && departments[department_index].subscreens[subscreen].confirmed) setChangesSavedMessage(true);
			
			const course_index = courses.findIndex(course => course.course_id === course_id);
			const course_info = courses[course_index];
			
			const teachers_can_teach = course_info.teachers_can_teach;
			
			if(update_type === 'remove')
			{
				const new_teachers_can_teach = teachers_can_teach.filter(tct_info => tct_info.teacher_id !== teacher_id);
				course_info.teachers_can_teach = new_teachers_can_teach;
			}
			else
			{
				// Check if course already has teacher attached
				// If yes, update type
				// If no, add teacher
				const teacher_index = teachers_can_teach.findIndex(tct_info => tct_info.teacher_id === teacher_id);
				
				if(teacher_index !== -1)
				{
					teachers_can_teach[teacher_index]['type'] = tct_type;
				}
				else
				{
					teachers_can_teach.push({teacher_id:teacher_id, type:tct_type});
				}
			}
			
			delete courses[course_index]['searching'];
			setCourses([...courses]);
			setDepartments([...departments]);
		} catch(e)
		{
			console.log(e.response);
		}
	}

	const updateCourse = (changeType, dataType, data_id, old_department_id, new_department_id) => {
		changeDepartmentResource(changeType, dataType, data_id, old_department_id, new_department_id, () => {
			if(changeType === 'add' && userIsSupervisor) 
			{
				const department_index = departments.findIndex(department => department.department_id === departmentID);

				if(department_index !== -1)
				{
					departments[department_index].subscreens[subscreen].submitted = false;
					departments[department_index].subscreens[subscreen].confirmed = false;
				}
				
				// Update cleaning screen progress (for check marks)
				departments[department_index].subscreens['course_availability'].submitted = false;
				departments[department_index].subscreens['course_availability'].confirmed = false;

				departments[department_index].subscreens['course_caps'].submitted = false;
				departments[department_index].subscreens['course_caps'].confirmed = false;

				departments[department_index].subscreens['course_options'].submitted = false;
				departments[department_index].subscreens['course_options'].confirmed = false;

				departments[department_index].subscreens['course_other_options'].submitted = false;
				departments[department_index].subscreens['course_other_options'].confirmed = false;

				departments[department_index].subscreens['course_teachers'].submitted = false;
				departments[department_index].subscreens['course_teachers'].confirmed = false;

				departments[department_index].subscreens['course_classrooms'].submitted = false;
				departments[department_index].subscreens['course_classrooms'].confirmed = false;

				setDepartments([...departments]);

				// Reset overall progress to update the Progress screen
				getData('schoolProgress', '/get-school-progress', {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true).then(user_progress_data => {
					const role_pages = user_progress_data.role_pages;
					
					setRolePages(role_pages);
				});
			}
		});
	}
	
	const toggleAddNewData = (course_id) => {
		const course_index = courses.findIndex(course => course.course_id === course_id);
		const course_info = courses[course_index];
		
		if('searching' in course_info) 
		{
			delete courses[course_index]['searching'];
		}
		else
		{
			courses[course_index]['searching'] = true;
		}
		
		delete courses[course_index]['department_teacher_results'];
		delete courses[course_index]['other_teacher_results'];
		
		setCourses([...courses]);
	}
	
	const fuzzySearch = (e, course_id) => {
		const search_value = e.target.value;
		
		const fuse = new Fuse(teachers, {
			keys: ['name', 'first_name'],
			threshold: .6
		})
		
		const results = fuse.search(search_value);
		const teacher_results = results.map(result => result.item);
		
		const department_teacher_results = teacher_results.filter(teacher => teacher.departments.includes(departmentID)).splice(0, 5);
		const other_teacher_results = teacher_results.filter(teacher => !teacher.departments.includes(departmentID)).splice(0, 5);
		
		const course_index = courses.findIndex(course => course.course_id === course_id);
		const course_info = courses[course_index];
		
		course_info['department_teacher_results'] = department_teacher_results;
		course_info['other_teacher_results'] = other_teacher_results;
		setCourses([...courses]);
	}
	
	//////////////////////////////
	//// USE EFFECT FUNCTIONS ////
	//////////////////////////////
	useEffect(() => {
		if(!departmentID || !userInfo || !users || !departments) return;

		const department_index = departments.findIndex(department => department.department_id === departmentID);
		const department_info = (department_index !== -1) ? departments[department_index] : null;
		const department_supervisors = (department_info) ? department_info.supervisors : [];

		const available_users = users.filter(user => (user.role_id === '1' || user.role_id === '2') || department_supervisors.findIndex(supervisor => supervisor.user_id === user.user_id) !== -1);

		setScreenAllowsUniversalSidebar(true);
		setUniversalSidebarLoadingInfo({sidebar_name:"Course Teacher Notes", sidebar_type:'notes', subscreen:'course_teachers', department_id:departmentID, user_id:userInfo.user_id, available_users:available_users, help_link_path:'/app/hsms/documentation/clean_data/clean_department_data'});
	}, [departmentID, userInfo, users, departments, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo]);
	
	const tctTypeOptions = [
		{value:'1', display: 'Primary'},
		{value:'2', display: 'Back Up'},
	];
	
	const row_style = {gridTemplateColumns:'1fr 1fr auto', gridColumnGap:'15px'};
	
	const department_index = departments.findIndex(department => department.department_id === departmentID);
	const department_info = departments[department_index];
	const subscreen_info = (department_info) ? department_info.subscreens[subscreen] : null;
	
	const current_screen_submitted = (subscreen_info) ? subscreen_info.submitted : false;
	const current_screen_confirmed = (subscreen_info) ? subscreen_info.confirmed : false;
	const current_screen_unlocked= (subscreen_info) ? subscreen_info.unlocked : false;
	
	const show_current_screen = (current_screen_unlocked && (reviewing || (userIsSupervisor && !current_screen_submitted) || (userIsAdmin && current_screen_submitted && !current_screen_confirmed) ||(userIsAdmin && !current_screen_submitted && department_info.supervisors.length === 0)));
	const screen_complete_for_user = ((current_screen_confirmed) || (userIsSupervisor && !userIsAdmin && current_screen_submitted));
	
	const department_courses = courses.filter(course => course.departments.includes(departmentID));
	
	return (
		<>
		<h1 className='progress-main-header'>Course Teachers</h1>
		<p className='progress-subheader'>Set which teachers cam teach each course.</p>
		{(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):(!departmentID) ? 
		(
			<DepartmentProgressScreen subscreenResponsibleRoleID={subscreenResponsibleRoleID} subscreen={subscreen} sortedDepartments={sortedDepartments} changeDepartment={changeDepartment} subscreenSelect={subscreenSelect} userInfo={userInfo} userIsAdmin={userIsAdmin} userIsSupervisor={userIsSupervisor} />
		):(!userIsSupervisor) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to change this department</div>	
			</div>
		):(departmentID) ?
			(
				<>
				<div id='clean-data-content'>
					<div>
						<FontAwesomeIcon icon={faChevronLeft} className='progress-back-to-departments-btn' onClick={()=>changeDepartment(null)}/>
					</div>
					<div className='clean-data-content-inner-content'>
						<div className='clean-data-submit-btn-container'>
							{!isLoading &&
								<>
								{(reviewing && screen_complete_for_user) &&
									<>
									{changesSavedMessage && 
										<div className='all-changes-saved-message' style={{margin:'15px 0px 0px 0px'}}>
											<FontAwesomeIcon className='green-text' icon={faCheckCircle}/>
											<div>
												<h5>Changes Saved</h5>
												<div className='small-text'>All your changes have been saved</div>
											</div>
											<FontAwesomeIcon className='x-remove' icon={faTimes} onClick={() => setChangesSavedMessage(false)}/>
										</div>
									}
									</>
								}
								{(!screen_complete_for_user && show_current_screen) &&
									<Button key={departmentID} classes={['mark-as-complete-btn']} selected={false} text={`${!current_screen_submitted ? 'Mark As Complete' : 'Confirm Department'}`} icon={faCheck} iconPosition='left' onClickFunction={() => submitDepartment(departmentID)} />
								}
								</>
							}
						</div>
						<div className='clean-data-content-header-container'>
							<h1 className='clean-data-content-header'>{department_info && department_info.department} Department</h1>
							{current_screen_confirmed ?
								(
									<div className='clean-data-status green-text'><div>Complete</div><FontAwesomeIcon icon={faCheckCircle}/></div>
								): (current_screen_submitted) ?
								(
									<div className='clean-data-status bright-yellow-text'><div>Pending Confirmation</div><FontAwesomeIcon icon={faCheckCircle}/></div>
								): (null)
							}
						</div>
						<div className='department-supervisor-parent-container'>
							<>
							{(department_info && department_info.supervisors.length === 0) &&
								<>
								<div className='department-supervisor-inner-container'>
									<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
									<div className='department-supervisor-name'>No Supervisor Assigned</div>
								</div>
								</>
							}
							{department_info && department_info.supervisors.map((supervisor, index) => {
								return (
									<div className='department-supervisor-outer-container' key={index}>
										<div className='department-supervisor-inner-container'>
											{supervisor.img_url &&
												<img className='department-supervisor-img' src={require(`../../../../images/users/${supervisor.img_url}`)} alt='user' />
											}
											{!supervisor.img_url &&
												<FontAwesomeIcon className='department-supervisor-user-icon gray-text' icon={faUserCircle}/>
											}
											<div className='department-supervisor-name'>{supervisor.last_name}, {supervisor.first_name}</div>
											{userIsAdmin &&
												<FontAwesomeIcon className='gray-text-hover' icon={faTimes} onClick={() => removeSupervisorFromDepartment(supervisor.user_id)}/>
											}
										</div>
									</div>
								)
							})}
							{userIsAdmin &&
								<>
								<div className={`clean-data-add-supervisor-link small-text click-restricted ${(department_info && department_info.supervisors.length === 0) ? 'blue-link' : 'gray-to-blue-link'}`} onClick={() => openSupervisorsList(departmentID)}>Add a Supervisor</div>
								<div className='supervisor-options-container'>
									{(department_info && department_info.supervisors_open) &&
										<div className='data-options-container' ref={ref}>
											{supervisors.map((supervisor_info, index) => {
												
												return (
													<div className='data-options-option assign-resourses-supervisor-option' key={index} onClick={() => addSupervisorToDepartment(supervisor_info)}>
														{supervisor_info.img_url &&
															<img className='department-supervisor-img' src={require(`../../../../images/users/${supervisor_info.img_url}`)} alt='supervisor' />
														}
														{!supervisor_info.img_url &&
															<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
														}
														<div className='department-supervisor-name'>{supervisor_info.last_name}, {supervisor_info.first_name}</div>
													</div>
												)	
											})}
											<Link className='data-options-option blue-text' to={{pathname:`/app/${schoolType}/settings/users`, redirect:`/app/${schoolType}/progress/clean_data/course_teachers`}} style={{borderTop:'1px solid #efefef'}} onClick={() => closeSupervisorsList(departmentID)}>Add new Supervisor</Link>
										</div>
									}
								</div>
								</>
							}
							</>
						</div>
						<div className='clean-data-container'>
							{isLoading ?
								(
									<div className="center-text">
										<img className='clean-data-loader' src={require(`../../../../images/balls.gif`)} alt='loading' />
									</div>
								):
								(
									<>
									{(!current_screen_unlocked) ?
										(
											<div className='general-screen-message'>
												<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
												<h1>Locked</h1>
												<div>This department has previous steps which have not been completed. Please review previous steps and make sure they are completed and saved. This page should open automatically once all previous steps have been finished.</div>
											</div>
										):(userIsAdmin && current_screen_unlocked && !show_current_screen && !current_screen_submitted && !userIsSupervisor) ?
										(
											<div className='general-screen-message'>
												<FontAwesomeIcon className='turquoise-text' style={{fontSize:'60px'}} icon={faHourglassHalf}/>
												<h1>Pending...</h1>
												<div>Waiting on confirmation from department supervisor</div>
												<p className='blue-link' onClick={toggleReviewDepartment}>Review course teachers yourself</p>	
											</div>
										):(show_current_screen) ?
										(
											<>
											{department_courses.length === 0 ?
												(
													<div className='clean-data-no-data-container'>
														<FontAwesomeIcon className='clean-data-no-data-icon dark-blue-text' icon={faExclamationCircle}/>
														<div>No courses found</div>
														<p>Please confirm that this is correct by submitting the screen</p>
													</div>
												):
												(
													<>
													{department_courses.map((course_info, i) => {
														const course_id = course_info.course_id;
														const teachers_can_teach = course_info.teachers_can_teach.filter(tct_info => teachers.findIndex(teacher => teacher.teacher_id === tct_info.teacher_id) !== -1);
														
														const is_searching = ('searching' in course_info) ? true : false;
														
														const department_teacher_results = ('department_teacher_results' in course_info) ? course_info.department_teacher_results : [];
														const other_teacher_results = ('other_teacher_results' in course_info) ? course_info.other_teacher_results : [];
														const any_search_results = department_teacher_results.length + other_teacher_results.length;
							
														return (
															<div className='clean-data-connection-main-container' key={i}>
																<h3 className='clean-data-connection-main-header'>
																	<div>{capitalizeFirstLetters(course_info.name)} ({course_info.course_code})</div>
																	<FontAwesomeIcon className='gray-to-red-link' icon={faTrashAlt} onClick={() => updateCourse('remove', 'course', course_id, departmentID, null)} style={{justifySelf:'center'}}/>
																</h3>
																<div className='clean-data-connection-main-container-inner'>
																	{teachers_can_teach.map((tct_info, index) => {
																		const teacher_id = tct_info.teacher_id;
																		const tct_type = tct_info.type;
																		
																		const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
																		
																		return (
																			<div className='clean-data-connection-container' data-courseid={course_id} data-teacherid={teacher_id} style={row_style} key={index}>
																				<div className='clean-data-connection-header'>
																					<div className='dark-blue-text'><FontAwesomeIcon className='clean-data-connection-icon' icon={faChalkboardTeacher}/> {teacher_info.name}, {teacher_info.first_name}</div>
																				</div>
																				<div className='clean-data-connection-details'>
																					<Dropdown data={tctTypeOptions} currentValue={tct_type} handleChange={() => updateCourseTeachers(course_id, 'update', teacher_id)} />
																				</div>
																				<div className='clean-data-remove-connection-container' onClick={() => updateCourseTeachers(course_id, 'remove', teacher_id)}>
																					<FontAwesomeIcon className='x-remove' icon={faTimes}/>
																				</div>
																			</div>
																		)
																	})}
																	<div className='blue-link clean-data-add-new-connection' onClick={() => toggleAddNewData(course_id)}>Add Teacher Who Can Teach</div>
																	{is_searching &&
																		<div className='search-add-row-container'>
																			<div className='search-add-search-container'>
																				<div className='search-add-adding-input-container'>
																					<input id={`search-input-course-${course_id}`} className='search-add-adding-input' onChange={(e) => fuzzySearch(e, course_id)} placeholder='Search for teacher' autoFocus={true}/>
																					<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
																				</div>
																				<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewData(course_id)}/>
																			</div>
																			{any_search_results > 0 &&
																				<div className='search-add-search-results'>
																					{(department_teacher_results.length !== 0) && 
																						<>
																						<div className='search-add-did-you-mean'>Department Teachers:</div>
															
																						{department_teacher_results.map((teacher, i) => {
																							const teacher_departments = teacher.departments.reduce((result, department_id) => {
																								const department_index = departments.findIndex(department => department.department_id === department_id);
																								
																								if(department_index !== -1) result.push(departments[department_index].department);
																								
																								return result;
																							}, []);
																							
																							const department_text = teacher_departments.join(', ');
																							
																							return (
																								<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 1fr auto'}}>
																									<div>{teacher.name}, {teacher.first_name}</div>
																									<div className='dark-gray-text small-text'>{department_text}</div>
																									<div>
																										<div className='search-add-add-btn' onClick={() => updateCourseTeachers(course_id, 'add', teacher.teacher_id)}>Add</div>
																									</div>
																								</div>
																							)
																						})}
																						</>
																					}
																					{(other_teacher_results.length !== 0) &&
																						<>
																						<div className='search-add-did-you-mean'>Other Teachers:</div>
																						{other_teacher_results.map((teacher, i) => {
																							const teacher_departments = teacher.departments.reduce((result, department_id) => {
																								const department_index = departments.findIndex(department => department.department_id === department_id);
																								
																								if(department_index !== -1) result.push(departments[department_index].department);
																								
																								return result;
																							}, []);
																							
																							const department_text = teacher_departments.join(', ');
																							
																							return (
																								<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 1fr auto'}}>
																									<div>{teacher.name}, {teacher.first_name}</div>
																									<div className='dark-gray-text small-text'>{department_text}</div>
																									<div>
																										<div className='search-add-add-btn' onClick={() => updateCourseTeachers(course_id, 'add', teacher.teacher_id)}>Add</div>
																									</div>
																								</div>
																							)
																						})}
																						</>
																					}
																				</div>
																			}
																		</div>
																	}
																</div>
															</div>
														)
													})}
													</>
												)	
											}
											<div className='clean-data-add-new-data blue-link assign-resources-supervisor-confirm-add-new' onClick={() => setAddCourseOpen(!addCourseOpen)}>Add A Course</div>
											{addCourseOpen &&
												<div className='search-add-row-container'>
													<div className='search-add-search-container'>
														<div className='search-add-adding-input-container'>
															<input id='teacher-search-input' className='search-add-adding-input' onChange={(e) => addSearch(e)} placeholder='Search for course'/>
															<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
														</div>
														<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => setAddCourseOpen(!addCourseOpen)}/>
													</div>
													{courseResults.length !== 0 &&
														<div className='search-add-search-results'>
															<div className='search-add-did-you-mean'>Did you mean:</div>
															{courseResults.map((course, i) => {
																return (
																	<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr auto'}}>
																		<div>{capitalizeFirstLetters(course.name)} ({course.course_code})</div>
																		<div>
																			<div className='search-add-add-btn' onClick={() => updateCourse('add', 'course', course.course_id, null, departmentID)}>Add</div>
																		</div>
																	</div>
																)
															})}
															<div className='clean-data-add-new-data-text dark-blue-text'>If you don&rsquo;t see the course you are looking for, you should first add them <span className='blue-link' onClick={() => setAddNewCourseOpen(true)}>Add A New Course</span></div>
														</div>
													}
												</div>
											}
											{addNewCourseOpen &&
												<EditCourseData schoolInfo={schoolInfo} dataID={null} toggleEditData={() => setAddNewCourseOpen(false)} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} courses={courses} students={students} classrooms={classrooms} teachers={teachers} departments={departments} setDatabaseData={setCourses} setStudents={setStudents} sections={sections} labels={labels} defaultDepartmentID={departmentID}/>
											}
											</>
										):null
									}
									</>
								)
							}
						</div>
					</div>
				</div>
				</>
			):null}
			{showNextButton &&
				<div className='fixed-bottom-save-container'>
					<Button classes={['btn', 'btn-extra-large', 'green-btn', 'align-right', 'move-to-next-screen-btn']} selected={false} text='Go to Next Step: Classroom Details' onClickFunction={handleMoveToNextScreen} />
				</div>
			}
		</>
	);
}