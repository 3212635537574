import React, { useEffect, useState, useRef } from 'react';
import Fuse from 'fuse.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';

import { useOutsideClick, } from '../../../../js/Helpers';

export default function EditBlockSecondaryTeacher({ teachers, blockData, secondaryTeacherID, changeSecondaryTeacher = () => {}, removeSecondaryTeacher = () => {}}) {
	const [currentSecondaryTeacherID, setCurrentSecondaryTeacherID] = useState(secondaryTeacherID);
	const [teacherName, setTeacherName] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [teacherError, setTeacherError] = useState(null);

	const teacher_ref = useRef();

	useOutsideClick(teacher_ref, () => {
		setSearchResults([]);
	}, []);

	const handleFocus = (event) => event.target.select();

	const handleSelect = (secondary_teacher_id) => {
		changeSecondaryTeacher({old_secondary_teacher_id:currentSecondaryTeacherID, new_secondary_teacher_id:secondary_teacher_id});

		setCurrentSecondaryTeacherID(secondary_teacher_id);
		setSearchResults([]);
	}

	const handleRemove = () => {
		removeSecondaryTeacher({secondary_teacher_id:currentSecondaryTeacherID});

		setSearchResults([]);
	}
	
	const fuzzySearch = (e) => {
		const search_value = e.target.value;
		
		//////////////////////////////
		///// GET SEARCH RESULTS /////
		//////////////////////////////
		const fuse = new Fuse(teachers, {
			keys: ['first_name', 'name'],
			threshold: .1
		});
		
		const results = fuse.search(search_value);
		const teacher_results = results.map(result => result.item).slice(0,4);
		
		///////////////////////
		///// SET RESULTS /////
		///////////////////////
		setSearchResults(teacher_results);
		setTeacherName(search_value);
	}

	useEffect(() => {
		const teacher_info = teachers.find(teacher => teacher.teacher_id === currentSecondaryTeacherID);
		const teacher_name = (teacher_info) ? `${teacher_info.name}, ${teacher_info.first_name}` : '';
		setTeacherName(teacher_name);
	},[currentSecondaryTeacherID, teachers]);

	useEffect(() => {
		setCurrentSecondaryTeacherID(secondaryTeacherID);
	}, [secondaryTeacherID]);
	
	return (
		<div>
			<div className='elem-edit-block-secondary-teacher'>
				<input value={teacherName} onChange={fuzzySearch} disabled={blockData.locked === '1'}/>
				<div className='elem-edit-block-remove-secondary-teacher red-link small-text' onClick={handleRemove}>Remove</div>
			</div>
			{teacherError && <div className='error-message-no-box'>{teacherError}</div>}
			{(searchResults.length > 0) &&
				<div className='elem-suggestion-box-parent parent-div' ref={teacher_ref}>
					<div className='elem-suggestion-box'>
						{searchResults.map((search_result, index) => {
							const search_teacher_id = search_result.teacher_id;
							const search_teacher_first_name = search_result.first_name;
							const search_teacher_last_name = search_result.name;
							const search_teacher_full_name =  `${search_teacher_last_name}, ${search_teacher_first_name}`;

							return (
								<div className='search-add-search-result' key={index}>
									<div>{search_teacher_full_name}</div>
									<div className='search-add-add-btn' onClick={() => handleSelect(search_teacher_id)}>Add</div>
								</div>
							)
						})}
					</div>
				</div>
			}
		</div>
		
	);
}