import React, { memo } from 'react'
import { useDrop } from 'react-dnd';

import MagnetBoardSectionHSMS from './MagnetboardSectionHSMS';

export default memo(function MagnetboardUnplacedDropZoneHSMS ({schoolInfo, scheduleInfo, scheduleStructureData, scheduleType, currentViewType, currentDay, currentScheduleVersionID, teachers, courses, classrooms, sections, setSections, subsections, labels, conflicts, setConflicts, setConflictsLoading, setMatchPercent, setMatchPercentLoading, toggleEditSection, updateManualPlacement,  teacherID, unassignedSections, handleSelectTeacher}) {
	/////////////////////////////
	///// USE DROP FUNCTION /////
	/////////////////////////////
    const [{ isActive }, dropRef] = useDrop({
        accept: ['section'],
		drop: (item) => {
			updateManualPlacement({ updateType:'undo', sectionID:item.sectionID, droppedCoursePeriodID: null});
		},
		collect: (monitor) => ({
			isActive: monitor.isOver(),
		}),
    });

	/////////////////////
	///// USE EFFECT /////
	/////////////////////
    return (
		<div className='mb-unassigned-sections-container'>
			<div className='dashboard-grid'>
				<h5 style={{margin:'0px 0px 5px 0px'}}>Unplaced Sections</h5>
				<div className='align-right small-text dark-gray-link' onClick={() => handleSelectTeacher(null)}>Close</div>
			</div>
			<div className={`mb-unassigned-sections-dropzone ${isActive && 'mb-unassigned-sections-hover-over'}`} ref={dropRef}>
				{unassignedSections.map((section_info, index) => {
					return (
						<MagnetBoardSectionHSMS key={index} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} scheduleType={scheduleType} currentViewType={currentViewType} currentDay={currentDay} currentScheduleVersionID={currentScheduleVersionID} teachers={teachers} courses={courses} classrooms={classrooms} sections={sections} setSections={setSections} subsections={subsections} labels={labels} conflicts={conflicts} setConflicts={setConflicts} setConflictsLoading={setConflictsLoading} setMatchPercent={setMatchPercent} setMatchPercentLoading={setMatchPercentLoading} labsOnlyFiltered={false} nonLabsOnlyFiltered={false} singletonsFiltered={false} doubletonsFiltered={false} noClassroomFiltered={false} hasClassroomFiltered={false} toggleEditSection={toggleEditSection} dataID={teacherID} coursePeriodID={null} sectionID={section_info.section_id} sectionType='manual-section'/>
					)
				})}
			</div>
		</div>
    )
})