import React from 'react';

import SchoolInfo from '../../hsms/progress/school_info';
import ElemSchoolSettings from './school_settings';

import '../../../css/hsms/school_setup.css';

export default function SchoolSetup({ isLoading, subscreenRestricted, subscreenLocked, subscreen, schoolInfo, setSchoolInfo, scheduleInfo, setScheduleInfo, handleMoveToNextScreen }) {
	return (
		<div>
			{isLoading ?
				(
					<div className='center-text'>
						<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
					</div>
				) :
				(
					<>
					{(() => {
						switch(subscreen) {
							case 'school_info':
								return <SchoolInfo isLoading={isLoading} subscreenLocked={subscreenLocked} subscreenRestricted={subscreenRestricted} schoolInfo={schoolInfo} setSchoolInfo={setSchoolInfo} handleMoveToNextScreen={handleMoveToNextScreen}/>;
							case 'school_settings':
								return <ElemSchoolSettings isLoading={isLoading} subscreenLocked={subscreenLocked} subscreenRestricted={subscreenRestricted} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} setScheduleInfo={setScheduleInfo} handleMoveToNextScreen={handleMoveToNextScreen}/>;
							default:
								return null;
						}
					})()}
					</>
				)
			}
		</div>
	);
}