import React, { useState, useEffect, useRef } from 'react';
import { API } from "aws-amplify";
import swal from '@sweetalert/with-react';

import EditPeriod from '../modals/edit_period';

import { formatTime, useOutsideClick, sortArrayOfObjects } from '../../../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimes, faLock} from '@fortawesome/free-solid-svg-icons';

import '../../../css/magnetboard.css';

export default function SpecialsScheduleStructure({ subscreenLocked, subscreenRestricted, schoolInfo, scheduleInfo, setScheduleInfo, periods, setPeriods, periodsLoading, handleMoveToNextScreen }) {
	const [isLoading, setIsLoading] = useState(true);

	/////////////////////////////
	///// LOADING VARIABLES /////
	/////////////////////////////
	const group_positions = {
		'1':{'0':{start:'1', end:'60'}}, 
		'2':{'0':{start:'1', end:'28'},'1':{start:'32', end:'60'}}, 
		'3':{'0':{start:'1', end:'18'},'1':{start:'21', end:'39'},'2':{start:'42', end:'60'}}, 
		'4':{'0':{start:'1', end:'10'},'1':{start:'17', end:'27'},'2':{start:'33', end:'43'},'3':{start:'50', end:'60'}}
	};
	
	//////////////////////////
	///// DATA VARIABLES /////
	//////////////////////////
	const [periodID, setPeriodID] = useState(null);
	const [periodType, setPeriodType] = useState(null);
	const [dayNumber, setDayNumber] = useState(null);

	const [sidePanelClosing, setSidePanelClosing] = useState(false);

	const [addNewPeriodIsOpen, setAddNewPeriodIsOpen] = useState(false);
	const [addPeriodOpenDayNumber, setAddPeriodOpenDayNum] = useState(null);

	const [days, setDays] = useState([]);

	///////////////////
	///// USE REF /////
	///////////////////
	const add_new_period_ref = useRef();

	useOutsideClick(add_new_period_ref, () => {
		setAddNewPeriodIsOpen(false);
		setAddPeriodOpenDayNum(null);
	}, ['mb-add-new-section']);
	
	/////////////////////////////////
	///// MAGNETBOARD FUNCTIONS /////
	/////////////////////////////////
	const updateNumberDays = async (update_type) => {
		const options =  {
			title: "Are you sure?",
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			},
			content: (
				<div>
					<p>Are you sure you want to remove this day? This will permanently delete all periods set up on this day.</p>
				</div>
			)
		}

		if(update_type === 'add' || await swal(options))
		{
			/// Update the FRONTEND ///
			let num_days_in_cycle = scheduleInfo.num_days_in_cycle;
			const new_num_days_in_cycle = (update_type === 'add') ? (parseInt(num_days_in_cycle) + 1) : ((update_type === 'delete') ? (parseInt(num_days_in_cycle) -1) : num_days_in_cycle);

			scheduleInfo.num_days_in_cycle = new_num_days_in_cycle;
			setScheduleInfo({...scheduleInfo});

			/// Update the BACKEND ///
			const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, update_type:update_type};
			const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
			const url = '/elementary/update-number-days';
			const myInit = { // OPTIONAL
				response: true,
				body: JSON.stringify(data),
			};
		
			try {
				await API.post(apiName, url, myInit);
			} catch(e)
			{
				console.log(e.response);
			}
		}
	}

	const handleToggleAddNewPeriod = (day_number = null) => {
		if(day_number && (day_number === addPeriodOpenDayNumber))
		{
			setAddPeriodOpenDayNum(null);
			setAddNewPeriodIsOpen(false);
		}
		else
		{
			setAddPeriodOpenDayNum(day_number);
			setAddNewPeriodIsOpen(true);
		}
	}

	const createNewPeriod = (period_type = null, teacher_id = null, day_number = null) => {
		handleToggleAddNewPeriod();
		toggleEditPeriod('new', period_type, teacher_id, day_number);
	}

	const toggleEditPeriod = (period_id, period_type = null, day_number = null) => {
		setPeriodType(period_type);
		setDayNumber(day_number);
		setPeriodID(period_id);
	}

	const calculatePeriodColumnStartEnd = (period_data, day_schedule, logged_positions, start_time, end_time) => {
		const overlapCheck = (time1, time2) => {
			const start_time1 = time1.start_time;
			const end_time1 = time1.end_time;
			const start_time1_array = start_time1.split(':');
			const end_time1_array = end_time1.split(':');

			const start_time1_number = parseInt(start_time1_array[0] + start_time1_array[1]);
			const end_time1_number = parseInt(end_time1_array[0] + end_time1_array[1]);

			const start_time2 = time2.start_time;
			const end_time2 = time2.end_time;
			const start_time2_array = start_time2.split(':');
			const end_time2_array = end_time2.split(':');

			const start_time2_number = parseInt(start_time2_array[0] + start_time2_array[1]);
			const end_time2_number = parseInt(end_time2_array[0] + end_time2_array[1]);

			if(end_time1_number > start_time2_number && end_time2_number > start_time1_number) return true;

			return false;
		}

		const period_id = period_data.period_id;

		///// Calculate left and right position
		// Get max number of overlaps
		const period_time = {period_id:period_id, start_time:start_time, end_time:end_time};

		let overlapping_time_groups = [[period_time]];
		for(var i = 0; i < day_schedule.length; i++)
		{
			const new_period = day_schedule[i];
			const new_period_id = new_period.period_id;

			if(period_id === new_period_id) continue; //ignore the original period itself

			const new_time = {period_id:new_period_id, start_time:new_period.start_time, end_time:new_period.end_time};
			const new_time_overlaps_original = overlapCheck(period_time, new_time);

			if(!new_time_overlaps_original) continue; //ignore periods that don't even overlap original

			// Go through all overlap groups and see if new period overlaps all of the times in the current groups
			// If yes, add to that group
			// If no, create a new group
			for(var j = 0; j < overlapping_time_groups.length; j++)
			{
				const overlapping_time_group = overlapping_time_groups[j];

				// Ignore a period that has already been added to this group
				const new_time_index = overlapping_time_group.findIndex(overlapping_time => overlapping_time.period_id === new_period_id);
				if(new_time_index !== -1) continue;

				let new_time_overlaps_entire_group = true;
				
				for(var k = 0; k < overlapping_time_group.length; k++)
				{
					const time_to_check = overlapping_time_group[k];
					const time_overlaps = overlapCheck(time_to_check, new_time);

					if(!time_overlaps) new_time_overlaps_entire_group = false;
				}

				if(new_time_overlaps_entire_group)
				{
					overlapping_time_groups[j].push(new_time);
				}
				else
				{
					overlapping_time_groups.push([period_time, new_time]);
				}
			}
		}

		const overall_max_stats = overlapping_time_groups.reduce((results, overlapping_time_group) => {
			const overlapping_time_group_length = overlapping_time_group.length;
			if(overlapping_time_group_length > results.max_overlaps) results = {max_overlaps: overlapping_time_group_length, overlapping_periods:overlapping_time_group};
			return results;
		}, {max_overlaps:0, overlapping_periods:[]});

		/// Use max overlaps to get the possible positions (preset depending on number of overlaps)
		const max_overlaps = overall_max_stats.max_overlaps;
		const overlapping_periods = overall_max_stats.overlapping_periods;

		const possible_positions = group_positions[max_overlaps];
		const possible_positions_indexes = Object.keys(possible_positions);
		const used_positions = overlapping_periods.reduce((results, period_times) => {
			const overlapping_period_id = period_times.period_id;
			const used_position = (overlapping_period_id in logged_positions) ? logged_positions[overlapping_period_id] : null;
			if(used_position) results.push(used_position);
			return results;
		},[]);
		const unused_positions = possible_positions_indexes.filter(x => used_positions.indexOf(x) === -1);
		const position_index = unused_positions[0];
		const start_position = possible_positions[position_index].start;
		const end_position = possible_positions[position_index].end;

		return {position_index:position_index, col_start:start_position, col_end:end_position};
	}

	const sendToDelete = async (period_id) => {
		/// Update the BACKEND ///
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, period_id:period_id};
		const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
		const url = '/elementary/delete-period';
		const myInit = { // OPTIONAL
			response: true,
			body: JSON.stringify(data),
		};
	
		try {
			await API.post(apiName, url, myInit);
		} catch(e)
		{
			console.log(e.response);
		}
	}

	const removePeriod = async (period_id) => {
		const options =  {
			title: "Are you sure?",
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			},
			content: (
				<div>
					<p>Are you sure you want to remove this period?</p>
				</div>
			)
		}

		if(await swal(options))
		{
			/// Update the FRONTEND ///
			const new_periods = periods.filter(period => period.period_id !== period_id);
			setPeriods([...new_periods]);
			toggleEditPeriod(null);

			// Update the BACKEND
			sendToDelete(period_id);
		}
	}

	const handleRemovePeriod = async (period_id, day_number, e = null) => {
		if(e && e.stopPropagation) e.stopPropagation();

		const options =  {
			title: "Are you sure?",
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			},
			content: (
				<div>
					<p>Are you sure you want to remove this period?</p>
				</div>
			)
		}

		if(await swal(options))
		{
			// Decide if you need to delete the entire period or just one day
			const period_index = periods.findIndex(period => period.period_id === period_id);
			const period_data = (period_index !== -1) ? periods[period_index] : null;
			const period_days = (period_data) ? period_data.days : [];
			const period_days_without_this_day = period_days.filter(day => day != day_number);

			if(period_days_without_this_day.length === 0)
			{
				// Update FRONTEND 
				const new_periods = periods.filter(period => period.period_id !== period_id);
				setPeriods([...new_periods]);

				// Update BACKEND
				sendToDelete(period_id);
			}
			else
			{
				// Update FRONTEND
				periods[period_index].days = period_days_without_this_day;
				setPeriods([...periods]);

				const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, period_data:periods[period_index]};
				const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
				const url = '/elementary/save-period';
				const myInit = { // OPTIONAL
					response: true,
					body: JSON.stringify(data),
				};
			
				try {
					await API.post(apiName, url, myInit);
				} catch(e)
				{
					console.log(e.response);
				}
			}

			toggleEditPeriod(null);
		}
	}

	////////////////////////////////
	///// USE EFFECT FUNCTIONS /////
	////////////////////////////////
	useEffect(() => {
		const num_days_in_cycle = scheduleInfo.num_days_in_cycle;

		let new_days = [];

		for(let day = 1; day <= num_days_in_cycle; day++)
		{
			new_days.push(`${day}`);
		}

		setDays(new_days);
	}, [scheduleInfo]);

	useEffect(() => {
		if(!periodsLoading) setIsLoading(false);
	}, [periodsLoading])
	
	//console.log({scheduleInfo});
	//console.log({addNewPeriodIsOpen, addPeriodOpenTeacherID, addPeriodOpenDayNumber});
	//console.log({periods});

	const last_day = days[days.length - 1];

	////////////////////////////////
	///// INITIALIZE VARIABLES /////
	////////////////////////////////
	return (
		<>
		{(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):(isLoading) ?
		(
			<div className='center-text'>
				<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'25px'}}/>
			</div>
		):
		(
			<>
			<EditPeriod schoolInfo={schoolInfo} periodID={periodID} periodType={periodType} dayNumber={dayNumber} days={days} periods={periods} setPeriods={setPeriods} removePeriod={removePeriod} toggleEditPeriod={toggleEditPeriod} sidePanelClosing={sidePanelClosing} setSidePanelClosing={setSidePanelClosing}/>
			<div className='mb-elem-container'>
				<div className='mb-elem-top-bar'>
					<div className='mb-top-bar-inner'>
						<div className='mb-elem-schedule-name-container'>
							<h1>Schedule Structure</h1>
						</div>
						<p className='extra-large-text'>Create the periods of time that courses can run. This is especially important for auto placing specials.</p>
					</div>
				</div>
				<div className='btn blue-btn align-right' style={{width:'fit-content'}} onClick={() => updateNumberDays('add')}>Add a Day</div>
				<div>
					<div className='mb-elem-teacher-schedule'>
						<div className='mb-elem-week-schedule-container'>
							<div className='mb-elem-week-schedule-time-container'>
								<div className='mb-elem-time-delimiter'>
									<div>7:00AM</div>
								</div>
								<div className='mb-elem-time-delimiter'>
									<div>8:00AM</div>
								</div>
								<div className='mb-elem-time-delimiter'>
									<div>9:00AM</div>
								</div>
								<div className='mb-elem-time-delimiter'>
									<div>10:00AM</div>
								</div>
								<div className='mb-elem-time-delimiter'>
									<div>11:00AM</div>
								</div>
								<div className='mb-elem-time-delimiter'>
									<div>12:00PM</div>
								</div>
								<div className='mb-elem-time-delimiter'>
									<div>1:00PM</div>
								</div>
								<div className='mb-elem-time-delimiter'>
									<div>2:00PM</div>
								</div>
								<div className='mb-elem-time-delimiter'>
									<div>3:00PM</div>
								</div>
								<div className='mb-elem-time-delimiter'>
									<div>4:00PM</div>
								</div>
							</div>
							<div className='mb-elem-week-schedule-days-container'>
								{days.map(day_number => {
									const day_name = `Day ${day_number}`;

									const day_schedule = periods.filter(period => period.days.includes(day_number));

									///// Organize all the periods into overlapping groups /////
									// Any non overlapping periods get thrown into their own group
									const day_schedule_with_overlaps = day_schedule.reduce((results, period) => {
										const period_start_time = period.start_time;
										const period_end_time = period.end_time;

										const period_start_time_array = period_start_time.split(':');
										const period_end_time_array = period_end_time.split(':');

										const period_start_time_hour = parseInt(period_start_time_array[0]);
										const period_start_time_min = parseInt(period_start_time_array[1]);
										const period_start_num_hours = period_start_time_hour - 7;
										const period_row_start = Math.max(1, period_start_num_hours*60 + period_start_time_min);

										const period_end_time_hour = parseInt(period_end_time_array[0]);
										const period_end_time_min = parseInt(period_end_time_array[1]);
										const period_end_num_hours = period_end_time_hour - 7;
										const period_row_end = Math.max(1, period_end_num_hours*60 + period_end_time_min);

										period.row_start = period_row_start;
										period.row_end = period_row_end;
										period.period_length = period_row_end - period_row_start;

										results.push(period);
										return results;
									},[]);

									const sorted_day_schedule_with_overlaps = sortArrayOfObjects(day_schedule_with_overlaps, 'period_length', 'number', 'desc');

									const logged_positions = {};

									return (
										<div className='mb-elem-week-day-container' key={`${day_number}`}>
											<h3>{day_name}</h3>
											{day_number === last_day && 
												<div className='red-link full-width text-align-center small-text' onClick={() => updateNumberDays('delete')} style={{position:'absolute',top:'43px'}}>Remove Day</div> 
											}
											<div className='mb-elem-week-day-block-parent-container'>
												<div className='mb-elem-week-day-grid'>
													{sorted_day_schedule_with_overlaps.map(period => {
														const period_id = period.period_id;
														const period_name = period.period_name;
														const is_lunch = period.is_lunch;
														const is_special = period.is_special;
														const is_pullout = period.is_pullout;
														
														const start_time = period.start_time;
														const end_time = period.end_time;
														const start_time_formatted = formatTime(start_time);
														const end_time_date_formatted = formatTime(end_time);

														const row_start = period.row_start;
														const row_end = period.row_end;

														const period_color = (is_lunch === '1') ? 'pastel-yellow' : ((is_special === '1') ? 'green' :((is_pullout === '1') ? 'orange' : 'dark-purple'));

														// Calculate the column start and end position on the grid
														const period_position = calculatePeriodColumnStartEnd(period, day_schedule_with_overlaps, logged_positions, start_time, end_time);
														
														const position_index = period_position.position_index;
														const col_start = period_position.col_start;
														const col_end = period_position.col_end;

														logged_positions[period_id] = position_index;
														
														return (
															<div className='mb-elem-block parent-div' style={{gridColumnStart:col_start, gridColumnEnd:col_end, gridRowStart:row_start, gridRowEnd:row_end}} key={period_id} onClick={!periodID ? () => toggleEditPeriod(period_id) : null}>
																<div className={`mb-elem-block-inner left-border-${period_color}-thick ${periodID === period_id ? `${period_color}-border mb-elem-block-selected` : ''}`} >
																	<div className={`mb-elem-block-header`}>
																		<span className='tooltip' data-tooltip-text={period_name}>
																			<div className='mb-elem-block-name ellipsis'>{period_name}</div>
																		</span>
																		<FontAwesomeIcon className='mb-elem-remove-block small-text cursor-pointer' icon={faTimes} onClick={(e) => handleRemovePeriod(period_id, day_number, e)}/>
																	</div>
																	<div className={`mb-elem-block-content`}>
																		<div className='tooltip' data-tooltip-text={`${start_time_formatted} - ${end_time_date_formatted}`}>
																			<div className='mb-elem-block-time ellipsis'>{start_time_formatted} - {end_time_date_formatted}</div>
																		</div>
																	</div>
																</div>
															</div>
														)
													})}
												</div>
												<div className='mb-add-new-section parent-div' ref={add_new_period_ref} data-daynumber={day_number}>
													<div className='mb-elem-add-block-container blue-link' onClick={() => createNewPeriod('special', day_number)}>
														<FontAwesomeIcon icon={faPlusCircle} />
														<div className='medium-text'>Add Period</div>
													</div>
													{/* {(addNewPeriodIsOpen && day_number === addPeriodOpenDayNumber) &&
														<div className='dropdown-options-container mb-add-new-block-options-container parent-div' style={{top:'27px'}}>
															<div className='dropdown-option left-border-dark-purple-thick' onClick={() => createNewPeriod('period', day_number)}>Add Other Period</div>
															<div className='dropdown-option left-border-green-thick' onClick={() => createNewPeriod('special', day_number)}>Add Specials Period</div>
															<div className='dropdown-option left-border-orange-thick' onClick={() => createNewPeriod('pullout', day_number)}>Add Pullout Period</div>
															<div className='dropdown-option left-border-pastel-yellow-thick' onClick={() => createNewPeriod('lunch', day_number)}>Add Lunch Period</div>
														</div>
													} */}
												</div>
											</div>
										</div>
									)
								})}
							</div>
						</div>
					</div>				
				</div>
			</div>
			<div className='fixed-bottom-save-container'>
				<div className="btn btn-extra-large green-btn align-right move-to-next-screen-btn move-to-next-screen-btn" onClick={handleMoveToNextScreen}>Go to Next Step: Specials Setup</div>
			</div>
			</>
		)
		}
		</>
	)
}