import React, { useState , useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import Fuse from 'fuse.js';

import Button from '../../Button';
import Dropdown from '../../Dropdown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faTimes, faSquare, faCheckSquare, faSearch, faSlidersH } from '@fortawesome/free-solid-svg-icons';

import { capitalizeFirstLetters, sortArrayOfObjects, filterArrayOfObjects, JSONexists, getJSONFromStorage, useOutsideClick } from '../../../js/Helpers';
import { createInclusionLabels } from '../../../js/SaveFunctions';

export default function StudentLabels({ isLoading, setIsLoading, subscreenRestricted, subscreenLocked, getNewData, userInfo, schoolInfo, studentsLoading, students, setStudents, coursesLoading, courses, sectionsLoading, sections, labels, handleMoveToNextScreen, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo }) {
	const filter_ref = useRef();

	const [isSearching, setIsSearching] = useState(false);
	const [currentView, setCurrentView] = useState(null);
	
	const [teamLabels, setTeamLabels] = useState([]);
	const [courseLabels, setCourseLabels] = useState([]);
	const [displayResults, setDisplayResults] = useState(students);
	const [maxStudentsToShow, setMaxStudentsToShow] = useState(50);
	
	const [filterDataVisible, setFilterDataVisible] = useState(false);
	const [filterOptions, setFilterOptions] = useState({});
	
	let typingTimeout = null;
	
	const handleIsSearching = (e) => {
		const search_value = e.target.value;
		
		if(search_value.length > 1)
		{
			setIsSearching(true);
		}
		else
		{
			setIsSearching(false);
		}
	}
	
	const handleChange = (e) => {
		setMaxStudentsToShow(50);
		
		if(typingTimeout) clearTimeout(typingTimeout);
		
		const search_value = e.target.value;
		
		if(search_value.length > 1)
		{
			typingTimeout = setTimeout(() => {
				const filtered_results = filterArrayOfObjects(students, search_value, ['last_name', 'first_name']);
				setDisplayResults([...filtered_results]);
			
				setIsSearching(false);
			}, 300);
		}
		else
		{
			setDisplayResults([...students]);
			setIsSearching(false);
		}
	}
	
	const showMoreStudents = () => {
		const num_students_to_show = maxStudentsToShow + 50;
		setMaxStudentsToShow(num_students_to_show);
	}

	const toggleFilterOptions = () => {
		setFilterDataVisible(!filterDataVisible);
	}

	const updateFilterOptions = (filter_title, option_index, selected) => {
		filterOptions[filter_title][option_index]['selected'] = selected;
		setFilterOptions({...filterOptions});
	}

	useOutsideClick(filter_ref, () => {
		setFilterDataVisible(false);
	});
	
	const toggleSearch = (label_type, index) => {
		if(label_type === 'team')
		{
			const is_open = ('search_open' in teamLabels[index]) ? true : false;
			
			if(is_open)
			{
				delete teamLabels[index]['search_open'];
			}
			else
			{
				teamLabels[index]['search_open'] = true;
			}
			
			setTeamLabels([...teamLabels]);
		}
		else
		{
			const is_open = ('search_open' in courseLabels[index]) ? true : false;
			
			if(is_open)
			{
				delete courseLabels[index]['search_open'];
			}
			else
			{
				courseLabels[index]['search_open'] = true;
			}
			
			setCourseLabels([...courseLabels]);
		}
	}
	
	const searchStudents = (e, label_type, index) => {
		const search_value = e.target.value;
		
		//////////////////////////////
		///// GET COURSE RESULTS /////
		//////////////////////////////
		const fuse = new Fuse(students, {
			keys: ['first_name', 'last_name'],
			threshold: .6
		});
		
		const results = fuse.search(search_value);
		const student_results = results.map(result => result.item).slice(0,10); // only grab 10 results
		
		if(label_type === 'team')
		{
			teamLabels[index]['student_suggestions'] = student_results;
			setTeamLabels([...teamLabels]);
		}
		else
		{
			courseLabels[index]['student_suggestions'] = student_results;
			setCourseLabels([...courseLabels]);
		}
	}
	
	const updateDatabase = async (update_type, student_id, label_id, course_id, _callback = () => {}) => {
		const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, update_type:update_type, student_id:student_id, label_id:label_id, course_id:course_id};
		
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/admin/update-student-label';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			await API.post(apiName, url, myInit);
			
			_callback()
		} catch(e)
		{
			console.log(e.response);
		}
	}
	
	const updateStudentLabel = (update_type, student_id, label_id, label_course_id) => {
		const student_index = students.findIndex(student => student.student_id === student_id);
		const student_info = students[student_index];
		const student_labels = student_info.student_labels;
		
		// Update FRONTEND
		const student_label_index = student_labels.findIndex(label => label.label_id === label_id && label.course_id === label_course_id);
		if(update_type === 'add' && student_label_index === -1)
		{
			student_labels.push({label_id:label_id, course_id:label_course_id});
		}
		else if(update_type === 'remove' && student_label_index !== -1)
		{
			student_labels.splice(student_label_index, 1);
		}

		setStudents([...students]);

		// Update BACKEND
		updateDatabase(update_type, student_id, label_id, label_course_id)
	}
	
	//////////////////////////////
	///// DO ON STATE CHANGE /////
	//////////////////////////////
	useEffect(() => {
		if(!studentsLoading && !coursesLoading && !sectionsLoading)
		{
			const course_labels = sections.reduce((result, section) => {
				const section_labels = section.labels;
				
				// If section has labels, add the course-label combo to the list (if it hasn't shown up already)
				if(section_labels.length !== 0)
				{
					const course_id = section.course_id;
					
					section_labels.forEach(label_id => {
						// Search for this label/course combo in both team and label arrays
						const course_label_index = result.labels.findIndex(course_label => course_label.label_id === label_id && course_label.course_id === course_id);
						const course_label_team_index = result.teams.findIndex(course_label => course_label.label_id === label_id && course_label.course_id === course_id);
						// If label/team doesn't exist in either, add it to the result
						if(course_label_index === -1 && course_label_team_index === -1) 
						{
							const label_info = labels.find(label => label.label_id === label_id);

							if(!label_info) return;

							const label_name = (label_info) ? label_info.label : null;
							const is_team = (label_info) ? label_info.is_team : null;
							const course_info = courses.find(course => course.course_id === course_id);
							const course_name = (course_info) ? capitalizeFirstLetters(course_info.name) : null;
							const course_code = (course_info) ? course_info.course_code : null;
							
							const course_label_name = `${label_name} - ${course_name} (${course_code})`;
							
							if(is_team === '1') 
							{
								result.teams.push({label_id:label_id, course_id:course_id, course_code:course_code, course_label_name: course_label_name});
							}
							else
							{
								result.labels.push({label_id:label_id, course_id:course_id, course_code:course_code, course_label_name: course_label_name});
							}
						}
					});
				}
				
				return result;
			}, {'teams':[], 'labels':[]});
			
			const sorted_team_course_labels = sortArrayOfObjects(course_labels.teams, 'course_code', 'text', 'asc');
			const sorted_label_course_labels = sortArrayOfObjects(course_labels.labels, 'course_code', 'text', 'asc');
			
			setDisplayResults([...students]);
			setTeamLabels(sorted_team_course_labels);
			setCourseLabels(sorted_label_course_labels);
			
			setIsLoading(false);
		}
	}, [studentsLoading, coursesLoading, sectionsLoading, labels, courses, sections, students, setIsLoading]);

	useEffect(() => {
		if(currentView) sessionStorage.setItem(`student_label_view`, currentView);
	}, [currentView]);

	useEffect(() => {
		if(studentsLoading) return;

		let filter_options = {'grades':[]}
		const grades = students.reduce((result, student) => {
			const student_grade = student.grade;
			if(result.findIndex(option => option.value === student_grade) === -1) result.push({display:student_grade, value:student_grade, selected:false});
			return result;
		}, []);
		const sorted_grades = sortArrayOfObjects(grades, 'value', 'number', 'asc');

		filter_options['grades'].push(...sorted_grades);
		setFilterOptions({...filter_options});
	}, [studentsLoading]);

	useEffect(() => {
		if(!userInfo || subscreenRestricted || subscreenLocked) return;

		setScreenAllowsUniversalSidebar(true);
		setUniversalSidebarLoadingInfo({sidebar_name:"Student Labels Notes", sidebar_type:'notes', subscreen:'student_labels', department_id:null, user_id:userInfo.user_id, help_link_path:'/app/hsms/documentation/section_creation/student_labels'});
	}, [userInfo, subscreenRestricted, subscreenLocked, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo]);
	
	
	//////////////////////
	///// DO ON LOAD /////
	//////////////////////
	useEffect(() => {
		const current_view = JSONexists(`student_label_view`, 'session') ? getJSONFromStorage(`student_label_view`, false, 'session') : 'label';
		setCurrentView(current_view);
	}, []);
	
	//console.log(course_labels);
	//console.log({sorted_label_course_labels, sorted_team_course_labels});
	//console.log({courses, students, sections, labels});
	const current_user_is_admin = (userInfo.role_id === '1'|| userInfo.role_id === '2' || userInfo.role_id === '5');
	const viewOptions = [{value:'student', display:'By Student'}, {value:'label', display:'By Team/Label'}];
	
	let selected_filters = [];
	Object.keys(filterOptions).forEach(filter_title => {
		const filter_options = filterOptions[filter_title];

		filter_options.forEach(filter_option => {
			if(filter_option.selected) selected_filters.push(filter_option);
		});
	});

	let student_counter = 1;
	
	return (
		<>
		<h1 className='progress-main-header'>Student Labels</h1>
		<p className='progress-subheader'>Add your labels from the previous step to specific students to start grouping/teaming them.</p>
		{(isLoading) ?
		(
			<div className='center-text'>
				<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'40px'}}/>
			</div>
		):(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):(current_user_is_admin)?
		(
			<div id='student-labels-main-container'>
				<div id='student-labels-header-container'>
					<div></div>
					<div id='student-label-department-dropdown'>
						<Dropdown data={viewOptions} currentValue={currentView} handleChange={(new_value) => setCurrentView(new_value)} />
					</div>
				</div>
				{currentView === 'student' ?
					(
						<>
						<div className='mb-top-bar-inner'>
							<div className='mb-options-bar'>
								<div className='mb-search-bar-outer-container'>
									<div className='mb-search-bar-container'>
										<input className='mb-search-bar' placeholder="Search for students..." onChange={e => handleChange(e)} onKeyDown={(e) => handleIsSearching(e)} />
										<FontAwesomeIcon className='mb-search-bar-icon' icon={faSearch}/>
										<div className='mb-search-bar-filter-container' ref={filter_ref}>
											<div style={{display:'grid',width:'100%', height:'100%', alignItems:'center', justifyItems:'center'}} onClick={toggleFilterOptions}>
												<FontAwesomeIcon icon={faSlidersH}/>
											</div>
											{filterDataVisible &&
												<div className='list-filter-options-container click-restricted' style={{top:'60px'}}>
													{Object.keys(filterOptions).map((filter_title, j) => {
														const filter_options = filterOptions[filter_title];

														return (
															<div key={j}>
																<div className='capitalize'>{filter_title}</div>
																{filter_options.map((filter_option, i) => {
																	const filter_display = filter_option.display;
																	const filter_selected = filter_option.selected;

																	return (
																		<div className='parent-div list-filter-option click-restricted' key={i}>
																			<div className='list-filter-option-name click-restricted'>{filter_display}</div>
																			{filter_selected &&
																				<FontAwesomeIcon className='fas-checkbox-checked click-restricted' icon={faCheckSquare} onClick={() => updateFilterOptions(filter_title, i, false)}/>
																			}
																			{!filter_selected &&
																				<FontAwesomeIcon className='fas-checkbox-unchecked click-restricted' icon={faSquare} onClick={() => updateFilterOptions(filter_title, i, true)}/>
																			}
																		</div>
																	)
																})}
															</div>
														)
													})}
												</div>
											}
										</div>
									</div>
								</div>
							</div>
							<div className ='mb-selected-filters-bar'>
								{selected_filters.length > 0 &&
									<>
									<div className='mb-selected-filters-text'>Filters:</div>
									{Object.keys(filterOptions).map((filter_title, i) => {
										const filter_options = filterOptions[filter_title];

										return (
											<React.Fragment key={i}>
											{filter_options.map((filter_option, i) => {
												const filter_option_selected = filter_option.selected;
												
												if(!filter_option_selected) return;

												return (
													<div className='mb-selected-filters-filter-tag' key={i}>
														<div className='mb-selected-filters-filter-tag-inner-container'>
															<div>{filter_option.display}</div>
															<FontAwesomeIcon className='dark-gray-link' icon={faTimes} onClick={() => updateFilterOptions(filter_title, i, false)} />
														</div>
													</div>
												)
											})}
											</React.Fragment>
										)
									})}
									</>
								}
							</div>
						</div>
						{isSearching ?
							(
								<div className='center-text'>
									<img src={require('../../../images/searching.gif')} alt='searching...' style={{height:'100px'}}/>
								</div>
							): isLoading ? 
							(
								<div className='center-text'>
									<img src={require('../../../images/balls.gif')} alt='searching...' style={{height:'40px'}}/>
								</div>
							):
							(
								<>
								{displayResults.map((student_info, index) => {
									if(student_counter > maxStudentsToShow) return null;
									
									const student_id = student_info.student_id;
									const student_first_name = student_info.first_name;
									const student_last_name = student_info.last_name;
									const student_requests = student_info.student_requests;
									const student_grade = student_info.grade;

									const student_is_allowed = (selected_filters.length > 0) ? filterOptions['grades'].findIndex(grade_option => grade_option.value === student_grade && grade_option.selected) !== -1 : true;
									if(!student_is_allowed) return null;
									
									const student_labels = student_info.student_labels;
									const student_team_labels = teamLabels.reduce((results, label_info) => {
										const label_course_id = label_info.course_id;
										const student_course_index = student_requests.findIndex(request => request.course_id === label_course_id && request.is_deleted === '0');
										if(student_course_index !== -1) results.push(label_info);
										return results;
									}, []);

									const student_course_labels = courseLabels.reduce((results, label_info) => {
										const label_course_id = label_info.course_id;
										const student_course_index = student_requests.findIndex(request => request.course_id === label_course_id && request.is_deleted === '0');
										if(student_course_index !== -1) results.push(label_info);
										return results;
									}, []);
									
									student_counter++;
									
									return (
										<div className='student-labels-student-container' key={student_id}>
											<h2 className='student-labels-student-container-heading'>{student_last_name}, {student_first_name}</h2>
											<div className='student-labels-student-container-grade'>{student_grade && `Grade ${student_grade}`}</div>
											<div className='student-labels-student-labels-parent-container'>
												<div>
													<div>Teams</div>
													{student_team_labels.length === 0 &&
														<div className='student-labels-no-label-options'>No teams added yet...</div>
													}
													{student_team_labels.map((label_info, index) => {
														const label_id = label_info.label_id;
														const label_course_id = label_info.course_id;
														
														const student_label_index = student_labels.findIndex(label => label.label_id === label_id && label.course_id === label_course_id);
														const student_has_label = (student_label_index !== -1) ? true : false;
														
														return (
															<div className='student-labels-label-option' key={`${student_id}-${label_id}-${label_course_id}`}>
																<div className='create-labels-icon pastel-blue'></div>
																<div>{label_info.course_label_name}</div>
																{student_has_label ? 
																	(
																		<FontAwesomeIcon className='fas-checkbox-checked' icon={faCheckSquare} onClick={() => updateStudentLabel('remove', student_id, label_id, label_course_id)}/>
																	) :
																	(
																		<FontAwesomeIcon className='fas-checkbox-unchecked' icon={faSquare} onClick={() => updateStudentLabel('add', student_id, label_id, label_course_id)}/>
																	)
																}
															</div>	
														)	
													})}
												</div>
												<div>
													<div>Labels</div>
													{student_course_labels.length === 0 &&
														<div className='student-labels-no-label-options'>No labels added yet...</div>
													}
													{student_course_labels.map((label_info, index) => {
														const label_id = label_info.label_id;
														const label_course_id = label_info.course_id;
														
														const student_label_index = student_labels.findIndex(label => label.label_id === label_id && label.course_id === label_course_id);
														const student_has_label = (student_label_index !== -1) ? true : false;
														
														return (
															<div className='student-labels-label-option' key={`${student_id}-${label_id}-${label_course_id}`}>
																<div className='create-labels-icon pastel-orange'></div>
																<div>{label_info.course_label_name}</div>
																{student_has_label ? 
																	(
																		<FontAwesomeIcon className='fas-checkbox-checked' icon={faCheckSquare} onClick={() => updateStudentLabel('remove', student_id, label_id, label_course_id)}/>
																	) :
																	(
																		<FontAwesomeIcon className='fas-checkbox-unchecked' icon={faSquare} onClick={() => updateStudentLabel('add', student_id, label_id, label_course_id)}/>
																	)
																}
															</div>	
														)
													})}
												</div>
											</div>
										</div>
									)	
								})}
								{(student_counter > maxStudentsToShow) && 
									<div className='btn blue-btn load-more-btn' onClick={showMoreStudents}>Load More Students</div>
								}
								</>
							)
						}
						</>
					):
					(
						<div id='student-labels-by-team-container'>
							<div>
								<div className='student-labels-by-team-header pastel-blue'>Teams</div>
								{teamLabels.length === 0 &&
									<div className='student-labels-no-label-options' style={{padding:'30px'}}>No teams added yet...</div>
								}
								{teamLabels.map((label_info, index) => {
									const course_label_name = label_info.course_label_name;
									const label_id = label_info.label_id;
									const label_course_id = label_info.course_id;
									
									const students_with_label = students.filter(student => student.student_labels.findIndex(label => label.label_id === label_id && label.course_id === label_course_id) !== -1);
									const num_students_with_label = students_with_label.length;
									const student_search_open = ('search_open' in label_info) ? true : false;
									const student_suggestions = ('student_suggestions' in label_info) ? label_info.student_suggestions : [];
									
									return (
										<div className='student-labels-student-container' key={`${label_id}-${label_course_id}`}>
											<h2 className='student-labels-student-container-heading'>{course_label_name}</h2>
											<div className='student-labels-student-count pastel-blue-text'>{num_students_with_label} student(s)</div>
											{num_students_with_label === 0 &&
												<div className='student-labels-no-label-options'>No students added yet...</div>
											}
											{students_with_label.map((student_info, index) => {
												const student_id = student_info.student_id;
												
												return (
													<div className='student-labels-label-option' key={`${student_id}-${label_id}-${label_course_id}`}>
														<div className='create-labels-icon pastel-blue'></div>
														<div>{student_info.last_name}, {student_info.first_name}</div>
														<FontAwesomeIcon className='gray-link' icon={faTimes} onClick={() => updateStudentLabel('remove', student_id, label_id, label_course_id)}/>
													</div>
												)
											})}
											<div className='student-labels-add-container'>
												<div className='blue-link student-labels-add-student-link' onClick={() => toggleSearch('team', index)}>Add Student</div>
											</div>
											{student_search_open &&
												<>
													<div className='search-add-search-container'>
														<div className='search-add-adding-input-container'>
															<input className='student-labels-search-input' placeholder='Search for a student'onChange={(e) => searchStudents(e, 'team', index)} autoFocus={true}/>
															<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
														</div>
														<FontAwesomeIcon className='gray-link' icon={faTimes} onClick={() => toggleSearch('team', index)}/>
													</div>
													<div className='section-review-search-add-search-results'>
														{student_suggestions.length !== 0 &&
															<>
															<div className='search-add-did-you-mean'>Did you mean:</div>
															{student_suggestions.map((student, i) => {
																return (
																	<div className='search-add-search-result' key={i}>
																		<div>{student.last_name}, {student.first_name}</div>
																		<div>
																			<div className='search-add-add-btn' onClick={() => updateStudentLabel('add', student.student_id, label_id, label_course_id)}>Add</div>
																		</div>
																	</div>
																)
															})}
															</>
														}
													</div>
												</>
											}
										</div>
									)	
								})}
							</div>
							<div>
								<div className='student-labels-by-team-header pastel-orange'>Labels</div>
								{courseLabels.length === 0 &&
									<div className='student-labels-no-label-options' style={{padding:'30px'}}>No labels added yet...</div>
								}
								{courseLabels.map((label_info, index) => {
									const course_label_name = label_info.course_label_name;
									const label_id = label_info.label_id;
									const label_course_id = label_info.course_id;
									
									const students_with_label = students.filter(student => student.student_labels.findIndex(label => label.label_id === label_id && label.course_id === label_course_id) !== -1);
									const num_students_with_label = students_with_label.length;
									const student_search_open = ('search_open' in label_info) ? true : false;
									const student_suggestions = ('student_suggestions' in label_info) ? label_info.student_suggestions : [];
									
									return (
										<div className='student-labels-student-container' key={`${label_id}-${label_course_id}`}>
											<h2 className='student-labels-student-container-heading'>{course_label_name}</h2>
											<div className='student-labels-student-count pastel-orange-text'>{num_students_with_label} student(s)</div>
											{num_students_with_label === 0 &&
												<div className='student-labels-no-label-options'>No students added yet...</div>
											}
											{students_with_label.map((student_info, index) => {
												const student_id = student_info.student_id;
												
												return (
													<div className='student-labels-label-option' key={`${student_id}-${label_id}-${label_course_id}`}>
														<div className='create-labels-icon pastel-orange'></div>
														<div>{student_info.last_name}, {student_info.first_name}</div>
														<FontAwesomeIcon className='gray-link' icon={faTimes} onClick={() => updateStudentLabel('remove', student_id, label_id, label_course_id)}/>
													</div>
												)
											})}
											<div className='student-labels-add-container'>
												<div className='blue-link student-labels-add-student-link' onClick={() => toggleSearch('label', index)}>Add Student</div>
											</div>
											{student_search_open &&
												<>
													<div className='search-add-search-container'>
														<div className='search-add-adding-input-container'>
															<input className='student-labels-search-input' placeholder='Search for a student'onChange={(e) => searchStudents(e, 'label', index)} autoFocus={true}/>
															<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
														</div>
														<FontAwesomeIcon className='gray-link' icon={faTimes} onClick={() => toggleSearch('label', index)}/>
													</div>
													<div className='section-review-search-add-search-results'>
														{student_suggestions.length !== 0 &&
															<>
															<div className='search-add-did-you-mean'>Did you mean:</div>
															{student_suggestions.map((student, i) => {
																return (
																	<div className='search-add-search-result' key={i}>
																		<div>{student.last_name}, {student.first_name}</div>
																		<div>
																			<div className='search-add-add-btn' onClick={() => updateStudentLabel('add', student.student_id, label_id, label_course_id)}>Add</div>
																		</div>
																	</div>
																)
															})}
															</>
														}
													</div>
												</>
											}
										</div>
									)	
								})}
							</div>
						</div>
					)
				}
			</div>
		):(userInfo && !current_user_is_admin) ?
		(
			<div className='locked-screen-notification-container'>
				<FontAwesomeIcon className='blue-text' icon={faLock} style={{fontSize:'50px'}}/>
				<h1>Locked</h1>
				<p>This step is only accessible to the master scheduler. You should be notified when future steps are unlocked!</p>
				<Link to='/progress' className='blue-text'>Go Back</Link>
			</div>
		):null}
		{(!subscreenLocked && !subscreenRestricted && current_user_is_admin) &&
			<div className='fixed-bottom-save-container'>
				<Button classes={['btn', 'btn-extra-large', 'green-btn', 'align-right', 'move-to-next-screen-btn']} selected={false} text='Continue to Place Sections' onClickFunction={handleMoveToNextScreen}/>
			</div>
		}
		</>
	)
}