import React, { useState , useEffect, useRef, useCallback } from 'react';

import { filterArrayOfObjects, sortArrayOfObjects } from '../../../js/Helpers';

import SortToggle from '../../SortToggle';
import ListFilter from '../../ListFilter';
import UploadModal from '../../UploadModal';
import Portal from '../../Portal';

import StudentRequests from '../reports/student_requests';
import BulkEditCourseRequest from '../../BulkEditCourseRequest';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronDown, faChevronUp, faCheckSquare, faUpload } from '@fortawesome/free-solid-svg-icons';
import { faSquare as farSquare } from '@fortawesome/free-regular-svg-icons';

export default function DatabaseDataStudentRequests({ schoolInfo, scheduleInfo, coursesLoading, courses, studentsLoading, students, setStudents, sections, setSections, departments, handlePullNewData}) {
	const [isLoading, setIsLoading] = useState(true);
	const [handleChangeComplete, setHandleChangeComplete] = useState(false);

	const [filteredResults, setFilteredResults] = useState([]);
	const [displayResults, setDisplayResults] = useState([]);
	const [isSearching, setIsSearching] = useState(false);

	const [selectedStudents, setSelectedStudents] = useState([]);
	const [selectedCourseID, setSelectedCourseID] = useState(null);
	const [showUploadModal, setShowUploadModal] = useState(false);

	const [toggledCourses, setToggledCourses] = useState([]);

	const filteredResultsRef = useRef(filteredResults);
	filteredResultsRef.current = filteredResults;
	
	/////////////////////////////
	//// FRONT END FUNCTIONS ////
	/////////////////////////////
	const handleIsSearching = (e) => {
		if(e.keyCode !== 8)
		{
			setIsLoading(false);
			setIsSearching(true);
		}
		else
		{
			setIsSearching(false);
		}
	}

	const toggleCourse = (course_id) => {
		const course_index = toggledCourses.findIndex(course => course === course_id);

		if(course_index !== -1)
		{
			toggledCourses.splice(course_index, 1);
		}
		else
		{
			toggledCourses.push(course_id);
		}

		setSelectedStudents([]);
		setToggledCourses([...toggledCourses]);
	}

	const toggleSelectStudent = (student_id) => {
		const student_selected_index = selectedStudents.findIndex(student => student === student_id);

		if(student_selected_index !== -1)
		{
			selectedStudents.splice(student_selected_index, 1);
		}
		else
		{
			selectedStudents.push(student_id);
		}

		setSelectedStudents([...selectedStudents]);
	}
	
	const handleChange = useCallback(() => {
		if(filteredResultsRef.current.length === 0 && isLoading) return;
		const search_value = document.getElementsByClassName("school-data-search-bar")[0].value;
		
		if(search_value.length > 1)
		{
			const filtered_results = filterArrayOfObjects(filteredResultsRef.current, search_value, ['name', 'course_code']);
			setDisplayResults([...filtered_results]);
		}
		else
		{
			setDisplayResults([...filteredResultsRef.current]);
		}
		
		//setIsLoading(false);
		setIsSearching(false);
		setHandleChangeComplete(true);
	}, [filteredResultsRef, isLoading]);
	
	const handleSortClick = (sortedData) => {
		setDisplayResults([...sortedData]);
	}

	const handleFilterClick = (filteredData) => {
		setFilteredResults([...filteredData]);
	}

	/////////////////////////////
	//// USE EFFECT FUNCTIONS ////
	/////////////////////////////
	useEffect(() => {
		handleChange();
	}, [filteredResults, handleChange]);

	useEffect(() => {
		if(!handleChangeComplete) setFilteredResults([...courses]);
	}, [courses, isLoading, handleChangeComplete]);

	useEffect(() => {
		if(!coursesLoading && !studentsLoading) setIsLoading(false);
	}, [coursesLoading, studentsLoading]);
	
	const row_style = {gridTemplateColumns:'1fr 3fr 1fr 1fr 30px'};

	//console.log(displayResults);
	//console.log({databaseData});
	//console.log({isLoading});
	
	return (
		<>
		{selectedCourseID &&
			<>
				<Portal windowName="Manage Student Requests" windowWidth="900" windowHeight="700" cleanUpFunction={() => setSelectedCourseID(null)}>
					<StudentRequests schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} sections={sections} setSections={setSections} students={students} setStudents={setStudents} courses={courses} courseID={selectedCourseID} />
				</Portal>
			</>
		}
		{showUploadModal &&
			<UploadModal schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} uploadTitle='Student Requests' uploadAPIName='studentreqdata' closeModal={() => setShowUploadModal(false)} handlePullNewData={handlePullNewData}/>
		}
		<div className='school-data-content-screen'>
			
			<h1 className='school-data-main-heading capitalize'>Student Requests</h1>
			<div className='school-data-top-bar'>
				<div className='school-data-search-bar-container'>
					<input className='school-data-search-bar' placeholder="Search for a section by course code, course name, or teacher..." onChange={handleChange} onKeyDown={(e) => handleIsSearching(e)} />
					<FontAwesomeIcon className='school-data-search-bar-icon' icon={faSearch}/>
				</div>
				<div className='btn btn-icon-left turquoise-btn' onClick={() => setShowUploadModal(true)}>
					<FontAwesomeIcon icon={faUpload} />
					<div>Upload</div>
				</div>
			</div>
			<div className='school-data-database-display'>
				<div className='school-data-database-row school-data-database-display-header fixed-heading-on-scroll' style={row_style}>
					<div className='school-data-database-header-col'>Course Code <SortToggle handleOnClick={handleSortClick} sortKey='course_code' passedData={displayResults}/></div>
					<div className='school-data-database-header-col'>Course <SortToggle handleOnClick={handleSortClick} sortKey='name' passedData={displayResults}/></div>
					<div className='school-data-database-header-col'>Department <ListFilter handleOnClick={handleFilterClick} passedData={courses} passedDataFilterKey='department_id' filterOptions={departments} filterKey='department_id' filterDisplayKey ='department'/></div>
					<div className='text-align-center'># Requests</div>
					<div></div>
				</div>
				{(!handleChangeComplete && isLoading) ?
				(
					<div className='school-data-database-message-container'>
						<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
					</div>
				): isSearching ?
				(
					<div className='school-data-database-message-container'>
						<img src={require('../../../images/searching.gif')} alt='searching...' style={{height:'80px'}}/>
					</div>
				):
				(
					<>
					{displayResults.length === 0 &&
						<div className='school-data-database-message-container'>
							<h4>Sorry, no results were found!</h4>
						</div>
					}
					{displayResults.map((course_info, index) => {
						const course_id = course_info.course_id;

						const course_name = course_info.name;
						const course_code = course_info.course_code;
						const course_departments = course_info.departments;

						const students_requesting_course = students.filter(student => (student.student_requests.findIndex(request => request.course_id === course_id && request.is_deleted === '0') !== -1));
						const sorted_students_requesting_course = (students_requesting_course.length > 0) ? sortArrayOfObjects(students_requesting_course, 'last_name', 'text', 'asc') : [];
						const num_students_requesting_course = students_requesting_course.length;
						const request_type_count = students_requesting_course.reduce((results, student) => {
							const student_id = student.student_id;
							const request_info = student.student_requests.find(request => request.course_id === course_id);
							const is_alternate = (request_info.priority !== '1');
							const is_inclusion = (request_info.is_inclusion === '1');
							if(is_alternate) results.alternate_requests.push(student_id);
							if(is_inclusion) results.inclusion_requests.push(student_id);
							return results;
						}, {alternate_requests:[], inclusion_requests:[]});

						const course_toggled = toggledCourses.includes(course_id);

						//console.log({students_requesting_course, isLoading});

						return (
							<div className='school-data-database-row' key={index}>
								<div className='upload-screen-student-request-inner-row' style={row_style}>
									<div>{course_code}</div>
									<div>{course_name}</div>
									<div>
										{course_departments.length === 0 ?
											(
												<div className='school-data-department medium-text' key={index}>No department</div>
											):
											(
												<>
												{course_departments.map((department_id, index) => {
													const department_index = departments.findIndex(department => department.department_id === department_id);
													const department = (department_index !== -1) ? departments[department_index].department : 'No department';
													
													return (
														<div className='school-data-department medium-text' key={index}>{department}</div>
													)
												})}
												</>
											)
										}
									</div>
									<div className='text-align-center'>
										{isLoading ? 
											(
												<div className='loader' style={{height:'20px', width:'20px'}}></div>
											):
											(
												<>
													<div>{num_students_requesting_course} requests</div>
													{request_type_count.alternate_requests.length > 0 &&
														<div className='medium-text purple-text'>{request_type_count.alternate_requests.length} alternates</div>
													}
													{request_type_count.inclusion_requests.length > 0 &&
														<div className='medium-text orange-text'>{request_type_count.inclusion_requests.length} inclusion</div>
													}
												</>
											)
										}
									</div>
									<FontAwesomeIcon className='gray-link' icon={course_toggled ? faChevronUp : faChevronDown} onClick={()=>{toggleCourse(course_id)}}/>
								</div>
								{course_toggled &&
									<div style={{padding:'30px 30px 30px 30px'}}>
										<div className='school-data-student-request-bulk-actions-container'>
											<div></div>
											<div className='btn blue-btn btn-small school-data-student-request-btn' onClick={() => setSelectedCourseID(course_id)}>Edit Requests</div>
										</div>
										<div className='upload-screen-request-list'>
											{(num_students_requesting_course > 0) ? 
												(
													<div className='upload-screen-request-list-inner'>
														<div className='school-data-student-requesting-row small-text'>
															<div></div>
															<div></div>
															<div style={{borderBottom:'1px dashed'}}>Code</div>
															<div style={{borderBottom:'1px dashed'}}>Last Name</div>
															<div style={{borderBottom:'1px dashed'}}>First Name</div>
															<div style={{borderBottom:'1px dashed'}}>Grade</div>
														</div>
														<BulkEditCourseRequest courseID={course_id} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} courses={courses} students={students} setStudents={setStudents} sections={sections} setSections={setSections} selectedStudents={selectedStudents} setSelectedStudents={setSelectedStudents}/>
														{sorted_students_requesting_course.map((student_info, index) => {
															const student_id = ('student_id' in student_info) ? student_info.student_id : null;
															const student_code = ('student_code' in student_info) ? student_info.student_code : null;
															const student_last_name = ('last_name' in student_info) ? student_info.last_name : null;
															const student_first_name = ('first_name' in student_info) ? student_info.first_name : null;
															const student_grade = ('grade' in student_info) ? student_info.grade : null;
															const student_request_is_alternate = request_type_count.alternate_requests.includes(student_id);
															const student_request_is_inclusion = request_type_count.inclusion_requests.includes(student_id);

															const student_selected = selectedStudents.includes(student_id);

															return (
																<div className='school-data-student-requesting-row' key={index}>
																	<FontAwesomeIcon className={student_selected ? 'blue-text' : 'dark-gray-text'} icon={student_selected ? faCheckSquare : farSquare} onClick={() => toggleSelectStudent(student_id)}/>
																	<div>
																		{student_request_is_alternate &&
																			<div className='purple-text small-text'>Alternate</div>
																		}
																		{student_request_is_inclusion &&
																			<div className='orange-text small-text'>Inclusion</div>
																		}
																	</div>
																	<div>{student_code}</div>
																	<div>{student_last_name}</div>
																	<div>{student_first_name}</div>
																	<div>{student_grade}</div>
																</div>
															)
														})}
													</div>
												):
												(
													<div className='upload-screen-empty-requests'>No students have requested this course yet!</div>
												)
											}
										</div>
									</div>
								}
							</div>
						)
					})}
					</>
				)}
			</div>
		</div>
		</>
	);
}