import React, { useState , useEffect, useRef } from 'react';
import { API } from "aws-amplify";
import swal from '@sweetalert/with-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { useOutsideClick, createUniqueObjectID, isObject } from '../../../js/Helpers';

import '../../../css/elementary/edit_modals.css';

export default function EditPeriod({ schoolInfo, periodID = null, periodType = null, dayNumber = null, days, periods, setPeriods, removePeriod, toggleEditPeriod, sidePanelClosing, setSidePanelClosing }) {
	///////////////////////////
	///// STATE VARIABLES /////
	///////////////////////////
	const [periodData, setPeriodData] = useState(null);
	const [periodDataCopied, setPeriodDatCopied] = useState(false);

	const [timeErrorMessage, setTimeErrorMessage] = useState(null);
	const [saveError, setSaveError] = useState(null);
	const [changesMade, setChangesMade] = useState(false);

	///////////////////
	///// USE REF /////
	///////////////////
	const side_panel_ref = useRef();

	useOutsideClick(side_panel_ref, () => {
		handleExitWithoutSaving();
	}, ['mb-add-new-section', 'mb-elem-block', 'elem-suggestion-box-parent', 'mb-add-new-block-options-container', 'swal-button--cancel', 'swal-button--confirm', 'dropdown-option']);

	
	/////////////////////
	///// FUNCTIONS /////
	/////////////////////
	const closeSidePanel = () => {
		toggleEditPeriod(null);

		setSidePanelClosing(true);
		setTimeErrorMessage(null);
		setSaveError(null);
		setChangesMade(false);
	}

	const validateTime = () => {
		const day_start_time = 700;
		const day_end_time = 1600;

		const start_time = document.getElementById("elem-edit-block-start-time").value;
		const end_time = document.getElementById("elem-edit-block-end-time").value;

		if(!start_time || !end_time) return {status:'error', error:'Missing start or end time'};

		const start_time_array = start_time.split(':');
		const end_time_array = end_time.split(':');

		const start_time_number = parseInt(start_time_array[0] + start_time_array[1]);
		const end_time_number = parseInt(end_time_array[0] + end_time_array[1]);

		if(start_time_number < day_start_time) return {status:'error', error:'Periods cannot start before the start of the day'};
		if(start_time_number > day_end_time) return {status:'error', error:'Periods cannot start after the end of the day'};
		if(end_time_number < day_start_time) return {status:'error', error:'Periods cannot end before the start of the day'};
		if(end_time_number > day_end_time) return {status:'error', error:'Periods cannot end after the end of the day'};
		if(start_time_number > end_time_number) return {status:'error', error:'Your period has a start time that is after the end time'};

		return {status:'valid'};
	}

	const updateState = (e, key) => {
		setChangesMade(true);
		setTimeErrorMessage(null);

		const value = (isObject(e) && 'target' in e) ? e.target.value : e;

		// If updated data is start or end time, make sure they are within the range
		// If not, return error
		let error_status;
		if(key === 'end_time' || key === 'start_time')
		{
			const validated_time = validateTime();
			error_status = validated_time.status;

			//if(error_status === 'empty') return;
			if(error_status === 'error')  setTimeErrorMessage(validated_time.error);
		}

		// Change the copied period data values
		periodData[key] = value;
		setPeriodData({...periodData});

		// Update original period so you can see the changes made in real time
		if(periodData.period_id && error_status !== 'error')
		{
			const period_index = periods.findIndex(period => period.period_id === periodData.period_id);
			if(period_index !== -1) periods[period_index] = periodData;
			setPeriods([...periods]);
		}
	}

	const updateDays = (day_number) => {
		const day_index = periodData.days.findIndex(day => day === day_number);

		if(day_index !== -1)
		{
			periodData.days.splice(day_index, 1);
		}
		else
		{
			periodData.days.push(day_number);
		}
		setPeriodData({...periodData});
	}

	const savePeriod = async () => {
		//////////////////////////
		// Validate the period data
		//////////////////////////
		const start_time_input = document.getElementById('elem-edit-block-start-time');
		const end_time_input = document.getElementById('elem-edit-block-end-time');
		const validated_time = validateTime();
		
		if(!start_time_input.value || start_time_input.value === '00:00:00' || !end_time_input.value || end_time_input.value === '00:00:00')
		{
			setSaveError("Missing a start/end time!");
			return false;
		}
		else if(validated_time.status === 'error')
		{
			setSaveError(validated_time.error);
			return false;
		}
		else if(periodData.days.length === 0)
		{
			setSaveError("Period must run on at least one day!");
			return false;
		}

		// If course is new, check if course name already exists
		// If yes, use the existing course for this period
		// If no, add/save the new course, then use for this period
		let period_id = null;

		// Save the current periods with the new updated copied data
		if(periodData.period_id)
		{
			const period_index = periods.findIndex(period => period.period_id === periodData.period_id);
			if(period_index !== -1) periods[period_index] = periodData;
			period_id = periodData.period_id;
		}
		else
		{
			const period_unique_id = createUniqueObjectID(5);
			periodData.period_id = period_unique_id;
			periods.push(periodData);
			period_id = period_unique_id;
		}

		setPeriods([...periods]);

		///////////////////////////////
		///// SAVE TO BACKEND //////
		////////////////////////////
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, period_data:periodData};
		const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
		const url = '/elementary/save-period';
		const myInit = { // OPTIONAL
			response: true,
			body: JSON.stringify(data),
		};
	
		try {
			const result = await API.post(apiName, url, myInit);
			const response_data = result.data;

			const new_period_id = response_data.new_period_id;
			const period_index = periods.findIndex(period => period.period_id === period_id);
			if(period_index !== -1) periods[period_index].period_id = new_period_id;
		} catch(e)
		{
			console.log(e.response);
		}

		closeSidePanel();
	}

	const handleExitWithoutSaving = async () => {
		let save_before_exiting = true;
		
		if(changesMade)
		{
			const options =  {
				title: "Would you like to save your changes?",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Exit without saving",
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "Continue Working",
						value: true,
						visible: true,
						className: 'blue-btn'
					},
				},
				content: (
					<div>
						<p>You have changes that have not been saved. Do you want to save them now?</p>
					</div>
				)
			}
		
			save_before_exiting = await swal(options);
		}
		
		if(!save_before_exiting) 
		{
			// If changes were made but user is not saving, revert the current board to have previous data
			if(periodData) 
			{
				if(periodData.period_id)
				{
					const period_index = periods.findIndex(period => period.period_id === periodData.period_id);
					if(period_index !== -1) periods[period_index] = periodData;
					setPeriods([...periods]);
				}

				closeSidePanel();
			}
		}
		else if(periodData)
		{
			if(!changesMade)
			{
				closeSidePanel();
				return false;
			}
			
			swal.close();
		}
	}

	const submitCheck = (e) => {
		const key_code = e.keyCode;
		if (key_code === 13)
		{
			e.preventDefault();
			savePeriod();
		}
	}

	//////////////////////
	///// USE EFFECT /////
	//////////////////////
	useEffect(() => {
		if(periodID && (!periodDataCopied || (periodID !== periodData.period_id)))
		{
			const period_index = periods.findIndex(period => period.period_id === periodID);
			const is_special = (periodType === 'special') ? '1' : '0';
			const is_pullout = (periodType === 'pullout') ? '1' : '0';
			const is_lunch = (periodType === 'lunch') ? '1' : '0';
			const period_data = (period_index !== -1) ? periods[period_index] : {period_id: null, days:[`${dayNumber}`], is_special:is_special, is_pullout:is_pullout, is_lunch:is_lunch, start_time: '00:00:00', end_time:'00:00:00'};
			
			if(period_index === -1)
			{
				const period_name =  (is_special === '1') ? 'Specials Period' : (is_pullout === '1' ? 'Pullout Period' : (is_lunch === '1' ? 'Lunch Period' : 'Untitled Period'));
				period_data.period_name = period_name;
			}
			
			setPeriodData(period_data);
			setPeriodDatCopied(true);
		}

		setSidePanelClosing(false);
	}, [periodID, dayNumber, periodData, periodDataCopied, periodType, periods, setSidePanelClosing]);

	const period_color = (!periodData) ? 'gray' : ((periodData.is_lunch === '1') ? 'pastel-yellow' : ((periodData.is_special === '1') ? 'green' :((periodData.is_pullout === '1') ? 'orange' : 'dark-purple')));

	//console.log({periods, periodID, periodType, dayNumber, periodData, searchResults, sidePanelClosing});

	return (
		<div className={`elem-modal-screen parent-div ${periodID ? 'open' :(sidePanelClosing ? 'close' : '')}`} ref={side_panel_ref}>
			<div id='elem-top-bar' className={period_color}>
				<div className='elem-modal-close' onClick={handleExitWithoutSaving}>&times;</div>
				<div id='elem-top-bar-btns'>
					{(periodData && periodData.period_id) &&
						<div className='tooltip-left align-right' data-tooltip-text='Delete Period' style={{width:'fit-content'}} onClick={() => removePeriod(periodData.period_id)}>
							<FontAwesomeIcon icon={faTrashAlt} className='opaque-link'/>
						</div>
					}
				</div>
			</div>
			<div className='elem-modal-screen-content'>
				<div className='full-width'>
					{!periodData ?
						(
							<div className='dashboard-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
							</div>
						):
						(
							<div>
								<div id='elem-edit-block-main-info'>
									<div className='elem-edit-block-label'>Period Name</div>
									<input id='elem-edit-block-name' value={periodData.period_name} onChange={(e) => updateState(e, 'period_name')} onKeyDown={submitCheck}/>
									<div className='elem-edit-block-screen'>
										<div id='elem-edit-period-time-container'>
											<input id='elem-edit-block-start-time' type='time' min='07:00' max='16:00' value={periodData.start_time} onChange={(e) => updateState(e, 'start_time')} onKeyDown={submitCheck}/>
											<div>to</div>
											<input id='elem-edit-block-end-time' type='time' min='07:00' max='16:00' value={periodData.end_time} onChange={(e) => updateState(e, 'end_time')} onKeyDown={submitCheck}/>
										</div>
										<div id='elem-edit-period-days-container'>
											{days.map((day, index) => {
												return (
													<div key={index}>
														<div>Day {day}</div>
														{(periodData.days.includes(day)) ? 
															(
																<FontAwesomeIcon className='fas-checkbox-checked' icon={faCheckSquare} onClick={() => updateDays(day)}/>
															) : 
															(
																<FontAwesomeIcon className='fas-checkbox-unchecked' icon={faSquare} onClick={() => updateDays(day)}/>
															)
														}
													</div>
												)
											})}
											
										</div>
										{timeErrorMessage && <div className='error-message-no-box'>{timeErrorMessage}</div>}
									</div>
								</div>
							</div>
						)
					}
				</div>
			</div>
			<div id='elem-edit-block-save-row'>
				{periodData &&
					<>
					{saveError && <div className='error-message-no-box' style={{marginBottom:'10px'}}>{saveError}</div>}
					<div className={`btn btn-large ${period_color}-btn`} onClick={savePeriod}>Save</div>
					</>
				}
			</div>
		</div>
	);
}