import React, { useState , useEffect, useCallback } from 'react';
import Fuse from 'fuse.js';
import { API } from "aws-amplify";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt, faUserCircle, faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons';

import { updateNote, cloneObj } from '../js/Helpers';

import '../css/universal_sidebar.css';

export default function UniversalSidebar({ schoolInfo, scheduleInfo, users, userInfo, sections, courses, students, teachers, classrooms, showUniversalSidebar, setShowUniversalSidebar, universalSidebarClosing, setUniversalSidebarClosing, universalSidebarLoadingInfo, closeUniversalSidebar }) {
	///////////////////////////
	///// STATE VARIABLES /////
	///////////////////////////
	const [isLoading, setIsLoading] = useState(true);
	const [notes, setNotes] = useState([]);
	const [newNotes, setNewNotes] = useState([]);
	const [userSearchResults, setUserSearchResults] = useState([]);
	const [saveError, setSaveError] = useState(null);

	/////////////////////
	///// FUNCTIONS /////
	/////////////////////
	const getNotes = useCallback(async () => {
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const myInit = { // OPTIONAL
			response: true,
			queryStringParameters: {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, subscreen:universalSidebarLoadingInfo.subscreen, department_id:universalSidebarLoadingInfo.department_id},
		};
		
		try {
			const response = await API.get(apiName, '/get-notes', myInit);
			const notes = response.data;

			setNotes(notes);
			setIsLoading(false);
		} catch(e)
		{
			console.log(e.response);
		}
	},[schoolInfo, universalSidebarLoadingInfo])

	const openNewNote = () => {
		const new_note = {note:null, user_id:userInfo.user_id, tagged_users:[]};
		newNotes.push(new_note);
		setNewNotes([...newNotes]);
	}

	const updateNewNote = (e, new_note_index) => {
		const new_note = e.target.value;
		newNotes[new_note_index].note = new_note;
		setNewNotes([...newNotes]);
	}

	const addTaggedUser = (new_note_index, user_id) => {
		const new_note_info = newNotes[new_note_index];
		const user_to_notify_index = new_note_info.tagged_users.findIndex(user_to_notify => user_to_notify === user_id);

		// Add users to notify
		if(user_to_notify_index === -1) newNotes[new_note_index].tagged_users.push(user_id);
		
		setNewNotes([...newNotes]);
		setUserSearchResults([]);
	}

	const removeTaggedUser = (new_note_index, tag_index) => {
		newNotes[new_note_index].tagged_users.splice(tag_index, 1);
		setNewNotes([...newNotes]);
	}
	
	const removeNewNote = (new_note_index) => {
		newNotes.splice(new_note_index, 1);
		setNewNotes([...newNotes]);
	}

	const saveNewNote = (new_note_index) => {
		// Add note info to actual note list
		const note_info = cloneObj(newNotes[new_note_index]);
		const note = note_info.note;

		if(!note)
		{
			setSaveError("You can't save an empty note!");
			return false;
		}

		note_info.time_stamp = new Date().toISOString().slice(0, 19).replace('T', ' ');
		// Add new note to front of notes array
		notes.unshift(note_info);

		// Remove note from new note list
		removeNewNote(new_note_index);

		const new_note_id = updateNote({school_id:schoolInfo.school_id, update_type:'add', schedule_version_id:schoolInfo.current_schedule_version_id, subscreen:universalSidebarLoadingInfo.subscreen, department_id:universalSidebarLoadingInfo.department_id, user_id:userInfo.user_id, note:note_info.note, tagged_users:note_info.tagged_users});

		// Add note id to the new note
		notes[0].note_id = new_note_id;
		setNotes([...notes]);
	}

	const deleteNote = (note_index) => {
		const note_id = notes[note_index].note_id;
		notes.splice(note_index, 1);
		setNotes([...notes]);

		updateNote({school_id:schoolInfo.school_id, update_type:'delete', schedule_version_id:schoolInfo.current_schedule_version_id, note_id:note_id});
	}

	const userSearch = (e) => {
		const search_value = e.target.value;
		const available_users = ('available_users' in universalSidebarLoadingInfo) ? universalSidebarLoadingInfo.available_users.filter(user => user.user_id !== userInfo.user_id) : [];

		if(!search_value) setUserSearchResults([]);

		const fuse = new Fuse(available_users, {
			keys: ['first_name', 'last_name', 'email'],
			threshold: .1
		})
		
		const results = fuse.search(search_value);
		const user_results = results.map(result => result.item).slice(0, 6);

		setUserSearchResults([...user_results]);
	}

	const handleOpenUniversalSidebar = () => {
		setNewNotes([]);
		setSaveError(null);
		setShowUniversalSidebar(true);
	}

	//////////////////////
	///// USE EFFECT /////
	//////////////////////
	useEffect(() => {
		if(!universalSidebarLoadingInfo)
		{
			setShowUniversalSidebar(false);
			setSaveError(null);
			return;
		};
		if(universalSidebarLoadingInfo.sidebar_type === 'notes') getNotes();
	}, [universalSidebarLoadingInfo, getNotes, setShowUniversalSidebar]);
	
	//console.log({newNotes, notes, users});
	//console.log({showUniversalSidebar, universalSidebarClosing, universalSidebarLoadingInfo});

	return (
		<>
		<div className='universal-toolbar'>
			<div className='universal-toolbar-btn shadow-sm' onClick={handleOpenUniversalSidebar}>
				<FontAwesomeIcon icon={faCommentAlt} />
			</div>
			<Link className='universal-toolbar-btn shadow-sm' to={`/app/hsms/documentation/clean_data/clean_department_data`} target="_blank">
				<FontAwesomeIcon icon={faQuestion} />
			</Link>
		</div>
		<div className={`universal-sidebar-modal-screen parent-div ${showUniversalSidebar ? 'open' : ''} ${(universalSidebarClosing && !showUniversalSidebar) ? 'close' : ''}`}>
			{universalSidebarLoadingInfo &&
				<>
				<div id='universal-sidebar-top-bar' className='dark-blue'>
					<div className='universal-sidebar-modal-close' onClick={closeUniversalSidebar}>&times;</div>
					<div>{universalSidebarLoadingInfo && universalSidebarLoadingInfo.sidebar_name}</div>
				</div>
				<div className='universal-sidebar-modal-screen-content'>
					<div className='full-width'>
						{isLoading ? 
						(
							<div className='center-text'>
								<img src={require('../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
							</div>
						):
						(
							<>
							{(universalSidebarLoadingInfo.sidebar_type === 'notes') ? 
							(
								<>
								{newNotes.map((new_note_info, new_note_index) => {
									const note = (new_note_info.note) ? new_note_info.note : '';
									const user_id = userInfo.user_id;
									const tagged_users = new_note_info.tagged_users;

									// Get user info
									const user_index = users.findIndex(user => user.user_id === user_id);
									const user_info = (user_index !== -1) ? users[user_index] : null;
									const user_img = (user_info) ? user_info.img : null;
									const user_email = (user_info) ? user_info.email : null;
									const user_first_name = (user_info) ? user_info.first_name : null;
									const user_last_name = (user_info) ? user_info.last_name : null;
									const user_display = (user_first_name && user_last_name) ? `${user_last_name}, ${user_first_name}` : user_email;

									return (
										<div className='universal-sidebar-note universal-sidebar-new-note shadow-sm' key={new_note_index}>
											{user_info && 
												<div className='universal-sidebar-user-container'>
													{user_img ?
													(
														<img className='universal-sidebar-note-img' src={require(`../images/users/${user_img}`)} alt='new note user img' />
													):
													(
														<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
													)}
													<div className='universal-sidebar-user-name small-text'>{user_display}</div>
												</div>
											}
											<div className='universal-sidebar-new-note-parent-container'>
												<textarea className='universal-sidebar-new-note-textarea' placeholder='Type new comment...' value={note} onChange={(e) => updateNewNote(e, new_note_index)}>
													{note}
												</textarea>
												<div className='universal-sidebar-new-note-at-user-container'>
													<div className='universal-sidebar-new-note-at-user'>
														<div>@</div>
														<input onChange={(e) => userSearch(e)} placeholder='Tag someone...'/>
													</div>
												</div>
											</div>
											{(userSearchResults.length > 0) &&
												<div className='supervisor-options-container'>
													<div className='data-options-container'>
														{userSearchResults.map((user, i) => {
															const user_id = (user) ? user.user_id : null;
															const user_first_name = (user) ? user.first_name : null;
															const user_last_name = (user) ? user.last_name : null;
															const user_display = (user_first_name && user_last_name) ? `${user_last_name}, ${user_first_name}` : user_email;
															
															return (
																<div key={i} className='data-options-option assign-resourses-supervisor-option' onClick={() => addTaggedUser(new_note_index, user_id)}>
																	{user.img_url ?
																		(
																			<img className='department-supervisor-img' src={require(`../images/users/${user.img_url}`)} alt='supervisor' />
																		):
																		(
																			<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
																		)
																	}
																	<div className='department-supervisor-name'>{user_display}</div>
																</div>
															)
														})}
													</div>
												</div>
											}
											<div className='universal-sidebar-tagged-users'>
												{tagged_users.map((tagged_user_id, tag_index) => {
													const user_index = users.findIndex(user => user.user_id === tagged_user_id);
													const user_info = (user_index !== -1) ? users[user_index] : null;
													const user_first_name = (user_info) ? user_info.first_name : null;
													const user_last_name = (user_info) ? user_info.last_name : null;
													const user_display = (user_first_name && user_last_name) ? `${user_last_name}, ${user_first_name}` : user_email;

													return (
														<div className='universal-sidebar-tagged-user' key={tag_index}>
															<div>@{user_display}</div>
															<FontAwesomeIcon className='cursor-pointer' icon={faTimes} onClick={() => removeTaggedUser(new_note_index, tag_index)}/>
														</div>
													)
												})}
											</div>
											{saveError &&
												<div className='error-message-no-box'>{saveError}</div>
											}
											<div className='universal-sidebar-btn-container'>
												<div className='btn gray-btn' onClick={() => removeNewNote(new_note_index)}>Cancel</div>
												<div className='btn green-btn' onClick={() => saveNewNote(new_note_index)}>Save</div>
											</div>
										</div>
									)
								})}
								{(newNotes.length === 0 && notes.length === 0) ?
								(
									<div>No notes have been left yet...</div>
								):
								(
									<>
									{notes.map((note_info, note_index) => {
										const note = note_info.note;
										const user_id = note_info.user_id;
										const tagged_users = note_info.tagged_users;
										const time_stamp = note_info.time_stamp;

										const t = time_stamp.split(/[- :]/);
										const note_date = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));

										const options = { year: 'numeric', month: 'short', day: '2-digit', hour:'2-digit', minute:'numeric'};
										const formatted_date = note_date.toLocaleDateString("en-US", options);


										// Get user info
										const user_index = users.findIndex(user => user.user_id === user_id);
										const user_info = (user_index !== -1) ? users[user_index] : null;
										const user_img = (user_info) ? user_info.img : null;
										const user_email = (user_info) ? user_info.email : null;
										const user_first_name = (user_info) ? user_info.first_name : null;
										const user_last_name = (user_info) ? user_info.last_name : null;
										const user_display = (user_first_name && user_last_name) ? `${user_last_name}, ${user_first_name}` : user_email;


										return (
											<div className='universal-sidebar-note shadow-sm' key={note_index}>
												<div className='universal-sidebar-user-container'>
													{user_img ?
													(
														<img className='universal-sidebar-note-user-img' src={require(`../images/users/${user_img}`)} alt='new note user img' />
													):
													(
														<FontAwesomeIcon className='universal-sidebar-note-user-icon' icon={faUserCircle}/>
													)}
													<div className='universal-sidebar-user-name small-text'>
														<div>{user_display}</div>
														<div>{formatted_date}</div>
													</div>
												</div>
												<div className='medium-text'>{note}</div>
												<div className='universal-sidebar-tagged-users'>
													{tagged_users.map((tagged_user_id, tagged_user_index) => {
														const user_index = users.findIndex(user => user.user_id === tagged_user_id);
														const user_info = (user_index !== -1) ? users[user_index] : null;
														const user_first_name = (user_info) ? user_info.first_name : null;
														const user_last_name = (user_info) ? user_info.last_name : null;
														const user_display = (user_first_name && user_last_name) ? `${user_last_name}, ${user_first_name}` : user_email;

														return (
															<div className='universal-sidebar-tagged-user' key={tagged_user_index}>
																<div>@{user_display}</div>
															</div>
														)
													})}
												</div>
												<div className='universal-sidebar-delete-note' onClick={() => deleteNote(note_index)}>
													<FontAwesomeIcon icon={faTrashAlt} />
												</div>
											</div>
										)
									})}
									</>
								)
								}
								</>
							):(universalSidebarLoadingInfo.sidebar_type === 'teacher_info') ?
							(
								<></>
							):(universalSidebarLoadingInfo.sidebar_type === 'classroom_info') ?
							(
								<></>
							):(universalSidebarLoadingInfo.sidebar_type === 'student_info') ?
							(
								<></>
							): null}
							</>
						)}
					</div>
				</div>
				{universalSidebarLoadingInfo.sidebar_type === 'notes' &&
					<div id='universal-sidebar-add-comment' className='shadow dark-blue' onClick={openNewNote}>
						<FontAwesomeIcon icon={faPlus}/>
					</div>
				}
				</>
			}
		</div>
		</>
	);
}