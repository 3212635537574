import React, { useState , useEffect } from 'react';
import Fuse from 'fuse.js';
import swal from '@sweetalert/with-react';

import Dropdown from '../../Dropdown';

import { sortArrayOfObjects, loadSpecificData, capitalizeFirstLetters, recalculateMatchPercent, calculateConflicts } from '../../../js/Helpers';
import { saveDataToDatabase } from '../../../js/SaveFunctions';

import '../../../css/hsms/edit_modals.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusCircle, faTimes, faSearch, faCheck, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSave, faSquare as farSquare } from '@fortawesome/free-regular-svg-icons';

export default function EditStudentData({ dataID, toggleEditData, schoolInfo, scheduleInfo, students, courses, teachers, departments, sections, labels, setDatabaseData }) {
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState({});
	
	const [studentRequestResults, setStudentRequestResults] = useState([]);
	const [studentLabelResults, setStudentLabelResults] = useState([]);
	const [teacherRestrictionResults, setTeacherRestrictionResults] = useState([]);
	const [studentRestrictionResults, setStudentRestrictionResults] = useState([]);
	
	const [addStudentRequestOpen, setAddStudentRequestOpen] = useState(false);
	const [addStudentLabelOpen, setAddStudentLabelOpen] = useState(false);
	const [addTeacherRestrictionOpen, setAddTeacherRestrictionOpen] = useState(false);
	const [addStudentRestrictionOpen, setAddStudentRestrictionOpen] = useState(false);
	
	const [teamLabels, setTeamLabels] = useState([]);
	const [courseLabels, setCourseLabels] = useState([]);
	
	const [changesMade, setChangesMade] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	
	const grades = (students.length === 0) ? [{display:'5', value:'5'},{display:'6', value:'6'},{display:'7', value:'7'},{display:'8', value:'8'},{display:'9', value:'9'},{display:'10', value:'10'},{display:'11', value:'11'},{display:'12', value:'12'}] : students.reduce((result, student) => {
		const student_grade = student.grade;
		if(result.findIndex(option => option.value === student_grade) === -1) result.push({display:student_grade, value:student_grade});
		return result;
	}, []);
	const sorted_grades = sortArrayOfObjects(grades, 'value', 'number', 'asc');

	const genders = [{value:'M', display:'Male'}, {value:'F', display:'Female'}, {value:'O', display: 'Other'}];
	const requestOptions = [...Array(100).keys()].map(x => {
		const value = `${x+1}`;
		return {value:value, display:value};
	});
	
	const toggleAddNewConnection = (connection_type) => {
		if(connection_type === 'student_request')
		{
			setAddStudentRequestOpen(!addStudentRequestOpen);
			setStudentRequestResults([]);
		}
		else if(connection_type === 'student_label')
		{
			setAddStudentLabelOpen(!addStudentLabelOpen);
			setStudentLabelResults([]);
		}
		else if(connection_type === 'teacher_restriction')
		{
			setAddTeacherRestrictionOpen(!addTeacherRestrictionOpen);
			setTeacherRestrictionResults([]);
		}
		else if(connection_type === 'student_restriction')
		{
			setAddStudentRestrictionOpen(!addStudentRestrictionOpen);
			setStudentRestrictionResults([]);
		}
	}
	
	const fuzzySearch = (e, dataType) => {
		const search_value = e.target.value;
		
		if(dataType === 'courses')
		{
			const fuse = new Fuse(courses, {
				keys: ['name', 'course_code'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const course_results = results.map(result => result.item);
			
			setStudentRequestResults(course_results);
		}
		else if(dataType === 'teachers')
		{
			const fuse = new Fuse(teachers, {
				keys: ['name', 'first_name'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const teacher_results = results.map(result => result.item);
			
			setTeacherRestrictionResults(teacher_results);
		}
		else if(dataType === 'students')
		{
			const fuse = new Fuse(students, {
				keys: ['first_name', 'last_name'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const student_results = results.map(result => result.item);
			
			setStudentRestrictionResults(student_results);
		}
		else if(dataType === 'labels')
		{
			const team_fuse = new Fuse(teamLabels, {
				keys: ['course_label_name'],
				threshold: .4
			})
			
			const team_search_results = team_fuse.search(search_value);
			const team_results = team_search_results.map(result => result.item);

			const course_fuse = new Fuse(courseLabels, {
				keys: ['course_label_name'],
				threshold: .4
			})
			
			const course_search_results = course_fuse.search(search_value);
			const course_results = course_search_results.map(result => result.item);

			const all_results = team_results.concat(course_results);
			
			setStudentLabelResults(all_results);
		}
	}
	
	const updateData = (change_type, data_id, data_key, new_value = null, permanently_remove = false, sub_data_key = null) => {
		if(change_type === 'add')
		{
			if(data_key === 'student_requests')
			{
				const request_index = data[data_key].findIndex(request => request.course_id === data_id);
				if(request_index !== -1)
				{
					data[data_key][request_index].matched = '0';
					data[data_key][request_index].is_inclusion = '0';
					data[data_key][request_index].is_deleted = '0';
				}
				else
				{
					data[data_key].push({request_id: 'new', course_id: data_id, matched:'0', priority:'1', is_inclusion:'0', data_origin:'2', is_deleted:'0'});
				}
			}
			else
			{
				data[data_key].push(data_id)
			}
		}
		else if(change_type === 'change')
		{
			if(data_key === 'student_requests')
			{
				if(sub_data_key === 'is_inclusion') new_value = (new_value === '0') ? '1' : '0';
				data.student_requests[data_id][sub_data_key] = new_value;
			}
			else
			{
				data[data_key] = data_id;
			}
		}
		else if(change_type === 'remove')
		{
			if(data_key === 'student_requests')
			{
				if(permanently_remove)
				{
					data[data_key].splice(data_id, 1);
				}
				else
				{
					data[data_key][data_id].matched = '0';
					data[data_key][data_id].is_deleted = '1';
				}
			}
			else
			{
				data[data_key].splice(data_id, 1);
			}
		}
		
		setAddStudentRequestOpen(false);
		setAddStudentLabelOpen(false);
		setAddTeacherRestrictionOpen(false);
		setAddStudentRestrictionOpen(false);
		
		setStudentRequestResults([]);
		setStudentLabelResults([]);
		setTeacherRestrictionResults([]);
		setStudentRestrictionResults([]);
		
		setData({...data});
		setChangesMade(true);
	}
	
	const handleExitWithoutSaving = async () => {
		let exit_without_saving = true;
		
		if(changesMade)
		{
			const options =  {
				title: "Exit without saving?",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Cancel",
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "Yes",
						value: true,
						visible: true,
						className: 'blue-btn'
					},
				},
				content: (
					<div>
						<p>You have changes that have not been saved. Do you really want to exit without saving?</p>
					</div>
				)
			}
		
			exit_without_saving = await swal(options);
		}
		
		if(exit_without_saving) toggleEditData();
	}
	
	const updateStudent = () => {
		// Make sure student data is trimmed
		data.student_code = data.student_code.trim();
		data.first_name = data.first_name.trim();
		data.last_name = data.last_name.trim();

		const student_code = data.student_code;
		const first_name = data.first_name;
		const last_name = data.last_name;
		const grade = data.grade;
		
		if(!student_code || !first_name || !last_name || !grade)
		{
			const options =  {
				title: "Missing Data",
				icon:"warning",
				buttons: {
					cancel: {
						text: "Okay",
						value: false,
						visible: true,
						className: 'blue-btn'
					}
				},
				content: (
					<div className='text-align-left'>
						<p>You are missing data! Make sure you have the following data before saving:</p>
						<ul>
							<li>Student Code</li>
							<li>First Name</li>
							<li>Last Name</li>
							<li>Gender</li>
							<li>Grade</li>
						</ul>
					</div>
				)
			}
		
			swal(options);
		}
		else
		{
			setIsSaving(true);
			
			saveDataToDatabase({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'student', data_to_save:data}).then(response_data => {
				// change data student id to new database id
				data.student_id = response_data.new_data_id;
				
				if(dataID)
				{
					const index_of_specific_data = students.findIndex(student => student.student_id === dataID);
					students[index_of_specific_data] = data;
					students[index_of_specific_data].is_deleted = '0';
				}
				else
				{
					students.push(data);
				}
				
				setDatabaseData([...students]);
				setIsSaving(false);
				toggleEditData();

				////////////////////////
				/// Update conflicts ///
				////////////////////////
				const affected_sections = sections.reduce((results, section) => {
					const section_student_list = section.student_list;
					if(section_student_list.includes(data.student_id)) results.push(section.section_id);
					return results;
				}, []);
				const conflicts_to_check = ['teacher_student_restriction', 'student_student_restriction', 'student_double_booked', 'student_conflicting_periods'];

				if(affected_sections.length > 0) calculateConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, affected_sections:affected_sections, conflicts_to_check:conflicts_to_check, update_all:false});

				// Recalculate match percent
				recalculateMatchPercent({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id});
			});
		}
	}
	
	/////////////////////////////
	///// FOCUS ON INPUT ////////
	/////////////////////////////
	useEffect(() => {
		if(addStudentRequestOpen) document.getElementById("search-student-request-input").focus();
	}, [addStudentRequestOpen]);

	useEffect(() => {
		if(addStudentLabelOpen) document.getElementById("search-student-label-input").focus();
	}, [addStudentLabelOpen]);
	
	useEffect(() => {
		if(addTeacherRestrictionOpen) document.getElementById("search-teacher-restriction-input").focus();
	}, [addTeacherRestrictionOpen]);
	
	useEffect(() => {
		if(addStudentRestrictionOpen) document.getElementById("search-student-restriction-input").focus();
	}, [addStudentRestrictionOpen]);
	
	
	/////////////////////////
	///// DO ON LOAD ////////
	/////////////////////////
	useEffect(() => {
		if(dataID)
		{
			loadSpecificData({data_type:'student', data_id:dataID, data_version_id:schoolInfo.current_data_version_id, schedule_version_id:schoolInfo.current_schedule_version_id, school_id:schoolInfo.school_id}).then(specific_data => {
				const index_of_specific_data = students.findIndex(student => student.student_id === dataID);
				students[index_of_specific_data] = specific_data;

				setDatabaseData(students);
				setData(specific_data);
				setIsLoading(false);
			});
		}
		else
		{
			setData({new: true, first_name: null, gender: null, grade: null, is_special_ed: "0", last_name: null, state_id: null, student_code: null, student_id: null, student_requests: [], student_labels:[], student_restrictions: [], teacher_restrictions: []});
			setIsLoading(false);
		}

		const course_labels = sections.reduce((result, section) => {
			const section_labels = section.labels;
			
			// If section has labels, add the course-label combo to the list (if it hasn't shown up already)
			if(section_labels.length !== 0)
			{
				const course_id = section.course_id;
				
				section_labels.forEach(label_id => {
					// Search for this label/course combo in both team and label arrays
					const course_label_index = result.labels.findIndex(course_label => course_label.label_id === label_id && course_label.course_id === course_id);
					const course_label_team_index = result.teams.findIndex(course_label => course_label.label_id === label_id && course_label.course_id === course_id);
					
					// If label/team doesn't exist in either, add it to rhe result
					if(course_label_index === -1 && course_label_team_index === -1) 
					{
						const label_info = labels.find(label => label.label_id === label_id)
						const label_name = label_info.label;
						const is_team = label_info.is_team;
						const course_name = capitalizeFirstLetters(courses.find(course => course.course_id === course_id).name);
						
						const course_label_name = `${label_name} - ${course_name}`;
						
						if(is_team === '1') 
						{
							result.teams.push({label_id:label_id, course_id:course_id, course_label_name: course_label_name});
						}
						else
						{
							result.labels.push({label_id:label_id, course_id:course_id, course_label_name: course_label_name});
						}
					}
				});
			}
			
			return result;
		}, {'teams':[], 'labels':[]});
	
		const sorted_team_course_labels = sortArrayOfObjects(course_labels.teams, 'course_label_name', 'text', 'asc');
		const sorted_label_course_labels = sortArrayOfObjects(course_labels.labels, 'course_label_name', 'text', 'asc');
		
		setTeamLabels(sorted_team_course_labels);
		setCourseLabels(sorted_label_course_labels);
	}, [dataID, schoolInfo.current_data_version_id, schoolInfo.current_schedule_version_id, schoolInfo.school_id, courses, students, sections, labels]);
	
	//console.log({dataID, toggleEditData, scheduleStructureData, students, courses, teachers, sections});
	//console.log(data);

	const student_requests_with_index = ('student_requests' in data) ? data.student_requests.reduce((results, request_info, index) => {
		request_info.index = index;
		results.push(request_info);
		return results;
	}, []) : [];
	const student_requests_non_alternates = student_requests_with_index.filter(request_info => request_info.priority === '1' && request_info.is_deleted === '0');
	const student_requests_alternates = student_requests_with_index.filter(request_info => request_info.priority !== '1' && request_info.is_deleted === '0');
	const previously_deleted_student_requests_non_alternates = student_requests_with_index.filter(request_info => request_info.priority === '1' && request_info.is_deleted === '1');
	const previously_deleted_student_requests_alternates = student_requests_with_index.filter(request_info => request_info.priority !== '1' && request_info.is_deleted === '1');
	const sorted_requests = student_requests_non_alternates.concat(student_requests_alternates).concat(previously_deleted_student_requests_non_alternates).concat(previously_deleted_student_requests_alternates);

	const requests_style = (scheduleInfo.separate_ICR_codes === '0') ? {gridTemplateColumns:'2fr 1fr 1fr 100px 30px'} : {gridTemplateColumns:'2fr 1fr 100px 30px'};

	let total_credits = 0;
	
	return (
		<div className='modal-screen'>
			<div className='modal-screen-content'>
				{!isSaving &&
					<>
					<span className="data-modal-close" onClick={handleExitWithoutSaving}>&times;</span>
					<div className="modal-save-btn" onClick={updateStudent}><FontAwesomeIcon icon={faSave}/>Save Student</div>
					</>
				}
				<div className='full-width'>
					<div className='edit-data-content'>
						<FontAwesomeIcon className='edit-data-main-head-icon' icon={faEdit}/>
						<h1 className='edit-data-main-header'>{dataID ? 'Edit' : 'Add New'} Student</h1>
						{isSaving &&
							<div className='dashboard-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
								<div>Saving...</div>
							</div>
						}
						{isLoading &&
							<div className='dashboard-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
							</div>
						}
						{(!isLoading && !isSaving) &&
							<form className='edit-data-form'>
								<div className='edit-data-grid-33' style={{gridTemplateColumns:'200px 1fr 1fr'}}>
									<div>
										<p className='modal-label'>Student ID/Code:</p>
										<input type="text" className='edit-data-input' defaultValue={data.student_code} onChange={(e) => updateData('change', e.target.value, 'student_code')}/>
									</div>
									<div>
										<p className='modal-label'>Last Name:</p>
										<input type="text" className='edit-data-input' defaultValue={data.last_name} onChange={(e) => updateData('change', e.target.value, 'last_name')}/>
									</div>
									<div>
										<p className='modal-label'>First Name:</p>
										<input type="text" className='edit-data-input' defaultValue={data.first_name} onChange={(e) => updateData('change', e.target.value, 'first_name')}/>
									</div>
								</div>
								<div className='edit-data-grid-50'>
									<div>
										<div className='data-modal-heading'>Grade</div>
										<div id="student-grade-container">
											{sorted_grades.map((grade_info, index) => {
												return (
													<div className={`option-btn ${grade_info.value === data.grade ? 'option-btn-selected' : ''}`} key={index} onClick={() => updateData('change', grade_info.value, 'grade')}>{grade_info.display}</div>
												)	
											})}
										</div>
									</div>
									<div>
										<div className='data-modal-heading'>Gender</div>
										<div id="student-gender-type-container">
											{genders.map((gender_info, index) => {
												return (
													<div className={`option-btn ${gender_info.value === data.gender ? 'option-btn-selected' : ''}`} key={index} onClick={() => updateData('change', gender_info.value, 'gender')}>{gender_info.display}</div>
												)	
											})}
										</div>
									</div>
								</div>
								<div>
									<div className='data-modal-heading'>Course Requests</div>
									<div>
										{sorted_requests.length > 0 &&
											<div className="edit-data-subheading edit-data-student-request-row" style={requests_style}>
												<div><strong>Course Name</strong></div>
												<div><strong>Priority</strong></div>
												{scheduleInfo.separate_ICR_codes === '0' && <div className='text-align-center'><strong>Inclusion?</strong></div>}
												<div>Matched?</div>
											</div>
										}
										<div className='edit-data-list'>
											{sorted_requests.length === 0 &&
												<div className='edit-data-no-data-container'>No courses have been requested yet!</div>
											}
											{sorted_requests.map((request_info, index) => {
												const data_index = request_info.index;
												const request_course_id = request_info.course_id;
												const request_match_index = sections.findIndex(section => section.course_periods.length > 0 && section.course_id === request_course_id && section.student_list.includes(dataID));
												const priority = request_info.priority;
												const is_inclusion = request_info.is_inclusion;
												const data_origin = request_info.data_origin;
												const is_deleted = (request_info.is_deleted === '1') ? true: false;

												const course_info = courses.find(course => course.course_id === request_course_id);
												const course_name = (course_info) ? course_info.name : null;
												const course_code = (course_info) ? course_info.course_code : null;
												const course_num_credits = (course_info && ('credits' in course_info) && course_info.credits && !is_deleted) ? parseFloat(course_info.credits) : 0;
												total_credits += course_num_credits;

												const course_labels = data.student_labels.filter(label => label.course_id === request_course_id);
												
												return (
													<div className='table-row-inv edit-data-student-request-row' style={requests_style} key={request_course_id} data-studentid={dataID} data-courseid={request_course_id}>
														<div className={`${is_deleted && 'opacity-25'}`}>
															<div>
																<div>{course_name} ({course_code}) <span className='purple-text small-text'>{priority !== '1' && ' - Alternate'}</span></div>
																{data_origin === '2' && <div className='small-text yellow-text'>Added in Edario</div>}
															</div>
															<div>
																{course_labels.map((course_label, i) => {
																	const label_id = course_label.label_id;
																	const label_info = labels.find(label => label.label_id === label_id);
																	
																	if(!label_info) return null;

																	const is_team = (label_info.is_team === '1');

																	return (
																		<div className={`edit-student-schedule-request-label ${is_team ? 'pastel-blue-text' : 'pastel-orange-text'}`} key={i}>{label_info.label}</div>
																	)
																})}
															</div>
														</div>
														{is_deleted ?
															(
																<>
																{scheduleInfo.separate_ICR_codes === '0' && 
																	<>
																	<div></div>
																	<div></div>
																	</>
																}
																<div className='red-text small-text opacity-50'>Deleted</div>
																<FontAwesomeIcon className='gray-to-red-link' icon={faTimes} onClick={() => updateData('remove', data_index, 'student_requests', null, true)} style={{width:'100%'}}/>
																</>
															):
															(
																<>
																<div>
																	<Dropdown data={requestOptions} currentValue={priority} handleChange={(new_value) => updateData('change', data_index, 'student_requests', new_value, null, 'priority')} />
																</div>
																{scheduleInfo.separate_ICR_codes === '0' &&
																	<div className='text-align-center cursor-pointer' onClick={() => updateData('change', data_index, 'student_requests', is_inclusion, null, 'is_inclusion')}>
																		<FontAwesomeIcon className={is_inclusion === '1' ? 'orange-text' : 'dark-gray-text'} icon={is_inclusion === '1' ? faCheckSquare : farSquare}/>
																	</div>
																}
																<div>
																	<div className={`${request_match_index !== -1 ? 'green-text' : 'gray-text'}`}>{request_match_index === -1 ? "Not " : ''}Matched {request_match_index !== -1 ? <FontAwesomeIcon icon={faCheck}/> : ''}</div>
																	<div className={`medium-text ${request_match_index !== -1 ? 'green-text' : 'gray-text'}`}>{course_num_credits} credits</div>
																</div>
																<div className="remove-data" onClick={() => updateData('remove', data_index, 'student_requests')}><FontAwesomeIcon icon={faTimes}/></div>
																</>
															)
														}
													</div>
												)	
											})}
										</div>
									</div>
									<div className='edit-student-requests-row'>
										<div className='edit-data-add-new-btn blue-link' onClick={() => toggleAddNewConnection('student_request')}>
											<FontAwesomeIcon icon={faPlusCircle}/>
											<div>Add Course Request</div>
										</div>
										<div className='edit-data-student-total-credits'>Total Credits: {total_credits}</div>
									</div>
									{addStudentRequestOpen &&
										<div className='search-add-row-container'>
											<div className='search-add-search-container'>
												<div className='search-add-adding-input-container'>
													<input id='search-student-request-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'courses')} placeholder='Search for course'/>
													<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
												</div>
												<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('student_request')}/>
											</div>
											{studentRequestResults.length !== 0 &&
												<div className='search-add-search-results'>
													<div className='search-add-did-you-mean'>Did you mean:</div>
													{studentRequestResults.map((course, i) => {
														return (
															<div className='search-add-search-result' key={i}>
																<div>{course.name} ({course.course_code})</div>
																<div>
																	<div className='search-add-add-btn' onClick={() => updateData('add', course.course_id, 'student_requests')}>Add</div>
																</div>
															</div>
														)
													})}
												</div>
											}
										</div>
									}
								</div>
								<div>
									<div className='data-modal-heading'>Special Ed Flag</div>
									<div className='edit-data-grid-50 edit-data-other-options-container'>
										<div style={{width:'100%',textAlign:'left'}}>
											<div>Does this student have an IEP?</div>
											<div className='dark-gray-text'>Answer "Yes" if this is a speciel ed student</div>
										</div>
										<div className='toggle-with-text-btn-container edit-course-toggle'>
											<div className={`toggle-with-text-btn-optn ${data.is_special_ed === '0' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '0', 'is_special_ed')}>No</div><div className={`toggle-with-text-btn-optn ${data.is_special_ed === '1' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '1', 'is_special_ed')}>Yes</div>
										</div>
									</div>
								</div>
								<div>
									<div className='data-modal-heading'>Student Teams/Labels</div>
									<div className='dark-gray-text edit-data-explanation-text'>Add student labels</div>
									<div className='edit-data-list'>
										{data.student_labels.length === 0 &&
											<div className='edit-data-no-data-container'>No student teams/labels!</div>
										}
										{data.student_labels.map((student_label, index) => {
											const course_id = student_label.course_id;
											const course_info = courses.find(course => course.course_id === course_id);
											const label_info = labels.find(label => label.label_id === student_label.label_id);
											const is_team = label_info.is_team;
											
											return (
												<div className='edit-section-section-label' key={index} style={{padding:'8px'}}>
													<div className={`create-labels-icon ${is_team === '1' ? 'pastel-blue' : 'pastel-orange'}`}></div>
													<div>{label_info.label} - {course_info.name} ({course_info.course_code})</div>
													<FontAwesomeIcon className='cancel-link' icon={faTimes} onClick={() => updateData('remove', index, 'student_labels')}/>
												</div>
											)	
										})}
									</div>
									<div className='edit-data-add-new-btn blue-link' onClick={() => toggleAddNewConnection('student_label')}>
										<FontAwesomeIcon icon={faPlusCircle}/>
										<div>Add Student Team/Label</div>
									</div>
									{addStudentLabelOpen &&
										<div className='search-add-row-container'>
											<div className='search-add-search-container'>
												<div className='search-add-adding-input-container'>
													<input id='search-student-label-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'labels')} placeholder='Search for teams/labels'/>
													<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
												</div>
												<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('student_label')}/>
											</div>
											{studentLabelResults.length !== 0 &&
												<div className='search-add-search-results'>
													<div className='search-add-did-you-mean'>Did you mean:</div>
													{studentLabelResults.map((label, i) => {
														const label_id = label.label_id;
														const course_id = label.course_id;
														const course_info = courses.find(course => course.course_id === course_id);
														const course_label_name = label.course_label_name;
														const label_info = labels.find(label => label.label_id === label_id);
														const is_team = label_info.is_team;

														return (
															<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'auto 1fr auto'}}>
																<div className={`create-labels-icon ${is_team === '1' ? 'pastel-blue' : 'pastel-orange'}`}></div>
																<div>{course_label_name} ({course_info.course_code})</div>
																<div>
																	<div className='search-add-add-btn' onClick={() => updateData('add', label, 'student_labels')}>Add</div>
																</div>
															</div>
														)
													})}
												</div>
											}
										</div>
									}
								</div>
								<div>
									<div className='data-modal-heading'>Student-Teacher Restrictions</div>
									<div className='dark-gray-text edit-data-explanation-text'>Add a teacher this student cannot be assigned to</div>
									<div className='edit-data-list'>
										{data.teacher_restrictions.length === 0 &&
											<div className='edit-data-no-data-container'>No restricted teachers!</div>
										}
										{data.teacher_restrictions.map((teacher_id, index) => {
											const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
											
											return (
												<div className='table-row-inv edit-data-teacher-restriction-row' key={index}>
													<div>{teacher_info.name}, {teacher_info.first_name}</div>
													<div>
														{teacher_info.departments.map((department_id, index) => {
															const department_index = departments.findIndex(department => department.department_id === department_id);
															const department = (department_index !== -1) ? departments[department_index].department : 'No department';
															
															return(
																<div className='dashboard-department medium-text' key={index}>{department}</div>
															)	
														})}
													</div>
													<div className="remove-data" onClick={() => updateData('remove', index, 'teacher_restrictions')}><FontAwesomeIcon icon={faTimes}/></div>
												</div>
											)	
										})}
									</div>
									<div className='edit-data-add-new-btn blue-link' onClick={() => toggleAddNewConnection('teacher_restriction')}>
										<FontAwesomeIcon icon={faPlusCircle}/>
										<div>Add Teacher Restriction</div>
									</div>
									{addTeacherRestrictionOpen &&
										<div className='search-add-row-container'>
											<div className='search-add-search-container'>
												<div className='search-add-adding-input-container'>
													<input id='search-teacher-restriction-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'teachers')} placeholder='Search for teacher'/>
													<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
												</div>
												<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('teacher_restriction')}/>
											</div>
											{teacherRestrictionResults.length !== 0 &&
												<div className='search-add-search-results'>
													<div className='search-add-did-you-mean'>Did you mean:</div>
													{teacherRestrictionResults.map((teacher, i) => {
														return (
															<div className='search-add-search-result' key={i}>
																<div>{teacher.name}, {teacher.first_name}</div>
																<div>
																	<div className='search-add-add-btn' onClick={() => updateData('add', teacher.teacher_id, 'teacher_restrictions')}>Add</div>
																</div>
															</div>
														)
													})}
												</div>
											}
										</div>
									}
								</div>
								<div>
									<div className='data-modal-heading'>Student-Student Restrictions</div>
									<div className='dark-gray-text edit-data-explanation-text'>Add a student this student cannot be in the same classes with</div>
									<div className='edit-data-list'>
										{data.student_restrictions.length === 0 &&
											<div className='edit-data-no-data-container'>No restricted students!</div>
										}
										{data.student_restrictions.map((student_id, index) => {
											const student_info = students.find(student => student.student_id === student_id);
											
											return (
												<div className='table-row-inv edit-data-student-restriction-row' key={index}>
													<div>{student_info.last_name}, {student_info.first_name}</div>
													<div className="remove-data" onClick={() => updateData('remove', index, 'student_restrictions')}><FontAwesomeIcon icon={faTimes}/></div>
												</div>
											)	
										})}
									</div>
									<div className='edit-data-add-new-btn blue-link' onClick={() => toggleAddNewConnection('student_restriction')}>
										<FontAwesomeIcon icon={faPlusCircle}/>
										<div>Add Student Restriction</div>
									</div>
									{addStudentRestrictionOpen &&
										<div className='search-add-row-container'>
											<div className='search-add-search-container'>
												<div className='search-add-adding-input-container'>
													<input id='search-student-restriction-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'students')} placeholder='Search for student'/>
													<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
												</div>
												<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('student_restriction')}/>
											</div>
											{studentRestrictionResults.length !== 0 &&
												<div className='search-add-search-results'>
													<div className='search-add-did-you-mean'>Did you mean:</div>
													{studentRestrictionResults.map((student, i) => {
														return (
															<div className='search-add-search-result' key={i}>
																<div>{student.last_name}, {student.first_name}</div>
																<div>
																	<div className='search-add-add-btn' onClick={() => updateData('add', student.student_id, 'student_restrictions')}>Add</div>
																</div>
															</div>
														)
													})}
												</div>
											}
										</div>
									}
								</div>
							</form>
						}
					</div>
				</div>
			</div>
		</div>
	);
}