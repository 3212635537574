import React, { useState, useEffect } from 'react';
import Dropdown from '../../Dropdown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import { calculateConflicts } from '../../../js/Helpers';
import { saveSchoolSettings } from '../../../js/SaveFunctions';

import '../../../css/hsms/school_setup.css';

export default function SchoolSettings({ isLoading, subscreenRestricted, subscreenLocked, schoolInfo, scheduleInfo, setScheduleInfo, handleMoveToNextScreen}) {
	const [isSubmitting, setIsSubmitting] = useState(false);
	
	const [tempScheduleInfo, setTempScheduleInfo] = useState(scheduleInfo);
	const [currentPage, setCurrentPage] = useState(1);
	
	const submitForm = () => {
		setIsSubmitting(true);
		
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, max_classes_in_a_row:tempScheduleInfo.max_classes_in_a_row, max_teacher_classes_allowed:tempScheduleInfo.max_teacher_classes_allowed, separate_ICR_codes:tempScheduleInfo.separate_ICR_codes};
		
		// Save school settings to database
		saveSchoolSettings(data, () => {
			setScheduleInfo({...scheduleInfo, ...data});

			// Update conflicts
			const conflicts_to_check = ['teacher_too_many_sections', 'teacher_too_many_sections_in_a_row'];
			calculateConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, conflicts_to_check:conflicts_to_check, update_all:true});
			
			// Move to next screen
			handleMoveToNextScreen();
		});
	}

	useEffect(() => {
		if(!isLoading) setTempScheduleInfo(scheduleInfo);
	}, [isLoading, scheduleInfo]);

	const periodOptions = [{value:'0', display:'N/A'},{value:'1', display:'1'},{value:'2', display:'2'},{value:'3', display:'3'},{value:'4', display:'4'},{value:'5', display:'5'},{value:'6', display:'6'},{value:'7', display:'7'},{value:'8', display:'8'},{value:'9', display:'9'},{value:'10', display:'10'},{value:'11', display:'11'},{value:'12', display:'12'},{value:'13', display:'13'},{value:'14', display:'14'},{value:'15', display:'15'},{value:'16', display:'16'}];

	const num_total_question_pages = document.querySelectorAll('.school-settings-question-page').length;
	
	return (
		<div className='school-setup-main-container'>
			<h1 className='progress-main-header'>School Settings</h1>
			<p className='progress-subheader'>We just a couple of more specific questions about your school.</p>
			{isLoading ?
				(
					<div className='center-text'>
						<img src={require("../../../images/balls.gif")} alt='loading...' style={{height:'40px'}}/>
					</div>
				):(subscreenRestricted) ?
				(
					<div className='general-screen-message'>
						<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
						<h1>No Access</h1>
						<div>You do not have access to this screen</div>	
					</div>
				):(subscreenLocked) ?
				(
					<div className='general-screen-message'>
						<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
						<h1>Temporarily Locked</h1>
						<div>This screen is locked until all other prior steps are completed</div>	
					</div>
				):
				(
					<div id="school-settings-container">
					<h5 style={{margin:'10px 0px 10px 0px'}}>Settings Questionairre</h5>
						<div className='school-settings-page-counter-container'>
							<div className='school-settings-page-counter-current'>0{currentPage}</div>
							<div className='school-settings-page-counter-total gray-text'>of 0{num_total_question_pages}</div>
						</div>
						<div className={`school-settings-question-page ${currentPage === 1 && 'school-settings-current-page'}`}>
							<div className='school-settings-question-container'>
								<div className='school-settings-question'>In a single day, what are the max number of periods <strong>OVERALL</strong> that a teacher can teach?</div>
								<div className='school-settings-dropdown-container'>
									<Dropdown data={periodOptions} currentValue={tempScheduleInfo.max_teacher_classes_allowed} handleChange={(new_value) => setTempScheduleInfo({...tempScheduleInfo, max_teacher_classes_allowed:new_value})} />
									<div className='medium-text'>periods total</div>
								</div>
							</div>
							<div className="btn green-btn btn-large full-width" onClick={()=>setCurrentPage(currentPage+1)}>Next</div>
						</div>
						<div className={`school-settings-question-page ${currentPage === 2 && 'school-settings-current-page'}`}>
							<div className='school-settings-question-container'>
								<div className='school-settings-question'>In a single day, what is the max number of periods <strong>IN A ROW</strong> that a teacher can teach?</div>
								<div className='school-settings-dropdown-container'>
									<Dropdown data={periodOptions} currentValue={tempScheduleInfo.max_classes_in_a_row} handleChange={(new_value) => setTempScheduleInfo({...tempScheduleInfo, max_classes_in_a_row:new_value})} />
									<div className='medium-text'>periods in a row</div>
								</div>
							</div>
							<div style={{display:'grid', gridTemplateColumns:'1fr 1fr',columnGap:'15px'}}>
								<div className="btn gray-btn btn-large full-width" onClick={()=>setCurrentPage(currentPage-1)}>Back</div>
								<div className="btn green-btn btn-large full-width" onClick={()=>setCurrentPage(currentPage+1)}>Next</div>
							</div>
						</div>
						<div className={`school-settings-question-page ${currentPage === 3 && 'school-settings-current-page'}`}>
							<div className='school-settings-question-container'>
								<div className='school-settings-question'>Does your school use separate course codes for Inclusion, ICR, or ICS courses?</div>
								<div className={`option-btn ${tempScheduleInfo.separate_ICR_codes === '1' && 'option-btn-selected'}`} onClick={() => setTempScheduleInfo({...tempScheduleInfo, separate_ICR_codes:'1'})}>Yes</div>
								<div className={`option-btn ${tempScheduleInfo.separate_ICR_codes === '0' && 'option-btn-selected'}`} onClick={() => setTempScheduleInfo({...tempScheduleInfo, separate_ICR_codes:'0'})}>No</div>
							</div>
							{!isSubmitting ? 
								(
									<div style={{display:'grid', gridTemplateColumns:'1fr 1fr',columnGap:'15px'}}>
										<div className="btn gray-btn btn-large full-width" onClick={()=>setCurrentPage(currentPage-1)}>Back</div>
										<div className="btn green-btn btn-large full-width" onClick={submitForm}>Submit</div>
									</div>
								) : 
								(
									<div style={{display:'grid', gridTemplateColumns:'1fr 1fr',columnGap:'15px'}}>
										<div className="btn gray-btn btn-large full-width">Back</div>
										<div className="btn green-btn btn-large full-width"><img src={require('../../../images/ajax-loader.gif')} alt='Loading' style={{height:'20px'}}/></div>
									</div>
								)
							}
						</div>
					</div>
				)
			}
		</div>
	);
}