import React, { useState , useEffect } from 'react';
import Fuse from 'fuse.js';
import swal from '@sweetalert/with-react';

import Dropdown from '../../Dropdown';

import { loadSpecificData, sortArrayOfObjects, formatTime } from '../../../js/Helpers';
import { saveDataToDatabase } from '../../../js/SaveFunctions';

import '../../../css/hsms/edit_modals.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusCircle, faTimes, faSearch, faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-regular-svg-icons';

export default function EditTeacherData({ dataID, toggleEditData, schoolInfo, scheduleInfo, periods, teachers, courses, classrooms, students, setStudents, blocks, setDatabaseData, filteredResults, setFilteredResults = ()=>{} }) {
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState({});
	const [days, setDays] = useState([]);
	
	const [tctResults, setTctResults] = useState([]);
	const [studentResults, setStudentResults] = useState([]);

	const [addTctOpen, setAddTctOpen] = useState(false);
	const [addStudentsOpen, setAddStudentsOpen] = useState(false);

	const [changesMade, setChangesMade] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	
	const [classroomOptions, setClassroomOptions] = useState([]);
	const primary_secondary_options = [{value:'1', display:'Primary'}, {value:'2', display:'Back Up'}];

	const group_positions = {
		'1':{'0':{start:'1', end:'120'}}, 
		'2':{'0':{start:'1', end:'58'},'1':{start:'62', end:'120'}}, 
		'3':{'0':{start:'1', end:'38'},'1':{start:'42', end:'78'},'2':{start:'82', end:'120'}}, 
		'4':{'0':{start:'1', end:'28'},'1':{start:'32', end:'58'},'2':{start:'62', end:'88'},'3':{start:'92', end:'120'}}
	};

	const calculatePeriodColumnStartEnd = (period_data, day_schedule, logged_positions, start_time, end_time) => {
		const overlapCheck = (time1, time2) => {
			const start_time1 = time1.start_time;
			const end_time1 = time1.end_time;
			const start_time1_array = start_time1.split(':');
			const end_time1_array = end_time1.split(':');

			const start_time1_number = parseInt(start_time1_array[0] + start_time1_array[1]);
			const end_time1_number = parseInt(end_time1_array[0] + end_time1_array[1]);

			const start_time2 = time2.start_time;
			const end_time2 = time2.end_time;
			const start_time2_array = start_time2.split(':');
			const end_time2_array = end_time2.split(':');

			const start_time2_number = parseInt(start_time2_array[0] + start_time2_array[1]);
			const end_time2_number = parseInt(end_time2_array[0] + end_time2_array[1]);

			if(end_time1_number > start_time2_number && end_time2_number > start_time1_number) return true;

			return false;
		}

		const period_id = period_data.period_id;

		///// Calculate left and right position
		// Get max number of overlaps
		const period_time = {period_id:period_id, start_time:start_time, end_time:end_time};

		let overlapping_time_groups = [[period_time]];
		for(var i = 0; i < day_schedule.length; i++)
		{
			const new_period = day_schedule[i];
			const new_period_id = new_period.period_id;

			if(period_id === new_period_id) continue; //ignore the original period itself

			const new_time = {period_id:new_period_id, start_time:new_period.start_time, end_time:new_period.end_time};
			const new_time_overlaps_original = overlapCheck(period_time, new_time);

			if(!new_time_overlaps_original) continue; //ignore periods that don't even overlap original

			// Go through all overlap groups and see if new period overlaps all of the times in the current groups
			// If yes, add to that group
			// If no, create a new group
			for(var j = 0; j < overlapping_time_groups.length; j++)
			{
				const overlapping_time_group = overlapping_time_groups[j];

				// Ignore a period that has already been added to this group
				const new_time_index = overlapping_time_group.findIndex(overlapping_time => overlapping_time.period_id === new_period_id);
				if(new_time_index !== -1) continue;

				let new_time_overlaps_entire_group = true;
				
				for(var k = 0; k < overlapping_time_group.length; k++)
				{
					const time_to_check = overlapping_time_group[k];
					const time_overlaps = overlapCheck(time_to_check, new_time);

					if(!time_overlaps) new_time_overlaps_entire_group = false;
				}

				if(new_time_overlaps_entire_group)
				{
					overlapping_time_groups[j].push(new_time);
				}
				else
				{
					overlapping_time_groups.push([period_time, new_time]);
				}
			}
		}

		const overall_max_stats = overlapping_time_groups.reduce((results, overlapping_time_group) => {
			const overlapping_time_group_length = overlapping_time_group.length;
			if(overlapping_time_group_length > results.max_overlaps) results = {max_overlaps: overlapping_time_group_length, overlapping_periods:overlapping_time_group};
			return results;
		}, {max_overlaps:0, overlapping_periods:[]});

		/// Use max overlaps to get the possible positions (preset depending on number of overlaps)
		const max_overlaps = overall_max_stats.max_overlaps;
		const overlapping_periods = overall_max_stats.overlapping_periods;

		const possible_positions = group_positions[max_overlaps];
		const possible_positions_indexes = Object.keys(possible_positions);
		const used_positions = overlapping_periods.reduce((results, period_times) => {
			const overlapping_period_id = period_times.period_id;
			const used_position = (overlapping_period_id in logged_positions) ? logged_positions[overlapping_period_id] : null;
			if(used_position) results.push(used_position);
			return results;
		},[]);
		const unused_positions = possible_positions_indexes.filter(x => used_positions.indexOf(x) === -1);
		const position_index = unused_positions[0];
		const start_position = possible_positions[position_index].start;
		const end_position = possible_positions[position_index].end;

		return {position_index:position_index, col_start:start_position, col_end:end_position};
	}
		
	const toggleAddNewConnection = (connection_type) => {
		if(connection_type === 'tct')
		{
			setAddTctOpen(!addTctOpen);
			setTctResults([]);
		}
		if(connection_type === 'student')
		{
			setAddStudentsOpen(!addStudentsOpen);
			setStudentResults([]);
		}
	}
	
	const fuzzySearch = (e, dataType) => {
		const search_value = e.target.value;
		
		if(dataType === 'courses')
		{
			const fuse = new Fuse(courses, {
				keys: ['name', 'course_code'],
				threshold: .1
			});
			
			const results = fuse.search(search_value);
			const course_results = results.map(result => result.item);
			
			setTctResults(course_results);
		}
		if(dataType === 'students')
		{
			const fuse = new Fuse(students, {
				keys: ['first_name', 'last_name'],
				threshold: .1
			});
			
			const results = fuse.search(search_value);
			const student_results = results.map(result => result.item);
			
			setStudentResults(student_results);
		}
	}
	
	const updateData = (change_type, data_id, data_key, tct_type = null) => {
		if(change_type === 'add')
		{
			const data_to_add = (data_key === 'teacher_can_teach') ? {course_id:data_id, type:'1'} : data_id;
			data[data_key].push(data_to_add);
		}
		else if(change_type === 'change')
		{
			if(data_key === 'teacher_can_teach')
			{
				data[data_key][data_id].type = tct_type;
			}
			else
			{
				data[data_key] = data_id;
			}
		}
		else if(change_type === 'remove')
		{
			data[data_key].splice(data_id, 1);
		}
		
		setAddTctOpen(false);
		setTctResults([]);

		setAddStudentsOpen(false);
		setStudentResults([]);
		
		setData({...data});
		setChangesMade(true);
	}
	
	const handleExitWithoutSaving = async () => {
		let exit_without_saving = true;
		
		if(changesMade)
		{
			const options =  {
				title: "Exit without saving?",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Cancel",
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "Yes",
						value: true,
						visible: true,
						className: 'blue-btn'
					},
				},
				content: (
					<div>
						<p>You have changes that have not been saved. Do you really want to exit without saving?</p>
					</div>
				)
			}
		
			exit_without_saving = await swal(options);
		}
		
		if(exit_without_saving) toggleEditData();
	}
	
	const updateTeacher = () => {
		const first_name = data.first_name;
		const name = data.name;
		
		if(!first_name || !name)
		{
			const options =  {
				title: "Missing Teacher First or Last Name",
				icon:"warning",
				buttons: {
					cancel: {
						text: "Okay",
						value: false,
						visible: true,
						className: 'blue-btn'
					}
				},
				content: (
					<div>
						<p>You need to enter a teacher's first and last name before saving!</p>
					</div>
				)
			}
		
			swal(options);
		}
		else
		{
			setIsSaving(true);
			
			saveDataToDatabase({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'teacher', data_to_save:data, database:'elementary'}).then(response_data => {
				const new_teacher_id = response_data.new_data_id;

				// change data teacher to new database id
				data.teacher_id = new_teacher_id;
				
				if(dataID)
				{
					const index_of_specific_data = teachers.findIndex(teacher => teacher.teacher_id === dataID);
					teachers[index_of_specific_data] = data;

					const filter_results_index = filteredResults.findIndex(teacher => teacher.teacher_id === dataID);
					filteredResults[filter_results_index] = data;
				}
				else
				{
					teachers.push(data);
					filteredResults.push(data);
				}

				// Change student's homerooms
				// First delete all students who used to have this homeroom, then add new students
				const new_students = students.reduce((results, student) => {
					const student_id = student.student_id;
					const homeroom_teacher_id = student.homeroom_teacher_id;
					
					if(data.homeroom_students.includes(student_id)) 
					{
						student.homeroom_teacher_id = new_teacher_id;
					}
					else if(student.homeroom_teacher_id === homeroom_teacher_id)
					{
						student.homeroom_teacher_id = null;
					}
					
					results.push(student);
					return results;
				}, []);
				
				setStudents([...new_students]);
				setDatabaseData([...teachers]);
				setFilteredResults([...filteredResults]);
				setIsSaving(false);
				toggleEditData();
			});
		}
	}
	
	/////////////////////////////
	///// DO ON CHANGE ////////
	/////////////////////////////
	useEffect(() => {
		if(addTctOpen) document.getElementById("search-tct-input").focus();
	}, [addTctOpen]);

	useEffect(() => {
		const classroom_options = classrooms.reduce((results, classroom) => {
			const classroom_id = classroom.classroom_id;
			const classroom_name = classroom.classroom_name;

			const new_option = {value:classroom_id, display:classroom_name};
			results.push(new_option);
			return results;
		}, []);

		setClassroomOptions(classroom_options);
	}, [classrooms]);

	useEffect(() => {
		const num_days_in_cycle = scheduleInfo.num_days_in_cycle;

		let new_days = [];

		for(let day = 1; day <= num_days_in_cycle; day++)
		{
			new_days.push(`${day}`);
		}

		setDays(new_days);
	}, [scheduleInfo]);
	
	/////////////////////////
	///// DO ON LOAD ////////
	/////////////////////////
	useEffect(() => {
		if(dataID)
		{
			loadSpecificData({data_type:'teacher', data_id:dataID, data_version_id:schoolInfo.current_data_version_id, schedule_version_id:schoolInfo.current_schedule_version_id, school_id:schoolInfo.school_id, database:'elementary'}).then(specific_data => {
				const index_of_specific_data = teachers.findIndex(teacher => teacher.teacher_id === dataID);
				teachers[index_of_specific_data] = specific_data;

				setDatabaseData(teachers);
				setData(specific_data);
				setIsLoading(false);
			});
		}
		else
		{
			setData({new: true, first_name: null, homeroom_classroom_id:null, homeroom_students:[], name: null, period_restrictions:[], teacher_can_teach: [], is_homeroom_teacher:'0'});
			setIsLoading(false);
		}
	}, [dataID, schoolInfo.school_id, schoolInfo.current_data_version_id, schoolInfo.current_schedule_version_id, teachers]);

	const teacher_teaches = ('teacher_can_teach' in data) ? data.teacher_can_teach.reduce((result, tct_course_info) => {
		const tct_course_id = tct_course_info.course_id;
		const course_info = courses.find(course => course.course_id === tct_course_id);
		if(course_info && course_info.is_special === '1') result.teacher_teaches_specials = true;
		if(course_info && course_info.is_pullout === '1') result.teacher_teaches_pullouts = true;
		return result;
	}, {teacher_teaches_specials: false, teacher_teaches_pullouts: false}) : {teacher_teaches_specials: false, teacher_teaches_pullouts: false};
	const teacher_teaches_specials = teacher_teaches.teacher_teaches_specials;
	const teacher_teaches_pullouts = teacher_teaches.teacher_teaches_pullouts;

	const num_cols = days.length;
	const day_period_grid = {gridTemplateColumns : `repeat(${num_cols}, minmax(90px, 1fr))`};
	
	//console.log(data);
	//console.log(students);
	
	return (
		<div className='modal-screen'>
			<div className='modal-screen-content'>
				{!isSaving &&
					<>
					<span className="data-modal-close" onClick={handleExitWithoutSaving}>&times;</span>
					<div className="modal-save-btn" onClick={updateTeacher}><FontAwesomeIcon icon={faSave}/>Save Teacher</div>
					</>
				}
				<div className='full-width'>
					<div className='edit-data-content'>
						<FontAwesomeIcon className='edit-data-main-head-icon' icon={faEdit}/>
						<h1 className='edit-data-main-header'>{dataID ? 'Edit' : 'Add New'} Teacher</h1>
						{isSaving &&
							<div className='magnetboard-screen-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
								<div>Saving...</div>
							</div>
						}
						{isLoading &&
							<div className='magnetboard-screen-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
							</div>
						}
						{(!isLoading && !isSaving) &&
							<form className='edit-data-form'>
								<div className='edit-data-grid-33' style={{gridTemplateColumns:'200px 1fr 1fr'}}>
									<div>
										<p className='modal-label'>Teacher Code:</p>
										<input type="text" className='edit-data-input' defaultValue={data.teacher_code} onChange={(e) => updateData('change', e.target.value, 'teacher_code')}/>
									</div>
									<div>
										<p className='modal-label'>Last Name:</p>
										<input type="text" className='edit-data-input' defaultValue={data.name} onChange={(e) => updateData('change', e.target.value, 'name')}/>
									</div>
									<div>
										<p className='modal-label'>First Name:</p>
										<input type="text" className='edit-data-input' defaultValue={data.first_name} onChange={(e) => updateData('change', e.target.value, 'first_name')}/>
									</div>
								</div>
								<div>
									<div className='data-modal-heading'>Courses this Teacher Teaches</div>
									<div>
										{data.teacher_can_teach.length > 0 &&
											<div className="edit-data-subheading edit-data-teacher-can-teach-row">
												<div><strong>Course Name</strong></div>
												<div>Primary/Back Up</div>
											</div>
										}
										{data.teacher_can_teach.length === 0 &&
											<div className='edit-data-no-data-container'>This teacher is not set to teach any courses yet!</div>
										}
										{data.teacher_can_teach.map((tct_info, index) => {
											const course_id = tct_info.course_id;
											const primary_secondary = tct_info.type;
											
											const course_info = courses.find(course => course.course_id === course_id);
											
											return (
												<div className='table-row-inv edit-data-teacher-can-teach-row' key={index}>
													<div>{course_info.name} {course_info.course_code && `(${course_info.course_code})`}</div>
													<div>
														<Dropdown data={primary_secondary_options} currentValue={primary_secondary} handleChange={(new_value) => updateData('change', index, 'teacher_can_teach', new_value)} />
													</div>
													<div className="remove-data" onClick={() => updateData('remove', index, 'teacher_can_teach')}><FontAwesomeIcon icon={faTimes}/></div>
												</div>
											)	
										})}
									</div>
									<div className='edit-data-add-new-btn blue-link' onClick={() => toggleAddNewConnection('tct')}>
										<FontAwesomeIcon icon={faPlusCircle}/>
										<div>Add Course for Teacher</div>
									</div>
									{addTctOpen &&
										<div className='search-add-row-container'>
											<div className='search-add-search-container'>
												<div className='search-add-adding-input-container'>
													<input id='search-tct-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'courses')} placeholder='Search for course'/>
													<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
												</div>
												<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('tct')}/>
											</div>
											{tctResults.length !== 0 &&
												<div className='search-add-search-results'>
													<div className='search-add-did-you-mean'>Did you mean:</div>
													{tctResults.map((course, i) => {
														return (
															<div className='search-add-search-result' key={i}>
																<div>{course.name} {course.course_code && `(${course.course_code})`}</div>
																<div>
																	<div className='search-add-add-btn' onClick={() => updateData('add', course.course_id, 'teacher_can_teach')}>Add</div>
																</div>
															</div>
														)
													})}
												</div>
											}
										</div>
									}
								</div>
								<div>
									<div className='data-modal-heading'>Is this a homeroom teacher?</div>
									<div className='toggle-with-text-btn-container edit-course-toggle' style={{marginTop:'10px'}}>
										<div className={`toggle-with-text-btn-optn ${data.is_homeroom_teacher === '1' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '1', 'is_homeroom_teacher')}>Yes</div><div className={`toggle-with-text-btn-optn ${(!data.is_homeroom_teacher || data.is_homeroom_teacher === '0') ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '0', 'is_homeroom_teacher')}>No</div>
									</div>
								</div>
								{(data.is_homeroom_teacher === '1') &&
									<div className='edit-data-grid-50'>
										<div>
											<div className='data-modal-heading'>Homeroom Classroom</div>
											<div className='edit-data-explanation-text medium-text'>If this teacher is a homeroom teacher, add a classroom</div>
											<div className='edit-data-dropdown-container'>
												<Dropdown data={classroomOptions} displayDefault='--' currentValue={data.homeroom_classroom_id} handleChange={(new_value) => updateData('change', new_value, 'homeroom_classroom_id')} />
											</div>
										</div>
										<div>
											<div className='data-modal-heading'>Homeroom Students</div>
											<div className='edit-data-explanation-text medium-text'>If this teacher is a homeroom teacher, add students</div>
											<div className='edit-data-list'>
												{data.homeroom_students.length === 0 &&
													<div className='edit-data-no-data-container'>No homeroom students have been added yet!</div>
												}
												{data.homeroom_students.map((student_id, index) => {
													const student_info = students.find(student => student.student_id === student_id);
													const display_grade = (student_info.grade === '0') ? "K" : student_info.grade;
													
													return (
														<div className='table-row-inv edit-data-teacher-classroom-row' key={index}>
															<div>{student_info.last_name}, {student_info.first_name}</div>
															<div>{display_grade}</div>
															<div className="remove-data" onClick={() => updateData('remove', index, 'homeroom_students')}><FontAwesomeIcon icon={faTimes}/></div>
														</div>
													)	
												})}
											</div>
											<div className='edit-data-add-new-btn blue-link align-right' onClick={() => toggleAddNewConnection('student')}>
												<FontAwesomeIcon icon={faPlusCircle}/>
												<div>Add Student</div>
											</div>
											{addStudentsOpen &&
												<div className='search-add-row-container'>
													<div className='search-add-search-container'>
														<div className='search-add-adding-input-container'>
															<input id='search-tct-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'students')} placeholder='Search for student'/>
															<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
														</div>
														<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('student')}/>
													</div>
													{studentResults.length !== 0 &&
														<div className='search-add-search-results'>
															<div className='search-add-did-you-mean'>Did you mean:</div>
															{studentResults.map((student, i) => {
																const display_grade = (student.grade === '0') ? "K" : student.grade;

																return (
																	<div className='search-add-search-result' key={i}>
																		<div>{student.last_name}, {student.first_name} (Grade {display_grade})</div>
																		<div>
																			<div className='search-add-add-btn' onClick={() => updateData('add', student.student_id, 'homeroom_students')}>Add</div>
																		</div>
																	</div>
																)
															})}
														</div>
													}
												</div>
											}
										</div>
									</div>
								}
								{teacher_teaches_specials &&
									<div>
										<div className='data-modal-heading'>Periods teacher is available for specials</div>
										<div className='mb-elem-teacher-schedule-mini'>
											<div className='mb-elem-week-schedule-container-mini'>
												<div className='mb-elem-week-schedule-time-container-mini'>
													<div className='mb-elem-time-delimiter-mini'>
														<div>7:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>8:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>9:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>10:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>11:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>12:00PM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>1:00PM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>2:00PM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>3:00PM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>4:00PM</div>
													</div>
												</div>
												<div className='mb-elem-week-schedule-days-container-mini' style={day_period_grid}>
													{days.map(day_number => {
														const day_name = `Day ${day_number}`;

														const day_schedule = periods.filter(period => period.days.includes(day_number) && period.is_special === '1');

														///// Organize all the periods into overlapping groups /////
														// Any non overlapping periods get thrown into their own group
														const day_schedule_with_overlaps = day_schedule.reduce((results, period) => {
															const period_start_time = period.start_time;
															const period_end_time = period.end_time;

															const period_start_time_array = period_start_time.split(':');
															const period_end_time_array = period_end_time.split(':');

															const period_start_time_hour = parseInt(period_start_time_array[0]);
															const period_start_time_min = parseInt(period_start_time_array[1]);
															const period_start_num_hours = period_start_time_hour - 7;
															const period_row_start = Math.max(1, period_start_num_hours*60 + period_start_time_min);

															const period_end_time_hour = parseInt(period_end_time_array[0]);
															const period_end_time_min = parseInt(period_end_time_array[1]);
															const period_end_num_hours = period_end_time_hour - 7;
															const period_row_end = Math.max(1, period_end_num_hours*60 + period_end_time_min);

															period.row_start = period_row_start;
															period.row_end = period_row_end;
															period.period_length = period_row_end - period_row_start;

															results.push(period);
															return results;
														},[]);

														const sorted_day_schedule_with_overlaps = sortArrayOfObjects(day_schedule_with_overlaps, 'period_length', 'number', 'desc');

														const logged_positions = {};

														return (
															<div className='mb-elem-week-day-container-mini' key={`${day_number}`}>
																<h3>{day_name}</h3>
																<div className='mb-elem-week-day-block-parent-container-mini'>
																	<div className='mb-elem-week-day-grid-mini'>
																		{sorted_day_schedule_with_overlaps.map(period => {
																			const period_id = period.period_id;
																			const period_name = period.period_name;
																			const is_lunch = period.is_lunch;
																			const is_special = period.is_special;
																			const is_pullout = period.is_pullout;
																			
																			const start_time = period.start_time;
																			const end_time = period.end_time;
																			const start_time_formatted = formatTime(start_time);
																			const end_time_date_formatted = formatTime(end_time);

																			const row_start = period.row_start;
																			const row_end = period.row_end;

																			const period_color = (is_lunch === '1') ? 'pastel-yellow' : ((is_special === '1') ? 'green' :((is_pullout === '1') ? 'orange' : 'dark-purple'));

																			// Calculate the column start and end position on the grid
																			const period_position = calculatePeriodColumnStartEnd(period, day_schedule_with_overlaps, logged_positions, start_time, end_time);
																			
																			const position_index = period_position.position_index;
																			const col_start = period_position.col_start;
																			const col_end = period_position.col_end;

																			logged_positions[period_id] = position_index;

																			const day_period_index = data.day_period_restrictions.findIndex(day_period_restriction => day_period_restriction.day_number === day_number && day_period_restriction.period_id === period_id)
																			const day_is_restricted = day_period_index !== -1;
																			
																			return (
																				<div className='mb-elem-block-mini parent-div' style={{gridColumnStart:col_start, gridColumnEnd:col_end, gridRowStart:row_start, gridRowEnd:row_end}} key={period_id}>
																					<div className={`mb-elem-block-inner-mini left-border-${period_color}-thick`} >
																						<div>
																							{(!day_is_restricted) ? 
																								(
																									<FontAwesomeIcon className='fas-checkbox-checked' icon={faCheckSquare} onClick={() => updateData('add', {day_number:day_number, period_id:period_id}, 'day_period_restrictions')}/>
																								) : 
																								(
																									<FontAwesomeIcon className='fas-checkbox-unchecked' icon={faSquare} onClick={() => updateData('remove', day_period_index, 'day_period_restrictions')}/>
																								)
																							}
																						</div>
																						<div className='ellipsis'>
																							<div className={`mb-elem-block-header-mini`}>
																								<span className='tooltip' data-tooltip-text={period_name}>
																									<div className='mb-elem-block-name-mini ellipsis'>{period_name}</div>
																								</span>
																							</div>
																							<div className={`mb-elem-block-content-mini`}>
																								<div className='tooltip' data-tooltip-text={`${start_time_formatted} - ${end_time_date_formatted}`}>
																									<div className='mb-elem-block-time-mini ellipsis'>{start_time_formatted} - {end_time_date_formatted}</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			)
																		})}
																	</div>
																</div>
															</div>
														)
													})}
												</div>
											</div>
										</div>
									</div>
								}
								{teacher_teaches_pullouts &&
									<div>
										<div className='data-modal-heading'>Periods teacher is available for pullouts</div>
										<div className='mb-elem-teacher-schedule-mini'>
											<div className='mb-elem-week-schedule-container-mini'>
												<div className='mb-elem-week-schedule-time-container-mini'>
													<div className='mb-elem-time-delimiter-mini'>
														<div>7:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>8:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>9:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>10:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>11:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>12:00PM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>1:00PM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>2:00PM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>3:00PM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>4:00PM</div>
													</div>
												</div>
												<div className='mb-elem-week-schedule-days-container-mini' style={day_period_grid}>
													{days.map(day_number => {
														const day_name = `Day ${day_number}`;

														const day_schedule = periods.filter(period => period.days.includes(day_number) && period.is_pullout === '1');

														///// Organize all the periods into overlapping groups /////
														// Any non overlapping periods get thrown into their own group
														const day_schedule_with_overlaps = day_schedule.reduce((results, period) => {
															const period_start_time = period.start_time;
															const period_end_time = period.end_time;

															const period_start_time_array = period_start_time.split(':');
															const period_end_time_array = period_end_time.split(':');

															const period_start_time_hour = parseInt(period_start_time_array[0]);
															const period_start_time_min = parseInt(period_start_time_array[1]);
															const period_start_num_hours = period_start_time_hour - 7;
															const period_row_start = Math.max(1, period_start_num_hours*60 + period_start_time_min);

															const period_end_time_hour = parseInt(period_end_time_array[0]);
															const period_end_time_min = parseInt(period_end_time_array[1]);
															const period_end_num_hours = period_end_time_hour - 7;
															const period_row_end = Math.max(1, period_end_num_hours*60 + period_end_time_min);

															period.row_start = period_row_start;
															period.row_end = period_row_end;
															period.period_length = period_row_end - period_row_start;

															results.push(period);
															return results;
														},[]);

														const sorted_day_schedule_with_overlaps = sortArrayOfObjects(day_schedule_with_overlaps, 'period_length', 'number', 'desc');

														const logged_positions = {};

														return (
															<div className='mb-elem-week-day-container-mini' key={`${day_number}`}>
																<h3>{day_name}</h3>
																<div className='mb-elem-week-day-block-parent-container-mini'>
																	<div className='mb-elem-week-day-grid-mini'>
																		{sorted_day_schedule_with_overlaps.map(period => {
																			const period_id = period.period_id;
																			const period_name = period.period_name;
																			const is_lunch = period.is_lunch;
																			const is_special = period.is_special;
																			const is_pullout = period.is_pullout;
																			
																			const start_time = period.start_time;
																			const end_time = period.end_time;
																			const start_time_formatted = formatTime(start_time);
																			const end_time_date_formatted = formatTime(end_time);

																			const row_start = period.row_start;
																			const row_end = period.row_end;

																			const period_color = (is_lunch === '1') ? 'pastel-yellow' : ((is_special === '1') ? 'green' :((is_pullout === '1') ? 'orange' : 'dark-purple'));

																			// Calculate the column start and end position on the grid
																			const period_position = calculatePeriodColumnStartEnd(period, day_schedule_with_overlaps, logged_positions, start_time, end_time);
																			
																			const position_index = period_position.position_index;
																			const col_start = period_position.col_start;
																			const col_end = period_position.col_end;

																			logged_positions[period_id] = position_index;

																			const day_period_index = data.day_period_restrictions.findIndex(day_period_restriction => day_period_restriction.day_number === day_number && day_period_restriction.period_id === period_id)
																			const day_is_restricted = day_period_index !== -1;
																			
																			return (
																				<div className='mb-elem-block-mini parent-div' style={{gridColumnStart:col_start, gridColumnEnd:col_end, gridRowStart:row_start, gridRowEnd:row_end}} key={period_id}>
																					<div className={`mb-elem-block-inner-mini left-border-${period_color}-thick`} >
																						<div>
																							{(!day_is_restricted) ? 
																								(
																									<FontAwesomeIcon className='fas-checkbox-checked' icon={faCheckSquare} onClick={() => updateData('add', {day_number:day_number, period_id:period_id}, 'day_period_restrictions')}/>
																								) : 
																								(
																									<FontAwesomeIcon className='fas-checkbox-unchecked' icon={faSquare} onClick={() => updateData('remove', day_period_index, 'day_period_restrictions')}/>
																								)
																							}
																						</div>
																						<div className='ellipsis'>
																							<div className={`mb-elem-block-header-mini`}>
																								<span className='tooltip' data-tooltip-text={period_name}>
																									<div className='mb-elem-block-name-mini ellipsis'>{period_name}</div>
																								</span>
																							</div>
																							<div className={`mb-elem-block-content-mini`}>
																								<div className='tooltip' data-tooltip-text={`${start_time_formatted} - ${end_time_date_formatted}`}>
																									<div className='mb-elem-block-time-mini ellipsis'>{start_time_formatted} - {end_time_date_formatted}</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			)
																		})}
																	</div>
																</div>
															</div>
														)
													})}
												</div>
											</div>
										</div>
									</div>
								}
							</form>
						}
					</div>
				</div>
			</div>
		</div>
	);
}