import React, { useState , useEffect, useRef } from 'react';
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';

import Button from '../../Button';
import MagnetboardHSMS from '../MagnetboardHSMS';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle,faLock, faHourglassHalf, faTimes, faUserCircle, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { getSchoolRunningProcesses, checkConflicts, useOutsideClick } from '../../../js/Helpers';
import { FileUpload } from '../../../js/upload';

import '../../../css/hsms/section_placement.css';

export default function PlacementReview({ permissionChecked, schoolSubscribed, subscreenRestricted, subscreenLocked, subscreenResponsibleRoleID, subscreen, getNewData, departmentsLoading, departments, departmentID, sortedDepartments, changeDepartment, userInfo, schoolInfo, schoolInfoLoading, setSchoolInfoLoading, scheduleInfo, scheduleInfoLoading, setScheduleInfo, scheduleStructureData, setScheduleStructureData, scheduleStructureDataLoading, lunchInfo, coursesLoading, courses, setCourses, teachersLoading, teachers, setTeachers, classroomsLoading, classrooms, studentsLoading, students, setStudents, sectionsLoading, sections, setSections, subsections, setSubsections, labels, setLabels, reviewing, toggleReviewDepartment, users, userIsAdmin, userIsSupervisor, supervisors, openSupervisorsList, closeSupervisorsList, addSupervisorToDepartment, removeSupervisorFromDepartment, subscreenSelect, submitDepartment, changesSavedMessage, setChangesSavedMessage, showNextButton, handleMoveToNextScreen, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo }) {
	const ref = useRef();

	const [isLoading, setIsLoading] = useState(true);

	const [departmentSections, setDepartmentSections] = useState([]);
	const [departmentTeacherIDs, setDepartmentTeacherIDs] = useState(['None']);
	const [departmentClassroomIDs, setDepartmentClassroomIDs] = useState(['None']);

	const [placementsHaveStarted, setPlacementsHaveStarted] = useState(false);
	const [placementsGenerated, setPlacementsGenerated] = useState(false);
	const [statusCheckComplete, setStatusCheckComplete] = useState(false);

	const generateSchedule = async () => {
		setPlacementsHaveStarted(true); // switch screen to show that placements have started
		setSchoolInfoLoading(true);

		const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_version_id:schoolInfo.current_data_version_id};
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/place-sections';
		const myInit = { // OPTIONAL
			response: true,
			queryStringParameters: data,
		};
	
		try {
			await API.get(apiName, url, myInit);
			
			const fileUpload = new FileUpload(schoolInfo.current_data_version_id, scheduleInfo.school_id);
			await fileUpload.checkUploading('sectionplace', true, (event) => {
				if(event.done) 
				{
					if(event.failure) 
					{
						setPlacementsHaveStarted(false);
						setPlacementsGenerated(false);
					} 
					else 
					{
						const conflicts_to_check = ['course_exceeds_capacity', 'course_period_restriction', 'course_outside_normal_room', 'teacher_missing', 'teacher_period_restriction', 'teacher_student_restriction', 'student_student_restriction', 'teacher_double_booked', 'classroom_double_booked', 'student_double_booked', 'teacher_conflicting_periods', 'classroom_conflicting_periods', 'student_conflicting_periods', 'teacher_too_many_sections', 'teacher_too_many_sections_in_a_row', 'classroom_missing'];
						const update_all = true;

						checkConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, conflicts_to_check:conflicts_to_check, update_all:update_all}).then(data => {
							getNewData();
							setPlacementsGenerated(true);
						});
					}
					
					setSchoolInfoLoading(false);
					setIsLoading(false);
				}
			});
		} catch(error)
		{
			console.log(error.response);
		}
	}

	/////////////////////////////////
	///// SUPERVISORS FUNCTIONS /////
	/////////////////////////////////
	useOutsideClick(ref, () => {
		closeSupervisorsList();
	});
	
	//////////////////////////////
	///// DO ON STATE CHANGE /////
	//////////////////////////////
	// Run every render
	useEffect(() => {
		const checkStatus = async () => {
			getSchoolRunningProcesses({school_id: schoolInfo.school_id, schedule_year_id:schoolInfo.current_schedule_year_id}).then(running_processes => {
				const progress_index = running_processes.findIndex(process => process.process_type === 'sectionplace' && process.schedule_version_id == schoolInfo.current_schedule_version_id);

				let has_started = false;
				let is_done = false;

				if(progress_index !== -1)
				{
					const progress_info = running_processes[progress_index];
					
					has_started = progress_info.has_started;
					is_done = progress_info.is_done;
				}

				if(is_done)
				{
					setPlacementsHaveStarted(true);
					setPlacementsGenerated(true);
				}
				else if(has_started)
				{
					setPlacementsHaveStarted(true);
				}

				setStatusCheckComplete(true);
			});
		}

		checkStatus();
	}, [schoolInfo]);

	useEffect(() => {
		if(departmentID) 
		{
			if(!sectionsLoading)
			{
				const department_sections = sections.filter(section => section.departments.includes(departmentID));
				setDepartmentSections([...department_sections]);
			}
		}
	}, [departmentID, teachersLoading, sectionsLoading, sections]);
	
	useEffect(() => {
		if(permissionChecked && !coursesLoading && !classroomsLoading && !teachersLoading && !departmentsLoading && statusCheckComplete) setIsLoading(false);
	}, [permissionChecked, coursesLoading, classroomsLoading, teachersLoading, departmentsLoading, statusCheckComplete]);

	useEffect(() => {
		if(!teachersLoading)
		{
			const department_teacher_ids = teachers.reduce((result, teacher) => {
				const teacher_in_department = teacher.departments.includes(departmentID);
				const teacher_teaches_section_in_department = (departmentSections.findIndex(section => section.teacher_id === teacher.teacher_id) !== -1);

				if(teacher_in_department || teacher_teaches_section_in_department) result.push(teacher.teacher_id);

				return result;
			}, []);

			// Set initial teacher
			if(department_teacher_ids.length > 0)
			{
				setDepartmentTeacherIDs(department_teacher_ids);
			}
			else
			{
				setDepartmentTeacherIDs(['None']);
			}
		}

		if(!classroomsLoading)
		{
			const department_classroom_ids = classrooms.reduce((result, classroom) => {

				const classroom_in_department = classroom.departments.includes(departmentID);
				const classroom_has_section_in_department = (departmentSections.findIndex(section => section.classroom_id === classroom.classroom_id) !== -1);

				if(classroom_in_department || classroom_has_section_in_department) result.push(classroom.classroom_id);

				return result;
			}, []);

			if(department_classroom_ids.length > 0) 
			{
				setDepartmentClassroomIDs(department_classroom_ids);
			}
			else
			{
				setDepartmentClassroomIDs(['None']);
			}
		}
	}, [departmentSections, teachersLoading, classroomsLoading, classrooms, departmentID, teachers]);

	useEffect(() => {
		if(!departmentID || !userInfo || !users || !departments) return;

		const department_index = departments.findIndex(department => department.department_id === departmentID);
		const department_info = (department_index !== -1) ? departments[department_index] : null;
		const department_supervisors = (department_info) ? department_info.supervisors : [];

		const available_users = users.filter(user => (user.role_id === '1' || user.role_id === '2') || department_supervisors.findIndex(supervisor => supervisor.user_id === user.user_id) !== -1);

		setScreenAllowsUniversalSidebar(true);
		setUniversalSidebarLoadingInfo({sidebar_name:"Placement Review Notes", sidebar_type:'notes', subscreen:'placement_review', department_id:departmentID, user_id:userInfo.user_id, available_users:available_users, help_link_path:'/app/hsms/documentation/section_placement/placement_review'});
	}, [departmentID, userInfo, users, departments, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo]);
	
	//console.log(multipleDepartments);
	//console.log(departments);
	//console.log({departmentID, department_info});
	//console.log(userDepartments);
	//console.log({departmentTeacherIDs, departmentClassroomIDs});
	//console.log({userIsSupervisor});

	const department_index = departments.findIndex(department => department.department_id === departmentID);
	const department_info = departments[department_index];
	const subscreen_info = (department_info) ? department_info.subscreens[subscreen] : null;
	
	const current_department = (department_info) ? department_info.department : null;
	const current_screen_submitted = (subscreen_info) ? subscreen_info.submitted : false;
	const current_screen_confirmed = (subscreen_info) ? subscreen_info.confirmed : false;
	const current_screen_unlocked= (subscreen_info) ? subscreen_info.unlocked : false;
	const current_user_can_add_supervisors = (userIsAdmin);
	
	const show_current_screen = (department_info) ? (current_screen_unlocked && (reviewing || userIsSupervisor || userIsAdmin )) : false;
	const screen_complete_for_user = (department_info) ? ((current_screen_confirmed) || (!userIsAdmin && current_screen_submitted)) : false;

	return (
		<div>
			{isLoading ? 
			(
				<div className='center-text'>
					<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
				</div>
			):(subscreenRestricted) ?
			(
				<div className='general-screen-message'>
					<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
					<h1>No Access</h1>
					<div>You do not have access to this screen</div>	
				</div>
			):(subscreenLocked) ?
			(
				<div className='general-screen-message'>
					<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
					<h1>Temporarily Locked</h1>
					<div>This screen is locked until all other prior steps are completed</div>	
				</div>
			):(!userIsSupervisor) ?
			(
				<div className='section-creation-screen-message'>
					<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
					<h1>No Access</h1>
					<div>You do not have access to change this department</div>	
				</div>
			):(!schoolSubscribed) ? 
			(
				<div className='section-placement-screen-message' style={{maxWidth:'600px'}}>
					<img style={{height:'80px'}} src={require("../../../images/start-scheduling.png")} alt='Start scheduling'/>
					<h2>Place Your Sections</h2>
					<div>In this step, we will place all your remaining sections (taking into account all your constraints) to find the best place in the schedule for each section!</div>
					<div style={{padding:'20px', backgroundColor:'#efefef', border:'2px solid #ddd', margin:'20px 0px 20px 0px', borderRadius:'10px'}}>
						<div>You need a paid subscription for this feature.</div>
						<div style={{margin:'6px 0px 0px 0px'}}>But don't let that stop you-- you can still skip this step and keep working!</div>	
						<div style={{fontSize:'18px', margin:'45px 0px 0px 0px'}}><strong>Please contact us at contact@edario.com to get full access in Edario.</strong></div>
					</div>
					{userIsAdmin ?
					(
						<div className="blue-link align-right" style={{maxWidth:'250px', margin:'0px 0px -30px 0px'}} onClick={handleMoveToNextScreen}>Skip to Next Step</div>
					):
					(
						<Link className="blue-link align-right" style={{maxWidth:'250px', margin:'0px 0px -30px 0px'}} to={`/progress`}>Skip to Next Step</Link>
					)}
				</div>
			):(!departmentID) ? 
			(
				<div className='progress-choose-department-screen'>
				<div className='progress-choose-department-heading'>Choose a department to work on:</div>
				{(subscreenResponsibleRoleID === '3') &&
					<div className='progress-choose-department-container'>
						{sortedDepartments.map((department_info, j) => {
							const department_id = department_info.department_id;
							const department_name = department_info.department;

							const department_submitted = department_info.subscreens[subscreen].submitted;
							const department_confirmed = department_info.subscreens[subscreen].confirmed;
							const department_unlocked = department_info.subscreens[subscreen].unlocked;

							const user_is_supervisor = (userIsAdmin || department_info.supervisors.findIndex(supervisor => supervisor.user_id === userInfo.user_id) !== -1);

							const text_color = (!department_unlocked) ? 'gray' : ((department_confirmed) ? 'green' : ((department_submitted) ? 'yellow' : 'gray'));
							const status = (!department_unlocked) ? 'Locked' : ((department_confirmed) ? 'Complete' : ((department_submitted) ? 'Awaiting Confirmation' : 'Incomplete'));
							const status_icon = (!department_unlocked) ? faLock : faCheckCircle;
							const button_text = (!department_unlocked) ? '' : ((department_confirmed) ? 'Review' : ((department_submitted) ? 'Continue' : 'Start'));

							const last_unfinished_department_subscreen = (department_unlocked) ? null : Object.keys(department_info.subscreens).reduce((results, subscreen) => {
								const subscreen_progress_info = department_info.subscreens[subscreen];
								const subscreen_screen = (subscreen_progress_info) ? subscreen_progress_info.screen : null;
								const subscreen_order = (subscreen_progress_info) ? parseInt(subscreen_progress_info.subscreen_order) : 999;
								const subscreen_submitted = (subscreen_progress_info) ? subscreen_progress_info.submitted : false;
								const subscreen_confirmed = (subscreen_progress_info) ? subscreen_progress_info.confirmed : false;
								
								const screen_complete_for_user = ((subscreen_confirmed) || (userIsSupervisor && subscreen_submitted));

								if(!screen_complete_for_user && subscreen_order < results.logged_min)
								{
									results.last_screen = subscreen_screen;
									results.last_subscreen = subscreen;
									results.logged_min = subscreen_order;
								}

								return results;
							}, {last_screen: null, last_subscreen: null, logged_min:999});


							return (
								<div className={`progress-choose-department-row  ${!user_is_supervisor && 'opacity-50'}`} key={j}>
									<div className='progress-choose-department-name'>{department_name}</div>
									<div className={`progress-choose-department-status ${text_color}-text`}>
										<div>{status}</div> 
										<FontAwesomeIcon className='progress-choose-department-status-icon' icon={status_icon}/>
									</div>
									<div className='small-text text-align-right'>
										{department_unlocked ?
											(
												<div className={`progress-choose-department-btn btn ${text_color}-btn align-right`} onClick={() => changeDepartment(department_id)}>{button_text}</div>
											):
											(
												<div>
													<div>This screen is locked because there is a previously unfinished screen.</div>
													<div style={{marginTop:'3px'}} className='blue-link full-width' onClick={()=>subscreenSelect(last_unfinished_department_subscreen.last_subscreen, last_unfinished_department_subscreen.last_screen, department_id)}>Go to Last Unfinished Step</div>
												</div>
											)
										}
									</div>
								</div>
							)
						})}
					</div>
				}
			</div>
			):((placementsHaveStarted && !placementsGenerated) || (!placementsHaveStarted && !placementsGenerated && userInfo.role_id === '3')) ? (
				<div className='placements-in-progress-container'>
					<div id='custom-section-creation-loader-container'>
						<div className='custom-section-creation-loader-column'>
							<div className='custom-section-creation-loader-row'>
								<div className='custom-section-creation-loader-row-top pastel-orange'></div>
								<div className='custom-section-creation-loader-row-bottom'></div>
							</div>
							<div className='custom-section-creation-loader-row'>
								<div className='custom-section-creation-loader-row-top pastel-orange'></div>
								<div className='custom-section-creation-loader-row-bottom'></div>
							</div>
						</div>
						<div className='custom-section-creation-loader-column'>
							<div className='custom-section-creation-loader-row'>
								<div className='custom-section-creation-loader-row-top yellow'></div>
								<div className='custom-section-creation-loader-row-bottom'></div>
							</div>
							<div className='custom-section-creation-loader-row'>
								<div className='custom-section-creation-loader-row-top yellow'></div>
								<div className='custom-section-creation-loader-row-bottom'></div>
							</div>
							<div className='custom-section-creation-loader-row'>
								<div className='custom-section-creation-loader-row-top yellow'></div>
								<div className='custom-section-creation-loader-row-bottom'></div>
							</div>
						</div>
						<div className='custom-section-creation-loader-column'>
							<div className='custom-section-creation-loader-row'>
								<div className='custom-section-creation-loader-row-top pastel-green'></div>
								<div className='custom-section-creation-loader-row-bottom'></div>
							</div>
						</div>
						<div className='custom-section-creation-loader-column'>
							<div className='custom-section-creation-loader-row'>
								<div className='custom-section-creation-loader-row-top pastel-light-blue'></div>
								<div className='custom-section-creation-loader-row-bottom'></div>
							</div>
							<div className='custom-section-creation-loader-row'>
								<div className='custom-section-creation-loader-row-top pastel-light-blue'></div>
								<div className='custom-section-creation-loader-row-bottom'></div>
							</div>
						</div>
					</div>
					<h2>Schedule is being generated...</h2>
					<div>We are currently generating your schedule. Please check back shortly to review your schedule!</div>
				</div>
			):(!placementsHaveStarted && !placementsGenerated && userIsAdmin) ? 
			(
				<div className='section-placement-screen-message' style={{maxWidth:'600px'}}>
					<img style={{height:'80px'}} src={require("../../../images/start-scheduling.png")} alt='Start scheduling'/>
					<h2>Whenever You are Ready!</h2>
					<div>All your constraints have been set, all your sections have been approved, and all manual sections have been placed. You are now ready to generate your schedule.</div>
					<p className='medium-text' style={{maxWidth:'300px',margin:'30px auto 15px auto'}}><strong>NOTE</strong>: This will only place sections. No students will be placed until the next step.</p>
					<div id='generate-schedule-btn' className='btn green-btn' onClick={generateSchedule}>Generate Schedule</div>
				</div>
			):(
				<>
				{departmentID &&
					<>
					<div id='manual-placement-container'>
						<div id='manual-placement-top-bar-container'>
							<div className='manual-placement-top-bar-inner-container'>
								<div className='department-supervisor-parent-container' style={{margin:'10px 0px -5px 0px'}}>
									<>
									{department_info.supervisors.length === 0 &&
										<>
										<div className='department-supervisor-inner-container'>
											<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
											<div className='department-supervisor-name'>No Supervisor Assigned</div>
										</div>
										</>
									}
									{department_info.supervisors.map((supervisor, index) => {
										return (
											<div className='department-supervisor-outer-container' key={index}>
												<div className='department-supervisor-inner-container'>
													{supervisor.img_url &&
														<img className='department-supervisor-img' src={require(`../../../images/users/${supervisor.img_url}`)} alt='user' />
													}
													{!supervisor.img_url &&
														<FontAwesomeIcon className='department-supervisor-user-icon gray-text' icon={faUserCircle}/>
													}
													<div className='department-supervisor-name'>{supervisor.last_name}, {supervisor.first_name}</div>
													{current_user_can_add_supervisors &&
														<FontAwesomeIcon className='gray-text-hover' icon={faTimes} onClick={() => removeSupervisorFromDepartment(supervisor.user_id)}/>
													}
												</div>
											</div>
										)
									})}
									{current_user_can_add_supervisors &&
										<>
										<div className={`section-placement-add-supervisor small-text click-restricted ${(department_info.supervisors.length === 0) ? 'blue-link' : 'gray-to-blue-link'}`} onClick={() => openSupervisorsList(departmentID)}>Add a Supervisor</div>
										<div className='supervisor-options-container'>
											{department_info.supervisors_open &&
												<div className='data-options-container' ref={ref}>
													{supervisors.map((supervisor_info, index) => {
														
														return (
															<div className='data-options-option assign-resourses-supervisor-option' key={index} onClick={() => addSupervisorToDepartment(supervisor_info)}>
																{supervisor_info.img_url &&
																	<img className='department-supervisor-img' src={require(`../../../images/users/${supervisor_info.img_url}`)} alt='supervisor' />
																}
																{!supervisor_info.img_url &&
																	<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
																}
																<div className='department-supervisor-name'>{supervisor_info.last_name}, {supervisor_info.first_name}</div>
															</div>
														)	
													})}
													<Link className='data-options-option blue-text' to={{pathname:`/app/settings/users`, redirect:`/app/progress/section_creation`}} style={{borderTop:'1px solid #efefef'}} onClick={() => closeSupervisorsList(departmentID)}>Add new Supervisor</Link>
												</div>
											}
										</div>
										</>
									}
									</>
								</div>
								<div id='section-placement-submit-btn-container'>
									{(reviewing && screen_complete_for_user) &&
										<div className='btn gray-btn' onClick={() => changeDepartment(null)}>Go Back</div>
									}
									{(!screen_complete_for_user && show_current_screen) &&
										<Button key={departmentID} classes={['mark-as-complete-btn']} selected={false} text={`${!current_screen_submitted ? 'Mark As Complete' : 'Confirm Department'}`} icon={faCheck} iconPosition='left' onClickFunction={() => submitDepartment(departmentID)} />
									}
									{changesSavedMessage && 
										<div className='all-changes-saved-message' style={{margin:'20px 0px 0px 0px'}}>
											<FontAwesomeIcon className='green-text' icon={faCheckCircle}/>
											<div>
												<h5>Changes Saved</h5>
												<div className='small-text'>All your changes have been saved</div>
											</div>
											<FontAwesomeIcon className='x-remove' icon={faTimes} onClick={() => setChangesSavedMessage(false)}/>
										</div>
									}
								</div>
							</div>
							<div></div>
						</div>
						{(!current_screen_unlocked) ?
							(
								<div className='section-placement-screen-message'>
									<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
									<h1>Locked</h1>
									<div>This department has previous steps which have not been completed. Please review previous steps and make sure they are completed and saved. This page should open automatically once all previous steps have been finished.</div>
									<div className='blue-link' style={{marginTop:'30px'}} onClick={()=>changeDepartment(null)}>Go back to Departments</div>
								</div>
							):(userIsAdmin && current_screen_unlocked && !show_current_screen && !current_screen_submitted && !department_info.current_user_is_supervisor) ?
							(
								<div className='assign-resources-screen-message'>
									<FontAwesomeIcon className='turquoise-text' style={{fontSize:'60px'}} icon={faHourglassHalf}/>
									<h1>Pending...</h1>
									<div>Waiting on confirmation from department supervisor</div>
									<p className='blue-link' onClick={toggleReviewDepartment}>Review department yourself</p>	
								</div>
							):(show_current_screen)?
							(
								<div className='section-placement-content'>
									<div>
										<FontAwesomeIcon icon={faChevronLeft} className='progress-back-to-departments-btn' onClick={()=>changeDepartment(null)} style={{marginTop:'45px'}}/>
									</div>
									<div className='section-placement-content-inner-content'>
										<MagnetboardHSMS schoolSubscribed={schoolSubscribed} schoolInfoLoading={schoolInfoLoading} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} setScheduleInfo={setScheduleInfo} scheduleStructureData={scheduleStructureData} setScheduleStructureData={setScheduleStructureData} scheduleInfoLoading={scheduleInfoLoading} scheduleStructureDataLoading={scheduleStructureDataLoading} userInfo={userInfo} departmentsLoading={departmentsLoading} departments={departments} teachersLoading={teachersLoading} teachers={teachers} setTeachers={setTeachers} coursesLoading={coursesLoading} courses={courses} setCourses={setCourses} classroomsLoading={classroomsLoading} classrooms={classrooms} studentsLoading={studentsLoading} students={students} setStudents={setStudents} sectionsLoading={sectionsLoading} sections={sections} setSections={setSections} subsections={subsections} setSubsections={setSubsections} lunchInfo={lunchInfo} labels={labels} setLabels={setLabels} magnetboardOptions={{show_top_bar:true, allow_schedule_name_editing:false, allow_filtering:false, show_match_percent:false, show_conflicts:true, show_menu:false, allow_new_sections:true, allow_placeholders:false, allow_hotlinks:false, current_department_id:departmentID, current_department:current_department, allowed_teacher_ids:departmentTeacherIDs, allowed_classroom_ids:departmentClassroomIDs, view_options:[{value:'teacher', display:'Teacher View'}, {value:'classroom', display:'Classroom View'}]}}/>
									</div>
								</div>
							):null	
						}
					</div>
					</>
				}
				{showNextButton &&
					<div className='fixed-bottom-save-container'>
						{userIsAdmin ?
							(
								<Button classes={['btn', 'btn-extra-large', 'green-btn', 'align-right', 'move-to-next-screen-btn']} selected={false} text='Continue to Schedule Students' onClickFunction={() => handleMoveToNextScreen()} />
							):
							(
								<Link className="next_clean_step supervisor" to={`/progress`}>Save & Continue</Link>
							)
						}
					</div>
				}
				</>
			)}
		</div>
	)
}