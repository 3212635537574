import React, { memo, useState, useEffect, useRef, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { API } from "aws-amplify";
import { useReactToPrint } from 'react-to-print';

import Fuse from 'fuse.js';
import swal from '@sweetalert/with-react';

import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';

import Dropdown from '../Dropdown';
import Portal from '../Portal';

import MagnetboardQuickViewHSMS from './MagnetboardQuickViewHSMS';
import MagnetboardDropPeriodHSMS from './MagnetboardDropPeriodHSMS';
import MagnetboardUnplacedDropZoneHSMS from './MagnetboardUnplacedDropZoneHSMS';

import ScheduleSummary from './reports/schedule_summary';
import StudentSummary from './reports/student_summary';

import Conflicts from './reports/conflicts';
import ConflictMatrix from './reports/conflict_matrix';
import SectionBalancing from './reports/section_balancing';

import EditSection from './modals/edit_section';
import EditStudentSchedule from './modals/edit_student_schedule';
import ExportSchedule from './modals/export_schedule';

import { getData, checkConflicts, getJSONFromStorage, isEmpty, sortObject, sortArrayOfObjects, capitalizeFirstLetters, cloneObj, calculateAffectedSectionsForPlacements, saveSectionPlacement, recalculateMatchPercent, useOutsideClick, JSONexists, calculateConflicts } from '../../js/Helpers';
import { FileUpload } from '../../js/upload';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faCheck, faTimes, faSlidersH, faSearch, faChevronDown, faCheckCircle, faCheckSquare, faSquare, faCopy,faCalendarDay, faChevronUp, faArrowRight, faChevronRight, faChalkboard, faUserGraduate} from '@fortawesome/free-solid-svg-icons';

import '../../css/magnetboard.css';
import '../../css/hsms/reports.css';

export default function MagnetboardHSMS({ schoolSubscribed, schoolInfoLoading, schoolInfo, scheduleInfo, setScheduleInfo, scheduleStructureData, setScheduleStructureData, scheduleInfoLoading, scheduleStructureDataLoading, userInfo, departmentsLoading, departments, teachersLoading, teachers, setTeachers, coursesLoading, courses, setCourses, classroomsLoading, classrooms, studentsLoading, students, setStudents, studentMatchPercentages, sectionsLoading, sections, setSections, subsections, setSubsections, lunchInfo, labels, setLabels, magnetboardOptions = {show_top_bar:true, allow_schedule_name_editing:true, allow_filtering:true, show_match_percent:true, show_conflicts:true, show_menu:true, allow_new_sections:true, allow_placeholders:true, allow_hotlinks:true, current_department_id:null, current_department:null, allowed_teacher_ids:[], allowed_classroom_ids:[], view_options:[{value:'teacher', display:'Teacher View'}, {value:'classroom', display:'Classroom View'}, {value:'student', display:'Student View'}]} }) {
	const other_schedules_ref = useRef();
	const menu_ref = useRef();
	const filter_ref = useRef();
	const printRef = useRef();

	const singletons_filtered = useRef(false);
	const doubletons_filtered = useRef(false);
	const labs_only_filtered = useRef(false);
	const non_labs_only_filtered = useRef(false);
	const no_classroom_filtered = useRef(false);
	const has_classroom_filtered = useRef(false);

	const [isLoading, setIsLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [displayCreated, setDisplayCreated] = useState(false);

	/////////////////////////////
	///// LOADING VARIABLES /////
	/////////////////////////////
	const [matchPercentLoading, setMatchPercentLoading] = useState(true);
	const [conflictsLoading, setConflictsLoading] = useState(true);
	
	//////////////////////////
	///// DATA VARIABLES /////
	//////////////////////////
	const [currentScheduleVersionID, setCurrentScheduleVersionID] = useState(null);
	const [currentSchedule, setCurrentSchedule] = useState({});
	const [displaySchedule, setDisplaySchedule] = useState([]);
	
	const [scheduleType, setScheduleType] = useState(null);
	
	const [currentView, setCurrentView] = useState('teacher');
	const [currentViewType, setCurrentViewType] = useState(null);
	const [currentDay, setCurrentDay] = useState('1');
	
	const [editingScheduleName, setEditingScheduleName] = useState(false);
	const [periodType, setPeriodType] = useState(null);
	const [periodsToShow, setPeriodsToShow] = useState([]);
	const [matchPercent, setMatchPercent] = useState(0);

	const [otherSchedulesVisible, setOtherSchedulesVisible] = useState(false);
	const [currentOpenMenu, setCurrentOpenMenu] = useState(null);

	const [searchValue, setSearchValue] = useState('');

	const [filterDataVisible, setFilterDataVisible] = useState(false);
	const [filterOptions, setFilterOptions] = useState({});
	const [selectedFilters, setSelectedFilters] = useState([]);
	const [openFilterOptions, setOpenFilterOptions] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [filterIDs, setFilterIDs] = useState([]);

	const [openReports, setOpenReports] = useState([]);
	const [conflicts, setConflicts] = useState([]);
	
	const [currentSectionID, setCurrentSectionID] = useState(null);
	const [currentDataID, setCurrentDataID] = useState(null);
	const [currentSectionType, setCurrentSectionType] = useState(null);
	const [currentCoursePeriodID, setCurrentCoursePeriodID] = useState(null);
	const [currentStudentID, setCurrentStudentID] = useState(null);

	const [selectedTeacherID, setSelectedTeacherID] = useState(null);
	const [selectedQuickViewDataID, setSelectedQuickViewDataID] = useState(null);
	const [selectedQuickViewSectionID, setSelectedQuickViewSectionID] = useState(null);
	
	const [showEditSectionModal, setShowEditSectionModal] = useState(false);
	const [showEditStudentScheduleModal, setShowEditStudentScheduleModal] = useState(false);
	const [showExportScheduleModal, setShowExportScheduleModal] = useState(false);

	const [maxStudentsToShow, setMaxStudentsToShow] = useState(50);
	
	const [dayOptions, setDayOptions] = useState([]);
	let viewTypeOptions = [{value:'period', display:'Period View'}, {value:'day', display:'Day View'}];
	
	const openPrint = useReactToPrint({
		content: () => printRef.current,
		pageStyle: `
			@media print {
				@page { 
					size: 11in 8.5in; 
					margin: 20mm;
				}

				html, body {
					height: initial !important;
					overflow: initial !important;
					-webkit-print-color-adjust: exact;
				}
				
				.mb-section-name.ellipsis {
					white-space: normal !important;
				}

				.mb-section-more-info, .mb-add-new-section, .lock-section-btn {
					height:0 !important;
					overflow:hidden !important;
				}

				.print-period-name {
					height:fit-content !important;
					overflow:visible !important;
					margin:0px 0px 10px 0px;
					color:#95a5a6;
				}

				.mb-section-top-bar {
					height:5px;
				}

				.mb-schedule-row {
					grid-template-columns: repeat(auto-fit, minmax(50px, 1fr)) !important;
				}

				.page-break {
					margin-top: 1rem;
					display: block;
					page-break-before: always;
				}
			}
		`,
	});
	
	/////////////////////////////////
	///// MAGNETBOARD FUNCTIONS /////
	/////////////////////////////////
	const pullNewSectionData = (schedule_version_id, _callback = ()=>{}) => {
		setOtherSchedulesVisible(false);

		const old_schedule_index = scheduleInfo.schedules.findIndex(schedule => schedule.schedule_version_id === currentScheduleVersionID);
		const new_schedule_index = scheduleInfo.schedules.findIndex(schedule => schedule.schedule_version_id === schedule_version_id);

		if(new_schedule_index === -1) return;
		if(old_schedule_index !== -1) scheduleInfo.schedules[old_schedule_index].current_bool = '0';

		scheduleInfo.schedules[new_schedule_index].current_bool = '1';

		setCurrentScheduleVersionID(scheduleInfo.schedules[new_schedule_index].schedule_version_id);
		setCurrentSchedule({...scheduleInfo.schedules[new_schedule_index]});

		getData('sections', '/get-sections', {school_id: schoolInfo.school_id, schedule_version_id:schedule_version_id}, true).then(returned_data => {
			setLabels(returned_data.labels);
			setSubsections(returned_data.subsections);
			setSections(returned_data.sections);
			setIsLoading(false);

			_callback();
		});
	}
	
	const submitScheduleName = () => {
		const schedule_name_input = document.getElementById('mb-schedule-name-input');
		const new_schedule_name = schedule_name_input.value;
		
		if(!new_schedule_name)
		{
			schedule_name_input.classList.add('error');
			return false;
		}

		const save_type = 'schedule_name';
		saveSchedule(save_type, new_schedule_name);
	}

	const toggleOtherScheduleOptions = () => {
		setOtherSchedulesVisible(!otherSchedulesVisible);
	}

	const toggleMenuItem = (menu_item) => {
		let current_menu_item = null;

		if(menu_item !== currentOpenMenu) current_menu_item = menu_item;

		setCurrentOpenMenu(current_menu_item);
	}

	const toggleFilterOptions = () => {
		setFilterDataVisible(!filterDataVisible);
	}

	useOutsideClick(other_schedules_ref, () => {
		setOtherSchedulesVisible(false);
	});

	useOutsideClick(menu_ref, () => {
		setCurrentOpenMenu(null);
	});

	useOutsideClick(filter_ref, () => {
		setFilterDataVisible(false);
	});

	// Handle Dropdown Hovering 
	const handleDropdownHoverShow = (action_type) => {
		// Hide all other submenus
		const all_submenus = document.querySelectorAll('.mb-dropdown-options-subcontainer');
		for(var i = 0; i < all_submenus.length; i++)
		{
			all_submenus[i].classList.add('hide');
		}

		// Show the current submenu
		const parent_menu = document.querySelector('.mb-dropdown-option-hover[data-actiontype="' + action_type + '"]');
		const parent_menu_top_position = parent_menu.offsetTop + 42;

		const submenu = document.querySelector('.mb-dropdown-options-subcontainer[data-actiontype="' + action_type + '"]');
		if(submenu) 
		{
			submenu.style.top = parent_menu_top_position + "px";
			submenu.classList.remove("hide");
		}
	}

	const handleDropdownHoverHide = (action_type) => {
		const submenu = document.querySelector('.mb-dropdown-options-subcontainer[data-actiontype="' + action_type + '"]');

		if(submenu) submenu.classList.add("hide");
	}

	const addOpenReport = (report) => {
		const report_index = openReports.findIndex(open_report => open_report === report);

		if(report_index === -1) openReports.push(report);

		setOpenReports([...openReports]);
	}

	const removeOpenReport = (report) => {
		const report_index = openReports.findIndex(open_report => open_report === report);

		if(report_index !== -1) openReports.splice(report_index, 1);

		setOpenReports([...openReports]);
	}
	
	const changeCurrentViewType = (new_view_type) => {
		setIsLoading(true);
		setCurrentViewType(new_view_type);
	}
	
	const changeCurrentDay = (new_day) => {
		setCurrentDay(new_day);
		sessionStorage.setItem('current_day', new_day);
	}
	
	const changeCurrentView = (new_view) => {
		setIsLoading(true);
		setFilterIDs([]);
		setDisplayCreated(false);
		
		setCurrentView(new_view);
		sessionStorage.setItem('current_view', new_view);
	}

	const updateOpenFilters = (filter_title) => {
		const filter_index = openFilterOptions.findIndex(filter => filter === filter_title);
		
		if(filter_index !== -1)
		{
			openFilterOptions.splice(filter_index, 1);
		}
		else
		{
			openFilterOptions.push(filter_title);
		}

		setOpenFilterOptions([...openFilterOptions]);
	}

	const updateFilterOptions = (filter_title, option_index, selected) => {
		const singletons_filtered_temp = (filter_title === 'singletons' && filterOptions['singletons'][option_index].value === 'singletons') ? selected : singletons_filtered.current;
		const doubletons_filtered_temp = (filter_title === 'singletons' && filterOptions['singletons'][option_index].value === 'doubletons') ? selected : doubletons_filtered.current;
		const labs_only_filtered_temp = (filter_title === 'labs' && filterOptions['labs'][option_index].value === 'labs_only') ? selected : labs_only_filtered.current;
		const non_labs_only_filtered_temp = (filter_title === 'labs' && filterOptions['labs'][option_index].value === 'non_labs_only') ? selected : non_labs_only_filtered.current;
		const no_classroom_filtered_temp = (filter_title === 'classroom' && filterOptions['classroom'][option_index].value === 'no_classroom') ? selected : no_classroom_filtered.current;
		const has_classroom_filtered_temp = (filter_title === 'classroom' && filterOptions['classroom'][option_index].value === 'has_classroom') ? selected : has_classroom_filtered.current;

		singletons_filtered.current = singletons_filtered_temp;
		doubletons_filtered.current = doubletons_filtered_temp;
		labs_only_filtered.current = labs_only_filtered_temp;
		non_labs_only_filtered.current = non_labs_only_filtered_temp;
		no_classroom_filtered.current = no_classroom_filtered_temp;
		has_classroom_filtered.current = has_classroom_filtered_temp;

		filterOptions[filter_title][option_index]['selected'] = selected;
		setFilterOptions({...filterOptions});
	}

	const handleSelectTeacher = useCallback((selected_teacher) => {
		setSelectedQuickViewDataID(null);
		setSelectedTeacherID(selected_teacher);
	},[])

	const handleSelectQuickViewDataID = useCallback((selected_data_id, selected_section_id, e) => {
		setSelectedTeacherID(null);
		setSelectedQuickViewDataID(selected_data_id);
		setSelectedQuickViewSectionID(selected_section_id);
	},[])

	const showMoreStudents = () => {
		const num_students_to_show = maxStudentsToShow + 50;
		setMaxStudentsToShow(num_students_to_show);
	}

	const clearStudents = async () => {	
		const options =  {
			title: "Are you sure?",
			text: 'This will remove all students from sections that are not locked.',
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			},
			content: (
				<div className='sweet-alert-dont-show-message' style={{padding:'20px 10px 25px 10px'}}>
					<div className='dark-blue-text' style={{textAlign:'left',width:'340px',margin:'auto'}}>
						<div><strong>Pro Tip:</strong></div>
						<div>If you want to keep students in a certain section, make sure to lock that section (by clicking the <FontAwesomeIcon className='gray-text' icon={faLock}/> icon on the section) before clearing!</div>
					</div>
				</div>
			)
		}
		
		await swal(options).then(async (return_value) => {
			if(return_value)
			{
				setMatchPercentLoading(true);
				setConflictsLoading(true);

				// Update front end
				let new_sections = sections.map(section => {
					const locked = section.locked;

					if(locked === '1') return section;

					const section_id = section.section_id;
					const student_list = section.student_list;

					// Update student requests
					const new_student_list = student_list.reduce((results, student_id) => {
						const student_info = students.find(student => student.student_id === student_id);
						if(!student_info) return results;

						const student_locked_sections = student_info.student_locked_sections;
						const student_has_section_locked = student_locked_sections.includes(section_id);
						
						if(student_has_section_locked) results.push(student_id);
						return results;
					}, []);

					section.student_list = new_student_list;
					return section;
				});

				setStudents([...students]);
				setSections([...new_sections]);

				// Send to Backend ///
				const data = {school_id: schoolInfo.school_id, schedule_version_id:currentScheduleVersionID};
				const apiName = process.env.REACT_APP_ENDPOINT_NAME;
				const url = '/clear-students';
				const myInit = { // OPTIONAL
					response: true,
					body: JSON.stringify(data),
				};
			
				try {
					// Clear students
					await API.post(apiName, url, myInit);

					// Update conflicts
					const conflicts_to_check = ['course_exceeds_capacity', 'teacher_student_restriction', 'student_student_restriction', 'student_double_booked', 'student_conflicting_periods', 'inclusion_too_many_students', 'inclusion_over_half'];
					calculateConflicts({school_id: schoolInfo.school_id, schedule_version_id:currentScheduleVersionID, conflicts_to_check:conflicts_to_check, update_all:true}).then(conflicts_response => {
						const added_conflicts = conflicts_response.added_conflicts;
						const deleted_conflicts = conflicts_response.deleted_conflicts;
	
						// remove any deleted conflicts, add any added conflicts
						let new_conflicts = conflicts.filter(conflict => !deleted_conflicts.includes(conflict.conflict_id));
						new_conflicts = [...new_conflicts, ...added_conflicts];
	
						setConflicts([...new_conflicts]);
						setConflictsLoading(false);
					});

					// Recalculate match percent
					recalculateMatchPercent(data).then(match_percent => {
						setMatchPercent(match_percent);
						setMatchPercentLoading(false);
					});
				} catch(e)
				{
					console.log(e.response);
				}
			}
		});
	}

	const placeStudents = async () => {
		const options =  {
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Start Placing",
					value: true,
					visible: true,
					className: 'blue-btn'
				},
			},
			content: (
				<div>
					<FontAwesomeIcon className='modal-top-icon blue-text' icon={faCalendarDay}/>
					<h1 className='swal-title' style={{marginTop:'0px'}}>Place Students</h1>
					<p className='swal-text'>This will try to place any unmatched students into available sections!</p>
					<div className='sweet-alert-dont-show-message' style={{padding:'20px 10px 25px 10px'}}>
						<div className='dark-blue-text' style={{textAlign:'left',width:'340px',margin:'auto'}}>
							<div><strong>Pro Tip:</strong></div>
							<div>Students will not get placed in any currently locked sections. If you want to allow students to be placed in a certain section, unlock the section (by clicking the <FontAwesomeIcon className='orange-text' icon={faLock}/> icon on the section) before placing!</div>
						</div>
					</div>
				</div>
				
			)
		}

		await swal(options).then(async (return_value) => {
			if(return_value)
			{
				const placing_in_progress_options =  {
					button:false,
					closeOnClickOutside: false,
					closeOnEsc: false,
					content: (
						<div>
							<FontAwesomeIcon className='modal-top-icon placing-students-in-progress-icon' icon={faCalendarDay}/>
							<h1 className='swal-title' style={{marginTop:'0px'}}>Placing Students in Progress</h1>
							<p className='swal-text'>Please wait, this may take a few minutes...</p>
							<div className='sweet-alert-dont-show-message' style={{padding:'20px 10px 25px 10px'}}>
								<div className='red-text'>Do not refresh the page until this process is complete!</div>
							</div>
						</div>
					)
				}

				swal(placing_in_progress_options);

				const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_version_id:schoolInfo.current_data_version_id};
				const apiName = process.env.REACT_APP_ENDPOINT_NAME;
				const url = '/place-students';
				const myInit = { // OPTIONAL
					response: true,
					queryStringParameters: data,
				};
			
				try {
					await API.get(apiName, url, myInit);
					
					const fileUpload = new FileUpload(schoolInfo.current_data_version_id, schoolInfo.current_schedule_version_id, schoolInfo.school_id);
					const running_processes = await fileUpload.getSchoolProcesses(schoolInfo.current_schedule_year_id);
	
					// Check if there are any running processes with the same data version id
					const process_index = running_processes.findIndex(process => process.process_type === 'studentplace' && process.schedule_version_id == schoolInfo.current_schedule_version_id);
	
					// If process is already running, get progress of matches
					const process_info = (process_index !== -1) ? running_processes[process_index] : null;
					const progress_id = (process_info) ? process_info.progress_id : null;

					await fileUpload.checkUploading(schoolInfo.school_id, progress_id, (event) => {
						if(event.done) 
						{
							if(event.failure) 
							{
								const failure_options =  {
									icon:"error",
									title: "There was an error placing students.",
									text: "Please try again.",
									dangerMode: true,
								}

								swal(failure_options);
							}
							else 
							{
								setConflictsLoading(true);
								setMatchPercentLoading(true);

								const success_options =  {
									icon:"success",
									title: "Success!",
									text: "Your students should now be placed.",
								}

								pullNewSectionData(schoolInfo.current_schedule_version_id);
								swal(success_options);

								////////////////////////////
								///// UPDATE CONFLICTS /////
								////////////////////////////
								const conflicts_to_check = ['course_exceeds_capacity', 'teacher_student_restriction', 'student_student_restriction', 'student_double_booked', 'student_conflicting_periods', 'inclusion_too_many_students', 'inclusion_over_half'];
								const update_all = true;
								
								// Recalculate conflicts
								calculateConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, conflicts_to_check:conflicts_to_check, update_all:update_all}).then(conflicts_response => {
									const added_conflicts = conflicts_response.added_conflicts;
									const deleted_conflicts = conflicts_response.deleted_conflicts;
						
									// remove any deleted conflicts
									let new_conflicts = conflicts.filter(conflict => !deleted_conflicts.includes(conflict.conflict_id));
									new_conflicts = [...new_conflicts, ...added_conflicts];
						
									setConflicts([...new_conflicts]);
									setConflictsLoading(false);
								});
						
								// Recalculate MATCH PERCENT
								recalculateMatchPercent({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}).then(match_percent => {
									setMatchPercent(match_percent);
									setMatchPercentLoading(false);
								});
							}
						}
					});
				} catch(error)
				{
					const failure_options =  {
						icon:"error",
						title: "Error Placing Students",
						text: "We had an issue placing your students. Please try again.",
						dangerMode: true,
					}

					swal(failure_options);
					console.log(error.response);
				}
			}
		});
	}

	const clearSections = async () => {	
		const options =  {
			title: "Are you sure?",
			text: 'This will remove all sections from the schedule (that are not locked). This will NOT remove sections that were manually placed.',
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			},
			content: (
				<div className='sweet-alert-dont-show-message' style={{padding:'20px 10px 25px 10px'}}>
					<div className='dark-blue-text' style={{textAlign:'left',width:'340px',margin:'auto'}}>
						<div><strong>Pro Tip:</strong></div>
						<div>If you want to keep certain sections where they are, make sure to lock that section (by clicking the <FontAwesomeIcon className='gray-text' icon={faLock}/> icon on the section) before clearing!</div>
					</div>
				</div>
			)
		}
		
		await swal(options).then(async (return_value) => {
			if(return_value)
			{
				setConflictsLoading(true);

				// Update front end
				let new_sections = sections.map(section => {
					const locked = section.locked;
					const is_manual = section.is_manual;

					if(locked === '1' || is_manual === '1') return section;

					const course_id = section.course_id;
					const student_list = section.student_list;

					// Update student requests
					student_list.forEach(student_id => {
					const student_index = students.findIndex(student => student.student_id === student_id);

						if(student_index !== -1)
						{
							const student_info = students[student_index];
							const student_requests = student_info.student_requests;

							const request_index = student_requests.findIndex(request => request.course_id === course_id);
							if(request_index !== -1) students[student_index].student_requests[request_index].matched = '0';
						}
					});

					section.course_periods = [];
					section.quarter_days = [];
					section.student_list = [];
					return section;
				});

				setStudents([...students]);
				setSections([...new_sections]);

				// Send to Backend ///
				const data = {school_id: schoolInfo.school_id, schedule_version_id:currentScheduleVersionID};
				const apiName = process.env.REACT_APP_ENDPOINT_NAME;
				const url = '/clear-sections';
				const myInit = { // OPTIONAL
					response: true,
					body: JSON.stringify(data),
				};
			
				try {
					// Clear sections
					await API.post(apiName, url, myInit);

					// Recalculate conflicts
					const conflicts_to_check = ['course_exceeds_capacity', 'course_period_restriction', 'course_outside_normal_room', 'teacher_missing', 'teacher_period_restriction', 'teacher_student_restriction', 'student_student_restriction', 'teacher_double_booked', 'classroom_double_booked', 'student_double_booked', 'teacher_conflicting_periods', 'classroom_conflicting_periods', 'student_conflicting_periods', 'teacher_too_many_sections', 'teacher_too_many_sections_in_a_row', 'classroom_missing', 'inclusion_too_many_students', 'inclusion_over_half'];
					
					calculateConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, conflicts_to_check:conflicts_to_check, update_all:true}).then(conflicts_response => {
						const added_conflicts = conflicts_response.added_conflicts;
						const deleted_conflicts = conflicts_response.deleted_conflicts;

						// remove any deleted conflicts, add any added conflicts
						let new_conflicts = conflicts.filter(conflict => !deleted_conflicts.includes(conflict.conflict_id));
						new_conflicts = [...new_conflicts, ...added_conflicts];

						setConflicts([...new_conflicts]);
						setConflictsLoading(false);
					});

					// Recalculate match percent
					recalculateMatchPercent({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}).then(match_percent => {
						setMatchPercent(match_percent);
						setMatchPercentLoading(false);
					});
				} catch(e)
				{
					console.log(e.response);
				}
			}
		});
	}

	const clearClassrooms = async () => {	
		const options =  {
			title: "Are you sure?",
			text: 'This will remove classrooms from all sections in the schedule (that are not locked). This will NOT remove classrooms from sections that were manually placed.',
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			},
			content: (
				<div className='sweet-alert-dont-show-message' style={{padding:'20px 10px 25px 10px'}}>
					<div className='dark-blue-text' style={{textAlign:'left',width:'340px',margin:'auto'}}>
						<div><strong>Pro Tip:</strong></div>
						<div>If you want to keep classrooms of certain sections the way they are, make sure to lock that section (by clicking the <FontAwesomeIcon className='gray-text' icon={faLock}/> icon on the section) before clearing!</div>
					</div>
				</div>
			)
		}
		
		await swal(options).then(async (return_value) => {
			if(return_value)
			{
				setConflictsLoading(true);

				// Update front end
				let new_sections = sections.map(section => {
					const locked = section.locked;
					const is_manual = section.is_manual;

					if(locked === '1' || is_manual === '1') return section;

					section.classroom_id = null;
					return section;
				});

				setSections([...new_sections]);

				// Send to Backend ///
				const data = {school_id: schoolInfo.school_id, schedule_version_id:currentScheduleVersionID};
				const apiName = process.env.REACT_APP_ENDPOINT_NAME;
				const url = '/clear-classrooms';
				const myInit = { // OPTIONAL
					response: true,
					body: JSON.stringify(data),
				};
			
				try {
					// Clear classrooms
					await API.post(apiName, url, myInit);

					// Update conflicts
					const conflicts_to_check = ['course_outside_normal_room', 'classroom_double_booked', 'classroom_conflicting_periods', 'classroom_missing'];
					calculateConflicts({school_id: schoolInfo.school_id, schedule_version_id:currentScheduleVersionID, conflicts_to_check:conflicts_to_check, update_all:true}).then(conflicts_response => {
						const added_conflicts = conflicts_response.added_conflicts;
						const deleted_conflicts = conflicts_response.deleted_conflicts;
	
						// remove any deleted conflicts, add any added conflicts
						let new_conflicts = conflicts.filter(conflict => !deleted_conflicts.includes(conflict.conflict_id));
						new_conflicts = [...new_conflicts, ...added_conflicts];
	
						setConflicts([...new_conflicts]);
						setConflictsLoading(false);
					});
				} catch(e)
				{
					console.log(e.response);
				}
			}
		});
	}

	const placeSections = async () => {
		const options =  {
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Start Placing",
					value: true,
					visible: true,
					className: 'blue-btn'
				},
			},
			content: (
				<div>
					<FontAwesomeIcon className='modal-top-icon blue-text' icon={faCalendarDay}/>
					<h1 className='swal-title' style={{marginTop:'0px'}}>Place Sections</h1>
					<p className='swal-text'>This will try to place all your unplaced sections!</p>
				</div>
				
			)
		}

		await swal(options).then(async (return_value) => {
			if(return_value)
			{
				const placing_in_progress_options =  {
					button:false,
					closeOnClickOutside: false,
					closeOnEsc: false,
					content: (
						<div>
							<FontAwesomeIcon className='modal-top-icon placing-students-in-progress-icon' icon={faCalendarDay}/>
							<h1 className='swal-title' style={{marginTop:'0px'}}>Placing Sections in Progress</h1>
							<p className='swal-text'>Please wait, this may take a few minutes...</p>
							<div className='sweet-alert-dont-show-message' style={{padding:'20px 10px 25px 10px'}}>
								<div className='red-text'>Do not refresh the page until this process is complete!</div>
							</div>
						</div>
					)
				}

				swal(placing_in_progress_options);

				const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_version_id:schoolInfo.current_data_version_id};
				const apiName = process.env.REACT_APP_ENDPOINT_NAME;
				const url = '/place-sections';
				const myInit = { // OPTIONAL
					response: true,
					queryStringParameters: data,
				};
			
				try {
					await API.get(apiName, url, myInit);
					
					const fileUpload = new FileUpload(schoolInfo.current_data_version_id, schoolInfo.current_schedule_version_id, schoolInfo.school_id);
					const running_processes = await fileUpload.getSchoolProcesses(schoolInfo.current_schedule_year_id);

					// Check if there are any running processes with the same data version id
					const process_index = running_processes.findIndex(process => process.process_type === 'sectionplace' && process.schedule_version_id == schoolInfo.current_schedule_version_id);

					// If process is already running, get progress of matches
					const process_info = (process_index !== -1) ? running_processes[process_index] : null;
					const progress_id = (process_info) ? process_info.progress_id : null;

					await fileUpload.checkUploading(schoolInfo.school_id, progress_id, (event) => {
						if(event.done) 
						{
							if(event.failure) 
							{
								const failure_options =  {
									icon:"error",
									title: "There was an error placing sections.",
									text: "Please try again.",
									dangerMode: true,
								}

								swal(failure_options);
							} 
							else 
							{
								setConflictsLoading(true);
								setMatchPercentLoading(true);

								const success_options =  {
									icon:"success",
									title: "Success!",
									text: "Your sections should now be placed.",
								}

								pullNewSectionData(schoolInfo.current_schedule_version_id);
								swal(success_options);

								////////////////////////////
								///// UPDATE CONFLICTS /////
								////////////////////////////
								const conflicts_to_check = ['teacher_double_booked', 'teacher_conflicting_periods', 'classroom_double_booked', 'classroom_conflicting_periods'];
								const update_all = true;
								
								calculateConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, conflicts_to_check:conflicts_to_check, update_all:update_all}).then(conflicts_response => {
									const added_conflicts = conflicts_response.added_conflicts;
									const deleted_conflicts = conflicts_response.deleted_conflicts;
			
									// remove any deleted conflicts, add any added conflicts
									let new_conflicts = conflicts.filter(conflict => !deleted_conflicts.includes(conflict.conflict_id));
									new_conflicts = [...new_conflicts, ...added_conflicts];
			
									setConflicts([...new_conflicts]);
									setConflictsLoading(false);
								});
			
								// Recalculate match percent
								recalculateMatchPercent({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}).then(match_percent => {
									setMatchPercent(match_percent);
									setMatchPercentLoading(false);
								});
							}
						}
					});
				} catch(error)
				{
					const failure_options =  {
						icon:"error",
						title: "Error Placing Sections",
						text: "We had an issue placing your sections. Please try again.",
						dangerMode: true,
					}

					swal(failure_options);
					console.log(error.response);
				}
			}
		});
	}
	
	const CreateCourseMap = () => {
		// Initialize State Variables
		const [courseMap, setCourseMap] = useState({});
		const [gymCourseSelected, setGymCourseSelected] = useState(null);
		const [courseTypeOpen, setCourseTypeOpen] = useState(null);
		const [searchResults, setSearchResults] = useState([...courses]);
		const [errorMessage, setErrorMessage] = useState(null);
		const [isGenerating, setIsGenerating] = useState(false);

		// Functions
		const fuzzySearch = (e) => {
			const search_value = e.target.value;

			const fuse = new Fuse(courses, {
				keys: ['name', 'course_code'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const course_results = results.map(result => result.item).slice(0, 6);
			
			const search_results = (course_results.length === 0) ? courses : course_results;
			setSearchResults([...search_results]);
		}

		const addToCourseMap = (course_id) => {
			if(courseTypeOpen === 'gym')
			{
				const course_already_exists = Object.keys(courseMap).findIndex(gym_course_id => courseMap[gym_course_id] === gym_course_id) !== -1;
				if(!course_already_exists) courseMap[course_id] = null;
			}
			else if(courseTypeOpen === 'health' && gymCourseSelected)
			{
				if(gymCourseSelected in courseMap) courseMap[gymCourseSelected] = course_id;
			}

			setCourseMap({...courseMap});
			setGymCourseSelected(null);
			setCourseTypeOpen(null);
			setSearchResults([...courses]);
			setErrorMessage(null);
		}

		const removeFromCourseMap = (gym_course_id) => {
			delete courseMap[gym_course_id];

			setCourseMap({...courseMap});
			setErrorMessage(null);
		}

		// Submit Function
		const submitCourseMap = async () => {
			if(Object.keys(courseMap).length === 0)
			{
				setErrorMessage("You need to add a Phys Ed & Health pairing!");
				return false;
			}

			let health_is_missing = false;
			Object.keys(courseMap).forEach(gym_course_id => {
				const health_course_id = courseMap[gym_course_id];
				if(!health_course_id) health_is_missing = true;
			});

			if(health_is_missing)
			{
				setErrorMessage("There is a Phys Ed/Gym course missing a Health course pairing!");
				return false;
			}

			setIsGenerating(true);

			// Send to Backend ///
			const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_version_id:schoolInfo.current_data_version_id, courseMap: courseMap};
			const apiName = process.env.REACT_APP_ENDPOINT_NAME;
			const url = '/place-health';
			const myInit = { // OPTIONAL
				response: true,
				body: JSON.stringify(data),
			};
		
			try {
				await API.post(apiName, url, myInit);

				const fileUpload = new FileUpload(schoolInfo.current_data_version_id, schoolInfo.current_schedule_version_id, schoolInfo.school_id);
				const running_processes = await fileUpload.getSchoolProcesses(schoolInfo.current_schedule_year_id);

				// Check if there are any running processes with the same data version id
				const process_index = running_processes.findIndex(process => process.process_type === 'healthplace' && process.schedule_version_id == schoolInfo.current_schedule_version_id);

				// If process is already running, get progress of matches
				const process_info = (process_index !== -1) ? running_processes[process_index] : null;
				const progress_id = (process_info) ? process_info.progress_id : null;

				await fileUpload.checkUploading(schoolInfo.school_id, progress_id, (event) => {
					if(event.done) 
					{
						if(event.failure) 
						{
							const failure_options =  {
								icon:"error",
								title: "Error Creating Health Sections",
								text: "We had an issue creating your health sections. Please try again.",
								dangerMode: true,
							}

							swal(failure_options);
						}
						else 
						{
							const success_options =  {
								icon:"success",
								title: "Success!",
								text: "Your health sections were successfully created.",
							}

							pullNewSectionData(schoolInfo.current_schedule_version_id);
							swal(success_options);
						}
					}
				});
			} catch(e)
			{
				const failure_options =  {
					icon:"error",
					title: "Error Creating Health Sections",
					text: "We had an issue creating your health sections. Please try again.",
					dangerMode: true,
				}

				swal(failure_options);
				console.log(e.response);
			}
		}

		// HTML Component
		return (
			<div>
				{isGenerating ? 
					(
						<div className='loader'></div>
					):
					(
						<>
						<div className='mb-create-health-map-parent-container text-align-left'>
							<div>Tell us which Healths should be paired with which Phys Ed/Gym courses:</div>
							<div className='mb-create-health-map-container'>
								{(Object.keys(courseMap).length > 0) &&
									<>
									<h2 className='mb-create-health-map-header'>Phys Ed/Gym Course</h2>
									<div></div>
									<h2 className='mb-create-health-map-header'>Health Course</h2>
									<div></div>
									</>
								}
								{Object.keys(courseMap).map((gym_course_id, i) => {
									const health_course_id = courseMap[gym_course_id];

									const gym_course_info = courses.find(course => course.course_id === gym_course_id);
									const health_course_info = (health_course_id) ? courses.find(course => course.course_id === health_course_id) : null;

									return(
										<React.Fragment key={i}>
											<div className='mb-create-health-map-pair medium-text'>
												{gym_course_id && <span>{gym_course_info.name} ({gym_course_info.course_code})</span>}
											</div>
											<FontAwesomeIcon icon={faArrowRight} />
											<div className='mb-create-health-map-pair medium-text'>
												{health_course_id ?
													(
														<span>{health_course_info.name} ({health_course_info.course_code})</span>
													):
													(
														<div className='mb-create-health-map-link blue-link' onClick={() => {setGymCourseSelected(gym_course_id);setCourseTypeOpen('health')}}>Add Health Pairing</div>
													)
												}
											</div>
											<FontAwesomeIcon className='gray-to-red-link' icon={faTimes} onClick={() => removeFromCourseMap(gym_course_id)}/>
										</React.Fragment>
									)
								})}
							</div>
							<div className='mb-create-health-map-btn btn turquoise-btn' onClick={() => setCourseTypeOpen('gym')}>Add New Pairing</div>
							{courseTypeOpen &&
								<div className='mb-create-health-map-search-container'>
									<FontAwesomeIcon className='x-cancel mb-create-health-map-search-add-cancel-new-btn' icon={faTimes}  onClick={() => setCourseTypeOpen(null)}/>
									<div className='mb-create-health-map-search-add-did-you-mean'>Search for a <span className='capitalize'>{courseTypeOpen}</span> class:</div>
									<div className='search-add-adding-input-container'>
										<input id='main-course-input' className='edit-section-input' placeholder={`Search for a ${courseTypeOpen} course`} onChange={(e) => fuzzySearch(e, 'main', 'courses')} autoFocus={true}/>
										<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
									</div>
									{searchResults.length !== 0 &&
										<>
										<div className='mb-create-health-map-search-add-search-results'>
											{searchResults.map((course, i) => {
												return (
													<div className='search-add-search-result' key={i}>
														<div>{course.name} ({course.course_code})</div>
														<div>
															<div className='search-add-add-btn' onClick={() => addToCourseMap(course.course_id)}>Select</div>
														</div>
													</div>
												)
											})}
										</div>
										</>
									}
								</div>
							}
						</div>
						{errorMessage &&
							<div className='error-message-no-box'>{errorMessage}</div>
						}
						<div className="swal-footer">
							<div className="swal-button-container" onClick={() => swal.close()}>
								<button className="swal-button swal-button--cancel gray-btn" tabIndex="0">Cancel</button>
								<div className="swal-button__loader"></div>
							</div>
							<div className="swal-button-container" onClick={submitCourseMap}>
								<button className="swal-button blue-btn">Create Healths</button>
								<div className="swal-button__loader"></div>
							</div>
						</div>
						</>
					)
				}
			</div>
		)
	}

	const createHealthSections = async () => {	
		const options =  {
			title: "Auto Create Health Sections",
			text: 'This will automatically create health sections for all your Phys Ed/Gym sections.',
			buttons:false,
			content: (
				<CreateCourseMap />
			)
		}
		
		swal(options);
	}

	const updateSectionPlacement = useCallback(async (item) => {
		// This is the original teacher or classroom id that section came from
		const original_data_id = item.draggedDataID;

		// This is the new teacher or classroom id that section was dropped
		const new_data_id = item.droppedDataID;

		// This is the course period where section was dropped
		const new_course_period_id = item.droppedCoursePeriodID;

		// This is the course period where section was dragged from
		const old_course_period_id = item.draggedCoursePeriodID;

		// Sections from magnetboard always have the main section ID attached
		const main_section_id = item.sectionID;
		const main_section_info = sections.find(section => section.section_id === main_section_id);
		const main_section_subsections = main_section_info.subsections;

		// Now check if dragged section is actually a subsection
		// The only way to check if dragged section is from main teacher or subsection teacher is to compare original data id to subsection teachers
		const affected_section_id = main_section_subsections.reduce((results, subsection_id) => {
			const subsection_info = sections.find(subsection => subsection.section_id === subsection_id);
			const subsection_teacher_id = (subsection_info) ? subsection_info.teacher_id : null;
			if(currentView === 'teacher' && (original_data_id === subsection_teacher_id)) results = subsection_id;
			return results;
		}, main_section_id);

		const section_info = sections.find(section => section.section_id === affected_section_id);
		const old_course_periods = section_info.course_periods;
		const old_teacher_id = section_info.teacher_id;
		const old_classroom_id = section_info.classroom_id;

		// Don't allow a section that spans more than one period to have multiple sections in the same period
		if(old_course_periods.includes(new_course_period_id)) return;

		const new_teacher_id = (currentView === 'teacher') ? new_data_id : section_info.teacher_id;
		const new_classroom_id = (currentView === 'classroom') ? new_data_id : section_info.classroom_id;

		// If section was dropped in the same period, do nothing
		const nothing_changed = ((currentView === 'teacher' && old_teacher_id === new_teacher_id && old_course_period_id === new_course_period_id) || (currentView === 'classroom' && old_classroom_id === new_classroom_id && old_course_period_id === new_course_period_id));
		if(nothing_changed) return;

		setConflictsLoading(true);
		setMatchPercentLoading(true);

		// Check if this section meets any of the requirements to calculate affected sections
		const has_other_affected_sections = (main_section_subsections.length > 0);

		////////////////////////////
		///// SAVE TO FRONT END ////
		////////////////////////////
		const affected_sections = (has_other_affected_sections) ? calculateAffectedSectionsForPlacements({sections:sections, subsections:subsections, section_id:affected_section_id, new_course_period_id:new_course_period_id, old_course_period_id:old_course_period_id}) : [{section_id:affected_section_id, new_course_period_id:new_course_period_id, old_course_period_id:old_course_period_id, edit_teacher:true}];
		const affected_section_ids = [];

		// Update FRONTEND
		affected_sections.forEach(affected_section => {
			const section_id = affected_section.section_id;
			const section_index = sections.findIndex(section => section.section_id === section_id);
			
			if(section_index === -1) return;
			affected_section_ids.push(section_id); // collect section ids to calculate conflicts later

			const section_info = sections[section_index];
			const section_is_lab = section_info.is_lab === '1';

			const edit_teacher = affected_section.edit_teacher;
			const new_course_period_id = affected_section.new_course_period_id;
			const old_course_period_id = affected_section.old_course_period_id;

			// Update sections
			if(currentView === 'classroom') sections[section_index].classroom_id = new_classroom_id;
			if(edit_teacher) sections[section_index].teacher_id = new_teacher_id;

			// Get new quarter days if section isn't lab
			if(!section_is_lab)
			{
				const section_quarter_days = sections[section_index].quarter_days;

				const quarters_to_add = section_quarter_days.reduce((results, quarter_day) => {
					const quarter = quarter_day.quarter;
					if(!results.includes(quarter)) results.push(quarter);
					return results;
				}, []);

				const days_for_course_period_id = scheduleStructureData.course_periods[new_course_period_id].days;
				const days_to_add = Object.keys(days_for_course_period_id).reduce((results, day) => {
					const schedule_period_on_day = days_for_course_period_id[day];
					if(schedule_period_on_day && !results.includes(day)) results.push(day);
					return results;
				}, []);

				let new_quarter_days = [];
				for(const day of days_to_add)
				{
					for(const quarter of quarters_to_add)
					{
						new_quarter_days.push({day:day, quarter:quarter});
					}
				}

				sections[section_index].quarter_days = new_quarter_days;
			}

			// Update section course period id
			const course_period_index = sections[section_index].course_periods.findIndex(cp => cp === old_course_period_id);
			sections[section_index].course_periods[course_period_index] = new_course_period_id;
		});

		setSections([...sections]);

		///////////////////////////////////
		//// SAVE ACTION FOR UNDO/REDO ////
		///////////////////////////////////
		// Save old version of section to session storage
		const actionLog = getJSONFromStorage('actionLog', true, 'session');
		const new_action = {
								section_id:main_section_id, 
								current_view: currentView,
								old_section: {
									course_period_id:old_course_period_id, 
									teacher_id:old_teacher_id,
									classroom_id:old_classroom_id
								},
								new_section: {
									course_period_id:new_course_period_id, 
									teacher_id:new_teacher_id,
									classroom_id:new_classroom_id
								}
							};

		actionLog.push(new_action);
		sessionStorage.setItem('actionLog', JSON.stringify(actionLog));

		////////////////////////////
		///// SAVE TO DATABASE /////
		////////////////////////////
		// SAVE SECTION PLACEMENT
		await saveSectionPlacement({school_id:schoolInfo.school_id, schedule_version_id:currentScheduleVersionID, teacher_id:new_teacher_id, classroom_id:new_classroom_id, affected_sections:affected_sections});

		// Update CONFLICTS
		const conflicts_to_check = ['teacher_double_booked', 'classroom_double_booked', 'student_double_booked', 'teacher_conflicting_periods', 'classroom_conflicting_periods', 'student_conflicting_periods', 'teacher_period_restriction', 'teacher_too_many_sections_in_a_row', 'course_period_restriction', 'course_outside_normal_room'];
		
		setTimeout(function() { 
			calculateConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, conflicts_to_check:conflicts_to_check, affected_sections:affected_section_ids, update_all:false}).then(conflicts_response => {
				const added_conflicts = conflicts_response.added_conflicts;
				const deleted_conflicts = conflicts_response.deleted_conflicts;
	
				// remove any deleted conflicts
				let new_conflicts = conflicts.filter(conflict => !deleted_conflicts.includes(conflict.conflict_id));
				new_conflicts = [...new_conflicts, ...added_conflicts];
	
				setConflicts(conflicts => ([...new_conflicts]));
				setConflictsLoading(false);
			});
		}, 500);

		// Recalculate MATCH PERCENT
		recalculateMatchPercent({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}).then(match_percent => {
			setMatchPercent(match_percent);
			setMatchPercentLoading(false);
		});
	},[conflicts, currentScheduleVersionID, currentView, scheduleStructureData, schoolInfo, sections, setSections, subsections]);

	const undoAction = useCallback(() => {
		const actionLog = getJSONFromStorage('actionLog', true, 'session');
		
		if(actionLog.length === 0) return false;

		const last_action = actionLog.pop();
		const section_id = last_action.section_id;
		const current_view = last_action.current_view;

		// New section info (aka dragged)
		const new_section_info = last_action.new_section;
		const new_course_period_id = new_section_info.course_period_id;
		const new_teacher_id = new_section_info.teacher_id;
		const new_classroom_id = new_section_info.classroom_id;

		// Old section info (aka dropped)
		const old_section_info = last_action.old_section;
		const old_course_period_id = old_section_info.course_period_id;
		const old_teacher_id = old_section_info.teacher_id;
		const old_classroom_id = old_section_info.classroom_id;

		const old_data_id = (current_view === 'teacher') ? old_teacher_id : old_classroom_id;
		const new_data_id = (current_view === 'teacher') ? new_teacher_id : new_classroom_id;

		// Update section placement with old info
		updateSectionPlacement({sectionID:section_id, droppedCoursePeriodID:old_course_period_id, draggedCoursePeriodID:new_course_period_id, droppedDataID:old_data_id, draggedDataID:new_data_id});

		// Add the last action into redo list
		const undoneActions = getJSONFromStorage('undoneActions', true, 'session');
		undoneActions.push(last_action);

		sessionStorage.setItem('actionLog', JSON.stringify(actionLog));
		sessionStorage.setItem('undoneActions', JSON.stringify(undoneActions));
	},[updateSectionPlacement])

	const redoAction = useCallback(() => {
		const undoneActions = getJSONFromStorage('undoneActions', true, 'session');

		if(undoneActions.length === 0) return false;

		const last_action = undoneActions.pop();
		const section_id = last_action.section_id;
		const current_view = last_action.current_view;

		// Old section info (aka dragged)
		const old_section_info = last_action.old_section;
		const old_course_period_id = old_section_info.course_period_id;
		const old_teacher_id = old_section_info.teacher_id;
		const old_classroom_id = old_section_info.classroom_id;

		// New section info (aka dropped)
		const new_section_info = last_action.new_section;
		const new_course_period_id = new_section_info.course_period_id;
		const new_teacher_id = new_section_info.teacher_id;
		const new_classroom_id = new_section_info.classroom_id;

		const new_data_id = (current_view === 'teacher') ? new_teacher_id : new_classroom_id;
		const old_data_id = (current_view === 'teacher') ? old_teacher_id : old_classroom_id;

		// Update section placement with old info
		updateSectionPlacement({sectionID: section_id, droppedCoursePeriodID:new_course_period_id, draggedCoursePeriodID:old_course_period_id, droppedDataID:new_data_id, draggedDataID:old_data_id});

		sessionStorage.setItem('undoneActions', JSON.stringify(undoneActions));
	},[updateSectionPlacement])
	
	const toggleEditSection = useCallback((passed_data) => {
		let {section_id = null, data_id=null, section_type=null, course_period_id=null} = passed_data || {};
		// Check if section is subsection
		if(section_id)
		{
			const subsection_index = subsections.findIndex(subsection => subsection.subsection_id === section_id);
			if(subsection_index !== -1)
			{
				const section_info = subsections[subsection_index];
				section_id = section_info.section_id;
			}
		}

		setShowEditSectionModal(se => !se);
		setCurrentSectionID(section_id);
		setCurrentDataID(data_id);
		setCurrentSectionType(section_type);
		if(course_period_id) setCurrentCoursePeriodID(course_period_id);
	},[subsections])

	const toggleEditStudentSchedule = (studentID) => {
		setCurrentStudentID(studentID);
		
		if(studentID)
		{
			setShowEditStudentScheduleModal(true);
		}
		else
		{
			setShowEditStudentScheduleModal(false);
		}
	}

	const updateManualPlacement = async (props) => {
		const update_type = props.updateType;
		const section_id = props.sectionID;
		const new_course_period_id = (update_type === 'add') ? props.droppedCoursePeriodID : null;

		const new_course_period_days = (update_type === 'add') ? scheduleStructureData.course_periods[new_course_period_id].days : {};

		// Get the current day or if that doesn't exist, use first day that this course period shows up
		const current_day = (currentDay) ? currentDay : Object.keys(new_course_period_days).reduce((results, day_number) => {
			const day_schedule_period_id = new_course_period_days[day_number];
			if(!results && day_schedule_period_id) results = day_number;
			return results;
		}, null);
		
		setMatchPercentLoading(true);
		setConflictsLoading(true);

		////////////////////////
		/// Update front end ///
		////////////////////////
		const section_index = sections.findIndex(section => section.section_id === section_id);
		const section_info = sections[section_index];
		const section_course_id = section_info.course_id;
		const section_semester = section_info.semester;
		const section_span_id = section_info.span_id;
		const section_subsections = section_info.subsections;

		// Collect all the affected main sections
		const lab_sections = (!section_span_id) ? [] : sections.filter(section => section.span_id === section_span_id && section.section_id !== section_id);

		// Get dropped sections new schedule period's order
		const new_schedule_period_order = (update_type === 'add') ? parseInt(scheduleStructureData.schedule_structure[current_day].find(day_ss => day_ss.course_period_id === new_course_period_id).period_order) : null;

		// Keep track of which course periods have been used
		// Add the new course period as logged
		let is_manual = '0';
		let new_course_periods = [];
		let new_quarter_days = [];
		let new_lab_course_periods = [];
		let new_lab_quarter_days = [];
		
		if(update_type === 'add')
		{
			// Update is_manual
			is_manual = '1';

			// Add dropped course period id to the course period list
			let current_period_order = new_schedule_period_order;
			let logged_period_orders = (update_type === 'add') ? [new_schedule_period_order] : [];
			
			new_course_periods.push(new_course_period_id);

			// Const used to help decided quarter days
			const semester_to_quarters = {'FY': ['1', '2', '3', '4'], 'S1':['1','2'], 'S2':['3','4'], 'Q1':['1'], 'Q2':['2'], 'Q3':['3'], 'Q4':['4']};

			// Get schedule period with the max period order
			// Used later to see if we've bumped into the last period of the day
			const max_period_order = scheduleStructureData.schedule_structure[current_day].reduce((results, day_ss_row) => {
				const period_order = parseInt(day_ss_row.period_order);
				if(period_order > results) results = period_order;
				return results;
			}, -1);
			const max_num_schedule_periods = scheduleStructureData.schedule_structure[current_day].length;

			// Figure out if we need to add more course periods
			const section_course_info = courses.find(course => course.course_id === section_course_id);
			const num_periods_spanned = (section_course_info) ? parseInt(section_course_info.num_periods_spanned) : 1;

			for(let i = 1; i < num_periods_spanned; i++)
			{
				let index = 0;
				let section_new_course_period_id = null;

				while(!section_new_course_period_id && index < 5)
				{
					// If we've used every period once, then clear logged period orders
					if(logged_period_orders.length === max_num_schedule_periods) 
					{
						logged_period_orders = [];
						current_period_order = new_schedule_period_order;
					}
	
					// If we haven't reached max period order, then keep incrementing up
					// If we have hit max period order, increment down
					current_period_order = (!logged_period_orders.includes(max_period_order)) ? ++current_period_order: --current_period_order;
	
					// If period order hasn't been used yet, then find course period during this schedule period
					if(!logged_period_orders.includes(current_period_order)) 
					{
						// First find schedule period with this period order
						const temp_day_ss_row = scheduleStructureData.schedule_structure[current_day].reduce((results, day_ss_row) => {
							const period_order = parseInt(day_ss_row.period_order);
							if(period_order === current_period_order) results = day_ss_row;
							return results;
						}, null);
	
						// Then find course period id in this schedule period on current day
						section_new_course_period_id = temp_day_ss_row.course_period_id;
	
						// Log the use of this period order
						logged_period_orders.push(current_period_order);
						new_course_periods.push(section_new_course_period_id);
					}
					index++;
				}
			}

			// Find quarters and days to add for this new course period id
			const quarters_to_add = (section_semester in semester_to_quarters) ? semester_to_quarters[section_semester] : [];
			
			// Find days for this course period id
			const days_to_add = new_course_periods.reduce((results, new_course_period_id) => {
				const days_for_cp = scheduleStructureData.course_periods[new_course_period_id].days;

				for(const day in days_for_cp)
				{
					const schedule_period_on_day = days_for_cp[day];
					if(schedule_period_on_day && !results.includes(day)) results.push(day);
				}

				return results;
			},[]);

			// Get new quarter days
			for(const day of days_to_add)
			{
				for(const quarter of quarters_to_add)
				{
					new_quarter_days.push({day:day, quarter:quarter});
				}
			}

			if(lab_sections.length > 0)
			{
				let index = 0;
				let lab_new_course_period_id = null;

				while(!lab_new_course_period_id && index < 5)
				{
					// If we've used every period once, then clear logged period orders
					if(logged_period_orders.length === max_num_schedule_periods) 
					{
						logged_period_orders = [];
						current_period_order = new_schedule_period_order;
					}
	
					// If we haven't reached max period order, then keep incrementing up
					// If we have hit max period order, increment down
					current_period_order = (!logged_period_orders.includes(max_period_order)) ? ++current_period_order: --current_period_order;
	
					// If period order hasn't been used yet, then find course period during this schedule period
					if(!logged_period_orders.includes(current_period_order)) 
					{
						// First find schedule period with this period order
						const temp_day_ss_row = scheduleStructureData.schedule_structure[current_day].reduce((results, day_ss_row) => {
							const period_order = parseInt(day_ss_row.period_order);
							if(period_order === current_period_order) results = day_ss_row;
							return results;
						}, null);
	
						// Then find course period id in this schedule period on current day
						lab_new_course_period_id = temp_day_ss_row.course_period_id;;
	
						// Log the use of this period order
						logged_period_orders.push(current_period_order);
						new_lab_course_periods.push(lab_new_course_period_id);
					}
					index++;
				}
			}
		}

		// Set new section course periods and quarter days
		// If this was adding, these values are caluclated above
		// If this is removing, these should be empty arrays
		sections[section_index].course_periods = new_course_periods;
		sections[section_index].quarter_days = new_quarter_days;
		sections[section_index].is_manual = is_manual;

		section_subsections.forEach(subsection_id => {
			const subsection_section_index = sections.findIndex(section => section.section_id === subsection_id);
			if(subsection_section_index !== -1) 
			{
				sections[subsection_section_index].course_periods = new_course_periods;
				sections[subsection_section_index].quarter_days = new_quarter_days;
				sections[subsection_section_index].is_manual = is_manual;
			}
		});

		// Add new lab course periods and quarter days (if course has lab sections)
		for(const section_info of lab_sections)
		{
			const lab_section_id = section_info.section_id;
			const lab_subsections = section_info.subsections;
			const lab_section_index = sections.findIndex(section => section.section_id === lab_section_id);

			sections[lab_section_index].course_periods = new_lab_course_periods;
			sections[lab_section_index].quarter_days = new_lab_quarter_days;
			sections[lab_section_index].is_manual = is_manual;

			lab_subsections.forEach(subsection_id => {
				const subsection_section_index = sections.findIndex(section => section.section_id === subsection_id);
				if(subsection_section_index !== -1) 
				{
					sections[subsection_section_index].course_periods = new_lab_course_periods;
					sections[subsection_section_index].quarter_days = new_lab_quarter_days;
					sections[subsection_section_index].is_manual = is_manual;
				}
			});
		}

		setSections([...sections]);

		////////////////////////
		/// Update back end  ///
		////////////////////////

		const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, department_id:magnetboardOptions.current_department_id, update_type:update_type, section_info:section_info, new_course_period_id:new_course_period_id};
		
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/admin/update-manual-placement';
		const myInit = { // OPTIONAL
			response: true,
			body: JSON.stringify(data),
		};
	
		try {
			const result = await API.post(apiName, url, myInit);
			const response_data = result.data;
			const returned_sections = response_data.returned_sections;

			// Update sections
			const affected_section_ids = [];
			returned_sections.forEach(changed_section_info => {
				const section_id = changed_section_info.section_id;
				const new_course_periods = changed_section_info.course_periods;
				const quarter_days = ('quarter_days' in changed_section_info) ? changed_section_info.quarter_days : null;

				// See if previous section id existed
				const previous_section_index = sections.findIndex(section => section.section_id === section_id);

				// If section existed, replace old section data
				if(previous_section_index !== -1) 
				{
					sections[previous_section_index].course_periods = new_course_periods;
					if(quarter_days) sections[previous_section_index].quarter_days = quarter_days;
				}

				affected_section_ids.push(section_id);
			});

			setSections([...sections]);

			// Update conflicts
			const conflicts_to_check = ['teacher_double_booked', 'classroom_double_booked', 'student_double_booked', 'teacher_conflicting_periods', 'classroom_conflicting_periods', 'student_conflicting_periods', 'teacher_missing', 'teacher_period_restriction', 'teacher_too_many_sections', 'teacher_too_many_sections_in_a_row', 'student_student_restriction', 'teacher_student_restriction', 'course_period_restriction', 'course_exceeds_capacity', 'course_outside_normal_room', 'classroom_missing'];
			calculateConflicts({school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, conflicts_to_check:conflicts_to_check, affected_sections:affected_section_ids, update_all:false}).then(conflicts_response => {
				const added_conflicts = conflicts_response.added_conflicts;
				const deleted_conflicts = conflicts_response.deleted_conflicts;

				// remove any deleted conflicts, add any added conflicts
				let new_conflicts = conflicts.filter(conflict => !deleted_conflicts.includes(conflict.conflict_id));
				new_conflicts = [...new_conflicts, ...added_conflicts];

				setConflicts([...new_conflicts]);
				setConflictsLoading(false);
			});

			// Recalculate match percent
			recalculateMatchPercent({school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}).then(match_percent => {
				setMatchPercent(match_percent);
				setMatchPercentLoading(false);
			});
		} catch(error)
		{
			console.log(error.response);
		}
	}

	const showNewNameModal = (error_message = null) => {
		const options =  {
			className: 'swal-custom-save-schedule-name',
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn',
					closeModal: true
				},
				confirm: {
					text: "Save",
					visible: true,
					className: 'blue-btn', 
					closeModal: false, 
					value:''
				},
			},
			content: (
				<div>
					<div>
						<FontAwesomeIcon className='modal-top-icon blue-text' icon={faCopy}/>
						<h1 style={{margin:'0px',textAlign:'left'}}>Saving New Copy</h1>
						<p className='medium-text' style={{maxWidth:'300px',textAlign:'left'}}>This will create a new copy of your schedule that you can edit (to try to increase the match percent) without affecting your current copy</p>
						<div style={{margin:'0px 0px 50px 0px'}}>
							<div className='small-text' style={{margin:'20px 0px 0px 0px', textAlign:'left'}}>New Schedule Name</div>
							<input className={`add-new-schedule-name ${error_message ? 'error' : ''}`} defaultValue='' onChange={(e) => swal.setActionValue(e.target.value)}/>
						</div>
					</div>
					{error_message &&
						<div className='red-text small-text'>{error_message}</div>
					}
				</div>
			),
		}
	
		return swal(options).then((schedule_name) => {
			if(schedule_name === '')
			{
				swal.stopLoading();
				swal.close();
				return showNewNameModal("You need to input a name for your schedule!");
			}
			else if(!schedule_name)
			{
				swal.stopLoading();
				swal.close();
				return false;
			}
			else
			{
				swal.close();
				return schedule_name;
			}
		});
	}

	const saveSchedule = async (save_type = 'new', schedule_name = currentSchedule.schedule_name) => {
		if(save_type === 'new')  schedule_name = await showNewNameModal();
		
		if(schedule_name)
		{
			setIsSaving(true);

			const swal_options = {
				className: 'swal-custom-save-schedule-name',
				button:false,
				closeOnClickOutside: false,
				closeOnEsc: false,
				content: (
					<div className='mb-saving-container'>
						<h1 className='mb-saving-heading'>Your schedule is saving</h1>
						<p style={{margin:'0px 0px 30px 0px'}}>Please wait, your new schedule copy is being created.</p>
						<div className='dark-gray-text medium-text' style={{marginBottom:'10px'}}>Copying...</div>
						<div className='mb-saving-inner-container'>
							<div className='mb-saving-row'>
								<div>Section Placements</div>
								<div className='mb-saving-progress progress-bar-container'>
									<div id='mb-saving-section-progress' className='progress-bar-inner-container'></div>
								</div>
							</div>
							<div className='mb-saving-row'>
								<div>Teacher Placements</div>
								<div className='mb-saving-progress progress-bar-container'>
									<div id='mb-saving-teacher-progress' className='progress-bar-inner-container'></div>
								</div>
							</div>
							<div className='mb-saving-row'>
								<div>Classroom Placements</div>
								<div className='mb-saving-progress progress-bar-container'>
									<div id='mb-saving-classroom-progress' className='progress-bar-inner-container'></div>
								</div>
							</div>
							<div className='mb-saving-row'>
								<div>Student Placements</div>
								<div className='mb-saving-progress progress-bar-container'>
									<div id='mb-saving-student-progress' className='progress-bar-inner-container'></div>
								</div>
							</div>
							<div className='mb-saving-row'>
								<div>Saving Copy</div>
								<div className='mb-saving-progress progress-bar-container'>
									<div id='mb-saving-copying-progress' className='progress-bar-inner-container'></div>
								</div>
							</div>
						</div>
					</div>
				)
			}

			swal(swal_options);

			setTimeout(function () {
				swal.close();
				setIsSaving(false);
			}, 25000);

			const data = {school_id: schoolInfo.school_id, save_type:save_type, schedule_version_id:currentScheduleVersionID, schedule_name:schedule_name, match_percent:matchPercent};
		
			const apiName = process.env.REACT_APP_ENDPOINT_NAME;
			const url = '/save-schedule';
			const myInit = { // OPTIONAL
				response: true,
				body: JSON.stringify(data),
			};
		
			try {
				const response = await API.post(apiName, url, myInit);
				const response_data = response.data;

				const new_schedule_version_id = response_data.new_schedule_version_id;
				const new_schedule_name = response_data.new_schedule_name;
				const new_time_stamp = response_data.new_time_stamp;

				const old_schedule_index = scheduleInfo.schedules.findIndex(schedule => schedule.schedule_version_id === currentScheduleVersionID);
				let new_schedule_info = cloneObj(scheduleInfo.schedules[old_schedule_index]);
				
				if(save_type === 'schedule_name')
				{
					scheduleInfo.schedules[old_schedule_index].schedule_name = new_schedule_name;

					new_schedule_info.schedule_name = new_schedule_name;
					setEditingScheduleName(!editingScheduleName);
				}
				else if(save_type === 'new')
				{
					const old_schedule_info = scheduleInfo.schedules[old_schedule_index];

					old_schedule_info.current_bool = '0';

					new_schedule_info.schedule_version_id = new_schedule_version_id;
					new_schedule_info.schedule_name = new_schedule_name;
					new_schedule_info.time_stamp = new_time_stamp;
					new_schedule_info.current_bool = '1';

					scheduleInfo.schedules.push(new_schedule_info);
				}

				setCurrentScheduleVersionID(new_schedule_version_id);	
				setCurrentSchedule({...new_schedule_info});
				setScheduleInfo({...scheduleInfo});
			} catch(e)
			{
				console.log(e.response);
			}
		}
	}
	
	////////////////////////
	///// DO ON CHANGE /////
	////////////////////////
	// Create day options
	useEffect(() => {
		if(!isEmpty(scheduleInfo) && !isEmpty(schoolInfo))
		{
			const schedule_index = scheduleInfo.schedules.findIndex(schedule => schedule.schedule_version_id === schoolInfo.current_schedule_version_id);
			if(schedule_index !== -1) 
			{
				setCurrentScheduleVersionID(scheduleInfo.schedules[schedule_index].schedule_version_id);
				setCurrentSchedule({...scheduleInfo.schedules[schedule_index]});
			}
			
			if(!currentViewType)
			{
				if(scheduleInfo.schedule_type === 'daily')
				{
					setCurrentViewType('day')
				}
				else
				{
					setCurrentViewType('period');
				}
			}
			
			setScheduleType(scheduleInfo.schedule_type);
			
			const day_options = [];
			
			for(var i = 1; i <= parseInt(scheduleInfo.num_days_in_cycle); i++)
			{
				day_options.push({value:`${i}`, display:`Day ${i}`});
			}
			
			setDayOptions([...day_options]);
		}
	}, [scheduleInfoLoading, schoolInfoLoading, currentViewType, scheduleInfo, schoolInfo]);

	// Set a period type (course periods vs schedule periods)
	useEffect(()=>{
		if(!scheduleType || !currentViewType) return;
		const period_type = (scheduleType === 'daily' || currentViewType === 'day') ? 'schedule_periods' : 'course_periods';
		setPeriodType(period_type);

		const new_current_day = (currentViewType === 'day') ? '1' : null;
		
		setCurrentDay(new_current_day);
		
		sessionStorage.setItem('current_view_type', currentViewType);
		sessionStorage.setItem('current_day', new_current_day);
	}, [scheduleType, currentViewType]);

	// Create periods to show
	useEffect(() => {
		if(isEmpty(scheduleStructureData) || !periodType) return;
		const periods_to_show = (periodType === 'course_periods') ? scheduleStructureData.course_periods : scheduleStructureData.schedule_structure[currentDay];
		const array_to_sort = (periodType === 'course_periods') ? Object.keys(periods_to_show).map(period => {
			const period_info = periods_to_show[period];
			return {...period_info, period_order:parseInt(period_info.period_name)};
		}) : periods_to_show.map(period_info => {
			const schedule_period_id = period_info.schedule_period_id;
			const schedule_period_info = scheduleStructureData.schedule_periods[schedule_period_id];
			return {...period_info, ...schedule_period_info};
		});

		const sorted_periods = sortArrayOfObjects(array_to_sort, 'period_order', 'number', 'asc');

		setPeriodsToShow(sorted_periods);
	}, [periodType, scheduleType, scheduleStructureData, currentDay]);

	// Get conflicts
	useEffect(() => {
		if(!schoolInfoLoading && !scheduleInfoLoading) 
		{
			setConflictsLoading(true);
			setMatchPercentLoading(true);

			const conflicts_to_check = ['course_exceeds_capacity', 'course_period_restriction', 'course_outside_normal_room', 'teacher_missing', 'teacher_period_restriction', 'teacher_student_restriction', 'student_student_restriction', 'teacher_double_booked', 'classroom_double_booked', 'student_double_booked', 'teacher_conflicting_periods', 'classroom_conflicting_periods', 'student_conflicting_periods', 'teacher_too_many_sections', 'teacher_too_many_sections_in_a_row', 'classroom_missing', 'inclusion_too_many_students', 'inclusion_over_half'];
			const update_all = true;
			
			checkConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, conflicts_to_check:JSON.stringify(conflicts_to_check), update_all:update_all}).then(data => {
				const new_conflicts = data.conflicts;
				const new_match_percent = parseFloat(data.match_percent).toFixed(2);

				setConflicts([...new_conflicts]);
				setMatchPercent(new_match_percent);

				setConflictsLoading(false);
				setMatchPercentLoading(false);
			});
		}
	}, [schoolInfoLoading, scheduleInfoLoading, schoolInfo.school_id, schoolInfo.current_schedule_version_id]);

	// Create magnetboard display (will also create filter options)
	useEffect(() => {
		const createDisplaySchedule = () => {
			setIsLoading(true);
			
			// Organize schedules for display
			let display_schedule = [];
			let filter_options = {};
			
			if(currentView === 'teacher')
			{
				const sorted_departments = sortObject(departments, 'department', 'text');
				const sorted_teachers = sortArrayOfObjects(teachers, 'name', 'text', 'asc');
				const sorted_classrooms = sortArrayOfObjects(classrooms, 'classroom_name', 'text', 'asc');

				filter_options = {'departments': [], 'teachers':[], 'classrooms':[], 'classroom_status':[{display:"No Classroom", value:"no_classroom"}, {display:"Has Classroom", value:"has_classroom"}], 'singletons':[{display:"Singletons", value:"singletons"}, {display:"Doubletons", value:"doubletons"}], 'labs': [{display:"Labs Only", value:"labs_only"}, {display:"Non-Labs Only", value:"non_labs_only"}]};
				
				sorted_departments.forEach(department => {
					const department_id = department.department_id;
					filter_options['departments'].push({display:department.department, value:department_id});
				});

				for(const teacher of sorted_teachers)
				{
					const teacher_id = teacher.teacher_id;
					const teacher_last_name = teacher.name;
					const teacher_first_name= teacher.first_name;
					const teacher_display_name = `${teacher_last_name}, ${teacher_first_name}`;

					filter_options['teachers'].push({display:teacher_display_name, value:teacher_id});

					display_schedule.push({data_id:teacher_id, display_name:teacher_display_name, display_info:teacher});
				}

				for(const classroom of sorted_classrooms)
				{
					const classroom_id = classroom.classroom_id;
					const classroom_name = classroom.classroom_name;

					filter_options['classrooms'].push({display:classroom_name, value:classroom_id});
				}
			}
			else if(currentView === 'classroom')
			{
				const sorted_departments = sortObject(departments, 'department', 'text');
				const sorted_classrooms = sortArrayOfObjects(classrooms, 'classroom_name', 'text', 'asc');

				filter_options = {'departments': [], 'classrooms':[], 'singletons':[{display:"Singletons", value:"singletons"}, {display:"Doubletons", value:"doubletons"}], 'labs': [{display:"Labs Only", value:"labs_only"}, {display:"Non-Labs Only", value:"non_labs_only"}]};
				
				sorted_departments.forEach(department => {
					const department_id = department.department_id;
					filter_options['departments'].push({display:department.department, value:department_id, selected:false});
				});

				for(const classroom of sorted_classrooms)
				{
					const classroom_id = classroom.classroom_id;
					const classroom_name = classroom.classroom_name;

					filter_options['classrooms'].push({display:classroom_name, value:classroom_id});
					display_schedule.push({data_id:classroom_id, display_name:classroom_name, display_info:classroom});
				}
			}
			else if(currentView === 'student')
			{
				const sorted_courses = sortArrayOfObjects(courses, 'name', 'text', 'asc');

				filter_options = {'grades':[], 'special ed':[{value:'1', display:'Special Ed', selected:false}, {value:'0', display:'Not Special Ed', selected:false}], 'status': [{value:'1', display:'Complete (100%)', selected:false}, {value:'.75', display:'Mostly Complete (>75%)', selected:false}, {value:'.5', display:'Needs Work <75%)', selected:false}], 'courses':[]}

				const grades = students.reduce((result, student) => {
					const student_grade = student.grade;
					if(result.findIndex(option => option.value === student_grade) === -1) result.push({display:student_grade, value:student_grade, selected:false});
					return result;
				}, []);
				const sorted_grades = sortArrayOfObjects(grades, 'value', 'number', 'asc');

				filter_options['grades'].push(...sorted_grades);

				for(const student of students)
				{
					const student_id = student.student_id;
					display_schedule.push({data_id:student_id, display_name:null, display_info:student});
				}

				for(const course of sorted_courses)
				{
					const course_id = course.course_id;
					const course_name = course.name;
					const course_code = course.course_code;
					const course_display_name = `${course_name} (${course_code})`;

					filter_options['courses'].push({display:course_display_name, value:course_id});
				}
			}

			setDisplaySchedule([...display_schedule]);
			setIsLoading(false);
			setDisplayCreated(true);
			setFilterOptions({...filter_options});
		}

		if(currentView === 'student')
		{
			if(!scheduleInfoLoading && !scheduleStructureDataLoading && !sectionsLoading && !teachersLoading && !coursesLoading && !classroomsLoading && !studentsLoading && isLoading) createDisplaySchedule();
		}
		else
		{
			if(!scheduleInfoLoading && !scheduleStructureDataLoading && !sectionsLoading && !teachersLoading && !coursesLoading && !classroomsLoading && !departmentsLoading && isLoading) createDisplaySchedule();
		}
	}, [scheduleInfoLoading, scheduleStructureData, sectionsLoading, teachersLoading, classroomsLoading, studentsLoading, departmentsLoading, currentView, currentViewType, isLoading, students]);

	// Do search
	const handleSearch = useCallback(() => {
		if(!searchValue) 
		{
			setFilterIDs([]);
			return;
		}

		if(currentView === 'teacher')
		{
			const filtered_teacher_ids = [];

			// Search teachers
			const teachers_fuse = new Fuse(teachers, {
				keys: ['name', 'first_name'],
				threshold: .1
			});

			const teacher_results = teachers_fuse.search(searchValue);
			const subset_teacher_results = teacher_results.map(result => result.item).slice(0,6);

			subset_teacher_results.forEach(teacher => {
				filtered_teacher_ids.push(teacher.teacher_id);
			});
			
			// Search courses by name or code
			const courses_fuse = new Fuse(courses, {
				keys: ['name', 'course_code'],
				threshold: .1
			});

			const course_results = courses_fuse.search(searchValue);
			const subset_course_results = course_results.map(result => result.item).slice(0,4);

			subset_course_results.forEach(course => {
				const course_id = course.course_id;

				const sections_with_course = sections.filter(section => section.course_id === course_id);

				sections_with_course.forEach(section => {
					if(section.teacher_id) filtered_teacher_ids.push(section.teacher_id); 
				});
			});

			setFilterIDs([...filtered_teacher_ids]);
		}
		else if(currentView === 'classroom')
		{
			const filtered_classroom_ids = [];

			// Search classrooms
			const classrooms_fuse = new Fuse(classrooms, {
				keys: ['classroom_name', 'classroom_type'],
				threshold: .1
			});

			const classroom_results = classrooms_fuse.search(searchValue);
			const subset_classroom_results = classroom_results.map(result => result.item).slice(0,6);

			subset_classroom_results.forEach(classroom => {
				filtered_classroom_ids.push(classroom.classroom_id);
			});
			
			// Search courses by name or code
			const courses_fuse = new Fuse(courses, {
				keys: ['name', 'course_code'],
				threshold: .1
			});

			const course_results = courses_fuse.search(searchValue);
			const subset_course_results = course_results.map(result => result.item).slice(0,4);

			subset_course_results.forEach(course => {
				const course_id = course.course_id;

				const sections_with_course = sections.filter(section => section.course_id === course_id);

				sections_with_course.forEach(section => {
					if(section.classroom_id) filtered_classroom_ids.push(section.classroom_id); 
				});
			});

			setFilterIDs([...filtered_classroom_ids]);
		}
		else if(currentView === 'student')
		{
			const filtered_student_ids = [];

			// Search classrooms
			const students_fuse = new Fuse(students, {
				keys: ['first_name', 'last_name'],
				threshold: .1
			});

			const student_results = students_fuse.search(searchValue);
			const subset_student_results = student_results.map(result => result.item).slice(0,30);

			subset_student_results.forEach(student => {
				filtered_student_ids.push(student.student_id);
			});

			setFilterIDs([...filtered_student_ids]);
		}
	},[searchValue, classrooms, courses, currentView, sections, students, teachers]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			handleSearch();
		}, 500);
    	return () => clearTimeout(timeOutId);
	}, [searchValue, handleSearch]);

	// Update filtered data
	useEffect(() => {
		let selected_filters = [];
		Object.keys(filterOptions).forEach(filter_title => {
			const filter_options = filterOptions[filter_title];

			filter_options.forEach(filter_option => {
				if(filter_option.selected) selected_filters.push(filter_option);
			});
		});
		setSelectedFilters(selected_filters)

		let filtered_data = displaySchedule.reduce((result, display_data) => {
			let include = 0;
			let include_for_department = 0;
			let include_specific_data = 0;
			let include_for_classrooms = 0;
			let include_for_grade = 0;
			let include_for_status = 0;
			let include_for_special_ed = 0;
			let include_for_courses = 0;

			Object.keys(filterOptions).forEach(filter_title => {
				const filter_options = filterOptions[filter_title];

				// If none of the options are selected, then automatically include all of them
				if(filter_options.filter(option => option.selected).length === 0)
				{
					if(filter_title === 'departments') 
					{
						include_for_department = 1;
					}
					else if(filter_title === 'teachers')
					{
						include_specific_data = 1;
					}
					else if(currentView === 'teacher' && filter_title === 'classrooms')
					{
						include_for_classrooms = 1;
					}
					else if(currentView === 'classroom' && filter_title === 'classrooms')
					{
						include_specific_data = 1;
					}
					else if(filter_title === 'grades')
					{
						include_for_grade = 1;
					}
					else if(filter_title === 'status')
					{
						include_for_status = 1;
					}
					else if(filter_title === 'special ed')
					{
						include_for_special_ed = 1;
					}
					else if(filter_title === 'courses')
					{
						include_for_courses = 1;
					}
				}
				
				// Loop through all filter options and start filtering those that apply
				filter_options.forEach(filter_option => {
					const filter_option_selected = filter_option.selected;
					if(!filter_option_selected) return;
	
					const filter_option_value = filter_option.value;
	
					if(currentView === 'teacher')
					{
						if(filter_title === 'departments' && display_data.display_info.departments.includes(filter_option_value)) include_for_department = 1;
						if(filter_title === 'teachers' && display_data.display_info.teacher_id === filter_option_value) include_specific_data = 1;

						if(filter_title === 'classrooms')
						{
							const teacher_id = display_data.display_info.teacher_id;
							const section_teacher_classroom_index = sections.findIndex(section => section.teacher_id === teacher_id && section.classroom_id === filter_option_value);
							if(section_teacher_classroom_index !== -1) include_for_classrooms = 1;
						}
					}
					else if(currentView === 'classroom')
					{
						if(display_data.display_info.departments.includes(filter_option_value)) include_for_department = 1;

						if(filter_title === 'classrooms' && display_data.display_info.classroom_id === filter_option_value) include_specific_data = 1;
					}
					else if(currentView === 'student')
					{
						if(filter_title === 'grades' && display_data.display_info.grade === filter_option_value) include_for_grade = 1;
						
						if(filter_title === 'status')
						{
							const student_id = display_data.data_id;
							const student_match_percent = (student_id in studentMatchPercentages) ? studentMatchPercentages[student_id].match_percent : 0;
							const student_percent_schedule_full =  (student_id in studentMatchPercentages) ? studentMatchPercentages[student_id].match_percent : 0;

							const complete = (student_match_percent == 1 || student_percent_schedule_full == 1);
							const mostly_complete = ((student_match_percent >= .75 && student_match_percent < 1) || (student_percent_schedule_full >= .75 && student_percent_schedule_full < 1));
							const needs_work = (student_match_percent < .75 || student_percent_schedule_full < .75);

							if(filter_option_value === '1' && complete)
							{
								include_for_status = 1;
							}
							else if(filter_option_value === '.75' && !complete && mostly_complete)
							{
								include_for_status = 1;
							}
							else if(filter_option_value === '.5' && !complete && !mostly_complete && needs_work)
							{
								include_for_status = 1;
							}
						}

						if(filter_title === 'special ed' && display_data.display_info.is_special_ed === filter_option_value) include_for_special_ed = 1;

						if(filter_title === 'courses')
						{
							const student_id = display_data.data_id;
							const section_student_course_index = sections.findIndex(section => section.course_id === filter_option_value && section.student_list.includes(student_id));
							if(section_student_course_index !== -1) include_for_courses = 1;
						}
					}
				});
			});

			if(currentView === 'teacher' && include_for_department === 1 && include_specific_data === 1 && include_for_classrooms === 1) include = 1;
			if(currentView === 'classroom' && include_for_department === 1 && include_specific_data === 1) include = 1;
			if(currentView === 'student' && include_for_grade === 1 && include_for_status === 1 && include_for_special_ed === 1 && include_for_courses === 1) include = 1;

			if(include === 1) result.push(display_data.display_info[`${currentView}_id`]);

			return result;
		}, []);
		
		setFilteredData([...filtered_data]);
	}, [filterOptions, currentView, displaySchedule]);

	// Focus on schedule name input when it's open
	useEffect(() => {
		if(editingScheduleName) document.getElementById("mb-schedule-name-input").focus();
	}, [editingScheduleName]);

	// Handle on key down
	useEffect(() => {
		// Handle edit undo/redo 
		const handleKeyDown = (event) => {
			if((event.ctrlKey || event.metaKey) && event.key == 'y') 
			{
				event.preventDefault();
				redoAction();
			}
			else if((event.ctrlKey || event.metaKey) && event.key == 'z')
			{
				event.preventDefault();
				undoAction();
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		  };
	}, [sections, subsections, scheduleInfo, scheduleStructureData, redoAction, undoAction]);
	
	//////////////////////
	///// DO ON LOAD /////
	//////////////////////
	// Set background info, as well as create undo/redo
	useEffect(() => {
		const current_day = getJSONFromStorage('current_day', false, 'session');
		const current_view = getJSONFromStorage('current_view', false, 'session');
		const current_view_type = getJSONFromStorage('current_view_type', false, 'session');

		if(current_day) setCurrentDay(current_day);
		if(current_view) setCurrentView(current_view);
		if(current_view_type) setCurrentViewType(current_view_type);

		if(!JSONexists('actionLog', 'session')) sessionStorage.setItem('actionLog', JSON.stringify([]));
		if(!JSONexists('undoneActions', 'session')) sessionStorage.setItem('undoneActions', JSON.stringify([]));
	}, []);
	
	/////////////////////////////////
	///// INITIALIZE VARIABLES //////
	/////////////////////////////////
	const loading_active_schedule = (scheduleInfo.app_current_schedule_year_id === schoolInfo.current_schedule_year_id);

	const num_cols = periodsToShow.length;
	const row_style = {gridTemplateColumns : `170px repeat(${num_cols}, 1fr)`};

	const allowed_teacher_ids = magnetboardOptions.allowed_teacher_ids;
	const allowed_classroom_ids = magnetboardOptions.allowed_classroom_ids;
	let student_counter = 1;

	const num_conflicts = conflicts.filter(conflict => conflict.hard_conflict === '1' && conflict.is_deleted === '0').length;
	const num_warnings = conflicts.filter(conflict => conflict.hard_conflict === '0' && conflict.is_deleted === '0').length;

	const schedules_for_this_year = ('schedules' in scheduleInfo) ? scheduleInfo.schedules.filter(schedule => schedule.schedule_year_id === scheduleInfo.current_schedule_year_id) : [];

	//console.log({currentSchedule});
	//console.log({currentScheduleVersionID});
	//console.log({sections, subsections});
	//console.log({displayCreated});
	//console.log({displaySchedule});
	//console.log({students});
	//console.log(filterIDs);
	//console.log({openFilterOptions});

	return (
		<>
		{showEditSectionModal &&
			<EditSection schoolInfo={schoolInfo} currentView={currentView} currentDay={currentDay} currentViewType={currentViewType} scheduleType={scheduleType} sectionType={currentSectionType} sectionID={currentSectionID} dataID={currentDataID} coursePeriodID={currentCoursePeriodID} toggleEditSection={toggleEditSection} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} lunchInfo={lunchInfo} classroomsLoading={classroomsLoading} classrooms={classrooms} coursesLoading={coursesLoading} courses={courses} teachersLoading={teachersLoading} teachers={teachers} studentsLoading={studentsLoading} students={students} sectionsLoading={sectionsLoading} sections={sections} subsections={subsections} labels={labels} setCourses={setCourses} setStudents={setStudents} setTeachers={setTeachers} setSections={setSections} setSubsections={setSubsections} setLabels={setLabels} conflicts={conflicts} setConflicts={setConflicts} setConflictsLoading={setConflictsLoading} setMatchPercent={setMatchPercent} setMatchPercentLoading={setMatchPercentLoading} toggleEditStudentSchedule={toggleEditStudentSchedule} allow_hotlinks={magnetboardOptions.allow_hotlinks}/>
		}
		{showEditStudentScheduleModal &&
			<EditStudentSchedule schoolInfo={schoolInfo} scheduleType={scheduleType} currentView={currentView} currentViewType={currentViewType} currentDay={currentDay} studentID={currentStudentID} setStudentID={setCurrentStudentID} toggleEditStudentSchedule={toggleEditStudentSchedule} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} userInfo={userInfo} lunchInfo={lunchInfo} classrooms={classrooms} courses={courses} setCourses={setCourses} teachers={teachers} setTeachers={setTeachers} students={students} setStudents={setStudents} sections={sections} setSections={setSections} subsections={subsections} labels={labels} viewTypeOptions={viewTypeOptions} dayOptions={dayOptions} conflicts={conflicts} setConflicts={setConflicts} setMatchPercent={setMatchPercent}/>
		}
		{showExportScheduleModal &&
			<ExportSchedule schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} setScheduleStructureData={setScheduleStructureData} lunchInfo={lunchInfo} classrooms={classrooms} courses={courses} setCourses={setCourses} teachers={teachers} setTeachers={setTeachers} students={students} setStudents={setStudents} sections={sections} setSections={setSections} subsections={subsections} labels={labels} conflicts={conflicts} openConflicts={() => addOpenReport('conflicts')} closeModal={() => setShowExportScheduleModal(false)}/>
		}
		{openReports.includes('conflicts') &&
			<>
				<Portal windowName="Conflicts/Warnings" windowWidth="720" windowHeight="700" cleanUpFunction={() => removeOpenReport('conflicts')}>
					<Conflicts schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} scheduleName={currentSchedule.schedule_name} scheduleType={scheduleType} currentView={currentView} currentViewType={currentViewType} currentDay={currentDay} conflicts={conflicts} setConflicts={setConflicts} setMatchPercent={setMatchPercent} setMatchPercentLoading={setMatchPercentLoading} sections={sections} subsections={subsections} teachers={teachers} students={students} courses={courses} classrooms={classrooms} scheduleStructureData={scheduleStructureData} toggleEditStudentSchedule={toggleEditStudentSchedule} changeCurrentView={changeCurrentView} changeCurrentViewType={changeCurrentViewType} changeCurrentDay={changeCurrentDay} setSearchValue={setSearchValue}/>
				</Portal>
			</>
		}
		{openReports.includes('schedule-summary') &&
			<>
				<Portal windowName="Schedule Summary" windowWidth="1200" windowHeight="700" cleanUpFunction={() => removeOpenReport('schedule-summary')}>
					<ScheduleSummary scheduleName={currentSchedule.schedule_name} scheduleType={scheduleType} currentView={currentView} currentViewType={currentViewType} currentDay={currentDay} sections={sections} subsections={subsections} teachers={teachers} students={students} courses={courses} classrooms={classrooms} labels={labels} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} toggleEditSection={toggleEditSection} toggleEditStudentSchedule={toggleEditStudentSchedule}/>
				</Portal>
			</>
		}
		{openReports.includes('conflict-matrix') &&
			<>
				<Portal windowName="Conflict Matrix" windowWidth="500" windowHeight="700" cleanUpFunction={() => removeOpenReport('conflict-matrix')}>
					<ConflictMatrix scheduleName={currentSchedule.schedule_name} students={students} courses={courses}/>
				</Portal>
			</>
		}
		{openReports.includes('section-balancing') &&
			<>
				<Portal windowName="Section Balancing" windowWidth="1000" windowHeight="700" cleanUpFunction={() => removeOpenReport('section-balancing')}>
					<SectionBalancing schoolSubscribed={schoolSubscribed} schoolInfo={schoolInfo} scheduleName={currentSchedule.schedule_name} scheduleType={scheduleType} currentView={currentView} currentViewType={currentViewType} currentDay={currentDay} sections={sections} subsections={subsections} teachers={teachers} courses={courses} classrooms={classrooms} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} toggleEditSection={toggleEditSection} pullNewSectionData={pullNewSectionData}/>
				</Portal>
			</>
		}
		{openReports.includes('student-summary') &&
			<>
				<Portal windowName="Student Summary" windowWidth="1000" windowHeight="700" cleanUpFunction={() => removeOpenReport('student-summary')}>
					<StudentSummary scheduleName={currentSchedule.schedule_name} scheduleType={scheduleType} currentView={currentView} currentViewType={currentViewType} currentDay={currentDay} sections={sections} subsections={subsections} labels={labels} teachers={teachers} students={students} courses={courses} classrooms={classrooms} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} studentMatchPercentages={studentMatchPercentages} toggleEditStudentSchedule={toggleEditStudentSchedule} />
				</Portal>
			</>
		}
		<DndProvider backend={HTML5Backend}>
			<div className='mb-container'>
				<div className='mb-top-bar'>
					<div className='mb-top-bar-inner'>
						{magnetboardOptions.show_top_bar &&
							<div className='mb-top-bar-row'>
								<div>
									<img className='mb-match-img' src={require("../../images/logo-dark.png")} alt='Edario Logo' />
								</div>
								<div className='mb-schedule-name-container'>
									<div className='mb-top-bar-label' style={{margin:'0px 0px 0px 12px'}}>{!magnetboardOptions.current_department ? 'Schedule Name' : 'Department'}</div>
									{!editingScheduleName &&
										<>
										{!magnetboardOptions.current_department ? 
											(
												<h1 className='mb-schedule-name' onClick={magnetboardOptions.allow_schedule_name_editing ? () => setEditingScheduleName(!editingScheduleName) : null}>{currentSchedule.schedule_name}</h1>
											):
											(
												<h1 className='mb-schedule-name mb-schedule-name-no-hover' style={{border:'none', textAlign:'left'}}>{magnetboardOptions.current_department} Schedule</h1>
											)
										}
										</>
									}
									{editingScheduleName &&
										<div className='mb-editing-schedule-name-container'>
											<input id='mb-schedule-name-input' defaultValue={currentSchedule.schedule_name}/>
											<FontAwesomeIcon className='mb-editing-schedule-name-confirm checkmark-submit' icon={faCheck} onClick={(e) => submitScheduleName(e)}/>
											<FontAwesomeIcon className='mb-editing-schedule-name-cancel x-cancel' icon={faTimes} onClick={() => setEditingScheduleName(!editingScheduleName)}/>
										</div>
									}
									<div ref={other_schedules_ref}>
										{(schedules_for_this_year.length > 1) &&
											<div className='mb-other-schedules-icon click-restricted' onClick={toggleOtherScheduleOptions}>
												<FontAwesomeIcon icon={faChevronDown}/>
											</div>
										}
										{otherSchedulesVisible &&
											<div className='mb-other-schedules-container dropdown-options-container' >
												<h5>Saved Schedules</h5>
												{schedules_for_this_year.map((schedule_info, i) => {
													const schedule_selected = (schedule_info.current_bool === '1') ? true : false;

													const t = schedule_info.time_stamp.split(/[- :]/);
													const d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
													const datestring = ( "0" + (d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "-" + d.getFullYear();

													return (
														<div className={`mb-other-schedules-option ${schedule_selected ? 'mb-other-schedules-option-selected' : ''}`} key={i} onClick={schedule_selected ? ()=>{} : () => pullNewSectionData(schedule_info.schedule_version_id)}>
															<img className='mb-other-schedules-logo' src={schedule_selected ? require("../../images/logo-white.png") : require("../../images/logo-dark.png")} alt='Edario Logo' />
															<div>
																<div className={`extra-small-text ${schedule_selected ? 'gray-text' : 'dark-gray-text'}`}>Schedule Name</div> 
																<div>{schedule_info.schedule_name}</div>
															</div>
															<div>
																<div className={`extra-small-text ${schedule_selected ? 'gray-text' : 'dark-gray-text'}`}>Match</div> 
																<div>{schedule_info.match_percent}%</div>
															</div>
															<div>
																<div className={`extra-small-text ${schedule_selected ? 'gray-text' : 'dark-gray-text'}`}>Last Saved</div> 
																<div>{datestring}</div>
															</div>
															<div><FontAwesomeIcon className={`mb-other-schedule-check-mark ${schedule_selected ? 'green-text' : 'white-text'}`} style={{marginTop:'10px',fontSize:'20px'}} icon={faCheckCircle}/></div>
														</div>
													)
												})}
											</div>
										}
									</div>
								</div>
								<div className='mb-schedule-stats-container'>
									<div className='mb-match-percent-container'>
										{magnetboardOptions.show_match_percent &&
											<>
											<div className='mb-top-bar-label'>Match</div>
											<h1 className='mb-match-percent'>{matchPercentLoading ? <div style={{width:'128px',height:'60px'}}><img src={require('../../images/balls.gif')} alt='loading...' style={{height:'50px',textAlign:'left'}}/></div> : `${matchPercent}%`}</h1>
											</>
	}
									</div>
									<div className='mb-conflict-container'>
										{magnetboardOptions.show_conflicts &&
											<>
											<div className='mb-conflict-inner-row'>Conflicts <span className='mb-conflict-counter'>{conflictsLoading ? <img src={require('../../images/balls.gif')} alt='loading...' style={{height:'20px'}}/> :  num_conflicts}</span></div>
											<div className='mb-conflict-inner-row'>Warnings <span className='mb-conflict-counter'>{conflictsLoading ? <img src={require('../../images/balls.gif')} alt='loading...' style={{height:'20px'}}/> :  num_warnings}</span></div>
											{!conflictsLoading &&
												<div className='blue-link small-text' style={{marginTop:'5px', textAlign:'right'}} onClick={() => addOpenReport('conflicts')}>See all</div>
											}
											</>
										}
									</div>
								</div>
							</div>
						}
						{magnetboardOptions.show_menu &&
							<div className='mb-menu-bar' ref={menu_ref}>
								<div className='mb-menu-btn' onClick={() => toggleMenuItem('file')}>
									<span>File</span>
									{currentOpenMenu === 'file' &&
										<div className='mb-menu-dropdown-options-container'>
											{/* {loading_active_schedule &&
												<div className='mb-dropdown-option' onClick={() => saveSchedule('new')}>Save As..</div>
											} */}
											<div className='mb-dropdown-option' onClick={openPrint}>Print Schedule</div>
											{loading_active_schedule &&
												<div className={`mb-dropdown-option ${!schoolSubscribed && 'gray-text'}`} onClick={schoolSubscribed ? ()=>setShowExportScheduleModal(true) : () => alert("Sorry, this feature is only available with a paid subscription. Please contact us at contact@edario.com to get full access!")}>Export Schedule</div>
											}
										</div>
									}
								</div>
								{loading_active_schedule &&
									<>
									<div className='mb-menu-btn' onClick={() => toggleMenuItem('edit')}>
										<span>Edit</span>
										{currentOpenMenu === 'edit' &&
											<div className='mb-menu-dropdown-options-container'>
												<div className='mb-dropdown-option' onClick={undoAction}>
													<div>Undo</div>
													<div className='small-text dark-gray-text text-align-right'>Ctrl + Z</div>
												</div>
												<div className='mb-dropdown-option' onClick={redoAction}>
													<div>Redo</div>
													<div className='small-text dark-gray-text text-align-right'>Ctrl + Y</div>
												</div>
											</div>
										}
									</div>
									<div className='mb-menu-btn' onClick={() => toggleMenuItem('actions')}>
										<span>Actions</span>
										{currentOpenMenu === 'actions' &&
											<div className='mb-menu-dropdown-options-container'>
												<div className='mb-dropdown-option mb-dropdown-option-hover' data-actiontype='students' onMouseEnter={() => handleDropdownHoverShow('students')} onMouseLeave={() => handleDropdownHoverHide('students')}>
													<div>Students</div>
													<FontAwesomeIcon icon={faChevronRight} />
												</div>
												<div className='mb-dropdown-option mb-dropdown-option-hover' data-actiontype='classrooms'  onMouseEnter={() => handleDropdownHoverShow('classrooms')} onMouseLeave={() => handleDropdownHoverHide('classrooms')}>
													<div>Classrooms</div>
													<FontAwesomeIcon icon={faChevronRight}/>
												</div>
												<div className='mb-dropdown-option mb-dropdown-option-hover' data-actiontype='sections'  onMouseEnter={() => handleDropdownHoverShow('sections')} onMouseLeave={() => handleDropdownHoverHide('sections')}>
													<div>Sections</div>
													<FontAwesomeIcon icon={faChevronRight}/>
												</div>
												<div className={`mb-dropdown-option ${!schoolSubscribed && 'gray-text'}`} onClick={schoolSubscribed ? createHealthSections : () => alert("Sorry, this feature is only available with a paid subscription. Please contact us at contact@edario.com to get full access!")}>Create Health Sections</div>
											</div>
										}
										<div className='mb-dropdown-options-subcontainer hide' data-actiontype='students' onMouseEnter={() => handleDropdownHoverShow('students')} onMouseLeave={() => handleDropdownHoverHide('students')}>
											<div className='mb-dropdown-option' onClick={clearStudents}>Clear Students</div>
											<div className={`mb-dropdown-option ${!schoolSubscribed && 'gray-text'}`} onClick={schoolSubscribed ? placeStudents : () => alert("Sorry, this feature is only available with a paid subscription. Please contact us at contact@edario.com to get full access!")}>Place Students</div>
										</div>
										<div className='mb-dropdown-options-subcontainer hide' data-actiontype='classrooms' onMouseEnter={() => handleDropdownHoverShow('classrooms')} onMouseLeave={() => handleDropdownHoverHide('classrooms')}>
											<div className='mb-dropdown-option' onClick={clearClassrooms}>Clear Classrooms</div>
										</div>
										<div className='mb-dropdown-options-subcontainer hide' data-actiontype='sections' onMouseEnter={() => handleDropdownHoverShow('sections')} onMouseLeave={() => handleDropdownHoverHide('sections')}>
											<div className='mb-dropdown-option' onClick={clearSections}>Clear Sections</div>
											<div className={`mb-dropdown-option ${!schoolSubscribed && 'gray-text'}`} onClick={schoolSubscribed ? placeSections : () => alert("Sorry, this feature is only available with a paid subscription. Please contact us at contact@edario.com to get full access!")}>Place Sections</div>
										</div>
									</div>
									</>
								}
								<div className='mb-menu-btn' onClick={() => toggleMenuItem('reports')}>
									<span>Reports</span>
									{currentOpenMenu === 'reports' &&
										<div className='mb-menu-dropdown-options-container'>
											<div className='mb-dropdown-option' onClick={() => addOpenReport('schedule-summary')}>Schedule Summary</div>
											<div className='mb-dropdown-option' onClick={() => addOpenReport('conflict-matrix')}>Conflict Matrix</div>
											<div className='mb-dropdown-option' onClick={() => addOpenReport('section-balancing')}>Section Balancing</div>
											<div className='mb-dropdown-option' onClick={() => addOpenReport('student-summary')}>Student Summary</div>
										</div>
									}
								</div>
								<div className='mb-menu-btn'>
									<span>Help</span>
								</div>
							</div>
						}
						<div className='mb-options-bar'>
							<div className='mb-search-bar-outer-container'>
								<div className='mb-search-bar-container'>
									<input className='mb-search-bar' placeholder={`Search for a course or ${currentView}...`} value={searchValue} onChange={(e) => setSearchValue(e.target.value)}/>
									<FontAwesomeIcon className='mb-search-bar-icon' icon={faSearch}/>
									<div className='mb-search-bar-filter-container' ref={filter_ref}>
										<div style={{display:'grid',width:'100%', height:'100%', alignItems:'center', justifyItems:'center'}} onClick={magnetboardOptions.allow_filtering ? toggleFilterOptions : ()=>{}}>
											<FontAwesomeIcon icon={faSlidersH}/>
										</div>
										{filterDataVisible &&
											<div className='list-filter-options-container click-restricted' style={{top:'60px'}}>
												<div className='small-text'>Filter by:</div>
												{Object.keys(filterOptions).map((filter_title, j) => {
													const formatted_filter_title = filter_title.split("_").join(" ");
													const filter_options = filterOptions[filter_title];
													const filter_options_open = openFilterOptions.includes(filter_title);

													return (
														<div key={j}>
															<div className='list-filter-title-container' onClick={() => updateOpenFilters(filter_title, filter_options_open)}>
																<div className='capitalize'>{formatted_filter_title}</div>
																<FontAwesomeIcon className='small-text' icon={filter_options_open ? faChevronUp : faChevronDown}/>
															</div>
															{filter_options_open && filter_options.map((filter_option, i) => {
																const filter_display = filter_option.display;
																const filter_selected = filter_option.selected;

																return (
																	<div className='parent-div list-filter-option click-restricted' key={i}>
																		<div className='list-filter-option-name click-restricted'>{filter_display}</div>
																		{filter_selected &&
																			<FontAwesomeIcon className='fas-checkbox-checked click-restricted' icon={faCheckSquare} onClick={() => updateFilterOptions(filter_title, i, false)}/>
																		}
																		{!filter_selected &&
																			<FontAwesomeIcon className='fas-checkbox-unchecked click-restricted' icon={faSquare} onClick={() => updateFilterOptions(filter_title, i, true)}/>
																		}
																	</div>
																)
															})}
														</div>
													)
												})}
											</div>
										}
									</div>
								</div>
							</div>
							<div className='mb-change-view-container'>
								{(scheduleType === 'block') &&
									<>
									<div className='mb-dropdown-wrapper'><Dropdown data={viewTypeOptions} currentValue={currentViewType} handleChange={(new_view_type) => changeCurrentViewType(new_view_type)} /></div>
									{(currentViewType === 'day') &&
										<div className='mb-dropdown-wrapper'><Dropdown data={dayOptions} currentValue={currentDay} handleChange={(new_day) => changeCurrentDay(new_day)} /></div>
									}
									</>
								}
								<div className='mb-dropdown-wrapper'><Dropdown data={magnetboardOptions.view_options} currentValue={currentView} handleChange={(new_view) => changeCurrentView(new_view)} /></div>
							</div>
						</div>
						<div className ='mb-selected-filters-bar'>
							{selectedFilters.length > 0 &&
								<>
								<div className='mb-selected-filters-text'>Filters:</div>
								{Object.keys(filterOptions).map((filter_title, i) => {
									const filter_options = filterOptions[filter_title];

									return (
										<React.Fragment key={i}>
										{filter_options.map((filter_option, i) => {
											const filter_option_selected = filter_option.selected;
											
											if(!filter_option_selected) return null;

											return (
												<div className='mb-selected-filters-filter-tag' key={i}>
													<div className='mb-selected-filters-filter-tag-inner-container'>
														<div>{filter_option.display}</div>
														<FontAwesomeIcon className='dark-gray-link' icon={faTimes} onClick={() => updateFilterOptions(filter_title, i, false)} />
													</div>
												</div>
											)
										})}
										</React.Fragment>
									)
								})}
								</>
							}
						</div>
					</div>
				</div>
				<div className='mb-row mb-middle-header fixed-heading-on-scroll' style={row_style}>
					<div className='mb-main-inner-box capitalize'>{currentView}</div>
					{periodsToShow.map((period_info, index) => {
						const period_name = period_info.period_name;
						
						return (
							<div className='mb-inner-box' key={index}><div>{period_name}</div></div>
						)
					})}
				</div>
				<div className='mb-bottom-container' ref={printRef}>
					{(isLoading || isSaving) ?
						(
							<div className='mb-message-container'>
								<img src={require('../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
							</div>
						): (displayCreated && currentView === 'teacher') ?
						(
							<>
							{displaySchedule.map((teacher, data_index) => {
								const teacher_id = teacher.data_id;

								const teacher_not_allowed = (allowed_teacher_ids.length > 0 && !allowed_teacher_ids.includes(teacher_id));
								const teacher_filtered_out = ((filterIDs.length > 0 && !filterIDs.includes(teacher_id)) || !filteredData.includes(teacher_id));

								const teacher_info = teacher.display_info;
								
								const teacher_selected = (selectedTeacherID === teacher_id) ? true : false;
								const teacher_quick_view_selected = (selectedQuickViewDataID === teacher_id) ? true : false;
								const teacher_lunch_schedule_period_id = teacher_info.lunch_schedule_period_id;
								const teacher_sections = sections.filter(section => section.teacher_id === teacher_id || (('secondary_teachers' in section) && section.secondary_teachers.includes(teacher_id)));
								const teacher_unassigned_sections = teacher_sections.filter(section => ('course_periods' in section) && section.course_periods.length === 0);
								
								// Unassigned sections without duplicates
								let logged_span_ids = [];
								const teacher_unassigned_sections_no_duplicates = teacher_unassigned_sections.reduce((results, unassigned_section) => {
									// Skip any previously seen span sections
									const span_id = unassigned_section.span_id;
									const is_lab = unassigned_section.is_lab;
									if(span_id && logged_span_ids.includes(span_id)) return results;
									if(is_lab === '1') return results;

									logged_span_ids.push(span_id);

									// If teacher just so happens to teach both the main and subsection, only list the main section in unassigned list
									const subsection_index = subsections.findIndex(subsection => subsection.subsection_id === unassigned_section.section_id);
									const is_subsection = (subsection_index !== -1) ? true : false;
									
									if(is_subsection)
									{
										const subsection_section_id = subsections[subsection_index].section_id;
										const section_info = sections.find(section => section.section_id === subsection_section_id);
										const section_teacher_id = section_info.teacher_id;

										if(unassigned_section.teacher_id === section_teacher_id) return results;
									}

									results.push(unassigned_section);
									return results;
								},[]);
								const teacher_has_unassigned_sections = (teacher_unassigned_sections_no_duplicates.length > 0);

								const teacher_departments = teacher_info.departments;
								const department_names = [];
								
								teacher_departments.forEach(department_id => {
									const department_index = departments.findIndex(department => department.department_id === department_id)
									const department = (department_index !== -1) ? departments.find(department => department.department_id === department_id).department : null;
									if(department) department_names.push(department);
								});
								
								const department_list = department_names.join(', ');
								
								return (
									<div key={data_index} className={`${data_index % 5 == 0 && 'page-break'} ${teacher_selected ? 'mb-schedule-row-selected' : ''} ${(teacher_not_allowed || teacher_filtered_out) && 'hide'}`}>
										<div className={`mb-row mb-schedule-row cursor-pointer`} style={row_style}>
											<div className='mb-schedule-main-inner-box'>
												<div className={`mb-schedule-row-name-container ${teacher_has_unassigned_sections && ' left-border-red-thick'}`} onClick={() => handleSelectTeacher(teacher_id)}>
													<div className='mb-schedule-row-name'>{teacher.display_name}</div>
													<div className='mb-schedule-row-department dark-gray-text'>{department_list}</div>
													<div className={`extra-small-text ${teacher_has_unassigned_sections ? 'red-link' : 'green-link'} underline-dashed`} style={{margin:'3px auto 0px auto'}}> {teacher_unassigned_sections_no_duplicates.length} unplaced sections</div>
												</div>
											</div>
											{periodsToShow.map((period_info, index) => {
												const period_id = period_info.period_id;
												const period_name = period_info.period_name;
												
												const course_period_id = (periodType === 'course_periods') ? period_id : scheduleStructureData.schedule_structure[currentDay].find(structure_info => structure_info.schedule_period_id === period_id).course_period_id;
												const is_teacher_lunch_period = (currentViewType === 'day' && period_id === teacher_lunch_schedule_period_id) ? true : false;

												return (
													<MagnetboardDropPeriodHSMS key={index} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} scheduleType={scheduleType} currentView={currentView} currentViewType={currentViewType} currentDay={currentDay} currentScheduleVersionID={currentScheduleVersionID} magnetboardOptions={magnetboardOptions} teachers={teachers} setTeachers={setTeachers} courses={courses} classrooms={classrooms} students={students} setStudents={setStudents} sections={sections} setSections={setSections} subsections={subsections} labels={labels} conflicts={conflicts} setConflicts={setConflicts} setConflictsLoading={setConflictsLoading} setMatchPercent={setMatchPercent} setMatchPercentLoading={setMatchPercentLoading} labsOnlyFiltered={labs_only_filtered.current} nonLabsOnlyFiltered={non_labs_only_filtered.current} singletonsFiltered={singletons_filtered.current} doubletonsFiltered={doubletons_filtered.current} noClassroomFiltered={no_classroom_filtered.current} hasClassroomFiltered={has_classroom_filtered.current} toggleEditSection={toggleEditSection} updateSectionPlacement={updateSectionPlacement} updateManualPlacement={updateManualPlacement} isTeacherLunchPeriod={is_teacher_lunch_period} teacherID={teacher_id} classroomID={null} periodID={period_id} periodName={period_name} coursePeriodID={course_period_id} handleSelectQuickViewDataID={handleSelectQuickViewDataID} selectedQuickViewSectionID={selectedQuickViewSectionID} dataSections={teacher_sections}/>
												)
											})}
										</div>
										{

										}
										{teacher_selected &&
											<div className='mb-unassigned-sections-parent-container'>
												<div></div>
												<MagnetboardUnplacedDropZoneHSMS schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} scheduleType={scheduleType} currentView={currentView} currentViewType={currentViewType} currentDay={currentDay} currentScheduleVersionID={currentScheduleVersionID} magnetboardOptions={magnetboardOptions} teachers={teachers} setTeachers={setTeachers} courses={courses} classrooms={classrooms} students={students} setStudents={setStudents} sections={sections} setSections={setSections} subsections={subsections} labels={labels} conflicts={conflicts} setConflicts={setConflicts} setConflictsLoading={setConflictsLoading} setMatchPercent={setMatchPercent} setMatchPercentLoading={setMatchPercentLoading} toggleEditSection={toggleEditSection} updateManualPlacement={updateManualPlacement} teacherID={teacher_id} unassignedSections={teacher_unassigned_sections_no_duplicates} handleSelectTeacher={handleSelectTeacher}/>
											</div>
										}
										{teacher_quick_view_selected &&
											<div className='mb-unassigned-sections-parent-container'>
												<div></div>
												<MagnetboardQuickViewHSMS sectionID={selectedQuickViewSectionID} scheduleStructureData={scheduleStructureData} scheduleType={scheduleType} scheduleInfo={scheduleInfo} currentViewType={currentViewType} currentDay={currentDay} sections={sections} subsections={subsections} labels={labels} teachers={teachers} courses={courses} classrooms={classrooms} conflicts={conflicts} handleSelectQuickViewDataID={handleSelectQuickViewDataID}/>
											</div>
										}
									</div>
								)
							})}
							</>
						): (displayCreated && currentView === 'classroom') ? 
						(
							<>
							{displaySchedule.map((classroom, data_index) => {
								const classroom_id = classroom.data_id;

								const classroom_not_allowed = (allowed_classroom_ids.length > 0 && !allowed_classroom_ids.includes(classroom_id));
								const classroom_filtered_out = ((filterIDs.length > 0 && !filterIDs.includes(classroom_id)) || !filteredData.includes(classroom_id));

								const classroom_info = classroom.display_info;
								const classroom_name = classroom_info.classroom_name;
								
								const classroom_departments = classroom_info.departments;
								const department_names = [];
								
								classroom_departments.forEach(department_id => {
									const department = departments.find(department => department.department_id === department_id).department;
									department_names.push(department);
								});
								
								const department_list = department_names.join(', ');
								
								return (
									<div className={`mb-row mb-schedule-row ${(classroom_not_allowed || classroom_filtered_out) && 'hide'}`} style={row_style} key={data_index}>
										<div className='mb-schedule-main-inner-box'>
											<div className='mb-schedule-row-name-container left-border-orange-thick'>
												<div className='mb-schedule-row-name'>{classroom_name}</div>
												<div className='mb-schedule-row-department dark-gray-text'>{department_list}</div>
											</div>
											
										</div>
										{periodsToShow.map((period_info, index) => {
											const period_id = period_info.period_id;
											const period_name = period_info.period_name;
											const course_period_id = (periodType === 'course_periods') ? period_id : scheduleStructureData.schedule_structure[currentDay].find(structure_info => structure_info.schedule_period_id === period_id).course_period_id;

											const data_sections = sections.filter(section => section.classroom_id === classroom_id && section.course_periods.includes(course_period_id));
											const course_period_sections_sorted_by_quarter = (data_sections.length > 0) ? data_sections.sort((section_A, section_B) => {
												const section_A_min_quarter = section_A.quarter_days.reduce((result, quarter_day) => {
													if(quarter_day.quarter < result) result = quarter_day.quarter;
													return result;
												}, '999');
										
												const section_B_min_quarter = section_B.quarter_days.reduce((result, quarter_day) => {
													if(quarter_day.quarter < result) result = quarter_day.quarter;
													return result;
												}, '999');
										
												if(section_A_min_quarter < section_B_min_quarter) return -1;
												return 1;
											}) : [];

											return (
												<MagnetboardDropPeriodHSMS key={index} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} scheduleType={scheduleType} currentView={currentView} currentViewType={currentViewType} currentDay={currentDay} currentScheduleVersionID={currentScheduleVersionID} magnetboardOptions={magnetboardOptions} teachers={teachers} setTeachers={setTeachers} courses={courses} classrooms={classrooms} students={students} setStudents={setStudents} sections={sections} setSections={setSections} subsections={subsections} labels={labels} conflicts={conflicts} setConflicts={setConflicts} setConflictsLoading={setConflictsLoading} setMatchPercent={setMatchPercent} setMatchPercentLoading={setMatchPercentLoading} labsOnlyFiltered={labs_only_filtered} nonLabsOnlyFiltered={non_labs_only_filtered} singletonsFiltered={singletons_filtered} doubletonsFiltered={doubletons_filtered} noClassroomFiltered={no_classroom_filtered} hasClassroomFiltered={has_classroom_filtered} toggleEditSection={toggleEditSection} updateSectionPlacement={updateSectionPlacement} updateManualPlacement={updateManualPlacement} isTeacherLunchPeriod={false} teacherID={null} classroomID={classroom_id} periodID={period_id} periodName={period_name} coursePeriodID={course_period_id} dataSections={course_period_sections_sorted_by_quarter}/>
											)
										})}
									</div>
								)
							})}
							</>
						): (displayCreated && currentView === 'student') ?
						(
							<>
							{displaySchedule.map((student, data_index) => {
								const student_id = student.data_id;
								
								const student_filtered_out = ((filterIDs.length > 0 && !filterIDs.includes(student_id)) || !filteredData.includes(student_id));
								const student_index_too_high = (student_counter > maxStudentsToShow);

								if(student_filtered_out || student_index_too_high) return null;

								student_counter++;
								
								const student_info = student.display_info;

								const student_first_name = student_info.first_name;
								const student_last_name = student_info.last_name;
								const student_grade = student_info.grade;
								
								const student_lunch_schedule_period_id = student_info.lunch_schedule_period_id;
								const student_course_sections = sections.filter(section => section.student_list.includes(student_id));

								const student_match_percent = (student_id in studentMatchPercentages) ? studentMatchPercentages[student_id].match_percent : 0;
								const student_percent_schedule_full =  (student_id in studentMatchPercentages) ? studentMatchPercentages[student_id].percent_schedule_full : 0;
								const best_student_match = Math.round(Math.max(student_match_percent, student_percent_schedule_full)*100);

								const indicator_color = (best_student_match == 100) ? 'green-stroke' : ((best_student_match >= 75 && best_student_match < 100) ? 'yellow-stroke' : 'red-stroke');
								
								return (
									<div key={data_index}>
										<div className='mb-row mb-schedule-row' style={row_style} data-ref={student_id}>
											<div className='mb-schedule-main-inner-box'>
												<div className='mb-schedule-row-name-container' style={{display:'grid', gridTemplateColumns:'auto 1fr'}}>
													<svg viewBox="0 0 36 36" className={`mb-circular-chart ${indicator_color}`}>
														<path className="mb-circle-bg"
															d="M18 2.0845
															a 15.9155 15.9155 0 0 1 0 31.831
															a 15.9155 15.9155 0 0 1 0 -31.831"
														/>
														<path className="mb-circle"
															strokeDasharray={`${best_student_match}, 100`}
															d="M18 2.0845
															a 15.9155 15.9155 0 0 1 0 31.831
															a 15.9155 15.9155 0 0 1 0 -31.831"
														/>
														<text x="18" y="20.35" className="mb-percentage">{best_student_match}%</text>
													</svg>
													<div>
														<div className='mb-schedule-row-name'>{student_last_name}, {student_first_name}</div>
														<div className='mb-schedule-row-department dark-gray-text'>{student_grade}</div>
														<div className='blue-link small-text full-width' onClick={() => toggleEditStudentSchedule(student_id)}>Edit Schedule</div>
													</div>
												</div>
											</div>
											{periodsToShow.map((period_info, index) => {
												const period_id = period_info.period_id;
												const course_period_id = (periodType === 'course_periods') ? period_id : scheduleStructureData.schedule_structure[currentDay].find(structure_info => structure_info.schedule_period_id === period_id).course_period_id;
												const course_period_sections = student_course_sections.filter(section => section.course_periods.includes(course_period_id));
												const is_student_lunch_period = (currentViewType === 'day' && period_id === student_lunch_schedule_period_id) ? true : false;

												const course_period_sections_sorted_by_quarter = course_period_sections.sort((section_A, section_B) => {
													const section_A_min_quarter = section_A.quarter_days.reduce((result, quarter_day) => {
														if(quarter_day.quarter < result) result = quarter_day.quarter;
														return result;
													}, '999');

													const section_B_min_quarter = section_B.quarter_days.reduce((result, quarter_day) => {
														if(quarter_day.quarter < result) result = quarter_day.quarter;
														return result;
													}, '999');

													if(section_A_min_quarter < section_B_min_quarter) return -1;
													return 1;
												});
												
												return (
													<div className='mb-inner-box mb-schedule-inner-box' key={index}>
														{is_student_lunch_period &&
															<div className='mb-student-section'>
																<div className='mb-section-top-bar pastel-yellow'>
																	<div>Lunch</div>
																</div>
																<div className='mb-section-content'>
																	<div className='mb-section-name ellipsis'>Lunch</div>
																</div>
															</div>
														}
														{course_period_sections_sorted_by_quarter.map((section_info, index) => {
															let section_id = section_info.section_id;

															let main_section_id = section_id;
															let main_section_info = section_info;

															let teacher_id = null;
															let teacher_info = null;

															// If section is subsection, get main section info
															const section_is_subsection_index = subsections.findIndex(subsection_section => subsection_section.subsection_id === section_id);
															const section_is_subsection = (section_is_subsection_index !== -1) ? true : false;
															if(section_is_subsection)
															{
																main_section_id = subsections[section_is_subsection_index].section_id;
																main_section_info = sections.find(section => section.section_id === main_section_id);
															}

															const student_in_section = (student_course_sections.findIndex(section => section.section_id === main_section_id) !== -1) ? true : false;
															teacher_id = (student_in_section) ? main_section_info.teacher_id : null;
															teacher_info = (teacher_id) ? teachers.find(teacher => teacher.teacher_id === teacher_id) : null;

															const course_id = main_section_info.course_id;
															const classroom_id = main_section_info.classroom_id;
															const is_lab = main_section_info.is_lab;

															const quarter_days = main_section_info.quarter_days;
															const quarter_1_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '1') !== -1) ? true : false;
															const quarter_2_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '2') !== -1) ? true : false;
															const quarter_3_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '3') !== -1) ? true : false;
															const quarter_4_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '4') !== -1) ? true : false;
															const unique_days = quarter_days.reduce((results, quarter_day) => {
																const day = quarter_day.day;
																if(!results.includes(day)) results.push(day);
																return results;
															},[]);

															// If this is a lab course in a block schedule in day view, only show if this day is included for lab
															if(is_lab === '1' && scheduleType === 'block' && currentViewType === 'day' && quarter_days.findIndex(quarter_day => quarter_day.day === currentDay) === -1) return null;
															
															// Get num students from all subsections too
															const section_subsections = ('subsections' in main_section_info) ? main_section_info.subsections : [];
															
															let num_students = (section_subsections.length === 0) ? main_section_info.student_list.length : section_subsections.reduce((total_students, subsection_id) => {
																const subsection_num_students = sections.find(section => section.section_id === subsection_id).student_list.length;
																return total_students + subsection_num_students;
															}, main_section_info.student_list.length);
															
															const course_info = courses.find(course => course.course_id === course_id);

															const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
															
															const section_color = (is_lab === '1') ? 'green' : '';
															
															return (
																<div className='mb-student-section' key={section_id} data-sectionid={section_id}>
																	<div className={`mb-section-top-bar ${section_color}`}>
																		<div>{is_lab === '1' ? "Lab" : ''}</div>
																	</div>
																	<div className='mb-section-content'>
																		{course_info && <div className='mb-section-name ellipsis'>{capitalizeFirstLetters(course_info.name)} ({course_info.course_code})</div>}
																		{section_subsections.map(subsection_id => {
																			const section_subsection_info = sections.find(section => section.section_id === subsection_id);
																			const subsection_course_id = section_subsection_info.course_id;
																			const subsection_course_info = courses.find(course => course.course_id === subsection_course_id);
																			const subsection_course_name = (subsection_course_info) ? capitalizeFirstLetters(subsection_course_info.name) : null;
																			const subsection_course_code = (subsection_course_info) ? subsection_course_info.course_code : null;

																			if(!teacher_id)
																			{
																				const student_in_section = (student_course_sections.findIndex(section => section.section_id === subsection_id) !== -1) ? true : false;
																				teacher_id = (student_in_section) ? section_subsection_info.teacher_id : null;
																				teacher_info = (teacher_id) ? teachers.find(teacher => teacher.teacher_id === teacher_id) : null;
																			}

																			// Check if subsection is placeholder inclusion course (for schools without separate ICR codes)
																			const subsection_is_inclusion = section_subsection_info.is_inclusion;
																			let is_placeholder_inclusion = false;
																			if(scheduleInfo.separate_ICR_codes === '0' && course_id === subsection_course_id && subsection_is_inclusion === '1') is_placeholder_inclusion = true;

																			return (
																				<React.Fragment key={subsection_id}>
																				{is_placeholder_inclusion ?
																					(
																						<div className='orange-text'>Inclusion</div>
																					):
																					(
																						<div className='mb-section-name ellipsis blue-text'>{subsection_course_name} ({subsection_course_code})</div>
																					)
																				}
																				</React.Fragment>
																			)
																		})}
																		{teacher_info &&
																			<div className='dark-gray-text'>{teacher_info.name}, {teacher_info.first_name}</div>
																		}
																		<div className='mb-section-quarter-day-container'>
																			<div className={`mb-section-quarter-day ${quarter_1_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q1</div>
																			<div className={`mb-section-quarter-day ${quarter_2_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q2</div>
																			<div className={`mb-section-quarter-day ${quarter_3_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q3</div>
																			<div className={`mb-section-quarter-day ${quarter_4_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q4</div>
																		</div>
																		{(is_lab === '1') ?
																			(
																				<div className='mb-lab-days extra-small-text green-text'>{unique_days.length > 0 ? `Day(s) ${unique_days.map(day => (day))}` : 'No lab days'}</div>
																			):((scheduleType === 'daily' && unique_days.length > 0 && unique_days.length != scheduleInfo.num_days_in_cycle)) ?
																			(
																				<div className='mb-lab-days extra-small-text blue-text'>Day(s) {unique_days.map(day => (day))}</div>
																			):null
																		}
																		<div className='mb-section-subname-container'>
																			<div className='mb-section-subname'><FontAwesomeIcon icon={faChalkboard}/>{classroom_info ? classroom_info.classroom_name : '--'}</div>
																			<div className='mb-section-students'><FontAwesomeIcon icon={faUserGraduate}/> {num_students}</div>
																		</div>
																	</div>
																</div>
															)
														})}
													</div>
												)
											})}
										</div>
									</div>
								)
							})}
							{(student_counter > maxStudentsToShow) && 
								<div className='btn blue-btn load-more-btn' onClick={showMoreStudents}>Load More Students</div>
							}
							</>
						): null
					}
				</div>
			</div>
		</DndProvider>
		</>
	)
}