import React, { useState, useEffect, useCallback } from 'react';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faPen, faTrash, faCheck, faTimes, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { updateUser } from '../../../js/SaveFunctions';
import { getData, createUniqueObjectID } from '../../../js/Helpers';

export default function Users({ redirectURL, previousLoadScreen, schoolInfo, users, setUsers, setLoadScreen }) {
	const [isLoading, setIsLoading] = useState(true);
	
	const [adminMessage, setAdminMessage] = useState(null);
	const [supervisorMessage, setSupervisorMessage] = useState(null);
	const [counselorMessage, setCounselorMessage] = useState(null);
	
	const getUserData = useCallback(() => {
		getData('users', '/admin/get-users', {school_id: schoolInfo.school_id}, true).then(users => {
			setUsers([...users]);
			setIsLoading(false);
		});
	}, [schoolInfo.school_id]);
	
	const sendToUpdateUser = (data) => {
		setAdminMessage(null);
		setSupervisorMessage(null);
		setCounselorMessage(null);
		
		data.school_id = schoolInfo.school_id;
		
		updateUser(data).then(response => {
			const status = response.status;
			const message = response.message;

			const color = (status === 'error') ? 'red-text' : 'green-text';
			
			if(data.role_id === '2')
			{
				setAdminMessage({class: color, message:message});
			}
			else if(data.role_id === '3')
			{
				setSupervisorMessage({class: color, message:message});
			}
			else if(data.role_id === '4')
			{
				setCounselorMessage({class: color, message:message});
			}
			
			setTimeout(function(){
			    if(data.role_id === '2')
				{
					setAdminMessage(null);
				}
				else if(data.role_id === '3')
				{
					setSupervisorMessage(null);
				}
				else if(data.role_id === '4')
				{
					setCounselorMessage(null);
				}
			}, 3000);
			
			if(status === 'success') getUserData();
		});
	}
	
	const hideAllErrors = () => {
		users.forEach(user_info => {
			if('error' in user_info) delete user_info.error;
		});
		
		setUsers([...users]);
	}
	
	const addTempNewUser = (role_id) => {
		const new_user_id = createUniqueObjectID(7);
		
		users.push({user_id:new_user_id, new_user:true, editing:true, first_name:null, last_name:null, email:null, role_id:role_id, temp_changes:{first_name:null, last_name:null, email:null, verified:'0'}});
		setUsers([...users]);
	}
	
	const closeEditing = (user_id) => {
		const user_index = users.findIndex(user => user.user_id === user_id);
		const user_info = users[user_index];
		
		if('new_user' in user_info) 
		{
			users.splice(user_index, 1);
		}
		else
		{
			delete user_info['editing'];
			delete user_info['temp_changes'];
		}
		
		// Clear all error messages before closing the edit (otherwise error text remains)
		hideAllErrors();
		setUsers([...users]);
	}
	
	const openEditing = (user_id) => {
		const user_index = users.findIndex(user => user.user_id === user_id);
		const user_info = users[user_index];
		
		user_info.editing = true;
		user_info.temp_changes = user_info;
		
		setUsers([...users]);
	}
	
	const handleUpdateUser = (e, user_id, user_key = null) => {
		const user_index = users.findIndex(user => user.user_id === user_id);
		const user_info = users[user_index];
		
		if(e.key !== 'Enter' && e.type !== 'click') 
		{
			user_info.temp_changes[user_key] = e.target.value;
			setUsers([...users]);
			
			return;
		}
		
		const update_type = ('new_user' in user_info) ? 'add' : 'update';
		
		if(!user_info.temp_changes.last_name || !user_info.temp_changes.first_name || !user_info.temp_changes.email)
		{
			user_info.error = 'User info is missing!';
			setUsers([...users]);
			
			setTimeout(function(){
			    delete user_info.error;
			    setUsers([...users]);
			}, 3000);
			
			return;
		}
		
		user_info.first_name = user_info.temp_changes.first_name;
		user_info.last_name = user_info.temp_changes.last_name;
		user_info.email = user_info.temp_changes.email;
		
		const data = {update_type:update_type, ...user_info};
		
		if('editing' in user_info) delete user_info.editing;
		if('temp_changes' in user_info) delete user_info.temp_changes;
		
		setUsers([...users]);
		
		sendToUpdateUser(data);
	}
	
	const handleDeleteUser = (e, user_id, role_id) => {
		const data = {update_type:'delete', user_id:user_id, role_id:role_id};
		
		sendToUpdateUser(data);
	}

	const resendTempPassword = (user_id, role_id) => {
		const data = {update_type:'resend', user_id:user_id, role_id:role_id};

		sendToUpdateUser(data);
	}
	
	useEffect(() => {
		getUserData();
	}, [getUserData]);
	
	
	const admins = users.filter(user => user.role_id === '2');
	const supervisors = users.filter(user => user.role_id === '3');
	const counselors = users.filter(user => user.role_id === '4');
	
	return (
		<>
		{redirectURL &&
			<Link className='redirect-btn' to={redirectURL} onClick={() => setLoadScreen(previousLoadScreen)}><FontAwesomeIcon icon={faChevronLeft} className='redirect-btn-icon'/>Back to Previous Screen</Link>
		}
		<div className='page-main-heading' style={{marginTop:`${redirectURL ? '25px' : '0px'}`}}>Users</div>
		<p>Manage all your admin accounts, school counselor accounts, and department supervisor accounts</p>
		<div className='user-accounts-container'>
			<div className='user-account-header blue'>
				<FontAwesomeIcon className='user-account-header-icon white-text' icon={faUserAlt}/>
				<div className='user-account-header-label'>Admins</div>
			</div>
			
			<div className='user-accounts-inner-container'>
				{isLoading ? 
					(
						<div className='center-text'>
							<img src={require("../../../images/balls.gif")} alt='loading...' style={{height:'40px'}}/>
						</div>
					):
					(
						<>
						{admins.map((user, i) => {
							const user_id = user.user_id;
							
							if(user.editing)
							{
								const has_error = ('error' in user) ? true : false;
								
								return (
									<div className='users-add-new-user-row' key={i} data-key={i}>
										<div className='users-add-new-user-inner-row'>
											<div className='users-add-new-user-label'>Last Name</div>
											<div className='users-add-new-user-label'>First Name</div>
											<div className='users-add-new-user-label'>Email</div>
											<div className='white-text'>.</div>
										</div>
										<div className='users-add-new-user-inner-row'>
											<input className={`users-add-new-user-input ${has_error ? 'error' : ''}`} type='text' defaultValue={user.temp_changes.last_name} onKeyUp={(e) => handleUpdateUser(e, user_id, 'last_name')} autoFocus={true}/>
											<input className={`users-add-new-user-input ${has_error ? 'error' : ''}`} type='text'  defaultValue={user.temp_changes.first_name} onKeyUp={(e) => handleUpdateUser(e, user_id, 'first_name')}/>
											<input className={`users-add-new-user-input ${has_error ? 'error' : ''}`} type='text'  defaultValue={user.temp_changes.email} onKeyUp={(e) => handleUpdateUser(e, user_id, 'email')}/>
											<div className='users-add-new-col users-add-new-btns'>
												<FontAwesomeIcon className='users-add-new-icons users-add-new-confirm' icon={faCheck} onClick={(e) => handleUpdateUser(e, user_id)}/>
												<FontAwesomeIcon className='users-add-new-icons users-add-new-exit' icon={faTimes} onClick={() => closeEditing(user_id)}/>
											</div>
										</div>
										{has_error &&
											<div className='add-new-user-error-message'>{user.error}</div>
										}
									</div>
								)
							}
							else
							{
								return (
									<div className='user-row' key={i}>
										<div className='user-name'>{user.last_name}, {user.first_name}</div>
										<div className='user-email'>{user.email}</div>
										<div className='user-account-type'>
											<div>Admin <span className={`user-verified small-text ${user.verified === '1' ? 'green-text' : 'gray-text'}`}>{user.verified === '1' ? 'Verified' : 'Not Verified'}</span></div>
											{user.verified === '0' && <div className='blue-link' onClick={() => resendTempPassword(user_id, '2')}>Resend Password</div>}
										</div>
										<div className='user-options' data-key={i}>
											<FontAwesomeIcon className='user-edit' icon={faPen} onClick={() => openEditing(user_id)}/>
											<FontAwesomeIcon className='user-delete' icon={faTrash} onClick={(e) => handleDeleteUser(e, user_id, '2')}/>
										</div>
									</div>
								)
							}
						})}
						{adminMessage &&
							<div className={`users-status-message ${adminMessage.class}`}>{adminMessage.message}</div>
						}
						<div className='users-add-new-user-btn' onClick={() => addTempNewUser('2')}>Add New User</div>
						</>
					)
				}
			</div>
		</div>
		<div className='user-accounts-container'>
			<div className='user-account-header purple'>
				<FontAwesomeIcon className='user-account-header-icon white-text' icon={faUserAlt}/>
				<div className='user-account-header-label'>Department Supervisors</div>
			</div>
			<div className='user-accounts-inner-container'>
				{isLoading ? 
					(
						<div className='center-text'>
							<img src={require("../../../images/balls.gif")} alt='loading...' style={{height:'40px'}}/>
						</div>
					):
					(
						<>
						{supervisors.map((user, i) => {
							const user_id = user.user_id;
							
							if(user.editing)
							{
								const has_error = ('error' in user) ? true : false;
								
								return (
									<div className='users-add-new-user-row' key={i} data-key={i}>
										<div className='users-add-new-user-inner-row'>
											<div className='users-add-new-user-label'>Last Name</div>
											<div className='users-add-new-user-label'>First Name</div>
											<div className='users-add-new-user-label'>Email</div>
											<div className='white-text'>.</div>
										</div>
										<div className='users-add-new-user-inner-row'>
											<input className={`users-add-new-user-input ${has_error ? 'error' : ''}`} type='text' defaultValue={user.temp_changes.last_name} onKeyUp={(e) => handleUpdateUser(e, user_id, 'last_name')} autoFocus={true}/>
											<input className={`users-add-new-user-input ${has_error ? 'error' : ''}`} type='text'  defaultValue={user.temp_changes.first_name} onKeyUp={(e) => handleUpdateUser(e, user_id, 'first_name')}/>
											<input className={`users-add-new-user-input ${has_error ? 'error' : ''}`} type='text'  defaultValue={user.temp_changes.email} onKeyUp={(e) => handleUpdateUser(e, user_id, 'email')}/>
											<div className='users-add-new-col users-add-new-btns'>
												<FontAwesomeIcon className='users-add-new-icons users-add-new-confirm' icon={faCheck} onClick={(e) => handleUpdateUser(e, user_id)}/>
												<FontAwesomeIcon className='users-add-new-icons users-add-new-exit' icon={faTimes} onClick={() => closeEditing(user_id)}/>
											</div>
										</div>
										{has_error &&
											<div className='add-new-user-error-message'>{user.error}</div>
										}
									</div>
								)
							}
							else
							{
								return (
									<div className='user-row' key={i}>
										<div className='user-name'>{user.last_name}, {user.first_name}</div>
										<div className='user-email'>{user.email}</div>
										<div className='user-account-type'>
											<div>Supervisor <span className={`user-verified small-text ${user.verified === '1' ? 'green-text' : 'gray-text'}`}>{user.verified === '1' ? 'Verified' : 'Not Verified'}</span></div>
											{user.verified === '0' && <div className='blue-link' onClick={() => resendTempPassword(user_id, '3')}>Resend Password</div>}
										</div>
										<div className='user-options' data-key={i}>
											<FontAwesomeIcon className='user-edit' icon={faPen} onClick={() => openEditing(user_id)}/>
											<FontAwesomeIcon className='user-delete' icon={faTrash} onClick={(e) => handleDeleteUser(e, user_id, '3')}/>
										</div>
									</div>
								)
							}
						})}
						{supervisorMessage &&
							<div className={`users-status-message ${supervisorMessage.class}`}>{supervisorMessage.message}</div>
						}
						<div className='users-add-new-user-btn' onClick={() => addTempNewUser('3')}>Add New User</div>
						</>
					)
				}
			</div>
		</div>
		<div className='user-accounts-container'>
			<div className='user-account-header turquoise'>
				<FontAwesomeIcon className='user-account-header-icon white-text' icon={faUserAlt}/>
				<div className='user-account-header-label'>Counselors</div>
			</div>
			<div className='user-accounts-inner-container'>
				{isLoading ? 
					(
						<div className='center-text'>
							<img src={require("../../../images/balls.gif")} alt='loading...' style={{height:'40px'}}/>
						</div>
					):
					(
						<>
						{counselors.map((user, i) => {
							const user_id = user.user_id;
							
							if(user.editing)
							{
								const has_error = ('error' in user) ? true : false;
								
								return (
									<div className='users-add-new-user-row' key={i} data-key={i}>
										<div className='users-add-new-user-inner-row'>
											<div className='users-add-new-user-label'>Last Name</div>
											<div className='users-add-new-user-label'>First Name</div>
											<div className='users-add-new-user-label'>Email</div>
											<div className='white-text'>.</div>
										</div>
										<div className='users-add-new-user-inner-row'>
											<input className={`users-add-new-user-input ${has_error ? 'error' : ''}`} type='text' defaultValue={user.temp_changes.last_name} onKeyUp={(e) => handleUpdateUser(e, user_id, 'last_name')} autoFocus={true}/>
											<input className={`users-add-new-user-input ${has_error ? 'error' : ''}`} type='text'  defaultValue={user.temp_changes.first_name} onKeyUp={(e) => handleUpdateUser(e, user_id, 'first_name')}/>
											<input className={`users-add-new-user-input ${has_error ? 'error' : ''}`} type='text'  defaultValue={user.temp_changes.email} onKeyUp={(e) => handleUpdateUser(e, user_id, 'email')}/>
											<div className='users-add-new-col users-add-new-btns'>
												<FontAwesomeIcon className='users-add-new-icons users-add-new-confirm' icon={faCheck}  onClick={(e) => handleUpdateUser(e, user_id)}/>
												<FontAwesomeIcon className='users-add-new-icons users-add-new-exit' icon={faTimes} onClick={() => closeEditing(user_id)}/>
											</div>
										</div>
										{has_error &&
											<div className='add-new-user-error-message'>{user.error}</div>
										}
									</div>
								)
							}
							else
							{
								return (
									<div className='user-row' key={i}>
										<div className='user-name'>{user.last_name}, {user.first_name}</div>
										<div className='user-email'>{user.email}</div>
										<div className='user-account-type'>
											<div>Counselor <span className={`user-verified small-text ${user.verified === '1' ? 'green-text' : 'gray-text'}`}>{user.verified === '1' ? 'Verified' : 'Not Verified'}</span></div>
											{user.verified === '0' && <div className='blue-link' onClick={() => resendTempPassword(user_id, '4')}>Resend Password</div>}
										</div>
										<div className='user-options' data-key={i}>
											<FontAwesomeIcon className='user-edit' icon={faPen} onClick={() => openEditing(user_id)}/>
											<FontAwesomeIcon className='user-delete' icon={faTrash} onClick={(e) => handleDeleteUser(e, user_id, '4')}/>
										</div>
									</div>
								)
							}
						})}
						{counselorMessage &&
							<div className={`users-status-message ${counselorMessage.class}`}>{counselorMessage.message}</div>
						}
						<div className='users-add-new-user-btn'onClick={() => addTempNewUser('4')}>Add New User</div>
						</>
					)
				}
			</div>
		</div>
		</>
	);
}