import React, { useState, useEffect } from 'react';

import { isEmpty, getDepartmentProgress, timesAreOverlapping} from '../../js/Helpers';

import welcome_image from '../../images/welcome-page-image.png';
import '../../css/dashboard.css';

export default function DashboardHSMS({ userInfo, schoolInfo, scheduleStructureData, lunchInfo, goToScreen, rolePages, lastScreen, studentsLoading, students, studentMatchPercentages, classroomsLoading, classrooms, teachersLoading, teachers, sections }) {
	const [percentStepsLoading, setPercentStepsLoading] = useState(true);
	const [departmentProgressLoading, setDepartmentProgressLoading] = useState(true);
	const [studentMatchLoading, setStudentMatchLoading] = useState(true);
	const [classroomUtlizationLoading, setClassroomUtilizationLoading] = useState(true);
	const [teacherUtilizationLoading, setTeacherUtilizationLoading] = useState(true);

	const [totalQuarters, setTotalQuarters] = useState(999);
	const [departmentProgress, setDepartmentProgress] = useState([]);
	const [percentStepsComplete, setPercentStepsComplete] = useState(null);
	const [percentStepsCompleteColor, setPercentStepsCompleteColor] = useState(null);

	const [studentSummaryStatistics, setStudentSummaryStatistics] = useState(null);
	const [studentsPercentMatched, setStudentsPercentMatched] = useState(null);
	const [studentPercentMatchedColor, setStudentPercentMatchedColor] = useState(null);

	const [classroomUtilization, setClassroomUtilization] = useState([]);
	const [classroomAverageUtilization, setClassroomAverageUtilization] = useState(null);
	const [classroomUtilizationColor, setClassroomUtilizationColor] = useState(null);

	const [teacherUtilization, setTeacherUtilization] = useState([]);
	const [teacherAverageUtilization, setTeacherAverageUtilization] = useState(null);
	const [teacherUtilizationColor, setTeacherUtilizationColor] = useState(null);

	const [teacherDetailsOpen, setTeacherDetailsOpen] = useState(false);
	const [classroomDetailsOpen, setClassroomDetailsOpen] = useState(false);

	////////////////////////
	///// CALCULATIONS /////
	////////////////////////
	// CALCULATE TOTAL QUARTERS
	useEffect(() => {
		if(isEmpty(scheduleStructureData) || isEmpty(lunchInfo)) return;
		// Calculate total quarters
		let total_quarters = 0;
		for(const day in scheduleStructureData.schedule_structure)
		{
			const logged_schedule_periods = [];

			// Get one lunch and base off total quarters off this lunch
			const lunches = lunchInfo.lunches;
			const first_lunch_info = Object.keys(lunches).reduce((result, lunch_id) => {
				if(result) return result;
				const lunch_info = lunches[lunch_id];
				const lunch_days = lunch_info.days;
				if(lunch_days.includes(day)) result = lunch_info;
				return result;
			}, null);
			
			const lunch_start_time = (first_lunch_info) ? first_lunch_info.start_time : null;
			const lunch_end_time = (first_lunch_info) ? first_lunch_info.end_time : null;

			// Go through day schedule periods and build the lunch path
			const day_periods = scheduleStructureData.schedule_structure[day];
			let lunch_logged = false;
			let previous_start_time = null;
			let previous_end_time = null;
			for(const day_period_info of day_periods)
			{
				const schedule_period_id = day_period_info.schedule_period_id;
				const schedule_period_info = scheduleStructureData.schedule_periods[schedule_period_id];
				const include_in_match = schedule_period_info.include_in_match === '1';

				if(!include_in_match) continue;
				if(logged_schedule_periods.includes(schedule_period_id)) continue;

				// Get this schedule period's start and end time
				const schedule_period_start_time = schedule_period_info.start_time;
				const schedule_period_end_time = schedule_period_info.end_time;

				// If schedule period overlaps with lunch, and lunch has not been logged yet, count the lunch period
				// Otherwise, if nothing has been logged yet, then this is the first schedule period, so count it
				// Otherwise, if this schedule period overlaps with the previously logged start/end times, then skip it
				if(!lunch_logged && timesAreOverlapping(lunch_start_time, lunch_end_time, schedule_period_start_time, schedule_period_end_time))
				{
					logged_schedule_periods.push(schedule_period_id);
					total_quarters += 4;
					
					lunch_logged = true;
					previous_start_time = lunch_start_time;
					previous_end_time = lunch_end_time;
				}
				else if(logged_schedule_periods.length === 0) 
				{
					logged_schedule_periods.push(schedule_period_id);
					total_quarters += 4;

					previous_start_time = schedule_period_start_time;
					previous_end_time = schedule_period_end_time;
				}
				else
				{
					if(timesAreOverlapping(schedule_period_start_time, schedule_period_end_time, previous_start_time, previous_end_time)) continue;

					logged_schedule_periods.push(schedule_period_id);
					total_quarters += 4;

					previous_start_time = schedule_period_start_time;
					previous_end_time = schedule_period_end_time;
				}
			}
		}
		setTotalQuarters(total_quarters);
	}, [scheduleStructureData, lunchInfo]);

	//// CALCULCATE PERCENT STEP PROGRESS /////
	useEffect(() => {
		if(isEmpty(rolePages)) return;

		const subscreen_progress = Object.keys(rolePages).reduce((result, role_page) => {
			const role_page_info = rolePages[role_page];
			const role_page_subscreens = role_page_info.subscreens;
			const num_role_page_subscreens = role_page_subscreens.length;
			const num_completed_role_page_subscreens = role_page_subscreens.filter(subscreen => subscreen.complete).length;

			result.num_subscreens += num_role_page_subscreens;
			result.num_subscreens_complete += num_completed_role_page_subscreens;
			
			return result;
		}, {num_subscreens: 0, num_subscreens_complete: 0});

		//console.log(subscreen_progress);
		getDepartmentProgress({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}).then(data => {
			const all_departments = data.departments;
			
			setDepartmentProgress([...all_departments]);
			setDepartmentProgressLoading(false);
		});

		const percent_steps_complete = (subscreen_progress.num_subscreens === 0) ? 0 : Math.round((subscreen_progress.num_subscreens_complete/subscreen_progress.num_subscreens + Number.EPSILON) * 100);
		const percent_steps_complete_color = (percent_steps_complete < 50) ? "#c0392b": ((percent_steps_complete < 75) ? "#ff9f00": "#27ae60");

		setPercentStepsComplete(percent_steps_complete);
		setPercentStepsCompleteColor(percent_steps_complete_color);
		setPercentStepsLoading(false);
	}, [rolePages, schoolInfo.school_id, schoolInfo.current_schedule_version_id]);

	/// CALCULATE STUDENT PERCENTAGES ///
	useEffect(() => {
		if(studentsLoading || isEmpty(scheduleStructureData)) return;

		const student_summary_statistics = students.reduce((result, student) => {
			const student_id = student.student_id;
			const student_grade = student.grade;
			const student_requests = student.student_requests.filter(request => request.is_deleted === '0');
			
			const student_match_percent = (student_id in studentMatchPercentages) ? studentMatchPercentages[student_id].match_percent : 0;
			const student_percent_schedule_full =  (student_id in studentMatchPercentages) ? studentMatchPercentages[student_id].percent_schedule_full : 0;
	
			if(!(student_grade in result)) result[student_grade] = {'partial': 0, 'full': 0, 'empty': 0};
	
			if(student_requests.length === 0) 
			{
				result[student_grade]['empty']++;
				result.total.empty++;
				result.total.full++;
			}
			else if(student_match_percent == 1 || student_percent_schedule_full == 1)
			{
				result[student_grade]['full']++;
				result.total.full++;
			}
			else
			{
				result[student_grade]['partial']++;
				result.total.partial++;
			}
	
			return result;
		}, {'total':{'partial': 0, 'full': 0, 'empty': 0}});

		const percent_students_full = ('total' in student_summary_statistics && 'full' in student_summary_statistics.total) ? student_summary_statistics.total.full : 0;
		const total_students = students.length;
		const percent_students_matched = (total_students === 0) ? 0 : Math.round((percent_students_full/total_students + Number.EPSILON) * 100);
		const percent_students_matched_color = (percent_students_matched < 50) ? "#c0392b": ((percent_students_matched < 75) ? "#ff9f00": "#27ae60");
		
		setStudentSummaryStatistics({...student_summary_statistics});
		setStudentsPercentMatched(percent_students_matched);
		setStudentPercentMatchedColor(percent_students_matched_color);
		setStudentMatchLoading(false);
	}, [studentsLoading, students, scheduleStructureData, studentMatchPercentages]);

	const calculateClassroomUtilization = () => {
		let classroom_utilization = [];
		classrooms.forEach(classroom_info => {
			const classroom_id = classroom_info.classroom_id;
			const classroom_name = classroom_info.classroom_name;
			const classroom_sections = sections.filter(section => (('course_periods' in section) && section.course_periods.length > 0) && section.classroom_id === classroom_id);

			// Calculate percent schedule full
			const schedule_match_info = Object.keys(scheduleStructureData.schedule_structure).reduce((results, day) => {
				const day_periods = scheduleStructureData.schedule_structure[day];
				
				for(const period_info of day_periods)
				{
					const schedule_period_id = period_info.schedule_period_id;
					const include_in_match = scheduleStructureData.schedule_periods[schedule_period_id]['include_in_match'] === '1';
					if(!include_in_match) continue;
		
					const course_period_id = period_info.course_period_id;
		
					const quarter_1_bool = classroom_sections.findIndex(section => section.course_periods.includes(course_period_id) && section.quarter_days.findIndex(quarter_day => quarter_day.quarter === '1' && quarter_day.day === day) !== -1) !== -1;
					const quarter_2_bool = classroom_sections.findIndex(section => section.course_periods.includes(course_period_id) && section.quarter_days.findIndex(quarter_day => quarter_day.quarter === '2' && quarter_day.day === day) !== -1) !== -1;
					const quarter_3_bool = classroom_sections.findIndex(section => section.course_periods.includes(course_period_id) && section.quarter_days.findIndex(quarter_day => quarter_day.quarter === '3' && quarter_day.day === day) !== -1) !== -1;
					const quarter_4_bool = classroom_sections.findIndex(section => section.course_periods.includes(course_period_id) && section.quarter_days.findIndex(quarter_day => quarter_day.quarter === '4' && quarter_day.day === day) !== -1) !== -1;
		
					if(quarter_1_bool) results.matched_quarters++;
					if(quarter_2_bool) results.matched_quarters++;
					if(quarter_3_bool) results.matched_quarters++;
					if(quarter_4_bool) results.matched_quarters++;
				}
		
				return results;
			}, {'matched_quarters': 0,});

			const classroom_percent_schedule_full =(totalQuarters !== 0) ? Math.min(Math.round((schedule_match_info.matched_quarters/totalQuarters + Number.EPSILON)*100), 100) : 0; 

			classroom_utilization.push({classroom_id:classroom_id, classroom_name:classroom_name, percent_schedule_full: classroom_percent_schedule_full});
		});
		
		// Classroom Display //
		// Note: you can take an average of averages because all classrooms should be available for the same amount of time every day  (aka weighted the same)
		const average_classroom_utilization = (classroom_utilization.length > 0) ? Math.round((classroom_utilization.reduce((results, classroom) => {
			const classroom_percent_schedule_full = parseFloat(classroom.percent_schedule_full);
			results += classroom_percent_schedule_full;
			return results;
		}, 0)/classroom_utilization.length + Number.EPSILON)) : 0;
		const percent_classrooms_utilized_color = (average_classroom_utilization < 50) ? "#c0392b": ((average_classroom_utilization < 75) ? "#ff9f00": "#27ae60");
		
		setClassroomUtilization([...classroom_utilization]);
		setClassroomAverageUtilization(average_classroom_utilization);
		setClassroomUtilizationColor(percent_classrooms_utilized_color);
		setClassroomUtilizationLoading(false);
	}

	const calculateTeacherUtilization = () => {
		let teacher_utilization = [];
		teachers.forEach(teacher_info => {
			const teacher_id = teacher_info.teacher_id;
			const teacher_last_name = teacher_info.name;
			const teacher_first_name = teacher_info.first_name;
			const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;
			//console.log(JSON.parse(JSON.stringify(sections)));
			const teacher_sections = sections.filter(section => (('course_periods' in section) && section.course_periods.length > 0) && section.teacher_id === teacher_id);
			
			// Calculate percent schedule full
			let num_days = 0;
			const schedule_match_info = Object.keys(scheduleStructureData.schedule_structure).reduce((results, day) => {
				const day_periods = scheduleStructureData.schedule_structure[day];
				
				for(const period_info of day_periods)
				{
					const schedule_period_id = period_info.schedule_period_id;
					const include_in_match = scheduleStructureData.schedule_periods[schedule_period_id]['include_in_match'] === '1';
					if(!include_in_match) continue;
		
					const course_period_id = period_info.course_period_id;
		
					const quarter_1_bool = teacher_sections.findIndex(section => section.course_periods.includes(course_period_id) && section.quarter_days.findIndex(quarter_day => quarter_day.quarter === '1' && quarter_day.day === day) !== -1) !== -1;
					const quarter_2_bool = teacher_sections.findIndex(section => section.course_periods.includes(course_period_id) && section.quarter_days.findIndex(quarter_day => quarter_day.quarter === '2' && quarter_day.day === day) !== -1) !== -1;
					const quarter_3_bool = teacher_sections.findIndex(section => section.course_periods.includes(course_period_id) && section.quarter_days.findIndex(quarter_day => quarter_day.quarter === '3' && quarter_day.day === day) !== -1) !== -1;
					const quarter_4_bool = teacher_sections.findIndex(section => section.course_periods.includes(course_period_id) && section.quarter_days.findIndex(quarter_day => quarter_day.quarter === '4' && quarter_day.day === day) !== -1) !== -1;
		
					if(quarter_1_bool) results.matched_quarters++;
					if(quarter_2_bool) results.matched_quarters++;
					if(quarter_3_bool) results.matched_quarters++;
					if(quarter_4_bool) results.matched_quarters++;
				}
				num_days++;
				return results;
			}, {'matched_quarters': 0,});

			// Subtract 12 quarters (to account for 4 quarters for each of lunch, prep, and duty) PER DAY
			const total_quarters = Math.max(totalQuarters - 12*num_days, 0);

			// Get percent of teacher schedule that is full
			const teacher_percent_schedule_full =(total_quarters !== 0) ? Math.min(Math.round((schedule_match_info.matched_quarters/(total_quarters) + Number.EPSILON)*100), 100) : 0;

			teacher_utilization.push({teacher_id:teacher_id, full_name: teacher_full_name, percent_schedule_full:teacher_percent_schedule_full});
		});
		
		// Classroom Display //
		// Note: you can take an average of averages because all teachers should be available for the same amount of time every day  (aka weighted the same)
		const average_teacher_utilization = (teacher_utilization.length > 0) ? Math.round((teacher_utilization.reduce((results, classroom) => {
			const classroom_percent_schedule_full = parseFloat(classroom.percent_schedule_full);
			results += classroom_percent_schedule_full;
			return results;
		}, 0)/teacher_utilization.length + Number.EPSILON)) : 0;
		const percent_teachers_utilized_color = (average_teacher_utilization < 50) ? "#c0392b": ((average_teacher_utilization < 75) ? "#ff9f00": "#27ae60");
		
		setTeacherUtilization([...teacher_utilization]);
		setTeacherAverageUtilization(average_teacher_utilization);
		setTeacherUtilizationColor(percent_teachers_utilized_color);
		setTeacherUtilizationLoading(false);
	}

	/// CALCULATE CLASSROOM UTILIZATION ON CHANGE ///
	useEffect(() => {
		if(classroomsLoading || isEmpty(scheduleStructureData) || totalQuarters === 999) return;

		// Caluclate match percent/percent day full
		calculateClassroomUtilization();
	}, [classroomsLoading, classrooms, sections, scheduleStructureData, totalQuarters]);

	/// CALCULATE TEACHER UTILIZATION ON CHANGE///
	useEffect(() => {
		if(teachersLoading || isEmpty(scheduleStructureData) || totalQuarters === 999) return;
		// Caluclate match percent/percent day full
		calculateTeacherUtilization();
	}, [teachersLoading, teachers, classrooms, sections, scheduleStructureData, totalQuarters]);

	return (
		<div id='progress-page-welcome-screen'>
			<div id='progress-page-welcome-sidebar'>
				<img id='progress-page-welcome-screen-image' src={welcome_image} alt='Welcome Img'/>
				<h1 className='main-header'>Welcome to Edario!</h1>
				<p className='subheader' style={{margin:'-20px auto 100px auto'}}>Simplify scheduling by using our proven step by step process.</p>
				<div className='btn green-btn btn-large' style={{maxWidth:'400px',marginTop:'35px'}} onClick={() => goToScreen(lastScreen)}>{!rolePages ? <img src={require('../../images/balls.gif')} alt='Loading...' style={{height:'25px'}}/> : ((lastScreen === 'school_setup') ? "Let's Get Started!" : "Continue where you left off")}</div>
			</div>
			{(!isEmpty(userInfo) && (userInfo.role_id === '1' || userInfo.role_id === '2')) &&
			<div id='dashboard-parent-container'>
				<div id='dashboard'>
					<div id='dashboard-scheduling-progress' className='dashboard-container'>
						<div><h4>Scheduling Progress</h4></div>
						<div className='dashboard-schedule-progress-inner-container'>
							<div>
								<div className='percentage-container dashboard-percentage-container'>
									<svg viewBox="0 0 36 36">
										<path className='percentage-circle-default' d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
										{percentStepsComplete != 0 &&
											<path className='percentage-circle-inner' d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" stroke={percentStepsCompleteColor} strokeDasharray={`${percentStepsComplete}, 100`}/>
										}
									</svg>
									<div className='percentage-text-container'>
										{percentStepsLoading ?
											(
												<div>Loading...</div>
											):
											(
												<>
												<div className='percent-text'>{percentStepsComplete}%</div>
												<div className='percent-subtext'>Steps Complete</div>
												</>
											)
										}
									</div>
								</div>
							</div>
							<div className='text-align-left'>
								<div className='small-text'>Department Progress</div>
								{(departmentProgressLoading) ?
									(
										<div className='full-width text-align-center' style={{padding:'50px 0px'}}><div className='loader'></div></div>
									): (departmentProgress.length > 0) ? 
									(
										<div id='dashboard-department-progress-container'>
											{departmentProgress.map((department_info, index) => {
												const department = department_info.department;
												const department_subscreens = department_info.subscreens;
												
												let total_subscreens = 0;
												let number_completed_screens = 0;
												for(const subscreen in department_subscreens)
												{
													const department_subscreen = department_subscreens[subscreen];
													const subscreen_confirmed = department_subscreen.confirmed;
													if(subscreen_confirmed) number_completed_screens++;
													total_subscreens++;
												}

												const department_percent_complete = (total_subscreens === 0) ? 0 : Math.round((number_completed_screens/total_subscreens + Number.EPSILON) * 100);

												return (
													<div className='dashboard-department-progress' key={index}>
														<div>{department}</div>
														<div>{department_percent_complete}%</div>
													</div>
												)
											})}
										</div>
									):
									(
										<div className='general-screen-message'>No departments have been created yet.</div>
									)
									
								}
							</div>
						</div>
					</div>
					<div id='dashboard-teacher-utilization' className='dashboard-container'>
						<div>
							<h4>Teacher Utilization</h4>
						</div>
						<div>
							<div className='percentage-container dashboard-utilization-percentage-container'>
								<svg viewBox="0 0 36 36">
									<path className='percentage-circle-default' d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
									{teacherAverageUtilization != 0 &&
										<path className='percentage-circle-inner' d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" stroke={teacherUtilizationColor} strokeDasharray={`${teacherAverageUtilization}, 100`}/>
									}
								</svg>
								<div className='percentage-text-container'>
									{teacherUtilizationLoading ?
										(
											<div>Loading...</div>
										):
										(
											<>
											<div className='percent-text'>{teacherAverageUtilization}%</div>
											<div className='percent-subtext'>Utilized</div>
											</>
										)
									}
								</div>
							</div>
						</div>
						<div className='dashboard-details-link blue-link align-right' onClick={() => setTeacherDetailsOpen(!teacherDetailsOpen)}>{teacherDetailsOpen ? 'Hide' : 'Show'} Details</div>
						{teacherDetailsOpen &&
							<div className='dashboard-details-container'>
								<div className='small-text'>Teachers</div>
								{teacherUtilization.map((teacher_utilization, index) => {
									const teacher_full_name = teacher_utilization.full_name;
									const teacher_percent_schedule_full = teacher_utilization.percent_schedule_full;

									return (
										<div className='dashboard-detail-row' key={index}>
											<div>{teacher_full_name}</div>
											<div>{teacher_percent_schedule_full}%</div>
										</div>
									)
								})}
							</div>
						}
					</div>
					<div id='dashboard-classroom-utilization' className='dashboard-container'>
						<div>
							<h4>Classroom Utilization</h4>
						</div>
						<div>
							<div className='percentage-container dashboard-utilization-percentage-container'>
								<svg viewBox="0 0 36 36">
									<path className='percentage-circle-default' d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
									{classroomAverageUtilization != 0 &&
										<path className='percentage-circle-inner' d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" stroke={classroomUtilizationColor} strokeDasharray={`${classroomAverageUtilization}, 100`}/>
									}
								</svg>
								<div className='percentage-text-container'>
									{classroomUtlizationLoading ?
										(
											<div>Loading...</div>
										):
										(
											<>
											<div className='percent-text'>{classroomAverageUtilization}%</div>
											<div className='percent-subtext'>Utilized</div>
											</>
										)
									}
								</div>
							</div>
						</div>
						<div className='dashboard-details-link blue-link align-right' onClick={() => setClassroomDetailsOpen(!classroomDetailsOpen)}>{classroomDetailsOpen ? 'Hide' : 'Show'} Details</div>
						{classroomDetailsOpen &&
							<div className='dashboard-details-container'>
								<div className='small-text'>Classrooms</div>
								{classroomUtilization.map((classroom_utilization, index) => {
									const classroom_name = classroom_utilization.classroom_name;
									const classroom_percent_schedule_full = classroom_utilization.percent_schedule_full;

									return (
										<div className='dashboard-detail-row' key={index}>
											<div>{classroom_name}</div>
											<div>{classroom_percent_schedule_full}%</div>
										</div>
									)
								})}
							</div>
						}
					</div>
					<div id='dashboard-students-scheduled' className='dashboard-container'>
						<div>
							<h4>Students Scheduled</h4>
						</div>
						<div>
							<div className='percentage-container dashboard-percentage-container'>
								<svg viewBox="0 0 36 36">
									<path className='percentage-circle-default' d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
									{studentsPercentMatched &&
										<path className='percentage-circle-inner' d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" stroke={studentPercentMatchedColor} strokeDasharray={`${studentsPercentMatched}, 100`}/>
									}
								</svg>
								<div className='percentage-text-container'>
									{studentMatchLoading ?
										(
											<div>Loading...</div>
										):
										(
											<>
											<div className='percent-text'>{studentsPercentMatched}%</div>
											<div className='percent-subtext'>Fully Scheduled</div>
											</>
										)
									}
								</div>
							</div>
						</div>
						<div>
							{!studentMatchLoading && Object.keys(studentSummaryStatistics).map(grade => {
								const grade_info = studentSummaryStatistics[grade];
								const grade_full_schedules = grade_info.full;
								const grade_partial_schedules = grade_info.partial;
								const grade_empty_schedules = grade_info.empty;
								const grade_total = grade_full_schedules + grade_partial_schedules + grade_empty_schedules;

								const percent_full = (grade_total == 0) ? 0 : Math.round((grade_full_schedules/grade_total + Number.EPSILON) * 100);
								
								return (
									<div className='dashboard-grade-stats' key={grade}>
									{grade !== 'total' &&
										<>
										<div>Grade {grade}</div>
										<div>{percent_full}%</div>
										</>
									}
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</div>
			}
		</div>
	)
}