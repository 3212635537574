import React, { useState , useEffect } from 'react';
import swal from 'sweetalert';

import daily_schedule_img from '../../../images/daily_schedule.png';
import block_schedule_img from '../../../images/block_schedule.png';
import ab_schedule_img from '../../../images/ab_schedule.png';
import ab_skinny_schedule_img from '../../../images/ab_skinny_schedule.png';

import { saveScheduleType } from '../../../js/SaveFunctions';
import { calculateConflicts, recalculateMatchPercent } from '../../../js/Helpers';

import Button from '../../Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import '../../../css/hsms/structure_setup.css';
import '../../../css/hsms/schedule_type.css';

export default function ScheduleType({ subscreenRestricted, subscreenLocked, schoolInfo, scheduleInfo, scheduleInfoLoading, setScheduleInfo, handleMoveToNextScreen }) {
	const schedule_types = {
		"daily":{name:"Daily", explanation: "same periods every day, same classes every day", img:daily_schedule_img}, 
		"block":{name:"Block", explanation: "same periods every day, but the classes rotate", img:block_schedule_img},
		"ab":{name:"A/B", explanation: "same periods every day, but students take entirely different classes on A and B days", img:ab_schedule_img},
		"ab_skinny":{name:"A/B + Skinny", explanation: "same as the A/B schedule, with an additional 'skinny' day where students take all classes", img:ab_skinny_schedule_img},
		"custom":{name:"Custom", explanation: "create a custom schedule from scratch", img:null},
	};
	const [isLoading, setIsLoading] = useState(true);
	
	const onScheduleTypeSelect = async (schedule_type) => {
		const scheduleType = scheduleInfo.schedule_type;
		
		// Save only if schedule type has changed
		if(schedule_type !== scheduleType)
		{
			let change_schedule_type = false;
			
			// If there was already a schedule type then warn the user of changes
			// Else, this is first time setting schedule type, so just save and move on
			if(scheduleType)
			{
				const options =  {
					title: "Are you sure?",
					text: "Changing the schedule type will delete your current schedule structure and invalidate any schedules you've created.",
					icon: "warning",
					dangerMode: true,
					buttons: {
						cancel: {
							text: "Cancel",
							value: false,
							visible: true,
							className: 'gray-btn'
						},
						confirm: {
							text: "Yes",
							value: true,
							visible: true,
							className: 'red-btn'
						},
					}
				}
				
				change_schedule_type = await swal(options);
			}
			else
			{
				change_schedule_type = true;
			}
				
			if(change_schedule_type)
			{
				// Update fronten/state
				setScheduleInfo({...scheduleInfo, schedule_type:schedule_type});
				sessionStorage.clear();

				// Save schedule type in database
				const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, schedule_type: schedule_type};
				await saveScheduleType(data);

				// Update conflicts
				const conflicts_to_check = ['course_period_restriction', 'teacher_period_restriction', 'teacher_double_booked', 'classroom_double_booked', 'student_double_booked', 'teacher_conflicting_periods', 'classroom_conflicting_periods', 'student_conflicting_periods', 'teacher_too_many_sections_in_a_row'];
				calculateConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, conflicts_to_check:conflicts_to_check, update_all:true});

				// Recalculate match percent
				recalculateMatchPercent({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id});
			}
		}

		handleMoveToNextScreen();
	}
	
	useEffect(() => {
		if(!scheduleInfoLoading) setIsLoading(false);
	}, [scheduleInfoLoading]);
	
	return (
		<>
		{isLoading ?
			(
				<div className='center-text'>
					<img src={require("../../../images/balls.gif")} alt='loading...' style={{height:'40px'}}/>
				</div>
			):(subscreenRestricted) ?
			(
				<div className='general-screen-message'>
					<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
					<h1>No Access</h1>
					<div>You do not have access to this screen</div>	
				</div>
			):(subscreenLocked) ?
			(
				<div className='general-screen-message'>
					<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
					<h1>Temporarily Locked</h1>
					<div>This screen is locked until all other prior steps are completed</div>	
				</div>
			):
			(
				<>
				<h1 className='progress-main-header'>Schedule Type</h1>
				<p className='progress-subheader'>What type of schedule does your school have?</p>
				<div id='schedule-type-option-container'>
					{Object.keys(schedule_types).map((schedule_type, i) => {
						const schedule_type_name = schedule_types[schedule_type].name;
						const schedule_type_explantion = schedule_types[schedule_type].explanation;
						const schedule_type_img = schedule_types[schedule_type].img;
						const selected = (scheduleInfo.schedule_type === schedule_type) ? true : false;
						const classes = (selected) ? ['schedule-structure-option-select-btn schedule-structure-option-select-btn-selected'] : ['schedule-structure-option-select-btn'];
						
						return (
							<div className="schedule-structure-option" key={i}>
								<h1 className="schedule-structure-option-title">{schedule_type_name}</h1>
								{schedule_type_img &&
									<div className='full-width text-align-center'>
										<img className='schedule-structure-option-img' src={schedule_type_img} alt={`${schedule_type} img`} />
									</div>
								}
								<div>{schedule_type_explantion}</div>
								<div style={{position:'relative'}}>
									<Button classes={classes} selected={selected} text='Select' onClickFunction={() => onScheduleTypeSelect(schedule_type)} />
									{selected &&
										<img className="btn-selected-checkmark" alt='check mark' src={require("../../../images/check_mark.png")} />
									}
									<div className='clear'></div>
								</div>
							</div>
						)
					})}
				</div>
				</>
			)
		}
		</>
	);
}