import React, { useState , useEffect, useRef, useCallback } from 'react';
import swal from '@sweetalert/with-react';

import { filterArrayOfObjects, deleteData, sortArrayOfObjects } from '../../../js/Helpers';

import SortToggle from '../../SortToggle';
import UploadModal from '../../UploadModal';
import EditStudentData from '../modals/edit_student';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEdit, faPlus, faUpload, faMinusSquare, faSortDown, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt, faSquare as farSquare } from '@fortawesome/free-regular-svg-icons';

export default function DatabaseDataStudents({ schoolInfo, databaseData, setDatabaseData, scheduleStructureData, scheduleInfo, courses, teachers, departments, sections, labels, dataIsLoading, handlePullNewData }) {
	const [isLoading, setIsLoading] = useState(true);
	const [handleChangeComplete, setHandleChangeComplete] = useState(false);

	const [displayResults, setDisplayResults] = useState([]);
	const [isSearching, setIsSearching] = useState(false);
	
	const [showEditDataModal, setShowEditDataModal] = useState(false);
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [currentDataID, setCurrentDataID] = useState(null);
	
	const [selectedData, setSelectedData] = useState([]);
	const [dontShowDeletePopup, setDontShowDeletePopup] = useState(false);

	const [sortedBy, setSortedBy] = useState('last_name');
	const [sortAscDesc, setSortAscDesc] = useState('asc');
	const [dataSorted, setDataSorted] = useState(false);
	
	const [maxStudentsToShow, setMaxStudentsToShow] = useState(50);

	const databaseDataRef = useRef(databaseData);
	databaseDataRef.current = databaseData;
	
	const handleIsSearching = (e) => {
		const search_value = e.target.value;
		
		if(search_value.length > 1)
		{
			if(e.keyCode !== 8)
			{
				setIsLoading(false);
				setIsSearching(true);
			}
			else
			{
				setIsLoading(true);
				setIsSearching(false);
			}
		}
		else
		{
			setIsLoading(false);
			setIsSearching(false);
		}
	}
	
	const handleChange = useCallback(() => {
		setMaxStudentsToShow(50);
		
		if(databaseDataRef.current.length === 0 && dataIsLoading) return;
		
		const search_value = document.getElementsByClassName("school-data-search-bar")[0].value;
		if(search_value.length > 1)
		{
				const filtered_results = filterArrayOfObjects(databaseDataRef.current, search_value, ['last_name', 'first_name', 'student_code']);
				setDisplayResults([...filtered_results]);
		}
		else
		{
			setDisplayResults([...databaseDataRef.current]);
		}

		setIsLoading(false);
		setIsSearching(false);
		setHandleChangeComplete(true);
	},[databaseDataRef, dataIsLoading])
	
	const showMoreStudents = () => {
		const num_students_to_show = maxStudentsToShow + 50;
		setMaxStudentsToShow(num_students_to_show);
	}
	
	const handleSortClick = (sortedData, sorted_by, sort_asc_desc) => {
		setSortedBy(sorted_by);
		setSortAscDesc(sort_asc_desc);
		setDisplayResults([...sortedData]);
	}
	
	const toggleEditData = (dataID) => {
		setDataSorted(false);
		setCurrentDataID(dataID);
		setShowEditDataModal(!showEditDataModal);
	}
	
	const toggleDontShow = () => {
		setDontShowDeletePopup(!dontShowDeletePopup);
	}

	const toggleSelectData = (data_id) => {
		const data_selected_index = selectedData.findIndex(data => data === data_id);

		if(data_selected_index !== -1)
		{
			selectedData.splice(data_selected_index, 1);
		}
		else
		{
			selectedData.push(data_id);
		}

		setSelectedData([...selectedData]);
	}

	const toggleSelectAll = () => {
		const any_data_selected = selectedData.length > 0;
		
		let new_selected_data = [];

		if(!any_data_selected) 
		{
			new_selected_data = displayResults.reduce((results, student) => {
				const student_id = student.student_id;
				results.push(student_id);
				return results;
			}, []);
		}

		setSelectedData(new_selected_data);
	}
	
	const handeDeleteData = async (data_ids) => {
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'student', data_ids:data_ids};
		let delete_data_bool = true;
		
		if(!dontShowDeletePopup)
		{
			const options =  {
				title: "Are you sure?",
				icon: "warning",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Cancel",
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "Yes",
						value: true,
						visible: true,
						className: 'red-btn'
					},
				},
				content: (
					<div>
						<div>
							<p>Do you really want to delete the following students:</p>
							<div style={{marginLeft:'50px', textAlign:'left'}}>
								{data_ids.map((data_id, i) => {
									const data_index = databaseData.findIndex(student => student.student_id === data_id);
									const data_info = databaseData[data_index];

									return (
										<div key={i}>{data_info.first_name} {data_info.last_name}</div>
									)
								})}
							</div>
							<p className='red-text'>This will <strong>permanently</strong> remove them from the database.</p>
						</div>
						<div className='sweet-alert-dont-show-message' onClick={() => toggleDontShow('remove')}>
							<label className="checkmark_container">Please don&rsquo;t show me this message again
								<input type="checkbox" />
								<span className="checkmark"></span>
							</label>
						</div>
					</div>
				)
			}
		
			delete_data_bool = await swal(options);
		}
		
		if(delete_data_bool)
		{
			const new_database_data = databaseData.filter(data => !data_ids.includes(data.student_id));
			setDataSorted(false);
			setDatabaseData(new_database_data);
			
			deleteData(data);
		}
	}
	
	useEffect(() => {
		if(!showEditDataModal) setCurrentDataID(null);
		const search_value = document.getElementsByClassName("school-data-search-bar")[0].value;
		if(search_value.length > 0) handleChange();
	}, [showEditDataModal, handleChange]);
	
	useEffect(() => {
		handleChange();
	}, [databaseData, dataIsLoading, handleChange]);

	useEffect(() => {
		const sortData = (sort_type = null) => {
			let sorted_data = sortArrayOfObjects(displayResults, sortedBy, sort_type, sortAscDesc);
	
			setDataSorted(true);
			setDisplayResults([...sorted_data]);
		}

		if(dataSorted || displayResults.length <= 0) return;
		const sort_type = (sortedBy === 'grade') ? 'number' : null;
		sortData(sort_type);
	}, [displayResults, dataSorted, sortedBy, sortAscDesc]);
	
	let student_counter = 1;
	const row_style = {gridTemplateColumns:'35px 2fr 2fr 2fr 1fr 1fr 70px'};
	
	return (
		<>
		{showEditDataModal &&
			<EditStudentData schoolInfo={schoolInfo} dataID={currentDataID} toggleEditData={toggleEditData} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} students={databaseData} courses={courses} teachers={teachers} departments={departments} sections={sections} labels={labels} setDatabaseData={setDatabaseData}/>
		}
		{showUploadModal &&
			<UploadModal schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} uploadTitle='Students' uploadAPIName='studentdata' closeModal={() => setShowUploadModal(false)} handlePullNewData={handlePullNewData}/>
		}
		<div className='school-data-content-screen'>
			<h1 className='school-data-main-heading capitalize'>Students</h1>
			<div className='school-data-top-bar'>
				<div className='school-data-search-bar-container'>
					<input className='school-data-search-bar' placeholder="Search for students..." onChange={handleChange} onKeyDown={(e) => handleIsSearching(e)} />
					<FontAwesomeIcon className='school-data-search-bar-icon' icon={faSearch}/>
				</div>
				<div className='btn btn-icon-left turquoise-btn' onClick={() => setShowUploadModal(true)}>
					<FontAwesomeIcon icon={faUpload} />
					<div>Upload</div>
				</div>
			</div>
			<div className='school-data-database-display'>
				<div className='school-data-database-row school-data-database-display-header fixed-heading-on-scroll' style={row_style}>
					<div></div>
					<div className='school-data-database-header-col'>Code</div>
					<div className='school-data-database-header-col'>Last Name <SortToggle handleOnClick={handleSortClick} sortKey='last_name' sortType='text' passedData={databaseData}/></div>
					<div className='school-data-database-header-col'>First Name <SortToggle handleOnClick={handleSortClick} sortKey='first_name' sortType='text' passedData={databaseData}/></div>
					<div className='school-data-database-header-col'>Grade <SortToggle handleOnClick={handleSortClick} sortKey='grade' sortType='text' passedData={databaseData}/></div>
					<div className='school-data-database-header-col'>Gender <SortToggle handleOnClick={handleSortClick} sortKey='gender' sortType='text' passedData={databaseData}/></div>
					<div className='school-data-database-add-new-container' onClick={() => toggleEditData(null)}>
						<div className='school-data-database-add-new-btn'>
							<FontAwesomeIcon className='white-text' icon={faPlus}/>
						</div>
					</div>
				</div>
				{displayResults.length > 0 &&
					<div className='select-all-row'>
						<div className={`select-all-container ${selectedData.length > 0 && 'blue-text'}`} onClick={toggleSelectAll}>
							<FontAwesomeIcon icon={selectedData.length > 0 ? faMinusSquare : farSquare}/>
							<FontAwesomeIcon className='select-all-arrow' icon={faSortDown}/>
						</div>
						{selectedData.length > 0 &&
							<div className='small-text'>
								<div className='tooltip' data-tooltip-text='Delete students' onClick={() => handeDeleteData(selectedData)}>
									<div className='red-link'>Delete all selected</div>
								</div>
							</div>
						}
					</div>
				}
				{(!handleChangeComplete && isLoading) ?
				(
					<div className='school-data-database-message-container'>
						<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
					</div>
				): isSearching ?
				(
					<div className='school-data-database-message-container'>
						<img src={require('../../../images/searching.gif')} alt='searching...' style={{height:'80px'}}/>
					</div>
				):
				(
					<>
					{displayResults.length === 0 &&
						<div className='school-data-database-message-container'>
							<h4>Sorry, no results were found!</h4>
						</div>
					}
					{displayResults.map((data_info, index) => {
						if(student_counter > maxStudentsToShow) return null;
						student_counter++;

						const student_id = data_info.student_id;
						const data_selected = selectedData.includes(student_id);
						
						return (
							<div className='school-data-database-row' style={row_style} key={data_info.student_id}>
								<FontAwesomeIcon className={data_selected ? 'blue-text' : 'dark-gray-text'} icon={data_selected ? faCheckSquare : farSquare} onClick={() => toggleSelectData(student_id)}/>
								<div className='medium-text'>{data_info.student_code}</div>
								<div>{data_info.last_name}</div>
								<div>{data_info.first_name}</div>
								<div className='medium-text'>{data_info.grade}</div>
								<div className='medium-text'>{data_info.gender}</div>
								<div className='school-data-database-row-btn-container'>
									<FontAwesomeIcon className='gray-to-dark-blue-link' icon={faEdit} onClick={() => toggleEditData(student_id)}/>
									<FontAwesomeIcon className='gray-to-red-link' icon={faTrashAlt} onClick={() => handeDeleteData([student_id])}/>
								</div>
							</div>
						)
					})}
					</>
				)}
			</div>
			{(student_counter > maxStudentsToShow) && 
				<div className='btn blue-btn load-more-btn' onClick={showMoreStudents}>Load More Students</div>
			}
		</div>
		</>
	);
}