import React, { useState, useEffect, useRef, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { API } from "aws-amplify";
import { useReactToPrint } from 'react-to-print';

import Fuse from 'fuse.js';
import swal from '@sweetalert/with-react';

import Dropdown from '../Dropdown';

import EditBlock from './modals/edit_block';
import EditTeacherData from './modals/edit_teacher';
import EditClassroomData from './modals/edit_classroom';
import EditStudentData from './modals/edit_student';

import { getJSONFromStorage, getData, getBlockConnections, formatTime, useOutsideClick, sortArrayOfObjects, cloneObj } from '../../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSearch, faTimes, faChevronDown, faChevronUp, faChalkboard, faUserGraduate, faEdit, faChevronRight, faLock, faCalendarDay, faChalkboardTeacher, faGraduationCap, faSlidersH, faCheckSquare, faSquare, faCircleExclamation, faUnlock} from '@fortawesome/free-solid-svg-icons';

import '../../css/magnetboard.css';

export default function MagnetboardElementary({ schoolSubscribed, schoolInfoLoading, schoolInfo, scheduleInfo, scheduleInfoLoading, periodsLoading, periods, teachersLoading, teachers, setTeachers, classroomsLoading, classrooms, setClassrooms, studentsLoading, students, setStudents, coursesLoading, courses, setCourses, blocksLoading, blocks, setBlocks, magnetboardOptions = {show_top_bar:true, allow_filtering:true, show_conflicts:true, show_menu:true, allowed_teacher_ids:null, allowed_classroom_ids:null, view_options:[{value:'teacher', display:'Teacher View'}, {value:'classroom', display:'Classroom View'}, {value:'student', display:'Student View'}]} }) {

	/////////////////////////////
	///// LOADING VARIABLES /////
	/////////////////////////////
	const [isLoading, setIsLoading] = useState(true);

	const group_positions = {
		'1':{'0':{start:'1', end:'60'}}, 
		'2':{'0':{start:'1', end:'28'},'1':{start:'32', end:'60'}}, 
		'3':{'0':{start:'1', end:'18'},'1':{start:'21', end:'39'},'2':{start:'42', end:'60'}}, 
		'4':{'0':{start:'1', end:'10'},'1':{start:'17', end:'27'},'2':{start:'33', end:'43'},'3':{start:'50', end:'60'}}
	};
	
	//////////////////////////
	///// DATA VARIABLES /////
	//////////////////////////
	const [blockData, setBlockData] = useState(null);
	const [currentView, setCurrentView] = useState('teacher');
	const [displayData, setDisplayData] = useState([]);
	const [openDisplayData, setOpenDisplayData] = useState([]);
	const [searchValue, setSearchValue] = useState('');

	const [filterDataVisible, setFilterDataVisible] = useState(false);
	const [filterOptions, setFilterOptions] = useState({});
	const [openFilterOptions, setOpenFilterOptions] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [filterIDs, setFilterIDs] = useState([]);

	const [sidePanelClosing, setSidePanelClosing] = useState(false);
	const [displayCreated, setDisplayCreated] = useState(false);

	const [conflicts, setConflicts] = useState([]);
	const [openConflicts, setOpenConflicts] = useState([]);

	const [dataEditingID, setDataEditingID] = useState(null);
	const [showEditDataModal, setShowEditDataModal] = useState(false);

	const [addNewBlockIsOpen, setAddNewBlockIsOpen] = useState(false);
	const [addBlockOpenDataID, setAddBlockOpenDataID] = useState(null);
	const [addBlockOpenDayNumber, setAddBlockOpenDayNum] = useState(null);

	const [currentOpenMenu, setCurrentOpenMenu] = useState(null);

	const [maxTeachersToShow, setMaxTeachersToShow] = useState(10);
	const [maxStudentsToShow, setMaxStudentsToShow] = useState(50);
	const [days, setDays] = useState([]);

	///////////////////
	///// USE REF /////
	///////////////////
	const menu_ref = useRef();
	const filter_ref = useRef();
	const add_new_block_ref = useRef();
	const printRef = useRef();

	useOutsideClick(add_new_block_ref, () => {
		setAddNewBlockIsOpen(false);
		setAddBlockOpenDataID(null);
		setAddBlockOpenDayNum(null);
	}, ['mb-add-new-section']);

	useOutsideClick(menu_ref, () => {
		setCurrentOpenMenu(null);
	});

	useOutsideClick(filter_ref, () => {
		setFilterDataVisible(false);
	});

	//////////////////////////
	///// MENU FUNCTIONS /////
	//////////////////////////
	const toggleMenuItem = (menu_item) => {
		let current_menu_item = null;

		if(menu_item !== currentOpenMenu) current_menu_item = menu_item;

		setCurrentOpenMenu(current_menu_item);
	}

	const toggleFilterOptions = () => {
		setFilterDataVisible(!filterDataVisible);
	}
	
	const updateFilterOptions = (filter_title, option_index, selected) => {
		filterOptions[filter_title][option_index]['selected'] = selected;
		setFilterOptions({...filterOptions});
	}

	const updateOpenFilters = (filter_title) => {
		const filter_index = openFilterOptions.findIndex(filter => filter === filter_title);
		
		if(filter_index !== -1)
		{
			openFilterOptions.splice(filter_index, 1);
		}
		else
		{
			openFilterOptions.push(filter_title);
		}

		setOpenFilterOptions([...openFilterOptions]);
	}

	// Handle Dropdown Hovering 
	const handleDropdownHoverShow = (action_type) => {
		// Hide all other submenus
		const all_submenus = document.querySelectorAll('.mb-dropdown-options-subcontainer');
		for(var i = 0; i < all_submenus.length; i++)
		{
			all_submenus[i].classList.add('hide');
		}

		// Show the current submenu
		const parent_menu = document.querySelector('.mb-dropdown-option-hover[data-actiontype="' + action_type + '"]');
		const parent_menu_top_position = parent_menu.offsetTop + 42;

		const submenu = document.querySelector('.mb-dropdown-options-subcontainer[data-actiontype="' + action_type + '"]');
		if(submenu) 
		{
			submenu.style.top = parent_menu_top_position + "px";
			submenu.classList.remove("hide");
		}
	}

	const handleDropdownHoverHide = (action_type) => {
		const submenu = document.querySelector('.mb-dropdown-options-subcontainer[data-actiontype="' + action_type + '"]');

		if(submenu) submenu.classList.add("hide");
	}

	const openPrint = useReactToPrint({
		content: () => printRef.current,
		pageStyle: `
			@media print {
				@page { 
					size: 11in 8.5in; 
					margin: 20mm;
				}

				html, body {
					height: initial !important;
					overflow: initial !important;
					-webkit-print-color-adjust: exact;
				}

				.mb-elem-teacher-schedule {
					margin:0px 0px 0px 0px !important;
				}

				.mb-elem-week-schedule-container {
					margin:0px 0px 0px 0px !important;
				}

				.mb-elem-week-day-header {
					margin:0px !important;
					font-size:12px !important;
				}
				
				.mb-hide-during-print {
					display:none !important;
					height:0 !important;
				}

				.page-break {
					margin-top: 1rem;
					display: block;
					page-break-before: always;
				}
			}
		`,
	});
	
	/////////////////////////////////
	///// MAGNETBOARD FUNCTIONS /////
	/////////////////////////////////
	const createDisplaySchedule = useCallback(() => {
		setIsLoading(true);

		// Organize schedules for display
		let display_schedule = [];
		let filter_options = {};
		
		if(currentView === 'teacher')
		{
			const sorted_teachers = sortArrayOfObjects(teachers, 'name', 'text', 'asc');
			filter_options = {
				'teachers':[], 
				'type':[
					{display:"Homeroom Teacher", value:"is_homeroom"}, 
					{display:"Specials Teacher", value:"is_specials"}, 
					{display:"Pullout Teacher", value:"is_pullout"}
				]};

			for(const teacher of sorted_teachers)
			{
				const teacher_id = teacher.teacher_id;
				const teacher_last_name = teacher.name;
				const teacher_first_name= teacher.first_name;
				const teacher_display_name = `${teacher_last_name}, ${teacher_first_name}`;

				filter_options['teachers'].push({display:teacher_display_name, value:teacher_id});

				display_schedule.push({data_id:teacher_id, display_name:teacher_display_name, display_info:teacher});
			}
		}
		else if(currentView === 'classroom')
		{
			const sorted_classrooms = sortArrayOfObjects(classrooms, 'classroom_name', 'text', 'asc');

			filter_options = {
				'classrooms':[]
			};

			for(const classroom of sorted_classrooms)
			{
				const classroom_id = classroom.classroom_id;
				const classroom_name = classroom.classroom_name;

				filter_options['classrooms'].push({display:classroom_name, value:classroom_id});
				display_schedule.push({data_id:classroom_id, display_name:classroom_name, display_info:classroom});
			}
		}
		else if(currentView === 'student')
		{
			filter_options = {
				'grades':[], 
				'homerooms': []
			}

			const grades = students.reduce((result, student) => {
				const student_grade = student.grade;
				if(student_grade && result.findIndex(option => option.value === student_grade) === -1) result.push({display:student_grade, value:student_grade, selected:false});
				return result;
			}, []);
			const sorted_grades = sortArrayOfObjects(grades, 'value', 'number', 'asc');

			const homeroom_teachers = teachers.reduce((result, teacher) => {
				const teacher_id = teacher.teacher_id;
				const teacher_first_name = teacher.first_name;
				const teacher_last_name = teacher.name;
				const teacher_name = `${teacher_last_name}, ${teacher_first_name}`;
				const is_homeroom_teacher = teacher.is_homeroom_teacher;
				if(is_homeroom_teacher === '1') result.push({display:teacher_name, value:teacher_id, selected:false})
				return result;
			}, []);
			const sorted_homeroom_teachers = sortArrayOfObjects(homeroom_teachers, 'display', 'text', 'asc');

			filter_options['grades'].push(...sorted_grades);
			filter_options['homerooms'].push(...sorted_homeroom_teachers);

			for(const student of students)
			{
				const student_id = student.student_id;
				const student_first_name = student.first_name;
				const student_last_name = student.last_name;
				const display_name = `${student_last_name}, ${student_first_name}`;
				display_schedule.push({data_id:student_id, display_name:display_name, display_info:student});
			}
		}

		setDisplayData([...display_schedule]);
		setIsLoading(false);
		setDisplayCreated(true);
		setFilterOptions({...filter_options});
	},[currentView, teachers, classrooms, students])

	const toggleOpenConflicts = (data_id) => {
		const data_index = openConflicts.findIndex(display_data_id => display_data_id === data_id);
		if(data_index !== -1)
		{
			openConflicts.splice(data_index, 1);
		}
		else
		{
			openConflicts.push(data_id);
		}
		setOpenConflicts([...openConflicts]);
	}

	const handleToggleAddNewBlock = (data_id = null, day_number = null) => {
		if(data_id && day_number && (data_id === addBlockOpenDataID) && (day_number === addBlockOpenDayNumber))
		{
			setAddBlockOpenDataID(null);
			setAddBlockOpenDayNum(null);
			setAddNewBlockIsOpen(false);
		}
		else
		{
			setAddBlockOpenDataID(data_id);
			setAddBlockOpenDayNum(day_number);
			setAddNewBlockIsOpen(true);
		}
	}

	const handleToggleOpenDisplayData = (data_id) => {
		const display_data_index = openDisplayData.findIndex(display_data_id => display_data_id === data_id);
		if(display_data_index !== -1)
		{
			openDisplayData.splice(display_data_index, 1);
		}
		else
		{
			openDisplayData.push(data_id);
		}
		setOpenDisplayData([...openDisplayData]);
	}

	const toggleEditData = (dataEditingID) => {
		setDataEditingID(dataEditingID);
		setShowEditDataModal(!showEditDataModal);
	}
	
	const changeCurrentView = (new_view) => {
		setIsLoading(true);
		setFilterIDs([]);

		setCurrentView(new_view);
		sessionStorage.setItem('current_view', new_view);
	}

	const createNewBlock = (block_type = null, teacher_id = null, day_number = null) => {
		handleToggleAddNewBlock();
		toggleEditBlock('new', block_type, teacher_id, day_number);
	}

	const toggleEditBlock = (block_id, block_type = null, teacher_id = null, day_number = null) => {
		let block_data;

		if(!block_id)
		{
			block_data = null;
		}
		else if(block_id === 'new')
		{
			const is_lunch = (block_type === 'lunch') ? '1' : '0';
			const is_pullout = (block_type === 'pullout') ? '1' : '0';
			const is_special = (block_type === 'special') ? '1' : '0';
			const homeroom_teacher_id = (is_pullout !== '1') ? teacher_id : null;
			const actual_teacher_id = (block_type === 'special') ? null : teacher_id;
			const actual_teacher_info = (actual_teacher_id) ?  teachers.find(teacher => teacher.teacher_id === actual_teacher_id) : null;
			const secondary_teachers = [];
			const classroom_id = (actual_teacher_info) ? actual_teacher_info.homeroom_classroom_id : null;
			const student_list = (is_pullout === '0') ? students.reduce((results, student) => {
				if(student.homeroom_teacher_id && student.homeroom_teacher_id === teacher_id) results.push(student.student_id);
				return results;
			}, []) : [];

			block_data = {
				'new': true,
				'block_id': null,
				'classroom_id': classroom_id,
				'course_id':null,
				'homeroom_teacher_id': homeroom_teacher_id,
				'is_lunch': is_lunch,
				'is_pullout': is_pullout,
				'is_special': is_special,
				'start_time': '00:00:00',
				'end_time': '00:00:00',
				'teacher_id' : actual_teacher_id,
				'day_number' : `${day_number}`,
				'student_list' : student_list,
				'locked': '0',
				'secondary_teachers': secondary_teachers
			}
		}
		else
		{
			block_data = blocks.find(block => block.block_id === block_id);
		}

		setBlockData(block_data);
	}

	const overlapCheck = (time1, time2) => {
		const start_time1 = time1.start_time;
		const end_time1 = time1.end_time;
		const start_time1_array = start_time1.split(':');
		const end_time1_array = end_time1.split(':');

		const start_time1_number = parseInt(start_time1_array[0] + start_time1_array[1]);
		const end_time1_number = parseInt(end_time1_array[0] + end_time1_array[1]);

		const start_time2 = time2.start_time;
		const end_time2 = time2.end_time;
		const start_time2_array = start_time2.split(':');
		const end_time2_array = end_time2.split(':');

		const start_time2_number = parseInt(start_time2_array[0] + start_time2_array[1]);
		const end_time2_number = parseInt(end_time2_array[0] + end_time2_array[1]);

		if(end_time1_number > start_time2_number && end_time2_number > start_time1_number) return true;

		return false;
	}

	const calculateBlockColumnStartEnd = (block_data, teacher_day_schedule, logged_positions, start_time, end_time) => {
		const block_id = block_data.block_id;

		///// Calculate left and right position
		// Get max number of overlaps
		const block_time = {block_id:block_id, start_time:start_time, end_time:end_time};

		let overlapping_time_groups = [[block_time]];
		for(var i = 0; i < teacher_day_schedule.length; i++)
		{
			const new_block = teacher_day_schedule[i];
			const new_block_id = new_block.block_id;

			if(block_id === new_block_id) continue; //ignore the original block itself

			const new_time = {block_id:new_block_id, start_time:new_block.start_time, end_time:new_block.end_time};
			const new_time_overlaps_original = overlapCheck(block_time, new_time);

			if(!new_time_overlaps_original) continue; //ignore blocks that don't even overlap original

			// Go through all overlap groups and see if new block overlaps all of the times in the current groups
			// If yes, add to that group
			// If no, create a new group
			for(var j = 0; j < overlapping_time_groups.length; j++)
			{
				const overlapping_time_group = overlapping_time_groups[j];

				// Ignore a block that has already been added to this group
				const new_time_index = overlapping_time_group.findIndex(overlapping_time => overlapping_time.block_id === new_block_id);
				if(new_time_index !== -1) continue;

				let new_time_overlaps_entire_group = true;
				
				for(var k = 0; k < overlapping_time_group.length; k++)
				{
					const time_to_check = overlapping_time_group[k];
					const time_overlaps = overlapCheck(time_to_check, new_time);

					if(!time_overlaps) new_time_overlaps_entire_group = false;
				}

				if(new_time_overlaps_entire_group)
				{
					overlapping_time_groups[j].push(new_time);
				}
				else
				{
					overlapping_time_groups.push([block_time, new_time]);
				}
			}
		}

		const overall_max_stats = overlapping_time_groups.reduce((results, overlapping_time_group) => {
			const overlapping_time_group_length = overlapping_time_group.length;
			if(overlapping_time_group_length > results.max_overlaps) results = {max_overlaps: overlapping_time_group_length, overlapping_blocks:overlapping_time_group};
			return results;
		}, {max_overlaps:0, overlapping_blocks:[]});

		/// Use max overlaps to get the possible positions (preset depending on number of overlaps)
		const max_overlaps = overall_max_stats.max_overlaps;
		const overlapping_blocks = overall_max_stats.overlapping_blocks;

		const possible_positions = group_positions[max_overlaps];
		const possible_positions_indexes = Object.keys(possible_positions);
		const used_positions = overlapping_blocks.reduce((results, block_times) => {
			const overlapping_block_id = block_times.block_id;
			const used_position = (overlapping_block_id in logged_positions) ? logged_positions[overlapping_block_id] : null;
			if(used_position) results.push(used_position);
			return results;
		},[]);
		const unused_positions = possible_positions_indexes.filter(x => used_positions.indexOf(x) === -1);
		const position_index = unused_positions[0];
		const start_position = possible_positions[position_index].start;
		const end_position = possible_positions[position_index].end;

		return {position_index:position_index, col_start:start_position, col_end:end_position};
	}

	const toggleLockBlock = async (block_id, locked) => {
		// Update front end
		const block_index = blocks.findIndex(block => block.block_id === block_id);
		if(block_index !== -1) blocks[block_index].locked = locked;
		setBlocks([...blocks]);

		// Update backend
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, block_id:block_id, locked:locked};
		const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
		const url = '/elementary/update-lock-block';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			await API.post(apiName, url, myInit);
		} catch(e)
		{
			console.log(e.response);
		}
	}

	const toggleLockStudentBlock = async (student_id, block_id, locked) => {
		// Update front end
		const student_index = students.findIndex(student => student.student_id === student_id);
		if(student_index !== -1)
		{
			const student_locked_block_index = students[student_index].student_locked_blocks.findIndex(student_locked_block => student_locked_block === block_id);
			if(locked === '0' && student_locked_block_index !== -1) students[student_index].student_locked_blocks.splice(student_locked_block_index, 1);
			if(locked === '1' && student_locked_block_index === -1) students[student_index].student_locked_blocks.push(block_id);
		}
		setStudents([...students]);

		// Update backend
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, student_id:student_id, block_id:block_id, locked:locked};
		const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
		const url = '/elementary/update-lock-student-block';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			await API.post(apiName, url, myInit);
		} catch(e)
		{
			console.log(e.response);
		}
	}

	const pullNewBlocks = (_callback = ()=>{}) => {
		getData('blocks', '/elementary/get-blocks', {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true, 'elementary').then(returned_data => {

			setBlocks(returned_data);
			_callback();
		});
	}

	const removeBlock = async (block_id, e = null) => {
		if(e && e.stopPropagation) e.stopPropagation();

		const options =  {
			title: "Are you sure?",
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			},
			content: (
				<div>
					<p>Are you sure you want to remove this block?</p>
				</div>
			)
		}

		if(await swal(options))
		{
			/// Update the FRONTEND ///
			const new_blocks = blocks.filter(block => block.block_id !== block_id);
			setBlocks([...new_blocks]);
			toggleEditBlock(null);

			/// Update the BACKEND ///
			const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, block_id:block_id};
			const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
			const url = '/elementary/delete-block';
			const myInit = { // OPTIONAL
				response: true,
				body: JSON.stringify(data),
			};
		
			try {
				// Delete block in database
				await API.post(apiName, url, myInit);
			} catch(e)
			{
				console.log(e.response);
			}
		}
	}

	const removeStudentBlock = async (student_id, block_id, e = null) => {
		if(e && e.stopPropagation) e.stopPropagation();

		const options =  {
			title: "Are you sure?",
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			},
			content: (
				<div>
					<p>Are you sure you want to remove this block from student's schedule?</p>
				</div>
			)
		}

		if(await swal(options))
		{
			/// Update the FRONTEND ///
			const block_index = blocks.findIndex(block => block.block_id === block_id);
			if(block_index !== -1)
			{
				const student_index = blocks[block_index].student_list.findIndex(student => student === student_id);
				if(student_index !== -1) blocks[block_index].student_list.splice(student_index, 1);
			}
			setBlocks([...blocks]);

			/// Update the BACKEND ///
			const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, student_id:student_id, block_id:block_id};
			const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
			const url = '/elementary/remove-student-block';
			const myInit = { // OPTIONAL
				response: true,
				body: JSON.stringify(data),
			};
		
			try {
				// Delete block in database
				await API.post(apiName, url, myInit);
			} catch(e)
			{
				console.log(e.response);
			}
		}
	}

	const clearBlocks = async () => {	
		const options =  {
			title: "Are you sure?",
			text: 'This will remove all blocks from the schedule (that are not locked).',
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			},
			content: (
				<div className='sweet-alert-dont-show-message' style={{padding:'20px 10px 25px 10px'}}>
					<div className='dark-blue-text' style={{textAlign:'left',width:'340px',margin:'auto'}}>
						<div><strong>Pro Tip:</strong></div>
						<div>If you want to keep certain blocks where they are, make sure to lock that block (by clicking the <FontAwesomeIcon className='gray-text' icon={faLock}/> icon on the block) before clearing!</div>
					</div>
				</div>
			)
		}
		
		await swal(options).then(async (return_value) => {
			if(return_value)
			{
				// Update front end
				const new_blocks = blocks.filter(block => block.locked === '1');
				setBlocks(new_blocks);

				// Send to Backend ///
				const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id};
				const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
				const url = '/elementary/clear-blocks';
				const myInit = { // OPTIONAL
					response: true,
					body: JSON.stringify(data),
				};
			
				try {
					// Clear blocks
					await API.post(apiName, url, myInit);

					const success_options =  {
						icon:"success",
						title: "Success!",
						text: "All non-locked blocks have been cleared!",
					}
					swal(success_options);
				} catch(e)
				{
					const failure_options =  {
						icon:"error",
						title: "Error Clearing Blocks",
						text: "We had an issue clearing your blocks. Please try again.",
						dangerMode: true,
					}

					swal(failure_options);
					console.log(e.response);
				}
			}
		});
	}

	//// Specials Functions ////
	const autoPlaceSpecials = async () => {
		const options =  {
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Start Placing Specials",
					value: true,
					visible: true,
					className: 'blue-btn'
				},
			},
			content: (
				<div>
					<FontAwesomeIcon className='modal-top-icon blue-text' icon={faCalendarDay}/>
					<h1 className='swal-title' style={{marginTop:'0px'}}>Place Specials</h1>
					<p className='swal-text'>This will try to place all your unplaced specials!</p>
				</div>
				
			)
		}

		await swal(options).then(async (return_value) => {
			if(return_value)
			{
				const placing_in_progress_options =  {
					button:false,
					closeOnClickOutside: false,
					closeOnEsc: false,
					content: (
						<div>
							<FontAwesomeIcon className='modal-top-icon placing-students-in-progress-icon' icon={faCalendarDay}/>
							<h1 className='swal-title' style={{marginTop:'0px'}}>Placing Specials in Progress</h1>
							<p className='swal-text'>Please wait, this may take a few minutes...</p>
							<div className='sweet-alert-dont-show-message' style={{padding:'20px 10px 25px 10px'}}>
								<div className='red-text'>Do not refresh the page until this process is complete!</div>
							</div>
						</div>
					)
				}

				swal(placing_in_progress_options);

				/// Update the BACKEND ///
				const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id};
				const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
				const url = '/elementary/auto-place-specials';
				const myInit = { // OPTIONAL
					response: true,
					body: JSON.stringify(data),
				};
			
				try {
					await API.post(apiName, url, myInit);

					// Pull new blocks and then move to next screen
					pullNewBlocks();

					const success_options =  {
						icon:"success",
						title: "Success!",
						text: "Your specials should now be placed.",
					}
					swal(success_options);
					
				} catch(error)
				{
					const failure_options =  {
						icon:"error",
						title: "Error Placing Specials",
						text: "We had an issue placing your specials. Please try again.",
						dangerMode: true,
					}

					swal(failure_options);
					console.log(error.response);
				}
			}
		});
	}

	const clearSpecials = async () => {	
		const options =  {
			title: "Are you sure?",
			text: 'This will remove all specials from the schedule (that are not locked).',
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			},
			content: (
				<div className='sweet-alert-dont-show-message' style={{padding:'20px 10px 25px 10px'}}>
					<div className='dark-blue-text' style={{textAlign:'left',width:'340px',margin:'auto'}}>
						<div><strong>Pro Tip:</strong></div>
						<div>If you want to keep certain specials where they are, make sure to lock that special (by clicking the <FontAwesomeIcon className='gray-text' icon={faLock}/> icon on the special block) before clearing!</div>
					</div>
				</div>
			)
		}
		
		await swal(options).then(async (return_value) => {
			if(return_value)
			{
				// Update front end
				let new_blocks = blocks.filter(block => block.locked === '1' || block.is_special === '0');
				setBlocks(new_blocks);

				// Send to Backend ///
				const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id};
				const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
				const url = '/elementary/clear-specials';
				const myInit = { // OPTIONAL
					response: true,
					body: JSON.stringify(data),
				};
			
				try {
					// Clear specials
					await API.post(apiName, url, myInit);

					const success_options =  {
						icon:"success",
						title: "Success!",
						text: "All non-locked specials have been cleared!",
					}
					swal(success_options);
				} catch(e)
				{
					const failure_options =  {
						icon:"error",
						title: "Error Clearing Specials",
						text: "We had an issue clearing your specials. Please try again.",
						dangerMode: true,
					}

					swal(failure_options);
					console.log(e.response);
				}
			}
		});
	}

	//// Pullouts Functions ////
	const autoPlacePullouts = async () => {
		const options =  {
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Start Placing Pullouts",
					value: true,
					visible: true,
					className: 'blue-btn'
				},
			},
			content: (
				<div>
					<FontAwesomeIcon className='modal-top-icon blue-text' icon={faCalendarDay}/>
					<h1 className='swal-title' style={{marginTop:'0px'}}>Place Pullouts</h1>
					<p className='swal-text'>This will try to place all your unplaced pullouts!</p>
				</div>
				
			)
		}

		await swal(options).then(async (return_value) => {
			if(return_value)
			{
				const placing_in_progress_options =  {
					button:false,
					closeOnClickOutside: false,
					closeOnEsc: false,
					content: (
						<div>
							<FontAwesomeIcon className='modal-top-icon placing-students-in-progress-icon' icon={faCalendarDay}/>
							<h1 className='swal-title' style={{marginTop:'0px'}}>Placing Pullouts in Progress</h1>
							<p className='swal-text'>Please wait, this may take a few minutes...</p>
							<div className='sweet-alert-dont-show-message' style={{padding:'20px 10px 25px 10px'}}>
								<div className='red-text'>Do not refresh the page until this process is complete!</div>
							</div>
						</div>
					)
				}

				swal(placing_in_progress_options);

				/// Update the BACKEND ///
				const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id};
				const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
				const url = '/elementary/auto-place-pullouts';
				const myInit = { // OPTIONAL
					response: true,
					body: JSON.stringify(data),
				};
			
				try {
					await API.post(apiName, url, myInit);

					// Pull new blocks and then move to next screen
					pullNewBlocks();

					const success_options =  {
						icon:"success",
						title: "Success!",
						text: "Your pullouts should now be placed.",
					}
					swal(success_options);
					
				} catch(error)
				{
					const failure_options =  {
						icon:"error",
						title: "Error Placing Pullouts",
						text: "We had an issue placing your pullouts. Please try again.",
						dangerMode: true,
					}

					swal(failure_options);
					console.log(error.response);
				}
			}
		});
	}

	const clearPullouts = async () => {	
		const options =  {
			title: "Are you sure?",
			text: 'This will remove all pullouts from the schedule (that are not locked).',
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			},
			content: (
				<div className='sweet-alert-dont-show-message' style={{padding:'20px 10px 25px 10px'}}>
					<div className='dark-blue-text' style={{textAlign:'left',width:'340px',margin:'auto'}}>
						<div><strong>Pro Tip:</strong></div>
						<div>If you want to keep certain pullouts where they are, make sure to lock that pullout (by clicking the <FontAwesomeIcon className='gray-text' icon={faLock}/> icon on the pullout block) before clearing!</div>
					</div>
				</div>
			)
		}
		
		await swal(options).then(async (return_value) => {
			if(return_value)
			{
				// Update front end
				let new_blocks = blocks.filter(block => block.locked === '1' || block.is_pullout === '0');
				setBlocks(new_blocks);

				// Send to Backend ///
				const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id};
				const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
				const url = '/elementary/clear-pullouts';
				const myInit = { // OPTIONAL
					response: true,
					body: JSON.stringify(data),
				};
			
				try {
					// Clear specials
					await API.post(apiName, url, myInit);

					const success_options =  {
						icon:"success",
						title: "Success!",
						text: "All non-locked pull outs have been cleared!",
					}
					swal(success_options);
				} catch(e)
				{
					const failure_options =  {
						icon:"error",
						title: "Error Clearing Pull Outs",
						text: "We had an issue clearing your pull outs. Please try again.",
						dangerMode: true,
					}

					swal(failure_options);
					console.log(e.response);
				}
			}
		});
	}

	//// Fill Students Functions ////
	const fillStudents = async () => {
		const options =  {
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Start Filling Students",
					value: true,
					visible: true,
					className: 'blue-btn'
				},
			},
			content: (
				<div>
					<FontAwesomeIcon className='modal-top-icon blue-text' icon={faCalendarDay}/>
					<h1 className='swal-title' style={{marginTop:'0px'}}>Fill Students</h1>
					<p className='swal-text'>This will fill all homeroom classes with their homeroom students, as well as try to place any unplaced pullout students into available pullout blocks!</p>
				</div>
				
			)
		}

		await swal(options).then(async (return_value) => {
			if(return_value)
			{
				const placing_in_progress_options =  {
					button:false,
					closeOnClickOutside: false,
					closeOnEsc: false,
					content: (
						<div>
							<FontAwesomeIcon className='modal-top-icon placing-students-in-progress-icon' icon={faCalendarDay}/>
							<h1 className='swal-title' style={{marginTop:'0px'}}>Fill Students in Progress</h1>
							<p className='swal-text'>Please wait, this may take a few minutes...</p>
							<div className='sweet-alert-dont-show-message' style={{padding:'20px 10px 25px 10px'}}>
								<div className='red-text'>Do not refresh the page until this process is complete!</div>
							</div>
						</div>
					)
				}

				swal(placing_in_progress_options);

				/// Update the BACKEND ///
				const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id};
				const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
				const url = '/elementary/fill-students';
				const myInit = { // OPTIONAL
					response: true,
					body: JSON.stringify(data),
				};
			
				try {
					await API.post(apiName, url, myInit);

					// Pull new blocks and then move to next screen
					pullNewBlocks();

					const success_options =  {
						icon:"success",
						title: "Success!",
						text: "Your students should now be filled into their blocks.",
					}
					swal(success_options);
					
				} catch(error)
				{
					const failure_options =  {
						icon:"error",
						title: "Error Filling Students",
						text: "We had an issue filling your students. Please try again.",
						dangerMode: true,
					}

					swal(failure_options);
					console.log(error.response);
				}
			}
		});
	}

	const clearStudents = async () => {	
		const options =  {
			title: "Are you sure?",
			text: 'This will remove all students from their schedules (that are not locked).',
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			},
			content: (
				<div className='sweet-alert-dont-show-message' style={{padding:'20px 10px 25px 10px'}}>
					<div className='dark-blue-text' style={{textAlign:'left',width:'340px',margin:'auto'}}>
						<div><strong>Pro Tip:</strong></div>
						<div>If you want to keep certain students where they are, make sure to lock that student in those blocks before clearing!</div>
					</div>
				</div>
			)
		}
		
		await swal(options).then(async (return_value) => {
			if(return_value)
			{
				// Update front end
				const new_blocks = blocks.reduce((results, block) => {
					const block_id = block.block_id;
					const block_is_locked = (block.locked === '1');
					const block_student_list = block.student_list;
					const locked_students = block_student_list.filter(student_id => {
						const student_info = students.find(student => student.student_id === student_id);
						const student_locked_blocks = (student_info) ? student_info.student_locked_blocks : [];
						return student_locked_blocks.includes(block_id);
					});

					// if entire block is locked, keep all students
					// otherwise, keep any locked students
					block.student_list = (block_is_locked) ? block_student_list : locked_students;
					results.push(block);
					return results;
				}, []);

				setBlocks(new_blocks);
				setBlockData(null);

				// Send to Backend ///
				const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id};
				const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
				const url = '/elementary/clear-students';
				const myInit = { // OPTIONAL
					response: true,
					body: JSON.stringify(data),
				};
			
				try {
					// Clear blocks
					await API.post(apiName, url, myInit);

					const success_options =  {
						icon:"success",
						title: "Success!",
						text: "All non-locked students have been cleared!",
					}
					swal(success_options);
				} catch(e)
				{
					const failure_options =  {
						icon:"error",
						title: "Error Clearing Students",
						text: "We had an issue clearing your students. Please try again.",
						dangerMode: true,
					}

					swal(failure_options);
					console.log(e.response);
				}
			}
		});
	}

	// const addOpenReport = (report) => {
	// 	const report_index = openReports.findIndex(open_report => open_report === report);
	// 	if(report_index === -1) openReports.push(report);
	// 	setOpenReports([...openReports]);
	// }

	// const removeOpenReport = (report) => {
	// 	const report_index = openReports.findIndex(open_report => open_report === report);
	// 	if(report_index !== -1) openReports.splice(report_index, 1);
	// 	setOpenReports([...openReports]);
	// }

	const showMoreTeachers = () => {
		const num_teachers_to_show = maxTeachersToShow + 10;
		setMaxTeachersToShow(num_teachers_to_show);
	}

	const showMoreStudents = () => {
		const num_students_to_show = maxStudentsToShow + 50;
		setMaxStudentsToShow(num_students_to_show);
	}

	////////////////////////////////
	///// USE EFFECT FUNCTIONS /////
	////////////////////////////////
	// Set background info, as well as create undo/redo
	useEffect(() => {
		const current_view = getJSONFromStorage('current_view', false, 'session');
		if(current_view) setCurrentView(current_view);
	}, []);

	// Set Display Data
	// Create magnetboard display (will also create filter options)
	useEffect(() => {
		if(currentView === 'student')
		{
			if(!scheduleInfoLoading && !blocksLoading && !teachersLoading && !coursesLoading && !classroomsLoading && !studentsLoading && isLoading) createDisplaySchedule();
		}
		else
		{
			if(!scheduleInfoLoading && !blocksLoading && !teachersLoading && !coursesLoading && !classroomsLoading && isLoading) createDisplaySchedule();
		}
	}, [scheduleInfoLoading, blocksLoading, teachersLoading, coursesLoading, classroomsLoading, studentsLoading, currentView, isLoading, createDisplaySchedule]);

	useEffect(() => {
		const display_data = (currentView === 'teacher') ? teachers : ((currentView === 'classroom') ? classrooms : ((currentView === 'student') ? students : []));
		if(display_data.length === 0) return;

		const open_data_ids = display_data.map(display_data_info => display_data_info[`${currentView}_id`]);
		setOpenDisplayData(open_data_ids);

		sessionStorage.setItem("current_view", currentView);
		createDisplaySchedule();
	}, [currentView, teachers, classrooms, students, createDisplaySchedule]);

	useEffect(() => {
		const num_days_in_cycle = scheduleInfo.num_days_in_cycle;
		let new_days = [];

		for(let day = 1; day <= num_days_in_cycle; day++)
		{
			new_days.push(day);
		}

		setDays(new_days);
	}, [scheduleInfo]);
	
	// Do search
	useEffect(() => {
		if(!searchValue) 
		{
			setFilterIDs([]);
			return;
		}

		if(currentView === 'teacher')
		{
			const filtered_teacher_ids = [];

			// Search teachers
			const teachers_fuse = new Fuse(teachers, {
				keys: ['name', 'first_name'],
				threshold: .1
			});

			const teacher_results = teachers_fuse.search(searchValue);
			const subset_teacher_results = teacher_results.map(result => result.item).slice(0,6);

			subset_teacher_results.forEach(teacher => {
				filtered_teacher_ids.push(teacher.teacher_id);
			});

			setFilterIDs([...filtered_teacher_ids]);
		}
		else if(currentView === 'classroom')
		{
			const filtered_classroom_ids = [];

			// Search classrooms
			const classrooms_fuse = new Fuse(classrooms, {
				keys: ['classroom_name', 'classroom_type'],
				threshold: .1
			});

			const classroom_results = classrooms_fuse.search(searchValue);
			const subset_classroom_results = classroom_results.map(result => result.item).slice(0,6);

			subset_classroom_results.forEach(classroom => {
				filtered_classroom_ids.push(classroom.classroom_id);
			});

			setFilterIDs([...filtered_classroom_ids]);
		}
		else if(currentView === 'student')
		{
			const filtered_student_ids = [];

			// Search classrooms
			const students_fuse = new Fuse(students, {
				keys: ['first_name', 'last_name'],
				threshold: .1
			});

			const student_results = students_fuse.search(searchValue);
			const subset_student_results = student_results.map(result => result.item).slice(0,30);

			subset_student_results.forEach(student => {
				filtered_student_ids.push(student.student_id);
			});

			setFilterIDs([...filtered_student_ids]);
		}
	}, [searchValue, teachers, classrooms, students, currentView]);
	
	// Update filtered data
	useEffect(() => {
		let filtered_data = displayData.reduce((result, display_data) => {
			let include = 0;
			let include_specific_data = 0;
			let include_for_teacher_type = 0;
			let include_for_grade = 0;
			let include_for_homeroom = 0;

			Object.keys(filterOptions).forEach(filter_title => {
				const filter_options = filterOptions[filter_title];

				// If none of the options are selected, then automatically include all of them
				if(filter_options.filter(option => option.selected).length === 0)
				{
					if(filter_title === 'teachers')
					{
						include_specific_data = 1;
					}
					else if(filter_title === 'type')
					{
						include_for_teacher_type = 1;
					}
					else if(filter_title === 'classrooms')
					{
						include_specific_data = 1;
					}
					else if(filter_title === 'grades')
					{
						include_for_grade = 1;
					}
					else if(filter_title === 'homerooms')
					{
						include_for_homeroom = 1;
					}
				}
				
				// Loop through all filter options and start filtering those that apply
				filter_options.forEach(filter_option => {
					const filter_option_selected = filter_option.selected;
					if(!filter_option_selected) return;
	
					const filter_option_value = filter_option.value;
	
					if(currentView === 'teacher')
					{
						if(filter_title === 'teachers' && display_data.display_info.teacher_id === filter_option_value) include_specific_data = 1;
						
						if(filter_title === 'type')
						{
							const is_homeroom_teacher = display_data.display_info.is_homeroom_teacher;
							const is_specials_teacher = display_data.display_info.is_specials_teacher;
							const is_pullout_teacher = display_data.display_info.is_pullout_teacher;
							
							const teacher_teaches = ('teacher_can_teach' in display_data.display_info) ? display_data.display_info.teacher_can_teach.reduce((result, tct_course_info) => {
								const tct_course_id = tct_course_info.course_id;
								const course_info = courses.find(course => course.course_id === tct_course_id);
								if(course_info && course_info.is_special === '1') result.teacher_teaches_specials = true;
								if(course_info && course_info.is_pullout === '1') result.teacher_teaches_pullouts = true;
								return result;
							}, {teacher_teaches_specials: false, teacher_teaches_pullouts: false}) : {teacher_teaches_specials: false, teacher_teaches_pullouts: false};
							const teacher_teaches_specials = teacher_teaches.teacher_teaches_specials;
							const teacher_teaches_pullouts = teacher_teaches.teacher_teaches_pullouts;

							if(filter_option_value === 'is_homeroom' && is_homeroom_teacher === '1')
							{
								include_for_teacher_type = 1;
							}
							else if(filter_option_value === 'is_specials' && (teacher_teaches_specials || is_specials_teacher === '1'))
							{
								include_for_teacher_type = 1;
							}
							else if(filter_option_value === 'is_pullout' && (teacher_teaches_pullouts || is_pullout_teacher === '1'))
							{
								include_for_teacher_type = 1;
							}
						}
					}
					else if(currentView === 'classroom')
					{
						if(filter_title === 'classrooms' && display_data.display_info.classroom_id === filter_option_value) include_specific_data = 1;
					}
					else if(currentView === 'student')
					{
						if(filter_title === 'grades' && display_data.display_info.grade === filter_option_value) include_for_grade = 1;
						if(filter_title === 'homerooms' && display_data.display_info.homeroom_teacher_id === filter_option_value) include_for_homeroom = 1;
					}
				});
			});

			if(currentView === 'teacher' && include_for_teacher_type === 1 && include_specific_data === 1) include = 1;
			if(currentView === 'classroom' && include_specific_data === 1) include = 1;
			if(currentView === 'student' && include_for_grade === 1 && include_for_homeroom === 1) include = 1;

			if(include === 1) result.push(display_data.display_info[`${currentView}_id`]);

			return result;
		}, []);
		
		setFilteredData([...filtered_data]);
	}, [filterOptions, courses, currentView, displayData]);

	/////////////////////////////
	//// CALCULATE CONFLICTS ////
	/////////////////////////////
	// useEffect(() => {
	// 	if(teachersLoading || studentsLoading || coursesLoading || classroomsLoading || blocksLoading || periodsLoading) return;

	// 	let new_conflicts = [];
	// 	let checked_blocks = [];
	// 	for(const block of blocks)
	// 	{
	// 		const block_id = block.block_id;
	// 		const block_teacher_id = block.teacher_id;
	// 		const block_homeroom_teacher_id = block.homeroom_teacher_id;
	// 		const block_course_id = block.course_id;
	// 		const block_classroom_id = block.classroom_id;
	// 		const block_day_number = block.day_number;
	// 		const block_start_time = block.start_time;
	// 		const block_end_time = block.end_time;
	// 		const block_time = {start_time:block_start_time, end_time:block_end_time};
	// 		const block_student_list = (block_homeroom_teacher_id) ? students.reduce((results, student) => {
	// 			if(student.homeroom_teacher_id && student.homeroom_teacher_id === block_homeroom_teacher_id) results.push(student.student_id);
	// 			return results;
	// 		}, []) : block.student_list;
	// 		const block_is_pullout = block.is_pullout;
	// 		const block_is_special = block.is_special;
	// 		const block_is_lunch = block.is_lunch;

	// 		const block_start_time_formatted = formatTime(block_start_time);
	// 		const block_end_time_formatted = formatTime(block_end_time);

	// 		const block_teacher_info = (block_teacher_id) ? teachers.find(teacher => teacher.teacher_id === block_teacher_id) : null;
	// 		const block_homeroom_teacher_info = (block_homeroom_teacher_id) ? teachers.find(teacher => teacher.teacher_id === block_homeroom_teacher_id) : null;
	// 		const block_course_info = courses.find(course => course.course_id === block_course_id);
	// 		const block_classroom_info = (block_classroom_id) ? classrooms.find(classroom => classroom.classroom_id === block_classroom_id) : null;

	// 		const block_teacher_display_name = (block_teacher_info) ? `${block_teacher_info.name}, ${block_teacher_info.first_name}` : null;
	// 		const block_homeroom_teacher_display_name = (block_homeroom_teacher_info) ? `${block_homeroom_teacher_info.name}, ${block_homeroom_teacher_info.first_name}` : null;

	// 		const block_classroom_name = (block_classroom_info) ? block_classroom_info.classroom_name : null;
			
	// 		const block_course_name = (block_course_info) ? block_course_info.name : null;
	// 		const block_course_day_period_restrictions = (block_course_info) ? block_course_info.day_period_restrictions : [];

	// 		// Calculate course outside usual classroom conflicts
	// 		// Calculate course missing a classroom conflicts
	// 		const course_classroom_restrictions = (block_course_info) ? block_course_info.classroom_restrictions : [];
	// 		const course_outside_normal_classroom = (course_classroom_restrictions && course_classroom_restrictions.length > 0 && !course_classroom_restrictions.includes(block_classroom_id));
	// 		const course_missing_classroom = (course_classroom_restrictions && course_classroom_restrictions.length > 0 && !block_classroom_id);
			
	// 		if(course_outside_normal_classroom) new_conflicts.push({block_id:block_id, teachers:[block_teacher_id, block_homeroom_teacher_id], course_id:block_course_id, classroom_id:block_classroom_id, students:block_student_list, display:`${block_course_name} is not in its usual classroom(s) on Day ${block_day_number} from ${block_start_time_formatted}-${block_end_time_formatted}`});
	// 		if(course_missing_classroom) new_conflicts.push({block_id:block_id, teachers:[block_teacher_id, block_homeroom_teacher_id], course_id:block_course_id, classroom_id:block_classroom_id, students:block_student_list, display:`${block_course_name} is not in a classroom(s) on Day ${block_day_number} from ${block_start_time_formatted}-${block_end_time_formatted}`});

	// 		// Calculate teacher day period restrictions conflicts
	// 		const teacher_period_restrictions_conflicts = (block_teacher_info) ? block_teacher_info.day_period_restrictions.reduce((results, day_period_restriction) => {
	// 			const day_number_restriction = day_period_restriction.day_number;

	// 			const period_id_restriction = day_period_restriction.period_id;
	// 			const period_info = periods.find(period => period.period_id === period_id_restriction);
	// 			const period_start_time = period_info.start_time;
	// 			const period_end_time = period_info.end_time;
	// 			const period_time = {start_time:period_start_time, end_time:period_end_time};

	// 			if(block_day_number === day_number_restriction && overlapCheck(block_time, period_time)) results.push({block_id:block_id, teachers:[block_teacher_id, block_homeroom_teacher_id], course_id:block_course_id, classroom_id:block_classroom_id, students:block_student_list, display:`${block_teacher_info.name}, ${block_teacher_info.first_name} is in a restricted time Day ${block_day_number} from ${block_start_time_formatted}-${block_end_time_formatted}`});
				
	// 			return results;
	// 		}, []) : [];

	// 		// Calculate all double booked conflicts (teachers, classrooms, and students)
	// 		const double_booked_conflicts = blocks.reduce((double_booked_tracker, double_booked_block) => {
	// 			const double_booked_block_id = double_booked_block.block_id;
	// 			const double_booked_block_is_pullout = double_booked_block.is_pullout;
	// 			const double_booked_block_is_special = double_booked_block.is_special;
	// 			const double_booked_block_is_lunch = double_booked_block.is_lunch;

	// 			// Skip same blocks or blocks that have already been checked
	// 			if(checked_blocks.includes(double_booked_block_id)) return double_booked_tracker;
	// 			if(block_id === double_booked_block_id) return double_booked_tracker;

	// 			const double_booked_block_day_number = double_booked_block.day_number;
	// 			const double_booked_block_start_time = double_booked_block.start_time;
	// 			const double_booked_block_end_time = double_booked_block.end_time;
	// 			const double_booked_block_time = {start_time:double_booked_block_start_time, end_time:double_booked_block_end_time};

	// 			const day_and_time_overlap = (block_day_number === double_booked_block_day_number && overlapCheck(block_time, double_booked_block_time));

	// 			if(day_and_time_overlap)
	// 			{
	// 				const double_booked_teacher_id = double_booked_block.teacher_id;
	// 				const double_booked_homeroom_teacher_id = double_booked_block.homeroom_teacher_id;
	// 				const double_booked_classroom_id = double_booked_block.classroom_id;
	// 				const double_booked_student_list = double_booked_block.student_list;
	// 				const double_booked_teacher_and_homeroom_teacher_are_same = (double_booked_teacher_id === double_booked_homeroom_teacher_id);

	// 				const double_booked_students = (double_booked_homeroom_teacher_id) ? students.reduce((double_booked_student_results, student) => {
	// 					const student_id = student.student_id;
	// 					const student_homeroom_teacher_id = student.homeroom_teacher_id;
	// 					if(student_homeroom_teacher_id === double_booked_homeroom_teacher_id && !double_booked_student_results.includes(student_id)) double_booked_student_results.push(student_id);
	// 					return double_booked_student_results;
	// 				}, [...double_booked_student_list]) : double_booked_student_list;

	// 				const student_intersection = block_student_list.filter(value => double_booked_students.includes(value));

	// 				const block_teacher_match =  (block_teacher_id && (block_teacher_id === double_booked_teacher_id || block_teacher_id === double_booked_homeroom_teacher_id));
	// 				const block_homeroom_teacher_match = (block_homeroom_teacher_id && (block_homeroom_teacher_id === double_booked_teacher_id || block_homeroom_teacher_id === double_booked_homeroom_teacher_id));
	// 				const block_classroom_match = (block_classroom_id && (block_classroom_id === double_booked_classroom_id));
	// 				const block_student_match = (!(block_is_pullout === '1' && double_booked_block_is_special !== '1' && double_booked_block_is_pullout !== '1' && double_booked_block_is_lunch !== '1') && !(double_booked_block_is_pullout === '1' && block_is_special !== '1' && block_is_pullout !== '1' && block_is_lunch !== '1'));

	// 				if(block_teacher_match) double_booked_tracker.teachers_double_booked.push({block_id:block_id, teachers:[block_teacher_id], course_id:block_course_id, classroom_id:block_classroom_id, students:block_student_list, display:`${block_teacher_display_name} is double booked on Day ${block_day_number} from ${block_start_time_formatted}-${block_end_time_formatted}`});

	// 				if(block_homeroom_teacher_match && !double_booked_teacher_and_homeroom_teacher_are_same) double_booked_tracker.teachers_double_booked.push({block_id:block_id, teachers:[block_homeroom_teacher_id], course_id:block_course_id, classroom_id:block_classroom_id, students:block_student_list, display:`${block_homeroom_teacher_display_name} is double booked on Day ${block_day_number} from ${block_start_time_formatted}-${block_end_time_formatted}`});

	// 				if(block_classroom_match) double_booked_tracker.classrooms_double_booked.push({block_id:block_id, teachers:[block_teacher_id, block_homeroom_teacher_id], course_id:block_course_id, classroom_id:block_classroom_id, students:block_student_list, display:`${block_classroom_name} is double booked on Day ${block_day_number} from ${block_start_time_formatted}-${block_end_time_formatted}`});

	// 				if(block_student_match && student_intersection.length > 0)
	// 				{
	// 					const student_names = student_intersection.reduce((student_names_array, student_id) => {
	// 						const student_info = students.find(student => student.student_id === student_id);
	// 						const student_first_name = student_info.first_name;
	// 						const student_last_name = student_info.last_name;
	// 						const student_name = `${student_last_name}, ${student_first_name}`;
	// 						student_names_array.push(student_name);
	// 						return student_names_array;
	// 					},[]);
	// 					const comma_separated_student_names = student_names.join(", ");

	// 					double_booked_tracker.students_double_booked.push({block_id:block_id, teachers:[block_teacher_id, block_homeroom_teacher_id], course_id:block_course_id, classroom_id:block_classroom_id, students:student_intersection, display:`${comma_separated_student_names} is/are double booked on Day ${block_day_number} from ${block_start_time_formatted}-${block_end_time_formatted}`});
	// 				}
	// 			}
				
	// 			return double_booked_tracker;
	// 		}, {teachers_double_booked:[], classrooms_double_booked:[], students_double_booked:[]});

	// 		// Calculate course day period restrictions conflicts
	// 		const course_period_restrictions_conflicts = block_course_day_period_restrictions.reduce((results, day_period_restriction) => {
	// 			const day_number_restriction = day_period_restriction.day_number;

	// 			const period_id_restriction = day_period_restriction.period_id;
	// 			const period_info = periods.find(period => period.period_id === period_id_restriction);
	// 			const period_start_time = (period_info) ? period_info.start_time : null;
	// 			const period_end_time = (period_info) ? period_info.end_time : null;

	// 			if(!period_info || !period_start_time || !period_end_time) return results;
				
	// 			const period_time = {start_time:period_start_time, end_time:period_end_time};

	// 			if(block_day_number === day_number_restriction && overlapCheck(block_time, period_time)) results.push({block_id:block_id, teachers:[block_teacher_id, block_homeroom_teacher_id], course_id:block_course_id, classroom_id:block_classroom_id, students:block_student_list, display:`${block_course_name} is in a restricted time Day ${block_day_number} from ${block_start_time_formatted}-${block_end_time_formatted}`});
				
	// 			return results;
	// 		}, []);

	// 		new_conflicts = [...new_conflicts, ...teacher_period_restrictions_conflicts, ...double_booked_conflicts.teachers_double_booked, ...double_booked_conflicts.classrooms_double_booked, ...double_booked_conflicts.students_double_booked, ...course_period_restrictions_conflicts];

	// 		checked_blocks.push(block_id);
	// 	}

	// 	const homeroom_teacher_missing_special_conflicts = courses.reduce((results, course) => {
	// 		const course_id = course.course_id;
	// 		const course_name = course.name;
	// 		const course_homerooms = course.course_homerooms;
	// 		const course_num_times_needed = course.num_times_needed;

	// 		for(const homeroom_teacher_id of course_homerooms)
	// 		{
	// 			const homeroom_teacher_info = teachers.find(teacher => teacher.teacher_id === homeroom_teacher_id);
	// 			const num_times_homeroom_has_course = blocks.reduce((results, temp_block) => {
	// 				if(temp_block.homeroom_teacher_id === homeroom_teacher_id && temp_block.course_id === course_id) results++;
	// 				return results;
	// 			}, 0);

	// 			if(num_times_homeroom_has_course < course_num_times_needed) results.push({block_id:null, teachers:[homeroom_teacher_id], course_id:course_id, classroom_id:null, students:[], display:`${homeroom_teacher_info.name}, ${homeroom_teacher_info.first_name} is missing ${course_num_times_needed - num_times_homeroom_has_course} period of ${course_name}`});
	// 		}

	// 		return results;
	// 	}, []);

	// 	const students_missing_pullout_requirement = courses.reduce((results, course) => {
	// 		const course_id = course.course_id;
	// 		const course_name = course.name;
	// 		const pullout_groups = course.pullout_groups;

	// 		for(const pullout_group_id in pullout_groups)
	// 		{
	// 			const pullout_group = pullout_groups[pullout_group_id];
	// 			const course_num_times_needed = pullout_group.num_times_needed;
	// 			const course_duration_needed = pullout_group.duration;

	// 			for(const pullout_student_id of pullout_group.student_list)
	// 			{
	// 				const pullout_student_info = students.find(student => student.student_id === pullout_student_id);
	// 				const num_times_student_has_course = blocks.reduce((results, temp_block) => {
	// 					if(temp_block.student_list.includes(pullout_student_id) && temp_block.course_id === course_id) results++;
	// 					return results;
	// 				}, 0);

	// 				if(num_times_student_has_course < course_num_times_needed) results.push({block_id:null, teachers:[], course_id:course_id, classroom_id:null, students:[pullout_student_id], display:`${pullout_student_info.last_name}, ${pullout_student_info.first_name} is missing ${course_num_times_needed - num_times_student_has_course} period(s) of ${course_name}`});
	// 			}
	// 		}

	// 		return results;
	// // 	}, []);

	// 	new_conflicts = [...new_conflicts, ...homeroom_teacher_missing_special_conflicts, ...students_missing_pullout_requirement];

	// 	setConflicts(new_conflicts);
	// }, [teachersLoading, studentsLoading, coursesLoading, classroomsLoading, blocksLoading, teachers, students, courses, classrooms]);

	////////////////////////////////
	///// INITIALIZE VARIABLES /////
	////////////////////////////////
	const allowed_teacher_ids = magnetboardOptions.allowed_teacher_ids;
	let teacher_counter = 1;
	let student_counter = 1;

	let selected_filters = [];
	Object.keys(filterOptions).forEach(filter_title => {
		const filter_options = filterOptions[filter_title];

		filter_options.forEach(filter_option => {
			if(filter_option.selected) selected_filters.push(filter_option);
		});
	});
	
	//console.log({addNewBlockIsOpen, addBlockOpenDataID, addBlockOpenDayNumber});
	//console.log({blocks, courses, teachers, classrooms, students, periods});
	//console.log({displayData});
	//console.log({isLoading, displayCreated});
	//console.log({filterIDs, filteredData});
	//console.log({conflicts});

	return (
		<>
		{showEditDataModal &&
			<>
			{currentView === 'teacher' ? 
			(
				<EditTeacherData schoolInfo={schoolInfo} dataID={dataEditingID} toggleEditData={toggleEditData} scheduleInfo={scheduleInfo} periods={periods} teachers={teachers} courses={courses} classrooms={classrooms} students={students} setStudents={setStudents} setDatabaseData={setTeachers} filteredResults={teachers} setFilteredResults={()=>{}}/>
			):(currentView === 'classroom') ?
			(
				<EditClassroomData schoolInfo={schoolInfo} dataID={dataEditingID} toggleEditData={toggleEditData} scheduleInfo={scheduleInfo} classrooms={classrooms} courses={courses} teachers={teachers} setDatabaseData={setClassrooms} filteredResults={classrooms} setFilteredResults={()=>{}} blocks={blocks}/>
			):(currentView === 'student') ?
			(
				<EditStudentData schoolInfo={schoolInfo} dataID={dataEditingID} toggleEditData={toggleEditData} scheduleInfo={scheduleInfo} students={students} courses={courses} teachers={teachers} setDatabaseData={setStudents} blocks={blocks}/>
			):null}
			</>
		}
		<EditBlock schoolInfo={schoolInfo} days={days} blockData={blockData} blocks={blocks} setBlocks={setBlocks} courses={courses} setCourses={setCourses} teachers={teachers} classrooms={classrooms} students={students} overlapCheck={overlapCheck} removeBlock={removeBlock} toggleEditBlock={toggleEditBlock} sidePanelClosing={sidePanelClosing} setSidePanelClosing={setSidePanelClosing}/>
		<DndProvider backend={HTML5Backend}>
			<div className='mb-elem-container'>
				<div className='mb-elem-top-bar'>
					<div className='mb-top-bar-inner'>
						<div className='mb-elem-schedule-name-container'>
							<h1><span className='capitalize'>{currentView}</span> Schedules</h1>
						</div>
						<div className='mb-menu-bar' ref={menu_ref}>
							<div className='mb-menu-btn' onClick={() => toggleMenuItem('file')}>
								<span>File</span>
								{currentOpenMenu === 'file' &&
									<div className='mb-menu-dropdown-options-container'>
										<div className='mb-dropdown-option' onClick={openPrint}>Print Schedule</div>
										{/* <div className={`mb-dropdown-option ${!schoolSubscribed && 'gray-text'}`} onClick={schoolSubscribed ? ()=>setShowExportScheduleModal(true) : () => alert("Sorry, this feature is only available with a paid subscription. Please contact us at contact@edario.com to get full access!")}>Export Schedule</div> */}
									</div>
								}
							</div>
							<div className='mb-menu-btn' onClick={() => toggleMenuItem('actions')}>
								<span>Actions</span>
								{currentOpenMenu === 'actions' &&
									<div className='mb-menu-dropdown-options-container'>
										<div className='mb-dropdown-option mb-dropdown-option-hover' data-actiontype='blocks' onMouseEnter={() => handleDropdownHoverShow('blocks')} onMouseLeave={() => handleDropdownHoverHide('blocks')}>
											<div>Blocks</div>
											<FontAwesomeIcon icon={faChevronRight} />
										</div>
										<div className='mb-dropdown-option mb-dropdown-option-hover' data-actiontype='specials'  onMouseEnter={() => handleDropdownHoverShow('specials')} onMouseLeave={() => handleDropdownHoverHide('specials')}>
											<div>Specials</div>
											<FontAwesomeIcon icon={faChevronRight}/>
										</div>
										<div className='mb-dropdown-option mb-dropdown-option-hover' data-actiontype='pullouts'  onMouseEnter={() => handleDropdownHoverShow('pullouts')} onMouseLeave={() => handleDropdownHoverHide('pullouts')}>
											<div>Pullouts</div>
											<FontAwesomeIcon icon={faChevronRight}/>
										</div>
										<div className='mb-dropdown-option mb-dropdown-option-hover' data-actiontype='students'  onMouseEnter={() => handleDropdownHoverShow('students')} onMouseLeave={() => handleDropdownHoverHide('students')}>
											<div>Students</div>
											<FontAwesomeIcon icon={faChevronRight}/>
										</div>
									</div>
								}
								<div className='mb-dropdown-options-subcontainer hide' data-actiontype='blocks' onMouseEnter={() => handleDropdownHoverShow('blocks')} onMouseLeave={() => handleDropdownHoverHide('blocks')}>
									<div className='mb-dropdown-option' onClick={clearBlocks}>Clear All Blocks</div>
								</div>
								<div className='mb-dropdown-options-subcontainer hide' data-actiontype='specials' onMouseEnter={() => handleDropdownHoverShow('specials')} onMouseLeave={() => handleDropdownHoverHide('specials')}>
									<div className='mb-dropdown-option' onClick={clearSpecials}>Clear Specials</div>
									<div className={`mb-dropdown-option ${!schoolSubscribed && 'gray-text'}`} onClick={schoolSubscribed ? autoPlaceSpecials : () => alert("Sorry, this feature is only available with a paid subscription. Please contact us at contact@edario.com to get full access!")}>Auto-Place Specials</div>
								</div>
								<div className='mb-dropdown-options-subcontainer hide' data-actiontype='pullouts' onMouseEnter={() => handleDropdownHoverShow('pullouts')} onMouseLeave={() => handleDropdownHoverHide('pullouts')}>
									<div className='mb-dropdown-option' onClick={clearPullouts}>Clear Pullouts</div>
									<div className={`mb-dropdown-option ${!schoolSubscribed && 'gray-text'}`} onClick={schoolSubscribed ? autoPlacePullouts : () => alert("Sorry, this feature is only available with a paid subscription. Please contact us at contact@edario.com to get full access!")}>Auto-Place Pullouts</div>
								</div>
								<div className='mb-dropdown-options-subcontainer hide' data-actiontype='students' onMouseEnter={() => handleDropdownHoverShow('students')} onMouseLeave={() => handleDropdownHoverHide('students')}>
									<div className='mb-dropdown-option' onClick={clearStudents}>Clear Students</div>
									<div className={`mb-dropdown-option ${!schoolSubscribed && 'gray-text'}`} onClick={schoolSubscribed ? fillStudents : () => alert("Sorry, this feature is only available with a paid subscription. Please contact us at contact@edario.com to get full access!")}>Fill Students</div>
								</div>
							</div>
							<div className='mb-menu-btn'>
								<span>Help</span>
							</div>
						</div>
						<div className='mb-options-bar'>
							<div className='mb-search-bar-outer-container'>
								<div className='mb-search-bar-container'>
									<input id='mb-search-bar' className='mb-search-bar' placeholder={`Search for a teacher...`} value={searchValue} onChange={(e) => setSearchValue(e.target.value)}/>
									<FontAwesomeIcon className='mb-search-bar-icon' icon={faSearch}/>
									<div className='mb-search-bar-filter-container' ref={filter_ref}>
										<div style={{display:'grid',width:'100%', height:'100%', alignItems:'center', justifyItems:'center'}} onClick={magnetboardOptions.allow_filtering ? toggleFilterOptions : ()=>{}}>
											<FontAwesomeIcon icon={faSlidersH}/>
										</div>
										{filterDataVisible &&
											<div className='list-filter-options-container click-restricted' style={{top:'60px'}}>
												<div className='small-text'>Filter by:</div>
												{Object.keys(filterOptions).map((filter_title, j) => {
													const filter_options = filterOptions[filter_title];
													const filter_options_open = openFilterOptions.includes(filter_title);

													return (
														<div key={j}>
															<div className='list-filter-title-container' onClick={() => updateOpenFilters(filter_title, filter_options_open)}>
																<div className='capitalize'>{filter_title}</div>
																<FontAwesomeIcon className='small-text' icon={filter_options_open ? faChevronUp : faChevronDown}/>
															</div>
															{filter_options_open && filter_options.map((filter_option, i) => {
																const filter_display = filter_option.display;
																const filter_selected = filter_option.selected;

																return (
																	<div className='parent-div list-filter-option click-restricted' key={i}>
																		<div className='list-filter-option-name click-restricted'>{filter_display}</div>
																		{filter_selected &&
																			<FontAwesomeIcon className='fas-checkbox-checked click-restricted' icon={faCheckSquare} onClick={() => updateFilterOptions(filter_title, i, false)}/>
																		}
																		{!filter_selected &&
																			<FontAwesomeIcon className='fas-checkbox-unchecked click-restricted' icon={faSquare} onClick={() => updateFilterOptions(filter_title, i, true)}/>
																		}
																	</div>
																)
															})}
														</div>
													)
												})}
											</div>
										}
									</div>
								</div>
							</div>
							<div className='mb-change-view-container'>
								{magnetboardOptions.view_options &&
									<div className='mb-dropdown-wrapper'><Dropdown data={magnetboardOptions.view_options} currentValue={currentView} handleChange={(new_view) => changeCurrentView(new_view)} /></div>
								}
							</div>
						</div>
						<div className ='mb-selected-filters-bar'>
							{selected_filters.length > 0 &&
								<>
								<div className='mb-selected-filters-text'>Filters:</div>
								{Object.keys(filterOptions).map((filter_title, i) => {
									const filter_options = filterOptions[filter_title];

									return (
										<React.Fragment key={i}>
										{filter_options.map((filter_option, i) => {
											const filter_option_selected = filter_option.selected;
											
											if(!filter_option_selected) return null;

											return (
												<div className='mb-selected-filters-filter-tag' key={i}>
													<div className='mb-selected-filters-filter-tag-inner-container'>
														<div>{filter_option.display}</div>
														<FontAwesomeIcon className='dark-gray-link' icon={faTimes} onClick={() => updateFilterOptions(filter_title, i, false)} />
													</div>
												</div>
											)
										})}
										</React.Fragment>
									)
								})}
								</>
							}
						</div>
					</div>
				</div>
				<div ref={printRef}>
				{(isLoading || !displayCreated) ?
					(
						<div className='mb-message-container'>
							<img src={require('../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
						</div>
					):(
						<>
						{displayData.map((display_data, display_index) => {
							const data_id = display_data.data_id;
							const display_name = display_data.display_name;
							const display_info = display_data.display_info;

							if((filterIDs.length > 0 && !filterIDs.includes(data_id)) || !filteredData.includes(data_id)) return;
							if(currentView === 'teacher' && allowed_teacher_ids && !allowed_teacher_ids.includes(data_id)) return;
							if(currentView === 'teacher' && teacher_counter > maxTeachersToShow) return;						
							if(currentView === 'teacher') teacher_counter++;

							if(currentView === 'student' && student_counter > maxStudentsToShow) return;						
							if(currentView === 'student') student_counter++;

							let background_info = {};
							const data_conflicts = conflicts.reduce((results, conflict) => {
								const conflict_teachers = conflict.teachers;
								const conflict_classroom_id = conflict.classroom_id;
								const conflict_students = conflict.students;

								if((currentView === 'teacher' && conflict_teachers.includes(data_id)) || (currentView === 'classroom' && conflict_classroom_id === data_id) || (currentView === 'student' && conflict_students.includes(data_id))) results.push(conflict);

								return results;
							}, []);

							if(currentView === 'teacher')
							{
								const teacher_classroom_id = display_info.homeroom_classroom_id;
								const teacher_classroom_index = (teacher_classroom_id) ? classrooms.findIndex(classroom => classroom.classroom_id === teacher_classroom_id) : null;
								const teacher_classroom_info = (teacher_classroom_id && teacher_classroom_index !== -1) ? classrooms[teacher_classroom_index] : null;
								const teacher_classroom = (teacher_classroom_info) ? teacher_classroom_info.classroom_name : null;
								const teacher_homeroom_students = students.reduce((results, student) => {
									if(student.homeroom_teacher_id && student.homeroom_teacher_id === data_id) results.push(student.student_id);
									return results;
								}, []);
								const number_homeroom_students = teacher_homeroom_students.length;

								background_info = {teacher_classroom:teacher_classroom, teacher_homeroom_students:teacher_homeroom_students, number_homeroom_students:number_homeroom_students};
							}
							else if(currentView === 'classroom')
							{
								const homeroom_teacher_id = display_info.homeroom_teacher_id;
								const homeroom_teacher_index = (homeroom_teacher_id) ? teachers.findIndex(teacher => teacher.teacher_id === homeroom_teacher_id) : null;
								const homeroom_teacher_info = (homeroom_teacher_id && homeroom_teacher_index !== -1) ? teachers[homeroom_teacher_index] : null;
								const homeroom_teacher_first_name = (homeroom_teacher_info) ? homeroom_teacher_info.first_name : null;
								const homeroom_teacher_last_name = (homeroom_teacher_info) ? homeroom_teacher_info.name : null;
								const homeroom_teacher_name = (homeroom_teacher_id) ? `${homeroom_teacher_last_name}, ${homeroom_teacher_first_name}` : null;

								background_info = {homeroom_teacher_name:homeroom_teacher_name};
							}
							else if(currentView === 'student')
							{
								const student_grade = display_info.grade;

								const homeroom_teacher_id = display_info.homeroom_teacher_id;
								const homeroom_teacher_index = (homeroom_teacher_id) ? teachers.findIndex(teacher => teacher.teacher_id === homeroom_teacher_id) : null;
								const homeroom_teacher_info = (homeroom_teacher_id && homeroom_teacher_index !== -1) ? teachers[homeroom_teacher_index] : null;
								const homeroom_teacher_first_name = (homeroom_teacher_info) ? homeroom_teacher_info.first_name : null;
								const homeroom_teacher_last_name = (homeroom_teacher_info) ? homeroom_teacher_info.name : null;
								const homeroom_teacher_name = (homeroom_teacher_id) ? `${homeroom_teacher_last_name}, ${homeroom_teacher_first_name}` : null;

								background_info = {grade:student_grade, homeroom_teacher_name:homeroom_teacher_name};
							}

							const data_open = openDisplayData.includes(data_id);

							return (
								<div className='mb-elem-teacher-schedule' key={display_index}>
									<div className='mb-elem-teacher-row'>
										<div className='mb-elem-teacher-info'>
											<h1 className='mb-elem-teacher-name'>{display_name}</h1>
											{currentView === 'teacher' ? 
											(
											<>
											{(display_info.is_homeroom_teacher === '1') &&
												<div className='mb-elem-homeroom-info mb-hide-during-print'>
													<div className='mb-elem-homeroom-info-line'><FontAwesomeIcon icon={faChalkboard}/><span>{background_info.teacher_classroom ? background_info.teacher_classroom : 'No classroom set'}</span></div>
													<div className='mb-elem-homeroom-info-line'><FontAwesomeIcon icon={faUserGraduate}/> {background_info.number_homeroom_students} homeroom students</div>
												</div>
											}
											</>
											):(currentView === 'classroom') ?
											(
											<>
											<div className='mb-elem-homeroom-info mb-hide-during-print'>
												<div className='mb-elem-homeroom-info-line'><FontAwesomeIcon icon={faChalkboardTeacher}/><span>{background_info.homeroom_teacher_name ? background_info.homeroom_teacher_name : 'No teacher set'}</span></div>
											</div>
											</>
											):(currentView === 'student') ?
											(
											<>
											<div className='mb-elem-homeroom-info mb-hide-during-print'>
												{background_info.grade &&
													<div className='mb-elem-homeroom-info-line'><FontAwesomeIcon icon={faGraduationCap}/>{background_info.grade ? `Grade ${background_info.grade}` : 'No grade level set'}</div>
												}
												<div className='mb-elem-homeroom-info-line'><FontAwesomeIcon icon={faChalkboardTeacher}/><span>{background_info.homeroom_teacher_name ? background_info.homeroom_teacher_name : 'No homeroom teacher set'}</span></div>
											</div>
											</>
											):null}
											<FontAwesomeIcon icon={faEdit} className='gray-link mb-elem-edit-teacher-info mb-hide-during-print' onClick={() => toggleEditData(data_id)}/>
										</div>
										<div className='text-align-right mb-hide-during-print' onClick={() => handleToggleOpenDisplayData(data_id)}>
											<FontAwesomeIcon icon={data_open ? faChevronUp : faChevronDown} />
										</div>
									</div>
									<div className={`mb-elem-conflicts-link ${data_conflicts.length > 0 ? 'red' : 'green'}-link underline-dashed`} onClick={() => toggleOpenConflicts(data_id)}>{data_conflicts.length} conflicts/warnings</div>
									{openConflicts.includes(data_id) &&
										<div>
										{data_conflicts.map((conflict, conflict_index) => {
											return (
												<div className='mb-elem-conflict red-text' key={`${data_id}-${conflict_index}`}>
													<FontAwesomeIcon icon={faCircleExclamation} />
													<div>{conflict.display}</div>
												</div>
											)
										})}
										</div>
									}
									{data_open &&
										<>
										<div className='mb-elem-week-schedule-container'>
											<div className='mb-elem-week-schedule-time-container'>
												<div className='mb-elem-time-delimiter'>
													<div>7:00AM</div>
												</div>
												<div className='mb-elem-time-delimiter'>
													<div>8:00AM</div>
												</div>
												<div className='mb-elem-time-delimiter'>
													<div>9:00AM</div>
												</div>
												<div className='mb-elem-time-delimiter'>
													<div>10:00AM</div>
												</div>
												<div className='mb-elem-time-delimiter'>
													<div>11:00AM</div>
												</div>
												<div className='mb-elem-time-delimiter'>
													<div>12:00PM</div>
												</div>
												<div className='mb-elem-time-delimiter'>
													<div>1:00PM</div>
												</div>
												<div className='mb-elem-time-delimiter'>
													<div>2:00PM</div>
												</div>
												<div className='mb-elem-time-delimiter'>
													<div>3:00PM</div>
												</div>
												<div className='mb-elem-time-delimiter'>
													<div>4:00PM</div>
												</div>
											</div>
											<div className='mb-elem-week-schedule-days-container'>
												{days.map(day_number => {
													const day_name = `Day ${day_number}`;

													const day_schedule = (currentView === 'teacher') ? blocks.filter(block => (block.teacher_id === data_id || block.homeroom_teacher_id === data_id || block.secondary_teachers.includes(data_id) || (block.is_pullout === '1' && block.student_list.filter(block_student_id => background_info.teacher_homeroom_students.includes(block_student_id)).length > 0)) && block.day_number == day_number) : ((currentView === 'classroom') ? blocks.filter(block => block.classroom_id === data_id && block.day_number == day_number) : ((currentView === 'student') ? blocks.filter(block => (block.student_list.includes(data_id) && block.day_number == day_number)) : []));

													///// Organize all the blocks into overlapping groups /////
													// Any non overlapping blocks get thrown into their own group
													const day_schedule_with_overlaps = day_schedule.reduce((results, block) => {
														const block_info = cloneObj(block);
														const block_start_time = block.start_time;
														const block_end_time = block.end_time;

														const block_start_time_array = block_start_time.split(':');
														const block_end_time_array = block_end_time.split(':');

														const block_start_time_hour = parseInt(block_start_time_array[0]);
														const block_start_time_min = parseInt(block_start_time_array[1]);
														const block_start_num_hours = block_start_time_hour - 7;
														const block_row_start = Math.max(1, block_start_num_hours*60 + block_start_time_min);

														const block_end_time_hour = parseInt(block_end_time_array[0]);
														const block_end_time_min = parseInt(block_end_time_array[1]);
														const block_end_num_hours = block_end_time_hour - 7;
														const block_row_end = Math.max(1, block_end_num_hours*60 + block_end_time_min);

														block_info.row_start = block_row_start;
														block_info.row_end = block_row_end;
														block_info.block_length = block_row_end - block_row_start;

														results.push(block_info);
														return results;
													},[]);

													const sorted_day_schedule_with_overlaps = sortArrayOfObjects(day_schedule_with_overlaps, 'block_length', 'number', 'desc');

													const logged_positions = {};

													return (
														<div className='mb-elem-week-day-container' key={`${data_id}-${day_number}`}>
															<h3 className='mb-elem-week-day-header'>{day_name}</h3>
															<div className='mb-elem-week-day-block-parent-container'>
																<div className='mb-elem-week-day-grid'>
																	{sorted_day_schedule_with_overlaps.map(block => {
																		const block_id = block.block_id;
																		const course_id = block.course_id;
																		const is_lunch = block.is_lunch;
																		const is_pullout = block.is_pullout;
																		const is_special = block.is_special;
																		const is_locked = (currentView !== 'student') ? (block.locked === '1') : display_info.student_locked_blocks.includes(block_id);

																		const course_info = courses.find(course => course.course_id === course_id);
																		const course_code = (course_info) ? course_info.course_code : null;
																		const block_name = (course_id && course_info) ? `${course_info.name} ${course_code && `(${course_code})`}`: ((is_lunch === '1') ? 'Lunch' : 'Untitled');

																		const block_teacher_id = block.teacher_id;
																		const block_homeroom_teacher_id = block.homeroom_teacher_id;
																		const current_teacher_is_teacher = (currentView === 'teacher') ? (block_teacher_id === data_id) : false;

																		const teacher_info = (block_teacher_id) ? teachers.find(temp_teacher => temp_teacher.teacher_id === block_teacher_id) : null;
																		const teacher_last_name = (teacher_info) ? teacher_info.name : '';
																		
																		const homeroom_teacher_info = (block_homeroom_teacher_id) ? teachers.find(temp_teacher => temp_teacher.teacher_id === block_homeroom_teacher_id) : null;
																		const homerooom_teacher_last_name = (homeroom_teacher_info) ? homeroom_teacher_info.name : '';

																		// Figure out the name to show for the course
																		let block_name_formatted = null;
																		if(currentView === 'teacher')
																		{
																			if(is_special === '1') 
																			{
																				if(current_teacher_is_teacher)
																				{
																					block_name_formatted = `${course_code} ${homerooom_teacher_last_name && `- ${homerooom_teacher_last_name}`}`;
																				}
																				else
																				{
																					block_name_formatted = `${block_name} ${teacher_last_name && `- ${teacher_last_name}`}`;
																				}
																			}
																			else
																			{
																				if(current_teacher_is_teacher)
																				{
																					block_name_formatted = block_name;
																				}
																				else
																				{
																					block_name_formatted = `${block_name} ${teacher_last_name && `- ${teacher_last_name}`}`;
																				}
																			}
																		}
																		else if(currentView === 'classroom')
																		{
																			if(block_teacher_id !== block_homeroom_teacher_id)
																			{
																				block_name_formatted = `${block_name} ${teacher_last_name && `- ${teacher_last_name}`} ${homerooom_teacher_last_name && `- ${homerooom_teacher_last_name}`}`
																			}
																			else
																			{
																				block_name_formatted = `${block_name} ${teacher_last_name && `- ${teacher_last_name}`}`;
																			}
																		}
																		else if(currentView === 'student')
																		{
																			block_name_formatted = `${block_name} ${teacher_last_name && `- ${teacher_last_name}`}`;
																		}
																		
																		const start_time = block.start_time;
																		const end_time = block.end_time;
																		const start_time_formatted = formatTime(start_time);
																		const end_time_date_formatted = formatTime(end_time);

																		const row_start = block.row_start;
																		const row_end = block.row_end;

																		const block_color = (is_lunch === '1') ? 'pastel-yellow' : ((is_special === '1') ? 'green' :((is_pullout === '1') ? 'orange' : 'dark-purple'));

																		// Calculate the column start and end position on the grid
																		const block_position = calculateBlockColumnStartEnd(block, day_schedule_with_overlaps, logged_positions, start_time, end_time);
																		
																		const position_index = block_position.position_index;
																		const col_start = block_position.col_start;
																		const col_end = block_position.col_end;

																		logged_positions[block_id] = position_index;

																		if(!row_start || !row_end || !col_start || !col_end) return null;
																		
																		return (
																			<div className='mb-elem-block parent-div' style={{gridColumnStart:col_start, gridColumnEnd:col_end, gridRowStart:row_start, gridRowEnd:row_end}} key={block_id} onClick={(!blockData && currentView !== 'student') ? () => toggleEditBlock(block_id) : null} data-blockid={block_id}>
																				<div className={`mb-elem-block-inner left-border-${block_color}-thick ${blockData && blockData.block_id === block_id ? `${block_color}-border mb-elem-block-selected` : ''}`} >
																					<div className={`mb-elem-block-header`}>
																						<span className='tooltip' data-tooltip-text={block_name_formatted}>
																							<div className='mb-elem-block-name ellipsis'>{block_name_formatted}</div>
																						</span>
																						<FontAwesomeIcon className='mb-elem-remove-block small-text cursor-pointer' icon={faTimes} onClick={(currentView === 'student') ? (e) => removeStudentBlock(data_id, block_id, e) : (e) => removeBlock(block_id, e)}/>
																					</div>
																					<div className={`mb-elem-block-content`}>
																						<div className='tooltip' data-tooltip-text={`${start_time_formatted} - ${end_time_date_formatted}`}>
																							<div className='mb-elem-block-time ellipsis'>{start_time_formatted} - {end_time_date_formatted}</div>
																						</div>
																					</div>
																					<div className='elem-lock-block-btn'>
																						{(is_locked) ? 
																						(
																							<FontAwesomeIcon className='cursor-pointer bright-yellow-text' icon={faLock} onClick={(currentView === 'student') ? ()=>toggleLockStudentBlock(data_id, block_id, '0') : ()=>toggleLockBlock(block_id, '0')}/> 
																						):
																						(
																							<FontAwesomeIcon className='opaque-link dark-gray-text' icon={faUnlock} onClick={(currentView === 'student') ? ()=>toggleLockStudentBlock(data_id, block_id, '1') : () => toggleLockBlock(block_id, '1')}/>
																						)}
																					</div>
																				</div>
																			</div>
																		)
																	})}
																</div>
																{currentView === 'teacher' &&
																	<div className='mb-add-new-section parent-div' ref={add_new_block_ref} data-teacherid={data_id} data-daynumber={day_number} key={`${data_id}-${day_number}`}>
																		<div className='mb-elem-add-block-container blue-link mb-hide-during-print' onClick={() => handleToggleAddNewBlock(data_id, day_number)}>
																			<FontAwesomeIcon icon={faPlusCircle} />
																			<div className='medium-text'>Add Block</div>
																		</div>
																		{(addNewBlockIsOpen && data_id === addBlockOpenDataID && day_number === addBlockOpenDayNumber) &&
																			<div className='dropdown-options-container mb-add-new-block-options-container parent-div' style={{top:'27px'}}>
																				<div className='dropdown-option left-border-dark-purple-thick' onClick={() => createNewBlock('block', data_id, day_number)}>Add Block</div>
																				<div className='dropdown-option left-border-green-thick' onClick={() => createNewBlock('special', data_id, day_number)}>Add Special</div>
																				<div className='dropdown-option left-border-orange-thick' onClick={() => createNewBlock('pullout', data_id, day_number)}>Add Pull Out</div>
																				<div className='dropdown-option left-border-pastel-yellow-thick' onClick={() => createNewBlock('lunch', data_id, day_number)}>Add Lunch</div>
																			</div>
																		}
																	</div>
																}
															</div>
														</div>
													)
												})}
											</div>
										</div>
										</>
									}
								</div>
							)
						})}	
						{(currentView === 'teacher' && teacher_counter > maxTeachersToShow) && 
							<div className='btn blue-btn load-more-btn' onClick={showMoreTeachers}>Load More Teachers</div>
						}
						{(currentView === 'student' && student_counter > maxStudentsToShow) && 
							<div className='btn blue-btn load-more-btn' onClick={showMoreStudents}>Load More Students</div>
						}
						</>
					)}			
				</div>
			</div>
		</DndProvider>
		</>
	)
}