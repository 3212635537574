import React from 'react';
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGraduate, faBook, faChalkboardTeacher, faChalkboard, faCalendarDay, faDiagnoses } from '@fortawesome/free-solid-svg-icons';

import DatabaseDataStudents from './school_data/database_data_students';
import DatabaseDataCourses from './school_data/database_data_courses';
import DatabaseDataTeachers from './school_data/database_data_teachers';
import DatabaseDataClassrooms from './school_data/database_data_classrooms';
import DatabaseDataStudentRequests from './school_data/database_data_student_requests';
import DatabaseDataSections from './school_data/database_data_sections';

import manage_data from '../../images/manage_data.png';

import '../../css/school_data.css';

export default function SchoolData({ match, schoolInfo, scheduleInfo, scheduleStructureData, setLoadScreen, schoolType, departments, teachersLoading, teachers, setTeachers, coursesLoading, courses, setCourses, classroomsLoading, classrooms, setClassrooms, studentsLoading, students, setStudents, sectionsLoading, sections, setSections, subsections, setSubsections, lunchInfo, labels, setLabels, handlePullNewData }) {
	const history = useHistory();

	//////////////////////
	///// FUNCTIONS /////
	/////////////////////
	const goToScreen = (screen) => {
		sessionStorage.clear();

		history.push(`/app/${schoolType}/school_data/${screen}/`);
		setLoadScreen('school_data');
	}
	
	return (
		<div id='school-data-main-container'>
			<div id='school-data-sidebar'>
				<div className='secondary-sidebar-header'>Data</div>
				<div className={`school-data-sidebar-btn ${match.params.screen === 'courses' ? 'school-data-sidebar-btn-selected' : ''}`} onClick={(match.params.screen !== 'courses') ? (() => goToScreen('courses')) : null}>
						<FontAwesomeIcon className='school-data-sidebar-icon' icon={faBook}/>
						<div className='school-data-sidebar-step-name'>Courses</div>
				</div>
				<div className={`school-data-sidebar-btn ${match.params.screen === 'teachers' ? 'school-data-sidebar-btn-selected' : ''}`} onClick={(match.params.screen !== 'teachers') ? (() => goToScreen('teachers')) : null}>
						<FontAwesomeIcon className='school-data-sidebar-icon' icon={faChalkboardTeacher}/>
						<div className='school-data-sidebar-step-name'>Teachers</div>
				</div>
				<div className={`school-data-sidebar-btn ${match.params.screen === 'classrooms' ? 'school-data-sidebar-btn-selected' : ''}`} onClick={(match.params.screen !== 'classrooms') ? (() => goToScreen('classrooms')) : null}>
						<FontAwesomeIcon className='school-data-sidebar-icon' icon={faChalkboard}/>
						<div className='school-data-sidebar-step-name'>Classrooms</div>
				</div>
				<div className={`school-data-sidebar-btn ${match.params.screen === 'students' ? 'school-data-sidebar-btn-selected' : ''}`} onClick={(match.params.screen !== 'students') ? (() => goToScreen('students')) : null}>
						<FontAwesomeIcon className='school-data-sidebar-icon' icon={faUserGraduate}/>
						<div className='school-data-sidebar-step-name'>Students</div>
				</div>
				<div className={`school-data-sidebar-btn ${match.params.screen === 'requests' ? 'school-data-sidebar-btn-selected' : ''}`} onClick={(match.params.screen !== 'requests') ? (() => goToScreen('requests')) : null}>
						<FontAwesomeIcon className='school-data-sidebar-icon' icon={faDiagnoses}/>
						<div className='school-data-sidebar-step-name'>Requests</div>
				</div>
				<div className={`school-data-sidebar-btn ${match.params.screen === 'sections' ? 'school-data-sidebar-btn-selected' : ''}`} onClick={(match.params.screen !== 'sections') ? (() => goToScreen('sections')) : null}>
						<FontAwesomeIcon className='school-data-sidebar-icon' icon={faCalendarDay}/>
						<div className='school-data-sidebar-step-name'>Sections</div>
				</div>
			</div>
			<div id='school-data-main-content'>
				{(() => {
					switch(match.params.screen) {
						case 'courses':
							return <DatabaseDataCourses schoolInfo={schoolInfo} databaseData={courses}  setDatabaseData={setCourses} setStudents={setStudents} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} teachers={teachers} students={students} classrooms={classrooms} departments={departments} sections={sections} setSections={setSections} labels={labels} dataIsLoading={coursesLoading} handlePullNewData={handlePullNewData}/>;
						case 'teachers':
							return <DatabaseDataTeachers schoolInfo={schoolInfo} databaseData={teachers}  setDatabaseData={setTeachers} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} courses={courses} classrooms={classrooms} students={students} sections={sections} departments={departments} dataIsLoading={teachersLoading} handlePullNewData={handlePullNewData}/>;
						case 'classrooms':
							return <DatabaseDataClassrooms schoolInfo={schoolInfo} databaseData={classrooms}  setDatabaseData={setClassrooms} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} courses={courses} teachers={teachers} sections={sections} departments={departments} dataIsLoading={classroomsLoading} handlePullNewData={handlePullNewData}/>;
						case 'students':
							return <DatabaseDataStudents schoolInfo={schoolInfo} databaseData={students} setDatabaseData={setStudents} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} courses={courses} teachers={teachers} departments={departments} sections={sections} labels={labels} dataIsLoading={studentsLoading} handlePullNewData={handlePullNewData}/>;
						case 'requests':
							return <DatabaseDataStudentRequests schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} coursesLoading={coursesLoading} courses={courses} studentsLoading={studentsLoading} students={students} setStudents={setStudents} sections={sections} setSections={setSections} departments={departments} handlePullNewData={handlePullNewData}/>;
						case 'sections':
							return <DatabaseDataSections schoolInfo={schoolInfo} databaseData={sections} setDatabaseData={setSections} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} courses={courses} setCourses={setCourses} teachers={teachers} setTeachers={setTeachers} classrooms={classrooms} students={students} setStudents={setStudents} departments={departments} sections={sections} setSections={setSections} subsections={subsections} setSubsections={setSubsections} labels={labels} setLabels={setLabels} lunchInfo={lunchInfo} dataIsLoading={sectionsLoading}/>;
						default:
							return (
								<div id='school-data-welcome-screen'>
									<img id='school-data-welcome-screen-image' src={manage_data} alt='Manage Data Img'/>
									<h1 className='main-header'>Manage Your Data</h1>
									<p className='subheader' style={{margin:'-20px auto 50px auto'}}>Control all your school's data in one place</p>
								</div>
							);
					}
				})()}
			</div>
		</div>
	);
}