import React, { useState , useRef } from 'react';
import { Link } from "react-router-dom";
import swal from 'sweetalert';

import Button from '../../Button';

import { getData, updateDepartment, sortArrayOfObjects, isEmpty, useOutsideClick } from '../../../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock, faTimes, faCheck, faEdit, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

export default function AssignDepartments({ isLoading, subscreen, addSupervisorToDepartment, removeSupervisorFromDepartment, openSupervisorsList, closeSupervisorsList, departments, setDepartments, supervisors, userInfo, schoolInfo, rolePages, setRolePages, handleMoveToNextScreen }) {
	const ref = useRef();

	const [showAdd, setShowAdd] = useState(false);
	
	/////////////////////////////
	///// UTILITY FUNCTIONS /////
	/////////////////////////////
	const handleFocus = (event) => event.target.select();
	
	useOutsideClick(ref, () => {
		const department_id = ref.current.closest('.department-row').getAttribute('data-id');
		closeSupervisorsList(department_id);
	});
	
	const toggleEdit = (department_id) => {
		const department_index = departments.findIndex(department => department.department_id === department_id);
		const department_info = departments[department_index];
		
		if('editing' in department_info)
		{
			delete department_info.editing;
		}
		else
		{
			department_info.editing = true;
		}
		
		sessionStorage.setItem('departments', JSON.stringify(departments));
		setDepartments([...departments]);
	}
	
	const toggleShowAdd = () => {
		setShowAdd(!showAdd);
	}
	
	const saveUpdate = (e) => {
		const department_id = e.target.closest('.department-row').getAttribute('data-id');
		const department = e.target.closest('.edit-department-row-container').getElementsByClassName('edit-department-input')[0].value;
		
		updateDepartment({update_type:'update', school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, department_id:department_id, department:department}).then(data => {
			const department_index = departments.findIndex(department => department.department_id === department_id);
			const department_info = departments[department_index];
			
			if('editing' in department_info) delete department_info.editing;
			
			department_info.department = department;
			
			sessionStorage.setItem('departments', JSON.stringify(departments));
			setDepartments([...departments]);
		});
	}
	
	const addNewDepartment = (e) => {
		if(e.key !== 'Enter' && e.type !== 'click') return;
		
		const department = document.getElementById("new-department-name").value;
		
		if(!department)
		{
			document.getElementById("new-department-name").classList.add('error');	
			return false;
		}
		
		updateDepartment({update_type:'add', school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, department:department}).then(data => {
			departments.push({department_id:data.department_id, department:department, supervisors:[]});
		
			// Remove add info
			document.getElementById("new-department-name").value = "";
			setShowAdd(false);
			
			sessionStorage.setItem('departments', JSON.stringify(departments));
			setDepartments([...departments]);
			
			// Since a new department was added that needs to go through all the steps, update all future screens as not being complete now
			getData('schoolProgress', '/get-school-progress', {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true).then(user_progress_data => {
				const role_pages = user_progress_data.role_pages;
				
				setRolePages({...role_pages});
			});
		});
	}
	
	const deleteDepartment = async (department_id) => {
		const options =  {
			title: "Are you sure?",
			text: "Do you really want to delete this department? Teachers, courses, and classrooms in this department will not be deleted, but will be removed from this department.",
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			}
		}
		
		const delete_department_bool = await swal(options);
		
		if(delete_department_bool)
		{
			updateDepartment({update_type:'delete', school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, department_id:department_id}).then(data => {
				const department_index = departments.findIndex(department => department.department_id === department_id);
			
				//Remove department from object
				if(department_index !== -1) departments.splice(department_index, 1);
				
				//Save departments back to session storage
				sessionStorage.setItem('departments', JSON.stringify(departments));
				setDepartments([...departments]);
				
				const options =  {
					title: "Success!",
					text: "You have succcesfully removed this department",
					icon: "success",
					buttons: {
						cancel: {
							text: "Okay",
							value: false,
							visible: true,
							className: 'gray-btn'
						},
					}
				}
				
				swal(options);
			});
		}
	}

	//console.log({departments});
	//console.log({rolePages});
	
	const displayDepartments = (isLoading) ? [] : sortArrayOfObjects(departments, 'department', 'text', 'asc');

	const subscreen_index = (rolePages) ? rolePages['assign_supervisors']['subscreens'].findIndex(temp_subscreen => temp_subscreen.subscreen === subscreen) : -1;
	const subscreen_is_complete = (subscreen_index !== -1) ? rolePages['assign_supervisors']['subscreens'][subscreen_index].complete : false;
	const current_user_is_admin = (userInfo.role_id === '1'|| userInfo.role_id === '2' || userInfo.role_id === '5');
	
	return (
		<>
		{isLoading ?
			(
				<div className='center-text'>
					<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
				</div>
			): (current_user_is_admin) ?
			(
				<>
				{userInfo.role_id === '5' ?
					(
						<>
						<h1 className='progress-main-header'>Manage Departments</h1>
						<p className='progress-subheader'>In this step you can add, edit, and delete your departments.</p>
						</>
					):
					(
						<>
						<h1 className='progress-main-header'>Manage Departments</h1>
						<p className='progress-subheader'>In this step you can add, edit, or remove departments from your school. You can also assign responsibility of scheduling to department supervisors (if you want). Just leave supervisors blank if you'd like full control over scheduling yourself.</p>
						</>
					)
				}
				<h1>Departments</h1>
				<div id='assign-resources-department-list' style={{maxWidth:'400px'}}>
					{displayDepartments.map((department_info, index) => {
						const department_id = department_info.department_id;
						const department = department_info.department;
						const department_supervisors = department_info.supervisors;
						const editing = ('editing' in department_info) ? true : false;
						const supervisors_open = ('supervisors_open' in department_info) ? department_info.supervisors_open : false;
						
						return (
							<div className='department-row' key={index} data-id={department_id}>
								{!editing ?
									(
										<>
										<FontAwesomeIcon className='assign-resources-remove-department' icon={faTimesCircle} onClick={() => deleteDepartment(department_id)}/>
										<div className='assign-resources-department'>
											{department}<FontAwesomeIcon className='edit-department parent-icon' icon={faEdit} onClick={() => toggleEdit(department_id)}/>
										</div>
										{userInfo.role_id !== '5' &&
											<>
											<div className='assign-supervisor-supervisors-container'>
												{isEmpty(department_supervisors) &&
													<div className='department-supervisor-inner-container'>
														<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
														<div className='department-supervisor-name'>No Department Supervisor</div>
													</div>
												}
												{department_supervisors.map((supervisor_info, index) => {
													const supervisor_user_id = supervisor_info.user_id;
													
													return (
														<div className='department-supervisor-inner-container' key={index}>
															{supervisor_info.img_url &&
																<img className='department-supervisor-img' src={require(`../../../images/users/${supervisor_info.img_url}`)} alt='supervisor' />
															}
															{!supervisor_info.img_url &&
																<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
															}
															<div className='department-supervisor-name'>{supervisor_info.last_name}, {supervisor_info.first_name}</div>
															<FontAwesomeIcon className='assign-supervisor-icon gray-text-hover' icon={faTimes} onClick={() => removeSupervisorFromDepartment(supervisor_user_id, department_id)}/>
														</div>
													)
												})}
											</div>
											<div className='assign-supervisor-add-container click-restricted' onClick={() => openSupervisorsList(department_id)}>
												<div className='assign-supervisor-add-text click-restricted'>Assign a Department Supervisor</div>
											</div>
											<div className='supervisor-options-container'>
												{supervisors_open &&
													<div className='data-options-container' ref={ref}>
														{supervisors.map((supervisor_info, index) => {
															
															return (
																<div className='data-options-option assign-resourses-supervisor-option' key={index} onClick={() => addSupervisorToDepartment(supervisor_info, department_id)}>
																	{supervisor_info.img_url ?
																	(
																		<img className='department-supervisor-img' src={require(`../../../images/users/${supervisor_info.img_url}`)} alt='supervisor' />
																	):
																	(
																		<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
																	)}
																	<div className='department-supervisor-name'>{supervisor_info.last_name}, {supervisor_info.first_name}</div>
																</div>
															)	
														})}
														<Link className='data-options-option blue-text' to={{pathname:`/app/hsms/settings/users`, redirect:`/app/hsms/progress/assign_supervisors/assign_departments`}} style={{borderTop:'1px solid #efefef'}} onClick={() => closeSupervisorsList(department_id)}>Add new Supervisor</Link>
													</div>
												}
											</div>
											</>
										}
										</>
									):
									(
										<div className="edit-department-row-container">
											<div>
												<div className='assign-resources-adding-label'>Department Name</div>
												<input className='edit-department-input' defaultValue={department} autoFocus={true} onFocus={handleFocus} />
											</div>
											<FontAwesomeIcon className='x-cancel assign-resources-cancel-new-btn' icon={faTimes} onClick={() => toggleEdit(department_id)}/>
											<FontAwesomeIcon className='checkmark-submit assign-resources-add-new-btn' icon={faCheck}  onClick={saveUpdate}/>
										</div>
									)
								}
							</div>
						)
					})}
					{showAdd ?
						(
							<div className="assign-resources-add-row-container">
								<div className="edit-department-row-container">
									<div>
										<div className='assign-resources-adding-label'>New Department Name</div>
										<input id='new-department-name' className='edit-department-input' onKeyDown={addNewDepartment} autoFocus={true}/>
									</div>
									<FontAwesomeIcon className='x-cancel assign-resources-cancel-new-btn parent-icon' icon={faTimes} onClick={toggleShowAdd}/>
									<FontAwesomeIcon className='checkmark-submit assign-resources-add-new-btn parent-icon' icon={faCheck}  onClick={addNewDepartment}/>
								</div>
							</div>
						):
						(
							<div className='add-assign-resources-row' onClick={toggleShowAdd}>Add New Department</div>
						)
					}
					<div className='fixed-bottom-save-container'>
						<Button classes={['btn', 'btn-extra-large', 'green-btn', 'align-right', 'move-to-next-screen-btn']} selected={false} text='Continue to Data Cleaning' onClickFunction={handleMoveToNextScreen} />
					</div>
				</div>
				</>
			): (userInfo && !current_user_is_admin && !subscreen_is_complete) ?
			(
				<div className='locked-screen-notification-container'>
					<FontAwesomeIcon className='blue-text' icon={faLock} style={{fontSize:'50px'}}/>
					<h1>Locked</h1>
					<p>This step is only accessible to the master scheduler. You should be notified when future steps are unlocked!</p>
					<Link to='/progress' className='blue-text'>Go Back</Link>
				</div>
			) : (userInfo && !current_user_is_admin && subscreen_is_complete) ? 
			(
				<div className='locked-screen-notification-container'>
					<FontAwesomeIcon className='green-text' icon={faUnlock} style={{fontSize:'50px'}}/>
					<h1>Step Unlocked!</h1>
					<p>The scheduling admin has finished assigning department supervisors, and you can now move on to the next step!</p>
				</div>
			) : null
		}
		</>
	)
}