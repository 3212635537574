import React from 'react';

import ChooseSIS from './choose_sis';
import UploadSections from './upload_sections';
import UploadCourses from './upload_courses';
import UploadTeachers from './upload_teachers';
import UploadPreviousSections from './upload_previous_sections';
import UploadStudents from './upload_students';
import UploadStudentRequests from './upload_student_requests';

import { saveSIS } from '../../../js/SaveFunctions';
import '../../../css/uploads.css';

export default function UploadData({ isLoading, schoolSubscribed, subscreenRestricted, subscreenLocked, subscreen, schoolInfo, scheduleInfo, setScheduleInfo, scheduleStructureData, lunchInfo, departments, setDepartments, coursesLoading, setCoursesLoading, courses, setCourses, teachersLoading, setTeachersLoading, teachers, setTeachers, classroomsLoading, setClassroomsLoading, classrooms, setClassrooms, studentsLoading, setStudentsLoading, students, setStudents, sectionsLoading, setSectionsLoading, sections, setSections, setSubsections, labels, setLabels, setRolePages, handleMoveToNextScreen }) {
	const handleSelectSIS = (data) => {
		const sis = data.sis;
		data.school_id = schoolInfo.school_id;
		data.schedule_version_id = schoolInfo.current_schedule_version_id;
		saveSIS(data);

		// Set the current state
		setScheduleInfo({...scheduleInfo, sis:sis});
			
		// Go to the next screen
		handleMoveToNextScreen();
	}

	//console.log({uploadState, file, showUploadMore, uploadComplete, isUploading});
	
	return (
		<div className='school-setup-main-container' style={{maxWidth:'1200px'}}>
			{isLoading ?
				(
					<div className='center-text'>
						<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
					</div>
				) :
				(
					<>
					{(() => {
						switch(subscreen) {
							case 'choose_sis':
								return <ChooseSIS subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} schoolName={schoolInfo.name} currentSIS={scheduleInfo.sis} handleSelectSIS={handleSelectSIS}/>;
							case 'upload_sections':
								return <UploadSections subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreen={subscreen} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} currentSIS={scheduleInfo.sis} lunchInfo={lunchInfo} courses={courses} setCourses={setCourses} teachers={teachers} setTeachers={setTeachers} classrooms={classrooms} students={students} setStudents={setStudents} sectionsLoading={sectionsLoading} setSectionsLoading={setSectionsLoading} sections={sections} setSections={setSections} setSubsections={setSubsections} departments={departments} labels={labels} setLabels={setLabels} setRolePages={setRolePages} handleMoveToNextScreen={handleMoveToNextScreen}/>;	
							case 'upload_courses':
								return <UploadCourses subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreen={subscreen} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} departments={departments} setDepartments={setDepartments} currentSIS={scheduleInfo.sis} coursesLoading={coursesLoading} setCoursesLoading={setCoursesLoading} courses={courses} students={students} teachers={teachers} classrooms={classrooms} sections={sections} labels={labels} setCourses={setCourses} setStudents={setStudents} setRolePages={setRolePages} handleMoveToNextScreen={handleMoveToNextScreen}/>;	
							case 'upload_teachers':
								return <UploadTeachers subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreen={subscreen} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} currentSIS={scheduleInfo.sis} setRolePages={setRolePages} teachersLoading={teachersLoading} setTeachersLoading={setTeachersLoading} courses={courses} students={students} teachers={teachers} classrooms={classrooms} setTeachers={setTeachers} departments={departments} handleMoveToNextScreen={handleMoveToNextScreen}/>;	
							case 'upload_previous_sections':
								return <UploadPreviousSections subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreen={subscreen} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} currentSIS={scheduleInfo.sis} classroomsLoading={classroomsLoading} setClassroomsLoading={setClassroomsLoading} courses={courses} teachers={teachers} classrooms={classrooms} setClassrooms={setClassrooms} departments={departments} setRolePages={setRolePages} handleMoveToNextScreen={handleMoveToNextScreen}/>;
							case 'upload_students':
								return <UploadStudents subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} schoolSubscribed={schoolSubscribed} subscreen={subscreen} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} currentSIS={scheduleInfo.sis} studentsLoading={studentsLoading} setStudentsLoading={setStudentsLoading} courses={courses} students={students} setStudents={setStudents} teachers={teachers} departments={departments} sections={sections} labels={labels} setRolePages={setRolePages} handleMoveToNextScreen={handleMoveToNextScreen}/>;
							case 'upload_student_requests':
								return <UploadStudentRequests subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} schoolSubscribed={schoolSubscribed} subscreen={subscreen} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} currentSIS={scheduleInfo.sis} departments={departments} coursesLoading={coursesLoading} courses={courses} studentsLoading={studentsLoading} students={students} setStudents={setStudents} sections={sections} setSections={setSections} setRolePages={setRolePages} handleMoveToNextScreen={handleMoveToNextScreen}/>;
							default:
								return null;
						}
					})()}
					</>
				)
			}
		</div>
	);
}