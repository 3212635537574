import React, { useState , useEffect, useRef } from 'react';
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import swal from '@sweetalert/with-react';

import '../css/select_school.css';

import { useOutsideClick } from '../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faSchool, faCheckCircle, faEllipsisV } from '@fortawesome/free-solid-svg-icons';

export default function SelectSchools({ isAuth, authComplete, schoolType, setLoadScreen, userInfo, userInfoLoading, setUserInfo, setCurrentSchoolID, schoolInfo, schoolInfoLoading, setSchoolInfo, resetState, getSchoolData, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo }) {
	let history = useHistory();
	const ref = useRef();
	
	const [isLoading, setIsLoading] = useState(true);
	
	const [showAdd, setShowAdd] = useState(false);
	
	const [addError, setAddError] = useState(null);
	const [updateError, setUpdateError] = useState(null);
	const [message, setMessage] = useState(null);
	
	const [openSchools, setOpenSchools] = useState([]);
	const [schoolsEditing, setSchoolsEditing] = useState([]);

	const [newSchoolInfo, setNewSchoolInfo] = useState({school_name:null, school_type:null});
	
	const colors = ['turquoise', 'bright-blue', 'pastel-light-purple', 'yellow'];

	useOutsideClick(ref, () => {
		const school_id = ref.current.closest('.select-school-container').getAttribute('data-schoolid');
		const school_index = openSchools.findIndex(school => school === school_id);

		if(school_index !== -1) openSchools.splice(school_index, 1);
		setOpenSchools([...openSchools]);
	});

	const toggleOpenSchool = (school_id) => {
		const school_index = openSchools.findIndex(school => school === school_id);
		
		if(school_index === -1)
		{
			openSchools.push(school_id);
		}
		else
		{
			openSchools.splice(school_index, 1);
		}
		
		setOpenSchools([...openSchools]);
	}
	
	const toggleEdit = (school_id) => {
		const school_index = schoolsEditing.findIndex(school => school === school_id);
		
		if(school_index === -1)
		{
			toggleOpenSchool(school_id);
			schoolsEditing.push(school_id);
		}
		else
		{
			schoolsEditing.splice(school_index, 1);
		}
		
		setSchoolsEditing([...schoolsEditing]);
	}
	
	///////////////////////////
	///// UPDATE FUNCTION /////
	///////////////////////////
	const createNewSchool = async (e) => {
		if(e.key !== 'Enter' && e.type !== 'click') return;
		setAddError(null);
			
		if(!newSchoolInfo.school_name)
		{
			setAddError('Your school needs to have a name!');
			
			// Erase message after 2 seconds
			setTimeout(function () {
				setAddError(null);
			}, 2000);
			return false;
		}

		if(!newSchoolInfo.school_type)
		{
			setAddError('Your school needs to be set as either a High School/Junior High School/Middle School or an Elementary School!');
			
			// Erase message after 2 seconds
			setTimeout(function () {
				setAddError(null);
			}, 2000);
			return false;
		}

		/// Show processing modal
		const options =  {
			title: "Processing...",
			buttons: false,
			closeOnClickOutside: false,
			content: (
				<div className='sweet-alert-dont-show-message'>
					<div className='loader'></div>
					<p>Please wait while we create your new school...</p>
				</div>
			)
		}
		
		swal(options);

		/// UPDATE BACKEND
		const passed_data = {update_type:'add', school_name:newSchoolInfo.school_name, school_type:newSchoolInfo.school_type};
	
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/admin/update-school'
		const myInit = { // OPTIONAL
			response: true,
			body: JSON.stringify(passed_data),
		};
		
		try {
			const response = await API.post(apiName, url, myInit);
			const response_data = response.data;
			
			const new_school_id = response_data.school_id;
			userInfo.schools.push({school_id:new_school_id, name:newSchoolInfo.school_name, type:newSchoolInfo.school_type});
			
			swal.close();
			setShowAdd(false);
			setMessage(response_data.message);
			setUserInfo({...userInfo});
			
			// Erase message after 2 seconds
			setTimeout(function () {
				setMessage(null);
			}, 2000);
		} catch(e)
		{
			console.log(e);
		}
	}

	const copySchool = async (school_id) => {
		// Show loading modal
		const options =  {
			title: "Copying school...",
			buttons: false,
			closeOnClickOutside: false,
			content: (
				<div className='sweet-alert-dont-show-message'>
					<div className='loader'></div>
					<p>We are copying all your data from this school. Please be patient, this can take up to a minute depending on the amount of data!</p>
				</div>
			)
		}
		
		swal(options);


		const passed_data = {school_id:school_id};
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/copy-school'
		const myInit = { // OPTIONAL
			response: true,
			body: JSON.stringify(passed_data),
		};
		
		try {
			const response = await API.post(apiName, url, myInit);
			const response_data = response.data;
			const status = response_data.status;

			if(status === 'error')
			{
				const failure_options =  {
					icon:"error",
					title: "This feature is not available",
					text: response_data.message,
					dangerMode: true,
				}

				swal(failure_options);
				return false;
			}

			const new_school_id = response_data.school_id;

			const old_school_name = userInfo.schools.find(school => school.school_id === school_id).name;
			const new_school_name = old_school_name + ' COPY';

			userInfo.schools.push({school_id:new_school_id, name:new_school_name});
			setUserInfo({...userInfo});

			const success_options =  {
				icon:"success",
				title: "School succesfully copied!",
				text: `A copy of ${old_school_name} has successfully been created`,
			}

			swal(success_options);
		} catch(e)
		{
			console.log(e);
		}
	}

	const deleteSchool = async (school_id) => {
		if(!school_id) return false;

		const school_info = userInfo.schools.find(school => school.school_id === school_id);
		const school_name = school_info.name;

		const options =  {
			title: "Are you sure?",
			text: `Do you really want to delete ${school_name}?`,
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			},
			content: (
				<div className='sweet-alert-dont-show-message'>
					<div style={{width:'330px', textAlign:'left', margin:'auto'}}>
						<p>Deleting this school will also delete any existing data at the school, including:</p>
						<ul>
							<li>Teachers</li>
							<li>Courses</li>
							<li>Classrooms</li>
							<li>Schedules</li>
						</ul>
						<p>Are you <span className='red-text'>absolutely sure</span> you want to delete {school_name}?</p>
					</div>
				</div>
			)
		}
		
		if(await swal(options))
		{
			const options =  {
				title: "Processing...",
				buttons: false,
				closeOnClickOutside: false,
				content: (
					<div className='sweet-alert-dont-show-message'>
						<div className='loader'></div>
						<p>Please wait while we delete this school...</p>
					</div>
				)
			}
			
			swal(options);

			const passed_data = {update_type:'delete', school_id:school_id};
		
			const apiName = process.env.REACT_APP_ENDPOINT_NAME;
			const url = '/admin/update-school'
		    const myInit = { // OPTIONAL
		        response: true,
		        body: JSON.stringify(passed_data),
		    };
		    
		    try {
				const response = await API.post(apiName, url, myInit);
				const response_data = response.data;
				
				const school_index = userInfo.schools.findIndex(school => school.school_id === school_id);
				if(school_index !== -1) userInfo.schools.splice(school_index, 1);

				const current_school_id = ('school_id' in schoolInfo) ? schoolInfo.school_id : null;
				
				// Check if any schools exist, if not reset state
				if(userInfo.schools.length === 0 || current_school_id === school_id) 
				{
					resetState();
				}
				else
				{
					setCurrentSchoolID(current_school_id);
				}
				
				swal.close();
				setShowAdd(false);
				setMessage(response_data.message);
				setUserInfo({...userInfo});
				
				// Erase message after 2 seconds
				setTimeout(function () {
			       setMessage(null);
			    }, 2000);
			} catch(e)
			{
				console.log(e);
			}
		}
	}

	const updateSchoolName = async (e, school_id) => {
		if(!school_id) return;
		if(e.key !== 'Enter' && e.type !== 'click') return;
		setAddError(null);
		
		let school_name = document.getElementById(`editing-school-${school_id}`).value;
			
		if(!school_name)
		{
			setUpdateError('Your school needs to have a name!');
			
			// Erase message after 2 seconds
			setTimeout(function () {
				setUpdateError(null);
			}, 2000);
			return false;
		}
		
		const options =  {
			title: "Processing...",
			buttons: false,
			closeOnClickOutside: false,
			content: (
				<div className='sweet-alert-dont-show-message'>
					<div className='loader'></div>
					<p>Please wait while we update the school name...</p>
				</div>
			)
		}
		
		swal(options);

		const passed_data = {update_type:'update', school_id:school_id, school_name:school_name};
	
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/admin/update-school'
		const myInit = { // OPTIONAL
			response: true,
			body: JSON.stringify(passed_data),
		};
		
		try {
			const response = await API.post(apiName, url, myInit);
			const response_data = response.data;
			
			const school_index = userInfo.schools.findIndex(school => school.school_id === school_id);
			if(school_index !== -1) userInfo.schools[school_index]['name'] = school_name;
			
			// Close editing
			const editing_index = schoolsEditing.findIndex(school => school === school_id);
			if(editing_index !== -1) schoolsEditing.splice(editing_index, 1);
			
			const current_school_id = ('school_id' in schoolInfo) ? schoolInfo.school_id : null;
			if(current_school_id === school_id)
			{
				schoolInfo.name = school_name;
				setSchoolInfo({...schoolInfo});
			}
			
			swal.close();
			setShowAdd(false);
			setMessage(response_data.message);
			setUserInfo({...userInfo});
			
			// Erase message after 2 seconds
			setTimeout(function () {
				setMessage(null);
			}, 2000);
		} catch(e)
		{
			console.log(e);
		}
	}
	
	const selectSchool = (school_id) => {
		const current_school_id = ('school_id' in schoolInfo) ? schoolInfo.school_id : null;
		if(current_school_id === school_id)
		{
			if(userInfo.role_id === '4')
			{
				setLoadScreen('magnetboard');
				history.push(`/app/${schoolType}/magnetboard`);
			}
			else
			{
				setLoadScreen('progress');
				history.push(`/app/${schoolType}/progress`);
			}
			
			return;
		}

		sessionStorage.clear();
		 
		const school_params = {school_id:school_id, user_role_id:userInfo.role_id, schedule_version_id:null};
		getSchoolData(school_params, (school_info_data) => {
			const school_type = (school_info_data.type === '1') ? 'hsms' : ((school_info_data.type === '2') ? 'elementary' : null);
			const load_screen = (school_params.user_role_id === '4' || school_info_data.type === '2') ? 'magnetboard' : 'progress';

			setLoadScreen(load_screen);
			history.push(`/app/${school_type}/${load_screen}`);
		});
	}
	
	//////////////////////////////
	///// DO ON STATE CHANGE /////
	//////////////////////////////
	useEffect(() => {
		// Check if user is logged in
		// If not, send them to log in screen with a redirect to this screen
		// If yes, check if user has permission
		// If not permission, redirect to loading page
		if(authComplete && !isAuth)
		{
			history.push({
				pathname: '/app/login',
				state: {redirect: '/app/select_school'}
			});
		}
		else if(authComplete && !userInfoLoading && !schoolInfoLoading)
		{
			setIsLoading(false);
		}
	}, [isAuth, authComplete, userInfoLoading, schoolInfoLoading, history]);

	useEffect(() => {
		setScreenAllowsUniversalSidebar(false);
		setUniversalSidebarLoadingInfo(null);
	}, [setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo]);
	
	const current_school_id = ('school_id' in schoolInfo) ? schoolInfo.school_id : null;
	const show_add_new_schools = ((userInfo.role_id === '1' || userInfo.role_id === '2') || (userInfo.role_id === '5' && userInfo.schools.length === 0)) ? true : false;
	
	//console.log({newSchoolInfo});

	return (
		<>
		<div id='select-school-container'>
			{isLoading ?
				(
					<div className='center-text'>
						<img src={require('../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
					</div>
				):
				(
					<>
					<h1 className='subscreen-main-header'>Select a School</h1>
					<p className='subscreen-subheader dark-blue-text'>Which school would you like to work on?</p>
					{userInfo.schools.length === 0 &&
						<div id='select-school-no-schools-container'>
							<FontAwesomeIcon className='gray-text' style={{fontSize:'160px'}} icon={faSchool}/>
							<p className='gray-text'>No schools have been added yet</p>
						</div>
					}
					<div id='select-school-list'>
						{userInfo.schools.map((school_info, index) => {
							const school_id = school_info.school_id;
							const name = school_info.name;
							const school_type = school_info.type;
							
							const color_index = index % colors.length;
							const color = colors[color_index];
							const selected = (school_id === current_school_id);
							const school_open = openSchools.includes(school_id);
							const school_editing = schoolsEditing.includes(school_id);
							
							return (
								<div className={`select-school-container parent-div click-restricted ${selected ? `${color}-border border-2` : ''}`} key={school_id} data-schoolid={school_id}>
									<FontAwesomeIcon className='select-school-more-options gray-to-dark-blue-link click-restricted' icon={faEllipsisV} onClick={() => toggleOpenSchool(school_id)}/>
									<div className='select-school-more-options-container'>
										{school_open &&
											<div className='data-options-container' ref={ref}>
												<div className='data-options-option' onClick={() => toggleEdit(school_id)}>Change School Name</div>
												<div className='data-options-option' onClick={() => copySchool(school_id)}>Copy School</div>
												<div className='data-options-option' onClick={(e) => deleteSchool(school_id)}>Delete School</div>
											</div>
										}
									</div>
									<FontAwesomeIcon className={`${color}-text`} style={{fontSize:'100px'}} icon={faSchool}/>
									{school_editing ?
										(
											<div className='select-school-name-editing'>
												<div>
													<div className='assign-resources-adding-label'>School Name</div>
													<input id={`editing-school-${school_id}`} className={`update-school-name-input ${updateError ? 'error' : ''}`} defaultValue={name} onKeyDown={(e) => updateSchoolName(e, school_id)}/>
													{updateError && 
														<div className='error-message-no-box'>{updateError}</div>
													}
												</div>
												<div className='select-school-editing-btn-container'>
													<FontAwesomeIcon className='x-cancel' icon={faTimes} onClick={() => toggleEdit(school_id)}/>
													<FontAwesomeIcon className='checkmark-submit' icon={faCheck} onClick={(e) => updateSchoolName(e, school_id)}/>
												</div>
											</div>
										):
										(
											<h2 className='dark-blue-text select-school-name'>{name}</h2>
										)
									}
									{selected &&
										<FontAwesomeIcon className={`school-selected-check ${color}-text`} icon={faCheckCircle}/>
									}
									<div className={`btn ${color}`} onClick={() => selectSchool(school_id)}>{selected ? 'Continue Working' : 'Select'}</div>
									{(school_type === '1') ? 
									(
										<div className='dark-gray-text small-text select-school-school-type'>High/Junior/Middle School</div>
									):(school_type === '2') ?
									(
										<div className='dark-gray-text small-text select-school-school-type'>Elementary School</div>
									):null}
								</div>
							)
						})}
					</div>
					{message &&
						<div className='success-message-no-box' style={{marginTop:'15px'}}>{message}</div>
					}
					{show_add_new_schools &&
						<>
						{showAdd &&
							<div id="add-new-school-container">
								<div>
									<div className='assign-resources-adding-label'>School Name</div>
									<input id='new-school-name' className={`edit-department-input ${addError ? 'error' : ''}`} onKeyDown={(e) => createNewSchool(e)} onChange={(e) => setNewSchoolInfo({...newSchoolInfo, ...{school_name:e.target.value}})}/>
									<div className='assign-resources-adding-label' style={{marginTop:'15px'}}>School Type</div>
									<div className='text-align-left'>
										<div className={`option-btn medium-text ${newSchoolInfo.school_type === '1' && 'option-btn-selected'}`} onClick={() => setNewSchoolInfo({...newSchoolInfo, ...{school_type:'1'}})}>High School, Junior High School, or Middle School</div>
										<div className={`option-btn medium-text ${newSchoolInfo.school_type === '2' && 'option-btn-selected'}`} onClick={() => setNewSchoolInfo({...newSchoolInfo, ...{school_type:'2'}})}>Elementary School</div>
									</div>
									{addError && 
										<div className='error-message-no-box'>{addError}</div>
									}
								</div>
								<div id='add-new-school-btn-container'>
									<div className='btn gray-to-red-btn select-school-add-new-btn'  onClick={() => setShowAdd(!showAdd)}>
										<FontAwesomeIcon icon={faTimes}/>
									</div>
									<div className='btn gray-to-green-btn select-school-add-new-btn' onClick={(e) => createNewSchool(e)}>
										<FontAwesomeIcon  icon={faCheck}/>
									</div>
								</div>
							</div>
						}
						{!showAdd &&
							<div className='add-assign-resources-row' onClick={() => setShowAdd(!showAdd)}>Add New School</div>
						}
						</>
					}
					</>
				)
			}
		</div>
		</>
	);
}