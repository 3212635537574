import React, { useState , useEffect } from 'react';
import Dropdown from '../../Dropdown';

import { clearProcessFeedback, continueProcess } from '../../../js/Helpers';

import '../../../css/hsms/edit_modals.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function EditUploadHeaders({ schoolInfo, progressID, selectedSchedules, feedbackData, setFeedbackData, setShowHeadersFeedback, setUploadState, cancelUpload, fileUpload, handleCheckUpload, onCompleteHeaders }) {
	const [isLoading, setIsLoading] = useState(true);
	const [requiredDataOptions, setRequiredDataOptions] = useState([]);
	const [excelStartIndex, setExcelStartIndex] = useState(0);
	const [errorMessages, setErrorMessages] = useState([]);

	const changeMatchedHeader = (i, new_data_id) => {
		// Iterate through file headers (first the required headers and then )
		// If file header currently fulfills the required header, remove it
		// If file header is the one being changed, set it as fulfilling the required header

		let feedback_data = (new_data_id) ? feedbackData.data.headers.reduce((results, header, j) => {
			const data_id = header.data_id;
			if(data_id === new_data_id) results.data.headers[j].data_id = "";

			return results;
		}, feedbackData): feedbackData;

		feedback_data.data.headers[i].data_id = new_data_id;

		setFeedbackData({...feedback_data});
	}

	const submitHeaders = () => {
		// Reset all errors
		setErrorMessages([]);

		// Collect all required headers
		const required_headers = feedbackData.data.required_headers;
		
		// Check that all required headers have been set to a file header
		let all_required_headers_submitted = true;
		const missing_data = [];

		for(const required_header_id in required_headers)
		{
			const header_index = feedbackData.data.headers.findIndex(header => header.data_id === required_header_id);
			
			if(header_index === -1) 
			{
				const missing_data_display_name = required_headers[required_header_id];
				
				missing_data.push(missing_data_display_name);
				all_required_headers_submitted = false;
			}
		}

		// Check that at least one of the required conditional header groups have been completely set to respective file headers
		const missing_parent_group = [];
		for(const [parent_index, conditional_parent_group] of feedbackData.data.required_conditional_headers.entries())
		{
			let some_group_is_complete = false;

			for(const [conditional_child_group] of conditional_parent_group)
			{
				let this_group_is_complete = true;
				
				for(const [data_id, data_display_name] of Object.entries(conditional_child_group))
				{
					const header_index = feedbackData.data.headers.findIndex(header => header.data_id === data_id);

					if(header_index === -1)  this_group_is_complete = false;
				}

				if(this_group_is_complete) some_group_is_complete = true;
			}

			if(!some_group_is_complete) 
			{
				all_required_headers_submitted = false;
				missing_parent_group.push(parent_index);
			}
		}

		if(all_required_headers_submitted)
		{
			// Create comma delimited list of schedule version ids
			const selected_schedules = selectedSchedules.join(',');

			const data = {school_id:schoolInfo.school_id, progress_id:progressID, data_version_id:schoolInfo.current_data_version_id, schedule_version_ids:selected_schedules, process_type:feedbackData.process_type, data:feedbackData.data};

			// Reset state, clear feedback, and keep upload going with the headers submitted
			setUploadState(null);
			clearProcessFeedback(data);
			continueProcess(data);
			handleCheckUpload(feedbackData.process_type, fileUpload, true, () => {
				onCompleteHeaders();
			});
			setShowHeadersFeedback(false);
		}
		else
		{
			const error_messages = [];
			for(const missing_data_name of missing_data)
			{
				error_messages.push(`Missing data: Please let us know which excel column has ${missing_data_name} data by setting the dropdown`);
			}

			for(const parent_index of missing_parent_group)
			{
				const conditional_parent_group = feedbackData.data.required_conditional_headers[parent_index];
				let error_message_pieces = [];

				for(const [conditional_child_group] of conditional_parent_group)
				{
					const conditional_child_group_array_of_values = Object.values(conditional_child_group);
					const joined_child_group = conditional_child_group_array_of_values.join(" , ");
					error_message_pieces.push(joined_child_group);
				}

				const full_error_message = "Missing data: Please let us know which excel column has either " + error_message_pieces.join(" data OR ") + ' data by setting the dropdowns';
				error_messages.push(full_error_message);
			}

			setErrorMessages([...error_messages]);
		}
	}

	///////////////////////////////////////
	///// DO ON LOAD OF FEEDBACK DATA /////
	///////////////////////////////////////
	useEffect(()=> {
		if(feedbackData) 
		{
			// Create dropdown options for all required data
			let required_data_options = Object.keys(feedbackData.data.required_headers).reduce((results, data_id) => {
				const data_display_name = feedbackData.data.required_headers[data_id];

				results.push({value:data_id, display:data_display_name});
				return results;
			}, [{value:"", display:'--'}]);

			// Add required conditional data
			for(const conditional_parent_group of feedbackData.data.required_conditional_headers)
			{
				for(const conditional_child_group of conditional_parent_group)
				{
					for(const list_of_conditional_data_object of conditional_child_group)
					{
						for(const [data_id, data_display_name] of Object.entries(list_of_conditional_data_object)) {
							required_data_options.push({value:data_id, display:data_display_name});
						}
					}
				}
			}

			// Add all optional data
			required_data_options = Object.keys(feedbackData.data.optional_headers).reduce((results, data_id) => {
				const data_display_name = feedbackData.data.optional_headers[data_id];

				results.push({value:data_id, display:data_display_name});
				return results;
			}, required_data_options);

			setRequiredDataOptions([...required_data_options]);
			setIsLoading(false);
		}
	},[feedbackData]);

	const columnLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N','O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
	
	const max_columns_to_show = 5;
	let num_columns_shown = 1;

	const parent_group_status = {};
	for(const [parent_index, conditional_parent_group] of feedbackData.data.required_conditional_headers.entries())
	{
		let some_group_is_complete = false;

		for(const [conditional_child_group] of conditional_parent_group)
		{
			let this_group_is_complete = true;
			
			for(const [data_id, data_display_name] of Object.entries(conditional_child_group))
			{
				const header_index = feedbackData.data.headers.findIndex(header => header.data_id === data_id);

				if(header_index === -1)  this_group_is_complete = false;
			}

			if(this_group_is_complete) some_group_is_complete = true;
		}

		parent_group_status[parent_index] = some_group_is_complete;
	}

	return (
		<div className='modal'>
			<div className='modal-content' style={{width:'930px',textAlign:'left', padding:'5px 35px 15px 35px'}}>
				<span className="modal-close-right" onClick={() => setShowHeadersFeedback(false)}>&times;</span>
				<h2>Excel Upload</h2>
				{isLoading ?
				(
					<div className='center-text'>
						<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
					</div>
				):
				(
					<>
					<div className='medium-text'>These are the columns we pulled from your Excel sheet. We've used our algorithms to guess what data each column holds, but please confirm that it's right before continuing the upload!</div>
					<div id='edit-upload-headers-excel-sheet-parent-container'>
						<div>
							{excelStartIndex > 0 &&
								<FontAwesomeIcon className='cursor-pointer' icon={faChevronLeft} onClick={() => setExcelStartIndex(excelStartIndex - 5)}/>
							}
						</div>
						<div id='edit-upload-headers-excel-sheet'>
							<div className='edit-upload-headers-excel-column'>
								<div className='edit-upload-headers-excel-filler-row light-gray'></div>
								<div className='edit-upload-headers-excel-filler-row light-gray'></div>
								<div className='edit-upload-headers-excel-filler-row light-gray'></div>
								<div className='edit-upload-headers-excel-filler-row light-gray'></div>
							</div>
							{feedbackData.data.headers.map((header, i) => {
								if(i < excelStartIndex) return null;
								if(num_columns_shown > max_columns_to_show) return null;

								const header_name = header.display_name;
								const data_id = header.data_id;

								const example_data = feedbackData.data.values[header_name];

								num_columns_shown++;

								return (
									<div className='edit-upload-headers-excel-column' key={i}>
										<div className='edit-upload-headers-excel-row-header'>{columnLetters[i]}</div>
										<div className='edit-upload-headers-excel-row-title' title={`${header_name}`}><span className='ellipsis' title={header_name}>{header_name}</span></div>
										<div className='edit-upload-headers-excel-row'>{example_data && <span className='ellipsis' title={example_data}>{example_data}</span>}</div>
										<div className='edit-upload-headers-excel-row' style={{padding:'10px'}}>
											<div className='small-text' style={{margin:'0px 0px 6px 0px'}}>What data does this column hold?</div>
											<Dropdown className='edit-upload-headers-required-dropdown' data={requiredDataOptions} currentValue={data_id} handleChange={(new_data_id) => changeMatchedHeader(i, new_data_id)}/>
										</div>
									</div>
								)
							})}
						</div>
						<div>
							{((feedbackData.data.headers.length) > (excelStartIndex + 5)) &&
								<FontAwesomeIcon className='cursor-pointer' icon={faChevronRight} onClick={() => setExcelStartIndex(excelStartIndex + 5)}/>
							}
						</div>
					</div>
					<div class="medium-text" style={{margin: '40px 0px 0px 0px'}}>There should be a column in your Excel sheet that holds each of the following:</div>
					<div id='edit-upload-headers-required-data-parent-container'>
						<div className='edit-upload-headers-required-data-inner-container'>
							<div><strong><u>Required Data for Edario</u></strong></div>
							{Object.keys(feedbackData.data.required_headers).map((key, i) => {
								const header_name = feedbackData.data.required_headers[key];
								const header_has_been_set = feedbackData.data.headers.findIndex(header => header.data_id === key) !== -1;

								return (
									<div className='edit-upload-headers-required-data-row' key={key}>{header_name} <FontAwesomeIcon icon={faCheckCircle} className={`edit-upload-required-data-checkmark ${header_has_been_set ? 'green-text' : 'light-gray-text'}`}/></div>
								)
							})}
							{feedbackData.data.required_conditional_headers.map((header_combos, i) => {
								const num_header_combos = header_combos.length;
								
								return (
									<div className='edit-upload-headers-required-data-row' key={i}>
										<div>
											{header_combos.map((header_combo_array, j) => {
												const header_combo = header_combo_array[0];
												const is_last_header_combo = (num_header_combos - 1 == j);

												return (
													<span key={j}>
														{Object.keys(header_combo).map((key, k) => {
															const header_combo_name = header_combo[key];

															return (
																<div>{header_combo_name}</div>
															)
														})}
														{!is_last_header_combo && <div style={{margin:'7px 0px', fontSize:'12px'}}>- OR -</div>}
													</span>
												)
											})}
										</div>
										<FontAwesomeIcon icon={faCheckCircle} className={`edit-upload-required-data-checkmark ${parent_group_status[i] ? 'green-text' : 'light-gray-text'}`}/>
									</div>
								)
							})}
						</div>
						<div className='edit-upload-headers-required-data-inner-container'>
							<div><strong><u>Optional Data for Edario</u></strong></div>
							{Object.keys(feedbackData.data.optional_headers).map((key, i) => {
								const header_name = feedbackData.data.optional_headers[key];
								const header_has_been_set = feedbackData.data.headers.findIndex(header => header.data_id === key) !== -1;

								return (
									<div className='edit-upload-headers-required-data-row' key={key}>{header_name} <FontAwesomeIcon icon={faCheckCircle} className={`edit-upload-required-data-checkmark ${header_has_been_set ? 'green-text' : 'light-gray-text'}`}/></div>
								)
							})}
						</div>
					</div>
					{errorMessages.length > 0 &&
						<div className='edit-upload-headers-error-container'>
							{errorMessages.map((error_message, i) => (
								<div className='error-message-no-box text-align-left' key={i}>{error_message}</div>
							))}
							<div>If none of the columns in your excel sheet hold this data, please consider uploading a new file!</div>
						</div>
					}
					<div className='edit-upload-headers-btn-container'>
						<div className='btn gray-btn' onClick={cancelUpload}>Cancel Upload</div>
						<div className='btn green-btn' onClick={submitHeaders}>Submit Headers</div>
					</div>
					</>
				)
				}
			</div>
		</div>
	);
}