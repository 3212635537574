import React, { useEffect, useState } from 'react';
import Fuse from 'fuse.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';

export default function EditSectionSecondaryTeacher({ teachers, courses, sectionID, courseID, secondaryTeacherID, teachersAvailability, handleLoadAvailability, changeSecondaryTeacher = () => {}, removeSecondaryTeacher = () => {}}) {
	const [currentSecondaryTeacherID, setCurrentSecondaryTeacherID] = useState(secondaryTeacherID);
	const [teacherName, setTeacherName] = useState('');
	const [suggestedSearchResults, setSuggestedSearchResults] = useState([]);
	const [searchResults, setSearchResults] = useState([]);
	const [isEditing, setIsEditing] = useState(false);

	const handleFocus = (event) => event.target.select();

	const handleSelect = (secondary_teacher_id) => {
		changeSecondaryTeacher({section_id:sectionID, old_secondary_teacher_id:currentSecondaryTeacherID, new_secondary_teacher_id:secondary_teacher_id});

		setCurrentSecondaryTeacherID(secondary_teacher_id);
		setIsEditing(false);
		setSuggestedSearchResults([]);
		setSearchResults([]);
	}

	const handleRemove = () => {
		removeSecondaryTeacher({section_id:sectionID, secondary_teacher_id:currentSecondaryTeacherID});

		setSuggestedSearchResults([]);
		setSearchResults([]);
	}

	const toggleEditing = () => {
		setIsEditing(!isEditing);
	}
	
	const fuzzySearch = (e) => {
		const search_value = e.target.value;
		
		/////////////////////////////////
		///// GET SUGGESTED RESULTS /////
		/////////////////////////////////
		let suggested_results = [];
		
		// Search only through teacher can teach teachers
		if(courseID)
		{
			const teachers_can_teach = courses.find(course => course.course_id === courseID).teachers_can_teach;
			const courses_teachers = [];
			
			teachers_can_teach.forEach(tct_info => {
				const tct_teacher_id = tct_info.teacher_id;
				const tct_teacher = teachers.find(teacher => teacher.teacher_id === tct_teacher_id);
				
				courses_teachers.push(tct_teacher);
			});
			
			const fuse = new Fuse(courses_teachers, {
				keys: ['first_name', 'name'],
				threshold: .1
			});
			
			const results = fuse.search(search_value);
			suggested_results = results.map(result => result.item);
		}
		
		//////////////////////////////
		///// GET SEARCH RESULTS /////
		//////////////////////////////
		const fuse = new Fuse(teachers, {
			keys: ['first_name', 'name'],
			threshold: .1
		});
		
		const results = fuse.search(search_value);
		const teacher_results = results.map(result => result.item).slice(0,4);
		
		///////////////////////
		///// SET RESULTS /////
		///////////////////////
		setSuggestedSearchResults(suggested_results);
		setSearchResults(teacher_results);
	}

	useEffect(() => {
		const teacher_info = teachers.find(teacher => teacher.teacher_id === currentSecondaryTeacherID);
		const teacher_name = (teacher_info) ? `${teacher_info.name}, ${teacher_info.first_name}` : '';
		setTeacherName(teacher_name);
	},[currentSecondaryTeacherID, teachers]);

	useEffect(() => {
		setCurrentSecondaryTeacherID(secondaryTeacherID);
	}, [secondaryTeacherID]);
	
	return (
		<div className='edit-section-data-row-container edit-section-secondary-teacher-container'>
			{!isEditing ?
				(
					<>
					<div className='edit-section-data-row'>
						<div>{(secondaryTeacherID !== '') ? <span className='edit-section-current-data'>{teacherName} <span><FontAwesomeIcon icon={faTimes} className='medium-text gray-to-red-link' onClick={() => handleSelect('')}/></span></span> : '--'}</div>
						<div className='blue-link medium-text' onClick={toggleEditing}>{(secondaryTeacherID !== '') ? 'Change' : 'Search'}...</div>
					</div>
					<div className='red-link small-text' onClick={handleRemove}>Remove</div>
					</>
				) :
				(
					<div>
						<div className='search-add-search-container'>
							<div className='search-add-adding-input-container'>
								<input id='main-teacher-input' className='edit-section-input' placeholder='Search for a teacher' defaultValue={teacherName} onChange={(e) => fuzzySearch(e, 'main', 'teachers')} autoFocus={true} onFocus={handleFocus}/>
								<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
							</div>
							<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes}  onClick={() => toggleEditing('main', 'teacher')}/>
						</div>
						{(suggestedSearchResults.length !== 0) &&
							<>
							<div className='search-add-did-you-mean'>Teachers Who Can Teach:</div>
							<div className='search-add-search-results'>
								{suggestedSearchResults.map((teacher, i) => {
									const teacher_availability_index = teachersAvailability.findIndex(teacher_availability => teacher_availability.teacher_id === teacher.teacher_id);
									const teacher_available = (teacher_availability_index !== -1) ? teachersAvailability[teacher_availability_index].availability : false;

									return (
										<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 80px auto'}}>
											<div>{teacher.name}, {teacher.first_name}</div>
											<div className={`availability-hover small-text ${teacher_available ? 'green-text' : 'red-text'}`} onMouseEnter={(e) => handleLoadAvailability(e, 'teacher', teacher.teacher_id)} onMouseLeave={(e) => handleLoadAvailability(e, 'teacher', null)}>
												{teacher_available ? 'Available' : 'Unavailable'}
											</div>
											<div>
												<div className='search-add-add-btn' onClick={() => handleSelect(teacher.teacher_id)}>Select</div>
											</div>
										</div>
									)
								})}
							</div>
							</>
						}
						{searchResults.length !== 0 &&
							<>
							<div className='search-add-did-you-mean'>Other Teachers:</div>
							<div className='search-add-search-results'>
								{searchResults.map((teacher, i) => {
									const teacher_availability_index = teachersAvailability.findIndex(teacher_availability => teacher_availability.teacher_id === teacher.teacher_id);
									const teacher_available = (teacher_availability_index !== -1) ? teachersAvailability[teacher_availability_index].availability : false;
									
									return (
										<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 80px auto'}}>
											<div>{teacher.name}, {teacher.first_name}</div>
											<div className={`availability-hover small-text ${teacher_available ? 'green-text' : 'red-text'}`} onMouseEnter={(e) => handleLoadAvailability(e, 'teacher', teacher.teacher_id)} onMouseLeave={(e) => handleLoadAvailability(e, 'teacher', null)}>{teacher_available ? 'Available' : 'Unavailable'}</div>
											<div>
												<div className='search-add-add-btn' onClick={() => handleSelect(teacher.teacher_id)}>Select</div>
											</div>
										</div>
									)
								})}
							</div>
							</>
						}
					</div>
				)											
			}
		</div>
	);
}