import React, { useState , useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';

import { isEmpty, verifyUser } from '../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

import '../css/login.css';

export default function LogIn({ LoginScreen = 'signin', isAuth, authComplete, username, newPassword, onUsernameChange, onPasswordChange, onNewPasswordChange, onVerificationCodeChange, handleLogin, handleCompleteNewPassword, handleForgotPassword, handleForgotPasswordSubmit, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo }) {
	const location = useLocation();
	const history = useHistory();
	
	const { register, handleSubmit, errors } = useForm();
	
	const [isProcessing, setIsProcessing] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [redirect, setRedirect] = useState(null);
	const [screen, setScreen] = useState(LoginScreen);
	
	const [passwordHidden, setPasswordHidden] = useState(true);
	const [newPasswordHidden, setNewPasswordHidden] = useState(true);
	const [confirmPasswordHidden, setConfirmPasswordHidden] = useState(true);
	
	const switchScreens = (screen) => {
		setErrorMessage(null);
		setScreen(screen);
		
		togglePasswordHidden();
	}
	
	const togglePasswordHidden = (password_type = null) => {
		if(password_type === 'password')
		{
			setPasswordHidden(!passwordHidden);
		}
		else if(password_type === 'confirm')
		{
			setConfirmPasswordHidden(!confirmPasswordHidden);
		}
		else if(password_type === 'new')
		{
			setNewPasswordHidden(!newPasswordHidden);
		}
		else
		{
			setPasswordHidden(true);
			setConfirmPasswordHidden(true);
			setNewPasswordHidden(true);
		}
	}
	
	const letUserIn = () => {
		if(redirect)
		{
			history.push(redirect);
		}
		else
		{
			history.push('/app/');
		}
	}
	
	const onSubmit = () => {
		setIsProcessing(true);
		setErrorMessage(null);
		
		if(screen === 'signin')
		{
			handleLogin().then(user => {
				const new_password_needed = (user.challengeName === 'NEW_PASSWORD_REQUIRED') ? true : false;
				
				// If user verified send to loading screen
				if(user && !new_password_needed) 
				{
					letUserIn();
				}
				else if(new_password_needed) 
				{
					setScreen('new_password_needed');
					setIsProcessing(false);
				}
			}).catch(error => {
				setErrorMessage(error.message);
				setIsProcessing(false);
			});
			
		}
		else if(screen === 'signup')
		{
			return false;
		}
		else if(screen === 'new_password_needed')
		{
			handleCompleteNewPassword().then(user => {
				// If user verified send to loading screen
				if(user) 
				{
					verifyUser({username:username});
					letUserIn();
				}
			}).catch(error => {
				setIsProcessing(false);
				setErrorMessage(error.message);
			});
		}
		else if(screen === 'forgot_password')
		{
			handleForgotPassword().then(isAuth => {
				// If user verified send to loading screen
				setScreen('verify_code');
				setIsProcessing(false);
			}).catch(error => {
				setIsProcessing(false);
				setErrorMessage(error.message);
			});
		}
		else if(screen === 'verify_code')
		{
			handleForgotPasswordSubmit().then(user => {
				// If user verified send to loading screen
				if(user) letUserIn();
			}).catch(error => {
				setIsProcessing(false);
				setErrorMessage(error.message);
			});
		}
	}
	
	useEffect(() => {
		if(location.state) setRedirect(location.state.redirect);
	}, [location]);
	
	useEffect(() => {
		setScreenAllowsUniversalSidebar(false);
		setUniversalSidebarLoadingInfo(null);
		if(!authComplete && !isAuth) history.push('/app/');
	}, [authComplete, isAuth, history, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo]);
	
	return (
		<>
		{(authComplete || isProcessing) &&
			<>
			<div style={{width:'100%',textAlign:'center',margin:'150px 0px 0px 0px'}}>
				<a href='/'>
					<div style={{width:'fit-content',display:'grid', gridTemplateColumns:'auto 1fr', columnGap:'15px', alignItems:'center', margin:'auto'}}>
						<img style={{height:'49px'}} src={require("../images/logo-dark.png")} alt='Edario Logo'/>
						<h1 style={{color:'#2c3e50', fontSize:'3.5em'}}>edario</h1>
					</div>
				</a>
			</div>
			<form id="signin_form" name="signin_form" onSubmit={handleSubmit(onSubmit)}>
				{screen === 'signin' &&
					<div id="signin_inputs" className="inputs">
						<div className='input-label'>Email</div>
					  	<input id="signin_email" name="signin_email" ref={register({required: true})} onChange={onUsernameChange}/>
					  	{errors.signin_email && <div className='form-warning' >This field is required</div>}
						<div className='input-label'>Password</div>
						<div className='login-input-container'>
						  	<input type={`${passwordHidden ? 'password' : 'text'}`} id="signin_password" name="signin_password" ref={register({required: true})} onChange={onPasswordChange}/>
						  	{passwordHidden ?
							  	(
								  	<FontAwesomeIcon className='login-toggle-hidden-icon gray-to-blue-link' icon={faEye} onClick={() => togglePasswordHidden('password')}/>
							  	) :
							  	(
								  	<FontAwesomeIcon className='login-toggle-hidden-icon gray-to-blue-link' icon={faEyeSlash} onClick={() => togglePasswordHidden('password')}/>
							  	)
						  	}
					  	</div>
					  	{errors.signin_password && <div className='form-warning' >This field is required</div>}
					  	{errorMessage &&
						  	<div className='error-message-no-box login-error'>{errorMessage}</div>
					  	}
					  	{(!isProcessing || !isEmpty(errors))  ?
						  	(
							  	<button type='submit' className="btn blue-btn login-btn">Sign In</button>
						  	) :
						  	(
							  	<div className="btn blue-btn login-btn">
							  		<img style={{height:'20px'}} src={require("../images/ajax-loader.gif")} alt='loading...'/>
							  	</div>
						  	)
					  	}
					</div>
				}
				{screen === 'signup' &&
					<div id="signup_inputs" className="inputs">
						<div className='input-label'>First Name</div>
						<input id="first_name" name="first_name" ref={register({required: true})}/>
						<div className='input-label'>Last Name</div>
				      	<input id="last_name" name="last_name" ref={register({required: true})}/>
						<div className='input-label'>Email</div>
				      	<input id="signup_email" name="signup_email" ref={register({required: true})}/>
						<div className='input-label'>Create New Password</div>
						<div className='login-input-container'>
				      		<input type={`${passwordHidden ? 'password' : 'text'}`} id="signup_password" name="signup_password" ref={register({required: true})}/>
				  			{passwordHidden ?
							  	(
								  	<FontAwesomeIcon className='login-toggle-hidden-icon gray-to-blue-link' icon={faEye} onClick={() => togglePasswordHidden('password')}/>
							  	) :
							  	(
								  	<FontAwesomeIcon className='login-toggle-hidden-icon gray-to-blue-link' icon={faEyeSlash} onClick={() => togglePasswordHidden('password')}/>
							  	)
						  	}
					  	</div>
						<div className='input-label'>Confirm Password</div>
						<div className='login-input-container'>
				      		<input type={`${confirmPasswordHidden ? 'password' : 'text'}`} id="signup_password_retype" name="signup_password_retype" ref={register({required: true})}/>
	      					{confirmPasswordHidden ?
							  	(
								  	<FontAwesomeIcon className='login-toggle-hidden-icon gray-to-blue-link' icon={faEye} onClick={() => togglePasswordHidden('confirm')}/>
							  	) :
							  	(
								  	<FontAwesomeIcon className='login-toggle-hidden-icon gray-to-blue-link' icon={faEyeSlash} onClick={() => togglePasswordHidden('confirm')}/>
							  	)
						  	}
					  	</div>
				      	{errorMessage &&
						  	<div className='error-message-no-box login-error'>{errorMessage}</div>
					  	}
					  	{(!isProcessing || !isEmpty(errors)) ?
						  	(
							  	<button type='submit' className="btn blue-btn login-btn">Sign Up</button>
						  	) :
						  	(
							  	<div className="btn blue-btn login-btn">
							  		<img style={{height:'20px'}} src={require("../images/ajax-loader.gif")} alt='loading...'/>
							  	</div>
						  	)
					  	}
					</div>
				}
				{screen === 'new_password_needed' &&
					<>
					<h3 className='login-header'>Create New Password</h3>
					<p className='login-text'>Please set a new password for your account</p>
					<div id="signin_inputs" className="inputs">
						<div className='input-label'>New Password</div>
						<div className='login-input-container'>
					  		<input type={`${newPasswordHidden ? 'password' : 'text'}`} id="new_password" name="new_password" ref={register({required: true})} onChange={onNewPasswordChange}/>
			  				{newPasswordHidden ?
							  	(
								  	<FontAwesomeIcon className='login-toggle-hidden-icon gray-to-blue-link' icon={faEye} onClick={() => togglePasswordHidden('new')}/>
							  	) :
							  	(
								  	<FontAwesomeIcon className='login-toggle-hidden-icon gray-to-blue-link' icon={faEyeSlash} onClick={() => togglePasswordHidden('new')}/>
							  	)
						  	}
					  	</div>
					  	{errors.new_password && <div className='form-warning' >This field is required</div>}
					  	<div className='input-label'>Confirm New Password</div>
						<div className='login-input-container'>
					  		<input type={`${confirmPasswordHidden ? 'password' : 'text'}`} id="confirm_new_password" name="confirm_new_password" ref={register({required: true, validate: value => value === newPassword || "Passwords do not match"})}/>
					  		{confirmPasswordHidden ?
							  	(
								  	<FontAwesomeIcon className='login-toggle-hidden-icon gray-to-blue-link' icon={faEye} onClick={() => togglePasswordHidden('confirm')}/>
							  	) :
							  	(
								  	<FontAwesomeIcon className='login-toggle-hidden-icon gray-to-blue-link' icon={faEyeSlash} onClick={() => togglePasswordHidden('confirm')}/>
							  	)
						  	}
					  	</div>
					  	{errors.confirm_new_password && <div className='form-warning' >{(errors.confirm_new_password.type === 'required') ? 'This field is required' : errors.confirm_new_password.message}</div>}
					  	{errorMessage &&
						  	<div className='error-message-no-box login-error'>{errorMessage}</div>
					  	}
					  	{(!isProcessing || !isEmpty(errors))  ?
						  	(
							  	<button type='submit' className="btn blue-btn login-btn">Submit New Password</button>
						  	) :
						  	(
							  	<div className="btn blue-btn login-btn">
							  		<img style={{height:'20px'}} src={require("../images/ajax-loader.gif")} alt='loading...'/>
							  	</div>
						  	)
					  	}
					</div>
					</>
				}
				{screen === 'forgot_password' &&
					<>
					<h3 className='login-header'>Forgot your password?</h3>
					<p className='login-text'>Enter your account email below and we&rsquo;ll send you a password reset link!</p>
					<div id="signin_inputs" className="inputs">
						<div className='input-label'>Email</div>
					  	<input id="forgot_password_email" name="forgot_password_email" ref={register({required: true})} onChange={onUsernameChange}/>
					  	{errors.forgot_password_email && <div className='form-warning' >This field is required</div>}
					  	{errorMessage &&
						  	<div className='error-message-no-box login-error'>{errorMessage}</div>
					  	}
					  	{(!isProcessing || !isEmpty(errors))  ?
						  	(
							  	<button type='submit' className="btn blue-btn login-btn">Request Reset Link</button>
						  	) :
						  	(
							  	<div className="btn blue-btn login-btn">
							  		<img style={{height:'20px'}} src={require("../images/ajax-loader.gif")} alt='loading...'/>
							  	</div>
						  	)
					  	}
					</div>
					</>
				}
				{screen === 'verify_code' &&
					<>
					<h3 className='login-header'>Verify your email</h3>
					<p className='login-text'>A verification code has been sent to your email (<strong className='dark-blue-text'>{username}</strong>). Please enter this code to reset your password.</p>
					<div id="signin_inputs" className="inputs">
					  	<div className='input-label'>Verification Code</div>
					  	<input id="verify_code_code" name="verify_code_code" ref={register({required: true})} onChange={onVerificationCodeChange}/>
					  	{errors.verify_code_code && <div className='form-warning' >This field is required</div>}
					  	<div className='input-label' style={{margin:'30px 0px 0px 0px'}}>New Password</div>
						<div className='login-input-container'>
					  		<input type={`${newPasswordHidden ? 'password' : 'text'}`} id="new_password" name="new_password" ref={register({required: true})} onChange={onNewPasswordChange}/>
					  		{newPasswordHidden ?
							  	(
								  	<FontAwesomeIcon className='login-toggle-hidden-icon gray-to-blue-link' icon={faEye} onClick={() => togglePasswordHidden('new')}/>
							  	) :
							  	(
								  	<FontAwesomeIcon className='login-toggle-hidden-icon gray-to-blue-link' icon={faEyeSlash} onClick={() => togglePasswordHidden('new')}/>
							  	)
						  	}
					  	</div>
					  	{errors.new_password && <div className='form-warning' >This field is required</div>}
					  	<div className='input-label'>Confirm New Password</div>
						<div className='login-input-container'>
					  		<input type={`${confirmPasswordHidden ? 'password' : 'text'}`} id="confirm_new_password" name="confirm_new_password" ref={register({required: true, validate: value => value === newPassword || "Passwords do not match"})}/>
					  		{confirmPasswordHidden ?
							  	(
								  	<FontAwesomeIcon className='login-toggle-hidden-icon gray-to-blue-link' icon={faEye} onClick={() => togglePasswordHidden('confirm')}/>
							  	) :
							  	(
								  	<FontAwesomeIcon className='login-toggle-hidden-icon gray-to-blue-link' icon={faEyeSlash} onClick={() => togglePasswordHidden('confirm')}/>
							  	)
						  	}
					  	</div>
					  	{errors.confirm_new_password && <div className='form-warning' >{(errors.confirm_new_password.type === 'required') ? 'This field is required' : errors.confirm_new_password.message}</div>}
					  	{errorMessage &&
						  	<div className='error-message-no-box login-error'>{errorMessage}</div>
					  	}
					  	{(!isProcessing || !isEmpty(errors))  ?
						  	(
							  	<button type='submit' className="btn blue-btn login-btn">Reset Password</button>
						  	) :
						  	(
							  	<div className="btn blue-btn login-btn">
							  		<img style={{height:'20px'}} src={require("../images/ajax-loader.gif")} alt='loading...'/>
							  	</div>
						  	)
					  	}
					</div>
					</>
				}
			</form>
			{screen === 'signin' &&
				<div className="login-options">
					<div className="login-option" style={{float:'left'}} onClick={() => switchScreens('forgot_password')}>Forgot Password?</div>
					<div className="clear"></div>
				</div>
			}
			{screen === 'signup' &&
				<div className="login-options" style={{textAlign:'center'}}>
					<div>Already a User? <span className="login-option"  onClick={() => switchScreens('signin')}>Sign In</span></div>
				</div>
			}
			{screen === 'forgot_password' &&
				<div className="login-options" style={{textAlign:'center'}}>
					<div><span className="login-option"  onClick={() => switchScreens('signin')}>Back to Sign In</span></div>
				</div>
			}
			</>
		}
		</>
	);
}