import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";

import SchoolDataHSMS from './hsms/SchoolDataHSMS';
import SchoolDataElementary from './elementary/SchoolDataElementary';

export default function SchoolData({ match, isAuth, authComplete, loadScreen, schoolInfo, schoolInfoLoading, schoolType, scheduleInfo, periods, scheduleStructureData, setLoadScreen, departments, teachersLoading, teachers, setTeachers, coursesLoading, courses, setCourses, classroomsLoading, classrooms, setClassrooms, studentsLoading, students, setStudents, blocks, pulloutGroups, setPulloutGroups,  sectionsLoading, sections, setSections, subsections, setSubsections, lunchInfo, labels, setLabels, handlePullNewData, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo }) {
	const history = useHistory();

	//////////////////////////////
	///// DO ON STATE CHANGE /////
	//////////////////////////////
	useEffect(() => {
		// Check if user is logged in
		// If not, send them to log in screen with a redirect to this screen
		// If yes, check if user has permission
		// If not permission, redirect to loading page
		if(authComplete && !isAuth)
		{
			const redirect = (schoolType) ? `/app/${schoolType}/school_data` : `/app/select_school`;
			history.push({
				pathname: '/app/login',
				state: {redirect: redirect}
			});
		}
		else if(authComplete && loadScreen === 'select_school')
		{
			history.push({
				pathname: '/app/select_school',
				state: {redirect: `/app/${schoolType}/school_data`}
			});
		}
		else if(authComplete && !schoolInfoLoading)
		{
			setLoadScreen('school_data');
		}
	}, [isAuth, authComplete, schoolInfoLoading, loadScreen, history, schoolInfo, schoolType, setLoadScreen]);

	useEffect(() => {
		setScreenAllowsUniversalSidebar(false);
		setUniversalSidebarLoadingInfo(null);
	}, [setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo]);

	return (
		<>
			{(() => {
				switch(match.params.school_type) {
					case 'hsms':
						return <SchoolDataHSMS match={match} schoolInfo={schoolInfo} schoolInfoLoading={schoolInfoLoading} schoolType={schoolType} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} setLoadScreen={setLoadScreen} departments={departments} teachersLoading={teachersLoading} teachers={teachers} setTeachers={setTeachers} coursesLoading={coursesLoading} courses={courses} setCourses={setCourses} classroomsLoading={classroomsLoading} classrooms={classrooms} setClassrooms={setClassrooms} studentsLoading={studentsLoading} students={students} setStudents={setStudents} sectionsLoading={sectionsLoading} sections={sections} setSections={setSections} subsections={subsections} setSubsections={setSubsections} lunchInfo={lunchInfo} labels={labels} setLabels={setLabels} handlePullNewData={handlePullNewData}/>;
					case 'elementary':
						return <SchoolDataElementary match={match} schoolInfo={schoolInfo} schoolInfoLoading={schoolInfoLoading} schoolType={schoolType} scheduleInfo={scheduleInfo} periods={periods} setLoadScreen={setLoadScreen} teachersLoading={teachersLoading} teachers={teachers} setTeachers={setTeachers} coursesLoading={coursesLoading} courses={courses} setCourses={setCourses} classroomsLoading={classroomsLoading} classrooms={classrooms} setClassrooms={setClassrooms} studentsLoading={studentsLoading} students={students} setStudents={setStudents} blocks={blocks} pulloutGroups={pulloutGroups} setPulloutGroups={setPulloutGroups} handlePullNewData={handlePullNewData}/>;
					default:
						return null;
				}
			})()}
		</>
	);
}