import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";

import Toggle from '../../Toggle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faUserCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { cloneObj } from '../../../js/Helpers';
import { updateUser, saveSchoolInfo } from '../../../js/SaveFunctions';

import balls_loader from '../../../images/balls.gif';
import ajax_loader from '../../../images/ajax-loader.gif';

export default function Account({ redirectURL, previousLoadScreen, currentSchoolID, userInfo, userInfoLoading, setUserInfo, schoolInfo, schoolInfoLoading, setSchoolInfo, setLoadScreen }) {
	const [isLoading, setIsLoading] = useState(true);
	const [toggleState, setToggleState] = useState(false);
	
	const [currentPassword, setCurrentPassword] = useState(null);
	const [newPassword, setNewPassword] = useState(null);
	const [confirmNewPassword, setConfirmNewPassword] = useState(null);
	
	const [currentPasswordHidden, setCurrentPasswordHidden] = useState(true);
	const [newPasswordHidden, setNewPasswordHidden] = useState(true);
	const [confirmNewPasswordHidden, setConfirmNewPasswordHidden] = useState(true);
	
	const [savingSchoolInfo, setSavingSchoolInfo] = useState(false);
	const [savingUserInfo, setSavingUserInfo] = useState(false);
	const [savingNewPassword, setSavingNewPassword] = useState(false);
	
	const [saveSchoolInfoMessage, setSaveSchoolInfoMessage] = useState(null);
	const [saveUserInfoMessage, setSaveUserInfoMessage] = useState(null);
	const [saveNewPasswordMessage, setSaveNewPasswordMessage] = useState(null);
	
	const updateSchoolInfo = (e, key) => {
		const input_value = e.target.value;
		
		schoolInfo.temp_changes[key] = input_value;
		setSchoolInfo({...schoolInfo});
	}

	const updateUserInfo = (e, user_key) => {
		const input_value = e.target.value;
		
		userInfo.temp_changes[user_key] = input_value;
		setUserInfo({...userInfo});
	}

	const saveSchoolBackgroundInfo = () => {
		setSavingSchoolInfo(true);
		
		if(!schoolInfo.temp_changes.name || !schoolInfo.temp_changes.school_code)
		{
			setSavingSchoolInfo(false);
			setSaveSchoolInfoMessage({class: 'red-text', message:'School info is missing!'});
			
			setTimeout(function(){
				setSaveSchoolInfoMessage(null);
			}, 3000);
			
			return;
		}
		
		schoolInfo.name = schoolInfo.temp_changes.name;
		schoolInfo.school_code = schoolInfo.temp_changes.school_code;
		
		saveSchoolInfo(schoolInfo.temp_changes, () => {
			setSchoolInfo({...schoolInfo});
			setSavingSchoolInfo(false);
			setSaveSchoolInfoMessage({class: 'green-text', message:'Changes have been saved!'});
			
			setTimeout(function(){
				setSaveSchoolInfoMessage(null);
			}, 3000);
		});
	}
	
	const saveUserInfo = () => {
		setSavingUserInfo(true);
		
		if(!userInfo.temp_changes.first_name || !userInfo.temp_changes.last_name || !userInfo.temp_changes.email)
		{
			setSavingUserInfo(false);
			setSaveUserInfoMessage({class: 'red-text', message:'User info is missing!'});
			
			setTimeout(function(){
			   setSaveUserInfoMessage(null);
			}, 3000);
			
			return;
		}
		
		userInfo.school_id = schoolInfo.school_id;
		userInfo.update_type = 'update';
		userInfo.first_name = userInfo.temp_changes.first_name;
		userInfo.last_name = userInfo.temp_changes.last_name;
		userInfo.email = userInfo.temp_changes.email;
		
		updateUser(userInfo).then(response => {
			const status = response.status;
			const message = response.message;
			const color = (status === 'error') ? 'red-text' : 'green-text';
			
			setUserInfo({...userInfo});
			setSavingUserInfo(false);
			setSaveUserInfoMessage({class: color, message:message});
			
			setTimeout(function(){
			   setSaveUserInfoMessage(null);
			}, 3000);
		});
	}
	
	const changePassword = () => {
		setSavingNewPassword(true);
		
		///////////////////////////
		///// VALIDATE INPUTS /////
		///////////////////////////
		if(!currentPassword || !newPassword || !confirmNewPassword)
		{
			setSavingNewPassword(false);
			setSaveNewPasswordMessage({class: 'red-text', message:'Password info is missing!'});
			
			setTimeout(function(){
			   setSaveNewPasswordMessage(null);
			}, 3000);
			
			return;
		}
		else if(newPassword !== confirmNewPassword)
		{
			setSavingNewPassword(false);
			setSaveNewPasswordMessage({class: 'red-text', message:'Password confirmation does not match.'});
			
			setTimeout(function(){
			   setSaveNewPasswordMessage(null);
			}, 3000);
			
			return;
		}
		
		Auth.currentAuthenticatedUser().then(user => {
        	return Auth.changePassword(user, currentPassword, newPassword);
		}).then(data => {
			/////////////////////
			////// SUCCESS //////
			/////////////////////
			setSavingNewPassword(false);
			setSaveNewPasswordMessage({class: 'green-text', message:'Your password has been successfully updated!'});
			
			setCurrentPassword(null);
			setNewPassword(null);
			setConfirmNewPassword(null);
			
			setTimeout(function(){
			   setSaveNewPasswordMessage(null);
			}, 3000);
		}).catch(error => {
			/////////////////////
			///// AWS ERROR /////
			/////////////////////
			const message = (error.code === "NotAuthorizedException") ? 'Incorrect current password' : (error.code === "InvalidParameterException") ? 'Your password must be at least 6 characters long and contain an uppercase and lowercase letter.' : 'Could not change your password. Please try again.';
			
			setSavingNewPassword(false);
			setSaveNewPasswordMessage({class: 'red-text', message:message});
			
			setTimeout(function(){
			   setSaveNewPasswordMessage(null);
			}, 3000);
		});
	}
	
	const saveNotificationPreference = (new_state) => {
		const send_notifications = (new_state) ? '1' : '0';
		
		userInfo.update_type = 'update';
		userInfo.send_notifications = send_notifications;
		
		setToggleState(new_state);
		updateUser(userInfo);
	}
	
	//////////////////////
	///// DO ON LOAD /////
	//////////////////////
	useEffect(() => {
		if(!userInfoLoading)
		{
			const send_notifications = userInfo.send_notifications;
			const temp_changes = cloneObj(userInfo);
			
			if(send_notifications === '1') setToggleState(true);
			
			setUserInfo({...userInfo, temp_changes:temp_changes});
		}

		if(!schoolInfoLoading)
		{
			const temp_changes = cloneObj(schoolInfo);
			setSchoolInfo({...schoolInfo, temp_changes:temp_changes});
		}

		if(!userInfoLoading && !schoolInfoLoading) setIsLoading(false);
	}, [userInfoLoading, schoolInfoLoading, userInfo, schoolInfo]);
	
	//console.log(userInfo);
	//console.log(schoolInfo);
	
	return (
		<>
		{redirectURL &&
			<Link className='redirect-btn' to={redirectURL} onClick={() => setLoadScreen(previousLoadScreen)}><FontAwesomeIcon icon={faChevronLeft} className='redirect-btn-icon'/>Back to Previous Screen</Link>
		}
		<div className='page-main-heading' style={{marginTop:`${redirectURL ? '25px' : '0px'}`}}>Account Settings</div>
		<p>Manage your account settings</p>
		{isLoading ? 
			(
				<div className='center-text'>
					<img src={balls_loader} alt='loading...' style={{height:'40px'}}/>
				</div>
			):
			(
				<div id='account-settings-container'>
					{currentSchoolID &&
						<div id='account-settings-top-bar'>
							<div className='account-settings-info-group'>
								<h3>School Info</h3>
								<div className='account-settings-label'>School Name</div>
								<input className={`account-settings-input ${(saveSchoolInfoMessage && saveSchoolInfoMessage.class === 'red-text') ? 'error' : ''}`} defaultValue={schoolInfo.temp_changes.name} onChange={(e) => updateSchoolInfo(e, 'name')}/>
								<div className='account-settings-label'>School Code</div>
								<input className={`account-settings-input ${(saveSchoolInfoMessage && saveSchoolInfoMessage.class === 'red-text') ? 'error' : ''}`} defaultValue={schoolInfo.temp_changes.school_code} onChange={(e) => updateSchoolInfo(e, 'school_code')}/>
								{saveSchoolInfoMessage &&
									<div className={`account-settings-message medium-text ${saveSchoolInfoMessage.class}`}>{saveSchoolInfoMessage.message}</div>
								}
								{savingSchoolInfo ?
									(
										<div className='btn blue-btn account-settings-btn' style={{maxWidth:'300px'}}><img style={{height:'20px'}} src={ajax_loader} alt='loading...'/></div>
									):
									(
										<div className='btn blue-btn account-settings-btn' style={{maxWidth:'300px'}} onClick={saveSchoolBackgroundInfo}>Save Changes</div>
									)
								}
							</div>
						</div>
					}
					<div id='account-settings-info-container'>
						<div className='account-settings-info-group'>
							<h3>User Info</h3>
							<div id='account-settings-user-img-container'>
								{userInfo.img_url ? 
									(
										<img className='account-settings-user-img' src={require(`../../../images/users/${userInfo.img_url}`)} alt='user' />
									) :
									(
										<FontAwesomeIcon className='account-settings-user-img' icon={faUserCircle}/>
									)
								}
							</div>
							<div id='account-settings-name-container'>
								<div className='account-settings-label'>First Name</div>
								<div className='account-settings-label'>Last Name</div>
								<input className={`account-settings-input ${(saveUserInfoMessage && saveUserInfoMessage.class === 'red-text') ? 'error' : ''}`} defaultValue={userInfo.temp_changes.first_name} onChange={(e) => updateUserInfo(e, 'first_name')}/>
								<input className={`account-settings-input ${(saveUserInfoMessage && saveUserInfoMessage.class === 'red-text') ? 'error' : ''}`} defaultValue={userInfo.temp_changes.last_name} onChange={(e) => updateUserInfo(e, 'last_name')}/>
							</div>
							<div id='account-settings-email-container'>
								<div className='account-settings-label'>Email</div>
								<input className={`account-settings-input ${(saveUserInfoMessage && saveUserInfoMessage.class === 'red-text') ? 'error' : ''}`} defaultValue={userInfo.temp_changes.email} onChange={(e) => updateUserInfo(e, 'email')}/>
							</div>
							{saveUserInfoMessage &&
								<div className={`account-settings-message medium-text center-text ${saveUserInfoMessage.class}`}>{saveUserInfoMessage.message}</div>
							}
							{savingUserInfo ?
								(
									<div className='btn blue-btn account-settings-btn'><img style={{height:'20px'}} src={ajax_loader} alt='loading...'/></div>
								):
								(
									<div className='btn blue-btn account-settings-btn' onClick={saveUserInfo}>Save Changes</div>
								)
							}
						</div>
						<div className='account-settings-info-group'>
							<h3>Change Password</h3>
							<div id='account-settings-password-container'>
								<div className='account-settings-label'>Current Password</div>
								<div className='account-settings-input-container'>
									<input className={`account-settings-input ${(saveNewPasswordMessage && saveNewPasswordMessage.class === 'red-text') ? 'error' : ''}`} type={`${currentPasswordHidden ? 'password' : 'text'}`} onChange={(e) => setCurrentPassword(e.target.value)}/>
									{currentPasswordHidden ?
									  	(
										  	<FontAwesomeIcon className='account-settings-toggle-hidden-icon gray-to-blue-link' icon={faEye} onClick={() => setCurrentPasswordHidden(false)}/>
									  	) :
									  	(
										  	<FontAwesomeIcon className='account-settings-toggle-hidden-icon gray-to-blue-link' icon={faEyeSlash} onClick={() => setCurrentPasswordHidden(true)}/>
									  	)
								  	}
								</div>
								<div className='account-settings-label'>New Password</div>
								<div className='account-settings-input-container'>
									<input className={`account-settings-input ${(saveNewPasswordMessage && saveNewPasswordMessage.class === 'red-text') ? 'error' : ''}`} type={`${newPasswordHidden ? 'password' : 'text'}`} onChange={(e) => setNewPassword(e.target.value)}/>
									{newPasswordHidden ?
									  	(
										  	<FontAwesomeIcon className='account-settings-toggle-hidden-icon gray-to-blue-link' icon={faEye} onClick={() => setNewPasswordHidden(false)}/>
									  	) :
									  	(
										  	<FontAwesomeIcon className='account-settings-toggle-hidden-icon gray-to-blue-link' icon={faEyeSlash} onClick={() => setNewPasswordHidden(true)}/>
									  	)
								  	}
								</div>
								<div className='account-settings-label'>Confirm New Password</div>
								<div className='account-settings-input-container'>
									<input className={`account-settings-input ${(saveNewPasswordMessage && saveNewPasswordMessage.class === 'red-text') ? 'error' : ''}`} type={`${confirmNewPasswordHidden ? 'password' : 'text'}`} onChange={(e) => setConfirmNewPassword(e.target.value)}/>
									{confirmNewPasswordHidden ?
									  	(
										  	<FontAwesomeIcon className='account-settings-toggle-hidden-icon gray-to-blue-link' icon={faEye} onClick={() => setConfirmNewPasswordHidden(false)}/>
									  	) :
									  	(
										  	<FontAwesomeIcon className='account-settings-toggle-hidden-icon gray-to-blue-link' icon={faEyeSlash} onClick={() => setConfirmNewPasswordHidden(true)}/>
									  	)
								  	}
								</div>
							</div>
							{saveNewPasswordMessage &&
								<div className={`account-settings-message medium-text center-text ${saveNewPasswordMessage.class}`}>{saveNewPasswordMessage.message}</div>
							}
							{savingNewPassword ?
								(
									<div className='btn blue-btn account-settings-btn'><img style={{height:'20px'}} src={ajax_loader} alt='loading...'/></div>
								):
								(
									<div className='btn blue-btn account-settings-btn' onClick={changePassword}>Change Password</div>
								)
							}
						</div>
					</div>
					<div className='account-settings-info-group'>
						<h3>Notifications</h3>
						<div id='account-settings-notifications-container'>
							<div>
								<div style={{fontWeight:'bold'}}>Send notifications</div>
								<div className='medium-text'>Allow email notifications to be sent when progress is made in Edario.</div>
							</div>
							<div>
								<Toggle toggleOn={toggleState} handleChange={(new_state) => saveNotificationPreference(new_state)}/>
							</div>
						</div>
					</div>
				</div>
			)
		}
		</>
	);
}