import React, { memo, useState, useEffect } from 'react';

import { capitalizeFirstLetters } from '../../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboard, faUserGraduate, faCalendarDay, faClock, faCalendarTimes} from '@fortawesome/free-solid-svg-icons';

export default memo(function MagnetboardQuickViewHSMS ({ sectionID, scheduleStructureData, scheduleType, scheduleInfo, currentViewType, currentDay, sections, subsections, teachers, courses, classrooms, conflicts, handleSelectQuickViewDataID }) {
	const [sectionInfo, setSectionInfo] = useState({section_number:null, section_type:'1', section_id:null, course_id:null, classroom_id:null, is_manual:'0', is_lab:'0', subsections:[]});
	const [courseName, setCourseName] = useState(null);
	const [teacherName, setTeacherName] = useState(null);
	const [classroomName, setClassroomName] = useState(null);
	const [numStudents, setNumStudents] = useState(0);
	const [uniqueDays, setUniqueDays] = useState([]);
	const [quarter1Bool, setQuarter1Bool] = useState(false);
	const [quarter2Bool, setQuarter2Bool] = useState(false);
	const [quarter3Bool, setQuarter3Bool] = useState(false);
	const [quarter4Bool, setQuarter4Bool] = useState(false);
	const [applicableConflicts, setApplicableConflicts] = useState([]);

	useEffect(()=>{
		let section_id = sectionID;
		let section_info = null;

		// If section is subsection, get main section info
		const section_with_subsection_index = subsections.findIndex(subsection_section => subsection_section.subsection_id === section_id);
		const section_is_subsection = (section_with_subsection_index !== -1);

		if(section_is_subsection) section_id = subsections[section_with_subsection_index].section_id;

		section_info = sections.find(section => section.section_id === section_id);

		if(!section_info) return null;

		// If main teacher happens to be the subection teacher too, don't display both
		const main_section_teacher_id = section_info.teacher_id;

		const main_teacher_info = teachers.find(teacher => teacher.teacher_id === main_section_teacher_id);
		const main_teacher_name = (main_teacher_info) ? `${main_teacher_info.name}, ${main_teacher_info.first_name}` : null;
		
		const section_type = section_info.section_type;
		const course_id = section_info.course_id;
		const classroom_id = section_info.classroom_id;
		const is_lab = section_info.is_lab;
		const section_subsections = ('subsections' in section_info) ? section_info.subsections : [];

		const quarter_days = section_info.quarter_days;
		const quarter_1_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '1') !== -1) ? true : false;
		const quarter_2_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '2') !== -1) ? true : false;
		const quarter_3_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '3') !== -1) ? true : false;
		const quarter_4_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '4') !== -1) ? true : false;
		const unique_days = quarter_days.reduce((results, quarter_day) => {
			const day = quarter_day.day;
			if(!results.includes(day)) results.push(day);
			return results;
		},[]);

		// let section_labels = section_info.labels;
		// section_labels = section_labels.map(label_id => {
		// 	const label_info = labels.find(label => label.label_id === label_id);
		// 	return label_info;
		// });
		
		// const current_subsection_teams = section_labels.filter(label => label.is_team === '1');
		// const sorted_section_teams = sortArrayOfObjects(current_subsection_teams, 'label', 'text', 'asc');
		
		// const current_subsection_labels = section_labels.filter(label => label.is_team === '0');
		// const sorted_section_labels = sortArrayOfObjects(current_subsection_labels, 'label', 'text', 'asc');

		// If this is a lab course in a block schedule in day view, only show if this day is included for lab
		if(is_lab === '1' && scheduleType === 'block' && currentViewType === 'day' && quarter_days.findIndex(quarter_day => quarter_day.day === currentDay) === -1) return;
		
		const num_students = (section_subsections.length === 0) ? section_info.student_list.length : section_subsections.reduce((total_students, subsection_id) => {
			const subsection_index = sections.findIndex(section => section.section_id === subsection_id);
			if(subsection_index === -1) return total_students;
			const subsection_num_students = sections[subsection_index].student_list.length;
			return total_students + subsection_num_students;
		}, section_info.student_list.length);
		
		const course_info = courses.find(course => course.course_id === course_id);
		const course_name = (section_type === '2') ? "Prep" : ((section_type === '3') ? "Duty" : `${capitalizeFirstLetters(course_info.name)} (${course_info.course_code})`);
		const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
		const classroom_name = (classroom_info) ? classroom_info.classroom_name : null;
		
		const applicable_conflicts = conflicts.reduce((result, conflict) => {
			if(conflict.is_deleted === '1') return result;
			const conflict_sections = conflict.sections;
			if(conflict_sections.includes(section_id) && conflict.hard_conflict === '1' && conflict.type !== 'student_double_booked') result.push(conflict);
			return result;
		}, []);

		setSectionInfo(section_info);
		setCourseName(course_name);
		setTeacherName(main_teacher_name);
		setClassroomName(classroom_name);
		setNumStudents(num_students);
		setUniqueDays(unique_days);
		setQuarter1Bool(quarter_1_bool);
		setQuarter2Bool(quarter_2_bool);
		setQuarter3Bool(quarter_3_bool);
		setQuarter4Bool(quarter_4_bool);
		setApplicableConflicts(applicable_conflicts);
	},[sectionID, classrooms, conflicts, courses, currentDay, currentViewType, scheduleType, sections, subsections, teachers]);
	
	//const section_color = (has_conflict) ? 'red' : ((is_lab === '1') ? 'green' : ((section_type === '2') ? 'pastel-light-blue' : ((section_type === '3') ? 'pastel-brown' : 'dark-blue')));
	return (
		<div className='mb-unassigned-sections-container'>
			<div className='dashboard-grid'>
				<h5 style={{margin:'0px 0px 5px 0px'}}>Section Quick View</h5>
				<div className='align-right small-text dark-gray-link' onClick={(e) => handleSelectQuickViewDataID(null, null, e)}>Close</div>
			</div>
			<div className='mb-quick-view-container'>
				<div className='mb-quick-view-section-data'>
					{sectionInfo.is_manual === '1' && <div className='mb-quick-view-manual-label gray dark-blue-text align-right'>Manually Placed Section</div>}
					{sectionInfo.section_number && <div className='medium-text'>Section # {sectionInfo.section_number}</div>}
					<h1 className='mb-quick-view-course-name'>{courseName}</h1>
					<p className='medium-text mb-quick-view-teacher-name'>{teacherName}</p>
					{sectionInfo.subsections.map(subsection_id => {
						const section_subsection_info = sections.find(section => section.section_id === subsection_id);
						const subsection_teacher_id = section_subsection_info.teacher_id;
						const subsection_teacher_info = teachers.find(teacher => teacher.teacher_id === subsection_teacher_id);
						
						const subsection_course_id = section_subsection_info.course_id;
						const subsection_course_info = courses.find(course => course.course_id === subsection_course_id);
						const subsection_course_name = (subsection_course_info) ? subsection_course_info.name : null;
						const subsection_course_code = (subsection_course_info) ? subsection_course_info.course_code : null;

						// Check if subsection is placeholder inclusion course (for schools without separate ICR codes)
						const subsection_is_inclusion = section_subsection_info.is_inclusion;
						let is_placeholder_inclusion = false;
						if(scheduleInfo.separate_ICR_codes === '0' && sectionInfo.course_id === subsection_course_id && subsection_is_inclusion === '1') is_placeholder_inclusion = true;

						return (
							<React.Fragment key={`${sectionInfo.section_id}-${subsection_id}`}>
							{is_placeholder_inclusion ?
								(
									<div className='medium-text orange-text'>
										<div>Inclusion</div>
										<div>{subsection_teacher_info ? `${subsection_teacher_info.name}, ${subsection_teacher_info.first_name}` : ''}</div>
									</div>
								):
								(
									<div className='medium-text blue-text'>
										{subsection_course_id && <div>{subsection_course_name && capitalizeFirstLetters(subsection_course_name)} {subsection_course_code && `(${subsection_course_code})`}</div>}
										<div>{subsection_teacher_info ? `${subsection_teacher_info.name}, ${subsection_teacher_info.first_name}` : ''}</div>
									</div>
								)
							}
							</React.Fragment>
						)
					})}
					<div className='mb-section-quick-view-quarter-day-container'>
						<div className={`mb-section-quick-view-quarter-day ${quarter1Bool ? 'mb-section-quarter-day-selected' : ''}`}>Q1</div>
						<div className={`mb-section-quick-view-quarter-day ${quarter2Bool ? 'mb-section-quarter-day-selected' : ''}`}>Q2</div>
						<div className={`mb-section-quick-view-quarter-day ${quarter3Bool ? 'mb-section-quarter-day-selected' : ''}`}>Q3</div>
						<div className={`mb-section-quick-view-quarter-day ${quarter4Bool ? 'mb-section-quarter-day-selected' : ''}`}>Q4</div>
					</div>
					{(sectionInfo.is_lab === '1') &&
						<div className='mb-lab-days medium-text green-text'>{uniqueDays.length > 0 ? `Day(s) ${uniqueDays.map(day => (day))}` : 'No lab days'}</div>
					}
					{sectionInfo.section_type === '1' &&
						<div className='mb-section-subname-container medium-text'>
							<div className='mb-section-subname'><FontAwesomeIcon icon={faChalkboard}/>{sectionInfo.classroom_id ? classroomName : '--'}</div>
							<div className='mb-section-students'><FontAwesomeIcon icon={faUserGraduate}/> {numStudents}</div>
						</div>
					}
				</div>
				<div className='mb-quick-view-conflicts-container'>
					<h2 className='red-text'>Conflicts</h2>
					{(applicableConflicts.length > 0) ?
						(
							<div className='mb-quick-view-conflicts-inner-container'>
								{applicableConflicts.map((conflict, conflict_index) => {
									const conflict_id = conflict.conflict_id;
									const conflict_type = conflict.type;
									
									if(!['teacher_double_booked', 'teacher_conflicting_periods', 'classroom_double_booked', 'classroom_conflicting_periods'].includes(conflict_type)) return null;

									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const conflict_course_periods = (section1_info) ? section1_info.course_periods : [];
									const conflict_teacher_id = (section1_info) ? section1_info.teacher_id : null;

									const conflict_teacher_info = teachers.find(teacher => teacher.teacher_id === conflict_teacher_id);
									const conflict_teacher_first_name = (conflict_teacher_info) ? conflict_teacher_info.first_name : null;
									const conflict_teacher_last_name = (conflict_teacher_info) ? conflict_teacher_info.name : null;
									const conflict_teacher_full_name = (conflict_teacher_first_name) ? `${conflict_teacher_last_name}, ${conflict_teacher_first_name}` : conflict_teacher_last_name;

									const primary_conflict_name = (conflict_type === 'teacher_double_booked' || conflict_type === 'teacher_conflicting_periods') ? conflict_teacher_full_name : classroomName;
									const conflict_message = (conflict_type === 'teacher_double_booked' || conflict_type === 'classroom_double_booked') ? "double booked" : "in conflicting periods";

									return (
										<div className='conflict-row left-border-red' key={`double-booked-${conflict_index}`} data-id={conflict_id}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'>{primary_conflict_name} is {conflict_message}!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon red' icon={faClock}/>
														<div>
														{conflict_course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType === 'block')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${conflict_index}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon red' icon={faCalendarTimes}/>
														<div>
															{conflict_sections.map((section_id, i) => {
																const section_index = sections.findIndex(section => section.section_id === section_id);
																const section_info = (section_index !== -1) ? sections[section_index] : null;
																const section_course_id = (section_info) ? section_info.course_id : null;
																const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
																const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
																const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;

																return (
																	<div key={i}>{section_course_name}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
										</div>
									)
								})}
							</div>
						):
						(
							<div>No Conflicts!</div>
						)
					}
				</div>
			</div>
		</div>
	)
})