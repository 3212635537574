import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import { saveElementarySchoolSettings } from '../../../js/SaveFunctions';

import '../../../css/hsms/school_setup.css';

export default function ElemSchoolSettings({ isLoading, subscreenRestricted, subscreenLocked, schoolInfo, scheduleInfo, setScheduleInfo, handleMoveToNextScreen}) {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [tempScheduleInfo, setTempScheduleInfo] = useState(scheduleInfo);
	const [currentPage, setCurrentPage] = useState(1);
	
	const submitForm = () => {
		setIsSubmitting(true);
		
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, start_time:tempScheduleInfo.start_time, end_time:tempScheduleInfo.end_time};
		
		// Save school settings to database
		saveElementarySchoolSettings(data, () => {
			setScheduleInfo({...scheduleInfo, ...data});
			// Move to next screen
			handleMoveToNextScreen();
		});
	}

	useEffect(() => {
		if(!isLoading) setTempScheduleInfo(scheduleInfo);
	}, [isLoading]);

	const num_total_question_pages = document.querySelectorAll('.school-settings-question-page').length;
	
	return (
		<div className='school-setup-main-container'>
			<h1 className='progress-main-header'>School Settings</h1>
			<p className='progress-subheader'>We just a couple of more specific questions about your school.</p>
			{isLoading ?
				(
					<div className='center-text'>
						<img src={require("../../../images/balls.gif")} alt='loading...' style={{height:'40px'}}/>
					</div>
				):(subscreenRestricted) ?
				(
					<div className='general-screen-message'>
						<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
						<h1>No Access</h1>
						<div>You do not have access to this screen</div>	
					</div>
				):(subscreenLocked) ?
				(
					<div className='general-screen-message'>
						<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
						<h1>Temporarily Locked</h1>
						<div>This screen is locked until all other prior steps are completed</div>	
					</div>
				):
				(
					<div id="school-settings-container">
					<h5 style={{margin:'10px 0px 10px 0px'}}>Settings Questionairre</h5>
						<div className='school-settings-page-counter-container'>
							<div className='school-settings-page-counter-current'>0{currentPage}</div>
							<div className='school-settings-page-counter-total gray-text'>of 0{num_total_question_pages}</div>
						</div>
						<div className={`school-settings-question-page ${currentPage === 1 && 'school-settings-current-page'}`}>
							<div className='school-settings-question-container'>
								<div className='school-settings-question'>What is the start time of your school day?</div>
								<input type='time' defaultValue={tempScheduleInfo.start_time} onChange={(e) => setTempScheduleInfo({...tempScheduleInfo, start_time:e.target.value})}/>
							</div>
							<div className="btn green-btn btn-large full-width" onClick={()=>setCurrentPage(currentPage+1)}>Next</div>
						</div>
						<div className={`school-settings-question-page ${currentPage === 2 && 'school-settings-current-page'}`}>
							<div className='school-settings-question-container'>
								<div className='school-settings-question'>What is the end time of your school day?</div>
								<input type='time' defaultValue={tempScheduleInfo.end_time} onChange={(e) => setTempScheduleInfo({...tempScheduleInfo, end_time:e.target.value})}/>
							</div>
							{!isSubmitting ? 
								(
									<div style={{display:'grid', gridTemplateColumns:'1fr 1fr',columnGap:'15px'}}>
										<div className="btn gray-btn btn-large full-width" onClick={()=>setCurrentPage(currentPage-1)}>Back</div>
										<div className="btn green-btn btn-large full-width" onClick={submitForm}>Submit</div>
									</div>
								) : 
								(
									<div style={{display:'grid', gridTemplateColumns:'1fr 1fr',columnGap:'15px'}}>
										<div className="btn gray-btn btn-large full-width">Back</div>
										<div className="btn green-btn btn-large full-width"><img src={require('../../../images/ajax-loader.gif')} alt='Loading' style={{height:'20px'}}/></div>
									</div>
								)
							}
						</div>
					</div>
				)
			}
		</div>
	);
}