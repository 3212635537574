import React, { useState , useEffect, useCallback } from 'react';
import { FileUpload } from '../js/upload';

import EditUploadHeaders from './hsms/modals/edit_upload_headers';

import { getProcessFeedback, clearProcessFeedback, continueProcess } from '../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faSquare, faCheckSquare, faCheckCircle, faCheck, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

export default function UploadModal({schoolInfo, scheduleInfo, uploadTitle, uploadAPIName, addReplaceBool = false, closeModal, handlePullNewData,}) {
	/////////////////
	///// STATE /////
	/////////////////
	const [currentScreen, setCurrentScreen] = useState(1);

	const [file, setFile] = useState({name:null});
	const [fileUpload, setFileUpload] = useState(null);

	const [checkProcessProgressCompleted, setCheckProcessProgressCompleted] = useState(false);

	const [uploadType, setUploadType] = useState(null);
	const [selectedSchedules, setSelectedSchedules] = useState([]);

	const [currentProgressID, setCurrentProgressID] = useState(null);
	const [isUploading, setIsUploading] = useState(false);
	const [percent, setPercent] = useState(0);
	const [status, setStatus] = useState('--');
	const [uploadComplete, setUploadComplete] = useState(false);

	const [uploadState, setUploadState] = useState(null);
	
	const [feedbackData, setFeedbackData] = useState(null);
	
	const [showHeadersFeedback, setShowHeadersFeedback] = useState(false);
	const [showUploadsFeedback, setShowUploadsFeedback] = useState(false);

	/////////////////////
	///// FUNCTIONS /////
	/////////////////////
	const onFileChange = (e) => {
		const file = e.target.files[0];
		
		setIsUploading(false);
		setUploadComplete(false);
		setPercent(0);
		setUploadState(null);
		setStatus('--');
		setFile(file);
		setCurrentScreen(2);
	}

	const handleCheckUpload = useCallback(async (process_type, file_upload = fileUpload, ignore_process_flags = false, _callback = ()=>{}) => {
		try {
			const running_processes = await file_upload.getSchoolProcesses(schoolInfo.current_schedule_year_id);

			// Check if there are any running processes with the same data version id
			const dv_process_index = running_processes.findIndex(process => process.process_type === process_type && process.data_version_id == schoolInfo.current_data_version_id);
			const sv_process_index = running_processes.findIndex(process => process.process_type === process_type && process.schedule_version_id == schoolInfo.current_schedule_version_id);

			// If process is already running, get progress of matches
			const process_index = (sv_process_index !== -1) ? sv_process_index : ((dv_process_index !== -1) ? dv_process_index : null);
			const process_info = (process_index !== null) ? running_processes[process_index] : null;
			
			const progress_id = (process_info) ? process_info.progress_id : null;
			const failure = (process_info && !ignore_process_flags) ? process_info.failure : false;
			const is_done = (process_info && !ignore_process_flags) ? process_info.is_done : false;

			// If no process is running, process has failed, or process is done, then we don't have to check progress
			if(progress_id === null || failure || is_done) 
			{
				const screen_to_show = (failure) ? 'failure' : 1;
				removeFile(screen_to_show);
				setCheckProcessProgressCompleted(true);
				return false;
			}

			/// Set the current progress id
			setCurrentProgressID(progress_id);
			
			// Get process progress for this progress id
			let stop_checking = false;
			let feedback_checked = false;

			await file_upload.checkUploading(schoolInfo.school_id, progress_id, (event) => {
				if(!event.has_started || event.done || event.has_feedback || event.failure) 
				{
					file_upload.cancelCheck();
					stop_checking = true;
				}
	
				if(event.failure)
				{
					removeFile('failure');
					setCheckProcessProgressCompleted(true);
				}
				else if(!event.has_started)
				{
					removeFile(1);
					setCheckProcessProgressCompleted(true);	
				}
				else if(event.has_started && !event.done && !stop_checking)
				{
					setCurrentScreen(3);
					setFile({name: 'Processing data... This may take a few minutes...'});
					setIsUploading(true);
					setPercent(Math.floor(event.progress * 100));
				}
				else if(event.done)
				{
					setIsUploading(false);
					
					if(!event.failure)
					{
						setPercent(100);
						setUploadComplete(true);
						setUploadState(null);
	
						// Remove duplicates from local storage
						localStorage.removeItem('duplicates');
	
						if(!event.has_feedback) 
						{
							removeFile();
							setCheckProcessProgressCompleted(true);	
							_callback();
						}
					}
				}
	
				// If there is feedback, get it and set it, and set which type of feedback
				if(event.has_feedback && !feedback_checked)
				{
					feedback_checked = true; 
					
					const data = {school_id:schoolInfo.school_id, progress_id:progress_id, data_version_id:schoolInfo.current_data_version_id, process_type:process_type};
				
					getProcessFeedback(data).then(feedback_data => {
						setFeedbackData({...feedback_data});
						setCheckProcessProgressCompleted(true);	

						if(feedback_data.data_type === 'upload_headers') 
						{
							setCurrentScreen(3);
							setShowHeadersFeedback(true);
							setFile({name:'Waiting for headers to be confirmed...'});
							setUploadState('confirm_headers');
						}
						else if(feedback_data.data_type === 'upload') 
						{
							setShowUploadsFeedback(true);
						}
					});
				}	
			});
		} catch (e)
		{
			file_upload.cancelCheck();
			removeFile('failure');
			setCheckProcessProgressCompleted(true);	
		}
	},[fileUpload, schoolInfo.school_id, schoolInfo.current_schedule_version_id, schoolInfo.current_data_version_id, schoolInfo.current_schedule_year_id])
	
	const submitUpload = async (process_type) => {
		if(!file.name)
		{
			alert("You must upload a file!");
			return;
		}

		// Cancel any existing uploads
		if(fileUpload) fileUpload.cancelCheck();

		// Create comma delimited list of schedule version ids
		const selected_schedules = selectedSchedules.join(',');

		// Create a new FileUpload object
		const file_upload = new FileUpload(schoolInfo.current_data_version_id, selected_schedules, schoolInfo.school_id);

		setFileUpload(file_upload);
		setIsUploading(true);
		setStatus('Uploading File...');
		setCurrentScreen(3);

		// Is this a replace upload
		const is_replace = (uploadType === 'replace')

		// Start uploading file
		const success = await file_upload.uploadFile(file, process_type, is_replace, (event) => {
			setPercent(Math.floor(event.progress * 100));
		});

		// Wait for 3 seconds to ensure process is saved and can get feedback
		setTimeout(function () {
			// Handle check process
			// If success, check progress
			// If failure, reset screen to default
			if(success)
			{
				setPercent(0);
				setFile({name: 'Processing upload... This may take a few minutes...'});
				setStatus('--');

				handleCheckUpload(process_type, file_upload, true);
			} 
			else 
			{
				file_upload.cancelCheck();
				setIsUploading(false);
				setFile({name:null});
				setStatus('Failed to upload file.. Please try again');
			}
		}, 3000);
	}

	const toggleScheduleSelected = (schedule_version_id) => {
		const selected_index = selectedSchedules.findIndex(temp_schedule_version_id => temp_schedule_version_id === schedule_version_id);

		if(selected_index !== -1)
		{
			selectedSchedules.splice(selected_index, 1);
		}
		else 
		{
			selectedSchedules.push(schedule_version_id);
		}

		setSelectedSchedules([...selectedSchedules]);
	}


	const removeFile = (screen = 1) => {
		setFile({name:null});
		setIsUploading(false);
		setUploadComplete(false);
		setPercent(0);
		setUploadState(null);
		setStatus('--');
		setCurrentScreen(screen);
	}

	const cancelUpload = () => {
		removeFile(1);
		setShowHeadersFeedback(false);
		setUploadState(null);

		if(!currentProgressID) return;

		const data = {school_id:schoolInfo.school_id, progress_id:currentProgressID, data_version_id:schoolInfo.current_data_version_id, process_type:feedbackData.process_type, data:{cancel:true}};
		clearProcessFeedback(data);
		continueProcess(data);
	}

	const resetState = () => {
		setCurrentProgressID(null);
		setFile({name:null});
		setCheckProcessProgressCompleted(false);
		setIsUploading(false);
		setPercent(0);
		setStatus("--");
		setUploadComplete(false);
		setUploadState(null);
		setFeedbackData(null);
		setShowHeadersFeedback(false);
		setShowUploadsFeedback(false);
	}

	const handleCloseModal = () => {
		resetState();
		closeModal();
	}

	const showSuccessScreen = () => {
		setCurrentScreen(4);
		handlePullNewData();
	}

	//////////////////////
	///// USE EFFECT /////
	//////////////////////
	useEffect(() => {
		if(fileUpload) fileUpload.cancelCheck();

		const schedules_from_current_schedule_year = scheduleInfo.schedules.filter(schedule => schedule.data_version_id === schoolInfo.current_data_version_id).map(schedule => schedule.schedule_version_id);
		setSelectedSchedules([...schedules_from_current_schedule_year]);

		const selected_schedules = schedules_from_current_schedule_year.join(',');
		
		const file_upload = new FileUpload(schoolInfo.current_data_version_id, selected_schedules, schoolInfo.school_id);
		setFileUpload(fileUpload => (file_upload));
		handleCheckUpload(uploadAPIName, file_upload);
	}, [schoolInfo.school_id, schoolInfo.current_data_version_id, scheduleInfo.schedules, uploadAPIName]);

	const isCurrentAppScheduleYear = (scheduleInfo.app_current_schedule_year_id === schoolInfo.current_schedule_year_id);
	const schedules_from_current_schedule_year = scheduleInfo.schedules.filter(schedule => schedule.data_version_id === schoolInfo.current_data_version_id);

	return (
		<>
		<div className='modal'>
			<div id='upload-modal-content' className='modal-content'>
				{!isUploading && <span className="modal-close-right" onClick={handleCloseModal}>×</span>}
				<h1 id='upload-modal-title'>Upload {uploadTitle}</h1>
				{(!isCurrentAppScheduleYear) ?
				(
					<div className='center-text'>Uploads are not allowed in previous scheduling years.</div>
				):(currentScreen === 1) ?
				(
					<>
					{!checkProcessProgressCompleted ?
						(
							<div className='center-text'>
								<img src={require("../images/balls.gif")} alt='loading...' style={{height:'40px'}}/>
								<div className='medium-text'>Checking status...</div>
							</div>
						):
						(
							<div className="upload_type_container">
								<div className="drag_file_upload_container">
									<FontAwesomeIcon className='upload-icon' icon={faFileUpload}/>
									<div>Click here to select an Excel (.xlsx) file</div>
									<div className='small-text' style={{margin:'5px 0px 0px 0px'}}>(you can also drag in your files)</div>
									<input type="file" className='upload_data' onChange={onFileChange}/>
								</div>
							</div>
						)
					}
					</>
				):(currentScreen === 2) ?
					<div>
						<div className='uploaded-file-container'>
							<div className='uploaded-file-icon-container'>
								<img src={require('../images/excel.png')} style={{height:'30px'}} alt='excel icon'/>
							</div>
							<div className='uploaded-file-name-container'>
								<div className="teachers_file">{file.name}</div>
							</div>
							<div className='uploaded-file-remove-file' onClick={() => removeFile(1)}><FontAwesomeIcon className='remove-file-icon' icon={faTimesCircle}/></div>
						</div>
						{addReplaceBool &&
							<>
							<h4 className='upload-file-subtitle'>Select your upload type:</h4>
							<div className='upload-file-upload-type-container' onClick={() => setUploadType('add')}>
								<input className='upload-file-upload-type-radio' type='radio' checked={uploadType === 'add'} />
								<div>
									<h3 className='upload-file-upload-type-title'>Add Data</h3> 
									<div>Choose this if you want to add new data from your file to existing data. Any existing data will not be affected.</div>
								</div>
							</div>	
							<div className='upload-file-upload-type-container' onClick={() => setUploadType('replace')}>
								<input className='upload-file-upload-type-radio' type='radio' checked={uploadType === 'replace'}/>
								<div>
									<h3 className='upload-file-upload-type-title'>Replace Data</h3> 
									<div>Choose this if you want to replace all existing data with the data from your file.</div>
								</div>
							</div>
							</>
						}
						{(schedules_from_current_schedule_year.length > 1) &&
							<>
							<h4 className='upload-file-subtitle'>Select the schedules you would like this upload to change:</h4>
							{schedules_from_current_schedule_year.map((schedule, i) => {
								const schedule_version_id = schedule.schedule_version_id;
								const schedule_name = schedule.schedule_name;
								const schedule_date = schedule.time_stamp;
								const schedule_selected = selectedSchedules.findIndex(selected_schedule_version_id => selected_schedule_version_id === schedule_version_id) !== -1;

								return (
									<div className='upload-file-schedule-select-container' key={i} onClick={()=>toggleScheduleSelected(schedule_version_id)}>
										{schedule_selected ?
											(
												<FontAwesomeIcon className='upload-file-schedule-select-box fas-checkbox-checked' icon={faCheckSquare}/>
											):
											(
												<FontAwesomeIcon className='upload-file-schedule-select-box fas-checkbox-unchecked dark-gray-text' icon={faSquare}/>
											)
										}
										<div>
											<div>{schedule_name}</div>
											<div className='small-text'>{schedule_date}</div>
										</div>
									</div>
								)
							})}							
							</>
						}
						<span className='btn blue-btn btn-large upload-file-btn' onClick={()=>submitUpload(uploadAPIName)}>Start Uploading</span>
					</div>
				:(currentScreen === 3) ?
				(
					<div className='uploading-file-container'>
						<div className='uploaded-file-icon-container'>
							<img src={require('../images/excel.png')} style={{height:'30px'}} alt='excel icon'/>
						</div>
						<div className='uploaded-file-progress-bar-container'>
							{(uploadState === 'confirm_headers') ?
								(
									<div className='uploaded-file-name-container'>
										<div className="teachers_file">Waiting for headers to be confirmed...</div>
										<div className='blue-link medium-text' onClick={() => setShowHeadersFeedback(true)}>Confirm Headers</div>
									</div>
								):
								(
									<>
									<div className='uploaded-file-name-container'>
										<div className="teachers_file">{file.name}</div>
									</div>
									<progress className='progressor' value={percent} max='100'></progress>
									</>	
								)
							}
							{(isUploading || uploadComplete) &&
								<div className='uploaded-file-status-container'>
									<div className="uploaded-file-results">{status}</div>
								</div>
							}
						</div>
						<div className='uploaded-file-progress-container'>
							{(uploadState === 'confirm_headers') ?
								(
									<div className="uploaded-file-btn-container">
										<div className='text-align-center medium-text red-btn' style={{minWidth:'150px', padding:'10px 0px'}} onClick={cancelUpload}>Cancel Upload</div>
									</div>
								):
								(
									<>
									{(isUploading || uploadComplete) &&
										<div className="uploaded-file-percentage">{percent}%</div>
									}
									{uploadComplete &&
										<div className='upload-complete-icon-container'>
											<FontAwesomeIcon className='upload-complete-icon green-text' icon={faCheck}/>
										</div>
									}
									</>
								)
							}
						</div>
					</div>
				):(currentScreen === 4) ?
				(
					<div className='text-align-center'>
						<FontAwesomeIcon icon={faCheckCircle} className='green-text' style={{fontSize:'64px',marginTop:'20px'}}/>
						<h1>Success!</h1>
						<p>You have successfully uploaded your data!</p>
					</div>
				):(currentScreen === 'failure') ?
				(
					<div className='text-align-center'>
						<FontAwesomeIcon icon={faTriangleExclamation} className='red-text' style={{fontSize:'64px',marginTop:'20px'}}/>
						<h1>Error</h1>
						<p>Something went wrong. Please make sure your file is in .xlsx format and try again!</p>
						<div className='blue-link text-align-center full-width' onClick={cancelUpload}>Try Upload Again</div>
					</div>
				): null}
			</div>
		</div>
		{showHeadersFeedback &&
			<EditUploadHeaders schoolInfo={schoolInfo} progressID={currentProgressID} selectedSchedules={selectedSchedules} scheduleInfo={scheduleInfo} feedbackData={feedbackData} setFeedbackData={setFeedbackData} setShowHeadersFeedback={setShowHeadersFeedback} fileUpload={fileUpload} setUploadState={setUploadState} cancelUpload={cancelUpload} handleCheckUpload={handleCheckUpload} onCompleteHeaders={showSuccessScreen}/>
		}
		</>
	);
}