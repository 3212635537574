import { useEffect } from "react";
import { API } from "aws-amplify";

export function logOut()
{
	localStorage.clear();
	sessionStorage.clear();
	document.location.href = "logout.php";
}

export function getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

export function setCookie(cookieName, cookieValue)
{
	document.cookie = cookieName + "=" + cookieValue + "; path=/";
}

export function capitalizeFirstLetters(string) {
	const words = string.replace("&", " & ").split(" ");

	for (let i = 0; i < words.length; i++) {
		if(words[i].length === 0) continue;
		
		const first_letter = words[i][0].toUpperCase();
		const rest_of_word = (words[i].length <= 3) ? words[i].substr(1) : words[i].substr(1).toLowerCase();
	    words[i] = first_letter + rest_of_word;
	}
	
	return words.join(" ");
}

export function createCommaSeparatedListFromArray(arrayWithList) {
	const all_but_one_element_in_array = arrayWithList.slice(0, -1);
	const all_but_one_element_with_commas_string = all_but_one_element_in_array.join(', ');
	const last_element_in_array_string = arrayWithList.slice(-1)[0];

	const array_with_comma_separated_list_and_last_element = [all_but_one_element_with_commas_string, last_element_in_array_string];
	const final_list_string = array_with_comma_separated_list_and_last_element.join(arrayWithList.length < 2 ? '' : ' and ')
	return final_list_string;
}

export function timeSince(time_stamp) {
	// Split time stamp into array of each component of the date/time
	var t = time_stamp.split(/[- :]/);

	// Apply each element to the Date function
	var date = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
	
	var seconds = Math.floor((new Date() - date) / 1000);
	
	// Check if interval is in years, months, days, hours, min, seconds
	// Then return the difference
	var interval = seconds / 31536000;
	
	if (interval > 1) {
		return Math.floor(interval) + " years";
	}
	
	interval = seconds / 2592000;
	
	if (interval > 1) {
		return Math.floor(interval) + " months";
	}
	
	interval = seconds / 86400;
	
	if (interval > 1) {
		return Math.floor(interval) + " days";
	}
	
	interval = seconds / 3600;
	
	if (interval > 1) {
		return Math.floor(interval) + " hours";
	}
	
	interval = seconds / 60;
	
	if (interval > 1) {
		return Math.floor(interval) + " minutes";
	}
	
	return Math.floor(seconds) + " seconds";
}

export function formatTime(timestamp) {
	if(!timestamp) return "--:--";

	const date = new Date('1989-06-29T' + timestamp);

	const hour = date.getHours();
	const hour_in_twelve_hour_day = hour > 12 ? hour - 12 : hour;
	const minute = ('0' + date.getMinutes()).slice(-2);
	const ampm = hour >= 12 ? 'pm' : 'am';

	return `${hour_in_twelve_hour_day}:${minute}${ampm}`;
}

export function convertTimeToNumber(time) {
	const hours = Number(time.split(':')[0]) * 60;
	const minutes = Number(time.split(':')[1]);
	return hours + minutes;
}

export function timesAreOverlapping(start_time1, end_time1, start_time2, end_time2) {
	if(!start_time1 || !end_time1 || !start_time2 || !end_time2) return false;
	
	const a = convertTimeToNumber(start_time1);
	const b = convertTimeToNumber(end_time1);

	const c = convertTimeToNumber(start_time2);
	const d = convertTimeToNumber(end_time2);

	if (a < d && b > c) return true;

	return false;
}

export function JSONexists(name, type = "local")
{
	var jsonData = (type === "session") ? sessionStorage.getItem(name) : localStorage.getItem(name);
	
	if(!jsonData || jsonData === null || jsonData === 'null' || jsonData === 'undefined')
	{
		return false;
	}
	else
	{
		return true;
	}
}

export function getJSONFromStorage(json_name, parse_bool, type = "local")
{
	var json_data;
	
	if(!JSONexists(json_name, type)) return null;
	
	if(parse_bool === true)
	{
		if(type === "local")
		{
			json_data = JSON.parse(localStorage.getItem(json_name));
		}
		else if(type === "session")
		{
			json_data = JSON.parse(sessionStorage.getItem(json_name));
		}
	}
	else
	{
		if(type === "local")
		{
			json_data = localStorage.getItem(json_name);
		}
		else if(type === "session")
		{
			json_data = sessionStorage.getItem(json_name);
		}
		
	}
	
	return json_data;
}

export function isObject(obj)
{
	return typeof obj === 'object' && obj !== null;
}

export function isEmpty(obj) 
{
	if(!obj || obj === 'null' || obj === 'undefined') return true;
	if(Array.isArray(obj) && obj.length === 0) return true;
	
    for(var key in obj) 
    {
        return false;
    }
    
    return true;
}

export function shuffleArray(array) {
	var currentIndex = array.length, temporaryValue, randomIndex;
	
	// While there remain elements to shuffle...
	while (0 !== currentIndex)
	{
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;
		
		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}
	
	return array;
}

export function createUniqueObjectID(lengthOfID, obj = {}) {
	let result;
	
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	
	do {
	   result = '';
	   
	   for( var i = 0; i < lengthOfID; i++ )
	   {
	      result += characters.charAt(Math.floor(Math.random() * charactersLength));
	   }
	} while(result in obj);
	
	return result;
}

/*export function closeModal(modalToClose)
{
	// GENERAL CLOSING AND CLEARING OF ATTRIBUTES
	// Close it first, then do all necessary functions
	$("#close-without-saving-modal").fadeOut();
	// Run any passed function
	var referredData = $("#" + modalToClose).attr("data-referreddata");
	
	if(referredData && referredData !== '') eval(referredData);
		
	$("#" + modalToClose).fadeOut(function(){
		$("#close-without-saving-modal").attr('data-modaltoclose', "");
		
		// Clear any data attributes on the modal
		$.each($("#" + modalToClose).data(), function (i) {
		    $("#" + modalToClose).attr("data-" + i, "");
		});
		
		// SPECIAL screenES FOR CERTAIN MODALS
		if(modalToClose == "section-details-modal")
		{
			var delay_schedule_update = sessionStorage.getItem("delay_schedule_update");
			
			if(delay_schedule_update == 1)
			{
				sessionStorage.setItem("delay_schedule_update", 0);
				
				updateMatchPercentages();
				getConflicts();
			}
		}
	});
}*/

export function sortObject(passedJSONObject, sortBy, sortType = 'number')
{
	if(Object.keys(passedJSONObject).length === 0) return [];
	
	var sorted_object = Object.keys(passedJSONObject).sort(function(a,b){
		var compA, compB;
		
		if(sortBy)
		{
			compA = (sortType === 'number') ? parseInt(passedJSONObject[a][sortBy]) : passedJSONObject[a][sortBy].toString().toLowerCase();
			compB = (sortType === 'number') ? parseInt(passedJSONObject[b][sortBy]) : passedJSONObject[b][sortBy].toString().toLowerCase();
			
			return (compA === null) - (compB === null) || +(compA > compB) || -(compA < compB);
		} else
		{
			compA = (sortType === 'number') ? parseInt(passedJSONObject[a]) : passedJSONObject[a].toString().toLowerCase();
			compB = (sortType === 'number') ? parseInt(passedJSONObject[b]) : passedJSONObject[b].toString().toLowerCase();
			
			return (compA === null) - (compB === null) || +(compA > compB) || -(compA < compB);
		}
		
	}).map(key => passedJSONObject[key]);
	
	return sorted_object;
}

export function reverseSortObject(passedJSONObject, sortBy, sortType = 'number')
{
	if(Object.keys(passedJSONObject).length === 0) return [];
	
	var sorted_object = Object.keys(passedJSONObject).sort(function(a,b){
		var compA = (sortType === 'number') ? parseInt(passedJSONObject[a][sortBy]) : passedJSONObject[a][sortBy].toString().toLowerCase();
		var compB = (sortType === 'number') ? parseInt(passedJSONObject[b][sortBy]) : passedJSONObject[b][sortBy].toString().toLowerCase();
		
		return (compB === null) - (compA === null) || +(compB > compA) || -(compB < compA);
	}).map(key => passedJSONObject[key]);
	
	return sorted_object;
}

export function sortArrayOfObjects(passedArray, sortKey, sortType, AscDesc)
{
	if(passedArray.length === 0) return [];
	
	passedArray.sort((compA, compB) => {
		const valA = compA[sortKey];
		const valB = compB[sortKey];
		
		const compValA = (!valA) ? null : ((sortType === 'number') ? parseInt(valA) : valA.toString().toLowerCase());
		const compValB = (!valB) ? null : ((sortType === 'number') ? parseInt(valB) : valB.toString().toLowerCase());
		
		if(AscDesc === 'asc')
		{
			if(!compValA || compValA > compValB) return 1;
			if(!compValB || compValA < compValB) return -1;
		}
		else
		{
			if(!compValA || compValA > compValB) return -1;
			if(!compValB || compValA < compValB) return 1;
		}
			
		return 0;
	});
	
	return passedArray;
}

export function compareValues(key, order = 'asc') {
	return function innerSort(a, b) {
		if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
		
		const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
		const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];
		
		let comparison = 0;
		
		if(varA > varB) {
			comparison = 1;
		} 
		else if (varA < varB) {
			comparison = -1;
		}
		
		return ((order === 'desc') ? (comparison * -1) : comparison);
	};
}

export function getSubsetOfObject(originalObj, keysToKeepArray)
{
	const newObject = {};
    keysToKeepArray.forEach(key => { newObject[key] = originalObj[key]; });
    return newObject;
}

export function filterArrayOfObjects(passedArray, searchValue, possibleKeys)
{
	const lowercase_search_values = searchValue.trim().split(' ').map(word => word.toLowerCase());
	
	const filtered_array = passedArray.filter((data) => {
		for(const key of possibleKeys)
		{

			const lowercase_data = (key in data && data[key]) ? data[key].toLowerCase() : null;
			
			if(lowercase_data)
			{
				for(const search_value of lowercase_search_values)
				{
					if(lowercase_data.includes(search_value)) return true;
				}
			}
		}
		
		return false;
	});
	
	return filtered_array;
}

/*export function sortDivs(divsToSort, sortAttribute, divToAddTo, ascDesc, dataType)
{
	var divList = $(divsToSort);
	
	if(ascDesc == "asc")
	{
		if(dataType == "text")
		{
			divList.sort(function(a, b){
			    return ($(a).attr(sortAttribute).toLowerCase() > $(b).attr(sortAttribute).toLowerCase()) ? 1 : -1;
			});
		}
		else if(dataType == "number")
		{
			divList.sort(function(a, b){
			    return (parseInt($(a).attr(sortAttribute)) > parseInt($(b).attr(sortAttribute))) ? 1 : -1;
			});
		}
		
	}
	else
	{
		if(dataType == "text")
		{
			divList.sort(function(a, b){
			    return ($(a).attr(sortAttribute).toLowerCase() < $(b).attr(sortAttribute).toLowerCase()) ? 1 : -1;
			});
		}
		else if(dataType == "number")
		{
			divList.sort(function(a, b){
			    return (parseInt($(a).attr(sortAttribute)) < parseInt($(b).attr(sortAttribute))) ? 1 : -1;
			});
		}
	}
	
	$(divToAddTo).html(divList);
}*/

/*export function filterDivs(filter, divsToFilter, filterAttributes)
{	
	var filter = filter.toLowerCase();
	var filterAttributes = JSON.parse(filterAttributes);
	
	$(divsToFilter).hide();
	
	$(divsToFilter).each(function(){
		var $this = $(this);
		
		$.each(filterAttributes, function(index, attr){
			var divVal = $this.attr(attr).toLowerCase();
			
			if(divVal.indexOf(filter) > -1)
			{
				$this.show();
			}
		});
	});
}*/

export function createNewKeyForObject(obj) {
	return (isEmpty(obj)) ? "1" : (parseInt(Object.keys(obj).reduce(function(a, b){return parseInt(a,10) > parseInt(b,10) ? a : b})) + 1).toString();
}

export function cloneObj(obj) {
	return JSON.parse(JSON.stringify(obj));
}

Object.filter = (obj, predicate) => 
    Object.keys(obj)
          .filter( key => predicate(obj[key]) )
          .reduce( (res, key) => Object.assign(res, { [key]: obj[key] }), {} );


export function convertObjectToArray(obj) {
	let dataArray = [];

	for(const key in obj)
	{
		dataArray.push(obj[key]);
	}
	
	return dataArray;
}

export function arraysEqual(_arr1, _arr2)
{
    if (!Array.isArray(_arr1) || ! Array.isArray(_arr2) || _arr1.length !== _arr2.length) return false;

    var arr1 = _arr1.concat().sort();
    var arr2 = _arr2.concat().sort();

    for(var i = 0; i < arr1.length; i++)
    {
        if (arr1[i] !== arr2[i]) return false;
    }

    return true;
}

export function areArraysOrObjectsEqual(value, other) {

	// Get the value type
	var type = Object.prototype.toString.call(value);

	// If the two objects are not the same type, return false
	if (type !== Object.prototype.toString.call(other)) return false;

	// If items are not an object or array, return false
	if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

	// Compare the length of the length of the two items
	var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
	var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
	if (valueLen !== otherLen) return false;

	// Compare two items
	var compare = function (item1, item2) {

		// Get the object type
		var itemType = Object.prototype.toString.call(item1);

		// If an object or array, compare recursively
		if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
			if (!areArraysOrObjectsEqual(item1, item2)) return false;
		}

		// Otherwise, do a simple comparison
		else {

			// If the two items are not the same type, return false
			if (itemType !== Object.prototype.toString.call(item2)) return false;

			// Else if it's a function, convert to a string and compare
			// Otherwise, just compare
			if (itemType === '[object Function]') {
				if (item1.toString() !== item2.toString()) return false;
			} else {
				if (item1 !== item2) return false;
			}

		}
	};

	// Compare properties
	if (type === '[object Array]') {
		for (var i = 0; i < valueLen; i++) {
			if (compare(value[i], other[i]) === false) return false;
		}
	} else {
		for (var key in value) {
			if (value.hasOwnProperty(key)) {
				if (compare(value[key], other[key]) === false) return false;
			}
		}
	}

	// If nothing failed, return true
	return true;

};

export function findDeletedValues(arr1, arr2, passing_object = false, key_to_check = null)
{
	if(passing_object)
	{
		return arr1.filter(x => !arr2.some(y => x[key_to_check] === y[key_to_check]));
	}
	else
	{
		return arr1.filter(x => !arr2.includes(x));
	}
}

export function findAddedValues(arr1, arr2, passing_object = false, key_to_check = null)
{
	if(passing_object)
	{
		return arr2.filter(x => !arr1.some(y => x[key_to_check] === y[key_to_check]));
	}
	else
	{
		return arr2.filter(x => !arr1.includes(x));
	}
}

export function findIntersection(arr1, arr2, passing_object = false, key_to_check = null)
{
	if(passing_object)
	{
		return arr1.filter(x => arr2.some(y => x[key_to_check] === y[key_to_check]));
	}
	else
	{
		return arr1.filter(x => arr2.includes(x));
	}
}

export function overlapCheck(time1, time2) {
	const start_time1 = time1.start_time;
	const end_time1 = time1.end_time;
	const start_time1_array = start_time1.split(':');
	const end_time1_array = end_time1.split(':');

	const start_time1_number = parseInt(start_time1_array[0] + start_time1_array[1]);
	const end_time1_number = parseInt(end_time1_array[0] + end_time1_array[1]);

	const start_time2 = time2.start_time;
	const end_time2 = time2.end_time;
	const start_time2_array = start_time2.split(':');
	const end_time2_array = end_time2.split(':');

	const start_time2_number = parseInt(start_time2_array[0] + start_time2_array[1]);
	const end_time2_number = parseInt(end_time2_array[0] + end_time2_array[1]);

	if(end_time1_number > start_time2_number && end_time2_number > start_time1_number) return true;

	return false;
}

export function createMatrix(m, n) {
	if(!m || !n) return [];
	
	var matrix = [];
	
	for(var i=0; i < m; i++)
	{
		matrix[i] = [];
		for(var j=0; j<n; j++)
		{
			matrix[i][j] = 0;
		}
	}
	
	return matrix;
};

export const useOutsideClick = (ref, callback, array_of_class_name_exclusions = []) => {
	const handleClick = e => {
		const target_contains_exclusion = array_of_class_name_exclusions.reduce((results, class_name) => {
			if(e.target.classList.contains(class_name) || (e.target.closest('.parent-div') && e.target.closest('.parent-div').classList.contains(class_name))) results = true;
			return results;
		}, false);
		
		if(ref.current && !ref.current.contains(e.target) && !ref.current.classList.contains('click-restricted') && !e.target.classList.contains('click-restricted') && (!e.target.closest('.parent-div') || (e.target.closest('.parent-div') && !e.target.closest('.parent-div').classList.contains('click-restricted'))) && !target_contains_exclusion) {
			callback();
			return;
		}
	};
		
	useEffect(() => {
		document.addEventListener("click", handleClick);
		
		return () => {
			document.removeEventListener("click", handleClick);
		};
	});
};

export const getNextSchedulePeriod = (scheduleStructureData, current_day, starting_schedule_period_id, excluded_scheduled_period_ids = []) => {
	const availability_matrix = scheduleStructureData.availability_matrix;
	const all_schedule_periods = sortArrayOfObjects(scheduleStructureData.schedule_structure[current_day], 'period_order');
			
	// get the schedule periods that are not allowed during the current schedule period
	const unallowed_schedule_periods = availability_matrix[starting_schedule_period_id].concat(excluded_scheduled_period_ids);
	
	// get the index of the current schedule period (so we don't check any schedule periods before it
	const starting_schedule_period_index = all_schedule_periods.findIndex(schedule_period => schedule_period.period_id === starting_schedule_period_id);
	
	// Find next schedule period by traversing all schedule periods after the current, and finding the first one that is not in the unallowed list
	for(let i = starting_schedule_period_index + 1; i < all_schedule_periods.length;i++)
	{
		const current_schedule_period_id = all_schedule_periods[i].period_id;
	
		if(!unallowed_schedule_periods.includes(current_schedule_period_id)) return current_schedule_period_id;
	}
	
	return false;
}

export const getPreviousSchedulePeriod = (scheduleStructureData, current_day, starting_schedule_period_id, excluded_scheduled_period_ids = []) => {
	const availability_matrix = scheduleStructureData.availability_matrix;
	const all_schedule_periods = sortArrayOfObjects(scheduleStructureData.schedule_structure[current_day], 'period_order');
			
	// get the schedule periods that are not allowed during the current schedule period
	const unallowed_schedule_periods = availability_matrix[starting_schedule_period_id].concat(excluded_scheduled_period_ids);

	// get the index of the current schedule period (so we don't check any schedule periods before it
	const starting_schedule_period_index = all_schedule_periods.findIndex(schedule_period => schedule_period.period_id === starting_schedule_period_id);

	// Find previous schedule period by traversing all schedule periods before the current, and finding the first one that is not in the unallowed list
	for (let i = starting_schedule_period_index - 1; i >= 0; i--)
	{
		var current_schedule_period_id = all_schedule_periods[i].period_id;
		
		if(!unallowed_schedule_periods.includes(current_schedule_period_id)) return current_schedule_period_id;
	}
	
	return false;
}

export const getNextCoursePeriod = (scheduleStructureData, starting_course_period_id) => {
	const course_periods = scheduleStructureData.course_periods;
	const all_course_periods = Object.keys(course_periods).sort();
	
	// get the index of the current schedule period (so we don't check any schedule periods before it
	var starting_course_period_index = all_course_periods.indexOf(starting_course_period_id);
	
	var next_course_period_id = all_course_periods[starting_course_period_index + 1];
	
	if(!next_course_period_id) return false;
	
	return next_course_period_id;
}

export const getPreviousCoursePeriod = (scheduleStructureData, starting_course_period_id) => {
	const course_periods = scheduleStructureData.course_periods;
	const all_course_periods = Object.keys(course_periods).sort();
	
	// get the index of the current schedule period (so we don't check any schedule periods before it
	var starting_course_period_index = all_course_periods.indexOf(starting_course_period_id);
	
	var previous_course_period_id = all_course_periods[starting_course_period_index - 1];
	
	if(!previous_course_period_id) return false;
	
	return previous_course_period_id;
}

export const coursePeriodToSchedulePeriod = (scheduleStructureData, course_period_id, day) => {
	const course_periods = scheduleStructureData.course_periods;
	const schedule_period_id = course_periods[course_period_id]['days'][day];
	
	return schedule_period_id;
}

export const schedulePeriodToCoursePeriod = (scheduleStructureData, schedule_period_id, day) => {
	const schedule_structure = scheduleStructureData.schedule_structure;
	const course_period_id = schedule_structure[day][schedule_period_id];
	
	return course_period_id;
}

export const calculateAffectedSectionsForPlacements = (passed_data) => {
	const { sections, subsections, section_id, new_course_period_id, old_course_period_id } = passed_data;

	const section_index = sections.findIndex(section => section.section_id === section_id);
	const section_info = sections[section_index];

	let affected_sections = [];

	// Add dragged section as affected section
	affected_sections.push({section_id:section_id, new_course_period_id:new_course_period_id, old_course_period_id:old_course_period_id, edit_teacher:true});
	
	// Check if dragged is subsection 
	const subsection_index = subsections.findIndex(subsection => subsection.subsection_id === section_id);

	// If dragged is not subsection, collect subsections and update them too
	// If dragged is subsection, collect main section AND all other subsections and update them too
	if(subsection_index === -1)
	{
		const section_subsections = section_info.subsections;

		if(section_subsections.length > 0)
		{
			section_subsections.forEach(subsection_id => {
				affected_sections.push({section_id:subsection_id, new_course_period_id:new_course_period_id, old_course_period_id, edit_teacher:false});
			});
		}
	}
	else
	{
		const subsection_info = subsections[subsection_index];

		// Get main section info
		const main_section_id = subsection_info.section_id;
		const main_section_info = sections.find(section => section.section_id === main_section_id);

		// Add main section as affected section
		affected_sections.push({section_id:main_section_id, new_course_period_id:new_course_period_id, old_course_period_id, edit_teacher:false});

		// Add other subsections of the main section as affected sections too
		if(main_section_info.subsections.length > 0)
		{
			main_section_info.subsections.forEach(other_subsection_id => {
				if(other_subsection_id !== section_id) affected_sections.push({section_id:other_subsection_id, new_course_period_id:new_course_period_id, old_course_period_id:old_course_period_id, edit_teacher:false});
			});
		}
	}

	return affected_sections;
}

export const verifyUser = async (data) => {
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/verify-user';
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
    await API.post(apiName, url, myInit);
    
   	return;
}

export const copyPreviousYearData = async (data, _callback = () => {}) => {
	// data includes school_id, selected_schedule_year_id, courses_checked, teachers_checked, classrooms_checked, students_checked
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/copy-previous-year-data';
	const myInit = { // OPTIONAL
		response: true,
		body: JSON.stringify(data),
	};
	
	await API.post(apiName, url, myInit);

	return;
}

export const switchCurrentYear = async (data, _callback = () => {}) => {
	// data includes school_id, selected_schedule_year_id

	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/switch-current-year';
	const myInit = { // OPTIONAL
		response: true,
		body: JSON.stringify(data),
	};
	
	await API.post(apiName, url, myInit);

	_callback();
}

export const getCurrentSubscreen = async (data, _callback = () => {}) => {
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/get-current-subscreen';
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
    const response = await API.post(apiName, url, myInit);
    const result = response.data;
    
    _callback(result);
}

export const getDepartmentProgress = async (passed_data) => {
	const school_id = ('school_id' in passed_data) ? passed_data.school_id : null;
	const schedule_version_id = ('schedule_version_id' in passed_data) ? passed_data.schedule_version_id : null;
	const subscreen = ('subscreen' in passed_data) ? passed_data.subscreen : null;

	
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/get-department-progress';
    const myInit = { // OPTIONAL
        response: true,
        queryStringParameters: {school_id:school_id, subscreen:subscreen, schedule_version_id:schedule_version_id},
    };
    
    let data;
    
	try {
		const response = await API.get(apiName, url, myInit);
		data = response.data;
	} catch(e)
	{
		console.log(e.response);
	}
	
	return data;
}
	
export const checkPermissions = async (passed_data) => {
	const school_id = passed_data.school_id;
	const subscreen = passed_data.subscreen;
	const schedule_version_id = passed_data.schedule_version_id;
	const database = ('database' in passed_data) ? passed_data.database : 'edario';
	const _callback = ('callback' in passed_data) ? passed_data.callback : ()=>{};
	const forceLock = ('forceLock' in passed_data) ? passed_data.forceLock : false;

	let result= {};
	
	if(!forceLock)
	{
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/check-screen-permissions';
		const data = {school_id: school_id, schedule_version_id:schedule_version_id, selected_subscreen:subscreen, database:database};
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	    
	    const response = await API.post(apiName, url, myInit);
	    result = response.data;
	}
	else
	{
		result.permissions = 'locked';
	}
	
	_callback(result);
}

export const getSchoolRunningProcesses = async (passed_data) => {
	const school_id = passed_data.school_id;
	const schedule_year_id = passed_data.schedule_year_id;

	// Process Types:
	//course data = 1
	//previous section data = 2
	//student requests = 3
	//students = 4
	// predict sections = 5 
	//sectionplace = 6
	//studentplace = 7
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/process/check-progress';
	const myInit = {
        response: true,
        queryStringParameters: { 
          school_id: school_id, 
		  schedule_year_id: schedule_year_id
        }
    };
	
	const response = await API.get(apiName, url, myInit);
	const result = response.data;
	
	return result;
}

export const checkProcessProgress = async (data) => {
	const school_id = data.school_id;
	const progress_id = data.progress_id;

	// Process Types:
	//course data = 1
	//previous section data = 2
	//student requests = 3
	//students = 4
	// predict sections = 5 
	//sectionplace = 6
	//studentplace = 7
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/process/check-progress';
	const myInit = {
        response: true,
        queryStringParameters: { 
          school_id: school_id, 
		  progress_id: progress_id
        }
    };
	
	const response = await API.get(apiName, url, myInit);
	const result = response.data;
	
	return result;
}

export const getProcessFeedback = async (data) => {
	const school_id = data.school_id;
	const progress_id = data.progress_id;
	const data_version_id = data.data_version_id;
	const process_type = data.process_type;
	const department_id = ('department_id' in data) ? data.department_id : null;

	// Process Types:
	//course data = 1
	//previous section data = 2
	//student requests = 3
	//students = 4
	// predict sections = 5 
	//sectionplace = 6
	//studentplace = 7

	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/process/get-feedback';
	const myInit = {
        response: true,
        queryStringParameters: { 
          school_id: school_id, 
		  progress_id: progress_id,
		  data_version_id: data_version_id,
          process_type: process_type, 
		  ...department_id && ({department_id}),
        }
    };
	
	const response = await API.get(apiName, url, myInit);
	const result = response.data;
	
	return result;
}

export const clearProcessFeedback = async (data) => {
	const school_id = data.school_id;
	const progress_id = data.progress_id;
	const data_version_id = data.data_version_id;
	const process_type = data.process_type;
	const department_id = ('department_id' in data) ? data.department_id : null;

	// Process Types:
	//course data = 1
	//previous section data = 2
	//student requests = 3
	//students = 4
	// predict sections = 5 
	//sectionplace = 6
	//studentplace = 7

	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/process/clear-feedback';
	const myInit = {
        response: true,
        queryStringParameters: { 
          school_id: school_id, 
		  progress_id: progress_id,
		  data_version_id: data_version_id,
          process_type: process_type, 
		  ...department_id && ({department_id}),
        }
    };
	
	const response = await API.del(apiName, url, myInit);
	const result = response.data;
	
	return result;
}

export const continueProcess = async (data) => {
	// Process Types:
	//course data = 1
	//previous section data = 2
	//student requests = 3
	//students = 4
	// predict sections = 5 
	//sectionplace = 6
	//studentplace = 7

	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/process/continue';
	const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
	
	const response = await API.post(apiName, url, myInit);
	const result = response.data;
	
	return result;
}

export const getData = async (dataName, url, params = {}, forceNew = false, database = 'edario') => {
	const apiName = (database === 'edario') ? process.env.REACT_APP_ENDPOINT_NAME : process.env.REACT_APP_ELEM_ENDPOINT_NAME;
    const myInit = { // OPTIONAL
        response: true,
        queryStringParameters: params,
    };
    
    let data;
    	
	if(!JSONexists(`${dataName}`) || forceNew)
	{
		try {
			const response = await API.get(apiName, url, myInit);
			data = response.data;
		} catch(e)
		{
			console.log(e.response);
		}
		
		//console.log(`GETTING [${dataName}] DATA FROM DATABASE`);
		//console.log(data);
		
		localStorage.setItem(`${dataName}`, JSON.stringify(data));

		if(dataName === 'sections')
		{
			getSectionConnections(params.school_id, params.schedule_version_id).then(connections => {
				let sections_with_connections = [];

				data.sections.forEach(specific_data => {
					const section_id = specific_data.section_id;

					const course_periods = (section_id in connections.course_periods) ? connections.course_periods[section_id] : [];
					const section_departments = (section_id in connections.departments) ? connections.departments[section_id] : [];
					const quarter_days = (section_id in connections.quarter_days) ? connections.quarter_days[section_id] : [];
					const student_list = (section_id in connections.student_list) ? connections.student_list[section_id] : [];
					const subsections = (section_id in connections.subsections) ? connections.subsections[section_id] : [];
					const labels = (section_id in connections.labels) ? connections.labels[section_id] : [];
					const secondary_teachers = (section_id in connections.secondary_teachers) ? connections.secondary_teachers[section_id] : [];
					
					specific_data.course_periods = course_periods;
					specific_data.departments = section_departments;
					specific_data.quarter_days = quarter_days;
					specific_data.student_list = student_list;
					specific_data.subsections = subsections;
					specific_data.labels = labels;
					specific_data.secondary_teachers = secondary_teachers;

					sections_with_connections.push(specific_data);
				});

				data.sections = sections_with_connections;
			});
		}
		else if(dataName === 'blocks')
		{
			getBlockConnections(params.school_id, params.schedule_version_id).then(block_connections => {
				let blocks_with_connections = [];

				data.forEach(specific_data => {
					const block_id = specific_data.block_id;

					const secondary_teachers = (block_id in block_connections.secondary_teachers) ? block_connections.secondary_teachers[block_id] : [];
					const student_list = (block_id in block_connections.students_in_blocks) ? block_connections.students_in_blocks[block_id] : [];

					specific_data.secondary_teachers = secondary_teachers;
					specific_data.student_list = student_list;

					blocks_with_connections.push(specific_data);
				});

				data = blocks_with_connections;
			});
		}
	}
	else
	{
		const storageInfo = getJSONFromStorage(`${dataName}`, true);
		
		//console.log(`GETTING [${dataName}] DATA FROM STORAGE`);
		//console.log(storageInfo);
		data = storageInfo;
	}
	
	return data;
}

export const getSectionConnections = async (school_id, schedule_version_id) => {
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const myInit = { // OPTIONAL
		response: true,
		queryStringParameters: {school_id: school_id, schedule_version_id:schedule_version_id},
	};

	try {
		const response = await API.get(apiName, '/get-section-connections', myInit);
		const connections = response.data;

		return connections;
	} catch(e)
	{
		console.log(e.response);
	}
}

export const getBlockConnections = async (school_id, schedule_version_id) => {
	const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
	const myInit = { // OPTIONAL
		response: true,
		queryStringParameters: {school_id: school_id, schedule_version_id:schedule_version_id},
	};

	try {
		const response = await API.get(apiName, '/elementary/get-block-connections', myInit);
		const connections = response.data;

		return connections;
	} catch(e)
	{
		console.log(e.response);
	}
}

export const loadDataConnections = async (passed_data) => {
	const dataTypes = passed_data.data_types;
	const dataTypesStringified = JSON.stringify(dataTypes);
	const scheduleVersionID = passed_data.schedule_version_id;
	const dataVersionID = passed_data.data_version_id;
	const schoolId = passed_data.school_id;
	const database = ('database' in passed_data) ? passed_data.database : 'edario';
	
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const params = {school_id:schoolId, data_types:dataTypesStringified, data_version_id:dataVersionID, schedule_version_id:scheduleVersionID, database:database};
    const myInit = { // OPTIONAL
        response: true,
        queryStringParameters: params,
    };
    
    let data;
    
    try {
		const response = await API.get(apiName, '/load-data-connections', myInit);
		data = response.data;
	} catch(e)
	{
		console.log(e.response);
	}
	
	return data;
}

export const loadData = async (passed_data) => {
	const dataTypes = passed_data.data_types;
	const dataTypesStringified = JSON.stringify(dataTypes);
	const scheduleVersionID = passed_data.schedule_version_id;
	const dataVersionID = passed_data.data_version_id;
	const schoolId = passed_data.school_id;
	const database = ('database' in passed_data) ? passed_data.database : 'edario';
	
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const params = {school_id: schoolId, data_types: dataTypesStringified, schedule_version_id:scheduleVersionID, database:database};
    const myInit = { // OPTIONAL
        response: true,
        queryStringParameters: params,
    };
    
    let data_with_connections = dataTypes.reduce((results, data_type) => {
		results[data_type] = [];
		return results;
	}, {});
    
    try {
		const response = await API.get(apiName, '/load-data', myInit);
		const data_without_connections_by_data_type = response.data;

		if(Object.keys(data_without_connections_by_data_type).length <= 0) return [];

		// Pull connections for all data types in the dataTypes array
		await loadDataConnections({data_types:dataTypes, schedule_version_id:scheduleVersionID, data_version_id:dataVersionID, school_id:schoolId, database:database}).then(connections_by_data_type => {
			// Iterate through data types to start making connections
			Object.keys(data_without_connections_by_data_type).forEach(data_type => {
				// Get the data without connections in this data type
				const data_without_connections = data_without_connections_by_data_type[data_type];

				// Iterate through all data without connections to start adding connections
				data_without_connections.forEach((specific_data, index) => {
					// Get the connections for this data type
					const connections = connections_by_data_type[data_type];

					// Add connections for this specific data
					if(data_type === 'teachers')
					{
						const teacher_id = specific_data.teacher_id;
		
						const departments = (teacher_id in connections.departments) ? connections.departments[teacher_id] : [];
						const teacher_can_teach = (teacher_id in connections.teacher_can_teach) ? connections.teacher_can_teach[teacher_id] : [];
						const classroom_restrictions = (teacher_id in connections.classroom_restrictions) ? connections.classroom_restrictions[teacher_id] : [];
						const period_restrictions = (teacher_id in connections.period_restrictions) ? connections.period_restrictions[teacher_id] : [];
						const student_restrictions = (teacher_id in connections.student_restrictions) ? connections.student_restrictions[teacher_id] : [];
						const lunch_schedule_period_id = (teacher_id in connections.teacher_lunches) ? connections.teacher_lunches[teacher_id] : null;
						
						specific_data.teacher_can_teach = teacher_can_teach;

						if(database === 'edario')
						{
							specific_data.classroom_restrictions = classroom_restrictions;
							specific_data.departments = departments;
							specific_data.period_restrictions = period_restrictions;
							specific_data.student_restrictions = student_restrictions;
							specific_data.lunch_schedule_period_id = lunch_schedule_period_id;
						}
						else
						{
							specific_data.day_period_restrictions = period_restrictions;
						}
						
					}
					else if(data_type === 'courses')
					{
						const course_id = specific_data.course_id;
		
						const departments = (course_id in connections.departments) ? connections.departments[course_id] : [];
						const teachers_can_teach = (course_id in connections.teachers_can_teach) ? connections.teachers_can_teach[course_id] : [];
						const period_restrictions = (course_id in connections.period_restrictions) ? connections.period_restrictions[course_id] : [];
						const classroom_restrictions = (course_id in connections.classroom_restrictions) ? connections.classroom_restrictions[course_id] : [];
						const course_homerooms = (course_id in connections.course_homerooms) ? connections.course_homerooms[course_id] : [];
						const pullout_groups = (course_id in connections.pullout_groups) ? connections.pullout_groups[course_id] : [];
						const pullout_allowed_courses = (course_id in connections.pullout_allowed_courses) ? connections.pullout_allowed_courses[course_id] : [];
		
						specific_data.teachers_can_teach = teachers_can_teach;
						specific_data.classroom_restrictions = classroom_restrictions;

						if(database === 'edario')
						{
							specific_data.departments = departments;
							specific_data.period_restrictions = period_restrictions;
						}
						else
						{
							specific_data.day_period_restrictions = period_restrictions;
							specific_data.course_homerooms = course_homerooms;
							specific_data.pullout_groups = pullout_groups;
							specific_data.pullout_allowed_courses = pullout_allowed_courses;
						}
						
					}
					else if(data_type === 'classrooms')
					{
						const classroom_id = specific_data.classroom_id;
		
						const departments = (classroom_id in connections.departments) ? connections.departments[classroom_id] : [];
						const course_restrictions = (classroom_id in connections.course_restrictions) ? connections.course_restrictions[classroom_id] : [];
						const teacher_restrictions = (classroom_id in connections.teacher_restrictions) ? connections.teacher_restrictions[classroom_id] : [];
						
						specific_data.course_restrictions = course_restrictions;

						if(database === 'edario')
						{	
							specific_data.departments = departments;
							specific_data.teacher_restrictions = teacher_restrictions;
						}
					}
					else if(data_type === 'students')
					{
						const student_id = specific_data.student_id;
		
						const student_requests = (student_id in connections.student_requests) ? connections.student_requests[student_id] : [];
						const student_sections = (student_id in connections.student_sections) ? connections.student_sections[student_id] : [];
						const student_locked_sections = (student_id in connections.student_locked_sections) ? connections.student_locked_sections[student_id] : [];
						const student_labels = (student_id in connections.student_labels) ? connections.student_labels[student_id] : [];
						const student_restrictions = (student_id in connections.student_restrictions) ? connections.student_restrictions[student_id] : [];
						const teacher_restrictions = (student_id in connections.teacher_restrictions) ? connections.teacher_restrictions[student_id] : [];
						const lunch_schedule_period_id = (student_id in connections.student_lunches) ? connections.student_lunches[student_id] : null;
						
						if(database === 'edario')
						{	
							specific_data.student_requests = student_requests;
							specific_data.student_sections = student_sections;
							specific_data.student_locked_sections = student_locked_sections;
							specific_data.student_labels = student_labels;
							specific_data.student_restrictions = student_restrictions;
							specific_data.teacher_restrictions = teacher_restrictions;
							specific_data.lunch_schedule_period_id = lunch_schedule_period_id;
						}
						else
						{
							specific_data.student_locked_blocks = student_locked_sections;
						}
					}
					else if(data_type === 'departments')
					{
						const department_id = specific_data.department_id;

						const supervisors = (department_id in connections.supervisors) ? connections.supervisors[department_id] : [];

						specific_data.supervisors = supervisors;
					}
		
					data_with_connections[data_type][index] = specific_data;
				});
			});
		});
	} catch(e)
	{
		console.log(e.response);
	}

	return data_with_connections;
}

export const loadSpecificData = async (passed_data) => {
	const schoolId = passed_data.school_id;
	const dataType = passed_data.data_type;
	const dataID = passed_data.data_id;
	const dataVersionID = passed_data.data_version_id;
	const scheduleVersionID = passed_data.schedule_version_id;
	const database = ('database' in passed_data) ? passed_data.database : 'edario';

	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/load-specific-data';
	const params = {school_id:schoolId, data_type:dataType, data_version_id:dataVersionID, schedule_version_id:scheduleVersionID, data_id:dataID, database:database};
    const myInit = { // OPTIONAL
        response: true,
        queryStringParameters: params,
    };
    
    let data;
    
    try {
		const response = await API.get(apiName, url, myInit);
		data = response.data;
	} catch(e)
	{
		console.log(e.response);
	}
	
	return data;
}

export const loadSectionData = async (sectionID, schoolId, dataVersionID = null) => {
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const params = {school_id: schoolId, section_id: sectionID, data_version_id:dataVersionID};
    const myInit = { // OPTIONAL
        response: true,
        queryStringParameters: params,
    };
    
    let data;
    
    try {
		const response = await API.get(apiName, '/load-section-data', myInit);
		data = response.data;
	} catch(e)
	{
		console.log(e.response);
	}
	
	return data;
} 

export const deleteData = async (data) => {
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url ='/admin/delete-data';
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
    await API.post(apiName, url, myInit);
	
	return;
}

export const getNextAppScreen = async (passed_data) => {
	const school_id = passed_data.school_id;
	const completed_subscreen = passed_data.completed_subscreen;
	const schedule_version_id = passed_data.schedule_version_id;
	const database = ('database' in passed_data) ? passed_data.database : 'edario';
	const _callback = ('callback' in passed_data) ? passed_data.callback : ()=>{};

	const data = {school_id:school_id, schedule_version_id:schedule_version_id, completed_subscreen: completed_subscreen, database:database};
	
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/get-next-app-screen'
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
    const response = await API.post(apiName, url, myInit);
    const result = response.data;
	
	_callback(result);
}

export const getNotifications = async (params = {}) => {
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
    const myInit = { // OPTIONAL
        response: true,
        queryStringParameters: params,
    };
    
    let notifications;
    	
	try {
		const response = await API.get(apiName, '/get-notifications', myInit);
		notifications = response.data;
	} catch(e)
	{
		console.log(e.response);
	}
			
	return notifications;
}

export const updateDepartment = async (passed_data) => {
	const school_id = ('school_id' in passed_data) ? passed_data.school_id : null;
	const schedule_version_id = ('schedule_version_id' in passed_data) ? passed_data.schedule_version_id : null;
	const update_type = ('update_type' in passed_data) ? passed_data.update_type : null;
	const department_id = ('department_id' in passed_data) ? passed_data.department_id : null;
	const department = ('department' in passed_data) ? passed_data.department : null;
	const user_id = ('user_id' in passed_data) ? passed_data.user_id : null;

	const data = {school_id: school_id, schedule_version_id:schedule_version_id, update_type:update_type, department_id:department_id, department:department, user_id:user_id};
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/admin/update-department'
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
    const response = await API.post(apiName, url, myInit);
	
	return response.data;	    
} 

export const updateNotification = async (update_type, school_id, schedule_year_id, notification_id = null, subscreen_id = null, department_id = null, notification = null, for_admin = false) => {
	const data = {school_id: school_id, schedule_year_id:schedule_year_id, update_type:update_type, notification_id:notification_id};
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/update-notification'
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
    await API.post(apiName, url, myInit);
    
    const notifications = getNotifications({school_id: school_id, schedule_year_id:schedule_year_id});
	
	return notifications;
}

export const updateNote = async (passed_data) => {
	const school_id = ('school_id' in passed_data) ? passed_data.school_id : null; 
	const update_type = ('update_type' in passed_data) ? passed_data.update_type : null;
	const note_id = ('note_id' in passed_data) ? passed_data.note_id : null;
	const schedule_version_id = ('schedule_version_id' in passed_data) ? passed_data.schedule_version_id : null;
	const subscreen = ('subscreen' in passed_data) ? passed_data.subscreen : null; 
	const department_id = ('department_id' in passed_data) ? passed_data.department_id : null;
	const user_id = ('user_id' in passed_data) ? passed_data.user_id : null;
	const note = ('note' in passed_data) ? passed_data.note : null;
	const tagged_users = ('tagged_users' in passed_data) ? passed_data.tagged_users : null;

	const data = {school_id:school_id, update_type:update_type, note_id:note_id, schedule_version_id:schedule_version_id, subscreen:subscreen, department_id:department_id, user_id:user_id, note:note, tagged_users:tagged_users};
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/update-note'
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
   	const response = await API.post(apiName, url, myInit);
    
	return response.data;
}

export const saveSectionPlacement = async (data) => {
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/save-section-placement';
    const myInit = { // OPTIONAL
        response: true,
        body: JSON.stringify(data),
    };
    
    const response = await API.post(apiName, url, myInit);
	
	return response.data;
}

export const getConflicts = async (data) => {
	// data: {school_id, schedule_version_id, conflicts_to_check, update_all}
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/get-conflicts';
	const myInit = { // OPTIONAL
		response: true,
		queryStringParameters: data,
	};

	const response = await API.get(apiName, url, myInit);

	return response.data;
}

export const calculateConflicts = async (data) => {
	const passed_conflicts_to_check = ('conflicts_to_check' in data) ? data.conflicts_to_check : [];
	const passed_affected_sections = ('affected_sections' in data) ? data.affected_sections : [];
	const passed_affected_blocks = ('affected_blocks' in data) ? data.affected_blocks : [];

	const conflicts_to_check = Array.isArray(passed_conflicts_to_check) ? JSON.stringify(passed_conflicts_to_check) : passed_conflicts_to_check;
	const affected_sections = Array.isArray(passed_affected_sections) ? JSON.stringify(passed_affected_sections) : passed_affected_sections;
	const affected_blocks = Array.isArray(passed_affected_blocks) ? JSON.stringify(passed_affected_blocks) : passed_affected_blocks;

	data.conflicts_to_check = conflicts_to_check;
	data.affected_sections = affected_sections;
	data.affected_blocks = affected_blocks;
	
	// data: {school_id, schedule_version_id, conflicts_to_check, update_all}
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/calculate-conflicts';
	const myInit = { // OPTIONAL
		response: true,
		queryStringParameters: data,
	};

	const response = await API.get(apiName, url, myInit);

	return response.data;
}


export const checkConflicts = async (passed_data) => {
	// data: {school_id, schedule_version_id, conflicts_to_check, update_all}
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/check-conflicts';
	const myInit = { // OPTIONAL
		response: true,
		queryStringParameters: passed_data,
	};

	const response = await API.get(apiName, url, myInit);
	const conflicts_exist = response.data.conflicts_exist;

	// If conflicts don't exist yet, calculate them
	if(!conflicts_exist) await calculateConflicts(passed_data);

	// Then pull conflicts
	const conflict_data = getConflicts(passed_data);
	return conflict_data;
}

export const updateConflicts = async (data) => {
	// data: {school_id, schedule_version_id, changes_made:[]}
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/update-conflicts';
	const myInit = { // OPTIONAL
		response: true,
        body: JSON.stringify(data),
	};

	const response = await API.post(apiName, url, myInit);
	return response.data;	
}

export const recalculateMatchPercent = async (data) => {
	// Send to Backend ///
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/recalculate-match-percent';
	const myInit = { // OPTIONAL
		response: true,
		body: JSON.stringify(data),
	};

	try {
		const result = await API.post(apiName, url, myInit);
		const response_data = result.data;

		const match_percent = response_data.match_percent;

		return match_percent;
	} catch(e)
	{
		console.log(e.response);
	}
}

export const deleteSection = async (data) => {
	// Update database
	const apiName = process.env.REACT_APP_ENDPOINT_NAME;
	const url = '/admin/delete-section';
	const myInit = { // OPTIONAL
		response: true,
		body: JSON.stringify(data),
	};
   
	try {
		const result = await API.post(apiName, url, myInit);
		const response_data = result.data;
		
		return response_data;
	} catch(e)
	{
		console.log(e.response);
	}
}
