import React, { useState , useEffect, useRef, useCallback } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import Fuse from 'fuse.js';
import swal from '@sweetalert/with-react';

import DepartmentProgressScreen from './department_progress_screen';
import SectionReviewEditingSecondaryTeacher from './reusable-components/SectionReviewEditingSecondaryTeacher';

import Dropdown from '../../Dropdown';
import Button from '../../Button';
import Portal from '../../Portal';
import Toggle from '../../Toggle';

import EditCourseData from '../modals/edit_course';
import EditTeacherData from '../modals/edit_teacher';
import StudentRequests from '../reports/student_requests';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faLock, faUserCircle, faCheck, faEdit, faCheckCircle, faHourglassHalf, faExclamationCircle, faPrint, faSearch, faThLarge, faGripLines, faChevronLeft, faUnlock, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt, faClone } from '@fortawesome/free-regular-svg-icons';

import predictive_chart from '../../../images/predictive-chart.png';
import copy_sections from '../../../images/copy-sections.png';
import manually_create_sections from '../../../images/manually-create-sections.png';
import loading_gif from '../../../images/balls.gif';

import { getSchoolRunningProcesses, cloneObj, capitalizeFirstLetters, createUniqueObjectID, sortArrayOfObjects, arraysEqual, useOutsideClick, getData, deleteSection, recalculateMatchPercent, calculateConflicts } from '../../../js/Helpers';
import { changeDataDepartment } from '../../../js/SaveFunctions';

const colorClasses = ['pastel-orange','yellow', 'pastel-green', 'pastel-light-blue', 'pastel-light-purple', 'pastel-turquoise'];
let typingTimeout = null;

export default function SectionReview({ schoolSubscribed, subscreenRestricted, subscreenLocked, subscreenResponsibleRoleID,  getSubscreenDepartmentProgress, getNewData, subscreen, departments, sortedDepartments, departmentID, changeDepartment, setDepartments, userInfo, schoolInfo, scheduleInfo, scheduleStructureData, teachers, setTeachers, courses, setCourses, students, setStudents, classrooms, sectionsLoading, sections, setSections, subsections, setSubsections, labels, users, userIsAdmin, userIsSupervisor, supervisors, closeSupervisorsList, openSupervisorsList, addSupervisorToDepartment, removeSupervisorFromDepartment, subscreenSelect, submitDepartment, reviewing, toggleReviewDepartment, resetProgress, changesSavedMessage, setChangesSavedMessage, setRolePages, showNextButton, handleMoveToNextScreen, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo }) {
	const ref = useRef();
	const extra_settings_ref = useRef();
	const printRef = useRef();

	const [isLoading, setIsLoading] = useState(true);

	const [departmentInfo, setDepartmentInfo] = useState(null);

	const [currentView, setCurrentView] = useState('course');
	const [currentLayout, setCurrentLayout] = useState('grid');

	const [creationOption, setCreationOption] = useState(null);

	const [departmentHasCurrentSections, setDepartmentHasCurrentSections] = useState(false);
	const [departmentHasPastSections, setDepartmentHasPastSections] = useState(false);
	const [requestsSubmitted, setRequestsSubmitted] = useState(false);
	const [sectionPredictionHasStarted, setSectionPredictionHasStarted] = useState(false);
	const [sectionPredictionFinished, setSectionPredictionFinished] = useState(false);

	const [departmentHasPastSectionsChecked, setDepartmentHasPastSectionsChecked] = useState(false);
	const [requestsSubmittedChecked, setRequestsSubmittedChecked] = useState(false);
	const [sectionPredictionProcessChecked, setSectionPredictionProcessChecked] = useState(false);
	
	const [dataArray, setDataArray] = useState([]);
	const [filteredIDs, setFilteredIDs] = useState([]);

	const [addDataOpen, setAddDataOpen] = useState(false);
	const [addResults, setAddResults] = useState([]);
	const [addNewDataOpen, setAddNewDataOpen] = useState(false);
	const [extraSettingsOpen, setExtraSettingsOpen] = useState(false);
	const [duplicateSectionsInProgress, setDuplicateSectionsInProgress] = useState([]);
	
	const [studentRequestReportOpen, setStudentRequestReportOpen] = useState(false);
	const [courseID, setCourseID] = useState(null);

	const [currentScreenSubmitted, setCurrentScreenSubmitted] = useState(false);
	const [currentScreenConfirmed, setCurrentScreenConfirmed] = useState(false);
	const [currentScreenUnlocked, setCurrentScreenUnlocked] = useState(false);
	const [currentUserCanAddSupervisors, setCurrentUserCanAddSupervisors] = useState(false);
	const [showCurrentScreen, setShowCurrentScreen] = useState(false);
	const [screenCompleteForUser, setScreenCompleteForUser] = useState(false);

	//////////////////////////////
	///// UTILITY FUNCTIONS /////
	/////////////////////////////
	useOutsideClick(ref, () => {
		closeSupervisorsList();
	});

	useOutsideClick(extra_settings_ref, () => {
		setExtraSettingsOpen(false);
	});


	const toggleOpenExtraSettings = () => {
		setExtraSettingsOpen(!extraSettingsOpen);
	}

	const addSearch = (e) => {
		const search_value = e.target.value;
		const search_data = (currentView === 'course') ? courses : teachers;
		const search_keys = (currentView === 'course') ? ['name', 'course_code'] : ['name', 'first_name'];

		const fuse = new Fuse(search_data, {
			keys: search_keys,
			threshold: .6
		})
		
		const results = fuse.search(search_value);
		const data_results = results.map(result => result.item).splice(0,5);
		
		setAddResults(data_results);
	}

	const resetDepartmentCheck = () => {
		setIsLoading(true);
		setDepartmentHasCurrentSections(false);
		setDepartmentHasPastSections(false);
		setSectionPredictionHasStarted(false);
		setSectionPredictionFinished(false);
		setDepartmentHasPastSectionsChecked(false);
		setSectionPredictionProcessChecked(false);
	}
	
	const updateHTML = useCallback(() => {
		if(!departmentID || isLoading) return;
		
		const department_teachers = teachers.filter(teacher => teacher.departments.includes(departmentID) || sections.findIndex(section => (section.teacher_id === teacher.teacher_id || section.secondary_teachers.includes(teacher.teacher_id)) && section.departments.includes(departmentID)) !== -1);
		const department_courses = courses.filter(course => course.departments.includes(departmentID));
		
		const data_array = (currentView === 'teacher') ? department_teachers : department_courses;
		data_array.forEach((data_info, i) => {
			const data_id = (currentView === 'teacher') ? data_info.teacher_id : data_info.course_id;

			let logged_data_section_span_ids = [];
			let data_sections = sections.reduce((results, section) => {
				const section_type = section.section_type;
				const is_lab = section.is_lab;
				const span_id = section.span_id;
				const course_id = section.course_id;
				const teacher_id = section.teacher_id;
				const secondary_teachers = section.secondary_teachers;
				const is_relevant_section = (section[`${currentView}_id`] === data_id) || (currentView === 'teacher' && secondary_teachers.includes(data_id));

				// Skip if this section is a placeholder inclusion section
				if(scheduleInfo.separate_ICR_codes === '0')
				{
					const section_id = section.section_id;
					const section_is_inclusion = section.is_inclusion;
					const subsection_index = subsections.findIndex(subsection => subsection.subsection_id === section_id);
					const is_subsection = subsection_index !== -1;

					if(is_subsection && section_is_inclusion)
					{
						const main_section_id = subsections[subsection_index].section_id;
						const main_section_info = sections.find(temp_section => temp_section.section_id === main_section_id);
						const main_section_course_id = (main_section_info) ? main_section_info.course_id : null;

						if(course_id && course_id === main_section_course_id) return results;
					}
				}

				if(section_type !== '1') return results;
				if(!is_relevant_section) return results;
				if(is_lab === '1') return results;
				if(span_id && logged_data_section_span_ids.includes(span_id)) return results;
				if(span_id) logged_data_section_span_ids.push(span_id);

				const course_info = courses.find(course => course.course_id === course_id);
				const course_name = (course_info) ? course_info.name : null;
				const course_code = (course_info) ? course_info.course_code : null;
				
				const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
				const teacher_name = (teacher_info) ? teacher_info.name : null;
				const teacher_first_name = (teacher_info) ? teacher_info.first_name: null;

				section.course_name = course_name;
				section.course_code = course_code;
				section.teacher_name = teacher_name;
				section.teacher_first_name = teacher_first_name;

				results.push(section);
				return results;
			}, []);

			// Add any sections currently being duplicated
			data_sections = duplicateSectionsInProgress.reduce((results, section) => {
				const course_id = section.course_id;
				const teacher_id = section.teacher_id;
				const secondary_teachers = section.secondary_teachers;
				const is_relevant_section = (section[`${currentView}_id`] === data_id) || (currentView === 'teacher' && secondary_teachers.includes(data_id));
				if(!is_relevant_section) return results;

				const course_info = courses.find(course => course.course_id === course_id);
				const course_name = (course_info) ? course_info.name : null;
				const course_code = (course_info) ? course_info.course_code : null;
				
				const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
				const teacher_name = (teacher_info) ? teacher_info.name : null;
				const teacher_first_name = (teacher_info) ? teacher_info.first_name: null;

				section.course_name = course_name;
				section.course_code = course_code;
				section.teacher_name = teacher_name;
				section.teacher_first_name = teacher_first_name;

				results.push(section);
				return results;
			}, data_sections);

			const sorted_data_sections = (currentView === 'teacher') ? sortArrayOfObjects(data_sections, 'course_code', 'text', 'asc') : sortArrayOfObjects(data_sections, 'teacher_name', 'text', 'asc');

			//Organize sections by subcategory
			let subcategories = {};
			for(const data_section of sorted_data_sections)
			{

				if(currentView === 'course')
				{
					const teacher_id = data_section.teacher_id;
					const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
					const teacher_name = (teacher_info) ? teacher_info.name : null;
					const teacher_first_name = (teacher_info) ? teacher_info.first_name: null;

					if(!(teacher_id in subcategories)) subcategories[teacher_id] = {subcategory_id:teacher_id, subcategory_name:`${teacher_name}, ${teacher_first_name}`, subcategory_sections:[]};
					subcategories[teacher_id].subcategory_sections.push(data_section);
				}
				else
				{
					const course_id = data_section.course_id;
					const course_info = courses.find(course => course.course_id === course_id);
					const course_name = (course_info) ? course_info.name : null;
					const course_code = (course_info) ? course_info.course_code : null;

					if(!(course_id in subcategories)) subcategories[course_id] = {subcategory_id:course_id, subcategory_name:course_code, subcategory_sections:[]};
					subcategories[course_id].subcategory_sections.push(data_section);
				}
			}

			// Create array of sorted subcategorys
			let subcategories_array = [];
			for(const subcategory_id in subcategories)
			{
				subcategories_array.push(subcategories[subcategory_id]);
			}
			const sorted_subcategories_array = sortArrayOfObjects(subcategories_array, 'subcategory_name', 'text', 'asc')

			// Reorganize sections under a subcategory to be in section number order
			for(const [index, subcategory] of sorted_subcategories_array.entries())
			{
				const subcategory_sections = subcategory.subcategory_sections;
				const sorted_subcategory_sections = sortArrayOfObjects(subcategory_sections, 'section_number', 'number', 'asc');
				
				sorted_subcategories_array[index].subcategory_sections = sorted_subcategory_sections;
			}


			// Add number requests for courses
			const num_requests_for_course = (currentView === 'teacher') ? {total: 0, alternates:0} : students.reduce((result, student) => {
				const student_request_index = student.student_requests.findIndex(request => request.course_id === data_id && request.is_deleted === '0');
				
				if(student_request_index !== -1) 
				{
					const student_request_info = student.student_requests[student_request_index];

					if(student_request_info.priority !== '1') result.alternates++;
					if(student_request_info.is_inclusion === '1') result.inclusion++;
					result.total++;
				}

				return result;
			}, {total: 0, alternates:0, inclusion:0});
			data_info.num_requests_for_course = num_requests_for_course;

			const elective = (currentView === 'course') ? data_info.elective : '0';
			const max_class_size = (currentView === 'course') ? data_info.max_class_size : '0';
			const average_class_size = (num_requests_for_course.total === 0 || data_sections.length === 0) ? 0 : ((elective === '1') ? Math.min(Math.round(num_requests_for_course.total/data_sections.length), parseInt(max_class_size)) : Math.round(num_requests_for_course.total/data_sections.length));
			data_info.average_class_size = average_class_size;

			data_array[i] = {...data_info, sections_by_subcategory:sorted_subcategories_array};
		});
			
		setDataArray([...data_array]);
	},[departmentID, isLoading, teachers, sections, courses, currentView, duplicateSectionsInProgress])

	const changeDepartmentResource = async (changeType, dataType, data_id, old_department_id, new_department_id, _callback=()=>{}) => {
		if(old_department_id === new_department_id) return;
		if(changeType === "change" && new_department_id === '0') changeType = 'remove';
		
		/////////////////////////
		///// GET DATA INFO /////
		/////////////////////////
		let data_index, data_details, data_name;
		if(dataType === 'teacher')
		{
			data_index = teachers.findIndex(teacher => teacher.teacher_id === data_id);
			data_details = teachers[data_index];
			
			const teacher_first_name = data_details.first_name;
			const teacher_last_name = data_details.name;
			
			data_name = `${teacher_first_name} ${teacher_last_name}`;
		}
		else if(dataType === 'course')
		{
			data_index= courses.findIndex(course => course.course_id === data_id);
			data_details = courses[data_index];
			
			const course_name = capitalizeFirstLetters(data_details.name);
			const course_code = data_details.course_code;
			
			data_name = `${course_name} (${course_code})`;
		}
		
		const old_deparment_info = departments.find(department => department.department_id === old_department_id);
		const new_deparment_info = departments.find(department => department.department_id === new_department_id);
		
		const department = (changeType === 'remove') ? old_deparment_info['department'] : new_deparment_info['department'];
		
		//////////////////////
		///// SHOW MODAL /////
		//////////////////////
		// Show modal unless Dont Show Again was selected
		
		const modal_text = (changeType === 'remove') ? `Do you really want to remove ${data_name} from the ${department} department?` : ((changeType === 'add') ? `Do you really want to add ${data_name} to the ${department} department?`: null);
		
		const options =  {
			title: "Are you sure?",
			text: modal_text,
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			}
		}
	
		const change_data_department_bool = await swal(options);
		
		////////////////////////////////
		///// UPDATE DATABASE/JSON /////
		////////////////////////////////
		// Add, change, or remove in database
		// Then add, change, or remove in JSON
		
		if(change_data_department_bool)
		{
			const data = {school_id: schoolInfo.school_id, change_type:changeType, data_type:dataType, schedule_version_id:schoolInfo.current_schedule_version_id, data_id:data_id, old_department_id:old_department_id, new_department_id:new_department_id, data_name:data_name};
			
			changeDataDepartment(data).then(() => {
				// Remove old department
				if(changeType === 'remove')
				{
					if(dataType === 'teacher')
					{
						const old_teacher_department_index = teachers[data_index]['departments'].findIndex(department_id => department_id === old_department_id);
						teachers[data_index]['departments'].splice(old_teacher_department_index, 1);
					}
					else if(dataType === 'course')
					{
						const old_course_department_index = courses[data_index]['departments'].findIndex(department_id => department_id === old_department_id);
						courses[data_index]['departments'].splice(old_course_department_index, 1);
					}
				}
				
				// Add new department
				if(changeType !== 'remove')
				{
					if(dataType === 'teacher')
					{
						teachers[data_index]['departments'].push(new_department_id);
					}
					else if(dataType === 'course')
					{
						courses[data_index]['departments'].push(new_department_id);
					}
				}

				setTeachers([...teachers]);
				setCourses([...courses]);

				_callback();
			});
		}
	}

	const updateNewData = (changeType, dataType, data_id, old_department_id, new_department_id) => {
		changeDepartmentResource(changeType, dataType, data_id, old_department_id, new_department_id, () => {
			if(changeType == 'add' && userInfo.role_id === '3') 
			{
				const department_index = departments.findIndex(department => department.department_id === departmentID);

				if(department_index !== -1)
				{
					departments[department_index].subscreens[subscreen].submitted = false;
					departments[department_index].subscreens[subscreen].confirmed = false;
				}
				
				if(currentView === 'teacher')
				{
					// Update cleaning screen progress (for check marks)
					departments[department_index].subscreens['teacher_availability'].submitted = false;
					departments[department_index].subscreens['teacher_availability'].confirmed = false;

					departments[department_index].subscreens['course_teachers'].submitted = false;
					departments[department_index].subscreens['course_teachers'].confirmed = false;

					departments[department_index].subscreens['teacher_classrooms'].submitted = false;
					departments[department_index].subscreens['teacher_classrooms'].confirmed = false;
				}
				else if(currentView === 'course')
				{
					// Update cleaning screen progress (for check marks)
					departments[department_index].subscreens['course_availability'].submitted = false;
					departments[department_index].subscreens['course_availability'].confirmed = false;

					departments[department_index].subscreens['course_caps'].submitted = false;
					departments[department_index].subscreens['course_caps'].confirmed = false;

					departments[department_index].subscreens['course_options'].submitted = false;
					departments[department_index].subscreens['course_options'].confirmed = false;

					departments[department_index].subscreens['course_other_options'].submitted = false;
					departments[department_index].subscreens['course_other_options'].confirmed = false;

					departments[department_index].subscreens['course_teachers'].submitted = false;
					departments[department_index].subscreens['course_teachers'].confirmed = false;

					departments[department_index].subscreens['course_classrooms'].submitted = false;
					departments[department_index].subscreens['course_classrooms'].confirmed = false;
				}

				setDepartments([...departments]);

				// Reset overall progress to update the Progress screen
				getData('schoolProgress', '/get-school-progress', {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true).then(user_progress_data => {
					const role_pages = user_progress_data.role_pages;
					
					setRolePages(role_pages);
				});
			}
		});
	}

	const handleSearch = (e) => {
		if(typingTimeout) clearTimeout(typingTimeout);
		
		const search_value = e.target.value;
		
		typingTimeout = setTimeout(() => {
			if(search_value.length > 1)
			{
				// Find search values of courses that seem to match
				const courses_fuse = new Fuse(courses, {
					keys: ['course_code', 'name'],
					threshold: .1
				});
				
				const course_results = courses_fuse.search(search_value);
				const allowed_course_ids = course_results.map(result => result.item.course_id);

				// Find search values of teachers that seem to match
				const teachers_fuse = new Fuse(teachers, {
					keys: ['first_name', 'name'],
					threshold: .1
				});
				
				const teacher_results = teachers_fuse.search(search_value);
				const allowed_teacher_ids = teacher_results.map(result => result.item.teacher_id);

				// Set the initial allowed list for this screen
				const initial_allowed_list = (currentView === 'teacher') ? allowed_teacher_ids : allowed_course_ids;

				// Now add any additional data that might be missed bc it has a match to subdata
				const complete_allowed_list = sections.reduce((results, section) => {
					const section_teacher_id = section.teacher_id;
					const section_course_id = section.course_id;

					if(currentView === 'teacher' && allowed_course_ids.includes(section_course_id)) results.push(section_teacher_id);
					if(currentView === 'course' && allowed_teacher_ids.includes(section_teacher_id)) results.push(section_course_id);
					return results;
				}, initial_allowed_list);

				setFilteredIDs(complete_allowed_list);
			}
			else
			{
				setFilteredIDs([]);
			}
		}, 200);
	}
	
	const fuzzySearch = (e, search_type, section_id) => {
		const section_index = sections.findIndex(section => section.section_id === section_id);
		const temp_section_info = ('temp_changes' in sections[section_index]) ? sections[section_index]['temp_changes'] : {};
		const search_value = e.target.value;
		
		if(search_type === 'courses')
		{
			/////////////////////////////////
			///// GET SUGGESTED RESULTS /////
			/////////////////////////////////
			const temp_teacher_id = temp_section_info.teacher_id;
			let suggested_results = [];
			
			// Search only through teacher can teach courses
			if(temp_teacher_id)
			{
				const teacher_can_teach = teachers.find(teacher => teacher.teacher_id === temp_teacher_id).teacher_can_teach;
				const teachers_courses = [];
				
				teacher_can_teach.forEach(tct_info => {
					const tct_course_id = tct_info.course_id;
					const tct_course = courses.find(course => course.course_id === tct_course_id);
					
					teachers_courses.push(tct_course);
				});
				
				const fuse = new Fuse(teachers_courses, {
					keys: ['course_code', 'name'],
					threshold: .1
				});
				
				const results = fuse.search(search_value);
				suggested_results = results.map(result => result.item);
			}
			
			//////////////////////////////
			///// GET SEARCH RESULTS /////
			//////////////////////////////
			const fuse = new Fuse(courses, {
				keys: ['course_code', 'name'],
				threshold: .1
			});
			
			const results = fuse.search(search_value);
			const course_results = results.map(result => result.item).slice(0,4);
			
			///////////////////////
			///// SET RESULTS /////
			///////////////////////
			sections[section_index]['temp_changes']['course_results'] = {search: course_results, suggested:suggested_results};
			sections[section_index]['temp_changes']['teacher_results'] = {search: [], suggested:[]};
		}
		else if(search_type === 'teachers')
		{	
			/////////////////////////////////
			///// GET SUGGESTED RESULTS /////
			/////////////////////////////////
			const temp_course_id = ('course_id' in temp_section_info) ? temp_section_info.course_id : null;
			let suggested_results = [];
			
			// Search only through teacher can teach teachers
			if(temp_course_id)
			{
				const teachers_can_teach = courses.find(course => course.course_id === temp_course_id).teachers_can_teach;
				const courses_teachers = [];
				
				teachers_can_teach.forEach(tct_info => {
					const tct_teacher_id = tct_info.teacher_id;
					const tct_teacher = teachers.find(teacher => teacher.teacher_id === tct_teacher_id);
					
					courses_teachers.push(tct_teacher);
				});
				
				const fuse = new Fuse(courses_teachers, {
					keys: ['first_name', 'name'],
					threshold: .1
				});
				
				const results = fuse.search(search_value);
				suggested_results = results.map(result => result.item);
			}
			
			//////////////////////////////
			///// GET SEARCH RESULTS /////
			//////////////////////////////
			const fuse = new Fuse(teachers, {
				keys: ['first_name', 'name'],
				threshold: .1
			});
			
			const results = fuse.search(search_value);
			const teacher_results = results.map(result => result.item).slice(0,4);
			
			///////////////////////
			///// SET RESULTS /////
			///////////////////////
			sections[section_index]['temp_changes']['teacher_results'] = {search: teacher_results, suggested:suggested_results};
			sections[section_index]['temp_changes']['course_results'] = {search: [], suggested:[]};
		}
		
		setSections([...sections]);
	}

	const openStudentRequestReport = (course_id) => {
		setCourseID(course_id);
		setStudentRequestReportOpen(true);
	}
	
	const changeView = (new_view) => {
		setCurrentView(new_view);
	}
	
	////////////////////////////
	///// UPDATE FUNCTIONS /////
	////////////////////////////
	const predictSections = async () => {
		setSectionPredictionHasStarted(true); // switch screen to show that placements have started

		const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_version_id:schoolInfo.current_data_version_id, department_id:departmentID};
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/predict-sections';
		const myInit = { // OPTIONAL
			response: true,
			queryStringParameters: data,
		};
	
		try {
			await API.get(apiName, url, myInit);
			
			getSubscreenDepartmentProgress(subscreen, schoolInfo.school_id, departmentID);

			setDepartmentHasCurrentSections(true);
			setSectionPredictionFinished(true);

			getNewData();
		} catch(error)
		{
			setSectionPredictionHasStarted(false);
			setSectionPredictionFinished(false);
			console.log(error.response);
		}
	}

	const copyPreviousYearSections = async () => {
		setSectionPredictionHasStarted(true); // switch screen to show that placements have started
		setSectionPredictionFinished(false);

		const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_version_id:schoolInfo.current_data_version_id, department_id:departmentID};
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/copy-previous-year-sections';
		const myInit = { // OPTIONAL
			response: true,
			queryStringParameters: data,
		};
	
		try {
			await API.get(apiName, url, myInit);
			
			getNewData();
			
			setDepartmentHasCurrentSections(true);
			setSectionPredictionFinished(true);
		} catch(error)
		{
			console.log(error.response);
		}
	}

	const createSections = (creation_option = creationOption) => {
		if(creation_option === 'predict')
		{
			predictSections();
		}
		else if(creation_option === 'copy')
		{
			copyPreviousYearSections();
		}
		else if(creation_option === 'manual')
		{
			setDepartmentHasCurrentSections(true);
		}
	}

	const saveSection = async (e, update_type, section_id) => {
		const section_index = sections.findIndex(section => section.section_id === section_id);
		const section_info = sections[section_index];
		const is_new = (section_info && 'new' in section_info); 
		const span_id = section_info.span_id;
		
		const temp_changes = section_info['temp_changes'];
		const temp_teacher_id = (temp_changes) ? temp_changes.teacher_id : section_info.teacher_id;
		const temp_course_id = (temp_changes) ? temp_changes.course_id : section_info.course_id;
		const temp_secondary_teachers = (temp_changes) ? temp_changes.secondary_teachers.filter(st_id => st_id !== '') : section_info.secondary_teachers;

		const other_affected_sections = (span_id) ? sections.filter(section => section.section_id !== section_id && section.span_id == span_id) : [];
		
		if(update_type === 'update-main-section' || update_type === 'update-subsection')
		{
			if(temp_teacher_id && temp_course_id)
			{
				// Only update if something has changed
				if((temp_teacher_id !== section_info.teacher_id || temp_course_id !== section_info.course_id || !arraysEqual(temp_secondary_teachers, section_info.secondary_teachers)))
				{
					sections[section_index].loading = true;
					sections[section_index].teacher_id = temp_teacher_id;
					sections[section_index].course_id = temp_course_id;
					sections[section_index].secondary_teachers = temp_secondary_teachers;

					if(span_id)
					{
						other_affected_sections.forEach(affected_section => {
							const affected_section_id = affected_section.section_id;
							const affected_section_index = sections.findIndex(section => section.section_id === affected_section_id); 
							
							if(affected_section_index !== -1)
							{
								sections[affected_section_index].teacher_id = temp_teacher_id;
								sections[affected_section_index].course_id = temp_course_id;
								sections[affected_section_index].secondary_teachers = temp_secondary_teachers;
							}
						});
					}
					
					setSections([...sections]);
				}
			}
			else
			{
				const parent_container = (update_type === 'update-main-section') ?  e.target.closest('.section-review-section-container') : e.target.closest('.subsection-review-section-container');
				//parent_container.querySelector('.section-review-edit-section-name').classList.add('error');
				parent_container.querySelectorAll('.section-review-edit-section-subname').forEach((el) => {
					el.classList.add('error');
				});
				
				return false;
			}
		}
		else if(update_type === 'add-new-subsection')
		{
			sections[section_index].adding_subsection = true;
			setSections([...sections]);
		}
		
		const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, department_id:departmentID, is_new:is_new, update_type:update_type, section_id:section_id, course_id:temp_course_id, teacher_id:temp_teacher_id, secondary_teachers:temp_secondary_teachers};
		
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/admin/update-section-review';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			const result = await API.post(apiName, url, myInit);
			const result_data = result.data;
			const new_section_id = result_data.section_id;
			const new_section_number = result_data.section_number;
			const new_span_id = result_data.span_id;

			if(update_type === 'update-main-section') 
			{
				sections[section_index].section_id = new_section_id;
				sections[section_index].span_id = new_span_id;
				sections[section_index].section_number = new_section_number;
			}
			else if(update_type === 'add-new-subsection')
			{
				// Create the new subsection
				const subsection_section_info = {
					"section_id": new_section_id,
					"section_type": "1",
					"section_number": new_section_number,
					"course_id": null,
					"course_name": null,
					"teacher_id": null,
					"teacher_name": null,
					"teacher_first_name": null,
					"classroom_id": null,
					"course_periods": sections[section_index].course_periods,
					"is_lab": "0",
					"is_inclusion": "0",
					"is_manual": sections[section_index].is_manual,
					"locked": sections[section_index].locked,
					"span_id": new_span_id,
					"departments": sections[section_index].departments,
					"quarter_days": sections[section_index].departments,
					"student_list": [],
					"subsections": [],
					"labels": [], 
					"secondary_teachers" : []
				}
				
				// Add to sections
				sections.push(subsection_section_info);

				// Add to subsections
				subsections.push({course_id:null, departments:[], section_id:section_id, subsection_id:new_section_id, teacher_id:null});

				// Add to main section's subsections
				const section_subsections = section_info.subsections;
				const section_subsection_index = section_subsections.findIndex(subsection_id => subsection_id === new_section_id);
				if(section_subsection_index === -1) sections[section_index]['subsections'].push(new_section_id);

				other_affected_sections.forEach(affected_section => {
					const affected_section_id = affected_section.section_id;
					const affected_section_index = sections.findIndex(section => section.section_id === affected_section_id); 
					
					if(affected_section_index !== -1)
					{
						const new_affected_subsection_id = createUniqueObjectID(7);

						// Create the new subsection
						const affected_section_subsection_section_info = {
							"section_id": new_affected_subsection_id,
							"section_type": "1",
							"section_number": new_section_number,
							"course_id": null,
							"course_name": null,
							"teacher_id": null,
							"teacher_name": null,
							"teacher_first_name": null,
							"classroom_id": null,
							"course_periods": sections[affected_section_index].course_periods,
							"is_lab": "0",
							"is_inclusion": "0",
							"is_manual": sections[affected_section_index].is_manual,
							"locked": sections[affected_section_index].locked,
							"span_id": new_span_id,
							"departments": sections[affected_section_index].departments,
							"quarter_days": sections[affected_section_index].departments,
							"student_list": [],
							"subsections": [],
							"labels": [], 
							"secondary_teachers" : []
						}
						
						// Add to sections
						sections.push(affected_section_subsection_section_info);

						// Add to subsections
						subsections.push({course_id:null, departments:[], section_id:affected_section_id, subsection_id:new_affected_subsection_id, teacher_id:null});

						// Add to main section's subsections
						const section_subsections = sections[affected_section_index].subsections;
						const section_subsection_index = section_subsections.findIndex(subsection_id => subsection_id === new_affected_subsection_id);
						if(section_subsection_index === -1) sections[affected_section_index]['subsections'].push(new_affected_subsection_id);
					}
				});
				
			}
			else if(update_type === 'update-subsection')
			{
				const temp_course_info = courses.find(course => course.course_id === temp_course_id);
				const temp_course_departments = (temp_course_info) ? temp_course_info.departments : [];

				const temp_teacher_info = teachers.find(teacher => teacher.teacher_id === temp_teacher_id);
				const temp_teacher_departments = (temp_teacher_info) ? temp_teacher_info.departments : [];

				const section_departments = [...temp_course_departments, ...temp_teacher_departments];
				sections[section_index].departments = section_departments;

				const subsection_subsection_index = subsections.findIndex(subsection => subsection.subsection_id === section_id);
				subsections[subsection_subsection_index].course_id = temp_course_id;
				subsections[subsection_subsection_index].teacher_id = temp_teacher_id;
				subsections[subsection_subsection_index].departments = section_departments;
				
				delete sections[section_index].temp_changes;
				delete subsections[subsection_subsection_index].temp_changes;
			}

			sections[section_index].loading = false;
			sections[section_index].adding_subsection = false;
			delete sections[section_index].temp_changes;
			delete sections[section_index].new;

			setSubsections([...subsections]);
			setSections([...sections]);

			resetProgress();

			// Update conflicts if sections being updated already exist (so might have conflicts on them already)
			if(['toggle-inclusion', 'update-main-section', 'update-subsection'].includes(update_type))
			{
				const conflicts_to_check = ['course_exceeds_capacity', 'course_period_restriction', 'course_outside_normal_room', 'teacher_missing', 'teacher_period_restriction', 'teacher_student_restriction', 'student_student_restriction', 'teacher_double_booked', 'classroom_double_booked', 'student_double_booked', 'teacher_conflicting_periods', 'classroom_conflicting_periods', 'student_conflicting_periods', 'teacher_too_many_sections', 'teacher_too_many_sections_in_a_row', 'classroom_missing', 'inclusion_too_many_students', 'inclusion_over_half'];
				calculateConflicts({school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, conflicts_to_check:conflicts_to_check, update_all:true});
			}
		} catch(error)
		{
			console.log(error.response);
		}
	}
	
	const duplicateSection = async (section_id) => {
		// Get section info from duplicated section
		const section_index = sections.findIndex(section => section.section_id === section_id);
		const new_section_info = cloneObj(sections[section_index]);
		new_section_info.is_duplicate_section = true;
		new_section_info.section_number = null; // Remove section number until it is returned by the backend
		
		// Add this section info into the duplicate sections in progress
		duplicateSectionsInProgress.push(new_section_info);
		setDuplicateSectionsInProgress([...duplicateSectionsInProgress]);

		const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, ...new_section_info};
	
		// Update database
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/admin/duplicate-section';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			const response = await API.post(apiName, url, myInit);
			const sections_to_return = response.data.sections_to_return;
			const subsections_to_return = response.data.subsections_to_return;

			//console.log({sections_to_return});

			// Remove duplicate section from in progress
			const duplicate_section_in_progress_index = duplicateSectionsInProgress.findIndex(dsip_section => dsip_section.section_id === section_id);
			if(duplicate_section_in_progress_index !== -1) duplicateSectionsInProgress.splice(duplicate_section_in_progress_index, 1);
			setDuplicateSectionsInProgress([...duplicateSectionsInProgress]);

			// Update JSON
			const new_sections = [...sections, ...sections_to_return];
			setSections(new_sections);

			const new_subsections = [...subsections, ...subsections_to_return];
			setSubsections(new_subsections);
		} catch(e)
		{
			console.log(e.response);
		}
	}
	
	const removeSection = async (section_id) => {
		const section_index = sections.findIndex(section => section.section_id === section_id);
		const section_info = sections[section_index];
		
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, department_id:departmentID, subscreen:'section_review', section_id:section_id};
		
		section_info.loading = true;
		setSections([...sections]);

		deleteSection(data).then(response_data => {
			const deleted_sections = response_data.deleted_sections;

			// Delete all deleted sections, and remove subsections
			let new_sections = cloneObj(sections);
			let new_subsections = cloneObj(subsections);

			deleted_sections.forEach(temp_section_id => {
				const delete_section_index = new_sections.findIndex(section => section.section_id === temp_section_id);

				if(delete_section_index !== -1)
				{
					const deleted_section_info = new_sections[delete_section_index];
					// On this screen, you can only delete main sections (all subsections are uneditable)
					// Therefore, just need to delete the section itself and all the subsections of this section
					const section_subsections = deleted_section_info.subsections;
				
					// Remove actual section from sections
					new_sections.splice(delete_section_index, 1);
					
					// Remove subsections of this section from sections and subsections
					new_sections = new_sections.filter(section => !section_subsections.includes(section.section_id))
					new_subsections = new_subsections.filter(subsection => !section_subsections.includes(subsection.subsection_id));
				}
			});

			setSubsections(new_subsections);
			setSections(new_sections);

			// Update conflicts
			const conflicts_to_check = ['teacher_double_booked', 'classroom_double_booked', 'student_double_booked', 'teacher_conflicting_periods', 'classroom_conflicting_periods', 'student_conflicting_periods', 'teacher_missing', 'teacher_period_restriction', 'teacher_too_many_sections', 'teacher_too_many_sections_in_a_row', 'student_student_restriction', 'teacher_student_restriction', 'course_period_restriction', 'course_exceeds_capacity', 'course_outside_normal_room', 'classroom_missing', 'inclusion_too_many_students', 'inclusion_over_half'];
			calculateConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, affected_sections:deleted_sections, conflicts_to_check:conflicts_to_check, update_all:false});

			// Recalculate match percent
			recalculateMatchPercent({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id});

			// Reset progress
			resetProgress();
		});
	}
	
	const toggleInclusion = (main_section_id, subsection_id) => {
		const main_section_index = sections.findIndex(section => section.section_id === main_section_id);
		const main_section_info = (main_section_index !== -1) ? sections[main_section_index] : null;
		const main_section_course_id = (main_section_info) ? main_section_info.course_id : null;

		const subsection_index = sections.findIndex(section => section.section_id === subsection_id);
		const subsection_info = (subsection_index !== -1) ? sections[subsection_index] : null;
		const subsection_span_id = (subsection_info) ? subsection_info.span_id : null;
		const subsection_is_inclusion = (subsection_info) ? subsection_info.is_inclusion : null;

		const affected_subsections = (subsection_span_id) ? sections.filter(section => section.span_id === subsection_span_id) : [subsection_info];

		const new_is_inclusion = (subsection_is_inclusion === '0') ? '1' : '0';
		affected_subsections.forEach(affected_subsection_info => {
			const affected_subsection_id = affected_subsection_info.section_id;
			const affected_subsection_index = sections.findIndex(section => section.section_id === affected_subsection_id);
			
			if(affected_subsection_index !== -1) sections[affected_subsection_index].is_inclusion = new_is_inclusion;
			if(scheduleInfo.separate_ICR_codes === '0') 
			{
				if(new_is_inclusion === '0')
				{
					sections[affected_subsection_index].course_id = null;
				}
				else
				{
					sections[affected_subsection_index].course_id = main_section_course_id;
				}
			}
		});
		
		saveSection(null, 'toggle-inclusion', subsection_id);
	}
	
	const toggleSectionEditing = (section_id) => {
		const section_index = sections.findIndex(section => section.section_id === section_id);
		const section_info = sections[section_index];
		
		// This will only happen when editing is initiated or cancelled (if edits are saved then no need to toggle bc new sections are pulled)
		if('temp_changes' in section_info)
		{
			// If this was a new section, remove it from course sections 
			if('new' in section_info) sections.splice(section_index, 1);
			
			delete section_info['temp_changes'];
		}
		else
		{
			section_info['temp_changes'] = {teacher_id:section_info.teacher_id, course_id:section_info.course_id, course_results:{search:[], suggested:[]}, teacher_results:{search:[], suggested:[]}, secondary_teachers:section_info.secondary_teachers};
		}
		
		setSections([...sections]);
	}
	
	const addNewSection = (data_id) => {
		const new_section_id = createUniqueObjectID(7);
		
		if(currentView === 'teacher') sections.push({new:true, section_type:'1', section_id: new_section_id, departments:[departmentID], teacher_id:data_id, course_id:null, classroom_id:null, course_periods:[], quarter_days:[], student_list:[], secondary_teachers:[], subsections:[], labels:[], is_inclusion:'0', is_lab:'0', locked:'0', temp_changes:{teacher_id:data_id, course_id:null, secondary_teachers:[], course_results:{search:[], suggested:[]}, teacher_results:{search:[], suggested:[]}}});
		
		if(currentView === 'course') sections.push({new:true, section_type:'1', section_id: new_section_id, departments:[departmentID], teacher_id:null, course_id:data_id, classroom_id:null, course_periods:[], quarter_days:[], student_list:[], secondary_teachers:[], subsections:[], labels:[], is_inclusion:'0', is_lab:'0', locked:'0', temp_changes:{teacher_id:null, course_id:data_id, secondary_teachers:[], course_results:{search:[], suggested:[]}, teacher_results:{search:[], suggested:[]}}});
		
		setSections([...sections]);
	}
	
	const updateSectionTemp = (e, change_type, section_id, data_key, data_id) => {
		// Update the display input
		const target = e.target;
		const parent_div = target.closest('.section-review-edit-section-container');
		const child_div = parent_div.querySelector('.section-review-edit-section-subname[data-type=' + data_key + ']');

		let display_name = '';
		if(data_key === 'teacher_id')
		{
			const subsection_teacher_index = teachers.findIndex(teacher => teacher.teacher_id === data_id);
			const subsection_teacher_info = (subsection_teacher_index !== -1) ? teachers[subsection_teacher_index] : null;
			display_name = (subsection_teacher_info) ? `${subsection_teacher_info.name}, ${subsection_teacher_info.first_name}` : null;
		}
		else if(data_key === 'course_id')
		{
			const subsection_course_info = courses.find(course => course.course_id === data_id);
			const subsection_course_code = (subsection_course_info) ? `(${subsection_course_info.course_code})` : null;
			const subsection_course_name = (subsection_course_info) ? capitalizeFirstLetters(subsection_course_info.name) : null;
			display_name = `${subsection_course_name} ${subsection_course_code}`;
		}

		child_div.value = display_name;

		// Update the state
		const section_index = sections.findIndex(section => section.section_id === section_id);
		
		if(change_type === 'add')
		{
			sections[section_index]['temp_changes'][data_key].push(data_id);
		}
		else if(change_type === 'change')
		{
			sections[section_index]['temp_changes'][data_key] = data_id;
		}
		else if(change_type === 'remove')
		{
			sections[section_index]['temp_changes'][data_key].splice(data_id, 1);
		}
		
		// Reset all temp changes searches and open options
		sections[section_index]['temp_changes']['course_results'] = {search:[], suggested:[]};
		sections[section_index]['temp_changes']['teacher_results'] = {search:[], suggested:[]};
		
		setSections([...sections]);
	}

	const addSecondaryTeacher = (passedData) => {
		const e = passedData.e;
		const section_id = passedData.section_id;
		const secondary_teacher_id = passedData.secondary_teacher_id;

		// Update input display
		let display_name = '';
		if(secondary_teacher_id && secondary_teacher_id !== '')
		{
			const target = e.target;
			const parent_div = target.closest('.secion-review-secondary-teacher-input-container');
			const child_div = parent_div.querySelector('.section-review-edit-section-subname');

			const teacher_index = teachers.findIndex(teacher => teacher.teacher_id === secondary_teacher_id);
			const teacher_info = (teacher_index !== -1) ? teachers[teacher_index] : null;
			display_name = (teacher_info) ? `${teacher_info.name}, ${teacher_info.first_name}` : null;

			child_div.value = display_name;
		}

		// Update the state
		const section_index = sections.findIndex(section => section.section_id === section_id);

		if(!secondary_teacher_id || secondary_teacher_id === '')
		{
			sections[section_index].temp_changes.secondary_teachers.push(secondary_teacher_id);
		}
		else
		{
			const secondary_teacher_index = sections[section_index].temp_changes.secondary_teachers.findIndex(st_id => st_id === '');
			sections[section_index].temp_changes.secondary_teachers[secondary_teacher_index] = secondary_teacher_id;
		}
		
		setSections([...sections]);
	}

	const removeSecondaryTeacher = (passedData) => {
		const section_id = passedData.section_id;
		const secondary_teacher_id = passedData.secondary_teacher_id;

		// Update the state
		const section_index = sections.findIndex(section => section.section_id === section_id);
		const secondary_teacher_index = (section_index !== -1) ? sections[section_index].temp_changes.secondary_teachers.findIndex(st_id => st_id === secondary_teacher_id) : -1;
		
		if(secondary_teacher_index !== -1) sections[section_index].temp_changes.secondary_teachers.splice(secondary_teacher_index, 1);
		
		setSections([...sections]);
	}

	const toggleLockSection = async (section_id, locked) => {
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, section_id:section_id, locked:locked};
		
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/update-lock-section';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			const response = await API.post(apiName, url, myInit);
			const affected_sections = response.data.affected_sections;
			
			affected_sections.forEach(section_id => {
				const section_index = sections.findIndex(section => section.section_id === section_id);
				if(section_index !== -1) sections[section_index].locked = locked;
			});
			setSections([...sections]);
		} catch(e)
		{
			console.log(e.response);
		}
	}

	const openPrint = useReactToPrint({
		content: () => printRef.current,
		pageStyle: `
			@media print {
				.section-review-data-container {
					max-height:none !important;
				}
				.pagebreak {
					page-break-before: always;
				}
				.section-review-edit-btn, .section-review-duplicate-btn, .section-review-delete-btn, .section-review-add-new-section {
					overflow: hidden; 
					height: 0;
				}
				.section-review-section-content {
					padding:2px 10px !important;
				}
			}
		`,
	});
	
	//////////////////////
	///// DO ON LOAD /////
	//////////////////////
	useEffect(() => {
		if(departmentID && !sectionsLoading) resetDepartmentCheck();
	}, [sectionsLoading, departmentID]);

	useEffect(() => {
		const checkStatus = async () => {
			if(sectionsLoading) return;
			if(departmentHasPastSectionsChecked && requestsSubmittedChecked && sectionPredictionProcessChecked) return;
	
			// Check if this department has sections that exist this year
			const department_sections = sections.filter(section => ('departments' in section) && section.departments.includes(departmentID));
	
			if(department_sections.length > 0) 
			{
				setDepartmentHasCurrentSections(true);
				setDepartmentHasPastSectionsChecked(true);
				setRequestsSubmittedChecked(true);
				setSectionPredictionProcessChecked(true);
	
				// setDepartmentHasCurrentSections(false);
				// setDepartmentHasPastSectionsChecked(true);
				// setRequestsSubmitted(true);
				// setSectionPredictionProcessChecked(true);
				// setIsLoading(false);
			}
			else if(departmentID)
			{
				setDepartmentHasCurrentSections(false);
				setDepartmentHasPastSectionsChecked(true);
				setRequestsSubmitted(true);
				setSectionPredictionProcessChecked(true);
				setIsLoading(false);
				if(!departmentHasPastSectionsChecked)
				{
					setDepartmentHasCurrentSections(false);
					
					////////////////////////////////////////////////////////////
					// Check if department has sections from a previous year ///
					////////////////////////////////////////////////////////////
					const data = {school_id: schoolInfo.school_id, department_id:departmentID, schedule_version_id:schoolInfo.current_schedule_version_id};
			
					// Update database
					const apiName = process.env.REACT_APP_ENDPOINT_NAME;
					const url = '/check-department-previous-sections';
					const myInit = { // OPTIONAL
						response: true,
						queryStringParameters: data,
					};
				
					try {
						const response = await API.get(apiName, url, myInit);
						const department_has_previous_sections = response.data;
						
						setDepartmentHasPastSections(department_has_previous_sections);
						setDepartmentHasPastSectionsChecked(true);
					} catch(e)
					{
						console.log(e.response);
					}
				}
				else
				{
					// Get all running processes for this school
					// Then check status of student request and section prediction processes
					getSchoolRunningProcesses({school_id:schoolInfo.school_id, schedule_year_id:schoolInfo.current_schedule_year_id}).then(running_processes => {
						//// Check if student requests have been submitted ////
						let requests_submitted_is_done = false;
	
						const request_process_index = running_processes.findIndex(process => process.process_type === 'studentreqdata' && process.data_version_id == schoolInfo.current_data_version_id);
	
						if(request_process_index !== -1) requests_submitted_is_done = running_processes[request_process_index].is_done;
	
						setRequestsSubmitted(requests_submitted_is_done);
						setRequestsSubmittedChecked(true);
	
						//// Check if sections have been generated ////
						let section_prediction_has_started = false;
						let section_prediction_is_done = false;
	
						const section_prediction_process_index = running_processes.findIndex(process => process.process_type === 'sectiongen' && process.schedule_version_id == schoolInfo.current_schedule_version_id && process.department_id === departmentID);
	
						if(section_prediction_process_index !== -1)
						{
							const section_prediction_process = running_processes[section_prediction_process_index];
							section_prediction_has_started = section_prediction_process.has_started;
							section_prediction_is_done = section_prediction_process.is_done;
						}
	
						setSectionPredictionHasStarted(section_prediction_has_started);
						setSectionPredictionFinished(section_prediction_is_done);
						setSectionPredictionProcessChecked(true);
					});
				}
			}
			else if(!sectionsLoading)
			{
				setIsLoading(false);
			}
		}

		if(isLoading && !sectionsLoading) checkStatus();
	}, [isLoading, departmentID, sectionsLoading, sectionsLoading, sections, departmentHasPastSectionsChecked, requestsSubmittedChecked, schoolInfo, sectionPredictionProcessChecked]);

	useEffect(() => {
		if(departmentHasCurrentSections || (departmentHasPastSectionsChecked && requestsSubmittedChecked && sectionPredictionProcessChecked)) setIsLoading(false);
	}, [departmentHasCurrentSections, departmentHasPastSectionsChecked, requestsSubmittedChecked, sectionPredictionProcessChecked]);

	useEffect(() => {
		if(departmentID) changeDepartment(departmentID);
	}, [departmentID, departments, currentView, teachers, courses, sections, isLoading, changeDepartment]);

	useEffect(() => {
		setFilteredIDs([]);
	}, [departmentID]);

	useEffect(() => {
		if(departmentID) updateHTML();
	}, [departmentID, currentView, teachers, courses, sections, subsections, duplicateSectionsInProgress, isLoading, updateHTML]);

	useEffect(() => {
		if(!departmentID || !userInfo || !users || !departments) return;

		const department_index = departments.findIndex(department => department.department_id === departmentID);
		const department_info = (department_index !== -1) ? departments[department_index] : null;
		const department_supervisors = (department_info) ? department_info.supervisors : [];
		const subscreen_info = (department_info) ? department_info.subscreens[subscreen] : null;

		const current_screen_submitted = (subscreen_info) ? subscreen_info.submitted : false;
		const current_screen_confirmed = (subscreen_info) ? subscreen_info.confirmed : false;
		const current_screen_unlocked= (subscreen_info) ? subscreen_info.unlocked : false;
		const current_user_can_add_supervisors = (userInfo.role_id === '1' || userInfo.role_id === '2');
		
		const show_current_screen = (department_info) ? (current_screen_unlocked && (reviewing || userIsSupervisor || userIsAdmin)) : false;
		const screen_complete_for_user = (department_info) ? ((current_screen_confirmed) || (!userIsAdmin && current_screen_submitted)) : false;

		const available_users = users.filter(user => (user.role_id === '1' || user.role_id === '2') || department_supervisors.findIndex(supervisor => supervisor.user_id === user.user_id) !== -1);

		setDepartmentInfo(department_info);
		setCurrentScreenSubmitted(current_screen_submitted);
		setCurrentScreenConfirmed(current_screen_confirmed);
		setCurrentScreenUnlocked(current_screen_unlocked);
		setCurrentUserCanAddSupervisors(current_user_can_add_supervisors);
		setShowCurrentScreen(show_current_screen);
		setScreenCompleteForUser(screen_complete_for_user);

		setScreenAllowsUniversalSidebar(true);
		setUniversalSidebarLoadingInfo({sidebar_name:"Section Review Notes", sidebar_type:'notes', subscreen:'section_review', department_id:departmentID, user_id:userInfo.user_id, available_users:available_users, help_link_path:'/app/hsms/documentation/section_creation/section_review'});
	}, [departmentID, userInfo, users, departments, reviewing, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo, subscreen, userIsAdmin, userIsSupervisor]);
	
	const viewOptions = [
		{value:'teacher', display: 'Teacher View'},
		{value:'course', display: 'Course View'},
	];
	
	//console.log({department_info});
	//console.log({departments});
	//console.log(dataArray);
	//console.log({sections, subsections});
	//console.log(scheduleInfo);

	//console.log({isLoading, sectionPredictionHasStarted, sectionPredictionFinished, requestsSubmitted, departmentHasCurrentSections, departmentHasPastSections});
	//console.log({departmentHasPastSectionsChecked, requestsSubmittedChecked, sectionPredictionProcessChecked});
	//console.log({creationOption});
	//console.log({filteredIDs});
	
	return (
		<>
		<h1 className='progress-main-header'>Section Review</h1>
		<p className='progress-subheader'>Here you will get to manage the sections you are running next year.</p>
		{isLoading ? 
		(
			<div className='center-text'>
				<img src={loading_gif} alt='Loading...' style={{height:'40px'}}/>
			</div>
		):(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(!departmentID) ? 
		(
			<DepartmentProgressScreen subscreenResponsibleRoleID={subscreenResponsibleRoleID} subscreen={subscreen} sortedDepartments={sortedDepartments} changeDepartment={changeDepartment} subscreenSelect={subscreenSelect} userInfo={userInfo} userIsAdmin={userIsAdmin} userIsSupervisor={userIsSupervisor} />
		):(!userIsSupervisor) ?
		(
			<div className='section-creation-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to change this department</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):(!currentScreenUnlocked) ?
		(
			<div className='section-creation-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Locked</h1>
				<div>This department has previous steps which have not been completed. Please review previous steps and make sure they are completed and saved. This page should open automatically once all previous steps have been finished.</div>
				<div className='blue-link' style={{marginTop:'30px'}} onClick={()=>changeDepartment(null)}>Go back to Departments</div>
			</div>
		):(sectionPredictionHasStarted && !sectionPredictionFinished) ? 
		(
			<div className='section-review-missing-requests-container'>
				<div id='custom-section-creation-loader-container'>
					<div className='custom-section-creation-loader-column'>
						<div className='custom-section-creation-loader-row'>
							<div className='custom-section-creation-loader-row-top pastel-orange'></div>
							<div className='custom-section-creation-loader-row-bottom'></div>
						</div>
						<div className='custom-section-creation-loader-row'>
							<div className='custom-section-creation-loader-row-top pastel-orange'></div>
							<div className='custom-section-creation-loader-row-bottom'></div>
						</div>
					</div>
					<div className='custom-section-creation-loader-column'>
						<div className='custom-section-creation-loader-row'>
							<div className='custom-section-creation-loader-row-top yellow'></div>
							<div className='custom-section-creation-loader-row-bottom'></div>
						</div>
						<div className='custom-section-creation-loader-row'>
							<div className='custom-section-creation-loader-row-top yellow'></div>
							<div className='custom-section-creation-loader-row-bottom'></div>
						</div>
						<div className='custom-section-creation-loader-row'>
							<div className='custom-section-creation-loader-row-top yellow'></div>
							<div className='custom-section-creation-loader-row-bottom'></div>
						</div>
					</div>
					<div className='custom-section-creation-loader-column'>
						<div className='custom-section-creation-loader-row'>
							<div className='custom-section-creation-loader-row-top pastel-green'></div>
							<div className='custom-section-creation-loader-row-bottom'></div>
						</div>
					</div>
					<div className='custom-section-creation-loader-column'>
						<div className='custom-section-creation-loader-row'>
							<div className='custom-section-creation-loader-row-top pastel-light-blue'></div>
							<div className='custom-section-creation-loader-row-bottom'></div>
						</div>
						<div className='custom-section-creation-loader-row'>
							<div className='custom-section-creation-loader-row-top pastel-light-blue'></div>
							<div className='custom-section-creation-loader-row-bottom'></div>
						</div>
					</div>
				</div>
				<h2>Sections are currently being created...</h2>
				<div>We are currently calculating and assigning sections. Please check back shortly to review your sections.</div>
			</div>
		):(sectionPredictionFinished || departmentHasCurrentSections || screenCompleteForUser) ?
		(
			<>
			{studentRequestReportOpen &&
				<>
					<Portal windowName="Manage Student Requests" windowWidth="900" windowHeight="700" cleanUpFunction={() => setStudentRequestReportOpen(false)}>
						<StudentRequests schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} sections={sections} setSections={setSections} students={students} setStudents={setStudents} courses={courses} courseID={courseID} />
					</Portal>
				</>
			}
			<div id='section-review-dashboard'>
				<div id='section-review-content'>
					<div>
						<FontAwesomeIcon icon={faChevronLeft} className='progress-back-to-departments-btn' onClick={()=>changeDepartment(null)}/>
					</div>
					<div className='section-review-content-inner-content'>
						<div id='section-review-submit-btn-container'>
							{(!screenCompleteForUser && showCurrentScreen) &&
								<Button key={departmentID} classes={['mark-as-complete-btn']} selected={false} text={`${!currentScreenSubmitted ? 'Mark As Complete' : 'Confirm Department'}`} icon={faCheck} iconPosition='left' onClickFunction={() => submitDepartment(departmentID)} />
							}
							{changesSavedMessage && 
								<div className='all-changes-saved-message' style={{margin:'20px 0px 0px 0px'}}>
									<FontAwesomeIcon className='green-text' icon={faCheckCircle}/>
									<div>
										<h5>Changes Saved</h5>
										<div className='small-text'>All your changes have been saved</div>
									</div>
									<FontAwesomeIcon className='x-remove' icon={faTimes} onClick={() => setChangesSavedMessage(false)}/>
								</div>
							}
						</div>
						<div className='section-review-content-header-container'>
							<h1 className='section-review-content-header'>{departmentInfo && departmentInfo.department} Department</h1>
							{currentScreenConfirmed ?
								(
									<div className='section-review-status green-text'><div>Complete</div><FontAwesomeIcon icon={faCheckCircle}/></div>
								): (currentScreenSubmitted) ?
								(
									<div className='section-review-status bright-yellow-text'><div>Pending Confirmation</div><FontAwesomeIcon icon={faCheckCircle}/></div>
								): (null)
							}
						</div>
						<div className='department-supervisor-parent-container'>
							<>
							{(departmentInfo && departmentInfo.supervisors.length === 0) &&
								<>
								<div className='department-supervisor-inner-container'>
									<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
									<div className='department-supervisor-name'>No Supervisor Assigned</div>
								</div>
								</>
							}
							{departmentInfo && departmentInfo.supervisors.map((supervisor, index) => {
								return (
									<div className='department-supervisor-outer-container' key={index}>
										<div className='department-supervisor-inner-container'>
											{supervisor.img_url &&
												<img className='department-supervisor-img' src={require(`../../../images/users/${supervisor.img_url}`)} alt='user' />
											}
											{!supervisor.img_url &&
												<FontAwesomeIcon className='department-supervisor-user-icon gray-text' icon={faUserCircle}/>
											}
											<div className='department-supervisor-name'>{supervisor.last_name}, {supervisor.first_name}</div>
											{currentUserCanAddSupervisors &&
												<FontAwesomeIcon className='gray-text-hover' icon={faTimes} onClick={() => removeSupervisorFromDepartment(supervisor.user_id)}/>
											}
										</div>
									</div>
								)
							})}
							{currentUserCanAddSupervisors &&
								<>
								<div className={`section-creation-add-supervisor-link small-text click-restricted ${(departmentInfo && departmentInfo.supervisors.length === 0) ? 'blue-link' : 'gray-to-blue-link'}`} onClick={() => openSupervisorsList(departmentID)}>Add a Supervisor</div>
								<div className='supervisor-options-container'>
									{departmentInfo && departmentInfo.supervisors_open &&
										<div className='data-options-container' ref={ref}>
											{supervisors.map((supervisor_info, index) => {
												
												return (
													<div className='data-options-option assign-resourses-supervisor-option' key={index} onClick={() => addSupervisorToDepartment(supervisor_info)}>
														{supervisor_info.img_url &&
															<img className='department-supervisor-img' src={require(`../../../images/users/${supervisor_info.img_url}`)} alt='supervisor' />
														}
														{!supervisor_info.img_url &&
															<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
														}
														<div className='department-supervisor-name'>{supervisor_info.last_name}, {supervisor_info.first_name}</div>
													</div>
												)	
											})}
											<Link className='data-options-option blue-text' to={{pathname:`/app/settings/users`, redirect:`/app/progress/section_creation`}} style={{borderTop:'1px solid #efefef'}} onClick={() => closeSupervisorsList(departmentID)}>Add new Supervisor</Link>
										</div>
									}
								</div>
								</>
							}
							</>
						</div>
						{(userIsAdmin && currentScreenUnlocked && !showCurrentScreen && !currentScreenSubmitted && departmentInfo && !departmentInfo.current_user_is_supervisor) &&
							<div className='section-creation-screen-message'>
								<FontAwesomeIcon className='turquoise-text' style={{fontSize:'60px'}} icon={faHourglassHalf}/>
								<h1>Pending...</h1>
								<div>Waiting on confirmation from department supervisor</div>
								<p className='blue-link' onClick={toggleReviewDepartment}>Review department yourself</p>	
							</div>
						}
						{showCurrentScreen &&
							<>
							<div className='section-review-btn-container'>
								<div id='section-review-change-view'>
									<Dropdown data={viewOptions} currentValue={currentView} displayDefault={`${capitalizeFirstLetters(currentView)} View`} handleChange={changeView}/>
									<div id='section-review-change-layout-container'>
										<div className='section-review-change-layout-btn' onClick={() => setCurrentLayout('grid')}>
											<FontAwesomeIcon className='dark-blue-text' icon={faThLarge}/>
											<div>Grid</div>
										</div>
										<div className='section-review-change-layout-btn' onClick={() => setCurrentLayout('row')}>
											<FontAwesomeIcon className='dark-blue-text' icon={faGripLines}/>
											<div>Row</div>
										</div>
										<div className='section-review-change-layout-btn section-review-more-settings'  ref={extra_settings_ref} onClick={toggleOpenExtraSettings}>
											<FontAwesomeIcon className='dark-blue-text' icon={faEllipsisVertical}/>
											{(extraSettingsOpen) &&
												<div className='dropdown-options-container' style={{width:'200px', top:'55px', zIndex:'999'}}>
													<div className='dropdown-option' onClick={() => createSections('predict')}>Rerun Auto Sectioning</div>
												</div>
											}
										</div>
									</div>
								</div>
								<div className='btn gray-btn btn-round' onClick={() => openPrint()} style={{padding:'5px 20px'}}><FontAwesomeIcon icon={faPrint} style={{marginRight:'10px'}}/>Print</div>
							</div>
							<div className='section-review-search-bar-container'>
								<input className='section-review-search-bar' placeholder="Search for teachers or courses..." onChange={(e) => handleSearch(e)} />
								<FontAwesomeIcon className='section-review-search-bar-icon' icon={faSearch}/>
							</div>
							{isLoading ? (
									<div className='section-creation-message-container'>
										<img src={loading_gif} alt='loading...' style={{height:'40px'}}/>
									</div>
								) :
								(
									<div className='section-review-data-container' ref={printRef}>
										{dataArray.length === 0 && 
											<div className='section-review-no-data-container'>
												<FontAwesomeIcon className='section-review-no-data-icon dark-blue-text' icon={faExclamationCircle}/>
												<div>No data found</div>
												<p>Please confirm that this is correct by submitting the screen</p>
											</div>
										}
										{dataArray.map((data_info, index) => {
											const main_id = (currentView === 'teacher') ? data_info.teacher_id : data_info.course_id;

											if(filteredIDs.length > 0 && !filteredIDs.includes(main_id)) return null

											const subname = (currentView === 'teacher') ? `${data_info.name}, ${data_info.first_name}` : `${capitalizeFirstLetters(data_info.name)} (${data_info.course_code})`;
											const data_sections_by_subcategory = ('sections_by_subcategory' in data_info) ? data_info.sections_by_subcategory : [];
											const num_requests_for_course = ('num_requests_for_course' in data_info) ? data_info.num_requests_for_course : 0;
											const average_class_size = ('average_class_size' in data_info) ? data_info.average_class_size : 0;
											const num_course_sections = data_sections_by_subcategory.reduce((results, subcategory) => {
												const subcategory_sections = subcategory.subcategory_sections;
												const subcategory_num_sections = (subcategory_sections) ? subcategory_sections.length : 0;
												results = results + subcategory_num_sections;
												return results;
											}, 0);
											let colorIndex = 0;
												
											return (
												<div className='section-review-row-container pagebreak' key={index}>
													<div className='section-review-main-name-container'>
														<div className='section-review-main-name-display-name-container'>
															<h2 className='section-review-main-name'>{subname}</h2>
															<div className='btn blue-btn btn-small medium-font' style={{margin:'10px 0px 0px 0px', width:'fit-content'}} onClick={() => addNewSection(main_id)}>Add Section</div>
														</div>
														<div className='section-review-stats-container'>
															{currentView === 'course' &&
																<>
																<div className='section-review-request-count'>
																		<div>
																			<div className='blue-link' onClick={() => openStudentRequestReport(main_id)}>{num_requests_for_course.total} requests</div>
																			{num_requests_for_course.alternates > 0 &&
																				<div className='medium-text purple-text'>({num_requests_for_course.alternates} alternates)</div>
																			}
																			{num_requests_for_course.inclusion > 0 &&
																				<div className='medium-text orange-text'>({num_requests_for_course.inclusion} alternates)</div>
																			}
																		</div>
																		<div className='text-align-center'>/</div>
																		<div>{num_course_sections} section(s)</div>
																</div>
																<div className='section-review-estimated-class-size'>
																	<div className='small-text'>Current Est. Class Sizes</div>
																	<div className='extra-large-text'>{average_class_size}</div>
																</div>
																</>
															}
														</div>
													</div>
													{num_course_sections === 0 &&
														<div className='section-creation-no-data-container'>This {currentView} has no sections yet!</div>
													}
													{(currentLayout === 'grid') ?
													(
														<>
															{data_sections_by_subcategory.map((subcategory_info, i) => {
																const subcategory_id = subcategory_info.subcategory_id;
																const sections_in_subcategory = subcategory_info.subcategory_sections;

																const is_editing_subcategory = (subcategory_id === null || subcategory_id === 'null');
																
																let subcategory_name = null;

																if(currentView === 'teacher')
																{
																	const course_info = courses.find(course => course.course_id === subcategory_id);
																	const course_name = (course_info) ? capitalizeFirstLetters(course_info.name) : null;
																	const course_code = (course_info) ? course_info.course_code : null;
																	subcategory_name = `${course_name} (${course_code})`;
																}
																else if(currentView === 'course')
																{
																	const teacher_id = (is_editing_subcategory) ? null : subcategory_id;
																	const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
																	subcategory_name= (teacher_info) ? `${teacher_info.name}, ${teacher_info.first_name}` : null;
																}

																const color_index = colorIndex % colorClasses.length;
																let color = colorClasses[color_index];
																colorIndex++;

																let logged_sections = [];

																return(
																<div className='section-review-subname-column' key={i}>
																	{is_editing_subcategory ? 
																		(
																			<h4 className='section-review-subname'>To Be Added</h4>
																		):
																		(
																			<div className='tooltip section-review-subname' data-tooltip-text={`${subcategory_name} ${currentView === 'teacher' ? `- ${num_requests_for_course.total} requests` : ''}`}>
																				<h4 className='ellipsis'>{subcategory_name}</h4>
																				<div className='section-review-subname-stats'>
																					<div>{sections_in_subcategory.length} sections</div>
																				</div>
																			</div>
																		)
																	}
																	<div className='section-review-grid-sections-container'>
																		{sections_in_subcategory.map((section_info, j) => {
																			const section_id = section_info.section_id;

																			const is_duplicate_section = ('is_duplicate_section' in section_info) ? section_info.is_duplicate_section : false;
																			const is_subsection_index = subsections.findIndex(subsection => subsection.subsection_id === section_id);
																			const is_subsection = is_subsection_index !== -1;

																			const main_section_info = (is_subsection) ? sections.find(section => section.subsections.includes(section_id)) : section_info;
																			const main_section_id = (is_subsection) ? main_section_info.section_id : section_id;
																			
																			// If seen this section already, skip it
																			// Otherwise log this section (and all affected sections) and display it
																			if(logged_sections.includes(main_section_id) && !is_duplicate_section) return null;
																			if(!is_duplicate_section) logged_sections.push(main_section_id);

																			const section_subsections = main_section_info.subsections;
																			const is_editing = (('temp_changes' in main_section_info)) ? true : false;
																			const section_number = main_section_info.section_number;
																			const course_id = (is_editing) ? main_section_info.temp_changes.course_id : main_section_info.course_id;
																			const course_info = courses.find(course => course.course_id === course_id);
																			const course_name = (course_id) ? capitalizeFirstLetters(course_info.name) : null;
																			const course_code = (course_id) ? course_info.course_code : null;
																			const is_locked = main_section_info.locked === '1';

																			// Figure out if this section is inclusion
																			// If school doesn't have separate course codes, check if any subsections are placeholder inclusion subsections
																			let is_inclusion = '0';
																			if(!is_subsection)
																			{
																				section_subsections.forEach(subsection_id => {
																					const subsection_info = sections.find(section => section.section_id === subsection_id);
																					if(subsection_info && subsection_info.is_inclusion === '1') is_inclusion = '1';
																				});
																			}
																			
																			const teacher_id = (is_editing) ? main_section_info.temp_changes.teacher_id : main_section_info.teacher_id;
																			const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
																			const teacher_name = (teacher_info) ? `${teacher_info.name}, ${teacher_info.first_name}` : null;
																			const secondary_teachers = (is_editing) ? main_section_info.temp_changes.secondary_teachers : main_section_info.secondary_teachers;
																			
																			const course_search_results = (is_editing) ? main_section_info.temp_changes.course_results.search : [];
																			const teacher_search_results = (is_editing) ? main_section_info.temp_changes.teacher_results.search : [];
																			
																			const course_suggestions = (is_editing) ? main_section_info.temp_changes.course_results.suggested : [];
																			const teacher_suggestions = (is_editing) ? main_section_info.temp_changes.teacher_results.suggested : [];
																			
																			let section_all_labels = main_section_info.labels;
																			section_all_labels = section_all_labels.map(label_id => {
																				const label_info = labels.find(label => label.label_id === label_id);
																				return label_info;
																			});
																			
																			const current_section_teams = section_all_labels.filter(label => label.is_team === '1');
																			const sorted_current_section_teams = sortArrayOfObjects(current_section_teams, 'label', 'text', 'asc');
																			
																			const current_section_labels = section_all_labels.filter(label => label.is_team === '0');
																			const sorted_current_section_labels = sortArrayOfObjects(current_section_labels, 'label', 'text', 'asc');

																			if(main_section_info.loading || is_duplicate_section)
																			{
																				return (
																					<div className='section-review-section-container' key={`${main_section_id}-${j}`}>
																						<div className={`section-review-section-header dark-blue`}>
																							<div>Section ID: {section_number}</div>
																							<div></div>
																							<div></div>
																						</div>
																						<div className='section-review-section-content' style={{textAlign:'center'}}>
																							<img src={loading_gif} alt='loading...' style={{height:'40px'}}/>
																						</div>
																					</div>
																				)
																			}
																			else
																			{
																				return (
																					<div className='section-review-section-container' key={`${main_section_id}-${j}`} data-sectionid={main_section_id}>
																						<div className={`section-review-section-header dark-blue`}>
																							{is_locked ?
																								(
																									<FontAwesomeIcon className='cursor-pointer bright-yellow-text' icon={faLock} onClick={() => toggleLockSection(main_section_id, '0')}/>
																								):
																								(
																									<FontAwesomeIcon className='opaque-link' icon={faUnlock} onClick={() => toggleLockSection(main_section_id, '1')}/>
																								)
																							}
																							<div>Section ID: {section_number}</div>
																							{(!is_editing) &&
																								<>
																								{is_locked ?
																									(
																										<>
																										<div></div>
																										<div></div>
																										<div className='section-review-duplicate-btn tooltip' data-tooltip-text='Duplicate'>
																											<FontAwesomeIcon className='opaque-link' icon={faClone} onClick={() => duplicateSection(main_section_id)}/>
																										</div>
																										</>
																									):
																									(
																										<>
																										<div className='section-review-edit-btn tooltip' data-tooltip-text='Edit'>
																											<FontAwesomeIcon className='opaque-link' icon={faEdit} onClick={() => toggleSectionEditing(main_section_id)}/>
																										</div>
																										<div className='section-review-duplicate-btn tooltip' data-tooltip-text='Duplicate'>
																											<FontAwesomeIcon className='opaque-link' icon={faClone} onClick={() => duplicateSection(main_section_id)}/>
																										</div>
																										<div className='section-review-delete-btn tooltip' data-tooltip-text='Delete'>
																											<FontAwesomeIcon className='opaque-link' icon={faTrashAlt} onClick ={() => removeSection(main_section_id)}/>
																										</div>
																										</>
																									)
																								}
																								</>
																							}
																						</div>
																						<div className='section-review-section-content'>
																							{!is_editing ? 
																								(
																									<>
																									<div className='section-review-edit-label'>Course</div>
																									<div className='section-review-data tooltip' data-tooltip-text={`${course_name} (${course_code})`}>
																										<div className='ellipsis'>{course_name} ({course_code})</div>
																									</div>
																									<div className='section-review-edit-label'>Teacher(s)</div>
																									<div className='section-review-data tooltip' data-tooltip-text={`${teacher_name}`}>
																										<div className='ellipsis'>{teacher_name}</div>
																									</div>
																									{secondary_teachers.map((secondary_teacher_id, i) => {
																										const secondary_teacher_info = teachers.find(teacher => teacher.teacher_id === secondary_teacher_id);
																										const secondary_teacher_name = (secondary_teacher_info) ? `${secondary_teacher_info.name}, ${secondary_teacher_info.first_name}` : '';

																										return (
																											<div className='section-review-data tooltip' data-tooltip-text={`${secondary_teacher_name}`} key={`secondary-${i}`}>
																												<div className='ellipsis'>{secondary_teacher_name}</div>
																											</div>
																										)
																									})}
																									</>
																								) :
																								(
																									<>
																									<div className='section-review-edit-label'>Course</div>
																									<div className='section-review-edit-section-container'>
																										<input key={course_name} className='section-review-edit-section-subname' data-type='course_id' defaultValue={course_name} onChange={(e) => fuzzySearch(e, 'courses', main_section_id)}/>
																										<div className='section-review-search-add-search-results'>
																											{course_suggestions.length !== 0 &&
																												<>
																												<div className='search-add-did-you-mean'>Courses this Teacher Teaches:</div>
																												{course_suggestions.map((course, i) => {
																													return (
																														<div className='search-add-search-result' key={i}>
																															<div>{capitalizeFirstLetters(course.name)} ({course.course_code})</div>
																															<div>
																																<div className='search-add-add-btn' onClick={(e) => updateSectionTemp(e, 'change', main_section_id, 'course_id', course.course_id)}>Add</div>
																															</div>
																														</div>
																													)
																												})}
																												</>
																											}
																											{course_search_results.length !== 0 &&
																												<>
																												<div className='search-add-did-you-mean'>Other Courses:</div>
																												{course_search_results.map((course, i) => {
																													return (
																														<div className='search-add-search-result' key={i}>
																															<div>{capitalizeFirstLetters(course.name)} ({course.course_code})</div>
																															<div>
																																<div className='search-add-add-btn' onClick={(e) => updateSectionTemp(e, 'change', main_section_id, 'course_id', course.course_id)}>Add</div>
																															</div>
																														</div>
																													)
																												})}
																												</>
																											}
																										</div>
																									</div>
																									<div className='section-review-edit-label'>Teacher(s)</div>
																									<div className='section-review-edit-section-container'>
																										<input key={teacher_name} className='section-review-edit-section-subname' data-type='teacher_id' defaultValue={teacher_name} onChange={(e) => fuzzySearch(e, 'teachers', main_section_id)} />
																										<div className='section-review-search-add-search-results'>
																											{teacher_suggestions.length !== 0 &&
																												<>
																												<div className='search-add-did-you-mean'>Teachers Who Can Teach:</div>
																												{teacher_suggestions.map((teacher, i) => {
																													return (
																														<div className='search-add-search-result' key={i}>
																															<div>{teacher.name}, {teacher.first_name}</div>
																															<div>
																																<div className='search-add-add-btn' onClick={(e) => updateSectionTemp(e, 'change', main_section_id, 'teacher_id', teacher.teacher_id)}>Add</div>
																															</div>
																														</div>
																													)
																												})}
																												</>
																											}
																											{teacher_search_results.length !== 0 &&
																												<>
																												<div className='search-add-did-you-mean'>Other Teachers:</div>
																												{teacher_search_results.map((teacher, i) => {
																													return (
																														<div className='search-add-search-result' key={i}>
																															<div>{teacher.name}, {teacher.first_name}</div>
																															<div>
																																<div className='search-add-add-btn' onClick={(e) => updateSectionTemp(e, 'change', main_section_id, 'teacher_id', teacher.teacher_id)}>Add</div>
																															</div>
																														</div>
																													)
																												})}
																												</>
																											}
																										</div>
																									</div>
																									{secondary_teachers.map((secondary_teacher_id, i) => {
																										return (
																											<SectionReviewEditingSecondaryTeacher teachers={teachers} courses={courses} sectionID={main_section_id} courseID={course_id} secondaryTeacherID={secondary_teacher_id} onSelect={addSecondaryTeacher} onRemove={removeSecondaryTeacher} key={i}/>
																										)
																									})}
																									<div className='blue-link align-right small-text section-review-blue-link' onClick={(e) => addSecondaryTeacher({e:e, section_id:main_section_id, secondary_teacher_id:''})}>Add Another Teacher</div>
																									<div className='section-creation-btn-row'>
																										<div className='btn gray-btn' onClick={() => toggleSectionEditing(main_section_id)}>Cancel</div>
																										<div className='btn blue-btn' onClick={(e) => saveSection(e, 'update-main-section', main_section_id)}>Save</div>
																									</div>
																									</>
																								)
																							}
																							{(!is_editing && !is_locked) &&
																								<div className='blue-link align-right small-text section-review-blue-link' onClick={(e) => saveSection(e, 'add-new-subsection', main_section_id)}>Add Subsection</div>
																							}
																							{sorted_current_section_teams.map((label, index) => (
																								<div className='create-labels-section-label' key={label.label_id}>
																									<div className='create-labels-icon pastel-blue'></div>
																									<div>{label.label}</div> 
																								</div>
																							))}
																							{sorted_current_section_labels.map((label, index) => (
																								<div className='create-labels-section-label' key={label.label_id}>
																									<div className='create-labels-icon pastel-orange'></div>
																									<div>{label.label}</div>
																								</div>
																							))}
																							{section_subsections.map((subsection_id, i) => {
																								// SUBSECTION INFO
																								const subsection_info = subsections.find(section => section.subsection_id === subsection_id);
																								
																								if(!subsection_info) return null;
																								
																								const subsection_section_info = sections.find(section => section.section_id === subsection_id);

																								if(!subsection_section_info) return null;

																								const subsection_is_editing = ('temp_changes' in subsection_section_info) ? true : false;
																								
																								const subsection_course_id = (subsection_is_editing) ? subsection_section_info.temp_changes.course_id : subsection_section_info.course_id;
																								const subsection_course_info = courses.find(course => course.course_id === subsection_course_id);
																								const subsection_course_name = (subsection_course_info) ? capitalizeFirstLetters(subsection_course_info.name) : null;
																								const subsection_course_code = (subsection_course_info) ? subsection_course_info.course_code : null;
																								
																								const subsection_teacher_id = (subsection_is_editing) ? subsection_section_info.temp_changes.teacher_id : subsection_section_info.teacher_id;
																								const subsection_teacher_index = teachers.findIndex(teacher => teacher.teacher_id === subsection_teacher_id);
																								const subsection_teacher_info = (subsection_teacher_index !== -1) ? teachers[subsection_teacher_index] : null;
																								const subsection_teacher_name = (subsection_teacher_info) ? `${subsection_teacher_info.name}, ${subsection_teacher_info.first_name}` : null;
																								const subsection_secondary_teachers = (subsection_is_editing) ? subsection_section_info.temp_changes.secondary_teachers : subsection_section_info.secondary_teachers;

																								const subsection_teacher_search_results = (subsection_is_editing) ? subsection_section_info.temp_changes.teacher_results.search : [];
																								const subsection_course_search_results = (subsection_is_editing) ? subsection_section_info.temp_changes.course_results.search : [];
																								
																								const subsection_is_locked = subsection_section_info.locked === '1';
																								const subsection_is_inclusion = subsection_section_info.is_inclusion;
																								
																								//let subsection_all_labels = subsection_section_info.labels;
																				
																								// subsection_all_labels = subsection_all_labels.map(label_id => {
																								// 	const label_info = labels.find(label => label.label_id === label_id);
																								// 	return label_info;
																								// });
																								
																								//const current_subsection_teams = subsection_all_labels.filter(label => label.is_team === '1');
																								//const sorted_current_subsection_teams = sortArrayOfObjects(current_subsection_teams, 'label', 'text', 'asc');
																								
																								//const current_subsection_labels = subsection_all_labels.filter(label => label.is_team === '0');
																								//const sorted_current_subsection_labels = sortArrayOfObjects(current_subsection_labels, 'label', 'text', 'asc');
																								
																								
																								return (
																									<div key={`${subsection_id}-${i}`} className='subsection-review-section-container' data-id={subsection_id}>
																										<div className={`subsection-review-section-header ${subsection_is_inclusion === '1' ? 'yellow' : 'blue'}`}>
																											<div>{subsection_is_inclusion === '1' ? 'Inclusion Section' : 'Subsection'}</div>
																											{!subsection_is_locked &&
																												<>
																												<div className='section-review-edit-btn tooltip' data-tooltip-text='Edit'>
																													<FontAwesomeIcon className='opaque-link' icon={faEdit} onClick={() => toggleSectionEditing(subsection_id)}/>
																												</div>
																												<div className='section-review-delete-btn tooltip' data-tooltip-text='Delete'>
																													<FontAwesomeIcon className='opaque-link' icon={faTrashAlt}  onClick ={() => removeSection(subsection_id)}/>
																												</div>
																												</>
																											}
																										</div>
																										{subsection_section_info.loading ?
																										(
																											<div className='section-review-section-content' style={{textAlign:'center'}}>
																												<img src={loading_gif} alt='loading...' style={{height:'40px'}}/>
																											</div>
																										):
																										(
																											<div className='subsection-review-section-content'>
																												{(subsection_is_inclusion === '0' || scheduleInfo.separate_ICR_codes === '1') &&
																													<>
																													<div className='section-review-edit-label'>Course</div>
																													{(subsection_is_editing) ?
																													(
																														<div className='section-review-edit-section-container'>
																															<div style={{position:'relative'}}>
																																<input key={i} className='section-review-edit-section-subname' data-type='course_id' defaultValue={subsection_course_name} onChange={(e) => fuzzySearch(e, 'courses', subsection_id)}/>
																															</div>
																															{subsection_course_search_results.length !== 0 &&
																																<div className='section-review-search-add-search-results'>
																																	<div className='search-add-did-you-mean'>Did you mean:</div>
																																	{subsection_course_search_results.map((course, i) => {
																																		return (
																																			<div className='search-add-search-result' key={i}>
																																				<div>{course.name} ({course.course_code})</div>
																																				<div>
																																					<div className='search-add-add-btn' onClick={(e) => updateSectionTemp(e, 'change', subsection_id, 'course_id', course.course_id)}>Add</div>
																																				</div>
																																			</div>
																																		)
																																	})}
																																</div>
																															}
																														</div>
																													):
																													(
																														<div className={`section-review-inclusion-data tooltip ${!subsection_teacher_id && 'section-review-inclusion-no-data'}`} data-tooltip-text={subsection_id ? `${subsection_course_name} (${subsection_course_code})` : ''} onClick={() => toggleSectionEditing(subsection_id)}>
																															<div className='ellipsis'>{subsection_course_id ? `${subsection_course_name} (${subsection_course_code})` : <div className={`small-text ${subsection_is_inclusion === '1' ? 'orange-link' : 'blue-link'}`}>Add course</div>}</div>
																														</div>
																													)}
																													</>
																												}
																												<div className='section-review-edit-label'>Teacher</div>
																												{(subsection_is_editing) ?
																													(
																														<>
																														<div className='section-review-edit-section-container'>
																															<div style={{position:'relative'}}>
																																<input key={i} className='section-review-edit-section-subname' data-type='teacher_id' defaultValue={subsection_teacher_name} onChange={(e) => fuzzySearch(e, 'teachers', subsection_id)}/>
																															</div>
																															{subsection_teacher_search_results.length !== 0 &&
																																<div className='section-review-search-add-search-results'>
																																	<div className='search-add-did-you-mean'>Did you mean:</div>
																																	{subsection_teacher_search_results.map((teacher, i) => {
																																		return (
																																			<div className='search-add-search-result' key={i}>
																																				<div>{teacher.name}, {teacher.first_name}</div>
																																				<div>
																																					<div className='search-add-add-btn' onClick={(e) => updateSectionTemp(e, 'change', subsection_id, 'teacher_id', teacher.teacher_id)}>Add</div>
																																				</div>
																																			</div>
																																		)
																																	})}
																																</div>
																															}
																														</div>
																														{subsection_secondary_teachers.map((secondary_teacher_id, i) => {
																															return (
																																<SectionReviewEditingSecondaryTeacher teachers={teachers} courses={courses} sectionID={subsection_id} courseID={course_id} secondaryTeacherID={secondary_teacher_id} onSelect={addSecondaryTeacher} onRemove={removeSecondaryTeacher} key={i}/>
																															)
																														})}
																														<div className='blue-link align-right small-text section-review-blue-link' onClick={(e) => addSecondaryTeacher({e:e, section_id:subsection_id, secondary_teacher_id:''})}>Add Another Teacher</div>
																														</>
																													):
																													(
																														<>
																														<div className={`section-review-inclusion-data tooltip ${!subsection_teacher_id && 'section-review-inclusion-no-data'}`} data-tooltip-text={!subsection_teacher_id ? '' : subsection_teacher_name} onClick={() => toggleSectionEditing(subsection_id)}>
																															<div className='ellipsis'>{subsection_teacher_id ? subsection_teacher_name : <div className={`small-text ${subsection_is_inclusion === '1' ? 'orange-link' : 'blue-link'}`}>Add teacher</div>}</div>
																														</div>
																														{subsection_secondary_teachers.map((secondary_teacher_id, i) => {
																															const secondary_teacher_info = teachers.find(teacher => teacher.teacher_id === secondary_teacher_id);
																															const secondary_teacher_name = (teacher_info) ? `${secondary_teacher_info.name}, ${secondary_teacher_info.first_name}` : '';

																															return (
																																<div className='section-review-data tooltip' data-tooltip-text={`${secondary_teacher_name}`} key={`secondary-${i}`}>
																																	<div className='ellipsis'>{secondary_teacher_name}</div>
																																</div>
																															)
																														})}
																														</>
																													)
																												}
																												{subsection_is_editing ?
																												(
																													<div className='section-creation-btn-row'>
																														<div className='btn gray-btn' onClick={() => toggleSectionEditing(subsection_id)}>Cancel</div>
																														<div className='btn blue-btn' onClick={(e) => saveSection(e, 'update-subsection', subsection_id)}>Save</div>
																													</div>
																												):
																												(
																													<div className='section-review-inclusion-toggle-container'>
																														<span>Inclusion?</span>
																														<Toggle toggleOn={subsection_is_inclusion === '1'} handleChange={() => toggleInclusion(main_section_id, subsection_id)} isSmall={true} toggleColor='yellow'/>
																													</div>
																												)}
																											</div>
																										)}
																									</div>
																								)
																								
																							})}
																							{main_section_info.adding_subsection &&
																								<div className='section-review-section-content' style={{textAlign:'center'}}>
																									<img src={loading_gif} alt='loading...' style={{height:'40px'}}/>
																								</div>
																							}
																						</div>
																					</div>
																				)
																			}
																		})}
																	</div>
																</div>)
															})}
														</>
													):(currentLayout === 'row') ?
													(
														<div className='section-review-row-sections-container'>
															{data_sections_by_subcategory.map((subcategory_info, i) => {
																const subcategory_id = subcategory_info.subcategory_id;
																const sections_in_subcategory = subcategory_info.subcategory_sections;

																const is_editing_subcategory = (subcategory_id === null || subcategory_id === 'null');
																
																let subcategory_name = null;

																if(currentView === 'teacher')
																{
																	const course_info = courses.find(course => course.course_id === subcategory_id);
																	const course_name = (course_info) ? capitalizeFirstLetters(course_info.name) : null;
																	const course_code = (course_info) ? course_info.course_code : null;
																	subcategory_name = `${course_name} (${course_code})`;
																}
																else if(currentView === 'course')
																{
																	const teacher_id = (is_editing_subcategory) ? null : subcategory_id;
																	const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
																	subcategory_name= (teacher_info) ? `${teacher_info.name}, ${teacher_info.first_name}` : null;
																}

																const color_index = colorIndex % colorClasses.length;
																let color = colorClasses[color_index];
																colorIndex++;

																let logged_sections = [];

																return(
																<div className='section-review-subname-column' key={i}>
																	{is_editing_subcategory ? 
																		(
																			<h4 className='section-review-subname'>To Be Added</h4>
																		):(currentView === 'teacher') ?
																		(
																			<div className='tooltip section-review-subname' data-tooltip-text={`${subcategory_name} - ${num_requests_for_course.total} requests`}>
																				<h4 className='ellipsis'>{subcategory_name}</h4>
																				<div className='section-review-subname-stats'>
																					<div>{sections_in_subcategory.length} sections</div>
																				</div>
																			</div>
																		) :
																		(
																			<h4 className='section-review-subname ellipsis' data-tooltip-text={subcategory_name}>{subcategory_name}</h4>
																		)
																	}
																	{sections_in_subcategory.map((section_info, j) => {
																		const section_id = section_info.section_id;

																		const is_duplicate_section = ('is_duplicate_section' in section_info) ? section_info.is_duplicate_section : false;
																		const is_subsection_index = subsections.findIndex(subsection => subsection.subsection_id === section_id);
																		const is_subsection = is_subsection_index !== -1;

																		const main_section_info = (is_subsection) ? sections.find(section => section.subsections.includes(section_id)) : section_info;
																		const main_section_id = (is_subsection) ? main_section_info.section_id : section_id;
																		
																		// If seen this section already, skip it
																		// Otherwise log this section (and all affected sections) and display it
																		if(logged_sections.includes(main_section_id) && !is_duplicate_section) return null;
																		if(!is_duplicate_section) logged_sections.push(main_section_id);

																		const is_editing = (('temp_changes' in main_section_info)) ? true : false;
																		const section_subsections = main_section_info.subsections;
																		const section_number = (main_section_info.section_number) ? main_section_info.section_number : '--';
																		const course_id = (is_editing) ? main_section_info.temp_changes.course_id : main_section_info.course_id;
																		const course_info = courses.find(course => course.course_id === course_id);
																		const course_name = (course_id) ? capitalizeFirstLetters(course_info.name) : null;
																		const course_code = (course_id) ? course_info.course_code : null;
																		
																		const teacher_id = (is_editing) ? main_section_info.temp_changes.teacher_id : main_section_info.teacher_id;
																		const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
																		const teacher_name = (teacher_info) ? `${teacher_info.name}, ${teacher_info.first_name}` : null;
																		const secondary_teachers = (is_editing) ? main_section_info.temp_changes.secondary_teachers : main_section_info.secondary_teachers;
																		
																		const course_search_results = (is_editing) ? main_section_info.temp_changes.course_results.search : [];
																		const teacher_search_results = (is_editing) ? main_section_info.temp_changes.teacher_results.search : [];
																		
																		const course_suggestions = (is_editing) ? main_section_info.temp_changes.course_results.suggested : [];
																		const teacher_suggestions = (is_editing) ? main_section_info.temp_changes.teacher_results.suggested : [];
																		
																		let section_all_labels = main_section_info.labels;
																		section_all_labels = section_all_labels.map(label_id => {
																			const label_info = labels.find(label => label.label_id === label_id);
																			return label_info;
																		});
																		
																		const current_section_teams = section_all_labels.filter(label => label.is_team === '1');
																		const sorted_current_section_teams = sortArrayOfObjects(current_section_teams, 'label', 'text', 'asc');
																		
																		const current_section_labels = section_all_labels.filter(label => label.is_team === '0');
																		const sorted_current_section_labels = sortArrayOfObjects(current_section_labels, 'label', 'text', 'asc');
																		
																		if(main_section_info.loading || is_duplicate_section)
																		{
																			return (
																				<div className='section-review-section-container' key={`${main_section_id}-${j}`}>
																					<div></div>
																					<div></div>
																					<div className='section-review-section-content' style={{textAlign:'center'}}>
																						<img src={loading_gif} alt='loading...' style={{height:'40px'}}/>
																					</div>
																				</div>
																			)
																		}
																		else
																		{
																			return (
																				<div className='section-review-section-container row-view' key={`${main_section_id}-${j}`} data-sectionid={main_section_id}>
																					<div className='section-review-row-content'>
																						{!is_editing ? 
																							(
																								<>
																								<div>
																									<div className='section-review-edit-label'>Section #</div>
																									<div className='section-review-data tooltip' data-tooltip-text={`${section_number}`}>
																										<div className='ellipsis'>{section_number}</div>
																									</div>
																								</div>
																								<div>
																									<div className='section-review-edit-label'>Course</div>
																									<div className='section-review-data tooltip' data-tooltip-text={`${course_name} (${course_code})`}>
																										<div className='ellipsis'>{course_name} ({course_code})</div>
																									</div>
																								</div>
																								<div>
																									<div className='section-review-edit-label'>Teacher(s)</div>
																									<div className='section-review-data tooltip' data-tooltip-text={`${teacher_name}`}>
																										<div className='ellipsis'>{teacher_name}</div>
																									</div>
																									{secondary_teachers.map((secondary_teacher_id, i) => {
																										const secondary_teacher_info = teachers.find(teacher => teacher.teacher_id === secondary_teacher_id);
																										const secondary_teacher_name = (teacher_info) ? `${secondary_teacher_info.name}, ${secondary_teacher_info.first_name}` : '';

																										return (
																											<div className='section-review-data tooltip' data-tooltip-text={`${secondary_teacher_name}`} key={`secondary-${i}`}>
																												<div className='ellipsis'>{secondary_teacher_name}</div>
																											</div>
																										)
																									})}
																								</div>
																								<div className='section-review-row-view-btn-container'>
																									<div className='section-review-edit-btn tooltip' data-tooltip-text='Edit'>
																										<FontAwesomeIcon className='opaque-link' icon={faEdit} onClick={() => toggleSectionEditing(main_section_id)}/>
																									</div>
																									<div className='section-review-duplicate-btn tooltip' data-tooltip-text='Duplicate'>
																										<FontAwesomeIcon className='opaque-link' icon={faClone} onClick={() => duplicateSection(main_section_id)}/>
																									</div>
																									<div className='section-review-delete-btn tooltip' data-tooltip-text='Delete'>
																										<FontAwesomeIcon className='opaque-link' icon={faTrashAlt} onClick ={() => removeSection(main_section_id)}/>
																									</div>
																								</div>
																								</>
																							) :
																							(
																								<>
																								<div>
																									<div className='section-review-edit-label'>Section #</div>
																									<div className='section-review-data tooltip' data-tooltip-text={`${section_number}`}>
																										<div className='ellipsis'>{section_number}</div>
																									</div>
																								</div>
																								<div>
																									<div className='section-review-edit-label'>Course</div>
																									<div className='section-review-edit-section-container'>
																										<input key={course_name} className='section-review-edit-section-subname' data-type='course_id' defaultValue={course_name} onChange={(e) => fuzzySearch(e, 'courses', main_section_id)} />
																										<div className='section-review-search-add-search-results'>
																											{course_suggestions.length !== 0 &&
																												<>
																												<div className='search-add-did-you-mean'>Courses this Teacher Teaches:</div>
																												{course_suggestions.map((course, i) => {
																													return (
																														<div className='search-add-search-result' key={i}>
																															<div>{capitalizeFirstLetters(course.name)} ({course.course_code})</div>
																															<div>
																																<div className='search-add-add-btn' onClick={(e) => updateSectionTemp(e, 'change', main_section_id, 'course_id', course.course_id)}>Add</div>
																															</div>
																														</div>
																													)
																												})}
																												</>
																											}
																											{course_search_results.length !== 0 &&
																												<>
																												<div className='search-add-did-you-mean'>Other Courses:</div>
																												{course_search_results.map((course, i) => {
																													return (
																														<div className='search-add-search-result' key={i}>
																															<div>{capitalizeFirstLetters(course.name)} ({course.course_code})</div>
																															<div>
																																<div className='search-add-add-btn' onClick={(e) => updateSectionTemp(e, 'change', main_section_id, 'course_id', course.course_id)}>Add</div>
																															</div>
																														</div>
																													)
																												})}
																												</>
																											}
																										</div>
																									</div>
																								</div>
																								<div>
																									<div className='section-review-edit-label'>Teacher(s)</div>
																									<div className='section-review-edit-section-container'>
																										<input key={teacher_name} className='section-review-edit-section-subname' data-type='teacher_id' defaultValue={teacher_name} onChange={(e) => fuzzySearch(e, 'teachers', main_section_id)}/>
																										<div className='section-review-search-add-search-results'>
																											{teacher_suggestions.length !== 0 &&
																												<>
																												<div className='search-add-did-you-mean'>Teachers Who Can Teach:</div>
																												{teacher_suggestions.map((teacher, i) => {
																													return (
																														<div className='search-add-search-result' key={i}>
																															<div>{teacher.name}, {teacher.first_name}</div>
																															<div>
																																<div className='search-add-add-btn' onClick={(e) => updateSectionTemp(e, 'change', main_section_id, 'teacher_id', teacher.teacher_id)}>Add</div>
																															</div>
																														</div>
																													)
																												})}
																												</>
																											}
																											{teacher_search_results.length !== 0 &&
																												<>
																												<div className='search-add-did-you-mean'>Other Teachers:</div>
																												{teacher_search_results.map((teacher, i) => {
																													return (
																														<div className='search-add-search-result' key={i}>
																															<div>{teacher.name}, {teacher.first_name}</div>
																															<div>
																																<div className='search-add-add-btn' onClick={() => updateSectionTemp('change', main_section_id, 'teacher_id', teacher.teacher_id)}>Add</div>
																															</div>
																														</div>
																													)
																												})}
																												</>
																											}
																										</div>
																									</div>
																									{secondary_teachers.map((secondary_teacher_id, i) => {
																										return (
																											<SectionReviewEditingSecondaryTeacher teachers={teachers} courses={courses} sectionID={main_section_id} courseID={course_id} secondaryTeacherID={secondary_teacher_id} onSelect={addSecondaryTeacher} onRemove={removeSecondaryTeacher} key={i}/>
																										)
																									})}
																									<div className='blue-link align-right small-text section-review-blue-link' onClick={(e) => addSecondaryTeacher({e:e, section_id:main_section_id, secondary_teacher_id:''})}>Add Another Teacher</div>
																								</div>
																								<div className='section-creation-btn-row'>
																									<div className='btn gray-btn' onClick={() => toggleSectionEditing(main_section_id)}>Cancel</div>
																									<div className='btn blue-btn' onClick={(e) => saveSection(e, 'update-main-section', main_section_id)}>Save</div>
																								</div>
																								</>
																							)
																						}
																						{(!is_editing && !is_subsection) ?
																							(
																								<div className='blue-link align-right small-text section-review-row-subsection-link' onClick={(e) => saveSection(e, 'add-new-subsection', main_section_id)}>Add Subsection</div>
																							):
																							(
																								<div></div>
																							)
																						}
																						{section_subsections.map((subsection_id, i) => {
																							// SUBSECTION INFO
																							const subsection_info = subsections.find(section => section.subsection_id === subsection_id);
																							
																							if(!subsection_info) return;
																							
																							const subsection_section_info = sections.find(section => section.section_id === subsection_id);

																							if(!subsection_section_info) return;

																							const subsection_is_editing = ('temp_changes' in subsection_section_info) ? true : false;
																							
																							const subsection_course_id = subsection_section_info.course_id;
																							const subsection_course_info = courses.find(course => course.course_id === subsection_course_id);
																							const subsection_course_name = (subsection_course_info) ? capitalizeFirstLetters(subsection_course_info.name) : null;
																							const subsection_course_code = (subsection_course_info) ? subsection_course_info.course_code : null;
																							
																							const subsection_teacher_id = subsection_info.teacher_id;
																							const subsection_teacher_index = teachers.findIndex(teacher => teacher.teacher_id === subsection_teacher_id);
																							const subsection_teacher_info = (subsection_teacher_index !== -1) ? teachers[subsection_teacher_index] : null;
																							const subsection_teacher_name = (subsection_teacher_info) ? `${subsection_teacher_info.name}, ${subsection_teacher_info.first_name}` : null;
																							const subsection_secondary_teachers = (subsection_is_editing) ? subsection_section_info.temp_changes.secondary_teachers : subsection_section_info.secondary_teachers;

																							const subsection_teacher_search_results = (subsection_is_editing) ? subsection_section_info.temp_changes.teacher_results.search : [];
																							const subsection_course_search_results = (subsection_is_editing) ? subsection_section_info.temp_changes.course_results.search : [];
																							
																							const subsection_is_locked = subsection_section_info === '1';
																							const subsection_is_inclusion = subsection_section_info.is_inclusion;
																							
																							// let subsection_all_labels = subsection_section_info.labels;
																			
																							// subsection_all_labels = subsection_all_labels.map(label_id => {
																							// 	const label_info = labels.find(label => label.label_id === label_id);
																								
																							// 	return label_info;
																							// });
																							
																							// const current_subsection_teams = subsection_all_labels.filter(label => label.is_team === '1');
																							// const sorted_current_subsection_teams = sortArrayOfObjects(current_subsection_teams, 'label', 'text', 'asc');
																							
																							// const current_subsection_labels = subsection_all_labels.filter(label => label.is_team === '0');
																							// const sorted_current_subsection_labels = sortArrayOfObjects(current_subsection_labels, 'label', 'text', 'asc');
																							
																							return (
																								<React.Fragment key={`${subsection_id}-${i}`}>
																									<div></div>
																									<div>
																										{(subsection_is_inclusion === '0' || scheduleInfo.separate_ICR_codes === '1') &&
																											<>
																												<div className={`section-review-edit-label`}>{subsection_is_inclusion === '1' ? 'Inclusion' : 'Subsection'} Course</div>
																												{(subsection_is_editing) ?
																													(
																														<div className='section-review-edit-section-container'>
																															<div style={{position:'relative'}}>
																																<input key={i} className='section-review-edit-section-subname' data-type='course_id' defaultValue={subsection_course_name} onChange={(e) => fuzzySearch(e, 'courses', subsection_id)} />
																															</div>
																															{subsection_course_search_results.length !== 0 &&
																															<div className='section-review-search-add-search-results'>
																																<div className='search-add-did-you-mean'>Did you mean:</div>
																																{subsection_course_search_results.map((course, i) => {
																																	return (
																																		<div className='search-add-search-result' key={i}>
																																			<div>{course.name} ({course.course_code})</div>
																																			<div>
																																				<div className='search-add-add-btn' onClick={(e) => updateSectionTemp(e, 'change', subsection_id, 'course_id', course.course_id)}>Add</div>
																																			</div>
																																		</div>
																																	)
																																})}
																																</div>
																															}
																														</div>
																													):
																													(
																														<div className={`section-review-inclusion-data tooltip ${!subsection_course_id && 'section-review-inclusion-no-data'}`} data-tooltip-text={subsection_id ? `${subsection_course_name} (${subsection_course_code})` : ''} onClick={() => toggleSectionEditing(subsection_id)}>
																															<div className='ellipsis'>{subsection_course_id ? `${subsection_course_name} (${subsection_course_code})` : <div className={`small-text ${subsection_is_inclusion === '1' ? 'orange-link' : 'blue-link'}`}>Add course</div>}</div>
																														</div>
																													)
																												}
																											</>
																										}
																									</div>
																									<div>
																									<div className={`section-review-edit-label`}>{subsection_is_inclusion === '1' ? 'Inclusion' : 'Subsection'} Teacher</div>
																										{(subsection_is_editing) ?
																											(
																												<>
																												<div className='section-review-edit-section-container'>
																													<div style={{position:'relative'}}>
																														<input key={i} className='section-review-edit-section-subname' data-type='teacher_id' defaultValue={subsection_teacher_name} onChange={(e) => fuzzySearch(e, 'teachers', subsection_id)} />
																													</div>
																													{subsection_teacher_search_results.length !== 0 &&
																														<div className='section-review-search-add-search-results'>
																															<div className='search-add-did-you-mean'>Did you mean:</div>
																															{subsection_teacher_search_results.map((teacher, i) => {
																																return (
																																	<div className='search-add-search-result' key={i}>
																																		<div>{teacher.name}, {teacher.first_name}</div>
																																		<div>
																																			<div className='search-add-add-btn' onClick={(e) => updateSectionTemp(e, 'change', subsection_id, 'teacher_id', teacher.teacher_id)}>Add</div>
																																		</div>
																																	</div>
																																)
																															})}
																														</div>
																													}
																												</div>
																												{subsection_secondary_teachers.map((secondary_teacher_id, i) => {
																													return (
																														<SectionReviewEditingSecondaryTeacher teachers={teachers} courses={courses} sectionID={subsection_id} courseID={course_id} secondaryTeacherID={secondary_teacher_id} onSelect={addSecondaryTeacher} onRemove={removeSecondaryTeacher} key={i}/>
																													)
																												})}
																												<div className='blue-link align-right small-text section-review-blue-link' onClick={(e) => addSecondaryTeacher({e:e, section_id:subsection_id, secondary_teacher_id:''})}>Add Another Teacher</div>
																												</>
																											):
																											(
																												<>
																												<div className={`section-review-inclusion-data tooltip ${!subsection_teacher_id && 'section-review-inclusion-no-data'}`} data-tooltip-text={`${subsection_teacher_name}`} onClick={() => toggleSectionEditing(subsection_id)}>
																													<div className='ellipsis'>{subsection_teacher_id ? subsection_teacher_name : <div className={`small-text ${subsection_is_inclusion === '1' ? 'orange-link' : 'blue-link'}`}>Add teacher</div>}</div>
																												</div>
																												{subsection_secondary_teachers.map((secondary_teacher_id, i) => {
																													const secondary_teacher_info = teachers.find(teacher => teacher.teacher_id === secondary_teacher_id);
																													const secondary_teacher_name = (teacher_info) ? `${secondary_teacher_info.name}, ${secondary_teacher_info.first_name}` : '';

																													return (
																														<div className='section-review-data tooltip' data-tooltip-text={`${secondary_teacher_name}`} key={`secondary-${i}`}>
																															<div className='ellipsis'>{secondary_teacher_name}</div>
																														</div>
																													)
																												})}
																												</>
																											)
																										}
																									</div>
																									{subsection_is_editing ? 
																									(
																										<div className='section-creation-btn-row'>
																											<div className='btn gray-btn' onClick={() => toggleSectionEditing(subsection_id)}>Cancel</div>
																											<div className='btn blue-btn' onClick={(e) => saveSection(e, 'update-subsection', subsection_id)}>Save</div>
																										</div>
																									):(
																										<div className='section-review-row-view-btn-container'>
																											{!subsection_is_locked &&
																												<>
																												<div className='section-review-edit-btn tooltip' data-tooltip-text='Edit'>
																													<FontAwesomeIcon className='opaque-link' icon={faEdit} onClick={() => toggleSectionEditing(subsection_id)}/>
																												</div>
																												<div className='section-review-delete-btn tooltip' data-tooltip-text='Delete'>
																													<FontAwesomeIcon className='opaque-link' icon={faTrashAlt} onClick ={() => removeSection(subsection_id)}/>
																												</div>
																												</>
																											}
																										</div>
																									)}
																									<div>
																										<div className='section-review-inclusion-toggle-container' style={{alignSelf:'center'}}>
																											<span>Inclusion?</span>
																											<Toggle toggleOn={subsection_is_inclusion === '1'} handleChange={() => toggleInclusion(main_section_id, subsection_id)} isSmall={true} toggleColor='yellow'/>
																										</div>
																									</div>
																								</React.Fragment>
																							)
																						})}
																						{sorted_current_section_teams.map((label, index) => (
																							<div className='create-labels-section-label' key={label.label_id}>
																								<div className='create-labels-icon pastel-blue'></div>
																								<div>{label.label}</div> 
																							</div>
																						))}
																						{sorted_current_section_labels.map((label, index) => (
																							<div className='create-labels-section-label' key={label.label_id}>
																								<div className='create-labels-icon pastel-orange'></div>
																								<div>{label.label}</div>
																							</div>
																						))}
																					</div>
																				</div>
																			)
																		}
																	})}
																</div>
																)
															})}
														</div>
													):null}
												</div>
											)
										})}
										<div className='clean-data-add-new-data blue-link assign-resources-supervisor-confirm-add-new' onClick={() => setAddDataOpen(!addDataOpen)}>Add A <span className='capitalize'>{currentView}</span></div>
										{addDataOpen &&
											<div className='search-add-row-container'>
												<div className='search-add-search-container'>
													<div className='search-add-adding-input-container'>
														<input id='teacher-search-input' className='search-add-adding-input' onChange={(e) => addSearch(e)} placeholder={`Search for ${currentView}`}/>
														<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
													</div>
													<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => setAddDataOpen(!addDataOpen)}/>
												</div>
												{addResults.length !== 0 &&
													<div className='search-add-search-results'>
														<div className='search-add-did-you-mean'>Did you mean:</div>
														{addResults.map((data, i) => {
															const data_id = (currentView === 'course') ? data.course_id : data.teacher_id;
															const row_style = (currentView === 'course') ? {gridTemplateColumns:'1fr auto'} : {gridTemplateColumns:'1fr auto'};
															
															return (
																<div className='search-add-search-result' key={i} style={row_style}>
																	{currentView === 'course' ?
																		(
																			<div>{capitalizeFirstLetters(data.name)} ({data.course_code})</div>
																		):
																		(
																			<div>{data.name}, {data.first_name}</div>
																		)
																	}
																	<div>
																		<div className='search-add-add-btn' onClick={() => updateNewData('add', currentView, data_id, null, departmentID)}>Add</div>
																	</div>
																</div>
															)
														})}
														<div className='clean-data-add-new-data-text dark-blue-text'>If you don&rsquo;t see the {currentView} you are looking for, you should first add them <span className='blue-link' onClick={() => setAddNewDataOpen(true)}>Add A New <span className='capitalize'>{currentView}</span></span></div>
													</div>
												}
											</div>
										}
										{addNewDataOpen &&
											<>
											{currentView === 'course' ?
											(
												<EditCourseData schoolInfo={schoolInfo} dataID={null} toggleEditData={setAddNewDataOpen} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} courses={courses} students={students} classrooms={classrooms} teachers={teachers} departments={departments} setDatabaseData={setCourses} setStudents={setStudents} sections={sections} labels={labels} defaultDepartmentID={departmentID}/>
											):
											(
												<EditTeacherData schoolInfo={schoolInfo} dataID={null} toggleEditData={setAddNewDataOpen} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} teachers={teachers} students={students} courses={courses} classrooms={classrooms} departments={departments} setDatabaseData={setTeachers} defaultDepartmentID={departmentID}/>
											)
											}
											</>
										}
									</div>
								)
							}
							</>
						}
					</div>	
				</div>
			</div>
			</>
		):(
			<div id='section-review-dashboard'>
				<div id='section-review-content'>
					<div>
						<FontAwesomeIcon icon={faChevronLeft} className='progress-back-to-departments-btn' onClick={()=>{changeDepartment(null);setCreationOption(null);}}/>
					</div>
					<div className='section-review-content-inner-content'>
						<div className='section-review-content-header-container'>
							<h1 className='section-review-content-header'>{departmentInfo && departmentInfo.department} Department</h1>
						</div>
						<div style={{marginTop:'40px'}}>
							<h2>Choose How to Create Your Sections:</h2>
							<div className='section-review-section-creation-options-container'>
								<div className='section-review-missing-requests-container'  onClick={(schoolSubscribed && requestsSubmitted && creationOption !== 'predict') ? () => setCreationOption('predict') : () => setCreationOption(null)}>
									{(!schoolSubscribed) ?
										(
											<div className='section-review-currently-unavailable-container'>
												<p className='small-text dark-gray-text'>This feature is only available with a paid subscription. Please contact us at contact@edario.com to get full access to all features!</p>
											</div>
										):(requestsSubmitted) ?
										(
											<div className='section-review-creation-option-select-container'>
												<div className={`section-review-creation-option-select-container-inner ${creationOption === 'predict' && 'section-review-creation-option-select-container-inner-selected'}`}></div>
											</div>
										):
										(
											<div className='section-review-currently-unavailable-container'>
												<p className='small-text dark-gray-text'>This feature is currently unavailable until {userIsAdmin ? (<Link className='small-text blue-link' to='/progress/upload_data/upload_student_requests'>student requests are uploaded</Link>) : ('student requests are uploaded')}.</p>
											</div>
										)
									}
									<img style={{height:'80px'}} src={predictive_chart} alt='Edario Prediction'/>
									<h3 className='section-review-creation-option-header'>Let Us Create Sections</h3>
									<div className='section-review-creation-option-text medium-text'>Let Edario's algorithm figure out how many sections to create based off of requests, max class sizes, etc.</div>
								</div>
								{departmentHasPastSections &&
									<div className='section-review-missing-requests-container' style={{maxWidth:'600px'}} onClick={creationOption !== 'copy' ? () => setCreationOption('copy') : () => setCreationOption(null)}>
										<div className='section-review-creation-option-select-container'>
											<div className={`section-review-creation-option-select-container-inner ${creationOption === 'copy' && 'section-review-creation-option-select-container-inner-selected'}`}></div>
										</div>
										<img style={{height:'80px'}} src={copy_sections} alt='Copy Last Year Sections'/>
										<h3 className='section-review-creation-option-header'>Copy Last Year's Sections</h3>
										<div className='section-review-creation-option-text medium-text'>Copy over your sections from last year as a good starting point.</div>
									</div>
								}
								<div className='section-review-missing-requests-container' style={{maxWidth:'600px'}} onClick={creationOption !== 'manual' ? () => setCreationOption('manual') : () => setCreationOption(null)}>
									<div className='section-review-creation-option-select-container'>
										<div className={`section-review-creation-option-select-container-inner ${creationOption === 'manual' && 'section-review-creation-option-select-container-inner-selected'}`}></div>
									</div>
									<img style={{height:'80px'}} src={manually_create_sections} alt='Manually Create Sections'/>
									<h3 className='section-review-creation-option-header'>Manually Create Sections</h3>
									<div className='section-review-creation-option-text medium-text'>If you don't want to wait for student requests, you can start creating your sections manually!</div>
								</div>
							</div>
							<div id={creationOption ? 'section-review-create-sections-btn-active' : 'section-review-create-sections-btn'} className='btn btn-large' onClick={() => createSections()}>Create Sections</div>
						</div>
					</div>
				</div>
			</div>
		)}
		{showNextButton &&
			<div className='fixed-bottom-save-container'>
				<Button classes={['btn', 'btn-extra-large', 'green-btn', 'align-right', 'move-to-next-screen-btn']} selected={false} text='Go to Next Step: Create Labels' onClickFunction={handleMoveToNextScreen} />
			</div>
		}
		</>
	)
}