import React, { useState , useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import { useOutsideClick } from '../js/Helpers';

export default function Dropdown({ data, currentValue, displayDefault = null, backgroundClass=null, colorClass=null, handleChange = ()=>{}, disabled=false }) {
	const ref = useRef();
	
	const [visible, setVisible] = useState(false);
	
	const toggleVisible = (e) => {
		setVisible(!visible);
	}
	
	useOutsideClick(ref, () => {
		setVisible(false);
	});
	
	const selectOption = (e) => {
		const new_value = e.target.getAttribute('data-value');
		const display = e.target.innerHTML;
		const parentDiv = e.target.closest('.dropdown-container');
		const dropdownDisplay = parentDiv.querySelector('.dropdown-display');
		
		dropdownDisplay.innerHTML = display;
		dropdownDisplay.setAttribute('data-value', new_value);
		
		if(handleChange) handleChange(new_value);
	}
	
	useEffect(() => {
		if(visible)
		{
			const selected_option = document.getElementsByClassName('dropdown-option-selected')[0];
			
			if(selected_option) selected_option.parentNode.scrollTop = selected_option.offsetTop;
		}
	}, [visible]);
	
	const display_value_from_data = (data) ? data.find(option => option.value === currentValue) : null;
	const display = (display_value_from_data !== undefined) ? display_value_from_data.display : (disabled ? 'N/A' : displayDefault);
	
	return (
		<div className={`dropdown-container ${backgroundClass} ${(backgroundClass && backgroundClass !== 'white') && 'no-border'} ${disabled ? 'dropdown-disabled' : ''}`} onClick={!disabled ? toggleVisible : null} ref={ref}>
			<div className={`dropdown-display dropdown-display-default ${colorClass ? colorClass + '-text' : null}`} data-value={currentValue}>{display}</div>
			{!disabled &&
				<FontAwesomeIcon className={`dropdown-arrow ${colorClass ? colorClass + '-text' : null}`} icon={faAngleDown}/>
			}
			{visible &&
				<div className='dropdown-options-container'>
					{data.map((row, i) => {
						return (
							<div className={`dropdown-option ${row.value === currentValue ? 'dropdown-option-selected' : ''}`} onClick={selectOption} data-value={row.value} key={i}>{row.display}</div>
						)
					})}
				</div>
			}
		</div>
	);
}