import React, { useState, useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faTimes, faLock } from '@fortawesome/free-solid-svg-icons';

import { saveSchoolInfo } from '../../../js/SaveFunctions';

import '../../../css/hsms/school_setup.css';

export default function SchoolInfo({ isLoading, subscreenRestricted, subscreenLocked, schoolInfo, setSchoolInfo, handleMoveToNextScreen}) {
	const [isSubmitting, setIsSubmitting] = useState(false);
	
	const [tempSchoolInfo, setTempSchoolInfo] = useState(schoolInfo);
	const [errorType, setErrorType] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [showCodeInfo, setShowCodeInfo] = useState(false);
	
	const states = useRef([{value:"AL", name:"Alabama (AL)"},{value:"AK", name:"Alaska (AK)"},{value:"AZ", name:"Arizona (AZ)"},{value:"AR", name:"Arkansas (AR)"},{value:"CA", name:"California (CA)"},{value:"CO", name:"Colorado (CO)"},{value:"CT", name:"Connecticut (CT)"},{value:"DE", name:"Delaware (DE)"},{value:"DC", name:"District Of Columbia (DC)"},{value:"FL", name:"Florida (FL)"},{value:"GA", name:"Georgia (GA)"},{value:"HI", name:"Hawaii (HI)"},{value:"ID", name:"Idaho (ID)"},{value:"IL", name:"Illinois (IL)"},{value:"IN", name:"Indiana (IN)"},{value:"IA", name:"Iowa (IA)"},{value:"KS", name:"Kansas (KS)"},{value:"KY", name:"Kentucky (KY)"},{value:"LA", name:"Louisiana (LA)"},{value:"ME", name:"Maine (ME)"},{value:"MD", name:"Maryland (MD)"},{value:"MA", name:"Massachusetts (MA)"},{value:"MI", name:"Michigan (MI)"},{value:"MN", name:"Minnesota (MN)"},{value:"MS", name:"Mississippi (MS)"},{value:"MO", name:"Missouri (MO)"},{value:"MT", name:"Montana (MT)"},{value:"NE", name:"Nebraska (NE)"},{value:"NV", name:"Nevada (NV)"},{value:"NH", name:"New Hampshire (NH)"},{value:"NJ", name:"New Jersey (NJ)"},{value:"NM", name:"New Mexico (NM)"},{value:"NY", name:"New York (NY)"},{value:"NC", name:"North Carolina (NC)"},{value:"ND", name:"North Dakota (ND)"},{value:"OH", name:"Ohio (OH)"},{value:"OK", name:"Oklahoma (OK)"},{value:"OR", name:"Oregon (OR)"},{value:"PA", name:"Pennsylvania (PA)"},{value:"RI", name:"Rhode Island (RI)"},{value:"SC", name:"South Carolina (SC)"},{value:"SD", name:"South Dakota (SD)"},{value:"TN", name:"Tennessee (TN)"},{value:"TX", name:"Texas (TX)"},{value:"UT", name:"Utah (UT)"},{value:"VT", name:"Vermont (VT)"},{value:"VA", name:"Virginia (VA)"},{value:"WA", name:"Washington (WA)"},{value:"WV", name:"West Virginia (WV)"},{value:"WI", name:"Wisconsin (WI)"},{value:"WY", name:"Wyoming (WY)"}]);
	
	const submitForm = () => {
		setIsSubmitting(true);
		
		if(!tempSchoolInfo.name)
		{
			setErrorType('name');
			setErrorMessage('This field is required');
			setIsSubmitting(false);
			return false;
		}
		else if(!tempSchoolInfo.school_code)
		{
			setErrorType('code');
			setErrorMessage('This field is required');
			setIsSubmitting(false);
			return false;
		}
		
		tempSchoolInfo.school_id = schoolInfo.school_id;
		
		saveSchoolInfo(tempSchoolInfo, () => {
			setSchoolInfo({...schoolInfo, ...tempSchoolInfo});
			handleMoveToNextScreen();
		});
	}

	useEffect(() => {
		if(!isLoading) setTempSchoolInfo(schoolInfo);
	}, [isLoading, schoolInfo]);
	
	return (
		<div className='school-setup-main-container'>
			<h1 className='progress-main-header'>School Info</h1>
			<p className='progress-subheader'>Let's start by getting some background info about your school.</p>
			{isLoading ?
				(
					<div className='center-text'>
						<img src={require("../../../images/balls.gif")} alt='loading...' style={{height:'40px'}}/>
					</div>
				):(subscreenRestricted) ?
				(
					<div className='general-screen-message'>
						<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
						<h1>No Access</h1>
						<div>You do not have access to this screen</div>	
					</div>
				):(subscreenLocked) ?
				(
					<div className='general-screen-message'>
						<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
						<h1>Temporarily Locked</h1>
						<div>This screen is locked until all other prior steps are completed</div>	
					</div>
				):
				(
					<div id="school-set-up-container">
						<div className="full-width">
							<div className='school-set-up-label'>School Name*</div>
							<input id="name" name='name' className={`school-set-up-input ${(errorType === 'name' && errorMessage) && 'error'}`}  defaultValue={tempSchoolInfo.name} onChange={(e) => setTempSchoolInfo({...tempSchoolInfo, name:e.target.value})} autoFocus={true}/>
							{(errorType === 'name' && errorMessage) && <div className='error-message-no-box' style={{textAlign:'left'}}>{errorMessage}</div>}
						</div>
						<div id='school-code-container' className="full-width">
							{showCodeInfo &&
								<div className='explanation-popup school-code-explanation-popup'>
									<FontAwesomeIcon className='dark-gray-link school-code-explanation-cancel' icon={faTimes} onClick={()=>{setShowCodeInfo(false)}}/> 
									<h3 className='school-code-explanation-header'><FontAwesomeIcon className='blue-text school-code-explanation-popup-icon' icon={faQuestionCircle}/> What is a "school code"?</h3>
									<p>This is the ID that your current SIS uses for your school. Examples are 'LHS' or '004', depending if your SIS uses letters or numbers.</p>
								</div>
							}
							<div className='school-set-up-label'>School Code* <FontAwesomeIcon className='school-set-up-info-icon blue-link' icon={faQuestionCircle} onClick={() => {setShowCodeInfo(!showCodeInfo)}}/></div>
							<input id="name" name='name' className={`school-set-up-input ${(errorType === 'code' && errorMessage) && 'error'}`} defaultValue={tempSchoolInfo.school_code} onChange={(e) => setTempSchoolInfo({...tempSchoolInfo, school_code:e.target.value})}/>
							{(errorType === 'code' && errorMessage) && <div className='error-message-no-box' style={{textAlign:'left'}}>{errorMessage}</div>}
						</div>
						<div className="full-width">
							<div className='school-set-up-label'>City</div>
							<input id="city" name='city' className="school-set-up-input" defaultValue={tempSchoolInfo.city} onChange={(e) => setTempSchoolInfo({...tempSchoolInfo, city:e.target.value})}/>
						</div>
						<div className="full-width">
							<div className='school-set-up-label'>State</div>
							<div className="search_categories">
								<div className="select">
									<select id="state" name='state' key={tempSchoolInfo.state} defaultValue={tempSchoolInfo.state} onChange={(e) => setTempSchoolInfo({...tempSchoolInfo, state:e.target.value})}>
										<option value='' disabled> -- Select a State-- </option>
										{states.current.map((e, key) => {
									        return <option key={e.value} value={e.value}>{e.name}</option>;
									    })}
									</select>
								</div>
							</div>
						</div>
						<div className="full-width">
							<div className='school-set-up-label'>Zip Code</div>
							<input id="zip_code" name="zip_code" className="school-set-up-input" defaultValue={tempSchoolInfo.zip_code} onChange={(e) => setTempSchoolInfo({...tempSchoolInfo, zip_code:e.target.value})}/>
						</div>
						{!isSubmitting ? 
							(
								<div id='submit-school-info' className="btn green-btn btn-large full-width" onClick={submitForm}>Submit</div>
							) : 
							(
								<div id='submit-school-info' className="btn green-btn btn-large full-width">
									<img src={require('../../../images/ajax-loader.gif')} alt='Loading' style={{height:'20px'}}/>
								</div>
							)
						}
					</div>
				)
			}
		</div>
	);
}